import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import Flatpickr from "react-flatpickr";

import DataTableExtensions from "react-data-table-component-extensions";
import TimeHelper from "../../helpers/TimeHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";

import "react-data-table-component-extensions/dist/index.css";
import "../../assets/css/dataTableComponent.scss";
import { ChevronDown, Check } from "react-feather";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const DataTableComponent = ({
  title,
  data,
  columns,
  startDate,
  endDate,
  showDateFilter = true,
  dateFilterUpdated,
  isCheckBox,
  handleChange,
  users,
  pagination,
  exportBtn = false,
}) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  let usersArray = [];
  if (users) {
    usersArray = users.toString().includes(",")
      ? users.split(",")
      : users.toString().split();
  }
  const allTimeValue = TimeHelper.getFirstDayOf2018();
  const last7Days = TimeHelper.getDaysBeforeToday(7);
  const last30Days = TimeHelper.getDaysBeforeToday(30);
  const lastYear = TimeHelper.getDaysBeforeToday(365);
  const thisMonth = TimeHelper.getFirstDayOf("month");
  const thisYear = TimeHelper.getFirstDayOf("year");

  const [isOpen, setOpen] = useState(false);
  const [filterTitle, setTitle] = useState(t("all-time"));

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        {showDateFilter && (
          <div right="true">
            <div
              tag="small"
              className="text-bold-500 cursor-pointer"
              onClick={(e) => {
                setOpen(!isOpen);
              }}
            >
              {filterTitle} <ChevronDown size={10} />
            </div>
            {isOpen && (
              <div className="col-md-3 col-lg-3 col-xl-3 col-6 no-padding filter-container">
                <Button.Ripple
                  outline
                  onClick={() => {
                    dateFilterUpdated([last7Days]);
                    setTitle("Last 7 days");
                    setOpen(!isOpen);
                  }}
                  className="square full-width"
                >
                  {t("last-7-days")}{" "}
                </Button.Ripple>
                <Button.Ripple
                  outline
                  onClick={() => {
                    dateFilterUpdated([last30Days]);
                    setTitle("Last 30 days");
                    setOpen(!isOpen);
                  }}
                  className="square full-width"
                >
                  {t("last-30-days")}{" "}
                </Button.Ripple>
                <Button.Ripple
                  outline
                  onClick={() => {
                    dateFilterUpdated([lastYear]);
                    setTitle("Last 365 days");
                    setOpen(!isOpen);
                  }}
                  className="square full-width"
                >
                  {t("last-365-days")}{" "}
                </Button.Ripple>
                <Button.Ripple
                  outline
                  onClick={() => {
                    dateFilterUpdated([thisMonth]);
                    setTitle("Month to Date");
                    setOpen(!isOpen);
                  }}
                  className="square full-width"
                >
                  {t("month-to-date")}{" "}
                </Button.Ripple>
                <Button.Ripple
                  outline
                  onClick={() => {
                    dateFilterUpdated([thisYear]);
                    setTitle("Year to Date");
                    setOpen(!isOpen);
                  }}
                  className="square full-width"
                >
                  {t("year-to-date")}{" "}
                </Button.Ripple>
                <Button.Ripple
                  outline
                  onClick={() => {
                    setTitle("All Time");
                    dateFilterUpdated([allTimeValue]);
                    setOpen(!isOpen);
                  }}
                  className="square full-width"
                >
                  {t("all-time")}{" "}
                </Button.Ripple>
                <Button.Ripple
                  outline
                  className="no-padding square full-width center"
                >
                  <Flatpickr
                    id="date-interval"
                    className="form-control align-text-center"
                    options={{
                      mode: "range",
                      defaultDate: [startDate, endDate],
                    }}
                    onChange={(d) => {
                      if (d.length <= 1) return;
                      setTitle(t("custom-date"));
                      dateFilterUpdated(d);
                      setOpen(!isOpen);
                    }}
                  />
                </Button.Ripple>
              </div>
            )}
          </div>
        )}
      </CardHeader>
      <CardBody>
        {!isCheckBox && (
          <DataTableExtensions
            columns={columns}
            data={data}
            print={false}
            export={exportBtn}
            // style={{color: ('white', '!important')}}
          >
            <DataTable
              noHeader
              pagination={pagination}
              paginationRowsPerPageOptions={[25, 50, 100]}
              paginationPerPage={100}
              showDateFilter={showDateFilter}
            />
          </DataTableExtensions>
        )}
        {isCheckBox && (
          <DataTableExtensions
            columns={columns}
            data={data}
            print={false}
            export={exportBtn}
            // style={{color: ('white', '!important')}}
          >
            <DataTable
              noHeader
              pagination
              paginationRowsPerPageOptions={[25, 50, 100]}
              paginationPerPage={100}
              selectableRows
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={{
                color: "primary",
                icon: <Check className="vx-icon" size={12} />,
                label: "",
                size: "sm",
              }}
              onSelectedRowsChange={handleChange}
              showDateFilter={showDateFilter}
            />
          </DataTableExtensions>
        )}
      </CardBody>
    </Card>
  );
};

export default DataTableComponent;
