import React from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'reactstrap';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import LocationService from '../../services/LocationService';
import { getLocation } from '../../redux/actions/locations';
import { getAllFloors, saveFloor, delFloor } from '../../redux/actions/floors';
import ConfirmationModal from '../shared/forms/ConfirmationModal';
import SelectLocation from '../shared/SelectLocation';
import FloorModal from '../shared/forms/FloorModal';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faChair } from '@fortawesome/free-solid-svg-icons';
import { Plus } from 'react-feather';
import BOOKEDTABLE from '../../assets/img/pages/table_empty.png';
import '../../assets/scss/plugins/forms/react-select/_react-select.scss';
import '../../assets/scss/pages/app-ecommerce-shop.scss';
import toast, { Toaster } from 'react-hot-toast';

class FloorsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
      floors: [],
      isFloorModalOpen: false,
      selectedFloor: null,
      deletingFloor: false,
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: '',
        header: '',
      },
    };
  }
  async componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchMenu = true) {
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
      await this.props.getAllFloors(loc_id);
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.location)),
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async toggleFloorModal(floor = null) {
    let {
      state: { isFloorModalOpen },
    } = this;
    const selectedFloor = floor;
    isFloorModalOpen = !isFloorModalOpen;
    return this.setState({
      selectedFloor,
      isFloorModalOpen,
      deletingFloor: false,
    });
  }

  async deleteFloor(floor) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: '',
      header: '',
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        this.setState({
          confirmationDialog: defaultState,
          deletingFloor: true,
        });
        await this.props.delFloor(this.state.location.loc_id, floor.id);
        await this.toggleFloorModal();
        toast.success(this.props.t('floor-deleted-successfully'));
      },
      rejectCallback: () => {
        this.setState({
          confirmationDialog: defaultState,
          deletingFloor: false,
        });
      },
      title: `Are you sure to delete ${floor.floor_name}`,
      header: this.props.t('delete-floor'),
    };
    this.setState({ confirmationDialog });
  }

  // async saveFloor(floor) {
  //   try {
  //     await this.props.saveFloor(this.state.location.loc_id, floor);
  //     toast.success(this.props.t('floor-added-successfully'));
  //   } catch (e) {
  //     toast.error(e.toString());
  //   }
  //   await this.toggleFloorModal();
  // }

  async saveFloor(floor) {
    try {
      await this.props.saveFloor(this.state.location.loc_id, floor);
      if (floor.id !== null) {
        toast.success(this.props.t('floor-updated-successfully'));
      } else {
        toast.success(this.props.t('floor-added-successfully'));
      }
    } catch (e) {
      toast.error(e.toString());
    }
    await this.toggleFloorModal();
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  render() {
    const { t } = this.props;
    let chairs = 0;
    const {
      props: { location, floors },
    } = this;
    const {
      state: { selectLocation, loading },
    } = this;
    const locationName = location ? location.name : '';
    if (loading) return <SpinnerComponent />;
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );
    return (
      <div className="location ecommerce-application">
        <Toaster position="top-right" />
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
            <Button
              outline
              className="font-weight-bold custom-padding primary-2-border mb-1 ml-1"
              color={'primary'}
              size="md"
              onClick={() => this.toggleFloorModal()}
            >
              <Plus className="mx-2" size={18} />{' '}
              <span className="ml-1 mr-5 ">{t('add-new-floor')}</span>
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div id="ecommerce-products">
              <div className="row">
                {floors.length > 0 ? (
                  floors.map((floor) => {
                    chairs = 0;
                    floor.Tables.map((chair) => {
                      chairs += chair.max_capacity;
                    });
                    return (
                      <div
                        className="col-lg-3 col-md-4 col-sm-6"
                        key={floor.id}
                      >
                        <Card className="ecommerce-card table-session">
                          <div
                            className="card-content"
                            style={{
                              backgroundColor: `${
                                floor.floor_color != null
                                  ? floor.floor_color
                                  : '#82e9ab'
                              }`,
                            }}
                          >
                            <div className="row">
                              <div className="col-12">
                                <span
                                  className="float-left font-weight-bold floor-bg-design w-100 text-center"
                                  style={{ fontSize: '15px' }}
                                >
                                  <FontAwesomeIcon
                                    className=" text-white"
                                    icon={faTable}
                                    style={{ fontSize: '15px' }}
                                  />{' '}
                                  {floor.Tables.length}{' '}
                                  {floor.Tables.length > 1 ? `Tables` : `Table`}
                                </span>
                                {/* <span
                                  className="float-right font-weight-bold floor-bg-design"
                                  style={{ fontSize: '15px' }}
                                >
                                  <FontAwesomeIcon
                                    className="mr-1"
                                    style={{
                                      fontSize: '15px',
                                      color: 'gold',
                                    }}
                                    icon={faChair}
                                  />
                                  {floor.Tables.length > 0
                                    ? `${chairs} Seats`
                                    : t('no-seat')}
                                </span> */}
                              </div>
                            </div>

                            <div className="item-img text-center">
                              <img
                                style={{ filter: 'brightness(2)' }}
                                className="img-fluid"
                                src={BOOKEDTABLE}
                                alt={floor.floor_name}
                              />

                              <h4 className="col-12 floor-bg-design mt-1">
                                <div className="font-weight-bold text-white">
                                  {floor.floor_name}
                                </div>
                              </h4>
                              <button
                                onClick={() =>
                                  this.props.history.push('/floors/tables', {
                                    floor: floor,
                                    tables: floor.Tables,
                                  })
                                }
                                className="cart btn btn-warning mt-1 mx-auto d-flex"
                              >
                                {t('view-floor')}{' '}
                              </button>
                              <button
                                onClick={() => this.toggleFloorModal(floor)}
                                className="cart btn btn-success mt-1 ml-1 mx-auto d-flex"
                              >
                                {t('edit-floor')}{' '}
                              </button>
                            </div>
                          </div>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12 text-center p-4">
                    {t('no-floor-added')}
                  </div>
                )}
                <FloorModal
                  deleting={this.state.deletingFloor}
                  deleteFloor={this.deleteFloor.bind(this)}
                  saveFloor={this.saveFloor.bind(this)}
                  isOpen={this.state.isFloorModalOpen}
                  floor={this.state.selectedFloor}
                  loc_id={this.state.location.loc_id}
                  toggleModal={this.toggleFloorModal.bind(this)}
                />
                <ConfirmationModal
                  isOpen={this.state.confirmationDialog.show}
                  accept={this.state.confirmationDialog.acceptCallback.bind(
                    this
                  )}
                  reject={this.state.confirmationDialog.rejectCallback.bind(
                    this
                  )}
                  title={this.state.confirmationDialog.title}
                  header={this.state.confirmationDialog.header}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
    floors: {
      allFloors: { data: floorsData, loading: floorLoading },
    },
  } = state;
  return {
    location: locationData,
    floors: floorsData,
    loading: locationLoading || floorLoading,
  };
}

export default withTranslation('global')(
  connect(mapStateToProps, {
    getLocation,
    getAllFloors,
    saveFloor,
    delFloor,
  })(FloorsManagement)
);
