import React from "react";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import {
  Label,
  Row,
  Col,
  Card,
  Input,
  CardBody,
  CardHeader,
  Button,
  ButtonGroup,
} from "reactstrap";
import LocationService from "../../services/LocationService";
import CONSTANTS from "../../constants";
import { connect } from "react-redux";
import { getAllLocations } from "../../redux/actions/locations";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import MenuService from "../../services/MenuService";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import Toggle from "react-toggle";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import Menu from "../menus/Menu";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class DishTagForm extends React.Component {
  state = {
    activeStep: 0,
    isOpenSelect: false,
    location: null,
    selectLocation: false,
    editMode: false,
    dishtag: {
      tag_id: "",
      tag_name: "",
      loc_id: "",
      tag_description: "",
    },
    location: {
      loc_id: 0,
      name: this.props.t("all-venues"),
      Currency: {
        currency_code: "PKR",
      },
      decimal_places: 2,
    },
    loading: false,
    saving: false,
    errorSaving: false,
    errorWallet: false,
    isOpen: false,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: this.props.t("wallet"),
      header: this.props.t("wallet"),
    },
  };

  async getDishTagFromId(id) {
    let dishtag = await MenuService.getDishTag(this.state.location.loc_id, id);
    return dishtag;
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        await this.setState({ loading: false });
      } else {
        await this.setState({ loading: false, selectLocation: true });
      }
      if (window.location.pathname.indexOf("edit") !== -1) {
        const parts = window.location.pathname.split("/");
        let table = await this.getDishTagFromId(parts[parts.length - 1]);
        await this.setState({ dishtag: table, editMode: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  onBlur() {
    this.filterUpdated();
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  toggleItemsModal() {
    this.setState({ isMenuItemModalOpen: !this.state.isMenuItemModalOpen });
  }

  async updateValue(value, type) {
    if (!isNaN(value) && value) value = parseInt(value);
    let currency = JSON.parse(JSON.stringify(this.state.currency));
    let obj = currency;
    let parts = type.split(".");
    const n = parts.length - 1;
    for (let i = 0; i < n; i++) obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ currency });
  }

  async setOpen(open) {
    await this.setState({ isOpen: open });
  }

  async setOpenSelect() {
    await this.setState({ isOpenSelect: !this.state.isOpenSelect });
  }

  // async addUpdateDishTag() {
  //   const {
  //     state: { dishtag, location, editMode },
  //   } = this;
  //   var item = {};
  //   var tag_name = editMode
  //     ? document.getElementById(`tag_name_${dishtag.tag_id}`).value
  //     : document.getElementById(`tag_name`).value;
  //   if (tag_name === '' || tag_name === null) {
  //     return;
  //   }
  //   var tag_description = editMode
  //     ? document.getElementById(`tag_description_${dishtag.tag_id}`).value
  //     : document.getElementById(`tag_description`).value;
  //   if (dishtag.tag_id === '') {
  //     item = {
  //       tag_description,
  //       tag_name,
  //       loc_id: location.loc_id,
  //     };
  //     await MenuService.saveDishTag(location.loc_id, item);
  //     this.props.history.replace('/dish_tags');
  //   } else {
  //     item = {
  //       tag_description,
  //       tag_name,
  //       tag_id: dishtag.tag_id,
  //       loc_id: location.loc_id,
  //     };
  //     await MenuService.updateDishTags(location.loc_id, dishtag.tag_id, item);
  //     this.props.history.replace('/dish_tags');
  //   }
  // }
  async addUpdateDishTag() {
    const {
      state: { dishtag, location, editMode },
    } = this;

    var item = {};
    var tag_name = editMode
      ? document.getElementById(`tag_name_${dishtag.tag_id}`).value
      : document.getElementById(`tag_name`).value;

    if (tag_name === "" || tag_name === null) {
      toast.error(this.props.t("tag-name-cannot-be-empty"));
      return;
    }

    var tag_description = editMode
      ? document.getElementById(`tag_description_${dishtag.tag_id}`).value
      : document.getElementById(`tag_description`).value;

    if (dishtag.tag_id === "") {
      item = {
        tag_description,
        tag_name,
        loc_id: location.loc_id,
      };

      try {
        await MenuService.saveDishTag(location.loc_id, item);
        toast.success(this.props.t("dish-tag-saved-successfully"));
        setTimeout(() => {
          this.props.history.replace("/dish_tags");
        }, 1000);
        // this.props.history.replace('/dish_tags');
      } catch (error) {
        toast.error(this.props.t("failed-to-save-tag"));
      }
    } else {
      item = {
        tag_description,
        tag_name,
        tag_id: dishtag.tag_id,
        loc_id: location.loc_id,
      };

      try {
        await MenuService.updateDishTags(location.loc_id, dishtag.tag_id, item);
        toast.success(this.props.t("dish-tag-updated-successfully"));
        setTimeout(() => {
          this.props.history.replace("/dish_tags");
        }, 1000);
      } catch (error) {
        toast.error(this.props.t("failed-to-update-tag"));
      }
    }
  }

  handleStepChange(activeStep) {
    this.setState({ activeStep });
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  onConditionChange = (selectedOption) => {
    this.updateValue(selectedOption.value, "plan_time");
  };

  async deleteDishTag() {
    const {
      state: { dishtag, location },
    } = this;

    const result = await Swal.fire({
      title: this.props.t("confirm-deletion"),
      text: this.props.t("are-you-sure-you-want-to-delete-this-dish-tag"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.props.t("yes-delete-it"),
      cancelButtonText: this.props.t("no-cancel"),
    });

    if (result.isConfirmed) {
      await MenuService.deleteDishTags(location.loc_id, dishtag.tag_id);
      this.props.history.replace("/dish_tags");
    }
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  renderTables(item, locations) {
    return (
      <>
        <Row className="p-1 align-items-center">
          {/* <Row className="match-height">
            <Col xs={12} sm={6} lg={4}>
              <ToastSuccess />
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <ToastError />
            </Col>
          </Row> */}

          <Col md={this.state.editMode ? 5 : 6}>
            <AvGroup>
              <AvInput
                data-id={item.tag_name}
                name={`tag_name`}
                placeholder={this.props.t("tag-name")}
                type="text"
                required
                id={
                  this.state.editMode ? `tag_name_${item.tag_id}` : "tag_name"
                }
                value={item.tag_name}
              />
              <AvFeedback>
                {this.props.t("please-enter-dish-tag-name")}
              </AvFeedback>
            </AvGroup>
          </Col>
          <Col md={this.state.editMode ? 5 : 6}>
            <AvGroup>
              <Input
                type="textarea"
                rows="5"
                data-id={item.tag_description}
                defaultValue={item.tag_description}
                name={`tag_description`}
                id={
                  this.state.editMode
                    ? `tag_description_${item.tag_id}`
                    : "tag_description"
                }
              />
              <AvFeedback>{this.props.t("dish-tag-description")}</AvFeedback>
            </AvGroup>
          </Col>
          {/* {this.state.editMode && (
            <Col md={2} sm={2}>
              <AvGroup>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => this.deleteDishTag()}
                >
                  Delete
                </button>
              </AvGroup>
            </Col>
          )} */}
        </Row>
      </>
    );
  }

  render() {
    const { t } = this.props;
    const {
      props: { locations },
    } = this;

    const {
      state: {
        errorSaving,
        dishtag,
        editMode,
        confirmationDialog,
        selectLocation,
        location,
        loading,
      },
    } = this;

    const locationName = location ? location.name : "";

    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <div className="location">
        <Toaster position="top-right" />
        <div className="display-block mb-1">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.changeLocation.bind(this)}
          />
        </div>
        <Card className="full-width">
          <AvForm>
            <>
              <Row className="p-1">
                <Col md={6}>
                  <Label>{t("tag-name")}</Label>
                </Col>
                <Col md={6}>
                  <Label>{t("tag-description")}</Label>
                </Col>
              </Row>
            </>

            {this.renderTables(dishtag, locations)}
            <Row className="p-1 ml-1">
              <div className="actions ">
                {!editMode && (
                  <button
                    className="btn btn-primary"
                    onClick={() => this.addUpdateDishTag()}
                  >
                    {t("save-tag")}{" "}
                  </button>
                )}
                &nbsp;
                {editMode && (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.addUpdateDishTag()}
                    >
                      {t("update-dish-tag")}{" "}
                    </button>
                    <button
                      className="btn mx-2 btn btn-danger"
                      onClick={() => this.deleteDishTag()}
                    >
                      {t("delete")}{" "}
                    </button>
                  </>
                )}
              </div>
            </Row>
          </AvForm>
        </Card>

        <ConfirmationModal
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          accept={confirmationDialog.acceptCallback.bind(this)}
          reject={confirmationDialog.rejectCallback.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locations, loading: loading },
    },
  } = state;

  return { locations, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getAllLocations })(DishTagForm)
);
