import React, { Component } from "react";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  FormGroup,
  Input,
  Label,
  Badge,
  Col,
  Row,
} from "reactstrap";
import { Edit2, Trash2 } from "react-feather";
import { getLocation } from "../../redux/actions/locations";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import LocationService from "../../services/LocationService";
import PaymentService from "../../services/PaymentService";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";

import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import LocationSelectHeaderPackage from "../shared/LocationSelectHeaderPackage";
import toast from "react-hot-toast";
class Paymentlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      generatedQrCode: "",
      clickedQrCodeData: null,
      locationId: null,
      list: "",
      loading: false,
      locationData: null,
      selectLocation: false,
      location: null,
      isOutlet: false,
      status: "active",
    };
  }
  async componentDidMount() {
    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  // async componentDidMount() {
  //   try {
  //     this.setState({ loading: true });
  //     const location = LocationService.getLocation();
  //     console.log("LOCATION", location);
  //     const data = await PaymentService.getAllPayment(location.loc_id);
  //     this.setState({ locationId: location.loc_id, locationData: location });
  //     this.setState({ list: data });
  //     this.setState({ loading: false });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  async refreshData(loc_id, initialize = true, fetchSesionsOrder = true) {
    // this.setState({ loading: initialize, refreshing: true });
    this.setState({
      loading: initialize,
      refreshing: true,
      selectedSessions: new Set(),
    });
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
    }

    if (fetchSesionsOrder) {
      const data = await PaymentService.getAllPayment(loc_id);
      this.setState({ locationId: loc_id });
      this.setState({ list: data });
      this.setState({ loading: false });
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.location)),
      isOutlet: this.props.location.is_outlet,
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }
  generateQrCode(qrCodeData) {
    this.setState({ clickedQrCodeData: qrCodeData });
  }

  async save(values) {
    const dynamicPayment = this.state.selectedRoomId
      ? this.state.selectedRoomId.type
      : values.type;
    // const dynamicStatus = this.state.selectedRoomId
    //   ? this.state.selectedRoomId.status
    //   : values.status;
    const dynamicStatus = values.status || "active";
    const paymentData = {
      type: dynamicPayment,
      status: dynamicStatus,
    };

    const existingPaymentMethod = this.state.list.find(
      (payment) => payment.type === paymentData.type
    );
    if (existingPaymentMethod) {
      toast.error("Payment method already exists!");
      this.setState({ errorMessage: "Payment method already exists!" });
      return;
    } else {
      this.setState({ errorMessage: "" });
    }
    try {
      const response = await PaymentService.savePayment(
        this.state.locationId,
        paymentData
      );
      // console.log("API Response:", response);
      //CALL GET API
      toast.success("payment saved successfully");
      const updatedData = await PaymentService.getAllPayment(
        this.state.locationId
      );
      // console.log("Updated Data:", updatedData);

      this.setState({ list: updatedData });
    } catch (error) {
      console.error("Error saving payment:", error);
    }
    // console.log("Submitted:", values);
  }
  async update() {
    const paymentData = {
      type: this.state.selectedRoomId.type,
      status: this.state.selectedRoomId.status,
      location_id: this.state.location.loc_id,
    };

    try {
      const id = this.state?.selectedRoomId.id;
      console.log(this.props, "update");
      const response = await PaymentService.updatePayment(id, paymentData);
      // console.log("API Response:", response);
      if (response.error) {
        toast.error("same payment method already exist");
      } else {
        toast.success("payment method has been updated successfully");
      }

      //CALL GET API
      const updatedData = await PaymentService.getAllPayment(
        this.state.locationId
      );
      // console.log("Updated Data:", updatedData);
      this.setState({ list: updatedData });
    } catch (error) {
      console.error("Error payment", error);

      if (error.message === "One payment method is active. Cannot update.") {
        toast.error("One payment method should be active");
      } else {
        // Handle other errors here
        toast.error("One payment method should be active");
      }
    }
  }

  async delete(id) {
    const paymentDelete = {
      location_id: this.state.location.loc_id,
    };
    try {
      // debugger;
      const response = await PaymentService.deletePayment(id, paymentDelete);
      console.log("API Response:", response);
      if (response.error) {
        toast.error("One active payment method should axist!");
      } else {
        toast.success("payment deleted successfully");
      }
      // toast.success(" payment deleted successfully");
      //CALL GET API
      const updatedData = await PaymentService.getAllPayment(
        this.state.locationId
      );
      console.log("Updated Data:", updatedData);
      this.setState({ list: updatedData });
    } catch (error) {
      console.error("Error update :", error);
    }
  }
  toggleModal = () => {
    const { isModalOpen } = this.state;
    this.setState({
      isModalOpen: !isModalOpen,
      isEdit: isModalOpen ? false : this.state.isEdit,
      // status: "enabled"
    });
    if (isModalOpen) {
      // this.props.reset('roomForm');
    }
  };
  handleModalSubmit = (values) => {
    values.preventDefault();
    console.log("values", values);
    if (this.state.isEdit) {
      this.update();
    } else {
      this.save(values);
    }
    this.setState({ isEdit: false });
    // this.save(values)
    this.toggleModal();
    // this.props.reset('roomForm');
  };
  handleEditButtonClick = async (paymentId) => {
    console.log(" roomId:", paymentId, this.props);
    try {
      const data = await PaymentService.getPaymentId(paymentId);
      console.log("paymentId response:", data);
      this.setState({
        isModalOpen: true,
        selectedRoomId: data,
        isEdit: true,
      });
    } catch (error) {
      console.error("Error in getRoomByRoomId:", error);
    }
  };

  handleDeleteButtonClick = async (paymentId) => {
    console.log(paymentId, "this");

    this.setState({
      isDeleteConfirmationModalOpen: true,
      paymentDelete: paymentId,
    });
  };
  handel = (value) => {
    console.log("hhh", value);
    this.setState((prev) => ({
      selectedRoomId: {
        ...prev.selectedRoomId,
        type: value,
      },
    }));
  };
  handleStatusChange = (value) => {
    console.log(value, "status");
    this.setState((prev) => ({
      selectedRoomId: {
        ...prev.selectedRoomId,
        status: value,
      },
    }));
  };
  handleDeleteConfirmation = () => {
    const { paymentDelete } = this.state;
    if (paymentDelete) {
      this.delete(paymentDelete);
    }
    this.setState({
      isDeleteConfirmationModalOpen: false,
      paymentDelete: null,
    });
  };
  render() {
    const { locationName, location } = this.props;

    const {
      isEdit,
      list,
      loading,
      isDeleteConfirmationModalOpen,
      selectedRoomId,
      title,
      status,
      locationData,
      selectLocation,
    } = this.state;
    console.log("LOCATIONDATA", selectLocation, location);
    const locationNameRestaurant = location ? location.name : "";
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <>
        <ConfirmationModal
          header="Delete Payment Method"
          title="Are you sure you want to delete this Payment Method?"
          isOpen={isDeleteConfirmationModalOpen}
          accept={this.handleDeleteConfirmation}
          acceptTitle="Yes"
          reject={() =>
            this.setState({
              isDeleteConfirmationModalOpen: false,
              roomIdToDelete: null,
            })
          }
          rejectTitle="No"
        />
        <Col sm="12" className="mb-3">
          <Row>
            <Col sm="12">
              <LocationSelectHeader
                text={location ? location.name : "no location found"}
                onIconClick={this.toggleLocation.bind(this)}
              />
            </Col>
          </Row>
        </Col>
        <Col sm="12" className="mb-3">
          <Row>
            <Col sm="12">
              <strong>Payment Method</strong>
            </Col>
          </Row>
        </Col>

        <div className="d-flex justify-content-end mb-3 ">
          <Button type="button" color="primary" onClick={this.toggleModal}>
            Add New Payment
          </Button>
        </div>
        {console.log("dtaaa", this.state)}
        <Modal
          isOpen={this.state.isModalOpen}
          keyboard={false}
          backdrop="static"
          toggle={this.toggleModal}
        >
          <Form onSubmit={this.handleModalSubmit}>
            <ModalHeader toggle={this.toggleModal}>
              {isEdit ? "Edit Payment" : "Add New Payment"}
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="title">Type</Label>
                <Input
                  required
                  value={isEdit ? selectedRoomId?.type : title}
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Enter Title Name"
                  className="form-control"
                  onChange={(e) => this.handel(e.target.value)}
                />
              </FormGroup>
              {console.log(isEdit ? selectedRoomId.status : status, " show me")}
              <FormGroup>
                <Label for="status">Status</Label>
                <select
                  name={isEdit ? selectedRoomId.status : status}
                  component="select"
                  className="form-control"
                  required
                  // validate={[ValidationHelper.required]}
                  value={isEdit ? selectedRoomId.status : status}
                  onChange={(e) => this.handleStatusChange(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary">
                {isEdit ? "Update Payment Method" : "Save Payment Method"}
              </Button>
              <Button color="danger" onClick={this.toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        {loading ? (
          <SpinnerComponent></SpinnerComponent>
        ) : (
          <Card>
            <Table responsive>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Payment</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list && list.length > 0 ? (
                  list.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.type}</td>
                      <td>
                        <Badge
                          style={{ padding: "5px", width: "5rem" }}
                          id="collapse"
                          className="ml-1 font-small-2 align-top"
                          pill
                          color={
                            item?.status == "active" ? "success" : "danger"
                          }
                        >
                          {item?.status == "active" ? "active" : "inactive"}
                        </Badge>
                      </td>

                      <td style={{ display: "flex", gap: "8px" }}>
                        <Button
                          color="danger"
                          onClick={() => this.handleDeleteButtonClick(item.id)}
                        >
                          <Trash2 size={20} />
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => this.handleEditButtonClick(item.id)}
                        >
                          <Edit2 size={20} />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      style={{ textAlign: "center", paddingTop: "20px" }}
                    >
                      No Payment Method found for {locationNameRestaurant}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  const { sessionslist } = state.orders;
  const { location } = state.locations;
  return {
    sessionslist: sessionslist?.data?.data ? sessionslist?.data?.data : [],
    totalCount: sessionslist?.data?.count ? sessionslist?.data?.count : 0,
    loading: sessionslist.loading || location.loading,
    location: location.data,
  };
}
// export default Paymentlist;
export default withTranslation("global")(
  connect(mapStateToProps, {
    getLocation,
  })(Paymentlist)
);
