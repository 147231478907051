import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import {
  getStaff,
  changeStaffStatus,
  deleteAssignRoleWithAll,
  getAllStaffRoles,
} from "../../redux/actions/staff";
import "../../assets/scss/pages/page-pricing.scss";
import ListRoles from "./ListRoles";
import { Button } from "reactstrap";
import ConfirmationModal from "../shared/forms/ConfirmationModal";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: "",
      staff:"",
      selectableFields:"",
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    await this.props.getAllStaffRoles();
  }

  handleChange = (state) => {
    let locations = [];
    let staff=[];
    for (let i = 0; i < state.selectedRows.length; i++) {
      locations.push(state.selectedRows[i].id); 
    }
    for (let i = 0; i < state.selectedRows.length; i++) {
      staff.push(state.selectedRows[i].staff_id);
    }
    console.log("locations Match",locations)
    this.setState({ locations: locations });
    this.setState({ staff: staff }, () => {
      // Log selected staff details to the console
      console.log("Selected Staff Details:", state.selectedRows);
    });
    this.setState({selectableFields:state.selectedRows})
  };

  async updateStatus() {
   
    if (this.state.locations.length > 0) {
      let shouldUpdateStatus=true;
      for (const staff of this.state.selectableFields) {
        const staffGroupName = staff.StaffGroup.staff_group_name;
        const userRole = localStorage.getItem("tableordercart_admin_role");
  
        console.log("Staff Group Name:", staffGroupName);
        console.log("User Role:", userRole);
  
    
        if (staffGroupName === "Administrator" && userRole === "Administrator") {
          shouldUpdateStatus = false;
          this.showErrorDialog(
            null,
            this.props.t("You cannot delete an Administrator"),
            this.props.t("warning")
          );
          break; 
        }
      }
      if (shouldUpdateStatus) {
        await this.showConfirmationDialog(
        async () => {
          let deleteRoles = {
            staff_id: "",
            locations: this.state.locations,
          };
          await this.props.deleteAssignRoleWithAll(deleteRoles);
        },
        null,
        this.props.t("are-you-sure-you-want-to-delete-of-this-roles"),
        this.props.t("roles")
      );
      }
    } else {
      this.showErrorDialog(
        null,
        this.props.t("please-select-atleast-one-role-for-delete"),
        this.props.t("warning")
      );
    }
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }

  assignRole() {
    this.props.history.push("roles/new");
  }

  render() {
    const { t } = this.props;
    const {
      props: { staff, loading },
    } = this;
    const {
      state: { confirmationDialog, errorDialog },
    } = this;
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div id="pricing-table">
          <Button
            className="mb-1"
            color="primary"
            size="md"
            onClick={() => this.assignRole()}
          >
            <span className="ml-1 mr-1 d-md-inline">{t("new")}</span>
          </Button>
          <Button
            className="ml-1 mb-1"
            color="danger"
            size="md"
            onClick={() => this.updateStatus()}
          >
            <span className="ml-1 mr-1 d-md-inline"> {t("delete")} </span>
          </Button>
          {!loading && staff ? (
            <ListRoles
              staff={staff}
              handleChange={this.handleChange.bind(this)}
            />
          ) : null}
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t("cancel")}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={t("ok")}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    staff: {
      allstaff: { data: staff, loading: loading },
    },
  } = state;

  return { staff, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getStaff,
    changeStaffStatus,
    deleteAssignRoleWithAll,
    getAllStaffRoles,
  })(Roles)
);
