import React, {useEffect, useState} from "react"
import {Box, Button, CircularProgress, IconButton, Stack, Typography} from "@mui/material";
import {green, orange, red} from "@mui/material/colors";
import CONSTANTS from "../../constants";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LocationService from "../../services/LocationService";
import {getALlLocationReservations} from "../../redux/actions/reservations";
import {getLocation} from "../../redux/actions/locations";
import {CancelOutlined, DeleteForeverOutlined} from "@mui/icons-material";
import {deleteReservation, updateReservationStatus} from "../../services/ReservationServices";
import toast from "react-hot-toast";
import ReservationModal from "./reservationModal";

const Reservations = () => {
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global");
    const [location, setLocation] = useState(null);
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [reservation, setReservation] = useState(null);
    const [openReservationModal, setOpenReservationModal] = useState(false);

    const reservations = useSelector(state => state.reservationsReducers.reservationsReducer)
    const reduxLocation = useSelector(state => state.locations.location)

    const columns = [

        {
            name: t("#"),
            width: '5rem',
            selector: (row) => row.reservation_id,

            format: (val) => {
                return val.reservation_id ?
                    <IconButton onClick={()=> handleDeleteReservation(val.reservation_id)} size={'small'}>
                        <DeleteForeverOutlined color='error' sx={{height: '2rem', width: '2rem'}}/>
                    </IconButton>
                    : t("N/A");
            },
        },

        {
            name: t("name"),

            selector: (row) => row.name,
            format: (val) => {
                return val.name ? val.name : t("not-available");
            },
            sortable: true,
        },
        {
            name: t("email"),

            selector: (row) => row.email,
            width: "14%",
            format: (val) => {
                return val.email ? val.email : t("N/A");
            },
            sortable: true,
        },
        {
            name: t("phone"),

            selector: (row) => row.number,

            format: (val) => {
                return val.number ? val.number : t("N/A");
            },
        },

        {
            name: t("date"),

            selector: (row) => row.date,

            format: (val) => {
                return val.date ? val.date : t("N/A");
            },
        },

        {
            name: t("guest(s)"),

            selector: (row) => row.user_count,

            format: (val) => {
                return val.user_count ? val.user_count : t("N/A");
            },
        },

        {
            name: t("status"),
            width: '10rem',
            selector: (row) => row.status,

            format: (val) => {
                return val.status ?
                    <Typography variant="body2" style={{textTransform: 'capitalize', fontSize: '0.8rem', color: 'white', backgroundColor: (val.status === 'accepted' ? green[500] : val.status === 'pending' ? orange[500] : red[500]), borderRadius: '15px', padding: '0.3rem 1rem'}}>{val.status}</Typography>
                    : t("N/A");
            }
        },

        {
            name: t('actions'),
            selector: (row) => row,
            format: (val) => {
                return (
                    <Stack direction='row' columnGap={'0.25rem'}>
                        <IconButton disabled={val.status === 'rejected'} size={'small'} onClick={()=> handleCancelStatus(val.reservation_id)}>
                            <CancelOutlined sx={{height: '2rem', width: '2rem'}} color={'error'}/>
                        </IconButton>
                        <IconButton disabled={val.status === 'accepted'} size={'small'} onClick={()=> handleAcceptStatus(val.reservation_id)}>
                            <TaskAltIcon sx={{color: green[500], height: '2rem', width: '2rem'}} />
                        </IconButton>
                    </Stack>
                );
            },
        },
    ];

    const handleCancelStatus = async (reservation_id) => {
        const response = await updateReservationStatus('rejected', reservation_id);
        if (response.error) {
            toast.error(response.message);
        } else {
            toast.success('Reservation Canceled Successfully');
            await refreshData(location.loc_id)
        }
    }

    const handleAcceptStatus = async (reservation_id) => {
        const response = await updateReservationStatus('accepted', reservation_id);
        if (response.error) {
            toast.error(response.message);
        } else {
            toast.success('Reservation Accepted Successfully');
            await refreshData(location.loc_id)
        }
    }

    const handleDeleteReservation = async (reservation_id) => {
        const response = await deleteReservation(reservation_id);
        if (response.error) {
            toast.error(response.message);
        } else {
            toast.success('Reservation Deleted Successfully');
            await refreshData(location.loc_id)
        }
    }

    const reservationsData = {
        columns: columns,
        data: (reservations && reservations.data && reservations.data.data) ? reservations.data.data : [],
        print: false,
        export: false
    }

    const refreshData = async (loc_id, updateLocation = true) => {
        let loc = location;
        if (updateLocation) {
            loc = await LocationService.getLocationFromServer(loc_id);
            await dispatch(getLocation(loc_id));
        }
        await dispatch(await getALlLocationReservations());
        const locationData = {
            loc_id: loc.loc_id,
            location_status: loc.location_status,
            name: loc.name,
        };
        setLocation(locationData);
    }

    const loadComponent = async () => {
        const lang = localStorage.getItem("lang");
        i18n.changeLanguage(lang);

        setLoading(true);
        try {
            const location = LocationService.getLocation();

            if (location) {
                await refreshData(location.loc_id);
            } else {
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
        }
    }

    useEffect ( () => {
        loadComponent()
    }, []);

    const editReservation = (reservation) => {
        history.push({
            pathname: `${CONSTANTS.BASE_URL}reservation/edit`,
            state: {reservation: reservation}
        })
    }

    const handleRowClicked = (reservation) => {
        setReservation(reservation);
        setOpenReservationModal(true);
    }

    return(
        <>
            {(reservations.loading || loading || reduxLocation.loading) ?
                <CircularProgress/> :
                <>
                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                        <Button variant={'outlined'} sx={{
                            borderColor: green[400],
                            color: green[400],
                            '&:hover': {borderColor: green[500], color: green[500]}
                        }}>{reduxLocation.data.name}</Button>
                        <Button variant={'outlined'} sx={{
                            borderColor: green[400],
                            color: green[400],
                            '&:hover': {borderColor: green[500], color: green[500]}
                        }} onClick={() =>
                            history.push({
                                    pathname: `${CONSTANTS.BASE_URL}reservation/new`,
                                    state: {location: location}
                                }
                            )}>Add Reservation</Button>
                    </Stack>
                    <Box sx={{width: '100%', marginTop: '1rem'}}>
                        {!reservations || !reservations.data || !reservations.data.data || reservations.data.length <= 0 ?
                            <Typography variant='body2' sx={{fontSize: '1.1rem', fontWeight: '600'}} >No Reservations Found</Typography> :
                            (
                                <DataTableExtensions {...reservationsData}>
                                    <DataTable
                                        noHeader
                                        pagination
                                        paginationRowsPerPageOptions={[25, 50, 100]}
                                        paginationPerPage={50}
                                        columns={columns}
                                        data={reservations.data.data}
                                        onRowClicked={(row, e) => handleRowClicked(row)}
                                    />
                                </DataTableExtensions>
                            )}
                    </Box>
                </>
            }
            {openReservationModal && <ReservationModal reservation={reservation} openReservation={openReservationModal} setOpenReservation={setOpenReservationModal} />}
        </>
    )
}

export default Reservations;