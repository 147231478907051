import React, { useEffect, useState } from "react";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvField,
} from "availity-reactstrap-validation";
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import Select from "react-select";
import { getAllPackageProductCategory } from "../../redux/actions/packageproductcategory";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import PackageProductCategoryService from "../../services/PackageProductCategoryService";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import CONSTANTS from "../../constants";

import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PackageSessionForm = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [selectedCategoryTag, setSelectedCategoryTag] = useState("");
  const [description, setDescription] = useState("");
  const [multipleQuantity, setMultipleQuantity] = useState(0);
  const [physicalSupport, setPhysicalSupport] = useState(0);
  const [pricePerType, setPricePerType] = useState("license");
  const [selectedPackageProductId, setSelectedPackageProductId] = useState("");

  // Validation error states
  const [titleError, setTitleError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [selectedCategoryTagError, setSelectedCategoryTagError] = useState("");
  const [selectedPackageProductError, setSelectedPackageProductError] =
    useState("");
  const [pricePerTypeError, setpricePerTypeError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const history = useHistory();
  useEffect(() => {
    const data = async () => {
      await props.getAllPackageProductCategory();
    };
    data();
  }, []);
  const { data: packageproductcategory, loading } = useSelector(
    (store) => store.packageproductcategory.allProductPackageCategoryItems
  );
  console.log("packageproductcategory", packageproductcategory);

  if (loading) return <SpinnerComponent />;
  const { package_categories, package_products, priceType } =
    packageproductcategory;

  const priceTypeOptions = Object.keys(priceType).map((key) => ({
    value: key,
    label: priceType[key],
  }));

  const packageCategoriesOptions = package_categories.map((category) => ({
    value: category.tag,
    label: category.name,
  }));

  const filteredPackageProducts = package_products.filter(
    (product) => product.category_tag === selectedCategoryTag
  );

  const packageProductsOptions = filteredPackageProducts.map((product) => ({
    value: product.id,
    label: product.name,
  }));
  const handlePricePerTypeChange = (selectedOption) => {
    setPricePerType(selectedOption.value);
    setpricePerTypeError("");
  };

  const handleSelectedPackageProductChange = (selectedOption) => {
    setSelectedPackageProductId(selectedOption.value);
    setSelectedPackageProductError("");
  };

  const handleSubmit = async () => {
    let isValid = true;
    if (!title) {
      setTitleError(t("title-is-required"));
      isValid = false;
    }
    if (!price) {
      setPriceError(t("price-is-required"));
      isValid = false;
    }
    if (!selectedCategoryTag) {
      setSelectedCategoryTagError(t("category-is-required"));
      isValid = false;
    }

    if (!selectedPackageProductId) {
      setSelectedPackageProductError(t("package-product-is-required"));
      isValid = false;
    }
    if (!description) {
      setDescriptionError(t("description-is-required"));
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    try {
      const formData = {
        title,
        price,
        selectedCategoryTag,
        description,
        multipleQuantity,
        physicalSupport,
        pricePerType,
        selectedPackageProductId,
      };

      const response =
        await PackageProductCategoryService.createPackageProductCategory(
          formData
        );
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: t("package-product-created-successfully"),
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          history.push(`${CONSTANTS.BASE_URL}packages`);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: t(
            "an-error-occurred-while-creating-the-package-product-category"
          ),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: `An error occurred: ${error.message}`,
      });
    }
  };

  return (
    <div>
      <div className="location">
        <Card>
          <CardHeader className="flex-column align-items-start"></CardHeader>
          <CardBody>
            <AvForm>
              <Row>
                <Col md="6" sm="6">
                  <AvGroup>
                    <Label> {t("title")} </Label>
                    <AvInput
                      type="text"
                      name="title"
                      value={title}
                      // onChange={(e) => setTitle(e.target.value)

                      // }
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setTitleError(""); // Clear error when input changes
                      }}
                    />
                    {titleError && (
                      <div className="text-danger">{titleError}</div>
                    )}
                  </AvGroup>
                </Col>

                <Col md="6" sm="6">
                  <AvGroup>
                    <Label> {t("categories")} </Label>
                    <Select
                      name="packageCategories"
                      options={packageCategoriesOptions}
                      className="React"
                      classNamePrefix="select"
                      onChange={(selectedOption) => {
                        setSelectedCategoryTag(selectedOption.value);
                        setSelectedCategoryTagError("");
                      }}
                    />
                    {selectedCategoryTagError && (
                      <div className="text-danger">
                        {selectedCategoryTagError}
                      </div>
                    )}
                  </AvGroup>
                </Col>
                <Col md="6" sm="6">
                  <AvGroup>
                    <Label> {t("package-product-options")} </Label>

                    <Select
                      name="selectedPackageProduct"
                      options={packageProductsOptions}
                      className="React"
                      classNamePrefix="select"
                      onChange={handleSelectedPackageProductChange}
                    />
                    {selectedPackageProductError && (
                      <div className="text-danger">
                        {selectedPackageProductError}
                      </div>
                    )}
                  </AvGroup>
                </Col>
                <Col md="6" sm="6">
                  <AvGroup>
                    <Label> {t("price-0")} </Label>
                    <AvInput
                      type="text"
                      name="price"
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                        setPriceError("");
                      }}
                    />
                    {priceError && (
                      <div className="text-danger">{priceError}</div>
                    )}
                  </AvGroup>
                </Col>

                {/* <Col md="6" sm="6">
                  <AvGroup>
                    <Label> Price type </Label>
                    <Select
                      name="pricePerType"
                      options={priceTypeOptions}
                      className="React"
                      classNamePrefix="select"
                      onChange={handlePricePerTypeChange}
                    />
                    {pricePerTypeError && (
                      <div className="text-danger">{pricePerTypeError}</div>
                    )}
                  </AvGroup>
                </Col> */}
                <Col md="12" sm="12">
                  <AvGroup>
                    <Label> {t("description")} </Label>
                    <AvInput
                      type="text"
                      name="description"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        setDescriptionError("");
                      }}
                    />
                    {descriptionError && (
                      <div className="text-danger">{descriptionError}</div>
                    )}
                  </AvGroup>
                </Col>
                <Col md="6" sm="6">
                  <AvGroup>
                    <div className="d-flex align-items-center">
                      <Label className="mr-2">{t("multiple-quantity")}</Label>
                      <div className="custom-control custom-checkbox">
                        <AvInput
                          type="checkbox"
                          className="custom-control-input"
                          id="multipleQuantityCheckbox"
                          name="multipleQuantity"
                          checked={multipleQuantity === 1}
                          onChange={() => {
                            const newValue = multipleQuantity === 1 ? 0 : 1;
                            setMultipleQuantity(newValue);
                            console.log("Multiple Quantity:", newValue);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="multipleQuantityCheckbox"
                        ></label>
                      </div>
                    </div>
                  </AvGroup>
                </Col>

                {/* <Col md="6" sm="6">
                  <AvGroup>
                    <div className="d-flex align-items-center">
                      <Label className="mr-2">Physical Item</Label>
                      <div className="custom-control custom-checkbox">
                        <AvInput
                          type="checkbox"
                          className="custom-control-input"
                          id="physicalItemCheckbox"
                          name="physicalItem"
                          checked={physicalSupport === 1}
                          onChange={() => {
                            const newValue = physicalSupport === 1 ? 0 : 1;
                            setPhysicalSupport(newValue);
                            console.log('Physical Item:', newValue);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="physicalItemCheckbox"
                        ></label>
                      </div>
                    </div>
                  </AvGroup>
                </Col> */}
              </Row>

              <Button color="primary" size="md" onClick={handleSubmit}>
                <span className="ml-1 mr-1 d-md-inline ">{t("submit")}</span>
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const {
    packageproductcategory: {
      allProductPackageCategoryItems: {
        data: packageproductcategory,
        loading: loading,
      },
    },
  } = state;

  return { packageproductcategory, loading: loading };
}

export default connect(mapStateToProps, {
  getAllPackageProductCategory,
})(PackageSessionForm);
