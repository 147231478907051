import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import TimeHelper from '../../helpers/TimeHelper';
import { Badge, Button } from 'reactstrap';
import { Edit2 } from 'react-feather';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class ResponseList extends Component {
  componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }

  columns = [
    {
      name: this.props.t('question'),
      // selector: "LocationSurveyQuestion.question",
      selector: (row) => row.LocationSurveyQuestion.question,
      format: (val) => {
        return val?.LocationSurveyQuestion?.question;
      },
    },
    {
      name: this.props.t('type'),
      // selector: 'LocationSurveyQuestion.type',
      selector: (row) => row.LocationSurveyQuestion.type,
      format: (val) => {
        return val?.LocationSurveyQuestion?.type;
      },
    },
    {
      name: this.props.t('response'),
      // selector: 'response',
      selector: (row) => row.response,
      format: (val) => {
        return val?.response;
      },
    },
  ];

  renderSurveyResponseList = (data) => {
    if (!data || data.length === 0)
      return <h1>{this.props.t('no-user-survey-found')}</h1>;
    if (!this.props?.hideSessionbtn) {
      this.columns.push({
        name: this.props.t('created'),
        // selector: 'createdAt',
        selector: (row) => row.createdAt,
        format: (val) => {
          return val?.createdAt
            ? TimeHelper.toFormattedDateTimeDB(val.createdAt)
            : this.props.t('not-available-0');
        },
      });
      this.columns.push({
        name: this.props.t('action'),
        // selector: 'session_id',
        selector: (row) => row.session_id,
        format: (val) => {
          return (
            <Button
              outline
              className="btn-icon btn-primary"
              color="success"
              onClick={(e) => {
                e.stopPropagation();
                this.props.goToOrderSession(val?.session_id);
              }}
            >
              {this.props.t('view-session')}
            </Button>
          );
        },
      });
    }
    return (
      <>
        <DataTable
          noHeader
          paginationRowsPerPageOptions={[25, 50, 100]}
          paginationPerPage={50}
          columns={this.columns}
          data={data}
          rowCallback={() => {
            console.log('Row callback');
          }}
        />
      </>
    );
  };

  render() {
    const { t } = this.props;
    return this.renderSurveyResponseList(this.props.surveyResponse);
  }
}

export default withTranslation('global')(ResponseList);
