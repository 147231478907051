import React, {useState} from 'react';
import {Stack, IconButton, Tooltip, Switch, FormControlLabel} from "@mui/material";
import {Add, Edit} from "@mui/icons-material";
import AddCustomerModal from "../../../mainComponents/addCustomerModal";
import UserSelectionComponent from "./selectorsComponents/userSelectionComponent";
import UpdateCustomerModal from "../../../mainComponents/updateCustomerModal";
import {useDispatch, useSelector} from "react-redux";
import {setCustomerType} from "../../../../../../redux/actions/pos/customer";
import SnackBar from "../../../mainComponents/snackBar";

const SelectorsComponent = () => {
    const dispatch = useDispatch()
    const customerType = useSelector(state => state.pos.customerType);
    const customerData = useSelector(state => state.pos.customerData);
    const [addCustomerModal, setAddCustomerModal] = useState(false)
    const [editCustomerModal, setEditCustomerModal] = useState(false)
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: '',
        severity: ''
    })

    const handleOpenAddCustomerModal = () => {
        setAddCustomerModal(true);
    }

    const handleOpenUpdateCustomerModal = () => {
        if (customerData && customerData.id) {
            setEditCustomerModal(true)
        } else {
            setSnackbarParams({
                open: true,
                message: "Please, select a customer",
                severity: 'error'
            })
        }
    }

    const handleSwitchChange = async (checked) => {
        if (checked) {
            await dispatch(setCustomerType("walk-in"))
        } else {
            await dispatch(setCustomerType("regular"))
        }
    }

    return(
        <>
            <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem'}}>
                <Stack direction='row' sx={{alignItems: 'center', flexGrow: 1, columnGap: '0.5rem'}}>
                    {/*<Select*/}
                    {/*    size='small'*/}
                    {/*    fullWidth*/}
                    {/*>*/}
                    {/*</Select>*/}
                    <UserSelectionComponent />
                </Stack>
                <Stack direction='row' sx={{alignItems: 'center', width: '180px', marginLeft: '0.5rem'}}>
                    <FormControlLabel control={<Switch checked={customerType === 'walk-in'} size='small' color={'success'} onChange={(e, checked) => handleSwitchChange(checked)}/>} label="Walk In" />
                    <Tooltip title={'Edit Customer'}>
                        <IconButton onClick={()=> handleOpenUpdateCustomerModal()}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Add Customer'}>
                        <IconButton onClick={()=> handleOpenAddCustomerModal()}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
            <AddCustomerModal openModal={addCustomerModal} setOpenModal={setAddCustomerModal} setSnackbarParams={setSnackbarParams}/>
            <UpdateCustomerModal openModal={editCustomerModal} setOpenModal={setEditCustomerModal} setSnackbarParams={setSnackbarParams}/>
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </>
    )
}

export default SelectorsComponent;