import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../helpers/TimeHelper";
import { Badge, Button, Card, Col, Input, Label, Row } from "reactstrap";
import { Edit2, Star, Trash2 } from "react-feather";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QuestionnaireList = ({
  t,
  questionsListArray,
  changeStatus,
  editQuestion,
  deleteQuestion,
}) => {
  useEffect(() => {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  const columns = [
    {
      name: t("type"),
      selector: (row) => row.type,
      format: (val) => {
        return val.type ? val.type : t("no-type-found");
      },
      sortable: true,
    },
    {
      name: t("question"),
      selector: (row) => row.question,
      format: (val) => {
        return val.question;
      },
      sortable: true,
    },
    {
      name: t("status"),
      selector: (row) => row.status,
      format: (val) => {
        return val.status && val.status === "enabled" ? (
          <Badge
            pill
            color="success cursor-pointer"
            style={{ padding: "7px" }}
            onClick={() =>
              changeStatus(
                val,
                "Disabled Question",
                "Are you sure to disable this question",
                "disabled"
              )
            }
          >
            {val.status}
          </Badge>
        ) : (
          <Badge
            pill
            color="danger cursor-pointer"
            onClick={() =>
              changeStatus(
                val,
                "Enable Question",
                "Are you sure to enable this question",
                "enabled"
              )
            }
          >
            {val.status}
          </Badge>
        );
      },
    },
    {
      name: t("created"),
      selector: (row) => row.createdAt,
      format: (val) => {
        return val.createdAt
          ? TimeHelper.toFormattedDateTimeDB(val.createdAt)
          : t("not-available-0");
      },
      sortable: true,
    },
    {
      name: t("action"),
      selector: (row) => row.question_id,
      format: (val) => (
        <>
          <Button
            outline
            className="btn-icon bg-white"
            color="primary"
            style={{ marginRight: "4px" }}
            onClick={(e) => {
              e.stopPropagation();
              editQuestion(val);
            }}
          >
            <Edit2 color="var(--primary)" className="ml-0 p-0" size={10} />
          </Button>

          <Button
            outline
            className="btn-icon bg-white"
            color="danger"
            style={{ marginRight: "5px" }}
            onClick={() => deleteQuestion(val.question_id)}
          >
            <Trash2 color="var(--danger)" className="ml-0 p-0" size={10} />
          </Button>
        </>
      ),
    },
  ];

  // const renderSurveyQuestionList = (data) => {
  //   if (!data || data.length === 0)
  //     return <h1>{t("no-app-notification-found")}</h1>;
  //   const tableData = {
  //     columns: columns,
  //     data: data,
  //     print: false,
  //     export: false,
  //   };
  const renderSurveyQuestionList = (data) => {
    if (!data || data.length === 0)
      return <h1>{t("no-app-notification-found")}</h1>;

    const filteredData = data.filter((item) => item.type !== "Link");

    const tableData = {
      columns: columns,
      data: filteredData,
      print: false,
      export: false,
    };

    return (
      <>
        <Row>
          <Col md="8">
            <DataTableExtensions {...tableData}>
              <DataTable
                noHeader
                // pagination
                paginationRowsPerPageOptions={[25, 50, 100]}
                paginationPerPage={50}
                columns={columns}
                data={data}
              />
            </DataTableExtensions>
          </Col>
        </Row>
      </>
    );
  };

  return renderSurveyQuestionList(questionsListArray);
};

export default withTranslation("global")(QuestionnaireList);
