import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getAllUpcomingEvents,
  deleteUpcomingEventById,
  createUpcomingEvent,
  changeUpcomingEventStatus,
};

async function getAllUpcomingEvents(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.EVENTS.DEFAULT(locationId)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );

  if (response) return response;
  throw new Error("Could not fetch currencies");
}

export async function createUpcomingEvent(title, description, loc_id) {
  const { token } = LoginService.getToken();
  const response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.EVENTS.CREATEEVENT}`,
    {
      method: "POST",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      body: JSON.stringify({
        event_title: title,
        event_desc: description,
        loc_id: loc_id,
        event_status: false,
      }),
    }
  );
  if (response.ok) {
    const responseData = await response.json();
    return responseData;
  } else {
    throw new Error("Failed to save data");
  }
}

async function deleteUpcomingEventById(eventIds) {
  let eventId = { eventIds }; // Creating an object with the eventId
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.EVENTS.DELETEEVENT}`,
    {
      method: "POST",
      body: JSON.stringify(eventId),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not delete event");
}

export async function changeUpcomingEventStatus(id, newStatus) {
  const { token } = LoginService.getToken();
  const response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.EVENTS.CHANGESTATUS(id)}`,
    {
      method: "POST",
      body: JSON.stringify({ event_status: newStatus }),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  const responseData = await response.json();
  if (response.ok) {
    return responseData;
  }
  throw new Error("Failed to update event status");
}
