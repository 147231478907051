import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getAllProductPricingList,
};

async function getAllProductPricingList() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.GETPRODUCTPRICINGLIST}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );

  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch Product Pricing List');
}
