import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import TimeHelper from '../../helpers/TimeHelper';
import ViewHelper from '../../helpers/ViewHelper';
import Checkbox from '../@vuexy/checkbox/CheckboxesVuexy';
import { Check, Edit2 } from 'react-feather';
import { Button } from 'reactstrap';
import ImageHelper from '../../helpers/ImageHelper';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const ListModifiers = (props) => {

  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const editCurrency = (id) => {
    props.editCurrency(id);
  };

  const columns = [
    {
      name: 'ID',
      // selector: 'option_id',
      selector: (row) => row.option_id,
      sortable: true,
    },
    // {
    //   name: 'Edit',
    //   selector: 'option_id',
    //   format: (val) => {
    //     return <Button outline className='btn-icon' color='primary' onClick={() => editCurrency(val.staff_id)}><Edit2 color='var(--primary)' className='ml-0 p-0' size={10} /></Button>

    //   }
    // },
    {
      name: t('option-name'),
      // selector: 'option_name',
      selector: (row) => row.option_name,
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.option_name ? val.option_name : t('not-available');
      },
    },
    {
      name: t('display-type'),
      // selector: 'display_type',
      selector: (row) => row.display_type,
      minWidth: '300px',
      format: (val) => {
        return val.display_type ? val.display_type : t('not-available');
      },
    },
    {
      name: t('image'),
      minWidth: '200px',
      // selector: 'review_photo',
      selector: (row) => row.review_photo,
      format: (val) => {
        return val.review_photo ? (
          <img src={ImageHelper.getImageURL(val.review_photo)} alt="imgs" />
        ) : (
          t('not-available')
        );
      },
    },
  ];

  const renderModifiers = (modifiers, handleChange) => {
    if (!modifiers || modifiers.length === 0)
      return <h1>{t('no-app-modifiers-found')}</h1>;
    const tableData = {
      columns: columns,
      data: modifiers,
      print: false,
      export: false,
    };
    return (
      <DataTableExtensions {...tableData}>
        <DataTable
          noHeader
          pagination
          paginationRowsPerPageOptions={[25, 50, 100]}
          paginationPerPage={50}
          // selectableRows
          // selectableRowsComponent={Checkbox}
          // selectableRowsComponentProps={{
          //   color: 'primary',
          //   icon: <Check className="vx-icon" size={12} />,
          //   label: '',
          //   size: 'sm',
          // }}
          onSelectedRowsChange={handleChange}
          columns={columns}
          data={modifiers}
        />
      </DataTableExtensions>
    );
  };
  return renderModifiers(props.modifiers, props.handleChange);
};

export default ListModifiers;
