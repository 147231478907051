import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../helpers/TimeHelper";
import { Badge, Button, Card, Col, Input, Label, Row } from "reactstrap";
import { Edit2, Star, Trash2 } from "react-feather";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "qrcode.react";

const QrList = ({
  t,
  questionsListArray,
  changeStatus,
  editQr,
  deleteQuestion,
}) => {
  useEffect(() => {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  const columns = [
    {
      name: t("title"),
      selector: (row) => row.title,
      format: (val) => {
        return val.title ? val.title : t("no-title-found");
      },
      sortable: true,
    },

    {
      name: t("qr-code"),
      selector: (row) => row.question,
      sortable: true,
      padding: "10px",
      format: (val) => {
        const qrCodeUrl = val.question;
        console.log("QRCODEURL", qrCodeUrl);

        const qrCodeContainerStyle = {
          padding: "3px",
          paddingBottom: "0.5px",
          // opacity: val.is_table_available ? 1 : 0.5,
        };

        return (
          <>
            {qrCodeUrl != null && qrCodeUrl !== "" ? (
              <Button
                style={qrCodeContainerStyle}
                outline
                className="btn-icon"
                color="primary"
              >
                <QRCode value={qrCodeUrl} size={35} />
              </Button>
            ) : null}
          </>
        );
      },
    },
    {
      name: t("status"),
      selector: (row) => row.status,
      format: (val) => {
        return val.status && val.status === "enabled" ? (
          <Badge
            pill
            color="success cursor-pointer"
            style={{ padding: "7px" }}
            onClick={() =>
              changeStatus(
                val,
                "Disabled Question",
                "Are you sure to disable this question",
                "disabled"
              )
            }
          >
            {val.status}
          </Badge>
        ) : (
          <Badge
            pill
            color="danger cursor-pointer"
            onClick={() =>
              changeStatus(
                val,
                "Enable Question",
                "Are you sure to enable this question",
                "enabled"
              )
            }
          >
            {val.status}
          </Badge>
        );
      },
    },
    {
      name: t("created"),
      selector: (row) => row.createdAt,
      format: (val) => {
        return val.createdAt
          ? TimeHelper.toFormattedDateTimeDB(val.createdAt)
          : t("not-available-0");
      },
      sortable: true,
    },
    {
      name: t("action"),
      selector: (row) => row.question_id,
      format: (val) => (
        <>
          <Button
            outline
            className="btn-icon bg-white"
            color="primary"
            style={{ marginRight: "4px" }}
            onClick={(e) => {
              e.stopPropagation();
              editQr(val);
            }}
          >
            <Edit2 color="var(--primary)" className="ml-0 p-0" size={10} />
          </Button>

          <Button
            outline
            className="btn-icon bg-white"
            color="danger"
            style={{ marginRight: "5px" }}
            onClick={() => deleteQuestion(val.question_id)}
          >
            <Trash2 color="var(--danger)" className="ml-0 p-0" size={10} />
          </Button>
        </>
      ),
    },
  ];

  const renderSurveyQuestionList = (data) => {
    console.log("DATA", data);
    if (!data || data.length === 0)
      return <h1>{t("no-app-notification-found")}</h1>;

    const filteredData = data.filter((item) => item.type === "Link");

    const tableData = {
      columns: columns,
      data: filteredData,
      print: false,
      export: false,
    };

    return (
      <>
        <Row>
          <Col md="8">
            <DataTableExtensions {...tableData}>
              <DataTable
                noHeader
                paginationRowsPerPageOptions={[25, 50, 100]}
                paginationPerPage={50}
                columns={columns}
                data={data}
              />
            </DataTableExtensions>
          </Col>
          <Col md="4">
            <div className="row mb-4">
              <Col md={"12"}>
                <div
                  style={{
                    background: "black",
                    borderRadius: "30px",
                    padding: "25px",
                    display: "flex",
                    margin: "auto",
                  }}
                  className="col-12"
                >
                  <Card
                    className="p-1"
                    style={{
                      backgroundColor: "#F2F2F2",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "green",
                        color: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          margin: "auto",
                          padding: "5px",
                          textAlign: "center",
                        }}
                      >
                        Survey
                      </span>
                    </div>

                    <Label style={{ marginTop: "30px" }}>EXPERIENCE</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="exampleText"
                      rows="3"
                      placeholder={t("Comments")}
                    />
                    <strong style={{ marginTop: "20px" }}>Services</strong>
                    <div style={{ margin: "auto", marginTop: "10px" }}>
                      <Star size={30} />
                      <Star size={30} style={{ marginLeft: "10px" }} />
                      <Star size={30} style={{ marginLeft: "10px" }} />
                      <Star size={30} style={{ marginLeft: "10px" }} />
                      <Star size={30} style={{ marginLeft: "10px" }} />
                    </div>

                    <strong style={{ marginTop: "20px" }}>How was Food?</strong>
                    <div style={{ marginTop: "10px" }}>
                      <Input
                        type="text"
                        name="text"
                        id="exampleText"
                        // rows="3"
                        placeholder={t("Questionnaire")}
                      />

                      <br></br>
                      <div className="d-flex justify-content-center">
                        {[...Array(3)].map(
                          (
                            _,
                            index // Map over an array to render 3 QR code buttons
                          ) => (
                            <Button
                              className="btn-icon mr-2"
                              color="white"
                              key={index}
                            >
                              {" "}
                              <QRCode value={"test"} size={35} />
                            </Button>
                          )
                        )}
                      </div>
                      {/* <br></br>
                      <br></br>
                      <br></br> */}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "green",
                        color: "white",
                        borderRadius: "10px",
                        padding: "5px",
                        width: "100%",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      <span
                        style={{
                          margin: "auto",
                          padding: "5px",
                          textAlign: "center",
                        }}
                      >
                        Submit Survey
                      </span>
                    </div>
                  </Card>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return renderSurveyQuestionList(questionsListArray);
};

export default withTranslation("global")(QrList);
