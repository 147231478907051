import React, { useEffect, useState } from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Media,
  Badge,
  Container,
  Col,
} from "reactstrap";
import numeral from "numeral";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ViewHelper from "../../helpers/ViewHelper";
import {
  Star,
  StopCircle,
  Edit,
  Play,
  Pause,
  Image,
  ShieldOff,
  Check,
  Trash,
} from "react-feather";

import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import ImageHelper from "../../helpers/ImageHelper";
import ParamHelper from "../../helpers/ParamHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faGlassCheers,
  faBirthdayCake,
} from "@fortawesome/free-solid-svg-icons";

// datatable
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { useTranslation } from "react-i18next";
import dummyFoodImage from "../../assets/img/dummy-food.png";

// get data from array
const getItems = (listItems, location) =>
    listItems.map((k) => {
      let content = (
          <span>
        {" "}
            {ViewHelper.toCurrency(
                k?.menu_price,
                location?.Currency?.currency_code,
                location?.decimal_places
            )}
            {k?.overall_rating ? (
                <span>
            {" "}
                  <StopCircle size={2} fill="white" className="" />{" "}
                  {" "}
                  <StopCircle size={2} fill="white" className="" />{" "}
                  <Star
                      fill="gold"
                      color="gold"
                      size={10}
                      className="align-baseline"
                  />{" "}
                  {ViewHelper.toDecimal(k.overall_rating, 1)}{" "}
          </span>
            ) : null}
      </span>
      );
      return {
        id: `${k.menu_id}`,
        img: ImageHelper.getImageURL(k.thumb_path, "?func=crop&w=50&h=50"),
        name: k.menu_name,
        content: content,
        status: k.menu_status,
        ...k,
      };
    });

const DnDListView = (props) => {
  const [items, setItems] = useState([]);

  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const {
    hideEditableButtons,
    updateSelectedModifiers,
    selectedIds,
    isDragDisabled: dragDisableProp,
    categorySelected,
    toggleStatus,
    updateMenuPriority,
    location,
    menu,
    isOutlet,
    deleteMenu,
    deleting,
  } = props;

  const isDragDisabled = dragDisableProp || isOutlet;

  useEffect(() => {
    const itemsCopy = getItems(menu, location);
    setItems(itemsCopy);
  }, [menu]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const itemsCopy = ViewHelper.reorder(
        items,
        result.source.index,
        result.destination.index,
        true
    );
    setItems(itemsCopy);
    updateMenuPriority(
        location.loc_id,
        ParamHelper.getMenusForBulkUpdate(itemsCopy)
    );
  };

  const delMenu = (item) => {
    if (deleting) return;
    deleteMenu(item);
  };
  // const calculateTotalPrice = (menuPrice, taxPercent) => {
  //   const itemPrice = menuPrice || 0;
  //   const tax = taxPercent || 0;
  //   return itemPrice + (itemPrice * tax) / 100;
  // };
  const calculateTotalPrice = (menuPrice, taxPercent) => {
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;
    const totalPrice = itemPrice + (itemPrice * tax) / 100;
    return formatPrice(totalPrice);
  };
  function formatPrice(value) {
    if (value >= 1000) {
      const formattedValue = numeral(value).format("0.0a");
      return formattedValue;
    } else {
      return numeral(value).format("0.00");
    }
  }

  const calculateVat = (menuPrice, taxPercent) => {
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;
    let finalPrice = (itemPrice * tax) / 100;
    return formatPrice(finalPrice);
  };

  return (
      <>
        {location.is_hotel ? (
            <ListGroup id="list-group-dnd">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        <div
                            className="menuBox"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "14px",
                              // flexWrap: 'wrap',
                              fontWeight: "bold",
                              backgroundColor: "bg-dark",

                              padding: "10px",
                            }}
                        >
                          <p style={{ width: "130px" }}>{t("image")}</p>
                          <p style={{ width: "130px" }}>{t("name")}</p>
                          <p style={{ width: "130px" }}>{t("description")}</p>
                          <p style={{ width: "130px" }}>{t("price-0")}</p>
                          <p style={{ width: "130px" }}>{t("status")}</p>

                          {items.some(
                              (item) =>
                                  item.img ||
                                  item.name ||
                                  item.menu_price ||
                                  item.status ||
                                  (item.MenuTags && item.MenuTags.length > 0) ||
                                  item.menu_type ||
                                  item.status !== undefined ||
                                  (item.MenuOptions && item.MenuOptions.length > 0)
                          ) && <p style={{ width: "130px" }}>{t("actions")}</p>}
                        </div>
                        {items.map((item, index) => (
                            <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                                isDragDisabled={isDragDisabled}
                            >
                              {(provided, snapshot) => (
                                  <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="drag-wrapper"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        props.toggleMenuModal(item);
                                      }}
                                  >
                                    <ListGroupItem
                                        className="no-border-bottom-radius no-border-top-radius menu-item-li"
                                        disabled={categorySelected}
                                    >
                                      <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            fontSize: "15px",
                                            // flexWrap: 'wrap',
                                          }}
                                      >
                                        <div style={{ width: "130px" }}>
                                          <Media left tag="div">
                                            {item.img ? (
                                                <Media
                                                    object
                                                    src={item.img}
                                                    className="rounded-circle mr-2"
                                                    style={{ objectFit: "cover" }}
                                                    alt="image"
                                                    height="70"
                                                    width="70"
                                                />
                                            ) : (
                                                <div
                                                    className="rounded-circle mr-2"
                                                    style={{
                                                      // padding: 9,
                                                      height: 50,
                                                      width: 50,
                                                      backgroundColor: "#ffffff15",
                                                    }}
                                                >
                                                  <Image
                                                      size={32}
                                                      className="align-self-center rounded"
                                                      loading="lazy"
                                                  />
                                                </div>
                                            )}
                                          </Media>
                                        </div>
                                        <p style={{ width: "130px" }}>{item?.name}</p>

                                        <p style={{ width: "130px" }}>
                                          {item?.menu_description}
                                        </p>
                                        <p style={{ width: "130px" }}>
                                          {location?.Currency?.currency_symbol}
                                          {item?.menu_price}
                                        </p>
                                        <p style={{ width: "130px" }}>
                                          <Badge
                                              id="collapse"
                                              className="ml-1 font-small-2 align-top"
                                              pill
                                              color={item?.status ? "success" : "danger"}
                                          >
                                            {item?.status ? t("enabled") : t("disabled")}
                                          </Badge>
                                        </p>

                                        <div style={{ width: "130px" }}>
                                          {!hideEditableButtons && (
                                              <div className="align-self-center display-flex">
                                                {!isOutlet && (
                                                    <span
                                                        tag="small"
                                                        className="text-bold-500 cursor-pointer mr-1"
                                                    >
                                        <Trash
                                            size={25}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              delMenu(item);
                                            }}
                                            style={{ marginRight: "10px" }}
                                        ></Trash>

                                        <Edit
                                            size={25}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              props.toggleMenuModal(item);
                                            }}
                                        />
                                                      {item?.highlighted ? (
                                                          <Star
                                                              fill="gold"
                                                              color="gold"
                                                              size={25}
                                                              style={{ marginLeft: "10px" }}
                                                          />
                                                      ) : null}
                                      </span>
                                                )}
                                              </div>
                                          )}
                                          {!isOutlet && updateSelectedModifiers && (
                                              <Checkbox
                                                  color="primary"
                                                  icon={
                                                    <Check className="vx-icon" size={16} />
                                                  }
                                                  defaultChecked={
                                                      selectedIds.indexOf(parseInt(item.id)) >
                                                      -1 || categorySelected
                                                  }
                                                  checked={
                                                      selectedIds.indexOf(parseInt(item.id)) >
                                                      -1 || categorySelected
                                                  }
                                                  value={item.id}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    updateSelectedModifiers(e.target.value);
                                                  }}
                                              />
                                          )}
                                        </div>
                                      </div>
                                    </ListGroupItem>
                                  </div>
                              )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ListGroup>
        ) : (
            <>
              <div
                  style={{
                    display: "grid",
                    width: "100%",
                    fontSize: "14px",
                    overflowX: "auto",
                    overflowx: "scroll",
                    flexWrap: "nowrap",
                    "@media screen and (min-width: 768px)": {
                      flexDirection: "row",
                    },
                    "@media screen and (min-width: 1024px)": {
                      fontSize: "15px",
                      display: "inline-block",
                    },
                  }}
              >
                <ListGroup id="list-group-dnd">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                          <div ref={provided.innerRef}>
                            <div
                                className="menuBox"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  backgroundColor: "bg-dark",

                                  padding: "10px",
                                }}
                            >
                              <p style={{ width: "130px", fontFamily: "sans-serif" }}>
                                {t("image")}
                              </p>
                              <p style={{ width: "133px" }}>{t("name")}</p>
                              <p style={{ width: "133px" }}>{t("price-0")}</p>
                              <p style={{ width: "130px" }}>VAT</p>
                              <p style={{ width: "130px" }}>Excl VAT</p>
                              <p style={{ width: "130px" }}>{t("tags")}</p>
                              <p style={{ width: "130px" }}>{t("type")}</p>

                              <p style={{ width: "130px" }}>{t("status")}</p>

                              <p style={{ width: "130px" }}>{t("variants")}</p>
                              {items.some(
                                  (item) =>
                                      item.img ||
                                      item.name ||
                                      item.menu_price ||
                                      (item.MenuTags && item.MenuTags.length > 0) ||
                                      item.menu_type ||
                                      item.status !== undefined ||
                                      (item.MenuOptions && item.MenuOptions.length > 0)
                              ) && <p style={{ width: "130px" }}>{t("actions")}</p>}
                            </div>
                            {items.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                    isDragDisabled={isDragDisabled}
                                >
                                  {(provided, snapshot) => (
                                      <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="drag-wrapper"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            props.toggleMenuModal(item);
                                          }}
                                      >
                                        <ListGroupItem
                                            className="no-border-bottom-radius no-border-top-radius menu-item-li"
                                            disabled={categorySelected}
                                            style={{
                                              marginBottom: "10px",
                                            }}
                                        >
                                          <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                fontSize: "15px",
                                              }}
                                          >
                                            <div style={{ width: "130px" }}>
                                              <Media left tag="div">
                                                {item.img ? (
                                                    <Media
                                                        object
                                                        src={item.img}
                                                        className="rounded-circle mr-2"
                                                        style={{ objectFit: "cover" }}
                                                        alt="image"
                                                        height="70"
                                                        width="70"
                                                    />
                                                ) : (
                                                    <Media
                                                        object
                                                        src={dummyFoodImage}
                                                        className="rounded-circle mr-2 bg-white"
                                                        style={{ objectFit: "cover" }}
                                                        alt="image"
                                                        height="70"
                                                        width="70"
                                                    />
                                                    // <div
                                                    //   className="rounded-circle mr-2"
                                                    //   style={{
                                                    //     // padding: 9,
                                                    //     height: 70,
                                                    //     width: 70,
                                                    //     backgroundColor: '#ffffff15',
                                                    //   }}
                                                    // >
                                                    //   <Image
                                                    //     size={32}
                                                    //     className="align-self-center rounded"
                                                    //     loading="lazy"
                                                    //   />
                                                    // </div>
                                                )}
                                              </Media>
                                            </div>

                                            <p className="mt-1" style={{ width: "85px" }}>
                                              {item.name}
                                            </p>

                                            <p className="mt-1 ml-3" style={{ width: "180px" }}>
                                              {location.Currency.currency_symbol}
                                              {/* {item.menu_price} */}
                                              {calculateTotalPrice(
                                                  item.menu_price,
                                                  item.menu_tax
                                              )}
                                            </p>
                                            <p
                                                className="mt-1"
                                                style={{ width: "130px", marginLeft: " -44px " }}
                                            >
                                              {location?.Currency?.currency_symbol}
                                              {calculateVat(item.menu_price, item.menu_tax)}
                                            </p>
                                            <p className="mt-1" style={{ width: "130px" }}>
                                              {location.Currency.currency_symbol}
                                              {formatPrice(item.menu_price)}
                                            </p>
                                            <p style={{ width: "130px" }}>
                                              {item.MenuTags && item.MenuTags.length > 0 ? (
                                                  <>
                                                    {item.MenuTags.map((menu_tag) => {
                                                      return (
                                                          <React.Fragment key={menu_tag.id}>
                                                            <Badge
                                                                style={{ padding: "8px" }}
                                                                className="bg-success mt-1"
                                                            >
                                                              {menu_tag.Dish_Tag &&
                                                                  menu_tag.Dish_Tag.tag_name}
                                                            </Badge>
                                                            &nbsp;
                                                          </React.Fragment>
                                                      );
                                                    })}
                                                  </>
                                              ) : null}
                                            </p>
                                            <p className="mt-1" style={{ width: "130px" }}>
                                              {item.menu_type === "kitchen" ? (
                                                  <>
                                                    <FontAwesomeIcon
                                                        className="mr-1"
                                                        icon={faFire}
                                                    />
                                                    {t("kitchen")}{" "}
                                                  </>
                                              ) : item.menu_type === "bar" ? (
                                                  <>
                                                    <FontAwesomeIcon
                                                        className="mr-1"
                                                        icon={faGlassCheers}
                                                    />
                                                    {t("bar")}{" "}
                                                  </>
                                              ) : item.menu_type === "dessert" ? (
                                                      <>
                                                        <FontAwesomeIcon
                                                            className="mr-1"
                                                            icon={faBirthdayCake}
                                                        />
                                                        {t("dessert")}{" "}
                                                      </>
                                                  )


                                                  : item?.menu_type === "hotel" ? (
                                                          <>
                                                            <FontAwesomeIcon
                                                                className="mr-1"
                                                                icon={faBirthdayCake}
                                                            />
                                                            {t("dessert")}{" "}
                                                          </>
                                                      )


                                                      : item.menu_type === "alternative_1" ? (
                                                              <>
                                                                <FontAwesomeIcon
                                                                    className="mr-1"
                                                                    icon={faBirthdayCake}
                                                                />
                                                                {t("alternative_1")}{" "}
                                                              </>
                                                          )


                                                          : (
                                                              <>
                                                                <FontAwesomeIcon
                                                                    className="mr-1"
                                                                    icon={faBirthdayCake}
                                                                />
                                                                {t("alternative_2")}{" "}
                                                              </>
                                                          )}
                                            </p>

                                            <p className="mt-1" style={{ width: "130px" }}>
                                              <Badge
                                                  id="collapse"
                                                  className="font-small-2 align-top"
                                                  pill
                                                  style={{ padding: "8px" }}
                                                  color={
                                                    item.status === 1
                                                        ? "success"
                                                        : item.status === 0
                                                            ? "danger"
                                                            : "warning"
                                                  }
                                              >
                                                {item.status === 1 ? t("enabled") : item.status === 0 ? t("disabled") : t("out-of-stock")}
                                              </Badge>
                                            </p>
                                            <p className="mt-1" style={{ width: "130px" }}>
                                              {item.MenuOptions.length > 0 && (
                                                  <Badge
                                                      id="collapse"
                                                      className="font-small-2 align-top"
                                                      pill
                                                      style={{padding: "8px"}}
                                                      color={
                                                        item.flag ? "success" : "danger"
                                                      }
                                                  >
                                                    {" "}
                                                    {item.MenuOptions.length}{item.numberOfOptions > 0 ? `(${item.numberOfOptions})` : ""} {t("variants")}
                                                  </Badge>
                                              )}
                                            </p>
                                            <div style={{ width: "130px" }}>
                                              {!hideEditableButtons && (
                                                  <div className="align-self-center display-flex">
                                                    {!isOutlet && (
                                                        <span
                                                            tag="small"
                                                            className="text-bold-500 cursor-pointer mr-1"
                                                        >
                                      <Trash
                                          size={25}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            delMenu(item);
                                          }}
                                          style={{ marginRight: "10px" }}
                                      ></Trash>

                                      <Edit
                                          size={25}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            props.toggleMenuModal(item);
                                          }}
                                      />
                                                          {item.highlighted ? (
                                                              <Star
                                                                  fill="gold"
                                                                  color="gold"
                                                                  size={25}
                                                                  style={{ marginLeft: "10px" }}
                                                              />
                                                          ) : null}
                                    </span>
                                                    )}
                                                  </div>
                                              )}
                                              {!isOutlet && updateSelectedModifiers && (
                                                  <Checkbox
                                                      color="primary"
                                                      icon={<Check className="vx-icon" size={16} />}
                                                      defaultChecked={
                                                          selectedIds.indexOf(parseInt(item.id)) >
                                                          -1 || categorySelected
                                                      }
                                                      checked={
                                                          selectedIds.indexOf(parseInt(item.id)) >
                                                          -1 || categorySelected
                                                      }
                                                      value={item.id}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        updateSelectedModifiers(e.target.value);
                                                      }}
                                                  />
                                              )}
                                            </div>
                                          </div>
                                        </ListGroupItem>
                                      </div>
                                  )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </ListGroup>
              </div>
            </>
        )}







      </>
  );
};

export default DnDListView;
