import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Table,
  Progress,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class LastestSessionListDashboard extends React.Component {
  render() {
    const { t, location } = this.props;
    console.log("propscoming", location);

    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <div> {t("latest-sessions")}</div>
          </CardTitle>
        </CardHeader>

        <div className="table-responsive">
          <Table className="dashboard-table table-hover-animation mb-0 mt-1">
            <thead>
              <tr>
                {location?.is_hotel === 1 ? (
                  <th className=" d-md-table-cell text-nowrap">Room</th>
                ) : (
                  <th className=" d-md-table-cell text-nowrap">{t("table")}</th>
                )}

                {/* <th className=" d-md-table-cell text-nowrap">SESSION DATE</th> */}
                <th className="d-lg-table-cell text-nowrap ">
                  {t("session-date-and-time")}{" "}
                </th>
                <th className=" d-md-table-cell text-nowrap text-center">
                  {t("total-items-0")}{" "}
                </th>
                <th className="d-md-table-cell text-nowrap text-center">
                  {t("amount")}{" "}
                </th>
                <th className="text-center text-nowrap ">{t("status")}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.length > 0 ? (
                this.props.data.map((item, index) => {
                  return (
                    <tr key={index}>
                      {location?.is_hotel === 1 ? (
                        <td className="d-md-table-cell text-nowrap ">
                          <Link
                            to={`/sessions/${item.session_id}`}
                            className="dashboard-table table-hover-animation mb-0 mt-1"
                          >
                            <Badge
                              color="primary"
                              pill
                              className="text-capitalize "
                              style={{ width: "130px" }}
                            >
                              <FontAwesomeIcon
                                icon={faTable}
                                className="mr-1"
                              />
                              {item.room_name}
                            </Badge>
                          </Link>
                        </td>
                      ) : (
                        <td className="d-md-table-cell text-nowrap ">
                          <Link
                            to={`/sessions/${item.session_id}`}
                            className="dashboard-table table-hover-animation mb-0 mt-1"
                          >
                            <Badge
                              color="primary"
                              pill
                              className="text-capitalize "
                              style={{ width: "130px" }}
                            >
                              <FontAwesomeIcon
                                icon={faTable}
                                className="mr-1"
                              />
                              {item.table_name}
                            </Badge>
                          </Link>
                        </td>
                      )}

                      {/* <td className=" d-md-table-cell text-nowrap">
                        {item.session_date}
                      </td> */}
                      <td className="d-lg-table-cell text-nowrap">
                        <div className="d-flex align-items-center">
                          <div className="mr-2">
                            <i className="feather icon-clock"></i>
                          </div>
                          <div>
                            <div className=" font-weight-bold">
                              {item.start_time} -{" "}
                              {item.end_time !== null
                                ? item.end_time
                                : t("in-progress")}
                            </div>
                            <small className="text-muted">
                              {item.session_date}
                            </small>
                          </div>
                        </div>
                      </td>
                      {/* <td className=" d-lg-table-cell text-nowrap">
                        {item.end_time !== null ? (
                          <>
                            <i className="feather icon-clock mr-1"></i>
                            <span className="text-success">
                              {item.start_time} - {item.end_time}
                            </span>
                          </>
                        ) : (
                          <>
                            <i className="feather icon-clock mr-1"></i>
                            <span className="text-warning">
                              {item.start_time} - In Progress
                            </span>
                          </>
                        )}
                      </td> */}

                      <td className=" d-md-table-cell text-center text-nowrap">
                        {item.total_items > 0 ? item.total_items : "N/A"}
                      </td>
                      <td className=" d-md-table-cell text-center text-nowrap">
                        {item.order_total > 0
                          ? this.props.location.currency
                          : null}
                        {item.order_total > 0 ? ((item.order_total).toFixed(2)) : "N/A"}
                      </td>

                      <td className="text-center text-nowrap">
                        <Badge
                          color="primary"
                          id="collapse"
                          className="font-small-2 align-top"
                          pill
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "8px 12px",

                            // backgroundColor: '#28a745',
                          }}
                        >
                          {item.session_status.toUpperCase()}
                        </Badge>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">{t("no-session-found")}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card>
    );
  }
}
export default withTranslation("global")(LastestSessionListDashboard);

// export default LastestSessionListDashboard;
