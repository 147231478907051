import React from 'react';
import * as Icon from 'react-feather';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const adminNavigationConfig = [
  {
    id: 'dashboard',
    title: 'dashboard',
    type: 'item',
    icon: <Icon.Codesandbox size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/',
    starred: false,
  },
  {
    id: 'restaurant-settings',
    title: 'tables',
    type: 'item',
    icon: <Icon.Tablet size={20} />,
    permissions: ['admin'],
    navLink: '/tables',
    starred: false,
  },
  {
    id: 'rooms',
    title: 'Rooms',
    type: 'item',
    icon: <Icon.Box size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/listroom',
  },
  {
    id: 'premium_services',
    title: 'Premium Services',
    type: 'item',
    icon: <WorkspacePremiumIcon height={20} width={20} />,
    permissions: ['admin'],
    navLink: '/premium_services'
  },
  {
    id: 'settings',
    title: 'settings',
    type: 'collapse',
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: 'restaurants',
        title: 'restaurants',
        type: 'item',
        icon: <Icon.ShoppingBag size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations',
      },
      {
        id: 'hotels',
        title: 'Hotels',
        type: 'item',
        icon: <Icon.Codesandbox size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/hotels',
      },
 
      {
        id: 'services',
        title: 'services',
        type: 'item',
        icon: <Icon.ShoppingBag size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/services',
      },
      
      {
        id: 'currencies',
        title: 'currencies',
        type: 'item',
        icon: <Icon.DollarSign size={20} />,
        permissions: ['admin'],
        navLink: '/currencies',
      },
      {
        id: 'addIcon',
        title: 'Icon',
        type: 'item',
        icon: <Icon.PlusSquare size={20} />,
        permissions: ['admin'],
        navLink: '/icons',
      },
      // {
      //   id: 'paymentmethod',
      //   title: 'Payment Method',
      //   type: 'item',
      //   icon: <Icon.DollarSign size={20} />,
      //   permissions: ['admin'],
      //   navLink: '/payment/table',
      // },
    ],
    starred: false,
  },
  {
    id: 'stats',
    title: 'overall Stats',
    type: 'item',
    icon: <Icon.BarChart2 size={16} />,
    navLink: '/location/stats',
    starred: false,
  },
  {
    id: 'analytics',
    title: 'analytics',
    type: 'collapse',
    icon: <Icon.BarChart2 size={16} />,
    children: [
      {
        id: 'overall-stats',
        title: 'Sales Report',
        type: 'item',
        icon: <Icon.Activity size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/overall_stats',
      },
      {
        id: 'item-stats',
        title: 'item Stats',
        type: 'item',
        icon: <Icon.List size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/item_stats',
      },
    ],
    starred: false,
  },
  {
    id: 'customer-list',
    title: 'customer List',
    type: 'item',
    icon: <Icon.UserCheck size={20} />,
    permissions: ['admin'],
    navLink: '/customers',
    starred: false,
  },
  {
    id: 'packages',
    title: 'packages',
    type: 'collapse',
    icon: <Icon.Package size={20} />,
    permissions: ['admin'],
    starred: false,
    children: [
      {
        id: 'product-listing',
        title: 'Product Listing',
        type: 'item',
        icon: <Icon.Archive size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/packages',
      },
      {
        id: 'price-listing',
        title: 'price Listing',
        type: 'item',
        icon: <Icon.DollarSign size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/pricing',
      },
      {
        id: 'purchase-history',
        title: 'Purchase History',
        type: 'item',
        icon: <Icon.Slack size={20} />,
        permissions: ['admin'],
        navLink: '/purchase-history',
      },
    ],
  },
  {
    id: 'accounts',
    title: 'accounts & Roles',
    type: 'collapse',
    icon: <Icon.Lock size={20} />,
    children: [
      {
        id: 'Accounts',
        title: 'accounts',
        type: 'item',
        icon: <Icon.Users size={20} />,
        permissions: ['admin'],
        navLink: '/staff',
      },
      {
        id: 'Roles',
        title: 'roles',
        type: 'item',
        icon: <Icon.LogIn size={20} />,
        permissions: ['admin'],
        navLink: '/roles',
      },
    ],
    starred: false,
  },
  {
    id: 'logout',
    title: 'logout',
    type: 'item',
    icon: <Icon.Power size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/logout',
    starred: false,
  },
];
export default adminNavigationConfig;
