import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';

export default class SearchSelect extends Component {
  handleInputChange(searchTerm) {
    this.setState({ searchTerm });
    return searchTerm;
  }

  onChange(selected) {
    let { input } = this.props;
    if(selected) input.onChange(selected.loc_id);
    else input.onChange(null);
  }

  render() {
    let { input, label, meta: { touched, error, warning }, loadOptions, defaultValue } = this.props;
    return (
      <div className='form-group'>
        <label htmlFor={input.name}>{label}</label>
        <AsyncSelect
          isClearable
          cacheOptions
          placeholder={label}
          defaultValue={defaultValue}
          loadOptions={loadOptions}
          onChange={this.onChange.bind(this)}
          onInputChange={this.handleInputChange.bind(this)}
          noOptionsMessage={() => `No ${label} present.`}
          styles={{
            dropdownIndicator: (provided) => { return {...provided, display: 'none' }},
            indicatorSeparator: (provided) => { return {...provided, display: 'none' }}
          }}
          theme={ theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#00ccbb',
              primary: '#00ccbb',
            },
          })}
        />
        { touched && ((error && <small className='form-text text-danger'>{label} is {error}</small>) ||
          (warning && <small className='form-text text-muted'>{warning}</small>))}
      </div>
    );
  }

}
