import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllProductPricing } from "../../redux/actions/productpricing";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvField,
} from "availity-reactstrap-validation";

import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Collapse,
  Button,
} from "reactstrap";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { ChevronUp, ChevronDown } from "react-feather";
import { useParams } from "react-router-dom";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import PricingService from "../../services/PricingService";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import CONSTANTS from "../../constants";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const EditPricingSessionForm = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  console.log("PROPS", props);
  const history = useHistory();

  const { packageId } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState(props.title || "");
  const [price, setPrice] = useState(props.price || "");
  console.log(price, "setPrice");
  const [perksInPackages, setPerksInPackages] = useState(
    props.PerksInPackages || ""
  );
  const [minQuantities, setMinQuantities] = useState({});
  const [maxQuantities, setMaxQuantities] = useState({});

  const [requiredValues, setRequiredValues] = useState({});

  const [description, setDescription] = useState(props.description || "");

  const [benefitIdCounter, setBenefitIdCounter] = useState(1);
  const [productsInPackages, setProductsinPackages] = useState(
    props.ProductsInPackages || ""
  );

  const maxQuantitiy = productsInPackages.map((item) => item.max_quantity);

  // Calculate the maximum value from the 'maxQuantities' array
  const defaultMaxQuantityValue = Math.max(...maxQuantitiy);

  const minQuantity = productsInPackages.map((item) => item.min_quantity);
  // Calculate the maximum value from the 'maxQuantities' array
  const defaultMinQuantityValue = Math.max(...minQuantity);

  const PackagePrice = productsInPackages.map((item) => item.price);

  const defaultPackagePrice = Math.max(...PackagePrice);

  const defaultPaymentMode = productsInPackages.map(
    (item) => item.payment_mode
  );
  const defaultRequiredValue = productsInPackages.map((item) => item.required);

  // Set the state variable with the first element of 'maxQuantities' (a single number)
  const fullPrice = productsInPackages.map((item) => item);

  // State to store product prices
  const [productPrices, setProductPrices] = useState({});
  const [paymentMode, setPaymentMode] = useState({});

  const [paymentModeError, setPaymentModeError] = useState("");
  const [maxError, setMaxError] = useState("");
  const [minError, setMinError] = useState("");
  const [minQuantityError, setMinQuantityError] = useState("");

  useEffect(() => {
    // Initialize "requiredValues" with data from the API
    const initialRequiredValues = {};
    productsInPackages.forEach((pkg) => {
      initialRequiredValues[pkg.package_products_pricing_id] =
        pkg.required === 1; // Convert to boolean
    });
    setRequiredValues(initialRequiredValues);
  }, [productsInPackages]);
  console.log(isEdit, "Product");
  // Handler function to update product price
  const handleProductPriceChange = (pkgId, newPrice) => {
    console.log(`Update product price`, newPrice, pkgId);

    setIsEdit(true);
    console.log(isEdit, "Product");
    setProductPrices(
      (prevPrices) => (
        console.log(prevPrices, "prevPrices"),
        {
          ...prevPrices,
          [pkgId]: newPrice,
        }
      )
    );
    // setProductPrices({ [pkgId]: newPrice });
  };

  const [allProductsList, setAllProductsList] = useState();
  console.log("AllProductLIST COMBINED", allProductsList);
  const [isOpen, setIsOpen] = useState({});
  // const handleCheckboxChange = (pkgId) => {
  //   console.log(pkgId, "Check");
  //   toggleCollapse(pkgId);
  //   const inputfield = document.getElementById(pkgId);
  //   inputfield.min = 1;
  //   setRequiredValues((prevValues) => ({
  //     ...prevValues,
  //     [pkgId]: !prevValues[pkgId],
  //   }));
  // };
  const handleCheckboxChange = (pkgId) => {
    console.log(pkgId, "Check");
    toggleCollapse(pkgId);
    const inputfield = document.getElementById(pkgId);
    console.log("input_value", inputfield.value);
    setRequiredValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [pkgId]: !prevValues[pkgId],
      };

      setMinQuantityForPackage(
        pkgId,
        updatedValues[pkgId] ? 1 && (inputfield.min = 1) : 0
      );
      console.log("input_value_min", inputfield.min);

      return updatedValues;
    });
  };

  const setMinQuantityForPackage = (pkgId, newValue) => {
    setMinQuantities((prevQuantities) => ({
      ...prevQuantities,
      [pkgId]: newValue,
    }));
  };

  const setMaxQuantityForPackage = (pkgId, newValue) => {
    setMaxQuantities((prevQuantities) => ({
      ...prevQuantities,
      [pkgId]: newValue,
    }));
  };
  const handlePaymentModeChange = (pkgId, newPaymentMode) => {
    // Update the payment mode for this product
    setPaymentMode((prevModes) => ({
      ...prevModes,
      [pkgId]: newPaymentMode,
    }));
  };

  // Function to toggle the collapse of a card
  const toggleCollapse = (pkg, product = null) => {
    console.log(`Initial isOpen value for ${pkg}:`, isOpen[pkg]); // Add this line

    setIsOpen((prevState) => ({
      ...prevState,
      [pkg.id]: !prevState[pkg.id], // Use pkg.id as the key
    }));
    if (product !== null) {
      setRequiredValues((prevValues) => ({
        ...prevValues,
        [pkg.id]: !prevValues[pkg.id], // Toggle the required value
      }));
    }
    if (product != null) {
      let parray = allProductsList;
      console.log("parray", parray);
      let removeitem = false;
      if (parray.productPricing.length > 0) {
        for (let i = 0; i < parray.productPricing.length; i++) {
          if (
            parray.productPricing[i].id == pkg &&
            parray.productPricing[i]?.checked
          ) {
            console.log("Product Found");
            parray.productPricing[i].checked = false;
            break;
          }

          if (parray.productPricing[i].id == pkg) {
            console.log("Product Not Found");
            parray.productPricing[i] = {
              ...parray.productPricing[i],
              checked: true,
            };
            break;
          }
        }
      }
      console.log("Product", parray);
      setAllProductsList(parray);
    }
  };
  const { data: productpricing, loading: loadingProductPricing } = useSelector(
    (store) => store.productpricing.allProductPricingItems
  );
  useEffect(() => {
    const initialPaymentModes = {};
    productsInPackages.forEach((pkg) => {
      initialPaymentModes[pkg.package_products_pricing_id] =
        pkg.payment_mode || "";
    });
    setPaymentMode(initialPaymentModes);
  }, [productsInPackages]);
  useEffect(() => {
    let allProductsListArray = productpricing;
    productsInPackages.forEach((el) => {
      let a = allProductsListArray?.productPricing?.map((el1) => {
        if (el.package_products_pricing_id == el1.id) {
          el1.checked = true;
        }
      });
    });

    setAllProductsList(allProductsListArray);
  }, [productsInPackages]);

  useEffect(() => {
    // Calculate the highest existing ID and set the counter accordingly
    if (perksInPackages.length > 0 || perksInPackages.length === 0) {
      const maxExistingId = Math.max(
        ...perksInPackages.map((benefit) => benefit.id)
      );
      setBenefitIdCounter(maxExistingId + 1);
    }
  }, [perksInPackages]);
  useEffect(() => {
    const fetchData = async () => {
      await props.getAllProductPricing();
    };
    fetchData();
  }, []);

  if (loadingProductPricing) return <SpinnerComponent />;

  const handleAddBenefit = () => {
    const newBenefit = {
      id: benefitIdCounter,
      package_id: parseInt(packageId),
      title: "",
    };
    setBenefitIdCounter(benefitIdCounter + 1);
    setPerksInPackages([...perksInPackages, newBenefit]);
  };

  const handleRemoveBenefit = (benefitId) => {
    const updatedBenefits = perksInPackages.filter(
      (benefit) => benefit.id !== benefitId
    );
    setPerksInPackages(updatedBenefits);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const selectedProducts = [];

      allProductsList.productPricing.forEach((product) => {
        if (product.checked) {
          console.log(productPrices[27], "productPrices");

          let price = 0;

          const matchingProduct = productsInPackages.find(
            (item) => item.package_products_pricing_id === product.id
          );
          console.log(matchingProduct, "matchingProduct");

          if (matchingProduct) {
            price = matchingProduct.price;
          }
          // const minQuantity = parseFloat(minQuantities[product.id]);
          // const maxQuantity = parseFloat(maxQuantities[product.id]) || 0;

          // if (minQuantity > maxQuantitiy) {
          //   // Show error message and log details for debugging
          //   // console.error(`Product ID: ${product.id}`);
          //   // console.error(`Min Quantity: ${minQuantity}`);
          //   // console.error(`Max Quantity: ${maxQuantity}`);
          //   // console.error("Invalid quantity range.");
          //   // setMinQuantityError(
          //   //   toast.error(
          //   //     "Minimum quantity must be less than or equal to maximum quantity"
          //   //   )
          //   // );
          //   setMinQuantityError("Invalid quantity range");
          //   throw new Error(
          //     "Minimum quantity must be less than or equal to maximum quantity."
          //   );
          // }

          const extractedData = {
            // id: product.id,
            multiple_quantity: product.multiple_quantity,
            package_products_pricing_id: product.id,
            physical_available: product.physical_support,
            // price:
            //   productPrices[product.id] !== undefined
            //     ? productPrices[product.id] == ""
            //       ? 0
            //       : productPrices[product.id] // Use the edited price from state if available
            //     : product.price,
            price:
              isEdit == false
                ? price
                : productPrices[product.id] !== undefined
                ? productPrices[product.id] == ""
                  ? 0
                  : parseFloat(productPrices[product.id])
                : parseFloat(product.price),
            // Otherwise, use the default price
            // price: isEdit
            //   ? productPrices[product.id] !== undefined
            //     ? productPrices[product.id] == ""
            //       ? 0
            //       : productPrices[product.id] // Use the edited price from state if available
            //     : product.price
            //   : 0,

            product_title: product.title,
            payment_mode: paymentMode[product.id] || defaultPaymentMode[0], // Use the selected payment mode
            min_quantity:
              minQuantities[product.id] == ""
                ? 0
                : undefined || minQuantities[product.id]
                ? minQuantities[product.id]
                : defaultMinQuantityValue || 0, // Include minQuantity
            // max_quantity:
            //   maxQuantities[product.id] || defaultMaxQuantityValue || "1", // Include maxQuantity
            max_quantity:
              maxQuantities[product.id] == ""
                ? 0
                : undefined || maxQuantities[product.id]
                ? maxQuantities[product.id]
                : defaultMaxQuantityValue || 0,
            package_id: parseInt(packageId),
            required: requiredValues[product.id] ? 1 : 0,
          };

          selectedProducts.push(extractedData);
        }
      });

      const formData = {
        title,
        price: price === "" ? "0" : price,
        number_of_benefits: perksInPackages.length,
        description,
        benefits: perksInPackages, // Include the benefits array
        products: selectedProducts,
      };
      console.log("price", formData.price);

      const response = await PricingService.updatePricingPackage(
        formData,
        packageId
      );
      if (response) {
        console.log(response, "updated");
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: t("package-product-update-successfully"),
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          history.push(`${CONSTANTS.BASE_URL}pricing`);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: t(
            "an-error-occurred-while-creating-the-package-product-category"
          ),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: `An error occurred: ${error.message}`,
      });
    }
  };

  return (
    <div>
      {/* {JSON.stringify(productPrices)}
      {JSON.stringify(productsInPackages)} */}
      <div className="location">
        <Card>
          <CardBody>
            <Col md="12" sm="12">
              <h1>{t("info")}</h1>

              <AvForm>
                <Row>
                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label> {t("title")} </Label>
                      <AvInput
                        type="text"
                        name="title"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </AvGroup>
                  </Col>

                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label> {t("estimated-price")} </Label>
                      <AvInput
                        type="text"
                        name="price"
                        value={price === "" ? "0" : price}
                        defaultValue={0}
                        onChange={(e) => {
                          // setPrice(e.target.value);
                          setPrice(e.target.value);
                        }}
                      />
                    </AvGroup>
                  </Col>

                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t("description")} </Label>
                      <AvInput
                        type="text"
                        name="description"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </AvGroup>
                  </Col>
                </Row>

                <h1>{t("products")}</h1>
                <div>
                  {allProductsList?.productPricing.map((pkg) => (
                    <>
                      <div className="vx-collapse collapse-bordered mb-2">
                        <Card key={{ pkg }} className="border-radius-0-5">
                          <CardHeader
                            onClick={() => toggleCollapse(pkg)}
                            className="border-radius-0-5 bg-white border-bottom border-primary"
                          >
                            <CardTitle className="lead collapse-title collapsed">
                              {pkg.title}

                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                  padding: "5px 10px",
                                  borderRadius: "15px",
                                  backgroundColor: "green",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {pkg?.PackageProduct?.tag}
                              </span>
                            </CardTitle>

                            <div className="justify-content-end display-flex">
                              <Checkbox
                                color="primary"
                                icon={<span className="vx-icon"></span>}
                                checked={pkg?.checked ? "checked" : false}
                                onChange={() => {
                                  toggleCollapse(pkg.id, pkg);
                                }}
                              />

                              <span className="text-bold-500 cursor-pointer">
                                {isOpen ? (
                                  <ChevronUp size={25} />
                                ) : (
                                  <ChevronDown size={25} />
                                )}
                              </span>
                            </div>
                          </CardHeader>
                          <Collapse isOpen={isOpen[pkg.id]}>
                            <CardBody className=" p-3">
                              <div key={pkg.id}>
                                {/* <div className="form-group">
                                  <input
                                    type="checkbox"
                                    checked={pkg.physical_support}
                                    disabled
                                  />
                                  <span className="ml-2">
                                    Physical Available
                                  </span>
                                </div> */}
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    checked={pkg.multiple_quantity}
                                    disabled
                                  />
                                  <span className="ml-2">
                                    {t("multiple-quantity")}{" "}
                                  </span>
                                </div>
                                {pkg.multiple_quantity === 1 ? (
                                  <>
                                    <div className="form-group">
                                      <label>{t("max-quantity")}</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={
                                          maxQuantities[pkg.id] !== undefined
                                            ? maxQuantities[pkg.id]
                                            : productsInPackages.find(
                                                (item) =>
                                                  item.package_products_pricing_id ===
                                                  pkg.id
                                              )?.max_quantity == ""
                                            ? 0
                                            : productsInPackages.find(
                                                (item) =>
                                                  item.package_products_pricing_id ===
                                                  pkg.id
                                              )?.max_quantity ||
                                              pkg.max_quantity
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onWheel={(event) =>
                                          event.currentTarget.blur()
                                        }
                                        onChange={(e) => {
                                          const newMaxQuantity = e.target.value;
                                          setMaxQuantityForPackage(
                                            pkg.id,
                                            newMaxQuantity
                                          ); // Call the function to update Max Quantity
                                        }}
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label>{t("min-quantity")}</label>
                                      {/* <input
                                        type="number"
                                        className="form-control"
                                        value={
                                          minQuantities[pkg.id] !== undefined
                                            ? minQuantities[pkg.id]
                                            : productsInPackages.find(
                                                (item) =>
                                                  item.package_products_pricing_id ===
                                                  pkg.id
                                              )?.min_quantity == ""
                                            ? 0
                                            : productsInPackages.find(
                                                (item) =>
                                                  item.package_products_pricing_id ===
                                                  pkg.id
                                              )?.min_quantity ||
                                              pkg.min_quantity
                                        }
                                        onChange={(e) => {
                                          const newMinQuantity = e.target.value;
                                          setMinQuantityForPackage(
                                            pkg.id,
                                            newMinQuantity
                                          ); // Call the function to update Min Quantity
                                        }}
                                      /> */}
                                      <input
                                        type="number"
                                        id={pkg.id}
                                        className="form-control"
                                        // min={1}
                                        value={
                                          minQuantities[pkg.id] !== undefined
                                            ? minQuantities[pkg.id]
                                            : requiredValues[pkg.id] // Check if checkbox is checked
                                            ? 1 // If checked, set min quantity to 1
                                            : productsInPackages.find(
                                                (item) =>
                                                  item.package_products_pricing_id ===
                                                  pkg.id
                                              )?.min_quantity == ""
                                            ? 0
                                            : productsInPackages.find(
                                                (item) =>
                                                  item.package_products_pricing_id ===
                                                  pkg.id
                                              )?.min_quantity ||
                                              pkg.min_quantity
                                        }
                                        // onChange={(e) => {
                                        //   const newMinQuantity = e.target.value;
                                        //   if (requiredValues[pkg.id]) {
                                        //     if (newMinQuantity == "0") {
                                        //       setMinQuantityForPackage(
                                        //         pkg.id,
                                        //         1
                                        //       );
                                        //     } else {
                                        //       setMinQuantityForPackage(
                                        //         pkg.id,
                                        //         newMinQuantity
                                        //       );
                                        //     }
                                        //   } else {
                                        //     setMinQuantityForPackage(
                                        //       pkg.id,
                                        //       newMinQuantity
                                        //     );
                                        //   }
                                        // }}
                                        onKeyDown={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onWheel={(event) =>
                                          event.currentTarget.blur()
                                        }
                                        onChange={(e) => {
                                          const newMinQuantity = e.target.value;
                                          console.log(newMinQuantity);
                                          const maxQuantity =
                                            productsInPackages.find(
                                              (item) =>
                                                item.package_products_pricing_id ===
                                                pkg.id
                                            )?.max_quantity;

                                          const isExceedingMax =
                                            parseInt(newMinQuantity, 10) >
                                            parseInt(
                                              maxQuantities[pkg.id] ||
                                                maxQuantity
                                            );

                                          if (isExceedingMax) {
                                            // Handle error for minimum exceeding maximum
                                            toast.error(
                                              "Error: Minimum quantity cannot exceed maximum quantity."
                                            );
                                          } else {
                                            if (requiredValues[pkg.id]) {
                                              if (newMinQuantity === "0") {
                                                setMinQuantityForPackage(
                                                  pkg.id,
                                                  1
                                                );
                                              } else {
                                                setMinQuantityForPackage(
                                                  pkg.id,
                                                  newMinQuantity
                                                );
                                              }
                                            } else {
                                              setMinQuantityForPackage(
                                                pkg.id,
                                                newMinQuantity
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : null}

                                <div className="form-group">
                                  {/* <input
                                    type="checkbox"
                                    checked={requiredValues[pkg.id] || false}
                                    onChange={() => {
                                      handleCheckboxChange(pkg.id, pkg);
                                    }}
                                  /> */}
                                  <input
                                    type="checkbox"
                                    defaultChecked={
                                      productsInPackages.find(
                                        (item) =>
                                          item.package_products_pricing_id ===
                                          pkg.id
                                      )?.required === 1
                                    }
                                    onChange={() => {
                                      handleCheckboxChange(pkg.id, pkg);
                                    }}
                                  />

                                  <span className="ml-2">{t("required")}</span>
                                </div>
                                <div className="form-group">
                                  <label>{t("price-0")}</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      productPrices[pkg.id] !== undefined
                                        ? productPrices[pkg.id]
                                        : productsInPackages.find(
                                            (item) =>
                                              item.package_products_pricing_id ===
                                              pkg.id
                                          )?.price == ""
                                        ? 0
                                        : productsInPackages.find(
                                            (item) =>
                                              item.package_products_pricing_id ===
                                              pkg.id
                                          )?.price || pkg.price
                                    }
                                    // value={
                                    //   productPrices[pkg.id] !== undefined
                                    //     ? productPrices[pkg.id] !== ""
                                    //       ? parseFloat(productPrices[pkg.id])
                                    //       : "0" // Show 0 if productPrices[pkg.id] is an empty string during editing
                                    //     : productsInPackages.find(
                                    //         (item) =>
                                    //           item.package_products_pricing_id ===
                                    //           pkg.id
                                    //       )?.price || 0
                                    // }
                                    // defaultValue={
                                    //   productsInPackages.find(
                                    //     (item) =>
                                    //       item.package_products_pricing_id ===
                                    //       pkg.id
                                    //   )?.price || pkg.price
                                    // }
                                    onChange={(e) => {
                                      const newPrice = e.target.value;
                                      handleProductPriceChange(
                                        pkg.id,
                                        newPrice
                                      ); // Update productPrices immediately
                                    }}
                                  />
                                  <div className="form-group">
                                    <Label>{t("payment-mode")}</Label>
                                    <select
                                      className="form-control"
                                      name={`paymentMode-${pkg.id}`}
                                      // value={paymentMode[pkg.id] || ''}
                                      defaultValue={
                                        productsInPackages.find(
                                          (item) =>
                                            item.package_products_pricing_id ===
                                            pkg.id
                                        )?.payment_mode || pkg.payment_mode
                                      }
                                      onChange={(e) => {
                                        // Update the payment mode for this product
                                        const newPaymentMode = e.target.value;
                                        handlePaymentModeChange(
                                          pkg.id,
                                          newPaymentMode
                                        );
                                      }}
                                    >
                                      <option value="">
                                        {t("select-payment-mode")}{" "}
                                      </option>
                                      <option value="license">
                                        {t("license")}
                                      </option>
                                      <option value="onetime">
                                        {t("one-time")}
                                      </option>
                                    </select>
                                    {paymentModeError && (
                                      <div className="text-danger">
                                        {paymentModeError}
                                      </div>
                                    )}
                                    {/* <select
                                      className="form-control"
                                      name={`paymentMode-${pkg.id}`}
                                      defaultValue={
                                        productsInPackages.find(
                                          (item) =>
                                            item.package_products_pricing_id ===
                                            pkg.id
                                        )?.payment_mode || pkg.payment_mode
                                      }
                                      onChange={(e) => {
                                        // Update the payment mode for this product
                                        const newPaymentMode = e.target.value;
                                        handlePaymentModeChange(
                                          pkg.id,
                                          newPaymentMode
                                        );
                                      }}
                                    >
                                      <option value="">
                                        Select Payment Mode
                                      </option>
                                      <option value="license">License</option>
                                      <option value="onetime">One-Time</option>
                                    </select> */}
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                    </>
                  ))}
                </div>

                <h1 className="mt-3">{t("benefits")}</h1>
                {perksInPackages.map((benefit, index) => (
                  <div key={benefit.id} className="d-flex align-items-center">
                    <AvInput
                      className="mt-2"
                      name={`benefits_${index}`}
                      type="text"
                      value={benefit.title}
                      onChange={(e) => {
                        const updatedBenefits = [...perksInPackages];
                        updatedBenefits[index].title = e.target.value;
                        setPerksInPackages(updatedBenefits);
                      }}
                    />
                    <Button
                      color="danger"
                      size="sm"
                      className="ml-2"
                      onClick={() => handleRemoveBenefit(benefit.id)}
                    >
                      {t("remove")}{" "}
                    </Button>
                  </div>
                ))}
                <Button
                  color="primary"
                  size="sm"
                  className="mt-2"
                  onClick={handleAddBenefit}
                >
                  {t("add-benefit")}{" "}
                </Button>

                <div className="d-flex justify-content-end">
                  <Button color="primary mt-2" size="md" onClick={handleSubmit}>
                    <span className="ml-1 mr-1 d-md-inline">{t("submit")}</span>
                  </Button>
                </div>
              </AvForm>
            </Col>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const {
    productpricing: {
      allProductPricingItems: {
        data: productpricing,
        loading: loadingProductPricing,
      },
    },
  } = state;

  return {
    productpricing,
    loadingProductPricing,
  };
}

export default connect(mapStateToProps, {
  getAllProductPricing,
})(EditPricingSessionForm);
