// import React, { useState } from 'react';
// import {
//   Modal,
//   ModalHeader,
//   ModalBody,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   Button,
// } from 'reactstrap';
// import LoginService from '../../services/LoginService';
// import Swal from 'sweetalert2';

// const OtpModal = ({ isOpen, toggle, handleOtpSubmit, email, password }) => {
//   console.log('EMAIL AND PASS', email, password);
//   const [otp, setOtp] = useState('');

//   const handleOtpChange = (event) => {
//     setOtp(parseInt(event.target.value));
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     // handleOtpSubmit(otp);
//     try {
//       let tokenData = await LoginService.ownerLogin(email, password, otp);
//       LoginService.setLoginData(tokenData, tokenData.role);
//       // this.redirectToReturnURL();
//       // window.location.href = '/';
//       Swal.fire({
//         icon: 'success',
//         title: 'Logged In',
//         text: 'You have been successfully Logged In.',
//         showConfirmButton: false,
//         timer: 1500,
//       }).then(() => {
//         window.location.href = '/';
//       });
//     } catch (e) {
//       console.error('login error', e);
//       Swal.fire({
//         icon: 'error',
//         title: 'ERROR',
//         text: 'Wrong Code.Please Enter Correct OTP ',
//         showConfirmButton: false,
//         timer: 2000,
//       });
//     }
//   };

//   return (
//     <Modal isOpen={isOpen} toggle={toggle}>
//       <ModalHeader toggle={toggle}>Enter OTP Code</ModalHeader>
//       <ModalBody>
//         <Form onSubmit={handleSubmit}>
//           <FormGroup>
//             <Label for="otp">OTP Code:</Label>
//             <Input
//               type="number"
//               id="otp"
//               value={otp}
//               onChange={handleOtpChange}
//               placeholder="Enter OTP code"
//               required
//             />
//           </FormGroup>
//           <Button color="primary" type="submit">
//             Submit OTP
//           </Button>
//         </Form>
//       </ModalBody>
//     </Modal>
//   );
// };

// export default OtpModal;

import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import LoginService from '../../services/LoginService';
import Swal from 'sweetalert2';

const modalStyle = {
  marginTop: '20rem',
};

const OtpModal = ({ isOpen, toggle, handleOtpSubmit, email, password }) => {
  console.log('EMAIL AND PASS', email, password);
  const [otp, setOtp] = useState('');

  const handleOtpChange = (event) => {
    setOtp(parseInt(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // handleOtpSubmit(otp);
    try {
      let tokenData = await LoginService.ownerLogin(email, password, otp);
      LoginService.setLoginData(tokenData, tokenData.role);
      // this.redirectToReturnURL();
      // window.location.href = '/';
      Swal.fire({
        icon: 'success',
        title: 'Logged In',
        text: 'You have been successfully Logged In.',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.href = '/';
      });
    } catch (e) {
      console.error('login error', e);
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'Wrong Code.Please Enter Correct OTP ',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={modalStyle}>
      <ModalHeader toggle={toggle}>Enter OTP Code</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="otp">OTP Code:</Label>
            <Input
              type="number"
              id="otp"
              value={otp}
              onChange={handleOtpChange}
              placeholder="Enter OTP code"
              required
            />
          </FormGroup>
          <Button color="primary" type="submit">
            Submit OTP
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default OtpModal;
