// initialState.js
const currentTime = new Date();
const formattedCurrentTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`;

export const initialState = {
  cart: [],
  totalQuantity: 0,
  totalamount: 0,
  subtotal: 0,
  totaltax: 0,
  loc_id: null,
  user_id: null,
  table_no: null,
  table_name: null,
  session_id: null,
  order_type: null,
  total_items: 3,
  order_time: new Date().toLocaleTimeString(),
  order_date: new Date().toLocaleDateString(),
  order_type_id: null,
  order_variant: "small",
  discount_id: 0,
  total_discount: 0,
  totalPrice: 0,
  table_id: null,
  session_date: new Date().toLocaleDateString(),
  start_time: formattedCurrentTime,
  comment: "",
  end_time: null,
  qrcode: "",
  qrcodedata: "",
  promocode_id: null,
  payment_status_id: null,
  order_tax: null,
};
