import React, {useState} from 'react';
import {grey} from "@mui/material/colors";
import {IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {AttachMoney, CalendarMonth, RemoveShoppingCart, TableRestaurant} from "@mui/icons-material";
import {clearCart} from "../../../../../../redux/actions/cart";
import {useDispatch, useSelector} from "react-redux";
import CartTotalModal from "../../../mainComponents/cartTotalModal";

const OrderAmountComponent = () => {
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart.cartMenu);
    const selectedTable = useSelector(state => state.pos.selectedTable.table);
    const locationData = useSelector(state => state.pos.locationData);
    const [openCartTotalModal, setOpenCartTotalModal] = useState(false);

    const handleClearCart = () => {
        dispatch(clearCart());
    }

    return(
        <>
            <Stack direction='row' sx={{backgroundColor: grey[200], borderRadius: '0.25rem', padding: '0.25rem 1rem', alignItems: 'center', justifyContent: 'space-between'}}>
                <Stack direction='row' alignItems='center' columnGap='0.25rem'>
                    <Tooltip title={'Remove all Items'} onClick={()=> handleClearCart()}>
                        <IconButton size='small'>
                            <RemoveShoppingCart />
                        </IconButton>
                    </Tooltip>
                    <IconButton size='small'>
                        <CalendarMonth />
                    </IconButton>
                    <IconButton onClick={()=> setOpenCartTotalModal(true)} size='small'>
                        <AttachMoney />
                    </IconButton>

                    {selectedTable &&
                        <Stack direction='row' columnGap={'0.5rem'} alignItems={'center'} sx={{backgroundColor: '#186218', padding: '0.25rem 0.5rem', borderRadius: '4px'}}>
                            <TableRestaurant fontSize='small' sx={{color: 'white'}}/>
                            <Typography variant='h6' sx={{fontSize: '1rem', color: 'white'}}>
                                {selectedTable.table_name}
                            </Typography>
                        </Stack>
                    }
                </Stack>
                <Typography variant='h6'>
                    Total Payable: {(locationData && locationData.Currency && locationData.Currency.currency_symbol) ? `${locationData.Currency.currency_symbol}` : ''}{`${(cart.totalamount) ? (cart.totalamount).toFixed(2) : 0}`}
                </Typography>
            </Stack>
            {openCartTotalModal && <CartTotalModal openModal={openCartTotalModal} setOpenModal={setOpenCartTotalModal}/>}
        </>
    )
}

export default OrderAmountComponent;