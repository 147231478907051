import React, { Component } from "react";
import CONSTANTS from "../../../constants";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import SpinnerComponent from "../../@vuexy/spinner/Fallback-spinner";
import { ClipLoader } from "react-spinners";

class MenuSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchForm: {
        menu: null,
        type: null,
        category: null,
      },
    };
    this.menuType = [
      {
        value: "kitchen",
        label: CONSTANTS.VARIABLES.MENUS.TYPE.KITCHEN,
      },
      {
        value: "bar",
        label: CONSTANTS.VARIABLES.MENUS.TYPE.BAR,
      },
      {
        value: "dessert",
        label: CONSTANTS.VARIABLES.MENUS.TYPE.DESSERT,
      },
      {
        value: "alternative_1",
        label: CONSTANTS.VARIABLES.MENUS.TYPE.ALTERNATIVE_1,
      },
      {
        value: "alternative_2",
        label: CONSTANTS.VARIABLES.MENUS.TYPE.ALTERNATIVE_2,
      },
    ];
    this.categoryOptions = props.categories.map((cat) => ({
      value: cat.id,
      label: cat.name,
    }));
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }

  updateSearchItem = (index, value) => {
    // console.log("index,value", index, value);
    let searchFormItem = this.state.searchForm;
    // console.log("searchFormItem", searchFormItem);
    searchFormItem[index] = value;
    this.setState({ searchForm: searchFormItem });
    // }
  };

  submitSearchForm = async (resetform = false) => {
    let applySearch = Object.values(this.state.searchForm).every((value) => {
      // console.log("search", value);
      // console.log("search", this.state.search);
      // 👇️ check for multiple conditions
      // if (value === null || value === undefined || value === "") {
      //   // return true;
      // this.submitSearchForm(true);
      // }
      return false;
    });
    if (!applySearch || resetform) {
      await this.props.searchMenuData(this.state.searchForm, resetform);
      // console.log("search2", this.state.searchForm);
    }
  };

  resetForm = () => {
    let searchFormItem = this.state.searchForm;
    searchFormItem["menu"] = null;
    searchFormItem["category"] = null;
    searchFormItem["type"] = null;
    this.setState({ searchForm: searchFormItem });
    // console.log("xxxxxxxx", this.state.searchForm);
    this.submitSearchForm(true);
  };

  render() {
    const { t } = this.props;

    return (
      <form>
        <div className="form-row align-items-center mb-1">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label htmlFor="menu_name">{t("menu-name")}</label>
              <input
                type="text"
                className="form-control"
                id="menu_name"
                aria-describedby="menuName"
                placeholder="e.g Pizza"
                value={
                  this.state.searchForm.menu == null
                    ? ""
                    : this.state.searchForm.menu
                }
                onChange={(e) => this.updateSearchItem("menu", e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="form-group">
              <label htmlFor="menu_category">{t("category")}</label>
              <select
                className="form-control"
                id="menu_category"
                onChange={(e) =>
                  this.updateSearchItem("category", e.target.value)
                }
                value={
                  this.state.searchForm.category == null
                    ? ""
                    : this.state.searchForm.category
                }
              >
                <option value={""}>{t("select-category")}</option>
                {this.props.categories.length > 0 &&
                  this.props.categories.map((category) => {
                    return (
                      <option
                        key={category.category_id}
                        value={category.category_id}
                      >
                        {category.title}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2">
            <div className="form-group">
              <label htmlFor="menu_type">{t("menu-type-0")}</label>
              <select
                className="form-control"
                id="menu_type"
                onChange={(e) => this.updateSearchItem("type", e.target.value)}
                value={
                  this.state.searchForm.type == null
                    ? ""
                    : this.state.searchForm.type
                }
              >
                <option value={""}>{t("select-menu-type")}</option>
                {this.menuType.map((type) => {
                  return (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-6 col-sm-3 col-md-2 col-lg-2">
            <button
              type="button"
              className="btn btn-primary btn-block text-center"
              onClick={() => this.submitSearchForm()}
            >
              {t("submit")}
            </button>
          </div>
          <div className="col-6 col-sm-3 col-md-2 col-lg-2 ">
            <button
              type="button"
              className="btn btn-secondary btn-block text-center"
              onClick={() => {
                this.resetForm();
              }}
            >
              {t("reset")}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation("global")(MenuSearchForm);
