import axios from "axios";
import CONSTANTS from "../constants";
import LoginService from "./LoginService";

export const getPremiumServicesByLocation = async (loc_id) => {
    let {token} = LoginService.getToken();
    try {
        const response = await axios.get(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.PREMIUM_SERVICES.GET_LOCATION_PREMIUM_SERVICES(loc_id)}`,
            {headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token }})

        if (response.data) {
            return response.data
        } else {
            return [];
        }
    } catch (err) {
        console.log(err)
    }
}

export const updatePremiumServices = async (data, premium_service_id, loc_id) => {
    let {token} = LoginService.getToken();
    try {
        const response = await axios.put(
            `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.PREMIUM_SERVICES.UPDATE_LOCATION_PREMIUM_SERVICES(loc_id, premium_service_id)}`,
            data,
            {headers: {...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token}}
        )

        if (response.data) {
            return {error: false, data: response.data}
        } else {
            return {error: true, message: 'Update Premium Service Failed'}
        }
    } catch (err) {
        console.log(err)
        return {error: true, message: 'Update Premium Service Failed'}
    }
}

export const deletePremiumService = async (service_id) => {
    let {token} = LoginService.getToken();
    try {
        const response = await axios.delete(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.PREMIUM_SERVICES.DELETE_LOCATION_PREMIUM_SERVICES(service_id)}`,
            {headers: {...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token}})

        if (!response || !response.data) {
            return {error: true, message: "Failed to Delete Premium Service"}
        } else {
            return {error: false, data: response.data}
        }
    } catch (err) {
        console.log(err)
        return {error: true, message: "Failed to Delete Premium Service"}
    }
}

export const addNewPremiumService = async (loc_id, data) => {
    let {token} = LoginService.getToken();
    try {
        const response = await axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.PREMIUM_SERVICES.ADD_LOCATION_PREMIUM_SERVICES(loc_id)}`,
            data,
            {headers: {...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token, 'Content-Type': 'multipart/form-data'}})

        if (response.data) {
            return {error: false, data: response.data}
        } else {
            return {error: true, message: "Failed to Add New Premium Service"}
        }
    } catch (err) {
        console.log(err)
        return {error: true, message: "Failed to Add New Premium Service"}
    }
}