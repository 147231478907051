import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter, Table,Card } from 'reactstrap';
import QRCode from 'qrcode.react'; 
import { getAllRooms} from '../../redux/actions/rooms';

class ListRooms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      generatedQrCode: '', 
      clickedQrCodeData: null,
    };
  }

  componentDidMount() {

    this.props.getAllRooms();
   
  }
  

  
  generateQrCode(qrCodeData) {

    this.setState({ clickedQrCodeData: qrCodeData });
  }
 

  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));

    
    if (!this.state.isModalOpen) {
      this.props.reset('roomForm');
    }
  };
 
 
  

  render() {
    const { handleSubmit, submitting, rooms, location,locationName } = this.props;
    const { clickedQrCodeData } = this.state;
    return (
      <>
        {clickedQrCodeData && (
          <Modal isOpen={true} toggle={() => this.setState({ clickedQrCodeData: null })}>
            <ModalHeader>QR Code</ModalHeader>
            <ModalBody>
            <div style={{ textAlign: 'center' }}>
             
                <QRCode
                  value={clickedQrCodeData.qrcodedata}
                  size={150} 
                  style={{ marginBottom: '10px' }}
                />
               <div>
                <Button
                  color="primary"
                  onClick={() => window.open(clickedQrCodeData.qrcodedata, '_blank')}
                >
                  Open URL
                </Button>
                
                
</div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.setState({ clickedQrCodeData: null })}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <div className="d-flex justify-content-end mb-3 "> 
     

</div>
       
        <Card>
  <Table responsive>
    <thead>
      <tr>
        {/* <th>Edit</th> */}
        <th>Room Id</th>
        <th>Room Name</th>
        <th>Hotel Name</th>
        <th>Hotel Id</th>
        <th>Status</th>
        <th>QR-Code</th>
      
      </tr>
    </thead>
    <tbody>
      {rooms && rooms.length > 0 ? (
  
        rooms.map((item) => (
          <tr key={item.id}>
           
            <td>{item.room_id}</td>
            <td>{item.room_name}</td>
            <td>{item.hotel_name}</td>
            <td>{item.hotel_id}</td>
            <td>{item.status}</td>
            <td>

            
            <td>
  {item.qrcodedata && (
    <div
      style={{
        position: 'relative',
        margin: '5px', // Add margin to create spacing between QR codes
      }}
      onClick={() => this.generateQrCode(item)}
    >
      <QRCode
        value={item.qrcodedata}
        size={50}
        style={{
          position: 'absolute',
          top: '50%',
          cursor: 'pointer',
          left: '50%',
          transform: 'translate(-30%, -30%)',
        }}
      />
    </div>
  )}
</td>

     
            </td>
          
          </tr>
        ))
      ) : (
  
        <tr>
          <td colSpan="6" style={{ textAlign: 'center', paddingTop: '20px' }}>
            No rooms found for {locationName}
          </td>
        </tr>
      )}
    </tbody>
  </Table>
</Card>


      </>
    );
  }
}

function mapStateToProps(state) {
  return {
  
    rooms: state.roomReducer.allRooms.data,
 
  };
}

export default connect(mapStateToProps, {
 
  getAllRooms,

})(reduxForm({ form: 'roomForm' })(ListRooms));