// rootReducer.js
import { combineReducers } from "redux";
import {
  allRooms,
  roomsByLocation,
  roomById,
  deleteRoom,
  allSessionrooms,
} from "./RoomsReducer"; // Import your rooms reducer

const roomReducer = combineReducers({
  allRooms,
  roomsByLocation,
  roomById,
  deleteRoom,
  allSessionrooms,
});

export default roomReducer;
