import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import GlobalModifiersList from "../GlobalModifiersList";
import { updateMenuOptions } from "../../../redux/actions/menus";
import { getMenuGroupedByCategory } from "../../../redux/actions/categories";
import { ClipLoader } from "react-spinners";
import { X } from "react-feather";
import { deleteOption, getAll } from "../../../redux/actions/options";
import { useTranslation } from "react-i18next";

const GlobalModifiersFormModal = (props) => {
  const [t, i18next] = useTranslation("global");
  const dispatch = useDispatch();

  const modifiersList = useSelector((state) => state.options.options.data);

  let {
    selectedModifiers,
    isOpen,
    toggleModal,
    loc_id,
    showConfirmationDialog,
    menu_id,
    title,
  } = props;

  console.log("selected", props);

  const [items, setItems] = useState(modifiersList ? modifiersList : []);
  console.log(items, "items");
  const [selectedIds, setSelectedIds] = useState(selectedModifiers);
  const [prevState, setPrevState] = useState(isOpen);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    // const lang2 = localStorage.getItem("lang");
    // i18next.changeLanguage(lang2);

    if (isOpen !== prevState) {
      setSelectedIds(props.selectedModifiers ? props.selectedModifiers : []);
      setPrevState(isOpen);
    }
    setItems(modifiersList);
  }, [modifiersList, props]);

  const updateModifiers = (modifiers) => {
    setItems(modifiers);
  };

  const saveModifiers = async () => {
    setSaving(true);
    await dispatch(updateMenuOptions(loc_id, menu_id, selectedIds));
    if (props.isDummyItem) {
      props.fetchGlobalModifiersForItem();
      toggleModal(true);
      setSaving(false);
      return;
    }
    await dispatch(getMenuGroupedByCategory(loc_id));
    toggleModal(true);
    setSaving(false);
  };

  const cancelEvent = () => {
    if (JSON.stringify(selectedIds) !== JSON.stringify(selectedModifiers)) {
      showConfirmationDialog(
        async () => {
          toggleModal();
        },
        null,
        t("are-you-sure-you-want-to-return-without-saving")
      );
    } else {
      toggleModal();
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={cancelEvent}
        className="modal-dialog-centered d-sm-margin-0 p-0"
        backdrop="static"
      >
        <div className="modal-header display-flex full-width flex-space-between">
          <h5 className="modal-title">
            {t("add-variants-to")} {title}
          </h5>
          <X size={20} onClick={cancelEvent} />
        </div>
        <ModalBody>
          <GlobalModifiersList
            loc_id={loc_id}
            modifiersList={items}
            setSelectedIds={setSelectedIds.bind(this)}
            selectedModifiers={selectedIds}
            updateModifiers={updateModifiers}
            showConfirmationDialog={showConfirmationDialog}
          />
        </ModalBody>
        <ModalFooter>
          <div className="d-none d-sm-flex flex-space-between full-width">
            <div></div>
            <div>
              <Button
                color="dark"
                disabled={saving}
                onClick={() => {
                  if (saving) return;
                  cancelEvent();
                }}
              >
                {t("cancel")}{" "}
              </Button>{" "}
              <Button color="primary" disabled={saving} onClick={saveModifiers}>
                {saving ? <ClipLoader size={15} /> : t("save")}
              </Button>{" "}
            </div>
          </div>
          <div className="d-sm-none col-12">
            <Button
              className="col-12"
              color="primary"
              disabled={saving}
              onClick={saveModifiers}
            >
              {saving ? <ClipLoader size={15} /> : t("save")}
            </Button>{" "}
            <Button
              className="col-12 my-50"
              color="dark"
              disabled={saving}
              onClick={() => {
                if (saving) return;
                cancelEvent();
              }}
            >
              {t("cancel")}{" "}
            </Button>{" "}
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default GlobalModifiersFormModal;
