import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  FormGroup,
  Label,
  Card,
  Input,
  Badge,
} from "reactstrap";

import { Edit2, Trash2 } from "react-feather";
import ValidationHelper from "../../helpers/ValidationHelper";
import {
  getCurrencies,
  getCategories,
} from "../../redux/actions/configurations";
import {
  getAllRooms,
  getRoomsByLocationId,
  saveUpdateRoom,
  getRoomByRoomId,
  deleteRoom,
  updateRoom,
} from "../../redux/actions/rooms";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import QRCode from "qrcode.react";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
class RoomForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      generatedQrCode: "",
      clickedQrCodeData: null,
      isEdit: false,
      roomId: "",
    };
  }

  componentDidMount() {
    this.props.getCurrencies();
    this.props.getCategories();
    this.props.getAllRooms();
    // this.props.getRoomByRoomId()
    this.props.getRoomsByLocationId(this.props.location.loc_id);
  }
  generateQrCode(qrCodeData) {
    this.setState({ clickedQrCodeData: qrCodeData });
  }
  async save(values) {
    const dynamicRoomName = this.state.selectedRoomId
      ? this.state.selectedRoomId.room_name
      : values.room_name;
    const dynamicStatus = this.state.selectedRoomId
      ? this.state.selectedRoomId.status
      : values.status;
    const roomData = {
      hotel_id:
        values.loc_id || (this.props.location && this.props.location.loc_id),
      room_name: dynamicRoomName,
      status: dynamicStatus,
    };
    try {
      const response = await this.props.saveUpdateRoom(roomData, values.loc_id);
      console.log("API Response:", response);
      this.props.getRoomsByLocationId(this.props.location.loc_id);
      // Handle success response
    } catch (error) {
      console.error("Error saving room:", error);
      // Handle error
    }
    console.log("Submitted:", values);
  }
  async update(values) {
    console.log("values", values);

    const roomData = {
      hotel_id:
        values.loc_id || (this.props.location && this.props.location.loc_id),
      room_name: this.state.selectedRoomId.room_name
        ? this.state.selectedRoomId.room_name
        : values.room_name,
      status: this.state.selectedRoomId.status
        ? this.state.selectedRoomId.status
        : values.status,
    };
    console.log(roomData, "roomData");
    try {
      const room_id = this.state?.selectedRoomId.room_id;
      console.log(this.props, "update");
      const response = await this.props.updateRoom(room_id, roomData);
      console.log("API Response:", response);
      this.props.getRoomsByLocationId(this.props.location.loc_id);
    } catch (error) {
      console.error("Error update room:", error);
    }
  }
  handleDeleteButtonClick = (roomId) => {
    console.log("ROOMID", roomId);
    this.setState({
      isDeleteConfirmationModalOpen: true,
      roomIdToDelete: roomId,
    });
  };
  toggleModal = () => {
    const { isModalOpen } = this.state;
    this.setState({
      isModalOpen: !isModalOpen,
      isEdit: isModalOpen ? false : this.state.isEdit,
    });
    if (isModalOpen) {
      this.props.reset("roomForm");
    }
  };
  handleModalSubmit = (values) => {
    console.log("values", values);

    if (this.state.isEdit) {
      this.update(values);
    } else {
      this.save(values);
    }

    this.setState({ isEdit: false });
    this.toggleModal();
    this.props.reset("roomForm");
  };
  handleEditButtonClick = async (roomId) => {
    console.log(" roomId:", roomId, this.props);

    try {
      const data = await this.props.getRoomByRoomId(roomId);
      console.log("getRoomByRoomId response:", data);

      this.setState({
        isModalOpen: true,
        selectedRoomId: data,
        isEdit: true,
      });
      // this.props.initialize(data)
    } catch (error) {
      console.error("Error in getRoomByRoomId:", error);
    }
  };

  handel = (value) => {
    console.log("hhh", value);
    this.setState((prev) => ({
      selectedRoomId: {
        ...prev.selectedRoomId,
        room_name: value,
      },
    }));
    this.setState((prev) => ({
      selectedRoomId: {
        ...prev.selectedRoomId,
        room_name: value,
      },
    }));
  };
  handleStatusChange = (value) => {
    console.log(value, "status");
    this.setState((prev) => ({
      selectedRoomId: {
        ...prev.selectedRoomId,
        status: value,
      },
    }));
    console.log(value, "status");
    this.setState((prev) => ({
      selectedRoomId: {
        ...prev.selectedRoomId,
        status: value,
      },
    }));
  };
  handleDeleteConfirmation = () => {
    const { roomIdToDelete } = this.state;
    console.log("ROOMIDTO DLETED", roomIdToDelete);
    if (roomIdToDelete) {
      this.props.deleteRoom(this.props.location.loc_id, roomIdToDelete);
    }
    this.setState({
      isDeleteConfirmationModalOpen: false,
      roomIdToDelete: null,
    });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      rooms,
      location,
      locationName,
      isLoading,
    } = this.props;
    console.log("ROOMS", isLoading);
    // const { clickedQrCodeData, isEdit } = this.state;
    // const { isDeleteConfirmationModalOpen } = this.state;
    // const { handleSubmit, submitting, rooms, location, locationName } = this.props;
    const { clickedQrCodeData, isEdit, error } = this.state;
    const { isDeleteConfirmationModalOpen, selectedRoomId, room_name, status } =
      this.state;

    console.log(room_name, "::::");
    return (
      <>
        <ConfirmationModal
          header="Delete Room"
          title="Are you sure you want to delete this room?"
          isOpen={isDeleteConfirmationModalOpen}
          accept={this.handleDeleteConfirmation}
          acceptTitle="Yes"
          reject={() =>
            this.setState({
              isDeleteConfirmationModalOpen: false,
              roomIdToDelete: null,
            })
          }
          rejectTitle="No"
        />

        {clickedQrCodeData && (
          <Modal
            isOpen={true}
            toggle={() => this.setState({ clickedQrCodeData: null })}
          >
            <ModalHeader>QR Code</ModalHeader>
            <ModalBody>
              <div style={{ textAlign: "center" }}>
                <QRCode
                  value={clickedQrCodeData.qrcodedata}
                  size={150}
                  style={{ marginBottom: "10px" }}
                />
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      window.open(clickedQrCodeData.qrcodedata, "_blank")
                    }
                  >
                    Open URL
                  </Button>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => this.setState({ clickedQrCodeData: null })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
          <Form onSubmit={handleSubmit(this.handleModalSubmit)}>
            <ModalHeader toggle={this.toggleModal}>
              {isEdit ? "Edit Room" : "Add New Room"}
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="room_name">Room Name</Label>
                <Input
                  required
                  value={isEdit ? selectedRoomId?.room_name : room_name}
                  name="room_name"
                  component="input"
                  type="text"
                  placeholder="Enter Room Name"
                  className="form-control"
                  onChange={(e) => this.handel(e.target.value)}
                  validate={[
                    ValidationHelper.validateRoomName(
                      selectedRoomId?.room_name
                    ),
                  ]}
                />
                {selectedRoomId?.room_name === "" && (
                  <span
                    style={{ color: "red", padding: "7px", fontSize: "12px" }}
                  >
                    Room is required
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <select
                  required
                  value={isEdit ? selectedRoomId?.status : status}
                  component="select"
                  className="form-control"
                  onChange={(e) => this.handleStatusChange(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                {selectedRoomId?.status === "" && (
                  <span style={{ color: "red", padding: "7px" }}>
                    Status is required
                  </span>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary">
                {isEdit ? "Update Room" : "Save Room"}
              </Button>
              <Button color="danger" onClick={this.toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        <div className="d-flex justify-content-end mb-3 ">
          <Button type="button" color="primary" onClick={this.toggleModal}>
            Add New Room
          </Button>
        </div>

        <Card>
          <Table responsive>
            <thead>
              <tr>
                {/* <th>Edit</th> */}
                <th>Room Id</th>
                <th>Room Name</th>
                {/* <th>Hotel Name</th> */}
                <th>Hotel Id</th>
                <th>Status</th>
                <th>QR-Code</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {rooms && rooms.length > 0 ? (
                rooms.map((item) => (
                  <tr key={item.id}>
                    {/* <td>
              <Button
                color="warning"
                onClick={() => this.handleEditButtonClick(item.room_id)}
              >
                Edit
              </Button>
            </td> */}
                    <td>{item.room_id}</td>
                    <td>{item.room_name}</td>
                    {/* <td>{locationName}</td> */}
                    <td>{item.hotel_id}</td>
                    <td>{item.status}</td>
                    <td>
                      <td>
                        {item.qrcodedata && (
                          <div
                            style={{ position: "relative" }}
                            onClick={() => this.generateQrCode(item)}
                          >
                            <QRCode
                              value={item.qrcodedata}
                              size={50}
                              style={{
                                position: "absolute",
                                top: "50%",
                                cursor: "pointer",
                                left: "50%",
                                transform: "translate( -30%, -30%)",
                              }}
                            />
                          </div>
                        )}
                      </td>
                    </td>
                    <td style={{ display: "flex", gap: "8px" }}>
                      <Button
                        color="danger"
                        onClick={() =>
                          this.handleDeleteButtonClick(item.room_id)
                        }
                      >
                        <Trash2 size={20} />
                      </Button>

                      <Button
                        color="danger"
                        onClick={() => this.handleEditButtonClick(item.room_id)}
                      >
                        <Edit2 size={20} />
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    style={{ textAlign: "center", paddingTop: "20px" }}
                  >
                    No rooms found for {locationName}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.locations.location.data,
    rooms: state.roomReducer.roomsByLocation.data,
    isLoading: state.roomReducer.roomsByLocation.loading,
    initialValues: state.roomReducer.roomById.data,
  };
}

export default connect(mapStateToProps, {
  getCurrencies,
  getCategories,
  getAllRooms,
  getRoomsByLocationId,
  saveUpdateRoom,
  updateRoom,
  getRoomByRoomId,
  deleteRoom,
})(reduxForm({ form: "roomForm" })(RoomForm));

// import React, { Component } from 'react';
// import { connect,useSelector } from 'react-redux';
// import { reduxForm, Field } from 'redux-form';
// import { Button, Form, Label, Card, CardHeader, CardBody } from 'reactstrap';
// import Text from '../shared/fields/Text';
// import Select from '../shared/fields/Select';
// import Checkbox from '../shared/fields/Checkbox';
// import { getCurrencies, getCategories } from '../../redux/actions/configurations';
// import ValidationHelper from '../../helpers/ValidationHelper';
// import { getAllRooms,getRoomsByLocationId, saveUpdateRoom} from '../../redux/actions/rooms';
// import RoomService from '../../services/RoomService';
// import {  Table } from "reactstrap";
// import { Edit2 } from "react-feather";
// import { Modal } from 'reactstrap';

// class RoomForm extends Component {

//   componentDidMount() {
//     this.props.getCurrencies();
//     this.props.getCategories();
//     this.props.getAllRooms();
//       this.props.getRoomsByLocationId(this.props.location.loc_id);
//   }

//   async saveRoom(values) {
//     // Use the loc_id from the selected location as hotel_id
//     values.hotel_id = values.loc_id;

//     // If location.loc_id is available, use it in the handleSubmit
//     if (this.props.location && this.props.location.loc_id) {
//       values.hotel_id = this.props.location.loc_id;
//     }

//     try {
//       const response = await this.props.saveUpdateRoom(values, values.loc_id);
//       // You can handle the response here if needed
//       console.log('API Response:', response);
//       this.props.getRoomsByLocationId(this.props.location.loc_id);
//     } catch (error) {
//       console.error('Error saving room:', error);
//     }

//     console.log('Submitted:', values);
//   }

//   render() {
//     const {loading, handleSubmit, submitting,rooms,location } = this.props;
//     console.log("ROOMS",rooms)
//     console.log("Location",location)

//     if (loading) {

//       return <div>Loading...</div>;
//     }

//       return (
//         <Form onSubmit={handleSubmit(this.saveRoom.bind(this))}>
//           <Card>
//             <CardHeader>
//               <strong>Room Creation</strong>
//             </CardHeader>
//             <CardBody>
//               <Field
//                 name="room_name"
//                 component={Text}
//                 label="Room Name"
//                 validate={ValidationHelper.required}
//               />

//               <Field
//                 name="status"
//                 component={Text}
//                 label="Status"
//                 validate={ValidationHelper.required}
//               />

//             </CardBody>
//           </Card>
//           <div className="actions">
//             <Button type="submit" disabled={submitting}>
//               Save Room
//             </Button>
//           </div>
//           <Table responsive>
//           <thead>
//             <tr>

//               <th>ID</th>
//               <th>Room Name</th>
//               <th>Hotel Id</th>
//               <th>Status</th>

//             </tr>
//           </thead>
//           <tbody>
//             {rooms?.map((item) => (
//               <>
//                 <tr key={item.id}>

//                   <td>{item.room_id}</td>
//                   <td>{item.room_name}</td>
//                   <td>{item.hotel_id}</td>
//                   <td>{item.status}</td>

//                 </tr>
//               </>
//             ))}
//           </tbody>
//         </Table>

//         </Form>
//       );

//   }
// }

// function mapStateToProps(state) {
//   console.log("STATE",state)
//   return {

//     loading:
//       state.configurations.currencies.loading ||
//       state.configurations.categories.loading,
//     location: state.locations.location.data,
//     rooms: state.roomReducer.allRooms.data,

//   };
// }

// export default connect(mapStateToProps, {
//   getCurrencies,
//   getCategories,
//   getAllRooms,
//   getRoomsByLocationId,
//   saveUpdateRoom

// })(reduxForm({ form: 'roomForm' })(RoomForm));

// // import React, { useEffect, useState } from "react";
// // import { connect, useSelector } from "react-redux";
// // import { getAllPricing } from "../../redux/actions/pricing";
// // import { getAllProductPricing } from "../../redux/actions/productpricing";
// // import CONSTANTS from "../../constants";

// // import { getAllRooms } from '../../redux/actions/rooms';
// // import { Link } from 'react-router-dom';

// // import "../../assets/scss/pages/page-pricing.scss";
// // import { Button, Table } from "reactstrap";

// // import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";

// // import "../../assets/css/collapsibeCardComponent.scss";

// // import { Edit2 } from "react-feather";

// // import { useTranslation } from "react-i18next";

// // const RoomForm = (props) => {
// //   const [t, i18n] = useTranslation("global");
// //   useEffect(() => {
// //     const lang2 = localStorage.getItem("lang");
// //     i18n.changeLanguage(lang2);
// //   }, []);
// //   useEffect(() => {
// //     const rooms = async () => {
// //       await props.getAllRooms();
// //     };
// //     rooms();
// //   }, []);

// //   const { data: roomReducer, loading: roomsLoading } = useSelector(
// //     (store) => store.roomReducer.allRooms
// //   );
// //   console.log("Room Data:", roomReducer)

// //   const handleEdit = (
// //     packageId,
// //     title,
// //     price,
// //     description,
// //     PerksInPackages,
// //     ProductsInPackages
// //   ) => {
// //     const editData = {
// //       packageId,
// //       title,
// //       price,
// //       description,
// //       PerksInPackages,

// //       ProductsInPackages,
// //     };

// //     props.history.push({
// //       pathname: `/pricing/edit/${packageId}`,
// //       state: editData,
// //     });
// //   };

// //   if (roomsLoading) return <SpinnerComponent />;

// //   return (
// //     <>
// //       <div className="pricing-table-container">
// //         {/* <Button
// //           className="mb-1 col-sm-12 col-md-6 col-lg-3 btn btn-primary float-right"
// //           color="primary"
// //           size="md"
// //           // onClick={toggleMenuItemModal}
// //           onClick={() => props.history.push("/pricing/new")}
// //         >
// //           <Edit2 size={16} />
// //           <span className="ml-1 d-md-inline">{t('add-new')} </span>
// //         </Button> */}
// //            <Link
// //               className="col-sm-12 col-md-6 col-lg-3 btn btn-primary float-right"
// //               to={`${CONSTANTS.BASE_URL}hotels/new`}
// //             >
// //               Add New Room
// //             </Link>

// //         <Table responsive>
// //           <thead>
// //             <tr>
// //               <th>{t('edit')}</th>
// //               <th>ID</th>
// //               <th>Room Name</th>
// //               <th>Hotel Id</th>
// //               <th>Status</th>

// //             </tr>
// //           </thead>
// //           <tbody>
// //             {roomReducer?.map((item) => (
// //               <>
// //                 <tr key={item.id}>
// //                   <td>
// //                     <Button
// //                       color="primary"
// //                       size="sm"
// //                       onClick={() =>
// //                         handleEdit(
// //                           item.room_id,
// //                           item.room_name,
// //                           item.hotel_id

// //                         )
// //                       }
// //                     >
// //                       <Edit2 size={16} />
// //                     </Button>
// //                   </td>
// //                   <td>{item.room_id}</td>
// //                   <td>{item.room_name}</td>
// //                   <td>{item.hotel_id}</td>
// //                   <td>{item.status}</td>

// //                 </tr>
// //               </>
// //             ))}
// //           </tbody>
// //         </Table>
// //       </div>
// //     </>
// //   );
// // };

// // function mapStateToProps(state) {
// //   const {

// //     roomReducer: {
// //       allRooms: { data: rooms, loading: roomsLoading },
// //     },
// //   } = state;

// //   return {

// //     rooms, roomsLoading
// //   };
// // }

// // export default connect(mapStateToProps, {

// //   getAllRooms,

// // })(RoomForm);
