import CONSTANTS from '../../../constants';
import SurveyResponse from '../../../services/SurveyResponse';

export function getAllSurveyResponse(loc_id , page) {
    return async dispatch => {
        try {
            dispatch({ type: CONSTANTS.ACTIONS.SURVEYRESPONSE.GET_ALL, response: [], loading: true });
            let response = await SurveyResponse.getAllSurveyResponse(loc_id , page);
            dispatch({ type: CONSTANTS.ACTIONS.SURVEYRESPONSE.GET_ALL, response: response, loading: false });
        } catch (error) {
            console.log("Dispatch Error Survey Question.");
            dispatch({ type: CONSTANTS.ACTIONS.SURVEYRESPONSE.GET_ALL, response: [], loading: false });
        }
    };
}
