import React from "react";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import {
  Label,
  Row,
  Col,
  Card,
  Input,
  CardBody,
  CardHeader,
  Button,
  ButtonGroup,
} from "reactstrap";
import LocationService from "../../services/LocationService";
import CONSTANTS from "../../constants";
import { connect } from "react-redux";
import { getAllLocations } from "../../redux/actions/locations";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import TableService from "../../services/TableService";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import { createTable } from "../../redux/actions/tables";
import Toggle from "react-toggle";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class TablesForm extends React.Component {
  state = {
    activeStep: 0,
    isOpenSelect: false,
    selectLocation: false,
    editMode: false,
    table: {
      table_id: "",
      table_name: "",
      min_capacity: "",
      max_capacity: "",
      loc_id: "",
      table_status: 1,
    },
    location: {
      loc_id: 0,
      name: this.props.t('all-venues'),
      Currency: {
        currency_code: "PKR",
      },
      decimal_places: 2,
    },
    loading: false,
    saving: false,
    tables: [],
    errorSaving: false,
    errorWallet: false,
    isOpen: false,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: this.props.t('wallet'),
      header: this.props.t('wallet'),
    },
    errorDialog: {
      show: false,
      rejectCallback: () => {},
      title: this.props.t('tables'),
      header: this.props.t('no-table-found'),
    },
  };

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }

  async getTableFromId(id) {
    let table = await TableService.getTable(this.state.location.loc_id, id);
    return table;
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        await this.setState({ loading: false });
      } else {
        await this.setState({ loading: false, selectLocation: true });
      }
      let tables = this.state.tables;
      if (window.location.pathname.indexOf("edit") !== -1) {
        const parts = window.location.pathname.split("/");
        let table = await this.getTableFromId(parts[parts.length - 1]);
        tables.push(table);
        await this.setState({ tables, editMode: true });
      } else {
        this.addTable();
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  async getStats(loc_id) {
    await this.props.getCustomersStats(loc_id, this.state.filters);
  }

  onBlur() {
    this.filterUpdated();
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  toggleItemsModal() {
    this.setState({ isMenuItemModalOpen: !this.state.isMenuItemModalOpen });
  }

  async updateValue(value, type) {
    if (!isNaN(value) && value) value = parseInt(value);
    let currency = JSON.parse(JSON.stringify(this.state.currency));
    let obj = currency;
    let parts = type.split(".");
    const n = parts.length - 1;
    for (let i = 0; i < n; i++) obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ currency });
  }

  async setOpen(open) {
    await this.setState({ isOpen: open });
  }

  async setOpenSelect() {
    await this.setState({ isOpenSelect: !this.state.isOpenSelect });
  }

  async addTable() {
    const {
      state: { tables },
    } = this;
    tables.push({
      table_id: "",
      table_name: "",
      min_capacity: 0,
      max_capacity: 0,
      loc_id: "",
      table_status: 1,
    });
    await this.setState({ tables });
  }

  handleStepChange(activeStep) {
    this.setState({ activeStep });
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  onConditionChange = (selectedOption) => {
    this.updateValue(selectedOption.value, "plan_time");
  };

  async deleteTable(index) {
    const {
      state: { tables },
    } = this;
    let new_tables = tables;
    new_tables.splice(index, 1);
    await this.setState({ tables: new_tables });
  }

  async onChangeInput(event, index, type) {
    const {
      state: { tables },
    } = this;
    let new_tables = tables;
    if (type === "toggle") {
      new_tables[index][event.target.name] = event.target.checked;
    } else {
      new_tables[index][event.target.name] = event.target.value;
    }
    await this.setState({ tables: new_tables });
  }

  async saveTables() {
    await this.setState({ loading: true });
    try {
      let tables = await this.props.createTable(
        this.state.location.loc_id,
        this.state.tables
      );
    } catch (error) {
      await this.setState({ loading: false });
      this.showErrorDialog(null, error, this.props.t('warning'));
      return;
    }
    await this.props.history.replace("/tables");
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  renderTables(tables, locations) {
    const { t } = this.props;
    return (
      tables &&
      tables.map((table, i) => {
        return (
          <React.Fragment key={i}>
            <Row className="p-1 align-items-center">
              <Col
                md={this.state.editMode === false ? 5 : 6}
                sm={this.state.editMode === false ? 6 : 5}
              >
                <AvGroup>
                  <AvInput
                    data-id={table.table_name}
                    name={`table_name`}
                    placeholder={t("table-name")}
                    type="text"
                    required
                    value={table.table_name}
                    onChange={(e) => this.onChangeInput(e, i)}
                  />
                  <AvFeedback>{t("please-enter-table-name")}</AvFeedback>
                </AvGroup>
              </Col>
              <Col md={2} sm={2}>
                <AvGroup>
                  <AvInput
                    data-id={table.min_capacity}
                    name={`min_capacity`}
                    placeholder={t("min-capacity-0")}
                    type="number"
                    required
                    value={table.min_capacity}
                    onChange={(e) => this.onChangeInput(e, i)}
                  />
                  <AvFeedback>{t("min-capacity")}</AvFeedback>
                </AvGroup>
              </Col>
              <Col md={2} sm={2}>
                <AvGroup>
                  <AvInput
                    data-id={table.max_capacity}
                    name={`max_capacity`}
                    placeholder={t("max-capacity-0")}
                    type="number"
                    required
                    value={table.max_capacity}
                    onChange={(e) => this.onChangeInput(e, i)}
                  />
                  <AvFeedback>{t("max-capacity")}</AvFeedback>
                </AvGroup>
              </Col>
              <Col md="1" sm="1">
                <AvGroup>
                  <Toggle
                    name={"table_status"}
                    // defaultChecked={table.table_status}
                    defaultChecked={Boolean(table.table_status)}
                    className="switch-danger align-self-baseline"
                    onChange={(e) => this.onChangeInput(e, i, "toggle")}
                  />
                </AvGroup>
              </Col>
              {this.state.editMode === false && (
                <Col md={2} sm={2}>
                  <AvGroup>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => this.deleteTable(i)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </AvGroup>
                </Col>
              )}
            </Row>
          </React.Fragment>
        );
      })
    );
  }

  render() {
    const { t } = this.props;
    const {
      props: { locations },
    } = this;

    const {
      state: {
        errorSaving,
        tables,
        editMode,
        confirmationDialog,
        selectLocation,
        location,
        loading,
        errorDialog,
      },
    } = this;

    const locationName = location ? location.name : "";

    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <div className="location">
        <div className="display-block mb-1">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.changeLocation.bind(this)}
          />
        </div>
        <Card className="full-width">
          <AvForm onValidSubmit={() => this.saveTables()}>
            {/* <>
              <Row className="p-1">
                <Col
                  md={editMode === false ? 5 : 6}
                  sm={editMode === false ? 5 : 6}
                >
                  <Label>Table Name</Label>
                </Col>
                <Col md={2} sm={2}>
                  <Label>Minimum Capacity</Label>
                </Col>
                <Col md={2} sm={2}>
                  <Label>Maximum Capacity</Label>
                </Col>
                <Col md="1" sm="1">
                  <Label>Status</Label>
                </Col>
                {editMode == false ? (
                  <Col md={2} sm={2}>
                    <Label>Action</Label>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </> */}

            {tables.length > 0 ? this.renderTables(tables, locations) : ""}
            <Row className="p-1 ml-1">
              <div className="actions ">
                {tables.length > 0 && (
                  <button className="btn btn-primary">
                    {t("save-tables")}
                  </button>
                )}
                &nbsp;
                {editMode != true && (
                  <button
                    className="btn btn-primary"
                    onClick={() => this.addTable()}
                  >
                    {t("add-new-table")}{" "}
                  </button>
                )}
              </div>
            </Row>
          </AvForm>
        </Card>

        <ConfirmationModal
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          accept={confirmationDialog.acceptCallback.bind(this)}
          reject={confirmationDialog.rejectCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={"OK"}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locations, loading: loading },
    },
  } = state;

  return { locations, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, { createTable, getAllLocations })(TablesForm)
);
