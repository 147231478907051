// import React, { Component } from "react";
// import { connect } from "react-redux";
// import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
// import LocationService from "../../services/LocationService";
// import {
//   getAllSurveyQuestions,
//   changeQuestionStatus,
//   saveUpdateQuestion,
//   deleteQuestions,
// } from "../../redux/actions/surveyquestions";
// import QuestionnaireList from "./questionnaireList";
// import SelectLocation from "../shared/SelectLocation";
// import LocationSelectHeader from "../shared/LocationSelectHeader";
// import Swal from "sweetalert2";
// import ConfirmationModal from "../shared/forms/ConfirmationModal";
// import toast, { Toaster } from "react-hot-toast";
// import {
//   Modal,
//   ModalBody,
//   ModalFooter,
//   Label,
//   FormGroup,
//   Input,
//   Col,
//   Button,
// } from "reactstrap";
// import { X, Plus } from "react-feather";
// import Select from "react-select";
// import Radio from "../@vuexy/radio/RadioVuexy";

// import { withTranslation } from "react-i18next";
// import i18n from "i18next";

// let initialState = {
//   question: "",
//   type: "Questionnaire",
//   status: "enabled",
// };

// class Questionnaire extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       location: null,
//       selectLocation: false,
//       loading: false,
//       confirmationDialog: {
//         show: false,
//         acceptCallback: () => {},
//         rejectCallback: () => {},
//         title: this.props.t("are-you-sure-to-close-this-session"),
//         header: this.props.t("close-session"),
//       },
//       isOpenModal: false,
//       selectedQuestion: initialState,
//       questionType: [
//         {
//           value: "Questionnaire",
//           label: this.props.t("questionnaire"),
//         },
//         {
//           value: "Ratings",
//           label: this.props.t("ratings"),
//         },
//         {
//           value: "Comments",
//           label: this.props.t("comments"),
//         },
//       ],
//       selectedQuestionType: {
//         value: "Questionnaire",
//         label: this.props.t("questionnaire"),
//       },
//       saving: false,
//       errorMsg: false,
//     };
//   }

//   async componentDidMount() {
//     const savedLanguage = localStorage.getItem("lang");
//     if (savedLanguage) {
//       i18n.changeLanguage(savedLanguage);
//     }

//     this.setState({ loading: true });
//     try {
//       const location = LocationService.getLocation();
//       if (location) {
//         await this.refreshData(location.loc_id);
//         this.setState({ loading: false });
//       } else {
//         this.setState({ loading: false, selectLocation: true });
//       }
//     } catch (e) {
//       console.log(e);
//       this.setState({ loading: false, error: true });
//     }
//   }

//   async refreshData(loc_id, updateLocation = true) {
//     let loc = this.state.location;
//     if (updateLocation) {
//       loc = await LocationService.getLocationFromServer(loc_id);
//     }
//     await this.props.getAllSurveyQuestions(loc.loc_id);
//     const location = {
//       loc_id: loc.loc_id,
//       location_status: loc.location_status,
//       name: loc.name,
//     };
//     this.setState({ location });
//   }

//   toggleLocation() {
//     this.setState({ selectLocation: !this.state.selectLocation });
//   }

//   changeLocation() {
//     this.setState({ selectLocation: true });
//   }

//   changeStatus = (item, title, message, status) => {
//     this.showConfirmationDialog(
//       async () => {
//         await this.props.changeQuestionStatus(item, status);
//       },
//       null,
//       message,
//       title
//     );
//   };

//   editQuestion = (item) => {
//     return this.setState({
//       isOpenModal: true,
//       selectedQuestion: item,
//       selectedQuestionType: {
//         label: item.type,
//         value: item.type,
//       },
//       saving: false,
//       errorMsg: false,
//     });
//   };
//   // deleteQuestionHandler = (question_id) => {
//   //   let loc = this.state.location;
//   //   console.log('LOC', loc);
//   //   // Call the deleteQuestions action with the question_id
//   //   this.props.deleteQuestions(loc.loc_id, question_id);
//   // };

//   deleteQuestionHandler = (question_id) => {
//     Swal.fire({
//       title: "Are you sure?",

//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "var(--primary)",
//       cancelButtonColor: "#6c757d",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         const loc = this.state.location;
//         // Call the deleteQuestions action with the question_id
//         this.props.deleteQuestions(loc.loc_id, question_id).then((response) => {
//           console.log("RESPONSE", response);
//           if (response === "Question deleted successfully") {
//             toast.success("Your question has been deleted.", {
//               duration: 2000,
//             });
//             this.props.getAllSurveyQuestions(loc.loc_id);
//           } else {
//             toast.success("Your question has been deleted.", {
//               duration: 2000,
//             });
//             this.props.getAllSurveyQuestions(loc.loc_id);
//           }
//         });
//       }
//     });
//   };
//   onUpdateValues = (value, type) => {
//     let selQues = this.state.selectedQuestion;
//     if (type === "status") {
//       value = value == 1 ? "enabled" : "disabled";
//     }
//     if (type === "question" && this.state.errorMsg) {
//       this.setState({ errorMsg: false });
//     }
//     selQues[type] = value;
//     this.setState({ selectedQuestion: selQues });
//   };

//   submitQuestion = () => {
//     if (
//       this.state.selectedQuestion.question === "" ||
//       this.state.selectedQuestion.question === null
//     ) {
//       this.setState({ errorMsg: true });
//       return;
//     }
//     this.setState({ saving: true });
//     let newQuestion = this.state.selectedQuestion;
//     if (newQuestion?.location_id === undefined) {
//       newQuestion.location_id = this.state.location.loc_id;
//     }
//     let questionSaved = this.props.saveUpdateQuestion(
//       newQuestion,
//       this.state.location.loc_id
//     );
//     if (questionSaved) {
//       this.setState({
//         saving: false,
//         isOpenModal: false,
//         selectedQuestion: {
//           ...initialState,
//           question: "",
//         },
//         selectedQuestionType: {
//           value: "Questionnaire",
//           label: this.props.t("questionnaire"),
//         },
//       });
//     }
//   };

//   showConfirmationDialog(accept, reject, title, header) {
//     const defaultState = {
//       show: false,
//       acceptCallback: () => {},
//       rejectCallback: () => {},
//       title: "",
//       header: "",
//     };

//     const confirmationDialog = {
//       show: true,
//       acceptCallback: async () => {
//         if (accept) accept();
//         this.setState({ confirmationDialog: defaultState });
//       },
//       rejectCallback: () => {
//         this.setState({ confirmationDialog: defaultState });
//         if (reject) reject();
//       },
//       title: title,
//       header: header,
//     };
//     this.setState({ confirmationDialog });
//   }

//   closeModal = () => {
//     this.setState({
//       isOpenModal: false,
//       selectedQuestion: {
//         ...initialState,
//         question: "",
//       },
//       selectedQuestionType: {
//         value: "Questionnaire",
//         label: this.props.t("questionnaire"),
//       },
//       saving: false,
//       errorMsg: false,
//     });
//   };

//   handleStatusChange = (value) => {
//     this.onUpdateValues("status", value);
//   };
//   render() {
//     const { t } = this.props;
//     const {
//       props: { questionsList },
//     } = this;
//     let questionsListArray = questionsList;
//     const {
//       state: {
//         selectLocation,
//         location,
//         loading,
//         confirmationDialog,
//         isOpenModal,
//         selectedQuestion,
//         selectedQuestionType,
//         questionType,
//       },
//     } = this;
//     const locationName = location ? location.name : "";
//     if (selectLocation)
//       return (
//         <SelectLocation
//           onBack={this.toggleLocation.bind(this)}
//           onSelect={this.onSelect.bind(this)}
//         />
//       );

//     if (loading) return <SpinnerComponent />;
//     return (
//       <>
//         <div className="location">
//           <div className="display-flex flex-row justify-content-between mb-1">
//             <div className="flex-column">
//               <LocationSelectHeader
//                 text={locationName}
//                 onIconClick={this.changeLocation.bind(this)}
//               />
//               <Button
//                 outline
//                 className="font-weight-bold custom-padding primary-2-border mb-1  mt-2 "
//                 color={"primary"}
//                 size="md"
//                 // onClick={(e) => this.setState({ isOpenModal: true })}
//                 onClick={() => this.editQuestion(this.state.selectedQuestion)}
//               >
//                 <Plus className="mx-2" size={20} />{" "}
//                 <span className="ml-1 mr-5 ">{t("add-question")}</span>
//               </Button>
//             </div>
//           </div>
//         </div>
//         <div id="pricing-table">
//           {!loading &&
//           questionsListArray !== null &&
//           questionsListArray?.length !== 0 ? (
//             <>
//               <QuestionnaireList
//                 questionsListArray={questionsListArray}
//                 changeStatus={(item, title, message, status) =>
//                   this.changeStatus(item, title, message, status)
//                 }
//                 editQuestion={this.editQuestion.bind(this)}
//                 deleteQuestion={this.deleteQuestionHandler} // Call deleteQuestionHandler here
//               />
//             </>
//           ) : null}
//           <ConfirmationModal
//             header={confirmationDialog.header}
//             title={confirmationDialog.title}
//             isOpen={confirmationDialog.show}
//             reject={confirmationDialog.rejectCallback.bind(this)}
//             rejectTitle={t("cancel")}
//             accept={confirmationDialog.acceptCallback.bind(this)}
//           />

//           <React.Fragment>
//             <Modal
//               isOpen={isOpenModal}
//               className="modal-dialog-centered d-sm-margin-0 p-0"
//               backdrop="static"
//             >
//               <div className="modal-header display-flex full-width flex-space-between">
//                 <h5 className="modal-title">
//                   {selectedQuestion.location_id !== undefined
//                     ? t("edit-question")
//                     : t("new-question")}
//                 </h5>
//                 <X
//                   className="cursor-pointer"
//                   size={20}
//                   onClick={() => this.closeModal()}
//                 />
//               </div>

//               <ModalBody>
//                 <FormGroup className="row">
//                   <Col md={12}>
//                     <Label for="question">
//                       {" "}
//                       {this.state.errorMsg ? (
//                         <p style={{ color: "red" }}>{t("question-0")}</p>
//                       ) : (
//                         t("question")
//                       )}
//                     </Label>
//                     <Input
//                       type="text"
//                       id="question"
//                       defaultValue={
//                         selectedQuestion != null
//                           ? selectedQuestion?.question
//                           : ""
//                       }
//                       placeholder={t("survey-question")}
//                       onChange={(e) =>
//                         this.onUpdateValues(e.target.value, "question")
//                       }
//                       autoComplete="off"
//                       style={{
//                         borderColor: this.state.errorMsg ? "red" : null,
//                       }}
//                       autoFocus={true}
//                     />
//                   </Col>
//                 </FormGroup>
//                 <FormGroup className="row">
//                   <Col md={12}>
//                     <Label for="type">{t("question-type")}: </Label>
//                     <Select
//                       styles={{
//                         option: (provided, state) => ({
//                           ...provided,
//                           backgroundColor: state.isSelected
//                             ? "var(--primary)"
//                             : state.isFocused
//                             ? "#7367f099"
//                             : "#fff",
//                         }),
//                       }}
//                       className="React no-pointer-events"
//                       classNamePrefix="select"
//                       defaultValue={selectedQuestionType}
//                       name="type"
//                       id="type"
//                       options={questionType}
//                       onChange={(e) => this.onUpdateValues(e.value, "type")}
//                     />
//                   </Col>
//                 </FormGroup>
//                 <FormGroup>
//                   <Label for="status">{t("status-0")}: </Label>
//                   <div
//                     id="status"
//                     onChange={(e) =>
//                       this.onUpdateValues(parseInt(e.target.value), "status")
//                     }
//                   >
//                     <div className="d-inline-block mr-1">
//                       <Radio
//                         label={t("enabled")}
//                         name="status"
//                         value={1}
//                         checked={selectedQuestion?.status === "enabled"}
//                         onChange={() => this.handleStatusChange(1)}
//                       />
//                     </div>
//                     <div className="d-inline-block mr-1">
//                       <Radio
//                         label={t("disabled")}
//                         name="status"
//                         value={0}
//                         checked={selectedQuestion?.status === "disabled"}
//                         onChange={() => this.handleStatusChange(1)}
//                       />
//                     </div>
//                   </div>
//                 </FormGroup>
//               </ModalBody>
//               <ModalFooter>
//                 <div className="d-none d-sm-flex flex-space-between full-width">
//                   <div>
//                     <Button
//                       color="dark white"
//                       onClick={() => this.closeModal()}
//                     >
//                       {t("cancel")}{" "}
//                     </Button>{" "}
//                     <Button
//                       color="primary"
//                       onClick={() => this.submitQuestion()}
//                       disabled={this.state.saving}
//                     >
//                       {t("save")}{" "}
//                     </Button>{" "}
//                   </div>
//                 </div>
//               </ModalFooter>
//             </Modal>
//           </React.Fragment>
//         </div>
//       </>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   const {
//     surveyquestions: {
//       allsurveyquestions: { data: questionsList, loading },
//     },
//   } = state;
//   return {
//     questionsList: questionsList,
//     loading: loading,
//   };
// };

// export default withTranslation("global")(
//   connect(mapStateToProps, {
//     getAllSurveyQuestions,
//     changeQuestionStatus,
//     saveUpdateQuestion,
//     deleteQuestions,
//   })(Questionnaire)
// );
import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import LocationService from "../../services/LocationService";
import {
  getAllSurveyQuestions,
  changeQuestionStatus,
  saveUpdateQuestion,
  deleteQuestions,
} from "../../redux/actions/surveyquestions";
import QuestionnaireList from "./questionnaireList";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import Swal from "sweetalert2";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import toast, { Toaster } from "react-hot-toast";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  Col,
  Button,
} from "reactstrap";
import { X, Plus } from "react-feather";
import Select from "react-select";
import Radio from "../@vuexy/radio/RadioVuexy";

import { withTranslation } from "react-i18next";
import i18n from "i18next";
import QrList from "./qrList";

let initialState = {
  question: "",
  type: "Questionnaire",
  status: "enabled",
};
let initialState2 = {
  title: "",
  question: "",
  type: "Link",
  status: "enabled",
};

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      selectLocation: false,
      loading: false,
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t("are-you-sure-to-close-this-session"),
        header: this.props.t("close-session"),
      },
      isOpenModal: false,
      isQrModalOpen: false,
      selectedQuestion: initialState,
      selectedQr: initialState2,
      questionType: [
        {
          value: "Questionnaire",
          label: this.props.t("questionnaire"),
        },
        {
          value: "Ratings",
          label: this.props.t("ratings"),
        },
        {
          value: "Comments",
          label: this.props.t("comments"),
        },
      ],
      qrType: [
        {
          value: "Link",
          label: "Link",
        },
      ],
      selectedQuestionType: {
        value: "Questionnaire",
        label: this.props.t("questionnaire"),
      },
      selectedQrType: {
        value: "Link",
        label: "Link",
      },
      saving2: false,
      saving: false,
      errorMsg: false,
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation) {
      loc = await LocationService.getLocationFromServer(loc_id);
    }
    await this.props.getAllSurveyQuestions(loc.loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  changeStatus = (item, title, message, status) => {
    this.showConfirmationDialog(
      async () => {
        await this.props.changeQuestionStatus(item, status);
      },
      null,
      message,
      title
    );
  };

  editQuestion = (item) => {
    return this.setState({
      isOpenModal: true,
      selectedQuestion: item,
      selectedQuestionType: {
        label: item.type,
        value: item.type,
      },
      saving2: false,
      errorMsg: false,
    });
  };
  editQr = (item) => {
    return this.setState({
      isQrModalOpen: true,
      selectedQr: item,
      selectedQrType: {
        label: item.type,
        value: item.type,
      },

      saving2: false,
      errorMsg: false,
    });
  };
  // deleteQuestionHandler = (question_id) => {
  //   let loc = this.state.location;
  //   console.log('LOC', loc);
  //   // Call the deleteQuestions action with the question_id
  //   this.props.deleteQuestions(loc.loc_id, question_id);
  // };

  deleteQuestionHandler = (question_id) => {
    Swal.fire({
      title: "Are you sure?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const loc = this.state.location;
        // Call the deleteQuestions action with the question_id
        this.props.deleteQuestions(loc.loc_id, question_id).then((response) => {
          console.log("RESPONSE", response);

          //   if (response === 'Question deleted successfully') {
          //     Swal.fire({
          //       title: 'Deleted!',
          //       text: 'Yoquestion has been deleted.',
          //       icon: 'success',
          //       timer: 1000, // Auto-close after 2 seconds
          //       showConfirmButton: false, // Hide the "OK" button
          //     }).then(() => {
          //       // Reload the window after successful deletion
          //       window.location.reload();
          //     });
          //   } else {
          //     Swal.fire({
          //       title: 'Deleted!',
          //       text: 'Your question has been deleted.',
          //       icon: 'success',
          //       timer: 1000, // Auto-close after 2 seconds
          //       showConfirmButton: false, // Hide the "OK" button
          //     }).then(() => {
          //       // Reload the window after successful deletion
          //       window.location.reload();
          //     });
          //   }
          // });
          if (response === "Question deleted successfully") {
            toast.success("Your question has been deleted.", {
              duration: 2000,
            });
            // Reload the window after successful deletion
            this.props.getAllSurveyQuestions(loc.loc_id);
          } else {
            toast.success("Your question has been deleted.", {
              duration: 2000,
            });
            this.props.getAllSurveyQuestions(loc.loc_id);
          }
        });
      }
    });
  };
  deleteQrHandler = (question_id) => {
    Swal.fire({
      title: "Are you sure?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const loc = this.state.location;
        // Call the deleteQuestions action with the question_id
        this.props.deleteQuestions(loc.loc_id, question_id).then((response) => {
          console.log("RESPONSE", response);

          if (response === "Question deleted successfully") {
            toast.success("QR-Code has been deleted.", {
              duration: 2000,
            });
            // Reload the window after successful deletion
            this.props.getAllSurveyQuestions(loc.loc_id);
          } else {
            toast.success("QR-Code has been deleted.", {
              duration: 2000,
            });
            this.props.getAllSurveyQuestions(loc.loc_id);
          }
        });
      }
    });
  };

  // onQRUpdateValues = (value, type) => {
  //   let selQues = this.state.selectedQr;
  //   if (type === "status") {
  //     value = value == 1 ? "enabled" : "disabled";
  //   }
  //   if (type === "link" && this.state.errorMsg) {
  //     this.setState({ errorMsg: false });
  //   }
  //   selQues[type] = value;
  //   this.setState({ selectedQr: selQues });
  // };
  // onUpdateValues = (value, type) => {
  //   let selQues = this.state.selectedQuestion;
  //   if (type === "status") {
  //     value = value == 1 ? "enabled" : "disabled";
  //   }
  //   if (type === "question" && this.state.errorMsg) {
  //     this.setState({ errorMsg: false });
  //   }
  //   selQues[type] = value;
  //   this.setState({ selectedQuestion: selQues });
  // };

  // submitQuestion = () => {
  //   if (
  //     this.state.selectedQuestion.question === "" ||
  //     this.state.selectedQuestion.question === null
  //   ) {
  //     this.setState({ errorMsg: true });
  //     return;
  //   }
  //   this.setState({ saving: true });
  //   let newQuestion = this.state.selectedQuestion;
  //   if (newQuestion?.location_id === undefined) {
  //     newQuestion.location_id = this.state.location.loc_id;
  //   }
  //   let questionSaved = this.props.saveUpdateQuestion(
  //     newQuestion,
  //     this.state.location.loc_id
  //   );
  //   if (questionSaved) {
  //     this.setState({
  //       saving: false,
  //       isOpenModal: false,
  //       selectedQuestion: {
  //         ...initialState,
  //         question: "",
  //       },
  //       selectedQuestionType: {
  //         value: "Questionnaire",
  //         label: this.props.t("questionnaire"),
  //       },
  //     });
  //   }
  // };
  // submitQr = () => {
  //   this.setState({ saving: true });
  //   let newQuestion = this.state.selectedQr;
  //   if (newQuestion?.location_id === undefined) {
  //     newQuestion.location_id = this.state.location.loc_id;
  //   }
  //   let questionSaved = this.props.saveUpdateQuestion(
  //     newQuestion,
  //     this.state.location.loc_id
  //   );
  //   if (questionSaved) {
  //     this.setState({
  //       saving: false,
  //       isQrModalOpen: false,
  //       selectedQr: {
  //         ...initialState2,
  //         question: "",
  //       },
  //       selectedQrType: {
  //         value: "Link",
  //         label: "Link",
  //       },
  //     });
  //   }
  // };

  onUpdateValues = (value, type) => {
    this.setState((prevState) => {
      let selQues = { ...prevState.selectedQuestion }; // Create a copy of the selectedQuestion object
      if (type === "status") {
        value = value == 1 ? "enabled" : "disabled";
      }
      if (type === "question" && prevState.errorMsg) {
        return { ...prevState, errorMsg: false };
      }
      selQues[type] = value;
      return { ...prevState, selectedQuestion: selQues };
    });
  };
  onQRUpdateValues = (value, type) => {
    this.setState((prevState) => {
      let selQues = { ...prevState.selectedQr }; // Create a copy of the selectedQr object
      if (type === "status") {
        value = value == 1 ? "enabled" : "disabled";
      }
      if (type === "link" && prevState.errorMsg) {
        return { ...prevState, errorMsg: false };
      }
      selQues[type] = value;
      return { ...prevState, selectedQr: selQues };
    });
  };
  submitQuestion = () => {
    if (
      this.state.selectedQuestion.question === "" ||
      this.state.selectedQuestion.question === null
    ) {
      this.setState({ errorMsg: true });
      return;
    }
    this.setState({ saving: true });
    let newQuestion = this.state.selectedQuestion;
    if (newQuestion?.location_id === undefined) {
      newQuestion.location_id = this.state.location.loc_id;
    }
    let questionSaved = this.props.saveUpdateQuestion(
      newQuestion,
      this.state.location.loc_id
    );
    if (questionSaved) {
      this.setState((prevState) => ({
        saving: false,
        isOpenModal: false,
        selectedQuestion: {
          ...initialState,
          question: "",
        },
        selectedQuestionType: {
          value: "Questionnaire",
          label: this.props.t("questionnaire"),
        },
        errorMsg: false,
      }));
    }
  };
  submitQr = () => {
    if (
      this.state.selectedQr.question === "" ||
      this.state.selectedQr.question === null
    ) {
      this.setState({ errorMsg: true });
      return;
    }
    this.setState({ saving: true });
    let newQuestion = this.state.selectedQr;
    if (newQuestion?.location_id === undefined) {
      newQuestion.location_id = this.state.location.loc_id;
    }
    let questionSaved = this.props.saveUpdateQuestion(
      newQuestion,
      this.state.location.loc_id
    );
    if (questionSaved) {
      this.setState((prevState) => ({
        saving: false,
        isQrModalOpen: false,
        selectedQr: {
          ...initialState2,
          question: "",
        },
        selectedQrType: {
          value: "Link",
          label: "Link",
        },
        errorMsg: false,
      }));
    }
  };

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  closeModal = () => {
    this.setState({
      isOpenModal: false,
      selectedQuestion: {
        ...initialState,
        question: "",
      },
      selectedQuestionType: {
        value: "Questionnaire",
        label: this.props.t("questionnaire"),
      },
      saving: false,
      errorMsg: false,
    });
  };
  closeQrModal = () => {
    this.setState({
      isQrModalOpen: false,
      selectedQr: {
        ...initialState2,
        question: "",
      },
      selectedQrType: {
        value: "Link",
        label: "Link",
      },
      saving2: false,
      errorMsg: false,
    });
  };

  handleStatusChange = (value) => {
    this.onUpdateValues("status", value);
  };
  handleQRStatusChange = (value) => {
    this.onQRUpdateValues("status", value);
  };
  render() {
    const { t } = this.props;
    const {
      props: { questionsList },
    } = this;
    let questionsListArray = questionsList;
    const {
      state: {
        selectLocation,
        location,
        loading,
        confirmationDialog,
        isOpenModal,
        isQrModalOpen,
        selectedQuestion,
        selectedQuestionType,
        selectedQrType,
        questionType,
        selectedQr,
        qrType,
      },
    } = this;
    const locationName = location ? location.name : "";
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="location">
          <div className="display-flex flex-row justify-content-between mb-1">
            <div className="flex-column">
              <LocationSelectHeader
                text={locationName}
                onIconClick={this.changeLocation.bind(this)}
              />
              <Button
                outline
                className="font-weight-bold custom-padding primary-2-border mb-1 ml-1  mt-2 "
                color={"primary"}
                size="md"
                // onClick={(e) => this.setState({ isOpenModal: true })}
                onClick={() => this.editQuestion(this.state.selectedQuestion)}
              >
                <Plus className="mx-2" size={20} />{" "}
                <span className="ml-1 mr-5 ">{t("add-question")}</span>
              </Button>
              <Button
                outline
                className="font-weight-bold custom-padding primary-2-border mb-1 ml-1 mt-2 "
                color={"primary"}
                size="md"
                onClick={() => this.editQr(this.state.selectedQr)}
              >
                <Plus className="mx-2" size={20} />{" "}
                <span className="ml-1 mr-5 ">Add QR Code</span>
              </Button>
            </div>
          </div>
        </div>
        <div id="pricing-table">
          {!loading &&
          questionsListArray !== null &&
          questionsListArray?.length !== 0 ? (
            <>
              <QuestionnaireList
                questionsListArray={questionsListArray}
                changeStatus={(item, title, message, status) =>
                  this.changeStatus(item, title, message, status)
                }
                editQuestion={this.editQuestion.bind(this)}
                deleteQuestion={this.deleteQuestionHandler}
              />
              <QrList
                questionsListArray={questionsListArray}
                changeStatus={(item, title, message, status) =>
                  this.changeStatus(item, title, message, status)
                }
                editQr={this.editQr.bind(this)}
                deleteQuestion={this.deleteQrHandler}
              />
            </>
          ) : null}
          <ConfirmationModal
            header={confirmationDialog.header}
            title={confirmationDialog.title}
            isOpen={confirmationDialog.show}
            reject={confirmationDialog.rejectCallback.bind(this)}
            rejectTitle={t("cancel")}
            accept={confirmationDialog.acceptCallback.bind(this)}
          />

          <React.Fragment>
            <Modal
              isOpen={isOpenModal}
              className="modal-dialog-centered d-sm-margin-0 p-0"
              backdrop="static"
            >
              <div className="modal-header display-flex full-width flex-space-between">
                <h5 className="modal-title">
                  {selectedQuestion.location_id !== undefined
                    ? t("edit-question")
                    : t("new-question")}
                </h5>
                <X
                  className="cursor-pointer"
                  size={20}
                  onClick={() => this.closeModal()}
                />
              </div>

              <ModalBody>
                <FormGroup className="row">
                  <Col md={12}>
                    <Label for="question">
                      {" "}
                      {this.state.errorMsg ? (
                        <p style={{ color: "red" }}>{t("question-0")}</p>
                      ) : (
                        t("question")
                      )}
                    </Label>
                    <Input
                      type="text"
                      id="question"
                      defaultValue={
                        selectedQuestion != null
                          ? selectedQuestion?.question
                          : ""
                      }
                      placeholder={t("survey-question")}
                      onChange={(e) =>
                        this.onUpdateValues(e.target.value, "question")
                      }
                      autoComplete="off"
                      style={{
                        borderColor: this.state.errorMsg ? "red" : null,
                      }}
                      autoFocus={true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Col md={12}>
                    <Label for="type">{t("question-type")}: </Label>
                    <Select
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "var(--primary)"
                            : state.isFocused
                            ? "#7367f099"
                            : "#fff",
                        }),
                      }}
                      className="React no-pointer-events"
                      classNamePrefix="select"
                      defaultValue={selectedQuestionType}
                      name="type"
                      id="type"
                      options={questionType}
                      onChange={(e) => this.onUpdateValues(e.value, "type")}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label for="status">{t("status-0")}: </Label>
                  <div
                    id="status"
                    onChange={(e) =>
                      this.onUpdateValues(parseInt(e.target.value), "status")
                    }
                  >
                    <div className="d-inline-block mr-1">
                      <Radio
                        label={t("enabled")}
                        name="status"
                        value={1}
                        checked={selectedQuestion?.status === "enabled"}
                        onChange={() => this.handleStatusChange(1)}
                      />
                    </div>
                    <div className="d-inline-block mr-1">
                      <Radio
                        label={t("disabled")}
                        name="status"
                        value={0}
                        checked={selectedQuestion?.status === "disabled"}
                        onChange={() => this.handleStatusChange(1)}
                      />
                    </div>
                  </div>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <div className="d-none d-sm-flex flex-space-between full-width">
                  <div>
                    <Button
                      color="dark white"
                      onClick={() => this.closeModal()}
                    >
                      {t("cancel")}{" "}
                    </Button>{" "}
                    <Button
                      color="primary"
                      onClick={() => this.submitQuestion()}
                      disabled={this.state.saving}
                    >
                      {selectedQuestion.location_id !== undefined
                        ? "Update"
                        : "Save"}
                      {/* {t("save")}{" "} */}
                    </Button>{" "}
                  </div>
                </div>
              </ModalFooter>
            </Modal>
          </React.Fragment>
          <React.Fragment>
            <Modal
              isOpen={isQrModalOpen}
              className="modal-dialog-centered d-sm-margin-0 p-0"
              backdrop="static"
            >
              <div className="modal-header display-flex full-width flex-space-between">
                <h5 className="modal-title">
                  {selectedQr.location_id !== undefined
                    ? "Edit Qr Code"
                    : "New Qr Code"}
                </h5>
                <X
                  className="cursor-pointer"
                  size={20}
                  onClick={() => this.closeQrModal()}
                />
              </div>

              <ModalBody>
                <FormGroup className="row">
                  <Col md={12}>
                    <Label for="question">
                      {" "}
                      {this.state.errorMsg ? (
                        <p style={{ color: "red" }}>Title Required</p>
                      ) : (
                        "Title"
                      )}
                    </Label>
                    <Input
                      defaultValue={selectedQr != null ? selectedQr?.title : ""}
                      type="text"
                      id="title"
                      placeholder={t("survey-question")}
                      onChange={(e) =>
                        this.onQRUpdateValues(e.target.value, "title")
                      }
                      autoComplete="off"
                      style={{
                        borderColor: this.state.errorMsg ? "red" : null,
                      }}
                      autoFocus={true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Col md={12}>
                    <Label for="question">
                      {" "}
                      {this.state.errorMsg ? (
                        <p style={{ color: "red" }}>Url Required</p>
                      ) : (
                        "URL"
                      )}
                    </Label>
                    <Input
                      defaultValue={
                        selectedQr != null ? selectedQr?.question : ""
                      }
                      type="text"
                      id="question"
                      placeholder={"Type Url"}
                      onChange={(e) =>
                        this.onQRUpdateValues(e.target.value, "question")
                      }
                      autoComplete="off"
                      style={{
                        borderColor: this.state.errorMsg ? "red" : null,
                      }}
                      autoFocus={true}
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Col md={12}>
                    <Label for="type">{t("question-type")}: </Label>
                    <Select
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "var(--primary)"
                            : state.isFocused
                            ? "#7367f099"
                            : "#fff",
                        }),
                      }}
                      className="React no-pointer-events"
                      classNamePrefix="select"
                      defaultValue={selectedQrType}
                      name="type"
                      id="type"
                      options={qrType}
                      onChange={(e) => this.onUpdateValues(e.value, "type")}
                    />
                  </Col>
                </FormGroup> */}
                <FormGroup>
                  <Label for="status">{t("status-0")}: </Label>
                  <div
                    id="status"
                    onChange={(e) =>
                      this.onQRUpdateValues(parseInt(e.target.value), "status")
                    }
                  >
                    <div className="d-inline-block mr-1">
                      <Radio
                        label={t("enabled")}
                        name="status"
                        value={1}
                        checked={selectedQr?.status === "enabled"}
                        onChange={() => this.handleQRStatusChange(1)}
                      />
                    </div>
                    <div className="d-inline-block mr-1">
                      <Radio
                        label={t("disabled")}
                        name="status"
                        value={0}
                        checked={selectedQr?.status === "disabled"}
                        onChange={() => this.handleQRStatusChange(1)}
                      />
                    </div>
                  </div>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <div className="d-none d-sm-flex flex-space-between full-width">
                  <div>
                    <Button
                      color="dark white"
                      onClick={() => this.closeQrModal()}
                    >
                      {t("cancel")}{" "}
                    </Button>{" "}
                    <Button
                      color="primary"
                      onClick={() => this.submitQr()}
                      disabled={this.state.saving}
                    >
                      {selectedQr.location_id !== undefined ? "Update" : "Save"}
                    </Button>{" "}
                  </div>
                </div>
              </ModalFooter>
            </Modal>
          </React.Fragment>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    surveyquestions: {
      allsurveyquestions: { data: questionsList, loading },
    },
  } = state;
  return {
    questionsList: questionsList,
    loading: loading,
  };
};

export default withTranslation("global")(
  connect(mapStateToProps, {
    getAllSurveyQuestions,
    changeQuestionStatus,
    saveUpdateQuestion,
    deleteQuestions,
  })(Questionnaire)
);
