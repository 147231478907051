import React, {useEffect, useState} from 'react';
import {Button, IconButton, Stack, Tooltip} from "@mui/material";
import {
    Fullscreen,
    FullscreenExit,
    Restaurant,
    Edit,
    Add,
    Logout,
    Language,
    Drafts,
    Print,
    History, ShoppingBagOutlined, GridView, ConfirmationNumber, Notifications, PointOfSale, DevicesFold, MonitorOutlined
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {blue, green, red, teal, yellow} from "@mui/material/colors";
import {useSelector} from "react-redux";
import AddCustomerModal from "../../mainComponents/addCustomerModal";
import UpdateCustomerModal from "../../mainComponents/updateCustomerModal";
import SnackBar from "../../mainComponents/snackBar";
import SmallScreensMainMenu from "../../mainComponents/smallScreensMainMenu";

const menuList = [
    {
        icon: <Logout sx={{color: '#186218'}}/>,
        text: "Logout"
    },
    {
        icon: <Language sx={{color: '#186218'}}/>,
        text: "Language"
    },
    {
        icon: <Drafts sx={{color: '#186218'}}/>,
        text: "Open Draft Sales"
    },
    {
        icon: <Print sx={{color: '#186218'}}/>,
        text: "Print Last Invoice"
    },
    {
        icon: <History sx={{color: '#186218'}}/>,
        text: "Recent Sales Invoice"
    },
    {
        icon: <ShoppingBagOutlined sx={{color: '#186218'}}/>,
        text: "Future Sales"
    },
    {
        icon: <GridView sx={{color: '#186218'}}/>,
        text: "Self/Online Orders"
    },
    {
        icon: <ConfirmationNumber sx={{color: '#186218'}}/>,
        text: "Reservations List"
    },
    {
        icon: <Notifications sx={{color: '#186218'}}/>,
        text: "Kitchen Notifications"
    },
    {
        icon: <PointOfSale sx={{color: '#186218'}}/>,
        text: "Register"
    },
    {
        icon: <DevicesFold sx={{color: '#186218'}}/>,
        text: "Dashboard"
    },
    {
        icon: <MonitorOutlined sx={{color: '#186218'}}/>,
        text: "Customer Display"
    },
    {
        icon: <MonitorOutlined sx={{color: '#186218'}}/>,
        text: "Order Status Screen"
    }
]

const iconButtons = [
    {
        onHelperText: 'Edit Customer',
        onIcon: <Edit />,
        backgroundColor: blue[400],
        hoverBackgroundColor: blue[600]
    },

    {
        onHelperText: 'Add Customer',
        onIcon: <Add />,
        backgroundColor: green[400],
        hoverBackgroundColor: green[600]
    },

    {
        onIcon: <Fullscreen />,
        exitIcon: <FullscreenExit />,
        onHelperText: "Enter Fullscreen",
        offHelperText: "Exit Fullscreen",
        backgroundColor: yellow[400],
        hoverBackgroundColor: yellow[600]
    },
]

const buttons = [
    {
        label: "Waiter",
        icon: <Restaurant />,
        backgroundColor: teal[400],
        hoverBackgroundColor: teal[600]
    },

    {
        label: "Others",
        icon: <MenuIcon />,
        backgroundColor: red[400],
        hoverBackgroundColor: red[600]
    }
]

const iconButtonsStyle = {
    borderRadius: '0.25rem',
    padding: '0.35rem',
    color: 'white',
    flexGrow: 1
}

const buttonStyles = {
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.35rem 1rem',
    textAlign: 'center',
    lineHeight: '1.5rem',
    flexGrow: 1,
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0,0,0,0.25)',
}

const MediumScreenNavbarRightSide = () => {
    const [fullScreen, setFullScreen] = useState(false);
    const customerData = useSelector(state => state.pos.customerData);
    const [addCustomerModal, setAddCustomerModal] = useState(false)
    const [editCustomerModal, setEditCustomerModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: '',
        severity: ''
    })

    useEffect(() => {
        if (document.fullscreenElement === null) {
            setFullScreen(false);
        } else {
            setFullScreen(true);
        }
    }, []);

    const handleScreenChange = async () => {
        try {
            if (document.fullscreenElement === null) {
                await document.documentElement.requestFullscreen();
                setFullScreen(true);
            } else {
                await document.exitFullscreen();
                setFullScreen(false);
            }
        } catch (err) {
            console.error("Error attempting to toggle fullscreen:", err);
        }
    }

    const handleOpenAddCustomerModal = () => {
        setAddCustomerModal(true);
    }

    const handleOpenUpdateCustomerModal = () => {
        if (customerData && customerData.id) {
            setEditCustomerModal(true)
        } else {
            setSnackbarParams({
                open: true,
                message: "Please, select a customer",
                severity: 'error'
            })
        }
    }

    const handleIconButtonClick = async (index) => {
        if (index === 2) {
            await handleScreenChange();
        } else if (index === 0) {
            handleOpenUpdateCustomerModal();
        } else if (index === 1) {
            handleOpenAddCustomerModal();
        }
    }

    const handleButtonClick = (index, event) => {
        if (index === 0) {

        } else if (index === 1) {
            setAnchorEl(event.currentTarget);
        }
    }

    return(
        <>
            <Stack direction='row' sx={{alignItems: 'center', width: '50%', columnGap: '0.5rem'}}>
                {
                    iconButtons.map((item, index) => (
                        <Tooltip sx={{...iconButtonsStyle, backgroundColor: item.backgroundColor, '&:hover': { backgroundColor: item.hoverBackgroundColor } }} title={index === 2? (fullScreen? item.offHelperText : item.onHelperText) : item.onHelperText} key={index}>
                            <IconButton onClick={()=> handleIconButtonClick(index)}>
                                {index === 2 ? (fullScreen ? item.exitIcon : item.onIcon) : item.onIcon}
                            </IconButton>
                        </Tooltip>
                    ))
                }
                {
                    buttons.map((item, index) => (
                        <Button onClick={(event)=> handleButtonClick(index, event)} variant='contained' startIcon={item.icon} key={index} sx={{...buttonStyles, backgroundColor: item.backgroundColor, '&:hover': {backgroundColor: item.hoverBackgroundColor}}}>
                            {item.label}
                        </Button>
                    ))
                }
                <SmallScreensMainMenu open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuList={menuList}/>
            </Stack>
            {addCustomerModal && <AddCustomerModal openModal={addCustomerModal} setOpenModal={setAddCustomerModal} setSnackbarParams={setSnackbarParams}/> }
            {editCustomerModal &&<UpdateCustomerModal openModal={editCustomerModal} setOpenModal={setEditCustomerModal} setSnackbarParams={setSnackbarParams}/> }
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </>
    )
}

export default MediumScreenNavbarRightSide;