import { combineReducers } from 'redux'
import { overallStats } from "./OverallStatsReducer";
import { customerStats } from "./CustomerStatsReducer";
import { itemsStats } from "./ItemsStatsReducer";
import { dashboardStats } from "./DashboardStatsReducer";


const analyticsReducer = combineReducers({
  overallStats,
  customerStats,
  itemsStats,
  dashboardStats
});

export default analyticsReducer;
