import React from "react";
import {
  faArrowLeft,
  faCartPlus,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import { Image, Plus, X } from "react-feather";
import Slider from "react-slick";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Media,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationService from "../../services/LocationService";
import BannersService from "../../services/BannersService";
import BrandingService from "../../services/BrandingService";
import BannerModal from "../shared/forms/BannerModel";
import ImageHelper from "../../helpers/ImageHelper";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class Banners extends React.Component {
  state = {
    bannerlist: [],
    loadingBanner: true,
    loadingBranding: true,
    isBannerModalOpen: false,
    selectedBanner: null,
    location: null,
    branding: null,
    logo: null,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    },
  };

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    console.log(location, "brand");
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loadingBranding: false, location: location });
    } else {
      this.setState({ loadingBranding: false });
    }
  }

  async refreshData(loc_id, initialize = true) {
    this.setState({ loadingBranding: initialize });

    // Branding data
    let branding = await BrandingService.getBranding(loc_id);
    this.setState({ branding: branding, loadingBranding: false });

    // Banners data
    let banners = await BannersService.getAllBanners(loc_id);
    this.setState({ bannerlist: banners });
  }

  async toggleBannerModal(banner = null) {
    let {
      state: { isBannerModalOpen },
    } = this;
    const selectedBanner = banner;
    isBannerModalOpen = !isBannerModalOpen;
    return this.setState({ selectedBanner, isBannerModalOpen });
  }

  async deleteBanner(banner) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        this.setState({
          confirmationDialog: defaultState,
          loadingBanner: true,
        });
        try {
          let bannerlist = await BannersService.deleteBanner(
            this.state.location.loc_id,
            banner.banner_id
          );
          this.setState({ bannerlist: bannerlist, loadingBanner: false });
        } catch (error) {
          console.error("Error deleting banner:", error);
          this.setState({ loadingBanner: false });
        }
      },
      rejectCallback: () => {
        this.setState({
          confirmationDialog: defaultState,
          loadingBanner: false,
        });
      },
    };
    this.setState({ confirmationDialog });
  }

  async saveBanner() {
    const fileInput = document.querySelector("#bannerFileInput");
    const formData = new FormData();
    if (fileInput.files.length > 0) {
      formData.append("file", fileInput.files[0]);
      try {
        this.setState({ loadingBanner: true });

        let updatedBannerList = await BannersService.saveBanner(
          this.state.location.loc_id,
          formData
        );

        await this.toggleBannerModal();
        this.setState({ bannerlist: updatedBannerList, loadingBanner: false });
      } catch (error) {
        console.error("Error saving banner:", error);
        this.setState({ loadingBanner: false });
      }
    }
  }

  async saveBranding() {
    this.setState({ loadingBranding: true });
    const fileInput = document.querySelector("#brandingFileInput"); // Use a different ID for branding file input
    const formData = new FormData();
    if (fileInput.files.length > 0) {
      formData.append("file", fileInput.files[0]);
    }
    formData.append("brandingItem", JSON.stringify(this.state.branding));
    try {
      let branding = await BrandingService.saveBranding(
        this.state.location.loc_id,
        formData
      );
      this.setState({ branding: branding, loadingBranding: false, logo: null });
    } catch (error) {
      console.error("Error saving branding:", error);
      this.setState({ loadingBranding: false });
    }
  }

  async handleChange(e) {
    if (e.target.files.length > 0) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "webp", "svg"];
      const fileExtension = e.target.files[0].name.split(".").at(-1);
      if (!allowedFileTypes.includes(fileExtension)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Upload image does not support. Image type must be ${allowedFileTypes.join(
            ", "
          )}`,
        });
        return false;
      }

      var size = parseFloat(e.target.files[0].size / (1024 * 1024 * 5)).toFixed(
        2
      );
      if (size > 5) {
        alert("Please select image size less than 5 MB");
        return false;
      }
      let reader = new FileReader();
      let itemProps = this.state.branding;
      itemProps.deletePhoto = true;
      reader.onload = (ev) => {
        this.setState({ logo: ev.target.result, branding: itemProps });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  changeBaseColor(index, value) {
    console.log();
    let itemProps = this.state.branding;
    itemProps[index] = value;
    this.setState({ branding: itemProps });
  }

  render() {
    const { t } = this.props;
    let fileRef = React.createRef();
    let imageContainer = {
      height: 130,

      borderRadius: 1,
      overflow: "hidden",
      backgroundColor: "white",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
    };
    const arrowStyle = {
      position: "absolute",
      top: "50%",
      zIndex: 1,
      cursor: "pointer",
      fontSize: "24px",
      fontWeight: "bold",
      color: "white",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    };
    const CustomNextArrow = (props) => (
      <div
        className="custom-next-arrow"
        onClick={props.onClick}
        style={{ ...arrowStyle, right: "10px" }}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );

    const CustomPrevArrow = (props) => (
      <div
        className="custom-prev-arrow"
        onClick={props.onClick}
        style={{ ...arrowStyle, left: "10px" }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
    };

    const fakeCategoryItem = {
      Menus: [
        {
          menu_name: "Cake Li Carte",
          menu_price: 200.0,
          menu_status: 1,
          menu_photo:
            "https://server.servall.be/restaurant_data/8/menu/8cfd3efd-efec-4494-abe7-0fd4e14185cc.jpg",
        },
        {
          menu_name: "LE BON APPETIT",
          menu_price: 8.0,
          menu_status: 1,
          menu_photo:
            "https://server.servall.be/restaurant_data/8/menu/e16f6287-e0e4-4def-adda-89976aa7720d.jpg",
        },
        {
          menu_name: "LECOLIER",
          menu_price: 7.5,
          menu_status: 1,
          menu_photo:
            "https://server.servall.be/restaurant_data/8/menu/93326395-6af9-4114-8120-1ffd5c88af59.jpg",
        },
      ],
    };
    const fakeCategoryItem2 = {
      Menus: [
        {
          menu_name: "Burgers",
          menu_price: 200.0,
          menu_status: 1,
          menu_photo:
            "https://server.servall.be/restaurant_data/11/category/7b7e17fa-f759-43a0-a42c-e9a9316eebf1.jpeg",
        },
        {
          menu_name: "Pizza",
          menu_price: 8.0,
          menu_status: 1,
          menu_photo:
            "https://server.servall.be/restaurant_data/11/category/40e1b18d-fd17-4494-a9db-0751d94939a1.jpeg",
        },
        {
          menu_name: "Brownie",
          menu_price: 7.5,
          menu_status: 1,
          menu_photo:
            "https://server.servall.be/restaurant_data/11/category/9a3ba337-ad79-417e-8470-0028d946b48f.jpeg",
        },
      ],
    };
    const fakeDishTags = [
      { tag_name: "Brunch" },
      { tag_name: "Dinner" },
      { tag_name: "Drink" },
      { tag_name: "Test" },
    ];

    if (this.state.loadingBranding) return <SpinnerComponent />;
    return (
      <>
        <div className="location">
          <div className="row">
            <div className="col-12">
              <Card>
                <CardHeader>
                  <CardTitle>{t("branding")}</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="row d-none">
                    {this.state.branding.logo !== null &&
                      this.state.branding.logo !== "" && (
                        <div
                          className="col-sm-6 col-md-4 mb-5"
                          style={{
                            border: "1px solid #e6e7e9",
                            textAlign: "center",
                          }}
                        >
                          <img
                            src={ImageHelper.getImageURL(
                              this.state.branding.logo
                            )}
                            alt=""
                            className="img-fluid"
                            style={{ minHeight: "200px", maxHeight: "200px" }}
                          />
                        </div>
                      )}
                    <Col className="mx-auto" md={4} sm={6}>
                      <Label for="photoDiv">Image:</Label>
                      <div
                        tabIndex="0"
                        id="brandingFileDiv"
                        className="display-flex flex-column border border-2 border-light cursor-pointer"
                        onKeyUp={(e) =>
                          e.keyCode === 13 && !e.ctrlKey
                            ? fileRef.current.click()
                            : null
                        }
                        onClick={() => fileRef.current.click()}
                        style={{ position: "relative", height: 120 }}
                      >
                        <div style={{ textAlign: "-webkit-center" }}>
                          {this.state.logo ? (
                            <div>
                              <div
                                style={{
                                  position: "absolute",
                                  top: -10,
                                  right: -10,
                                }}
                              >
                                <Button.Ripple
                                  style={{ width: 20, height: 20 }}
                                  onClick={() => {
                                    fileRef.current.value = null;
                                    let itemProps = this.state.branding;
                                    itemProps.deletePhoto = false;
                                    this.setState({
                                      logo: null,
                                      branding: itemProps,
                                    });
                                  }}
                                  className="m-0 p-0"
                                  color="danger"
                                >
                                  <X size={14} />
                                </Button.Ripple>
                              </div>
                              <div
                                style={{ position: "absolute", bottom: 3 }}
                                className="full-width"
                              >
                                <Button.Ripple
                                  className="p-25"
                                  color="primary"
                                  onClick={() => fileRef.current.click()}
                                >
                                  <span className="font-small-1">
                                    {t("change-photo")}{" "}
                                  </span>
                                </Button.Ripple>
                              </div>
                              <Media
                                object
                                src={this.state.logo}
                                alt="image"
                                style={{ maxWidth: "100%", maxHeight: 118 }}
                                className="align-self-center"
                              />
                            </div>
                          ) : (
                            <div
                              className="align-self-center"
                              style={{ padding: 9, height: 50, width: 50 }}
                            >
                              <Image size={32} className="align-self-center" />
                            </div>
                          )}
                        </div>
                        {this.state.logo == null ? (
                          <React.Fragment>
                            <span className="font-small-2 primary align-self-center">
                              {t("click-to-upload-a-file")}{" "}
                            </span>
                            <span className="font-small-2 color- align-self-center align-text-center">
                              JPG,PNG,JPEG,WEBP,SVG <br /> {t("up-to-5mb")}
                            </span>
                          </React.Fragment>
                        ) : null}
                      </div>
                      <input
                        type="file"
                        id="brandingFileInput"
                        name="fileInput"
                        onChange={(e) => this.handleChange(e)}
                        ref={fileRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                    </Col>
                  </div>
                  <Row>
                    <Col md={"4"} lg={"4"}>
                      <div className="mt-2">
                        <p>Overall color scheme for the interface.</p>
                      </div>
                      <div className="mt-3">
                        <p>Color used for title text in your application.</p>
                      </div>
                      <div className="mt-3">
                        <p>Background Color Buttons in your application.</p>
                      </div>
                      <div className="mt-3">
                        <p>Color used for categories in your application.</p>
                      </div>
                      <div className="mt-3">
                        <p>Color used for price text in your application.</p>
                      </div>

                      <div className="row w-100  p-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.saveBranding()}
                        >
                          {t("save-branding")}{" "}
                        </button>
                      </div>
                    </Col>
                    <Col md={"3"} lg={"3"} sm={"12"}>
                      <div>
                        <Label for="photoDiv">{t("base-color")}: </Label>
                        <input
                          className="display-flex flex-column border border-2 border-light  rounded cursor-pointer w-100"
                          type="color"
                          id="basecolor"
                          name="basecolor"
                          value={this.state.branding.basecolor}
                          onChange={(e) =>
                            this.changeBaseColor("basecolor", e.target.value)
                          }
                        />
                      </div>

                      <div>
                        <Label for="photoDiv">{t("Title")}: </Label>
                        <input
                          className="display-flex flex-column border border-2 border-light  rounded cursor-pointer w-100"
                          type="color"
                          id="h2_text_color"
                          name="h2_text_color"
                          value={this.state.branding.h2_text_color}
                          onChange={(e) =>
                            this.changeBaseColor(
                              "h2_text_color",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div>
                        <Label for="photoDiv">Button Background</Label>
                        <input
                          className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
                          type="color"
                          id="highlight_text_color"
                          name="highlight_text_color"
                          value={this.state.branding.highlight_text_color}
                          onChange={(e) =>
                            this.changeBaseColor(
                              "highlight_text_color",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div>
                        <Label for="photoDiv">Categories Text Color </Label>
                        <input
                          className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
                          type="color"
                          id="highlight_color"
                          name="highlight_color"
                          value={this.state.branding.highlight_color}
                          onChange={(e) =>
                            this.changeBaseColor(
                              "highlight_color",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="mt-1">
                        <Label for="photoDiv">Price Text Color </Label>
                        <input
                          className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
                          type="color"
                          id="h2_color"
                          name="h2_color"
                          value={this.state.branding.h2_color}
                          onChange={(e) =>
                            this.changeBaseColor("h2_color", e.target.value)
                          }
                        />
                      </div>
                    </Col>
                    <Col md={"5"}>
                      <div
                        style={{
                          background: "black",
                          borderRadius: "30px",
                          paddingTop: "25px",
                          width: "29rem",
                          display: "flex",
                          margin: "auto",
                        }}
                        className="col-12"
                      >
                        <Card
                          className="p-1 "
                          style={{
                            backgroundColor: "#F2F2F2",

                            overflow: "auto",
                          }}
                        >
                          <div
                            className="w-100"
                            style={{
                              backgroundColor: `${this.state.branding?.basecolor}`,
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "7px",
                            }}
                          >
                            <h4
                              className="mb-0"
                              style={{
                                color: `${this.state.branding?.h2_text_color}`,
                                textAlign: "left",
                                fontWeight: "bold",
                                position: "relative",
                              }}
                            >
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "16px",
                                }}
                              >
                                <FontAwesomeIcon
                                  id="arrowLeftIcon"
                                  style={{
                                    width: "20px",
                                    height: "30px",
                                    marginRight: "10px",
                                    verticalAlign: "middle",
                                    color: "white",

                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                  icon={faArrowLeft}
                                  className="mr-1"
                                />
                                Enfant
                              </span>
                            </h4>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div>
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "white",
                                    padding: "5px",
                                    borderRadius: "20px",
                                    border: "1px solid white",
                                    textAlign: "center",
                                    fontSize: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {t("pay-now")}
                                </button>
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "white",
                                    padding: "5px",
                                    fontSize: "10px",
                                    borderRadius: "20px",
                                    border: "1px solid white",
                                    textAlign: "center",
                                  }}
                                >
                                  {t("call-waiter")}
                                </button>
                              </div>
                              <FontAwesomeIcon
                                id="myTooltip3"
                                style={{
                                  width: "35px",
                                  height: "30px",
                                  marginLeft: "10px",
                                  background: "green",
                                  color: "white",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                                icon={faCartPlus}
                                className="mr-1"
                              />
                            </div>
                          </div>
                          <div className=" p-2">
                            <Slider
                              dots={false}
                              infinite={false}
                              speed={500}
                              arrows={true}
                              slidesToShow={3}
                              slidesToScroll={1}
                            >
                              {[...Array(10).keys()].map((_, index) => (
                                <div key={index}>
                                  <button
                                    className="btn btn-sm"
                                    style={{
                                      borderRadius: "10px",
                                      fontWeight: "bolder",
                                      color: `${this.state.branding.highlight_color}`,
                                      backgroundColor:
                                        this.state.branding
                                          .highlight_text_color,
                                    }}
                                  >
                                    Menu {index + 1}
                                  </button>
                                </div>
                              ))}
                            </Slider>
                          </div>
                          <div>
                            <h1
                              style={{
                                fontSize: "14px",

                                margin: "5px",
                              }}
                            >
                              {fakeDishTags.map((tag, index) => (
                                <span key={index}>#{tag.tag_name}&nbsp;</span>
                              ))}
                            </h1>
                          </div>
                          <div className="row mt-1">
                            {fakeCategoryItem.Menus.slice(0, 7).map(
                              (menu, index) =>
                                menu.menu_status === 1 && (
                                  <div
                                    className="col-6"
                                    key={index}
                                    style={{
                                      marginBottom: "15px",
                                      position: "relative",
                                    }}
                                  >
                                    <Card style={imageContainer}>
                                      <div
                                        style={{
                                          position: "relative",
                                          height: "100%",
                                          width: "100%",
                                          // cursor: 'pointer',
                                        }}
                                        // onClick={() =>
                                        //   this.props.history.push(
                                        //     '/men/menu-option-preview',
                                        //     {
                                        //       category_data: fakeCategoryItem,
                                        //       menu_data: menu,
                                        //     }
                                        //   )
                                        // }
                                      >
                                        <img
                                          src={menu.menu_photo}
                                          alt={menu.menu_name}
                                          style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "10px",
                                          }}
                                        />

                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            left: "10px",
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {menu.menu_name}
                                        </div>

                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "10px",
                                            right: "10px",
                                            width: "5rem",
                                            backgroundColor:
                                              this.state.branding
                                                .highlight_text_color,
                                            color: this.state.branding.h2_color,
                                            padding: "5px",
                                            borderRadius: "20px",
                                            border: "1px solid black",
                                            textAlign: "center",
                                          }}
                                        >
                                          {this.state?.location?.Currency
                                            ?.currency_symbol + menu.menu_price}
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                )
                            )}
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </CardBody>

                <div className="location">
                  <div className="display-flex flex-row justify-content-between ">
                    <div className="d-flex">
                      <Button
                        outline
                        className="font-weight-bold custom-padding primary-2-border mb-1 ml-1"
                        color={"primary"}
                        size="md"
                        onClick={() => this.toggleBannerModal()}
                      >
                        <Plus className="mx-2" size={20} />{" "}
                        <span className="ml-1 mr-5 primary">
                          {t("add-new-banner")}
                        </span>
                      </Button>
                    </div>
                  </div>
                  {/* <div className="mx-3">
                    <p>
       .               To enhance your location's visibility, upload captivating
                      branding images. Each branding image takes up half of the
                      screen,<br></br>so make sure to upload at least three images
                      for a full-screen experience.
                    </p>
                  </div> */}

                  <div className="row mb-4">
                    <Col md={7}>
                      <div style={{ overflow: "hidden" }}>
                        {this.state.bannerlist.length > 0 ? (
                          <div className="row">
                            <div className="col-12">
                              <Card>
                                <CardHeader>
                                  <CardTitle>
                                    {this.state?.location?.name}
                                    {t("s-banners")}
                                  </CardTitle>
                                </CardHeader>
                                <CardBody>
                                  <div className="row">
                                    {this.state.bannerlist.map(
                                      (banner, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="col-md-4 col-sm-6 col-lg-3 mb-5"
                                            style={{
                                              border: "1px solid #e6e7e9",
                                            }}
                                          >
                                            <div
                                              style={{
                                                position: "absolute",
                                                top: -10,
                                                right: 0,
                                              }}
                                            >
                                              <Button.Ripple
                                                style={{
                                                  width: 20,
                                                  height: 20,
                                                }}
                                                onClick={() =>
                                                  this.deleteBanner(banner)
                                                }
                                                className="m-0 p-0"
                                                color="danger"
                                              >
                                                <X size={14} />
                                              </Button.Ripple>
                                            </div>
                                            <img
                                              src={ImageHelper.getImageURL(
                                                banner.image
                                              )}
                                              alt={t("swiper-1")}
                                              className="img-fluid"
                                              style={{
                                                minHeight: "130px",
                                                maxHeight: "130px",
                                              }}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-12">
                              <Card>
                                <CardHeader>
                                  <CardTitle>
                                    {this.state?.location?.name}
                                    {t("s-banners")}
                                  </CardTitle>
                                </CardHeader>
                                <CardBody>
                                  <div className="row">
                                    <div className="col-12">
                                      {t("no-banner-added")}
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={"5"}>
                      <div
                        style={{
                          background: "black",
                          borderRadius: "30px",
                          paddingTop: "25px",
                          width: "29rem",
                          display: "flex",
                          margin: "auto",
                        }}
                        className="col-12"
                      >
                        <Card
                          className="p-1"
                          style={{
                            backgroundColor: "#F2F2F2",

                            overflow: "auto",
                          }}
                        >
                          <div
                            className=" p-1 w-100"
                            style={{
                              backgroundColor: `${this.state.branding?.basecolor}`,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h4
                              className="mb-0"
                              style={{
                                color: `${this.state.branding?.h2_text_color}`,
                                textAlign: "left",
                                fontWeight: "bold",
                                position: "relative",
                              }}
                            >
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "16px",
                                }}
                              >
                                Categories
                              </span>
                            </h4>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                background: "green",
                                color: "white",
                                borderRadius: "5px",
                                padding: "5px",
                              }}
                            >
                              <span style={{ marginRight: "5px" }}>
                                English
                              </span>
                              <FontAwesomeIcon
                                id="myTooltip3"
                                style={{
                                  width: "30px",
                                  height: "30px",

                                  color: "white",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                                icon={faFlag}
                              />
                            </div>
                          </div>
                          {this.state.bannerlist.length > 0 ? (
                            <Slider {...settings}>
                              {this.state.bannerlist.map((banner) => (
                                <div
                                  key={banner.banner_id}
                                  style={{
                                    border: "1px solid #e6e7e9",
                                    width: "100%",
                                    paddingBottom: "75%",
                                  }}
                                >
                                  <img
                                    src={ImageHelper.getImageURL(banner.image)}
                                    alt={t("swiper-1")}
                                    className="img-fluid"
                                    style={{
                                      width: "25rem",
                                      height: "12rem",
                                      marginTop: "1rem",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </div>
                              ))}
                            </Slider>
                          ) : (
                            <Slider {...settings}>
                              <div
                                style={{
                                  border: "1px solid #e6e7e9",
                                  width: "100%",
                                  paddingBottom: "75%",
                                }}
                              >
                                <img
                                  src="https://app.servall.be/static/media/Servall%20W%20logo.28b70eda.png"
                                  alt="Default Banner"
                                  className="img-fluid"
                                  style={{
                                    width: "20rem",
                                    height: "12rem",
                                    margin: "auto",
                                    borderRadius: "8px",
                                  }}
                                />
                              </div>
                            </Slider>
                          )}

                          <div className="row mt-3 ">
                            {fakeCategoryItem2.Menus.slice(0, 7).map(
                              (menu, index) =>
                                menu.menu_status === 1 && (
                                  <div
                                    className="col-4"
                                    key={index}
                                    style={{
                                      marginBottom: "15px",
                                      position: "relative",
                                    }}
                                  >
                                    <Card style={imageContainer}>
                                      <div
                                        style={{
                                          position: "relative",
                                          height: "100px",
                                          width: "100%",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img
                                          src={menu.menu_photo}
                                          alt={menu.menu_name}
                                          style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            // height: '100%',
                                            borderRadius: "10px",
                                          }}
                                        />
                                      </div>
                                    </Card>
                                    <div
                                      style={{
                                        fontWeight: "bold",

                                        textAlign: "center",
                                      }}
                                    >
                                      {menu.menu_name}
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </div>

                  <BannerModal
                    deleteBanner={(banner) => this.deleteBanner(banner)}
                    saveBanner={() => this.saveBanner()}
                    isOpen={this.state.isBannerModalOpen}
                    toggleModal={(banner) => this.toggleBannerModal(banner)}
                  />
                  <ConfirmationModal
                    isOpen={this.state.confirmationDialog.show}
                    accept={this.state.confirmationDialog.acceptCallback.bind(
                      this
                    )}
                    reject={this.state.confirmationDialog.rejectCallback.bind(
                      this
                    )}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("global")(Banners);
// import React from 'react';
// import {
//   Card,
//   CardHeader,
//   CardTitle,
//   CardBody,
//   Button,
//   Col,
//   Label,
//   Media,
// } from 'reactstrap';
// import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
// import { X, Image } from 'react-feather';
// import LocationService from '../../services/LocationService';
// import BrandingService from '../../services/BrandingService';
// import ImageHelper from '../../helpers/ImageHelper';
// import Swal from 'sweetalert2';
// import { withTranslation } from 'react-i18next';
// import i18n from 'i18next';

// class Branding extends React.Component {
//   state = {
//     branding: null,
//     loading: true,
//     location: null,
//     logo: null,
//   };
//   async componentDidMount() {
//     const savedLanguage = localStorage.getItem('lang');
//     if (savedLanguage) {
//       i18n.changeLanguage(savedLanguage);
//     }

//     const location = LocationService.getLocation();
//     if (location) {
//       await this.refreshData(location.loc_id);
//       this.setState({ loading: false, location: location });
//     } else {
//       this.setState({ loading: false });
//     }
//   }

//   async refreshData(loc_id, initialize = true) {
//     this.setState({ loading: initialize });
//     let branding = await BrandingService.getBranding(loc_id);
//     this.setState({ branding: branding });
//   }

//   async saveBranding() {
//     this.setState({ loading: true });
//     const fileInput = document.querySelector('#bannerFileInput');
//     const formData = new FormData();
//     if (fileInput.files.length > 0) {
//       formData.append('file', fileInput.files[0]);
//     }
//     formData.append('brandingItem', JSON.stringify(this.state.branding));
//     let branding = await BrandingService.saveBranding(
//       this.state.location.loc_id,
//       formData
//     );
//     this.setState({ branding: branding, loading: false, logo: null });
//   }

//   async handleChange(e) {
//     if (e.target.files.length > 0) {
//       const allowedFileTypes = ['jpg', 'png', 'jpeg', 'webp', 'svg'];
//       const fileExtension = e.target.files[0].name.split('.').at(-1);
//       if (!allowedFileTypes.includes(fileExtension)) {
//         Swal.fire({
//           icon: 'error',
//           title: 'Oops...',
//           text: `Upload image does not support. Image type must be ${allowedFileTypes.join(
//             ', '
//           )}`,
//         });
//         return false;
//       }

//       var size = parseFloat(e.target.files[0].size / (1024 * 1024 * 5)).toFixed(
//         2
//       );
//       if (size > 5) {
//         alert('Please select image size less than 5 MB');
//         return false;
//       }
//       let reader = new FileReader();
//       let itemProps = this.state.branding;
//       itemProps.deletePhoto = true;
//       reader.onload = (ev) => {
//         this.setState({ logo: ev.target.result, branding: itemProps });
//       };
//       reader.readAsDataURL(e.target.files[0]);
//     }
//   }

//   changeBaseColor(index, value) {
//     console.log();
//     let itemProps = this.state.branding;
//     itemProps[index] = value;
//     this.setState({ branding: itemProps });
//   }

//   render() {
//     const { t } = this.props;
//     let fileRef = React.createRef();

//     if (this.state.loading) return <SpinnerComponent />;
//     return (
//       <>
//         <div className="location">
//           <div className="row">
//             <div className="col-12">
//               <Card>
//                 <CardHeader>
//                   <CardTitle>{t('branding')}</CardTitle>
//                 </CardHeader>
//                 <CardBody>
//                   <div className="row">
//                     {this.state.branding.logo !== null &&
//                       this.state.branding.logo !== '' && (
//                         <div
//                           className="col-sm-6 col-md-4 mb-5"
//                           style={{
//                             border: '1px solid #e6e7e9',
//                             textAlign: 'center',
//                           }}
//                         >
//                           <img
//                             src={ImageHelper.getImageURL(
//                               this.state.branding.logo
//                             )}
//                             alt=""
//                             className="img-fluid"
//                             style={{ minHeight: '200px', maxHeight: '200px' }}
//                           />
//                         </div>
//                       )}
//                     <Col className="mx-auto" md={4} sm={6}>
//                       <Label for="photoDiv">Image:</Label>
//                       <div
//                         tabIndex="0"
//                         id="photoDiv"
//                         className="display-flex flex-column border border-2 border-light cursor-pointer"
//                         onKeyUp={(e) =>
//                           e.keyCode === 13 && !e.ctrlKey
//                             ? fileRef.current.click()
//                             : null
//                         }
//                         onClick={() => fileRef.current.click()}
//                         style={{ position: 'relative', height: 120 }}
//                       >
//                         <div style={{ textAlign: '-webkit-center' }}>
//                           {this.state.logo ? (
//                             <div>
//                               <div
//                                 style={{
//                                   position: 'absolute',
//                                   top: -10,
//                                   right: -10,
//                                 }}
//                               >
//                                 <Button.Ripple
//                                   style={{ width: 20, height: 20 }}
//                                   onClick={() => {
//                                     fileRef.current.value = null;
//                                     let itemProps = this.state.branding;
//                                     itemProps.deletePhoto = false;
//                                     this.setState({
//                                       logo: null,
//                                       branding: itemProps,
//                                     });
//                                   }}
//                                   className="m-0 p-0"
//                                   color="danger"
//                                 >
//                                   <X size={14} />
//                                 </Button.Ripple>
//                               </div>
//                               <div
//                                 style={{ position: 'absolute', bottom: 3 }}
//                                 className="full-width"
//                               >
//                                 <Button.Ripple
//                                   className="p-25"
//                                   color="primary"
//                                   onClick={() => fileRef.current.click()}
//                                 >
//                                   <span className="font-small-1">
//                                     {t('change-photo')}{' '}
//                                   </span>
//                                 </Button.Ripple>
//                               </div>
//                               <Media
//                                 object
//                                 src={this.state.logo}
//                                 alt="image"
//                                 style={{ maxWidth: '100%', maxHeight: 118 }}
//                                 className="align-self-center"
//                               />
//                             </div>
//                           ) : (
//                             <div
//                               className="align-self-center"
//                               style={{ padding: 9, height: 50, width: 50 }}
//                             >
//                               <Image size={32} className="align-self-center" />
//                             </div>
//                           )}
//                         </div>
//                         {this.state.logo == null ? (
//                           <React.Fragment>
//                             <span className="font-small-2 primary align-self-center">
//                               {t('click-to-upload-a-file')}{' '}
//                             </span>
//                             <span className="font-small-2 color- align-self-center align-text-center">
//                               JPG,PNG,JPEG,WEBP,SVG <br /> {t('up-to-5mb')}
//                             </span>
//                           </React.Fragment>
//                         ) : null}
//                       </div>
//                       <input
//                         type="file"
//                         id="bannerFileInput"
//                         name="fileInput"
//                         onChange={(e) => this.handleChange(e)}
//                         ref={fileRef}
//                         style={{ display: 'none' }}
//                         accept="image/*"
//                       />
//                     </Col>
//                   </div>
//                   <div className="row mt-3">
//                     <Col md={'2'}>
//                       <Label for="photoDiv">{t('base-color')}: </Label>
//                       <input
//                         className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
//                         type="color"
//                         id="basecolor"
//                         name="basecolor"
//                         value={this.state.branding.basecolor}
//                         onChange={(e) =>
//                           this.changeBaseColor('basecolor', e.target.value)
//                         }
//                       />
//                     </Col>
//                     <Col md={'2'}>
//                       <Label for="photoDiv">{t('highlight-color')}: </Label>
//                       <input
//                         className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
//                         type="color"
//                         id="highlight_color"
//                         name="highlight_color"
//                         value={this.state.branding.highlight_color}
//                         onChange={(e) =>
//                           this.changeBaseColor(
//                             'highlight_color',
//                             e.target.value
//                           )
//                         }
//                       />
//                     </Col>
//                     <Col md={'2'}>
//                       <Label for="photoDiv">
//                         {t('highlight-text-color')}:{' '}
//                       </Label>
//                       <input
//                         className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
//                         type="color"
//                         id="highlight_text_color"
//                         name="highlight_text_color"
//                         value={this.state.branding.highlight_text_color}
//                         onChange={(e) =>
//                           this.changeBaseColor(
//                             'highlight_text_color',
//                             e.target.value
//                           )
//                         }
//                       />
//                     </Col>
//                     <Col md={'2'}>
//                       <Label for="photoDiv">{t('h2-color')}: </Label>
//                       <input
//                         className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
//                         type="color"
//                         id="h2_color"
//                         name="h2_color"
//                         value={this.state.branding.h2_color}
//                         onChange={(e) =>
//                           this.changeBaseColor('h2_color', e.target.value)
//                         }
//                       />
//                     </Col>
//                     <Col md={'2'}>
//                       <Label for="photoDiv">{t('h2-text-color')}: </Label>
//                       <input
//                         className="display-flex flex-column border border-2 border-light cursor-pointer w-100"
//                         type="color"
//                         id="h2_text_color"
//                         name="h2_text_color"
//                         value={this.state.branding.h2_text_color}
//                         onChange={(e) =>
//                           this.changeBaseColor('h2_text_color', e.target.value)
//                         }
//                       />
//                     </Col>
//                   </div>
//                   <div className="row w-100 mt-2 p-2">
//                     <button
//                       className="btn btn-primary"
//                       onClick={() => this.saveBranding()}
//                     >
//                       {t('save-branding')}{' '}
//                     </button>
//                   </div>
//                 </CardBody>
//               </Card>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default withTranslation('global')(Branding);

// export default Branding;
