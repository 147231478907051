import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getAllPricing } from "../../redux/actions/pricing";
import { getAllProductPricing } from "../../redux/actions/productpricing";

import "../../assets/scss/pages/page-pricing.scss";
import { Button, Table } from "reactstrap";

import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";

import "../../assets/css/collapsibeCardComponent.scss";

import { Edit2 } from "react-feather";

import { useTranslation } from "react-i18next";

const PricingSession = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await props.getAllProductPricing();
    };
    fetchData();
  }, []);

  useEffect(() => {
    const data = async () => {
      await props.getAllPricing();
    };
    data();
  }, []);

  const { data: pricing, loading: loadingPricing } = useSelector(
    (store) => store.pricing.allPricingItems
  );
  console.log("Pricing Data:", pricing);

  const { data: productpricing, loading: loadingProductPricing } = useSelector(
    (store) => store.productpricing.allProductPricingItems
  );
  console.log("Product Pricing Data:", productpricing);

  const handleEdit = (
    packageId,
    title,
    price,
    description,
    PerksInPackages,
    ProductsInPackages
  ) => {
    const editData = {
      packageId,
      title,
      price,
      description,
      PerksInPackages,

      ProductsInPackages,
    };

    props.history.push({
      pathname: `/pricing/edit/${packageId}`,
      state: editData,
    });
  };

  if (loadingPricing || loadingProductPricing) return <SpinnerComponent />;

  return (
    <>
      <div className="pricing-table-container">
        <Button
          className="mb-1 col-sm-12 col-md-6 col-lg-3 btn btn-primary float-right"
          color="primary"
          size="md"
          // onClick={toggleMenuItemModal}
          onClick={() => props.history.push("/pricing/new")}
        >
          <Edit2 size={16} />
          <span className="ml-1 d-md-inline">{t('add-new')} </span>
        </Button>

        <Table responsive>
          <thead>
            <tr>
              <th>{t('edit')}</th>
              <th>ID</th>
              <th>{t('title')}</th>
              <th>{t('description')}</th>
              <th>{t('price-0')}</th>
              <th>{t('perks')} </th>
              <th>{t('packages')}</th>
              <th>{t('products-in-packages')}</th>
            </tr>
          </thead>
          <tbody>
            {pricing?.packagesList?.map((item) => (
              <>
                <tr key={item.id}>
                  <td>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        handleEdit(
                          item.id,
                          item.title,
                          item.estimate_price,
                          item.description,
                          item.PerksInPackages,
                          item.ProductsInPackages
                        )
                      }
                    >
                      <Edit2 size={16} />
                    </Button>
                  </td>
                  <td>{item.id}</td>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>{item.estimate_price}</td>

                  <td> {item.PerksInPackages.length}</td>
                  <td> {item.ProductsInPackages.length}</td>
                  <td>
                    {item.ProductsInPackages.map(
                      (pkg) => pkg.product_title
                    ).join(", ")}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const {
    pricing: {
      allPricingItems: { data: pricing, loading: loadingPricing },
    },
    productpricing: {
      allProductPricingItems: {
        data: productpricing,
        loading: loadingProductPricing,
      },
    },
  } = state;

  return {
    pricing,
    loadingPricing,
    productpricing,
    loadingProductPricing,
  };
}

export default connect(mapStateToProps, {
  getAllPricing,
  getAllProductPricing,
})(PricingSession);

// import React, { useEffect, useState } from 'react';
// import { connect, useSelector } from 'react-redux';
// import { getAllPricing } from '../../redux/actions/pricing';
// import { getAllProductPricing } from '../../redux/actions/productpricing';
// import PricingService from '../../services/PricingService';
// import '../../assets/scss/pages/page-pricing.scss';
// import {
//   Button,
//   Table,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   Badge,
//   Card,
//   CardBody,
//   CardHeader,
//   CardTitle,
//   Collapse,
//   DropdownMenu,
//   DropdownToggle,
//   UncontrolledDropdown,
//   NavLink,
// } from 'reactstrap';
// import { Check, ChevronDown, ChevronUp, MoreVertical } from 'react-feather';
// import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';

// import '../../assets/css/collapsibeCardComponent.scss';

// import Checkbox from '../@vuexy/checkbox/CheckboxesVuexy';

// import { Edit2 } from 'react-feather';
// const PricingSession = (props) => {
//   const [activeTab, setActiveTab] = useState('info');
//   const [isOpen, setIsOpen] = useState(false); // State for card collapse
//   const [field1Value, setField1Value] = useState('');
//   const [field2Value, setField2Value] = useState('');
//   const [expandedAccordions, setExpandedAccordions] = useState({});
//   const [menuItemModalOpen, setMenuItemModalOpen] = useState(false);
//   const [formData, setFormData] = useState({
//     title: '',
//     description: '',
//     estimate_price: '',
//     products: [],
//     benefits: [],
//   });

//   const [checkboxValues, setCheckboxValues] = useState({
//     checkbox1: false,
//     checkbox2: false,
//     checkbox3: false,
//   });
//   const toggleCollapse = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleField1Change = (event) => {
//     setField1Value(event.target.value);
//   };

//   const handleField2Change = (event) => {
//     setField2Value(event.target.value);
//   };

//   const handleCheckboxChange = (checkboxName) => (event) => {
//     setCheckboxValues({
//       ...checkboxValues,
//       [checkboxName]: event.target.checked,
//     });
//   };

//   const toggleTab = (tab) => {
//     if (activeTab !== tab) setActiveTab(tab);
//   };

//   const toggleProductAccordion = (index) => {
//     setExpandedAccordions((prevState) => ({
//       ...prevState,
//       [index]: !prevState[index],
//     }));
//   };

//   const isProductAccordionOpen = (index) => {
//     return expandedAccordions[index];
//   };

//   const toggleMenuItemModal = () => {
//     setActiveTab('info');
//     setMenuItemModalOpen(!menuItemModalOpen);
//   };
//   // Function to open the modal
//   useEffect(() => {
//     const fetchData = async () => {
//       await props.getAllProductPricing();
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const data = async () => {
//       await props.getAllPricing();
//     };
//     data();
//   }, []);

//   const { data: pricing, loading: loadingPricing } = useSelector(
//     (store) => store.pricing.allPricingItems
//   );
//   console.log('Pricing Data:', pricing);

//   const { data: productpricing, loading: loadingProductPricing } = useSelector(
//     (store) => store.productpricing.allProductPricingItems
//   );
//   console.log('Product Pricing Data:', productpricing);
//   const handleFormSubmit = async () => {
//     const { title, description, estimate_price, products, benefits } = formData;

//     const newPackage = {
//       title,
//       description,
//       estimate_price,
//       total_products: products.length,
//       number_of_benefits: benefits.length,
//       products,
//       benefits,
//     };

//     try {
//       const response = await PricingService.createPricingPackage(newPackage);

//       if (response.ok) {
//         // Handle successful response
//         console.log('Package created successfully');
//       } else {
//         // Handle error response
//         console.error('Failed to create package');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const handleEdit = (
//     packageId,
//     title,
//     price,
//     pricePerType,
//     description,
//     multipleQuantity,
//     physicalSupport,
//     categoryTag,
//     packageProductName
//   ) => {
//     const editData = {
//       packageId,
//       title,
//       price,
//       pricePerType,
//       description,
//       multipleQuantity,
//       physicalSupport,
//       categoryTag,
//       packageProductName,
//     };

//     props.history.push({
//       pathname: `/packages/edit/${packageId}`,
//       state: editData,
//     });
//   };

//   if (loadingPricing || loadingProductPricing) return <SpinnerComponent />;

//   return (
//     <>
//       <div className="pricing-table-container">
//         <Button
//           className="mb-1 col-sm-12 col-md-6 col-lg-3 btn btn-primary float-right"
//           color="primary"
//           size="md"
//           // onClick={toggleMenuItemModal}
//           onClick={() => props.history.push('/pricing/new')}
//         >
//           <Edit2 size={16} />
//           <span className="ml-1 d-md-inline">Add New Package</span>
//         </Button>

//         <Table responsive>
//           <thead>
//             <tr>
//               <th>{/* Edit */}</th>
//               <th>ID</th>
//               <th>Title</th>
//               <th>Description</th>
//               <th>Estimated Price</th>
//               <th>Perks In Packages</th>
//               <th>Products In Packages</th>
//             </tr>
//           </thead>
//           <tbody>
//             {pricing.packagesList.map((item) => (
//               <>
//                 <tr key={item.id}>
//                   <td>
//                     {/* <Button
//                       color="primary"
//                       size="sm"
//                       onClick={() =>
//                         handleEdit(
//                           item.id,
//                           item.title,
//                           item.estimate_price,
//                           item.description
//                         )
//                       }
//                     >
//                       <Edit2 size={16} />
//                     </Button> */}
//                   </td>
//                   <td>{item.id}</td>
//                   <td>{item.title}</td>

//                   <td>{item.description}</td>
//                   <td>{item.estimate_price}</td>

//                   <td> {item.PerksInPackages.length}</td>

//                   <td> {item.ProductsInPackages.length}</td>
//                 </tr>

//                 <Modal isOpen={menuItemModalOpen} toggle={toggleMenuItemModal}>
//                   <ModalHeader toggle={toggleMenuItemModal}>
//                     Add New Package
//                   </ModalHeader>
//                   <ModalBody>
//                     <div className="tab-content" id="myTabContent">
//                       <ul className="nav nav-tabs" id="myTab" role="tablist">
//                         <li className="nav-item">
//                           <NavLink
//                             className={`nav-link ${
//                               activeTab === 'info' ? 'active' : ''
//                             }`}
//                             id="info-tab"
//                             onClick={() => toggleTab('info')}
//                           >
//                             Info
//                           </NavLink>
//                         </li>
//                         <li className="nav-item">
//                           <NavLink
//                             className={`nav-link ${
//                               activeTab === 'products' ? 'active' : ''
//                             }`}
//                             id="products-tab"
//                             onClick={() => toggleTab('products')}
//                           >
//                             Products
//                           </NavLink>
//                         </li>
//                         <li className="nav-item">
//                           <NavLink
//                             className={`nav-link ${
//                               activeTab === 'benefits' ? 'active' : ''
//                             }`}
//                             id="benefits-tab"
//                             onClick={() => toggleTab('benefits')}
//                           >
//                             Benefits
//                           </NavLink>
//                         </li>
//                       </ul>
//                       <div
//                         className={`tab-pane fade ${
//                           activeTab === 'info' ? 'show active' : ''
//                         }`}
//                         id="info"
//                         role="tabpanel"
//                         aria-labelledby="info-tab"
//                       >
//                         <form>
//                           <div className="form-group">
//                             <label>Title</label>
//                             <input
//                               type="text"
//                               className="form-control"
//                               name="title"
//                               value={formData.title}
//                               onChange={(e) =>
//                                 setFormData({
//                                   ...formData,
//                                   title: e.target.value,
//                                 })
//                               }
//                             />
//                           </div>
//                           <div className="form-group">
//                             <label>Description</label>
//                             <textarea
//                               className="form-control"
//                               name="description"
//                               rows="3"
//                               value={formData.description}
//                               onChange={(e) =>
//                                 setFormData({
//                                   ...formData,
//                                   description: e.target.value,
//                                 })
//                               }
//                             ></textarea>
//                           </div>
//                           <div className="form-group">
//                             <label>Estimated Price</label>
//                             <input
//                               type="number"
//                               className="form-control"
//                               name="estimate_price"
//                               value={formData.estimate_price}
//                               onChange={(e) =>
//                                 setFormData({
//                                   ...formData,
//                                   estimate_price: e.target.value,
//                                 })
//                               }
//                             />
//                           </div>
//                         </form>
//                       </div>

//                       {/* Products Tab */}

//                       {/* Products Tab */}
//                       <div
//                         className={`tab-pane fade ${
//                           activeTab === 'products' ? 'show active' : ''
//                         }`}
//                         id="products"
//                         role="tabpanel"
//                         aria-labelledby="products-tab"
//                       >
//                         {item.ProductsInPackages.map((product, index) => (
//                           <>
//                             <div>
//                               <Card className="border-radius-0-5">
//                                 <CardHeader
//                                   onClick={() => toggleProductAccordion(index)}
//                                   className="border-radius-0-5 bg-white border-bottom border-primary"
//                                 >
//                                   <CardTitle className="lead collapse-title collapsed">
//                                     {product.product_title}
//                                   </CardTitle>

//                                   <div className="justify-content-end display-flex">
//                                     <UncontrolledDropdown className="chart-dropdown">
//                                       <DropdownToggle
//                                         color=""
//                                         className="bg-transparent btn-sm border-0 p-50"
//                                       >
//                                         <MoreVertical
//                                           size={18}
//                                           className="cursor-pointer"
//                                         />
//                                       </DropdownToggle>
//                                       <DropdownMenu right></DropdownMenu>
//                                     </UncontrolledDropdown>
//                                     <Checkbox
//                                       color="primary"
//                                       icon={<span className="vx-icon"></span>}
//                                       checked={isOpen}
//                                       onChange={toggleCollapse}
//                                     />
//                                     <span className="text-bold-500 cursor-pointer">
//                                       <span className="text-bold-500 cursor-pointer">
//                                         {isProductAccordionOpen(index) ? (
//                                           <ChevronUp size={25} />
//                                         ) : (
//                                           <ChevronDown size={25} />
//                                         )}
//                                       </span>
//                                     </span>
//                                   </div>
//                                 </CardHeader>
//                                 <Collapse
//                                   isOpen={isProductAccordionOpen(index)}
//                                 >
//                                   <CardBody className="menu-dark-bg-color p-3">
//                                     {/* Text fields */}
//                                     <div className="form-group">
//                                       <label>Field 1</label>
//                                       <input
//                                         type="text"
//                                         className="form-control"
//                                         value={field1Value}
//                                         onChange={handleField1Change}
//                                       />
//                                     </div>
//                                     <div className="form-group">
//                                       <label>Field 2</label>
//                                       <input
//                                         type="text"
//                                         className="form-control"
//                                         value={field2Value}
//                                         onChange={handleField2Change}
//                                       />
//                                     </div>

//                                     {/* Checkboxes */}
//                                     <div className="form-group">
//                                       <Checkbox
//                                         color="primary"
//                                         icon={<span className="vx-icon"></span>}
//                                         checked={checkboxValues.checkbox1}
//                                         onChange={handleCheckboxChange(
//                                           'checkbox1'
//                                         )}
//                                       />
//                                       <span className="ml-2">Checkbox 1</span>
//                                     </div>
//                                     <div className="form-group">
//                                       <Checkbox
//                                         color="primary"
//                                         icon={<span className="vx-icon"></span>}
//                                         checked={checkboxValues.checkbox2}
//                                         onChange={handleCheckboxChange(
//                                           'checkbox2'
//                                         )}
//                                       />
//                                       <span className="ml-2">Checkbox 2</span>
//                                     </div>
//                                     <div className="form-group">
//                                       <Checkbox
//                                         color="primary"
//                                         icon={<span className="vx-icon"></span>}
//                                         checked={checkboxValues.checkbox3}
//                                         onChange={handleCheckboxChange(
//                                           'checkbox3'
//                                         )}
//                                       />
//                                       <span className="ml-2">Checkbox 3</span>
//                                     </div>
//                                   </CardBody>
//                                 </Collapse>
//                               </Card>
//                             </div>
//                           </>
//                         ))}
//                       </div>

//                       {/* Benefits Tab */}
//                       <div
//                         className={`tab-pane fade ${
//                           activeTab === 'benefits' ? 'show active' : ''
//                         }`}
//                         id="benefits"
//                         role="tabpanel"
//                         aria-labelledby="benefits-tab"
//                       >
//                         {item.PerksInPackages.map((benefit, index) => (
//                           <div key={index} className="benefit-item">
//                             <div className="form-group">
//                               <h3>{benefit.title}</h3>
//                             </div>
//                           </div>
//                         ))}
//                       </div>
//                     </div>

//                     <div className="text-right">
//                       <button
//                         type="submit"
//                         onClick={handleFormSubmit}
//                         className="btn btn-primary"
//                       >
//                         Save Package
//                       </button>
//                     </div>
//                   </ModalBody>
//                 </Modal>
//               </>
//             ))}
//           </tbody>
//         </Table>
//       </div>
//     </>
//   );
// };

// function mapStateToProps(state) {
//   const {
//     pricing: {
//       allPricingItems: { data: pricing, loading: loadingPricing },
//     },
//     productpricing: {
//       allProductPricingItems: {
//         data: productpricing,
//         loading: loadingProductPricing,
//       },
//     },
//   } = state;

//   return {
//     pricing,
//     loadingPricing,
//     productpricing,
//     loadingProductPricing,
//   };
// }

// export default connect(mapStateToProps, {
//   getAllPricing,
//   getAllProductPricing,
// })(PricingSession);
