import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { getReviewsByLocationId } from "../../redux/actions/reviews";
import "../../assets/scss/pages/page-pricing.scss";
import { Button, Card, Table } from "reactstrap";
import LocationService from "../../services/LocationService";
import Swal from "sweetalert2";
import ReviewService from "../../services/ReviewService";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import "react-image-lightbox/style.css";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { CheckCircle, Trash2, XCircle } from "react-feather";
import { getLocation } from "../../redux/actions/locations";
import CONSTANTS from "../../constants";
import TimeHelper from "../../helpers/TimeHelper";

const Review = ({ reviews, getReviewsByLocationId }) => {
  const [selectLocation, setSelectLocation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [locationData, setLocationData] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const loc = LocationService.getLocation();
        if (loc) {
          await getReviewsByLocationId(loc.loc_id);
          await refreshData(loc.loc_id);
          setLoading(false);
        } else {
          setLoading(false);
          setSelectLocation(true);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
        // Handle error
      }
    };

    fetchData();

    return () => {
      // Cleanup
    };
  }, [getReviewsByLocationId]);

  const refreshData = async (loc_id, updateLocation = true) => {
    let loc = locationData;
    if (updateLocation) {
      loc = await LocationService.getLocationFromServer(loc_id);
    }

    setLocationData({
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    });
  };

  const toggleLocation = () => {
    setSelectLocation(!selectLocation);
  };

  const onSelect = async (loc) => {
    setLoading(true);
    LocationService.setLocation(loc);
    await refreshData(loc.loc_id);
    setSelectLocation(false);
    setLoading(false);
  };

  const changeLocation = () => {
    setSelectLocation(true);
  };

  const locationName = locationData ? locationData.name : "";

  const handleReviewApproval = async (reviewId) => {
    try {
      // Call the approveReview action
      await ReviewService.approveReviewStatusById(reviewId);
      // Show success message
      Swal.fire({
        icon: "success",
        title: "Review Approved!",
        showConfirmButton: false,
        timer: 1500,
      });
      // Refresh reviews
      await getReviewsByLocationId(locationData.loc_id);
    } catch (error) {
      // Show error message
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const handleReviewReject = async (reviewId) => {
    try {
      await ReviewService.rejectReviewStatusById(reviewId);

      Swal.fire({
        icon: "success",
        title: "Review Rejected!",
        showConfirmButton: false,
        timer: 1500,
      });
      // Refresh reviews
      await getReviewsByLocationId(locationData.loc_id);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const handleReviewDelete = async (reviewId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this review.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // User confirmed deletion
        await ReviewService.deleteReviewById(reviewId);

        Swal.fire({
          icon: "success",
          title: "Review Deleted!",
          showConfirmButton: false,
          timer: 1500,
        });

        // Refresh reviews
        await getReviewsByLocationId(locationData.loc_id);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  if (selectLocation) {
    return <SelectLocation onBack={toggleLocation} onSelect={onSelect} />;
  }

  if (loading) {
    return <SpinnerComponent />;
  }

  return (
    <>
      <div className="display-block mb-1">
        <LocationSelectHeader
          text={locationName}
          onIconClick={changeLocation}
        />
      </div>
      <div id="pricing-table">
        <Card>
          <Table responsive>
            <thead>
              <tr>
                <th>Id</th>
                <th>Review</th>
                <th>Email</th>
                <th>Phone</th>

                <th>Room Number</th>
                <th className="text-center">Stay Period</th>
                <th>Status</th>
                <th>Image</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reviews && reviews.length > 0 ? (
                reviews.map((review) => (
                  <tr key={review.id}>
                    <td>{review.id}</td>
                    <td>{review.review_text}</td>
                    <td>{review.user_email}</td>
                    <td>{review.phone_number}</td>

                    <td>{review.room_number}</td>
                    <tr>
                      <td>{`${review.stay_date_start} - ${review.stay_date_end}`}</td>
                    </tr>
                    <td>{review.isApproved ? "Approved" : "Rejected"}</td>
                    <td>
                      {review.image && (
                        <img
                          src={`${CONSTANTS.API_URL}/hotel_data/reviews/${review.image}`}
                          alt="Review"
                          style={{ width: "50px" }}
                        />
                      )}
                    </td>

                    <td>
                      {" "}
                      {review.isApproved ? (
                        <Button
                          color="danger"
                          onClick={() => handleReviewReject(review.id, false)}
                        >
                          <XCircle />
                        </Button>
                      ) : (
                        <>
                          <Button
                            color="success"
                            onClick={() => handleReviewApproval(review.id)}
                          >
                            <CheckCircle />
                          </Button>
                        </>
                      )}
                      {/* <Button
                        color="success"
                        onClick={() => handleReviewApproval(review.id, true)}
                      >
                        <CheckCircle />
                      </Button>

                      <Button
                        className="ml-2"
                        color="danger"
                        onClick={() => handleReviewReject(review.id, false)}
                      >
                        <XCircle />
                      </Button> */}
                    </td>
                    <td>
                      <Button
                        color="danger"
                        onClick={() => handleReviewDelete(review.id)}
                      >
                        <Trash2 />
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="9"
                    style={{ textAlign: "center", paddingTop: "20px" }}
                  >
                    No Reviews found for {locationName}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  location: state.locations.location.data,
  reviews: state.reviewsReducer.reviewsByLocation.data,
});

export default withTranslation("global")(
  connect(mapStateToProps, {
    getReviewsByLocationId,
    getLocation,
  })(Review)
);
