// export default class ValidationHelper {
//   static required(value) {
//     return (value || typeof value === 'number') ? undefined : 'required';
//   }
//   static isValidEmail(value) {
//     let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     if (!value) {
//       return 'required';
//     } else if (!regEmail.test(value)) {
//       return 'required';
//     }
//   }
// }

export default class ValidationHelper {
  static required(value) {
    return value || typeof value === "number" ? undefined : "required";
  }
  static nonNegativeNumber(value) {
    return isNaN(value) || value >= 0
      ? undefined
      : "Please enter a non-negative number";
  }

  static isValidEmail(value) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!value) {
      return "required";
    } else if (!regEmail.test(value)) {
      return "required";
    }
  }

  static validateRoomName(value) {
    console.log("validateRoomName called with value:", value);

    if (value === undefined || value === null) {
      return "Room Name is required";
    }

    const trimmedValue = value.trim();

    const error = trimmedValue ? undefined : "Room Name is required";

    console.log("validateRoomName returning error:", error);

    return error;
  }
}
