import CONSTANTS from "../../../constants";
import * as ReservationService from "../../../services/ReservationServices";

export const getALlLocationReservations = (loc_id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CONSTANTS.ACTIONS.RESERVATIONS.GET_ALL, reservations: [], loading: true });
            let reservations = await ReservationService.getAllReservations(loc_id);
            dispatch({ type: CONSTANTS.ACTIONS.RESERVATIONS.GET_ALL, reservations, loading: false });
        } catch (error) {
            console.log(error);
            dispatch({ type: CONSTANTS.ACTIONS.RESERVATIONS.GET_ALL, reservations: [], loading: false });
        }
    };
}