import LoginService from "./LoginService";
import CONSTANTS from "../constants";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getAllSurveyQuestions,
  changeQuestionStatus,
  saveQuestion,
  updateQuestion,
  deleteQuestion,
};

async function getAllSurveyQuestions(loc_id, page = 0) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.SURVEY.GET_SURVEY(
      loc_id,
      page
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not delete tags");
}

async function changeQuestionStatus(item, status) {
  console.log("Item: ", item);
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.SURVEY.UPDATE_SURVEY_STATUS(
      item.location_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify({
        status: status,
        question_id: item.question_id,
      }),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not delete tags");
}

async function saveQuestion(item, loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.SURVEY.CREATE_QUESTION(
      loc_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(item),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not delete tags");
}

async function updateQuestion(item, loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.SURVEY.UPDATE_QUESTION(
      loc_id,
      item.question_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify({ question: item }),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not delete tags");
}
async function deleteQuestion(loc_id, question_id) {
  try {
    let { token } = LoginService.getToken();
    let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.SURVEY.DELETE_QUESTION(
        loc_id,
        question_id
      )}`,
      {
        method: "DELETE",
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
    );
    if (response.status === 200) {
      return "Question deleted successfully";
    } else {
      throw new Error("Failed to delete questionssssss");
    }
  } catch (error) {
    throw error;
  }
}
