import React, { Component } from "react";
import { Link } from "react-router-dom";
import SelectLocation from "../shared/SelectLocation";

import CONSTANTS from "../../constants";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class Locations extends Component {
  componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }

  onSelect(location) {
    this.props.history.push(
      `${CONSTANTS.BASE_URL}locations/${location.loc_id}`
    );
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="locations">
          <SelectLocation hideBack={true} onSelect={this.onSelect.bind(this)} />
          <div className="add-location-button p-3">
            {/* TODO: Add Validation so only admins can see that */}
            <Link
              className="col-sm-12 col-md-6 col-lg-3 btn btn-primary float-right"
              to={`${CONSTANTS.BASE_URL}locations/new`}
            >
              {t("add-new-location")}{" "}
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("global")(Locations);
