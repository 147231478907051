import CONSTANTS from "../../../constants";
import ReviewService from "../../../services/ReviewService";

export function getReviewsByLocationId(locationId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.HOTELREVIEWS.GET_ALL,
        reviews: [],
        loading: true,
      });
      let reviews = await ReviewService.getAllReviews(locationId);
      console.log("REVIEWS", reviews);
      dispatch({
        type: CONSTANTS.ACTIONS.HOTELREVIEWS.GET_ALL,
        reviews: reviews,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.HOTELREVIEWS.GET_ALL,
        reviews: [],
        loading: false,
      });
    }
  };
}

// export function changeTableStatus(locationId, data) {
//   return async (dispatch) => {
//     try {
//       dispatch({
//         type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
//         tables: [],
//         loading: true,
//       });
//       let tables = await TableService.changeTableStatus(locationId, data);
//       dispatch({
//         type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
//         tables,
//         loading: false,
//       });
//     } catch (error) {
//       console.log(error);
//       dispatch({
//         type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
//         tables: [],
//         loading: false,
//       });
//     }
//   };
// }
// export function deleteTable(locationId, data, floor_id) {
//   return async (dispatch) => {
//     try {
//       // dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
//       let tables = await TableService.deleteTable(locationId, data, floor_id);
//       dispatch({
//         type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
//         tables,
//         loading: false,
//       });
//     } catch (e) {
//       throw e.toString();
//     }
//   };
// }
