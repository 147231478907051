import React, { useState, useEffect } from "react";
import { RefreshCw, Copy } from "react-feather";
import LocationService from "../../services/LocationService";
import { Button, Card } from "reactstrap";
import { toast } from "react-hot-toast";
import { logDOM } from "@testing-library/react";

const OTPGenerator = () => {
  const [otp, setOTP] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const locationData = LocationService.getLocation();

    try {
      const location = await LocationService.getLocationFromServer(
        locationData.loc_id
      );

      console.log("OTP REFRESH TIME", location.otp_refresh_time);
      const otpCreateTime = new Date(location.otp_create_time);
      const otpRefreshTime = parseInt(location.otp_refresh_time);

      const expiryTimestamp =
        otpCreateTime.getTime() + otpRefreshTime * 60 * 1000;
      setExpiryTime(expiryTimestamp);

      setOTP(location.location_otp);
    } catch (error) {
      console.error("Error fetching location from server:", error);
    }
  };

  const resetOTP = async () => {
    try {
      await LocationService.locationOtpReset();
      fetchData();
    } catch (error) {
      console.error("Error resetting OTP:", error);
    }
  };

  const copyOTP = () => {
    navigator.clipboard.writeText(otp);
    toast.success("OTP copied to clipboard");
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = expiryTime - now;
      if (distance <= 0) {
        clearInterval(interval);
        fetchData();
      } else {
        const hours = Math.floor(distance / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdown(
          `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
            seconds < 10 ? "0" : ""
          }${seconds}`
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryTime]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const now = new Date().getTime();
  //     const distance = expiryTime - now;
  //     if (distance <= 0) {
  //       clearInterval(interval);
  //       fetchData();
  //     } else {
  //       const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //       const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  //       setCountdown(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [expiryTime]);

  const boxStyle = {
    width: "30px",
    height: "30px",
    border: "1px solid #ccc",
    margin: "0 1px",
    padding: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    color: "white",
    fontWeight: "bold",
  };

  return (
    <Card>
      <div className="d-flex justify-content-center align-items-center">
        {otp.split("").map((character, index) => (
          <div className="otp-box bg-primary" style={boxStyle} key={index}>
            {character}
          </div>
        ))}
        <div style={{ marginTop: "5px" }} className="otp-expiry ml-2 ">
          {expiryTime ? <h4>{countdown}</h4> : null}
        </div>
        <div className="ml-2">
          <button
            className="btn btn-sm bg-primary text-white mr-1"
            onClick={copyOTP}
          >
            <Copy size={16} />
          </button>
          <button
            className="btn btn-sm bg-primary text-white"
            onClick={resetOTP}
          >
            <RefreshCw size={16} />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default OTPGenerator;
