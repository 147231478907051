import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import { getAllNavBarNotifications } from "../../redux/actions/notifications";
import "../../assets/scss/pages/page-pricing.scss";
import ListAllNotification from "./ListAllNotification";
import { Button } from "reactstrap";
import LocationService from "../../services/LocationService";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import Pagination from "../shared/Pagination";

class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationslist: null,
      location: null,
      image: null,
      selectLocation: false,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);

    await this.props.getAllNavBarNotifications(loc.loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  onPageChange(page) {
    this.setState({ page: page.selected }, () => {
      this.props.getAllNavBarNotifications(
        this.state.location.loc_id,
        page.selected
      );
    });
  }

  render() {
    const {
      props: { notifications },
    } = this;
    const {
      state: { selectLocation, location, loading },
    } = this;

    const locationName = location ? location.name : "";

    // TODO: Implement proper error handling
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="display-block mb-1">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.changeLocation.bind(this)}
          />
        </div>
        <div id="pricing-table">
          {!loading && notifications.length !== 0 ? (
            <>
              <ListAllNotification notificationData={notifications} />
              {notifications?.notificationList.length > 0 && (
                <Pagination
                  onPageChange={this.onPageChange.bind(this)}
                  page={notifications}
                  count={notifications.countAllNotification}
                />
              )}
            </>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    notifications: {
      allnotifications: {
        data: notificationData,
        loading: notificationLoading,
      },
    },
  } = state;

  return {
    notifications: notificationData,
    loading: notificationLoading,
  };
};

export default connect(mapStateToProps, { getAllNavBarNotifications })(
  NotificationList
);
