import React from 'react';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Col } from 'reactstrap';
import CONSTANTS from '../../constants';

import '../../assets/scss/plugins/extensions/react-paginate.scss';

const Pagination = (props) => {
  return (
    <Col xl='12' md='12'>
      <ReactPaginate
        previousLabel={<ChevronLeft size={15} />}
        nextLabel={<ChevronRight size={15} />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        containerClassName={
          'vx-pagination icon-pagination pagination-end mt-2'
        }
        activeClassName={'active'}
        disableInitialCallback={true}
        initialPage={props.page}
        forcePage={props.page}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        pageCount={Math.ceil(props.count / CONSTANTS.VARIABLES.ORDERS.DEFAULT_PAGE_LENGTH)}
        onPageChange={props.onPageChange}
      />
    </Col>
  );
};

export default Pagination;
