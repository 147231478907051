import CONSTANTS from '../../../constants';

export function allProductPackageCategoryItems(
  state = { data: null, loading: true },
  action
) {
  if (action.type === CONSTANTS.ACTIONS.STAFF.ALL_PACKAGE_PRODUCT_CATEGORY) {
    return { data: action.packageProductCategoryList, loading: action.loading };
  }
  return state;
}
