import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getPackageProductCategory,
  createPackageProductCategory,
  updatePackageProductCategory,
};

async function getPackageProductCategory() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.GETPACKAGEPRODUCTCATEGORY}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );

  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch Product Pricing List');
}
async function createPackageProductCategory(formData) {
  let { token } = LoginService.getToken();

  const requestBody = {
    title: formData.title,
    price: formData.price,

    description: formData.description,
    multiple_quantity: formData.multipleQuantity,
    physical_support: formData.physicalSupport,
    price_per_type: formData.pricePerType, // Add the new field price_per_type
    package_products_id: formData.selectedPackageProductId, // Add the new field package_products_id
  };

  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.CREATEPACKAGEPRODUCTCATEGORY}`,
    {
      method: 'POST',
      headers: {
        ...CONSTANTS.REQUEST_HEADERS,
        'X-ACCESS-TOKEN': token,
        'Content-Type': 'application/json', // Add the appropriate content type
      },
      body: JSON.stringify(requestBody),
    }
  );

  response = await response.json();
  if (response) return response;
  throw new Error('Could not create package product category');
}
async function updatePackageProductCategory(formData, packageId) {
  let { token } = LoginService.getToken();

  const requestBody = {
    title: formData.title,
    price: formData.price,

    description: formData.description,
    multiple_quantity: formData.multipleQuantity,
    physical_support: formData.physicalSupport,
    price_per_type: formData.pricePerType,
    package_products_id: formData.selectedPackageProductId,
  };

  let response = await fetch(
    `${
      CONSTANTS.API_URL
    }${CONSTANTS.URLS.ADMIN.STAFF.UPDATEPACKAGEPRODUCTCATEGORY(packageId)}`,
    {
      method: 'POST',
      headers: {
        ...CONSTANTS.REQUEST_HEADERS,
        'X-ACCESS-TOKEN': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    }
  );

  response = await response.json();
  if (response) return response;
  throw new Error('Could not create package product category');
}
