import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import LocationService from "../../services/LocationService";
import {useDispatch, useSelector} from "react-redux";
import {getPremiumServicesAction} from "../../redux/actions/premiumservices";
import SelectHotel from "../shared/SelectHotel";
import {Stack, Button, Typography, CircularProgress, Box} from "@mui/material";
import {useHistory} from "react-router-dom";
import {green} from "@mui/material/colors";
import CONSTANTS from "../../constants";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {Edit2} from "react-feather";

const Premium_Services = () => {
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global");
    const [selectLocation, setSelectLocation] = useState(true);
    const [loading, setLoading] = useState(false)
    const [location, setLocation] = useState(null);
    const history = useHistory();

    const locationRedux = useSelector(state => state.locations.location.data );
    const premiumServices = useSelector(state => state.premium_services.premiumservices);

    // console.log(premiumServices);

    const columns = [
        {
            name: t("name"),

            selector: (row) => row.service_title,
            format: (val) => {
                return val.service_title ? val.service_title : t("not-available");
            },
            sortable: true,
        },
        {
            name: t("description"),

            selector: (row) => row.service_desc,
            width: "50%",
            format: (val) => {
                return val.service_desc ? val.service_desc : t("N/A");
            },
            sortable: true,
        },
        {
            name: t("link"),

            selector: (row) => row.service_link,

            format: (val) => {
                return val.service_link ? val.service_link : t("N/A");
            },
        },
        {
            name: t("image"),
            selector: (row) => row.service_image,
            format: (val) => {
                return <Box component='img' src={`${CONSTANTS.RESTAURANT_URL}${val.service_image}`} alt={val.service_title} style={{height: '50px', width: '50px'}} />
            }
        },

        {
            name: t('edit'),
            selector: (row) => row,
            format: (val) => {
                return (
                    <Button
                        outline
                        className="btn-icon"
                        style={{backgroundColor: 'var(--primary)', color: 'white', padding: '0.5rem 1rem'}}
                        color="primary"
                        onClick={() => editPremiumService(val)}
                    >
                        <Edit2 color="white" className="ml-0 p-0" size={10} />
                    </Button>
                );
            },
        },
    ];

    const serviceData = {
        columns: columns,
        data: (premiumServices && premiumServices.data && premiumServices.data.data) ? premiumServices.data.data : [],
        print: false,
        export: false
    }

    const refreshData = async (loc_id, updateLocation = true) => {
        let loc = location;
        if (updateLocation) {
            loc = await LocationService.getLocationFromServer(loc_id);
        }
        await dispatch(await getPremiumServicesAction(loc.loc_id));
        const locationData = {
            loc_id: loc.loc_id,
            location_status: loc.location_status,
            name: loc.name,
        };
        setLocation(locationData);
    }


    useEffect (() => {
        const lang = localStorage.getItem("lang");
        i18n.changeLanguage(lang);

        setLoading(true);
        try {
            const location = LocationService.getLocation();
            if (location) {
                refreshData(location.loc_id);
                setLoading(false)
            } else {
                setLoading(false);
                setSelectLocation(true);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }, []);


    const toggleLocation = () => {
        setSelectLocation(!selectLocation);
    };

    const onSelect = async (location) => {
        await setLoading(true)
        LocationService.setLocation(location);
        await refreshData(location.loc_id);
        await setSelectLocation(false);
        await setLoading(false);
    }

    const editPremiumService = (premiumService) => {
        history.push({
            pathname: `${CONSTANTS.BASE_URL}premium_services/edit`,
            state: {premiumService: premiumService}
        })
    }

    return(
        <>
            {selectLocation ?
                <SelectHotel
                    onBack={toggleLocation}
                    onSelect={onSelect}
                />
                :
                (premiumServices.loading ?
                        <CircularProgress/> :
                        <>
                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                <Button variant={'outlined'} sx={{
                                    borderColor: green[400],
                                    color: green[400],
                                    '&:hover': {borderColor: green[500], color: green[500]}
                                }} onClick={() => setSelectLocation(true)}>{location.name}</Button>
                                <Button variant={'outlined'} sx={{
                                    borderColor: green[400],
                                    color: green[400],
                                    '&:hover': {borderColor: green[500], color: green[500]}
                                }} onClick={() =>
                                    history.push({
                                        pathname: `${CONSTANTS.BASE_URL}premium_services/new`,
                                        state: {location: location}
                                    }
                                )}>Add Premium Service</Button>
                            </Stack>
                            <Box sx={{width: '100%'}}>
                                {!premiumServices || !premiumServices.data || !premiumServices.data.data || premiumServices.data.length <= 0 ?
                                    <Typography variant='body2' sx={{fontSize: '0.9rem', fontWeight: '600'}} >No Premium Service Found</Typography> :
                                    (
                                        <DataTableExtensions {...serviceData}>
                                            <DataTable
                                                noHeader
                                                pagination
                                                paginationRowsPerPageOptions={[25, 50, 100]}
                                                paginationPerPage={50}
                                                columns={columns}
                                                data={premiumServices.data.data}
                                            />
                                        </DataTableExtensions>
                                    )}
                            </Box>
                        </>
                )}
        </>
    )
}

export default Premium_Services;