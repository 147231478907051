// import React, { useState, useEffect } from "react";
// import {
//   Typography,
//   Grid,
//   Card,
//   CardContent,
//   Switch,
//   styled,
//   CircularProgress,
//   Snackbar,
//   Alert,
// } from "@mui/material";
// import TimeSlotsService from "../../services/TimeSlotsService";
// import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
// import { Clock } from "react-feather";

// const TimeSlotCard = styled(Card)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   alignItems: "center",
//   height: "100%",
//   border: `1px solid ${theme.palette.divider}`,
//   boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
//   transition: "transform 0.3s ease-in-out",
//   "&:hover": {
//     transform: "scale(1.02)",
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
//   },
// }));

// const TimeSlotCardContent = styled(CardContent)(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
// }));

// const SelectedTimeSlotsPage = ({ location }) => {
//   const { dayName } = location?.state;
//   const [timeSlots, setTimeSlots] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");

//   useEffect(() => {
//     const fetchTimeSlots = async () => {
//       try {
//         const response = await TimeSlotsService.getEachDaySlot(dayName);
//         setTimeSlots(response.data);
//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching time slots:", error);
//         setIsLoading(false);
//         setSnackbarMessage("Error fetching time slots.");
//         setSnackbarSeverity("error");
//         setSnackbarOpen(true);
//       }
//     };

//     if (dayName) {
//       fetchTimeSlots();
//     }
//   }, [dayName]);

//   const handleSlotToggle = async (index) => {
//     const updatedSlots = [...timeSlots];
//     const slot = updatedSlots[index];
//     const newStatus = !slot.status;

//     setIsUpdating(true);
//     try {
//       await TimeSlotsService.updateSlotStatus(slot.location_slot_id, newStatus);
//       const response = await TimeSlotsService.getEachDaySlot(dayName);
//       setTimeSlots(response.data);
//       setSnackbarMessage("Slot status updated successfully.");
//       setSnackbarSeverity("success");
//     } catch (error) {
//       console.error("Error updating slot status:", error);
//       setSnackbarMessage("Error updating slot status.");
//       setSnackbarSeverity("error");
//     } finally {
//       setIsUpdating(false);
//       setSnackbarOpen(true);
//     }
//   };

//   const handleSnackbarClose = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setSnackbarOpen(false);
//   };

//   if (isLoading) {
//     return (
//       <div>
//         <SpinnerComponent />
//       </div>
//     );
//   }

//   if (isUpdating) {
//     return (
//       <>
//         <SpinnerComponent />
//       </>
//     );
//   }

//   return (
//     <div>
//       <Typography
//         className="text-center"
//         variant="h5"
//         fontWeight={700}
//         gutterBottom
//       >
//         {dayName} Time Slots
//       </Typography>

//       <Grid container spacing={2}>
//         {timeSlots.map((slot, index) => (
//           <Grid item xs={12} sm={6} md={3} key={slot.location_slot_id}>
//             <TimeSlotCard variant="outlined">
//               <TimeSlotCardContent>
//                 <Clock className="mx-1" />
//                 <Typography variant="h6">{`${slot.start_time} - ${slot.end_time}`}</Typography>
//               </TimeSlotCardContent>
//               <Switch
//                 checked={slot.status}
//                 onChange={() => handleSlotToggle(index)}
//                 color="success"
//                 inputProps={{ "aria-label": "toggle time slot" }}
//                 style={{ marginRight: "10px" }}
//               />
//             </TimeSlotCard>
//           </Grid>
//         ))}
//       </Grid>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity}
//           sx={{ width: "100%" }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// };

// export default SelectedTimeSlotsPage;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Switch,
  Snackbar,
  Alert,
  CardContent,
  Box,
} from "@mui/material";
import { Card, CardBody, CardTitle } from "reactstrap";
import TimeSlotsService from "../../services/TimeSlotsService";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { Clock } from "react-feather";

const SelectedTimeSlotsPage = ({ location }) => {
  const { dayName } = location?.state;
  const [timeSlots, setTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchTimeSlots = async () => {
      try {
        const response = await TimeSlotsService.getEachDaySlot(dayName);
        setTimeSlots(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching time slots:", error);
        setIsLoading(false);
        setSnackbarMessage("Error fetching time slots.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    };

    if (dayName) {
      fetchTimeSlots();
    }
  }, [dayName]);

  const handleSlotToggle = async (index) => {
    const updatedSlots = [...timeSlots];
    const slot = updatedSlots[index];
    const newStatus = !slot.status;

    setIsUpdating(true);
    try {
      await TimeSlotsService.updateSlotStatus(slot.location_slot_id, newStatus);
      const response = await TimeSlotsService.getEachDaySlot(dayName);
      setTimeSlots(response.data);
      setSnackbarMessage("Slot status updated successfully.");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Error updating slot status:", error);
      setSnackbarMessage("Error updating slot status.");
      setSnackbarSeverity("error");
    } finally {
      setIsUpdating(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return (
      <div>
        <SpinnerComponent />
      </div>
    );
  }

  if (isUpdating) {
    return (
      <>
        <SpinnerComponent />
      </>
    );
  }

  return (
    <div>
      <Typography
        className="text-center"
        variant="h5"
        fontWeight={700}
        gutterBottom
      >
        {dayName} Time Slots
      </Typography>
      <Grid container spacing={2}>
        {timeSlots.map((slot, index) => (
          <Grid item xs={12} sm={6} md={3} key={slot.location_slot_id}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Clock className="mx-1" />
                    <Typography variant="h5">{`${slot.start_time} - ${slot.end_time}`}</Typography>
                  </Box>
                  <Switch
                    checked={slot.status}
                    onChange={() => handleSlotToggle(index)}
                    color="success"
                    inputProps={{ "aria-label": "toggle time slot" }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      ;
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SelectedTimeSlotsPage;
