import {useHistory, useLocation} from "react-router-dom";
import React, {useState} from "react";
import {Button, Grid, IconButton, OutlinedInput, Stack, Typography} from "@mui/material";
import {deleteReservation, updateReservation} from "../../services/ReservationServices";
import toast from "react-hot-toast";
import {DeleteForever} from "@mui/icons-material";
import CONSTANTS from "../../constants";

const fields = [
    {
        label: 'Name',
        name: 'name',
        db_label: 'name',
        type: 'text',
        placeholder: 'Please, Enter your name',
        required: true,
        multiline: false,
        fullWidth: false,
    },

    {
        label: 'Email',
        name: 'email',
        type: 'text',
        db_label: 'email',
        placeholder: 'Please, Enter your email',
        required: true,
        multiline: false,
        fullWidth: false,
    },

    {
        label: 'Phone',
        name: 'phone',
        type: 'text',
        db_label: 'number',
        placeholder: 'Please, Enter your phone number',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Number of Guests',
        name: 'numberOfGuests',
        type: 'text',
        db_label: 'user_count',
        placeholder: 'Please, Enter number of guests',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Date',
        name: 'date',
        type: 'select',
        db_label: 'date',
        placeholder: 'Select, a Date',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Time',
        name: 'time',
        type: 'select',
        db_label: 'time',
        placeholder: 'Select, a Time',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Message',
        name: 'message',
        type: 'text',
        db_label: 'description',
        placeholder: 'Please, Enter your message',
        required: false,
        multiline: true,
        fullWidth: true
    }
]

const UpdateNDeleteReservation = () => {
    const location = useLocation();
    const history = useHistory();
    const data = location.state?.reservation

    console.log(data)

    const [reservationData, setReservationData] = useState({
        name: data.name,
        email: data.email,
        number: data.number,
        date: data.date,
        time: data.time,
        user_count: data.user_count,
        description: data.description,
        location_id: data.location_id
    });

    const handleValueChange = (e, field, index) => {
        setReservationData({...reservationData, [field.db_label]: e.target.value})
    }

    const handleSaveReservation = async () => {
        const response = await updateReservation(data.location_id, data.reservation_id, reservationData);
        if (response.error) {
            toast.error(response.message);
        } else {
            toast.success('Reservation Updated Successfully');
        }
    }

    const handleDeleteReservation = async () => {
        const response = await deleteReservation(data.reservation_id);
        if (response.error) {
            toast.error(response.message);
        } else {
            toast.success('Reservation Deleted Successfully');
            history.push(`${CONSTANTS.BASE_URL}reservations`)
        }
    }

    return(
        <>
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                <Typography variant='h5' sx={{fontWeight: '600', marginBottom: '1rem'}}>Update Reservation</Typography>
                <IconButton onClick={()=> handleDeleteReservation()}>
                    <DeleteForever color={'error'} />
                </IconButton>
            </Stack>
            <Stack sx={{alignItems: 'start', justifyContent: 'start', rowGap: '1rem'}}>
                <Grid container spacing={2}>
                    {fields.map((field, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <Typography variant='h6' sx={{fontSize: '0.9rem'}}>{field.label}</Typography>
                            <OutlinedInput
                                value={reservationData[field.db_label]}
                                onChange={(e)=> handleValueChange(e, field, index)}
                                size="small"
                                fullWidth
                                placeholder={field.placeholder}
                                multiline={field.multiline}
                                rows={4}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Button sx={{ textTransform: 'capitalize', fontSize: '0.9rem', backgroundColor: '#28c76f', '&:hover': { backgroundColor: '#28c76f' } }} variant='contained' onClick={handleSaveReservation}>Update Reservation</Button>
            </Stack>
        </>
    )
}

export default UpdateNDeleteReservation