import CONSTANTS from '../../../constants';
import PricingService from '../../../services/PricingService';

export function getAllPricing() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.STAFF.All_PRICING,
        PricingList: [],
        loading: true,
      });
      let pricingItems = await PricingService.getAllPricingList();
      dispatch({
        type: CONSTANTS.ACTIONS.STAFF.All_PRICING,
        PricingList: pricingItems,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.STAFF.All_PRICING,
        PricingList: [],
        loading: false,
      });
    }
  };
}
