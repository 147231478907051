import React, {useEffect, useState} from "react";
import {Button, Divider, IconButton, Modal, Stack, Typography} from "@mui/material";
import {finalOrderModalStyles, itemModal} from "../../../../../assets/posStyles";
import CloseIcon from "@mui/icons-material/Close";
import LoginService from "../../../../../services/LoginService";
import {useSelector} from "react-redux";
import {closeRegister} from "../../../../../services/RegisterService";
import {useHistory} from "react-router-dom";
import moment from "moment";

const stackStyle = {
    height: 'fit-content',
    maxHeight: {xs: '95vw', md: '90vh'},
    width: {xs: '95vw', md: '60vw', lg: '55vw'},
    maxWidth: {xs: '95vw', sm: '600px', md: '850px'},
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '0.5rem'
}

const RegisterDetailsModal = ({openModal, setOpenModal}) => {
    const navigate = useHistory();
    const [user, setUser] = useState(null);
    const registerDetails = useSelector(state => state.pos.registerDetails);

    const getUserData = () => {
        const fetchedUser = LoginService.getLoggedInAuthData();
        setUser(fetchedUser);
    }

    useEffect(() => {
        getUserData();
    }, []);

    const handleCloseRegister = async () => {
        const data = {
            date_closed: new Date(),
            closing_balance: 2000,
        }

        const response = await closeRegister(registerDetails.location_register_id, data);
        if (response.error) {

        } else {
            navigate.push("/")
        }
    }

    return(
        <>
            <Modal sx={itemModal.modalStyle} open={openModal.openRegisterDetailModal} onClose={()=> setOpenModal({...openModal, openRegisterDetailModal: false})}>
                <Stack sx={stackStyle}>
                    <Stack direction='row' sx={{padding: '0.5rem 1rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>Register Details</Typography>
                        <IconButton size='small' onClick={()=> setOpenModal({...openModal, openRegisterDetailModal: false})}>
                            <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}}/>
                        </IconButton>
                    </Stack>
                    <Divider />
                    <Stack sx={{padding: '1rem', rowGap: '0.5rem'}}>
                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', columnGap: '0.5rem'}}>
                            <Typography variant='h6' sx={{fontWeight: '600', fontSize: '1.25rem'}}>User</Typography>
                            <Typography variant='h6' sx={{fontWeight: '600', fontSize: '1.25rem'}}>{user?.staff_name ? user?.staff_name : ""}</Typography>
                        </Stack>
                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', columnGap: '0.5rem'}}>
                            <Typography variant='h6'>Date Range</Typography>
                            <Typography variant='h6' sx={{fontWeight: '600', fontSize: '1.25rem'}}>{moment(registerDetails.date_opened).format("YYYY-MM-DD hh:mm A")} to {moment().format("YYYY-MM-DD hh:mm A")}</Typography>
                        </Stack>
                        <Stack direction='row' sx={{alignItems: 'start', justifyContent: 'space-between'}}>
                            <Stack>
                                <Typography variant='h6' sx={{fontWeight: '600'}}>Payment Method</Typography>
                                <Typography variant='h6'>Cash</Typography>
                            </Stack>
                            <Stack>
                                <Typography variant='h6' sx={{fontWeight: '600'}}>Transactions</Typography>
                                <Typography variant='h6'>Opening Balance</Typography>
                                <Typography variant='h6'>Sales</Typography>
                                <Typography variant='h6'>Closing Balance</Typography>
                            </Stack>
                            <Stack>
                                <Typography variant='h6' sx={{fontWeight: '600'}}>Amounts</Typography>
                                <Typography variant='h6'>{registerDetails?.opening_balance}</Typography>
                                <Typography variant='h6'>{registerDetails?.total_sales ? registerDetails.total_sales.toFixed(2) : 0}</Typography>
                                <Typography variant='h6'>{((registerDetails.opening_balance ? registerDetails.opening_balance : 0) + (registerDetails.total_sales ? registerDetails.total_sales : 0)).toFixed(2)}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider sx={{marginBottom: '1rem'}}/>
                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                        <Button sx={finalOrderModalStyles.saveButton} onClick={()=> handleCloseRegister()}>Close Register</Button>
                        <Button sx={finalOrderModalStyles.closeButton} onClick={()=> setOpenModal(false)}>Cancel</Button>
                    </Stack>
                </Stack>
            </Modal>
        </>
    )
}

export default RegisterDetailsModal;