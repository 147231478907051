import React, {useState} from 'react';
import {Stack, Button, Grid} from "@mui/material";
import {green, orange, purple, red} from "@mui/material/colors";
import {Close, Drafts, LocalCafe, Receipt} from "@mui/icons-material";
import {createDirectOrder} from "../../../../../../services/DirectOrderService";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import SnackBar from "../../../mainComponents/snackBar";
import {clearCart} from "../../../../../../redux/actions/cart";
import {
    getPosOrderHistory,
    setIsModifyOrder,
    setOrderType,
    setSelectedOrder
} from "../../../../../../redux/actions/pos/order";
import {setPOSUserLocationData} from "../../../../../../redux/actions/pos/location";
import {clearUserData, setCustomerDetails} from "../../../../../../redux/actions/pos/customer";
import {createSession, getSessionStatus} from "../../../../../../services/SessionService";
import OrderService, {createOrder} from "../../../../../../services/OrderService";
import {setSelectedTableRedux} from "../../../../../../redux/actions/pos/tables";
import socketService from "../../../../../../helpers/socket";
import FinalPaymentModal from "../../../mainComponents/finalPaymentModal/finalPaymentModal";

const buttons = [
    {
        label: 'Cancel',
        icon: <Close />,
        backgroundColor: red[400],
        hoverBackgroundColor: red[600],
        color: 'white',
    },
    {
        label: 'Draft',
        icon: <Drafts />,
        backgroundColor: purple[400],
        hoverBackgroundColor: purple[600],
        color: 'white'
    },
    {
        label: 'Quick Invoice',
        icon: <Receipt />,
        backgroundColor: orange[400],
        hoverBackgroundColor: orange[600],
        color: 'white'
    },
    {
        label: 'Place Order',
        icon: <LocalCafe />,
        backgroundColor: green[400],
        hoverBackgroundColor: green[600],
        color: 'white'
    },

    {
        label: 'Modify Order',
        icon: <LocalCafe />,
        backgroundColor: green[400],
        hoverBackgroundColor: green[600],
        color: 'white'
    }
]

const buttonStyle = {
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.5rem 0.5rem',
    lineHeight: '1.5rem',
    fontSize: '0.85rem',
    color: 'white',
    boxShadow: 'none',
}

const ButtonsComponent = () => {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart.cartMenu);
    const orderType = useSelector((state) => state.pos.orderType.order_type);
    const locationData = useSelector((state) => state.pos.locationData);
    const customerData = useSelector((state) => state.pos.customerData);
    const userLocationData = useSelector((state) => state.pos.userLocationData);
    const selectedTable = useSelector((state) => state.pos.selectedTable.table);
    const customerType = useSelector(state => state.pos.customerType);
    const isModifyOrder = useSelector(state => state.pos.isModifyOrder);
    const [placedOrder, setPlacedOrder] = useState(null);
    const [openFinalizeModal, setOpenFinalizeModal] = useState(false);

    const removeItems = useSelector(state => state.pos.removeItemsList);
    const selectedOrder = useSelector(state => state.pos.selectedOrder);

    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        severity: '',
        message: ''
    })


    const handlePlaceOrder = async (index) => {
        const currentTime = moment();
        const formattedCurrentTime = currentTime.format('HH:mm:ss');
        const formattedDate = currentTime.format('YYYY-MM-DD');

        const customerTypeObject = { [`customerType`]: true };

        let orderOwner = {};

        if (customerType === 'regular') {
            if (!customerData || !customerData.id || customerData.id === '') {
                setSnackbarParams({
                    open: true,
                    severity: 'error',
                    message: "Please, select a customer"
                })
                return;
            }

            if (orderType === 'Delivery') {
                if (!userLocationData) {
                    setSnackbarParams({
                        open: true,
                        severity: 'error',
                        message: 'No Address Found for Customer'
                    })
                    return;
                } else if (!userLocationData.address || userLocationData.address === '') {
                    setSnackbarParams({
                        open: true,
                        severity: 'error',
                        message: 'No Address Found for Customer'
                    })
                    return;
                } else if (!userLocationData.long || userLocationData.long === '') {
                    setSnackbarParams({
                        open: true,
                        severity: 'error',
                        message: 'No Address Found for Customer'
                    })
                    return;
                } else if (!userLocationData.lat || userLocationData.lat === '') {
                    setSnackbarParams({
                        open: true,
                        severity: 'error',
                        message: 'No Address Found for Customer'
                    })
                    return;
                }
            }

            if (orderType === 'Delivery') {
                orderOwner = {
                    name: customerData.name,
                    email: customerData.email,
                    number: customerData.number,
                    customer_id: customerData.id,
                    address: userLocationData.address,
                    latitude: userLocationData.lat,
                    longitude: userLocationData.long,
                    deliverData: moment().format("YYYY-MM-DD hh:mm a")
                }
            } else {
                orderOwner = {
                    name: customerData.name,
                    email: customerData.email,
                    number: customerData.number,
                    customer_id: customerData.id,
                    deliverData: moment().format("YYYY-MM-DD hh:mm a")
                }
            }
        }

        const datatoSend = {
            customerType: { ...customerTypeObject },
            totalQuantity: 0,
            totalamount: 0,
            subtotal: 0,
            totaltax: 0,
            loc_id: null,
            user_id: null,
            table_no: null,
            table_name: null,
            order_type: orderType,
            order_owner: [
                {
                    ...orderOwner,
                },
            ],
            total_items: cart.cart.length,
            order_time: formattedCurrentTime,
            order_date: formattedDate,
            order_type_id: null,
            order_variant: "small",
            discount_id: 0,
            total_discount: 0,
            totalPrice: cart.totalamount,
            start_time: formattedCurrentTime,
            location_id: locationData.loc_id,
            comment: "",
            end_time: null,
            qrcode: "",
            qrcodedata: "",
            pos_order: true,
            promocode_id: null,
            payment_status_id: null,
            order_tax: cart.totaltax,
            menu_items: cart.cart,
            walk_in: customerType === 'walk-in' ? true : false
        };
        try {
            const response = await createDirectOrder(datatoSend);
            if (response) {
                setSnackbarParams({
                    open: true,
                    message: 'Order Placed Successfully',
                    severity: 'success'
                })

                handleClearData();
                await dispatch(getPosOrderHistory())
                socketService.emit("order_placed", {
                    order_id: response?.order?.order_id,
                    location_id: locationData.loc_id,
                });

                if (index === 2) {
                    const order = {
                        ...response.order,
                        OrderTotals: [...response.orderTotals]
                    }
                    setPlacedOrder(order);
                    setOpenFinalizeModal(true);
                }

            } else {
                setSnackbarParams({
                    open: true,
                    message: 'Failed to Place Order',
                    severity: 'error'
                })
            }
        } catch (error) {
            console.log(error);
            setSnackbarParams({
                open: true,
                message: 'Failed to Place Order',
                severity: 'error'
            })
        }
    };

    const handleVerifyOtp = async (index) => {

        if (!selectedTable || !selectedTable.table_id || selectedTable.table_id === "") {
            setSnackbarParams({
                message: 'Please, Select a Table',
                severity: 'error',
                open: true
            })
            return
        }

        let session_id;
        if (selectedTable.LocationSessions && selectedTable.LocationSessions.length > 0 && selectedTable.LocationSessions[0].session_id) {
            session_id = selectedTable.LocationSessions[0].session_id;
        } else {
            session_id = null;
        }

        try {
            const currentTime = new Date();
            const formattedCurrentTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`;
            const formattedDate = `${currentTime.getUTCFullYear()}-${("0" + (currentTime.getUTCMonth() + 1)).slice(-2)}-${("0" + currentTime.getUTCDate()).slice(-2)}`;

            const dataToSend = {
                table_id: selectedTable.table_id,
                location_id: locationData.loc_id,
                // session_date,
                start_time: formattedCurrentTime,
                // end_time,
                // session_owner,
                hotel_id: null,
                order_from: "POS"
            };
            if (selectedTable.table_name) {
                dataToSend.table_name = selectedTable.table_name;
            }

            let sessionStatusResponse = "undefined"
            if (!session_id || session_id === undefined || session_id === null) {
                sessionStatusResponse = "no_session_id"
            } else {
                sessionStatusResponse = await getSessionStatus(session_id);
            }

            if (sessionStatusResponse.sessionStatus === "completed" || sessionStatusResponse === "no_session_id" || sessionStatusResponse === "undefined") {

                const sessionId = await createSessionFunction(dataToSend);
                await createAndHandleOrder(sessionId, formattedCurrentTime, formattedDate, dataToSend, cart, cart.totalamount, cart.totaltax, locationData.loc_id, index);
            } else {
                await createAndHandleOrder(session_id, formattedCurrentTime, formattedDate, dataToSend, cart, cart.totalamount, cart.totaltax, locationData.loc_id, index);
            }
        } catch (error) {
            console.log("Error", error);
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: error.message,
            })
        }
    }

    const createSessionFunction = async (dataToSend) => {
        const response = await createSession(dataToSend);

        if (response.status === 500) {
            console.warn("Session API Error", response.message, response.status);
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: response.message,
            })
            return;
        }

        const sessionId = response.SessionDetail.session_id;

        return sessionId
    };

    const createAndHandleOrder = async (sessionId, formattedCurrentTime, formattedDate, dataToSend, cart, totalamount, totaltax, loc_id, index) => {
        const orderData = {
            user_id: null,
            table_no: selectedTable.table_id,
            session_id: sessionId,
            total_items: cart.cart.length,
            hotel_id: null,
            room_id: null,
            order_time: formattedCurrentTime,
            order_date: formattedDate,
            start_time: formattedCurrentTime,
            order_type_id: 2,
            order_variant: "small",
            discount_id: 1,
            total_discount: 0,
            totalPrice: totalamount,
            comment: "",
            qrcode: "",
            qrcodedata: "",
            promocode_id: null,
            payment_status_id: 2,
            order_tax: totaltax,
            location_id: loc_id,
            session_owner: null,
            menu_items: cart.cart,
            order_from: "POS",
            pos_order: true,
        };

        const response = await createOrder(orderData);

        await dispatch(getPosOrderHistory())
        socketService.emit("order_placed", {
            order_id: response?.order?.order_id,
            location_id: locationData.loc_id,
        });

        setSnackbarParams({
            open: true,
            message: "Order Placed Successfully",
            severity: "success",
        })

        handleClearData();

        if (index === 2) {
            const order = {
                ...response.order,
                OrderTotals: [...response.orderTotals]
            }
            setPlacedOrder(order);
            setOpenFinalizeModal(true);
        }
    };

    const handleSessionOrder = async (index) => {
        await handleVerifyOtp(index);
    }

    const handleClearData = () => {
        dispatch(clearCart());
        dispatch(setOrderType({
            order_type: 'Dine In'
        }));
        dispatch(setCustomerDetails(null));
        dispatch(setPOSUserLocationData(null));
        dispatch(setSelectedTableRedux(null));
        dispatch(setIsModifyOrder(false));
        dispatch(setSelectedOrder(null));
        dispatch(clearUserData(true));
    }

    const handleButtonClick = async (index) => {
        if (index === 3 || index === 2) {
            if (cart.cart.length <= 0) {
                setSnackbarParams({
                    open: true,
                    message: "Cart is empty",
                    severity: "error",
                })
                return
            }
            if (orderType === 'Delivery' || orderType === 'Takeaway') {
                await handlePlaceOrder(index)
            } else {
                await handleSessionOrder(index)
            }
        } else if (index === 0) {
            handleClearData();
        }
    }

    const handleModifyOrder = async () => {
        if (cart.cart.length <= 0) {
            setSnackbarParams({
                open: true,
                message: "Cart is empty",
                severity: "error",
            })
            return
        }
        if (selectedOrder && selectedOrder.order_id) {
            const filteredCart = [];
            for (let i = 0; i < cart.cart.length; i++) {
                if (!cart.cart[i].order_menu_id || cart.cart[i].isModified) {
                    filteredCart.push(cart.cart[i]);
                }
            }

            const data = {
                order_menu_ids: removeItems,
                order_total: cart.totalamount,
                order_tax: cart.totaltax,
                menu_items: filteredCart
            }
            const response = await OrderService.modifyOrder(selectedOrder.order_id, data)
            if (response.error) {
                setSnackbarParams({
                    open: true,
                    message: "Error while modifying order",
                    severity: "error",
                })
            } else {
                setSnackbarParams({
                    open: true,
                    message: "Order modified successfully",
                    severity: "success",
                })
                dispatch(getPosOrderHistory())
                handleClearData();
            }
        } else {
            setSnackbarParams({
                open: true,
                message: "Please select an order to modify",
                severity: 'error'
            })
        }
    }

    return(
        <>
            <Grid container spacing={1}>
                {
                    buttons.map((item, index) => (
                        ((index === 3 && !isModifyOrder || index === 4 && isModifyOrder) ||
                            (index === 0 || index === 1 || index === 2)) &&
                        <Grid key={index} item xs={6} sm={3} md={6} xxl={3}>
                            <Button onClick={()=> index !== 4 ? handleButtonClick(index) : handleModifyOrder()} fullWidth startIcon={item.icon} variant='contained' sx={{...buttonStyle, backgroundColor: item.backgroundColor, '&:hover': {backgroundColor: item.hoverBackgroundColor}}}>
                                {item.label}
                            </Button>
                        </Grid>
                    ))
                }
            </Grid>
            {openFinalizeModal && <FinalPaymentModal openModal={openFinalizeModal} setOpenModal={setOpenFinalizeModal} order={placedOrder} setSnackbarParams={setSnackbarParams}/> }
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </>
    )
}

export default ButtonsComponent;