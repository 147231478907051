import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import TimeHelper from '../../helpers/TimeHelper';
import ViewHelper from '../../helpers/ViewHelper';
import Checkbox from '../@vuexy/checkbox/CheckboxesVuexy';
import { Check, Edit2 } from 'react-feather';
import { Button } from 'reactstrap';
import ImageHelper from '../../helpers/ImageHelper';

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ListAllergicItems = (props) => {
  const [t, i18n] = useTranslation('global');
  useEffect(() => {
    const lang2 = localStorage.getItem('lang');
    i18n.changeLanguage(lang2);
  }, []);

  const editDishTag = (item) => {
    props.editDishTag(item);
  };

  const columns = [
    // {
    //   name: 'ID',
    //   // selector: 'item_id ',
    //   selector: (row) => row.item_id,
    //   sortable: true,
    //   format: (val) => {
    //     return val.item_id ? val.item_id : '';
    //   },
    // },

    {
      name: t('name'),
      // selector: 'item_name',
      selector: (row) => row.item_name,
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.item_name ? val.item_name : t('not-available');
      },
    },
    {
      name: t('description'),
      // selector: 'description',
      selector: (row) => row.description,
      minWidth: '300px',
      format: (val) => {
        return val.description ? val.description : t('not-available');
      },
    },
    {
      name: t('edit'),
      // selector: 'item_id ',
      selector: (row) => row.item_id,
      format: (val) => {
        return (
          <Button
            outline
            className="btn-icon bg-white"
            color="primary"
            onClick={() => editDishTag(val)}
          >
            <Edit2 color="var(--primary)" className="ml-0 p-0" size={10} />
          </Button>
        );
      },
    },
  ];

  const renderDishTags = (dishtags, handleChange) => {
    if (!dishtags || dishtags.length === 0)
      return <h1>{t('no-allergic-item-found')}</h1>;
    const tableData = {
      columns: columns,
      data: dishtags,
      print: false,
      export: false,
    };
    return (
      <DataTableExtensions {...tableData}>
        <DataTable
          noHeader
          pagination
          paginationRowsPerPageOptions={[25, 50, 100]}
          paginationPerPage={50}
          selectableRows
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: 'primary',
            icon: <Check className="vx-icon" size={12} />,
            label: '',
            size: 'sm',
          }}
          onSelectedRowsChange={handleChange}
          columns={columns}
          data={dishtags}
        />
      </DataTableExtensions>
    );
  };
  return renderDishTags(props.allergicitems, props.handleChange);
};

export default ListAllergicItems;
