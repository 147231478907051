import React from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Check,
  ChevronDown,
  ChevronUp,
  Edit,
  Plus,
  Trash,
  MoreVertical,
} from "react-feather";

import "../../assets/css/collapsibeCardComponent.scss";
import TimeHelper from "../../helpers/TimeHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";

import "../../assets/scss/plugins/forms/switch/react-toggle.scss";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
// import { regular } from '@fortawesome/free-regular-svg-icons';

class CollapsibleCardComponent extends React.Component {
  componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }

  render() {
    // console.log("CollapsibleCardComponent props:", this)
    const { t } = this.props;
    const {
      props: {
        item: collapseItem,
        toggleCollapse,
        isOpen,
        toggleStatus,
        toggleCategoryModal,
        toggleMenuModal,
        bodyColor,
        badgeTitle,
        deleteCardItem,
        changeStatusItem,
        updateSelectedModifiers,
        selectedIds,
        isOutlet,
        deleteCategory,
        item,
        loading,
      },
    } = this;

    const logArray = this.props.item.Option?.OptionValues?.map((item) => {
      return item;
    });

    console.log(logArray, "option");
    // console.log(this.props.item.Option, "propsssssssssssssssssss");

    const isEnabled = collapseItem.status == 1;

    const isOffTime =
      collapseItem.available_time == "1" &&
      !TimeHelper.checkIfTimeIsActive(
        collapseItem.local_from_time,
        collapseItem.local_to_time
      );
    let buttonClasses = "font-weight-bold custom-padding mr-1";
    if (isEnabled) buttonClasses += " success-3-border";
    else buttonClasses += " disabled-danger-3-border";

    let rightSize =
      (toggleStatus ? 1 : 0) +
      (toggleCategoryModal ? 1 : 0) +
      (deleteCardItem ? 1 : 0) +
      (updateSelectedModifiers ? 1 : 0) +
      1;
    let leftSize = 10 - rightSize;
    // const imageURL = `https://api.tabletordercard.be/restaurant_data/${this.props.item.image}`;

    return (
      <>
        {loading ? (
          <SpinnerComponent />
        ) : (
          <div id="collapse" className="vx-collapse collapse-bordered mb-2 ">
            <Card key={collapseItem.id} className="border-radius-0-5">
              <CardHeader
                id="collapse"
                onClick={(e) => {
                  if (e.target.id !== "collapse") {
                    return;
                  }
                  toggleCollapse(collapseItem.id);
                }}
                className="border-radius-0-5  bg-white border-bottom border-primary"
                {...this.props.dragHandleProps}
              >
                {/* <div>
                <img
                  src={imageURL}
                  alt="Item "
                  style={{
                    width: '100px',
                    height: '50px',
                    objectFit: 'contain',
                    borderRadius: '50%',
                  }}
                />
              </div> */}
                <CardTitle
                  id="collapse"
                  className={`lead collapse-title collapsed flex-${leftSize} primary `}
                  // pl-2
                >
                  <b style={{ color: "gray" }}>{collapseItem.title}</b>
                  {badgeTitle && (
                    <Badge
                      id="collapse"
                      className="ml-1 font-small-2 align-top"
                      pill
                      color="primary"
                    >
                      {badgeTitle}
                    </Badge>
                  )}
                  {isOffTime && (
                    <Badge
                      id="collapse"
                      className="ml-1 font-small-2 align-top"
                      pill
                      color="primary"
                    >
                      {t("off-time")}{" "}
                    </Badge>
                  )}
                  {toggleStatus && (
                    <Badge
                      id="collapse"
                      className="ml-1 font-small-2 align-top"
                      pill
                      color={isEnabled ? "success" : "danger"}
                      style={{ padding: "10px" }}
                    >
                      {isEnabled ? t("enabled") : t("disabled")}
                    </Badge>
                  )}
                </CardTitle>

                <div
                  id="collapse"
                  className={`justify-content-end display-flex flex-${rightSize}`}
                >
                  {/* <Trash className="align-baseline" size={25} /> */}
                  {/* <Trash
                    size={25}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      deleteCategory(item);
                    }}
                    style={{ marginRight: '10px' }}
                    className="align-baseline "
                  ></Trash> */}

                  {/* <Button className="bg-transparent" ></Button> */}

                  {deleteCategory && (
                    <Trash
                      size={25}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        deleteCategory(item);
                      }}
                      style={{ marginRight: "10px" }}
                      className="align-baseline "
                    ></Trash>
                  )}
                  <UncontrolledDropdown className="chart-dropdown">
                    <DropdownToggle
                      color=""
                      className="bg-transparent btn-sm border-0 p-50"
                    >
                      <MoreVertical size={18} className="cursor-pointer" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      {!isOutlet && toggleMenuModal && (
                        <DropdownItem
                          className="w-100"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleMenuModal();
                          }}
                        >
                          <span
                            id="collapse"
                            tag="small"
                            className="text-bold-500 cursor-pointer mr-1"
                          >
                            <Plus className="mr-50" size={15} />{" "}
                            <span className="align-middle">
                              {t("add-item")}
                            </span>
                          </span>
                        </DropdownItem>
                      )}

                      {!isOutlet && toggleCategoryModal && (
                        <DropdownItem
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleCategoryModal(collapseItem);
                          }}
                        >
                          <span
                            id="collapse"
                            tag="small"
                            className="text-bold-500 cursor-pointer mr-1"
                          >
                            <Edit className="mr-50" size={15} />{" "}
                            <span className="align-middle">
                              {t("edit-category")}
                            </span>
                          </span>
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {updateSelectedModifiers && (
                    <Checkbox
                      color="primary"
                      icon={<Check className="vx-icon" size={25} />}
                      defaultChecked={selectedIds.indexOf(collapseItem.id) > -1}
                      value={collapseItem.id}
                      onChange={(e) => {
                        e.stopPropagation();
                        updateSelectedModifiers(e.target.value);
                      }}
                    />
                  )}
                  {/* <span
                    id="collapse"
                    tag="small"
                    className="text-bold-500 cursor-pointer "
                  >
                    {isOpen ? (
                      <ChevronUp id="collapse" size={30} />

                    ) : (
                      <ChevronDown id="collapse" size={30} />
                    )}
                  </span> */}
                  <span
                    id="collapse"
                    tag="small"
                    className="text-bold-500 cursor-pointer"
                    onClick={() => toggleCollapse(collapseItem.id)}
                  >
                    {isOpen ? (
                      <ChevronUp size={30} />
                    ) : (
                      <ChevronDown size={30} />
                    )}
                  </span>
                </div>
              </CardHeader>
              <Collapse isOpen={isOpen}>
                <CardBody className={bodyColor ? bodyColor : " p-0"}>
                  {this.props.children}
                </CardBody>
              </Collapse>
            </Card>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation("global")(CollapsibleCardComponent);
