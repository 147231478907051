import React from "react";
import { connect } from "react-redux";
import { Row, Col, Card, CardBody } from "reactstrap";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import "../../assets/scss/pages/dashboard-analytics.scss";
import "../../assets/scss/plugins/charts/apex-charts.scss";
import LocationService from "../../services/LocationService";
import SelectLocation from "../shared/SelectLocation";
import { getDashboardStats } from "../../redux/actions/analytics";
import { getLocation } from "../../redux/actions/locations";
import { getAllNavBarNotifications } from "../../redux/actions/notifications";
import LoginService from "../../services/LoginService";
import ManagerQuickLinks from "./ManagerQuickLinks";
import StatisticsCard from "../../components/@vuexy/statisticsCard/StatisticsCard";
import {
  Package,
  List,
  Archive,
  Activity,
  BarChart,
  BarChart2,
  TrendingUp,
} from "react-feather";
import LastestSessionListDashboard from "./LastestSessionListDashboard";
import TopProductsList from "./TopProductsList";
import SalesCard from "./SalesCard";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import RawSqlUploaderHeaders from "../shared/RawSqlUploaderHeaders";
import TakeDatabaseBackup from "../shared/TakeDatabaseBackup";
import AdministratorDashboard from "./AdministratorDashboard";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import OwnerQuickLinks from "./OwnerQuickLinks";
import OTPGenerator from "../shared/OtpTimer";
import SelectHotel from "../shared/SelectHotel";
import DirectOrderQr from "../directOrderQr";

class AnalyticsDashboard extends React.Component {
  state = {
    options: {
      legend: {
        itemMargin: {
          horizontal: 2,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 41, 17],
    loading: true,
    selectLocation: false,
    role: "",
    isHovered: false, // Add a new state to track hover
  };

  // fetchNotifications = () => {
  //   const location = LocationService.getLocation();
  //   this.props.getAllNavBarNotifications(location.loc_id);
  // };

  async componentDidMount() {
    try {
      let role = LoginService.getRole();

      this.setState({ role: role.role });
      if (role.role !== "Administrator") {
        const location = LocationService.getLocation();
        console.log("LOCATION COMING OR NOT", location);
        if (location) {
          await this.refreshData(location.loc_id);

          // await this.props.getAllNavBarNotifications(location.loc_id);
          // this.fetchNotifications();
          // const intervalId = setInterval(this.fetchNotifications, 7000);
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false, selectLocation: true });
        }
      } else {
        await this.props?.getDashboardStats(null, role, true);
        this.setState({ loading: false });
      }
      // this.saveToken();
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }
  handleCardHover = () => {
    this.setState({ isHovered: true }); // Set isHovered to true when card is hovered
  };

  handleCardLeave = () => {
    this.setState({ isHovered: false }); // Set isHovered to false when the mouse leaves the card
  };

  // async saveToken() {
  //   let token = NotificationService.getTokenFromCache();
  //   //TODO: Correct check would be if request is not sent by webview of native app.
  //   if (!token) token = await NotificationService.getToken();
  //   if (token) await NotificationService.saveToken(token);
  //   NotificationService.removeTokenFromCache();
  // }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    let role = LoginService.getRole();
    console.log("ROLE", role);
    this.setState({ role: role.role });
    if (updateLocation) await this.props.getDashboardStats(loc_id, role, true);
    if (loc_id !== 0) {
      await this.props.getLocation(loc_id);
      loc = this.props.location;
    }
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
      currency: loc.Currency?.currency_symbol,
    };
    this.setState({ location });
  }

  getChartOptions = (color) => {
    let ordersReceived = {
      chart: {
        id: "revenue",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      grid: {
        show: false,
      },
      colors: [color],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2.5,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.7,
          opacityTo: 0.5,
          stops: [0, 80, 100],
        },
      },

      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        x: { show: false },
      },
    };
    return ordersReceived;
  };

  async syncRawSqlData() {
    this.setState({ loading: true });
    try {
      let response = await LocationService.syncSqlData();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  async backupLocalDb() {
    this.setState({ loading: true });
    try {
      let response = await LocationService.backupSqlDatabase();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { t } = this.props;
    // const langChoosen = localStorage.getItem("lang");
    // this.props.i18n.changeLanguage(langChoosen);

    const {
      state: { selectLocation, loading },
    } = this;
    console.log("SELECTLOCATION", selectLocation);

    if (
        selectLocation &&
        this.state.role !== "Administrator" &&
        this.state.role !== "Hotel Owner"
    )
      return (
          <SelectLocation
              onBack={this.toggleLocation.bind(this)}
              onSelect={this.onSelect.bind(this)}
          />
      );
    if (selectLocation && this.state.role !== "Administrator")
      return (
          <SelectHotel
              onBack={this.toggleLocation.bind(this)}
              onSelect={this.onSelect.bind(this)}
          />
      );

    if (loading) return <SpinnerComponent />;
    const {
      props: { stats, location },
    } = this;
    console.log("STATS PROPS", stats);
    console.log("Location PROPS", location);
    console.log("ROLE  COMINGGGGGGGGGGGGG", this.state.role);
    const locationName = location ? location.name : "";

    return (
        <React.Fragment>
          {this.state.role === "Administrator" ? (
              <AdministratorDashboard
                  data={this.props.stats}
                  location={this.state.location}
                  t={t}
              />
          ) : this.state.role === "Restaurant Owner" ? (
              <>
                <div className="location">
                  <div className="display-flex flex-row justify-content-between mb-1">
                    <div className="d-flex">
                      <LocationSelectHeader
                          text={locationName}
                          onIconClick={this.toggleLocation.bind(this)}
                      />
                    </div>
                    <div className="d-flex">
                  <span className="mr-1">
                    <RawSqlUploaderHeaders
                        text={locationName}
                        onIconClick={this.syncRawSqlData.bind(this)}
                    />
                  </span>
                      <TakeDatabaseBackup
                          text={locationName}
                          onIconClick={this.backupLocalDb.bind(this)}
                      />
                    </div>
                  </div>
                </div>
                <OwnerQuickLinks />
                <Row className="match-height">
                  <Col sm="12" md="12" lg="4">
                    <SalesCard location={locationName} />
                  </Col>
                  <Col xl="2" lg="4" sm="4">
                    <a href="/locations/item_stats">
                      <StatisticsCard
                          hideChart
                          iconBg="primary"
                          icon={<Archive className="primary" size={32} />}
                          stat={stats.overAllItems}
                          statTitle={t("overall-items")}
                      />
                    </a>
                  </Col>

                  <Col xl="2" lg="4" sm="4">
                    <a href="/tables">
                      <StatisticsCard
                          hideChart
                          iconBg="success"
                          icon={<List className="success" size={32} />}
                          stat={stats.totalTables}
                          statTitle={t("overall-tables")}
                      />
                    </a>
                  </Col>
                  <Col xl="2" lg="4" sm="4">
                    <a href="/tables/session">
                      <StatisticsCard
                          hideChart
                          iconBg="primary"
                          icon={<Activity className="primary" size={32} />}
                          stat={stats.overAllSessions}
                          statTitle={t("overall-sessions")}
                      />
                    </a>
                  </Col>
                  <Col xl="2" lg="4" sm="4">
                    <Card className="text-center">
                      <CardBody className="d-flex flex-column align-items-center">
                        <div className="mb-2">
                          <Package className="primary" size={32} /> {/* Icon */}
                        </div>
                        <h5 className="card-title">{t("packages")}</h5>
                        <div className="mt-2">
                          <Link
                              to="/purchase-history"
                              className="btn btn-primary mt-auto"
                          >
                            {t("show-my-packages")}{" "}
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        iconRight
                        iconBg="success"
                        icon={<BarChart2 className="success" size={22} />}
                        stat={stats.lastSevenDaysSessions}
                        statTitle={t("last-7-days-sessions")}
                        series={stats.perDaySessionCountLastSevenDays}
                        options={this.getChartOptions("#28C76F")}
                        type="area"
                    />
                  </Col>

                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        iconRight
                        iconBg="primary"
                        icon={<BarChart className="primary" size={22} />}
                        stat={stats.last30DaysSessions}
                        statTitle={t("last-30-days-sessions")}
                        series={stats.perDaySessionCountLast30Days}
                        options={this.getChartOptions("#4a2884")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        iconRight
                        iconBg="warning"
                        icon={<TrendingUp className="warning" size={22} />}
                        stat={
                          stats.lastSevenDaysSessionsAmount
                        }
                        statTitle={t("last-7-days-sales")}
                        series={stats.perDaySessionAmountCountLastSevenDays}
                        options={this.getChartOptions("#ff9f43")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        iconRight
                        iconBg="danger"
                        icon={<TrendingUp className="danger" size={22} />}
                        stat={
                          stats.last30DaysSessionsAmount
                        }
                        statTitle={t("last-30-days-sales")}
                        series={stats.perDaySessionAmountCountLast30Days}
                        options={this.getChartOptions("#ea5455")}
                        type="area"
                    />
                  </Col>
                </Row>
                <Row className="match-height">
                  <Col md="6">
                    <TopProductsList
                        demandingItems={stats.famousItemsInLastSevenDays}
                        dataText={t("in-last-7-days")}
                    />
                  </Col>
                  <Col md="6">
                    <TopProductsList
                        demandingItems={stats.famousItemsInLast30Days}
                        dataText={t("in-last-30-days")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <LastestSessionListDashboard
                        data={stats.recent10Sessions}
                        location={this.state.location}
                    />
                  </Col>
                </Row>
              </>
          ) : this.state.role === "Hotel Owner" ? (
              <>
                <div className="location">
                  <div className="display-flex flex-row justify-content-between mb-1">
                    <div className="d-flex">
                      <LocationSelectHeader
                          text={locationName}
                          onIconClick={this.toggleLocation.bind(this)}
                      />
                    </div>
                    <div className="d-flex">
                  <span className="mr-1">
                    <RawSqlUploaderHeaders
                        text={locationName}
                        onIconClick={this.syncRawSqlData.bind(this)}
                    />
                  </span>
                      <TakeDatabaseBackup
                          text={locationName}
                          onIconClick={this.backupLocalDb.bind(this)}
                      />
                    </div>
                  </div>
                </div>
                {/* <OwnerQuickLinks /> */}
                <Row className="match-height">
                  <Col sm="12" md="12" lg="4">
                    <SalesCard location={locationName} />
                  </Col>
                  <Col xl="2" lg="4" sm="4">
                    <a href="/locations/item_stats">
                      <StatisticsCard
                          hideChart
                          iconBg="primary"
                          icon={<Archive className="primary" size={32} />}
                          stat={stats.overAllItems}
                          statTitle={t("overall-items")}
                      />
                    </a>
                  </Col>

                  <Col xl="2" lg="4" sm="4">
                    <a href="/tables">
                      <StatisticsCard
                          hideChart
                          iconBg="success"
                          icon={<List className="success" size={32} />}
                          stat={stats.totalTables}
                          statTitle={t("overall-tables")}
                      />
                    </a>
                  </Col>
                  <Col xl="2" lg="4" sm="4">
                    <a href="/tables/session">
                      <StatisticsCard
                          hideChart
                          iconBg="primary"
                          icon={<Activity className="primary" size={32} />}
                          stat={stats.overAllSessions}
                          statTitle={t("overall-sessions")}
                      />
                    </a>
                  </Col>
                  <Col xl="2" lg="4" sm="4">
                    <Card className="text-center">
                      <CardBody className="d-flex flex-column align-items-center">
                        <div className="mb-2">
                          <Package className="primary" size={32} /> {/* Icon */}
                        </div>
                        <h5 className="card-title">{t("packages")}</h5>
                        <div className="mt-2">
                          <Link
                              to="/purchase-history"
                              className="btn btn-primary mt-auto"
                          >
                            {t("show-my-packages")}{" "}
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        iconRight
                        iconBg="success"
                        icon={<BarChart2 className="success" size={22} />}
                        stat={stats.lastSevenDaysSessions}
                        statTitle={t("last-7-days-sessions")}
                        series={stats.perDaySessionCountLastSevenDays}
                        options={this.getChartOptions("#28C76F")}
                        type="area"
                    />
                  </Col>

                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        iconRight
                        iconBg="primary"
                        icon={<BarChart className="primary" size={22} />}
                        stat={stats.last30DaysSessions}
                        statTitle={t("last-30-days-sessions")}
                        series={stats.perDaySessionCountLast30Days}
                        options={this.getChartOptions("#4a2884")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}

                        iconRight
                        iconBg="warning"
                        icon={<TrendingUp className="warning" size={22} />}
                        stat={
                          stats.lastSevenDaysSessionsAmount
                        }
                        statTitle={t("last-7-days-sales")}
                        series={stats.perDaySessionAmountCountLastSevenDays}
                        options={this.getChartOptions("#ff9f43")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}
                        iconRight
                        iconBg="danger"
                        icon={<TrendingUp className="danger" size={22} />}
                        stat={
                          stats.last30DaysSessionsAmount
                        }
                        statTitle={t("last-30-days-sales")}
                        series={stats.perDaySessionAmountCountLast30Days}
                        options={this.getChartOptions("#ea5455")}
                        type="area"
                    />
                  </Col>
                </Row>
                <Row className="match-height">
                  <Col md="6">
                    <TopProductsList
                        demandingItems={stats.famousItemsInLastSevenDays}
                        dataText={t("in-last-7-days")}
                    />
                  </Col>
                  <Col md="6">
                    <TopProductsList
                        demandingItems={stats.famousItemsInLast30Days}
                        dataText={t("in-last-30-days")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <LastestSessionListDashboard
                        data={stats.recent10Sessions}
                        location={this.state.location}
                    />
                  </Col>
                </Row>
              </>
          ) : this.state.role === "Hotel Manager" ? (
              <>
                <div className="location">
                  <div className="display-flex flex-row justify-content-between mb-1">
                    <div className="d-flex"></div>
                    <div className="d-flex">
                  <span className="mr-1">
                    <OTPGenerator />
                  </span>
                      <span className="mr-1">
                    <RawSqlUploaderHeaders
                        text={locationName}
                        onIconClick={this.syncRawSqlData.bind(this)}
                    />
                  </span>
                      <TakeDatabaseBackup
                          text={locationName}
                          onIconClick={this.backupLocalDb.bind(this)}
                      />
                    </div>
                  </div>
                </div>
                <Row className="match-height">
                  <Col sm="12" md="12" lg="12">
                    <SalesCard location={locationName} />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}
                        iconRight
                        iconBg="success"
                        icon={<BarChart2 className="success" size={22} />}
                        stat={stats.lastSevenDaysSessions}
                        statTitle={t("last-7-days-sessions")}
                        series={stats.perDaySessionCountLastSevenDays}
                        options={this.getChartOptions("#28C76F")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}
                        iconRight
                        iconBg="primary"
                        icon={<BarChart className="primary" size={22} />}
                        stat={stats.last30DaysSessions}
                        statTitle={t("last-30-days-sessions")}
                        series={stats.perDaySessionCountLast30Days}
                        options={this.getChartOptions("#4a2884")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}
                        iconRight
                        iconBg="warning"
                        icon={<TrendingUp className="warning" size={22} />}
                        stat={
                          stats.lastSevenDaysSessionsAmount
                        }
                        statTitle={t("last-7-days-sales")}
                        series={stats.perDaySessionAmountCountLastSevenDays}
                        options={this.getChartOptions("#ff9f43")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}
                        iconRight
                        iconBg="danger"
                        icon={<TrendingUp className="danger" size={22} />}
                        stat={
                          stats.last30DaysSessionsAmount
                        }
                        statTitle={t("last-30-days-sales")}
                        series={stats.perDaySessionAmountCountLast30Days}
                        options={this.getChartOptions("#ea5455")}
                        type="area"
                    />
                  </Col>
                </Row>
                {/* <QuickLinks /> */}

                <Row className="match-height">
                  <Col md="6">
                    <TopProductsList
                        demandingItems={stats.famousItemsInLastSevenDays}
                        dataText={t("in-last-7-days")}
                    />
                  </Col>
                  <Col md="6">
                    <TopProductsList
                        demandingItems={stats.famousItemsInLast30Days}
                        dataText={t("in-last-30-days")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <LastestSessionListDashboard
                        data={stats.recent10Sessions}
                        location={location}
                    />
                  </Col>
                </Row>
              </>
          ) : (
              <>
                <div className="location">
                  <div className="display-flex flex-row justify-content-between mb-1">
                    <div className="d-flex">
                      <LocationSelectHeader
                          text={locationName}
                          onIconClick={this.toggleLocation.bind(this)}
                      />
                      <DirectOrderQr location={location} />
                    </div>

                    <div className="d-flex">
                  <span className="mr-1">
                    <OTPGenerator />
                  </span>
                      <span className="mr-1">
                    <RawSqlUploaderHeaders
                        text={locationName}
                        onIconClick={this.syncRawSqlData.bind(this)}
                    />
                  </span>
                      <TakeDatabaseBackup
                          text={locationName}
                          onIconClick={this.backupLocalDb.bind(this)}
                      />
                    </div>
                  </div>
                </div>

                <ManagerQuickLinks />
                <Row className="match-height">
                  <Col sm="12" md="12" lg="4">
                    <SalesCard location={locationName} />
                  </Col>
                  {/* <Col xl="2" lg="4" sm="4">
                <OTPGenerator />
              </Col>
              <Col xl="2" lg="4" sm="4">
                <a href="/locations/item_stats">
                  <StatisticsCard
                    hideChart
                    managerLinks
                    iconBg="primary"
                    icon={<Archive className="primary" size={32} />}
                    stat={stats.overAllItems}
                    statTitle={t("overall-items")}
                  />
                </a>
              </Col>
              <Col xl="2" lg="4" sm="4">
                <a href="/tables">
                  <StatisticsCard
                    hideChart
                    managerLinks
                    iconBg="success"
                    icon={<List className="success" size={32} />}
                    stat={stats.totalTables}
                    statTitle={t("overall-tables")}
                  />
                </a>
              </Col>
              <Col xl="2" lg="4" sm="4">
                <Link to="/tables/session">
                  <StatisticsCard
                    hideChart
                    managerLinks
                    iconBg="primary"
                    icon={<Activity className="primary" size={32} />}
                    stat={stats.overAllSessions}
                    statTitle={t("overall-sessions")}
                  />
                </Link>
              </Col>{" "} */}
                  <Col xl="3" lg="4" sm="4">
                    <a href="/locations/item_stats">
                      <StatisticsCard
                          hideChart
                          managerLinks
                          iconBg="primary"
                          icon={<Archive className="primary" size={32} />}
                          stat={stats.overAllItems}
                          statTitle={t("overall-items")}
                      />
                    </a>
                  </Col>

                  <Col xl="2" lg="4" sm="4">
                    <a href="/tables">
                      <StatisticsCard
                          hideChart
                          managerLinks
                          iconBg="success"
                          icon={<List className="success" size={32} />}
                          stat={stats.totalTables}
                          statTitle={t("overall-tables")}
                      />
                    </a>
                  </Col>
                  <Col xl="3" lg="4" sm="4">
                    <Link to="/tables/session">
                      <StatisticsCard
                          hideChart
                          managerLinks
                          iconBg="primary"
                          icon={<Activity className="primary" size={32} />}
                          stat={stats.overAllSessions}
                          statTitle={t("overall-sessions")}
                      />
                    </Link>
                  </Col>
                </Row>

                <Row>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}
                        iconRight
                        iconBg="success"
                        icon={<BarChart2 className="success" size={22} />}
                        stat={stats.lastSevenDaysSessions}
                        statTitle={t("last-7-days-sessions")}
                        series={stats.perDaySessionCountLastSevenDays}
                        options={this.getChartOptions("#28C76F")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}
                        iconRight
                        iconBg="primary"
                        icon={<BarChart className="primary" size={22} />}
                        stat={stats.last30DaysSessions}
                        statTitle={t("last-30-days-sessions")}
                        series={stats.perDaySessionCountLast30Days}
                        options={this.getChartOptions("#4a2884")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}
                        iconRight
                        iconBg="warning"
                        icon={<TrendingUp className="warning" size={22} />}
                        stat={
                          stats.lastSevenDaysSessionsAmount
                        }
                        statTitle={t("last-7-days-sales")}
                        series={stats.perDaySessionAmountCountLastSevenDays}
                        options={this.getChartOptions("#ff9f43")}
                        type="area"
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <StatisticsCard
                        symbol={location?.Currency?.currency_symbol}
                        iconRight
                        iconBg="danger"
                        icon={<TrendingUp className="danger" size={22} />}
                        stat={
                          stats.last30DaysSessionsAmount
                        }
                        statTitle={t("last-30-days-sales")}
                        series={stats.perDaySessionAmountCountLast30Days}
                        options={this.getChartOptions("#ea5455")}
                        type="area"
                    />
                  </Col>
                </Row>
                {/* <QuickLinks /> */}

                <Row className="match-height">
                  <Col md="6">
                    <TopProductsList
                        demandingItems={stats.famousItemsInLastSevenDays}
                        dataText={t("in-last-7-days")}
                    />
                  </Col>
                  <Col md="6">
                    <TopProductsList
                        demandingItems={stats.famousItemsInLast30Days}
                        dataText={t("in-last-30-days")}
                    />
                  </Col>
                </Row>
                {/* <Row>
              {' '}
              <Col lg="12" md="8" sm="12">
                <Card body>
                  <h4>Comments</h4>

                  <div className="d-flex mb-3">
                    <div
                      className="rounded-circle mr-3"
                      style={{
                        width: '50px',
                        height: '50px',
                        background: '#ccc',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <User size={24} color="#fff" />
                    </div>
                    <div>
                      <h5>John Doe</h5>
                      <p>
                        This is a sample comment by John. <User size={20} />
                      </p>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div
                      className="rounded-circle mr-3"
                      style={{
                        width: '50px',
                        height: '50px',
                        background: '#ccc',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <User size={24} color="#fff" />
                    </div>
                    <div>
                      <h5>Jane Smith</h5>
                      <p>
                        I like this feature a lot. <MessageCircle size={20} />
                      </p>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div
                      className="rounded-circle mr-3"
                      style={{
                        width: '50px',
                        height: '50px',
                        background: '#ccc',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <User size={24} color="#fff" />
                    </div>
                    <div>
                      <h5>Alice Johnson</h5>
                      <p>
                        Everything is very good for now, I will comment again
                        after a long use.
                        <Heart size={20} />
                      </p>
                    </div>
                  </div>
               
                </Card>
              </Col>
            </Row> */}
                <Row>
                  <Col md="12">
                    <LastestSessionListDashboard
                        data={stats.recent10Sessions}
                        location={this.state.location}
                    />
                  </Col>
                </Row>
              </>
          )}
        </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    analytics: {
      dashboardStats: { data: statsData, loading: statsLoading },
    },
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
    notifications: {
      allnotifications: {
        data: notificationData,
        loading: notificationLoading,
      },
    },
  } = state;

  return {
    stats: statsData,
    location: locationData,
    loading: statsLoading || locationLoading || notificationLoading,
  };
}

export default withTranslation("global")(
    connect(mapStateToProps, {
      getLocation,
      getDashboardStats,
      getAllNavBarNotifications,
    })(AnalyticsDashboard)
);
