// import React from "react";
// import Select from "react-select";
// import { Button, Row, Col, Card, CardBody } from "reactstrap";
// import "../../assets/scss/plugins/forms/react-select/_react-select.scss";
// import "../../assets/scss/pages/app-ecommerce-shop.scss";
// import { X } from "react-feather";
// import FREETABLE from "../../assets/img/pages/table_empty.png";
// import BOOKEDTABLE from "../../assets/img/pages/table_booked.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFire,
//   faGlassCheers,
//   faBirthdayCake,
//   faClock,
//   faCoins,
//   faTimes,
//   faStopwatch,
//   faMoneyBill,
// } from "@fortawesome/free-solid-svg-icons";
// import { withTranslation } from "react-i18next";
// import i18n from "i18next";
// import TimeHelper from "../../helpers/TimeHelper";

// class ListSessionRoomsStatus extends React.Component {
//   state = {
//     inCart: [],
//     inWishlist: [],
//     view: "grid-view",
//   };

//   componentDidMount() {
//     const savedLanguage = localStorage.getItem("lang");
//     if (savedLanguage) {
//       i18n.changeLanguage(savedLanguage);
//     }
//   }
//   getOrderFromDisplayName = (orderFrom) => {
//     switch (orderFrom) {
//       case "QR Scan":
//         return "QuickServe";
//       case "Eater App":
//         return "ServTable";
//       case "Waiter App":
//         return "ServWait";
//       default:
//         return orderFrom;
//     }
//   };

//   renderRoomsData = (rooms) => {
//     const { t } = this.props;
//     let renderTables = rooms?.data?.map((rooms, index) => {
//       const sessionTime = new Date(
//         rooms?.LocationSessions[0]?.session_date +
//           " " +
//           rooms?.LocationSessions[0]?.start_time
//       );
//       const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
//       if (rooms.room_status === false) {
//         return null;
//       }

//       return (
//         <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
//           {/* {rooms.room_status && ( */}
//           <Card className="ecommerce-card table-session">
//             <div className="card-content">
//               <div className="row">
//                 <div className="col-12">
//                   <span className="float-left font-weight-bold">
//                     <FontAwesomeIcon
//                       className=" text-white"
//                       icon={faClock}
//                       style={{ fontSize: "20px" }}
//                     />

//                     {rooms.is_room_available
//                       ? " 00"
//                       : ` ${this.getSessionTime(sessionTime)}`}
//                   </span>
//                   <span className="float-right font-weight-bold">
//                     <FontAwesomeIcon
//                       className="mr-1"
//                       style={{ fontSize: "20px", color: "gold" }}
//                       icon={faCoins}
//                     />
//                     {rooms.is_room_available
//                       ? 0
//                       : rooms?.LocationSessions[0]?.Orders.length > 0
//                       ? this.props.location.currency +
//                         " " +
//                         this.formatAmount(
//                           rooms?.LocationSessions[0]?.Orders[0]
//                             .session_order_amount
//                         )
//                       : 0}
//                   </span>

//                   {/* <span className="float-right font-weight-bold">
//                       <FontAwesomeIcon
//                         className="mr-1"
//                         style={{ fontSize: "20px", color: "gold" }}
//                         icon={faCoins}
//                       />
//                       {table.is_table_available
//                         ? 0
//                         : table?.LocationSessions[0]?.Orders.length > 0
//                         ? this.props.location.currency +
//                           " " +
//                           table?.LocationSessions[0]?.Orders[0]
//                             .session_order_amount
//                         : 0}
//                     </span> */}
//                 </div>
//               </div>

//               <div className="item-img text-center">
//                 {rooms.is_room_available ? (
//                   <img
//                     style={{ filter: "brightness(2)" }}
//                     className="img-fluid"
//                     src={FREETABLE}
//                     alt={rooms.room_name}
//                   />
//                 ) : (
//                   <img
//                     style={{ filter: "brightness(2)" }}
//                     className="img-fluid"
//                     src={BOOKEDTABLE}
//                     alt={rooms.room_name}
//                   />
//                 )}

//                 <h4 className="col-12">
//                   {rooms.room_name ? (
//                     <div className="font-weight-bold text-white">
//                       {rooms.room_name}
//                     </div>
//                   ) : (
//                     <div
//                       className="font-weight-bold"
//                       style={{ height: "1.5rem" }}
//                     ></div>
//                   )}
//                   {/* <h4 className="col-12 text-white font-weight-bold mt-1">
//                   {table?.LocationSessions[0]?.session_status === "started" ? (
//     table?.LocationSessions[0]?.order_from
//   ) : null}
//                   </h4> */}
//                   <h4 className="col-12 text-white font-weight-bold mt-1">
//                     {rooms?.LocationSessions[0]?.session_status === "started"
//                       ? this.getOrderFromDisplayName(
//                           rooms?.LocationSessions[0]?.order_from
//                         )
//                       : null}
//                   </h4>

//                   {/* <div className="font-weight-bold">{table.table_name} </div> */}
//                   {rooms.is_room_available ? (
//                     <span>
//                       {" "}
//                       <button
//                         type="submit"
//                         className="cart btn btn-success mt-1 col-6 "
//                         style={{ cursor: "not-allowed" }}
//                       >
//                         {t("free")}{" "}
//                       </button>
//                     </span>
//                   ) : (
//                     <button
//                       onClick={() => {
//                         this.props.closeLocationSession(
//                           rooms?.LocationSessions[0]?.session_id
//                         );
//                         // this.sessionEndDate();
//                       }}
//                       type="submit"
//                       className="cart btn btn-warning mt-1"
//                     >
//                       {t("close-session")}{" "}
//                     </button>
//                   )}
//                 </h4>
//               </div>

//               <CardBody className="text-center">
//                 <div className="item-wrapper">
//                   <div className="product-price">
//                     <h4 className="item-price">
//                       {rooms.is_room_available ? (
//                         <span className=" font-weight-bold success">
//                           <br></br>
//                         </span>
//                       ) : (
//                         <>
//                           <FontAwesomeIcon
//                             className=" text-white mr-1"
//                             icon={faStopwatch}
//                             style={{ fontSize: "20px" }}
//                           />
//                           <span className=" font-weight-bold warning">
//                             <span className=" font-weight-bold warning"></span>
//                             {sessionTimeCorrect}
//                             {/* {sessionTime.toLocaleTimeString("en-US", {
//                               hour: "2-digit",
//                               minute: "2-digit",
//                             })} */}
//                           </span>
//                         </>
//                       )}
//                     </h4>

//                     {/* <h4 className="item-price">
//                       <FontAwesomeIcon
//                         className=" text-white mr-1"
//                         icon={faStopwatch}
//                         style={{ fontSize: '20px' }}
//                       />
//                       {table.is_table_available ? (
//                         <span className=" font-weight-bold success">
//                           Not Started
//                         </span>
//                       ) : (
//                         <span className=" font-weight-bold warning">
//                           {sessionTime.toLocaleTimeString('en-US', {
//                             // en-US can be set to 'default' to use user's browser settings
//                             hour: '2-digit',
//                             minute: '2-digit',
//                           })}
//                         </span>
//                       )}
//                     </h4> */}
//                   </div>
//                 </div>
//               </CardBody>
//             </div>
//           </Card>
//           {/* )} */}
//         </div>
//       );
//     });
//     // Filter out null values (tables with table_status false)
//     return renderTables;
//   };

//   // getSessionTime = (time_diff) => {
//   //   let cDate = new Date();
//   //   console.log("CDATE", cDate);
//   //   let sDate = new Date(time_diff);
//   //   console.log("sdate", sDate);
//   //   const diffTime = Math.abs(cDate - sDate);
//   //   const diffMinutes = Math.ceil(diffTime / (1000 * 60));

//   //   console.log("DIFFERENCE TIME", diffMinutes);
//   //   return diffMinutes + " Minutes";
//   // };
//   getSessionTime = (time_diff) => {
//     let cDate = new Date();
//     console.log("CDATE", cDate);
//     let sDate = new Date(time_diff);
//     console.log("sdate", sDate);
//     const diffTime = Math.abs(cDate - sDate);
//     let diffMinutes = Math.ceil(diffTime / (1000 * 60));

//     console.log("DIFFERENCE TIME", diffMinutes);

//     let result;
//     if (diffMinutes > 60) {
//       const diffHours = Math.floor(diffMinutes / 60);
//       result = `${diffHours} Hrs`;
//     } else {
//       result = `${diffMinutes} Minutes`;
//     }

//     return result;
//   };

//   // getSessionTime = (time_diff) => {
//   //   let cDate = new Date();
//   //   console.log("CDATE", cDate);
//   //   let sDate = new Date(time_diff);
//   //   console.log("sdate", sDate);
//   //   const diffTime = Math.abs(cDate - sDate);
//   //   let diffMinutes = Math.ceil(diffTime / (1000 * 60));

//   //   console.log("DIFFERENCE TIME", diffMinutes);

//   //   if (diffMinutes > 60) {
//   //     diffMinutes = Math.round(diffMinutes / 60) * 60;
//   //   }
//   //   let result = diffMinutes + " Minutes";
//   //   if (diffMinutes > 1000) {
//   //     result = Math.round(diffMinutes / 1000) + "K Minutes";
//   //   }

//   //   return result;
//   // };
//   formatAmount = (amount) => {
//     if (amount >= 1000) {
//       if (amount >= 1000000 && amount < 1000000000) {
//         return (amount / 1000000).toFixed(1) + "M";
//       } else if (amount >= 1000000000) {
//         return (amount / 1000000000).toFixed(1) + "B";
//       } else {
//         return (amount / 1000).toFixed(1) + "K";
//       }
//     } else {
//       return amount.toFixed(2);
//     }
//   };

//   // sessionEndDate = () => {
//   //   const today = new Date();
//   //   const year = today.getFullYear();
//   //   const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
//   //   const day = String(today.getDate()).padStart(2, "0");

//   //   console.log(year, month, day);
//   //   return `${year}-${month}-${day}`;
//   // };

//   render() {
//     const { rooms } = this.props;
//     const { t } = this.props;

//     console.log(this.props);

//     return (
//       <div className="col-12">
//         <Row>
//           <Col sm="12">
//             <div id="ecommerce-products">
//               <Row>{this.renderRoomsData(rooms)}</Row>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     );
//   }
// }

// export default withTranslation("global")(ListSessionRoomsStatus);

// // export default ListSessionTablesStatus;

import React from "react";
import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import TimeHelper from "../../helpers/TimeHelper";
import FREETABLE from "../../assets/img/pages/table_empty.png";
import BOOKEDTABLE from "../../assets/img/pages/table_booked.png";
import QRCode from "qrcode.react";
import { Link, X } from "react-feather";
import { saveAs } from "file-saver";
import CONSTANTS from "../../constants";

class ListSessionRoomsStatus extends React.Component {
  state = {
    inCart: [],
    inWishlist: [],
    view: "grid-view",
    qrModalOpen: false,
    qrImageUrl: "",
    qrImageName: "",
    redirectURL: "",
    activeTab: "1",
  };

  componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  getOrderFromDisplayName = (orderFrom) => {
    switch (orderFrom) {
      case "QR Scan":
        return "QuickServe";
      case "Eater App":
        return "ServTable";
      case "Waiter App":
        return "ServWait";
      default:
        return orderFrom;
    }
  };

  getSessionTime = (time_diff) => {
    let cDate = new Date();
    let sDate = new Date(time_diff);
    const diffTime = Math.abs(cDate - sDate);
    let diffMinutes = Math.ceil(diffTime / (1000 * 60));

    let result;
    if (diffMinutes > 60) {
      const diffHours = Math.floor(diffMinutes / 60);
      result = `${diffHours} Hrs`;
    } else {
      result = `${diffMinutes} Minutes`;
    }

    return result;
  };

  formatAmount = (amount) => {
    if (amount >= 1000) {
      if (amount >= 1000000 && amount < 1000000000) {
        return (amount / 1000000).toFixed(1) + "M";
      } else if (amount >= 1000000000) {
        return (amount / 1000000000).toFixed(1) + "B";
      } else {
        return (amount / 1000).toFixed(1) + "K";
      }
    } else {
      return amount.toFixed(2);
    }
  };

  openQrModal = (qrCodeUrl, qrImageName, url) => {
    this.setState({
      qrModalOpen: true,
      qrImageUrl: qrCodeUrl,
      qrImageName: qrImageName,
      redirectURL: url,
    });
  };

  closeQrModal = () => {
    this.setState({
      qrModalOpen: false,
      qrImageUrl: "",
      qrImageName: "",
      redirectURL: "",
    });
  };

  redirectToURL = (url) => {
    this.closeQrModal();
    window.open(url, "_blank");
  };

  goToOrderSession = (sessionId) => {
    console.log("SESSIONID", sessionId);
    window.open(`${CONSTANTS.BASE_URL}sessions/${sessionId}`, "_self");
  };

  render() {
    const { tables, rooms, t } = this.props;
    console.log("TABLES & ROOMS", tables, rooms);

    const customStyles = {
      headCells: {
        style: {
          fontWeight: "bold",
        },
      },
    };

    const buttonStyle = {
      flex: 1,
      margin: "5px",
      display: "flex",
      alignItems: "center",
    };

    const roomData = rooms.data?.map((room, index) => {
      const sessionTime = new Date(
        room?.LocationSessions[0]?.session_date +
          " " +
          room?.LocationSessions[0]?.start_time
      );
      const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
      const roomAvailable = room?.room_available_hotel;
      const orderAmount =
        room?.LocationSessions[0]?.Orders.length > 0
          ? this.props.location?.currency +
            " " +
            this.formatAmount(
              room?.LocationSessions[0]?.Orders[0].session_order_amount
            )
          : 0;

      return {
        id: index,
        name: room.room_name,
        status: roomAvailable ? "Free" : "Occupied",
        time: roomAvailable ? "00" : this.getSessionTime(sessionTime),
        amount: roomAvailable ? 0 : orderAmount,
        hotelName: room?.hotel_name,
        sessionStatus:
          room?.LocationSessions[0]?.session_status === "started"
            ? this.getOrderFromDisplayName(
                room?.LocationSessions[0]?.order_from
              )
            : null,
        sessionTime: roomAvailable ? null : sessionTimeCorrect,
        image: roomAvailable ? FREETABLE : BOOKEDTABLE,
        sessionId: room?.LocationSessions[0]?.session_id,
        qrCodeData: room.qrcodedata,
        sessions: room?.LocationSessions[0]?.session_id,
        sessionsStatus: room?.LocationSessions[0]?.session_status,
      };
    });

    const tableData = rooms?.restaurantRoomsWithSessions?.map(
      (table, index) => {
        const TableSessionTime = new Date(
          table?.LocationSessions[0]?.session_date +
            " " +
            table?.LocationSessions[0]?.start_time
        );
        const tableSessionTimeCorrect =
          TimeHelper.toFormattedTime(TableSessionTime);
        const tableAvailable = table?.room_available_restaurant;
        const TableOrderAmount =
          table?.LocationSessions[0]?.Orders.length > 0
            ? this.props.location?.currency +
              " " +
              this.formatAmount(
                table?.LocationSessions[0]?.Orders[0].session_order_amount
              )
            : 0;

        return {
          id: index,
          name: table.room_name,
          tableStatus: tableAvailable ? "Free" : "Occupied",
          tableTime: tableAvailable
            ? "00"
            : this.getSessionTime(TableSessionTime),
          tableAmount: tableAvailable ? 0 : TableOrderAmount,

          tableSessionStatus:
            table?.LocationSessions[0]?.session_status === "started"
              ? this.getOrderFromDisplayName(
                  table?.LocationSessions[0]?.order_from
                )
              : null,
          tableSessionTime: tableAvailable ? null : tableSessionTimeCorrect,
          image: tableAvailable ? FREETABLE : BOOKEDTABLE,
          tableSessionId: table?.LocationSessions[0]?.session_id,
          tableQrCodeData: table.qrcodedata,
          tableSessions: table?.LocationSessions[0]?.session_id,
          tableSessionsStatus: table?.LocationSessions[0]?.session_status,
        };
      }
    );

    const tableColumns = [
      {
        name: t("Room Name"),
        selector: (row) => row?.name,
        sortable: true,
      },

      {
        name: t("Amount"),
        selector: (row) => row?.tableAmount,
        sortable: true,
        cell: (row) => <span>{row?.tableAmount}</span>,
      },
      {
        name: "Time Elapsed",
        selector: (row) => row?.tableTime,
        sortable: true,
        cell: (row) => <span>{row?.tableTime}</span>,
      },
      {
        name: t("Session Time"),
        selector: (row) => row?.tableSessionTime,
        sortable: true,
        cell: (row) =>
          row.tableSessionTime && <span>{row?.tableSessionTime}</span>,
      },
      {
        name: "Order From",
        selector: (row) => row?.tableSessionStatus,
        sortable: true,
      },
      {
        width: "200px",
        name: "Session Id",
        selector: (row) => row?.tableSessions,
        sortable: true,
        cell: (row) => {
          if (row?.tableSessionsStatus === "started") {
            return (
              <button
                onClick={() => this.goToOrderSession(row?.tableSessions)}
                className="btn  btn-link"
              >
                <span> {row?.tableSessions}</span>
              </button>
            );
          } else {
            return null;
          }
        },
      },
      {
        name: t("QR Code"),
        selector: (row) => row?.tableQrCodeData,
        sortable: true,
        cell: (row) =>
          row?.tableQrCodeData ? (
            <Button
              outline
              className="btn-icon"
              color="primary"
              onClick={() =>
                this.openQrModal(
                  row?.tableQrCodeData,
                  row?.name,
                  row?.tableQrCodeData
                )
              }
            >
              <QRCode value={row?.tableQrCodeData} size={35} />
            </Button>
          ) : null,
      },
      {
        name: t("Action"),
        width: "200px",
        cell: (row) =>
          row?.tableStatus === "Occupied" ? (
            <Button
              onClick={() =>
                this.props.closeLocationSession(row?.tableSessionId)
              }
              type="submit"
              className="cart btn  btn-warning"
            >
              {t("close-session")}
            </Button>
          ) : (
            <Button type="button" className="cart btn  btn-success" disabled>
              {t("Free")}
            </Button>
          ),
      },
    ];
    const roomColumns = [
      {
        name: t("Room Name"),
        selector: (row) => row?.name,
        sortable: true,
      },
      {
        name: t("Hotel Name"),
        selector: (row) => row?.hotelName,
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row?.amount,
        sortable: true,
        cell: (row) => <span>{row?.amount}</span>,
      },
      {
        name: "Time Elapsed",
        selector: (row) => row?.time,
        sortable: true,
        cell: (row) => <span>{row?.time}</span>,
      },
      {
        name: t("Session Time"),
        selector: (row) => row?.sessionTime,
        sortable: true,
        cell: (row) => row.sessionTime && <span>{row?.sessionTime}</span>,
      },
      {
        name: "Order From",
        selector: (row) => row?.sessionStatus,
        sortable: true,
      },
      {
        width: "200px",
        name: "Session Id",
        selector: (row) => row?.sessions,
        sortable: true,
        cell: (row) => {
          if (row?.sessionsStatus === "started") {
            return (
              <button
                onClick={() => this.goToOrderSession(row?.sessions)}
                className="btn  btn-link"
              >
                <span> {row?.sessions}</span>
              </button>
            );
          } else {
            return null;
          }
        },
      },
      {
        name: t("QR Code"),
        selector: (row) => row?.qrCodeData,
        sortable: true,
        cell: (row) =>
          row?.qrCodeData ? (
            <Button
              outline
              className="btn-icon"
              color="primary"
              onClick={() =>
                this.openQrModal(row?.qrCodeData, row?.name, row?.qrCodeData)
              }
            >
              <QRCode value={row?.qrCodeData} size={35} />
            </Button>
          ) : null,
      },
      {
        name: t("Action"),
        width: "200px",
        cell: (row) =>
          row?.status === "Occupied" ? (
            <Button
              onClick={() => this.props.closeLocationSession(row?.sessionId)}
              type="submit"
              className="cart btn  btn-warning"
            >
              {t("close-session")}
            </Button>
          ) : (
            <Button type="button" className="cart btn  btn-success" disabled>
              {t("Free")}
            </Button>
          ),
      },
    ];

    const roomDataFinal = {
      columns: roomColumns,
      data: roomData,
      print: false,
      export: false,
    };

    const tableDataFinal = {
      columns: tableColumns,
      data: tableData,
      print: false,
      export: false,
    };
    return (
      <>
        <div className="col-12">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "1" ? "active" : ""}
                onClick={() => {
                  this.toggleTab("1");
                }}
              >
                Hotel
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "2" ? "active" : ""}
                onClick={() => {
                  this.toggleTab("2");
                }}
              >
                Restaurant
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12" lg="12">
                  <DataTableExtensions {...roomDataFinal}>
                    <DataTable
                      noHeader
                      pagination
                      paginationRowsPerPageOptions={[25, 50, 100]}
                      paginationPerPage={50}
                      columns={roomColumns}
                      data={roomData}
                      customStyles={customStyles}
                    />
                  </DataTableExtensions>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12" lg="12">
                  <DataTableExtensions {...tableDataFinal}>
                    <DataTable
                      noHeader
                      pagination
                      paginationRowsPerPageOptions={[25, 50, 100]}
                      paginationPerPage={50}
                      columns={tableColumns}
                      data={tableData}
                      customStyles={customStyles}
                    />
                  </DataTableExtensions>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>

        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "500px",
          }}
          isOpen={this.state.qrModalOpen}
          toggle={this.closeQrModal}
        >
          <ModalBody>
            <div className="display-flex full-width flex-space-between">
              <Button.Ripple
                style={{ width: 20, height: 20 }}
                onClick={this.closeQrModal}
                className="m-0 p-0"
                color="danger"
              >
                <X size={14} />
              </Button.Ripple>
            </div>
            <div style={{ textAlign: "center", padding: "50px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  style={buttonStyle}
                  className="btn-icon"
                  color="primary"
                  onClick={() => this.redirectToURL(this.state.redirectURL)}
                >
                  <Link size={16} style={{ marginRight: "5px" }} />{" "}
                  {t("qr-url")}
                </Button>
                <h4 style={{ marginBottom: "-3px" }}>
                  {this.state.qrImageName}
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <QRCode value={this.state.qrImageUrl} size={100} />
                </div>
                <Button
                  style={buttonStyle}
                  className="btn-icon"
                  color="primary"
                  onClick={() =>
                    saveAs(
                      this.state.qrImageUrl,
                      `${this.state.qrImageName}.jpeg`
                    )
                  }
                >
                  {t("download")}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withTranslation("global")(ListSessionRoomsStatus);
