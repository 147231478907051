import LoginService from "./LoginService";
import CONSTANTS from "../constants";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getAllRooms,
  saveRoom,
  getRoomById,
  deleteRoom,
  getRoomsByLocationId,
  updateRoom,
  getAllSessionRooms,
};

async function getAllRooms() {
  try {
    let { token } = LoginService.getToken();
    let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROOMS.GET_ALL}`,
      {
        method: "GET",
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
    );
    response = await response.json();
    if (response) return response;
    throw new Error("Could not fetch rooms");
  } catch (error) {
    throw error;
  }
}

async function getAllSessionRooms(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROOMS.SESSION_ROOM_STATUSES(
      locationId
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch Rooms");
}

async function getRoomById(roomId) {
  try {
    let { token } = LoginService.getToken();

    let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROOMS.GET_ROOM_BY_ID(
        roomId
      )}`,
      {
        method: "GET",
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
    );
    response = await response.json();
    console.log("RESPONSE DATA", response);
    return response;
  } catch (error) {
    console.error("Error fetching rooms by location ID:", error);
    throw error;
  }
}
async function getRoomsByLocationId(locationId) {
  try {
    let { token } = LoginService.getToken();

    let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROOMS.GET_ROOM_BY_LOC(
        locationId
      )}`,
      {
        method: "GET",
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
    );
    response = await response.json();
    console.log("RESPONSE DATA", response);
    return response;
  } catch (error) {
    console.error("Error fetching rooms by location ID:", error);
    throw error;
  }
}

async function saveRoom(item) {
  console.log("ROOM SERVICE", item);
  try {
    let { token } = LoginService.getToken();
    let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROOMS.CREATE_ROOM(
        item.hotel_id
      )}`,
      {
        method: "POST",
        body: JSON.stringify(item),
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
    );
    response = await response.json();
    if (response) return response;
    throw new Error("Could not save room");
  } catch (error) {
    throw error;
  }
}

async function deleteRoom(room_id) {
  console.log("ROOMID DE", room_id);
  try {
    let { token } = LoginService.getToken();
    let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROOMS.DELETE_ROOM(room_id)}`,
      {
        method: "DELETE",
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
    );
    if (response.status === 200) {
      return "Room deleted successfully";
    } else {
      throw new Error("Failed to delete room");
    }
  } catch (error) {
    throw error;
  }
}

async function updateRoom(room_id, item) {
  console.log(room_id, item, "subbbbbb");
  try {
    let { token } = LoginService.getToken();
    let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.ROOMS.UPDATE_ROOM(room_id)}`,
      {
        method: "PUT",
        body: JSON.stringify(item),
        headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
      }
    );
    response = await response.json();
    console.log(response, "updateijhdhuhkduh");
    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to update room");
    }
  } catch (error) {
    throw error;
  }
}
