import React, {useEffect, useState} from 'react'
import {BrunchDining, LocalShipping, ShoppingBag, TableRestaurant} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import {Button, Stack} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setOrderType} from "../../../../../../redux/actions/pos/order";
import TableSelectModal from "../../../mainComponents/tableSelectModal";
import FinalPaymentModal from "../../../mainComponents/finalPaymentModal/finalPaymentModal";
import SnackBar from "../../../mainComponents/snackBar";

const buttonStyle = {
    backgroundColor: grey[200],
    flexGrow: 1,
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.4rem 0.5rem',
    lineHeight: '1.5rem',
    fontSize: '0.85rem',
    color: 'black',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: grey[400],
        padding: '0.4rem 0.5rem'
    }
}

const buttons = [
    {
        label: 'Dine In',
        icon: <BrunchDining />
    },

    {
        label: 'Takeaway',
        icon: <ShoppingBag />
    },

    {
        label: 'Delivery',
        icon: <LocalShipping />
    },

    {
        label: 'Table',
        icon: <TableRestaurant />
    }
]

const ButtonComponents = () => {
    const dispatch = useDispatch();
    const orderType = useSelector((state) => state.pos.orderType);
    const posOrderHistory = useSelector(state => state.pos.posOrdersHistory);
    const [orderData, setOrderData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [selectedTable, setSelectedTable] = useState(null);
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: "",
        severity: ""
    })

    useEffect(() => {
        console.log(selectedTable)
    }, []);

    const handleOrderType = async (index) => {
        if (index === 0) {
            await dispatch(setOrderType({
                order_type: 'Dine In'
            }))
        } else if (index === 1) {
            await dispatch(setOrderType({
                order_type: 'Takeaway'
            }))
        } else if (index === 2) {
            await dispatch(setOrderType({
                order_type: 'Delivery'
            }))
        } else if (index === 3) {
            setOpenModal(true);
        }
    }

    return (
        <>
            <Stack direction='row' sx={{columnGap: '0.5rem'}}>
                {buttons.map((button, index) => (
                    <Button disabled={(orderType.order_type !== 'Dine In' && index === 3)} onClick={()=> handleOrderType(index)} key={index} startIcon={button.icon} variant='contained' sx={{...buttonStyle, backgroundColor: (orderType.order_type === button.label ? grey[400] : grey[200])}} >
                        {button.label}
                    </Button>
                ))}
            </Stack>
            {openModal && <TableSelectModal openModal={openModal} setOpenModal={setOpenModal} setOpenPaymentModal={setOpenPaymentModal} selectedTable={selectedTable} setSelectedTable={setSelectedTable}/>}
            {openPaymentModal && <FinalPaymentModal openModal={openPaymentModal} setOpenModal={setOpenPaymentModal} setSnackbarParams={setSnackbarParams}/> }
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
        </>
    )
}

export default ButtonComponents;