import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reduxForm } from "redux-form";
import toast from "react-hot-toast";
import { serialize } from "php-serialize";
import { useHistory } from "react-router-dom";

import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import {
  getCurrencies,
  getCategories,
} from "../../redux/actions/configurations";
import { updateLocation, createLocation } from "../../redux/actions/locations";
import CONSTANTS from "../../constants";
import { getAllCountries } from "../../redux/actions/currency";

import {timezoneData} from "../../helpers/timezoneData";
import { useTranslation } from "react-i18next";
import CloudUpload from "@mui/icons-material/CloudUpload";

// tab
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LoginService from "../../services/LoginService";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Switch,
  Select,
  MenuItem,
  Button, Checkbox, Collapse, TextField, Autocomplete, Modal, CircularProgress
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import {getAllPaymentMethods} from "../../redux/actions/payment";
import LocationService from "../../services/LocationService";
import {green, red} from "@mui/material/colors";
import {
  addNewLocationPaymentData,
  deleteLocationPaymentData,
  updateLocationPaymentData
} from "../../services/PaymentMethodService";
import {styled} from "@mui/material/styles";
import axios from "axios";

const fields = [
  {
    panel_name: 'Company',
    allyProp: 0,
    label: 'restaurant-settings',
    field: [
      {
        name: 'name',
        type: 'text',
        label: 'restaurant-name',
        width: 'full',
      },

      {
        name: 'location_email',
        type: 'text',
        label: 'email',
        width: 'full'
      },

      {
        name: 'location_telephone',
        type: 'text',
        label: 'phone-number',
        width: 'third'
      },

      {
        name: 'whatsapp_number',
        type: 'text',
        label: 'whatsapp-number',
        width: 'third'
      },

      {
        name: 'postal_code',
        type: 'text',
        label: 'postal-code',
        width: 'third'
      },

      {
        name: 'city',
        type: 'text',
        label: 'city',
        width: 'third'
      },

      {
        name: 'state',
        type: 'text',
        label: 'state',
        width: 'third'
      },

      {
        name: 'country',
        type: 'select',
        label: 'country',
        width: 'third'
      },

      {
        name: 'category_name',
        type: 'select',
        label: 'category-name',
        width: 'third'
      },

      /*{
        name: 'description',
        type: 'textarea',
        label: 'description',
        width: 'full',
      },*/

      {
        name: 'timezone',
        type: 'autocomplete',
        label: 'time-zone',
        width: 'half'
      }
    ]
  },

  {
    panel_name: 'Logo',
    allyProp: 1,
    label: 'restaurant-logo',
    field: [
      {
        name: 'logo',
        type: 'image',
        label: 'logo',
      }
    ]
  },

  {
    panel_name: 'Location',
    allyProp: 2,
    label: 'restaurant-location',
    field: [
      {
        name: 'latitude',
        type: 'text',
        label: 'latitude',
        width: 'half'
      },

      {
        name: 'longitude',
        type: 'text',
        label: 'longitude',
        width: 'half'
      },

      {
        name: 'formattedAddress',
        type: 'text',
        label: 'address',
        width: 'full'
      },

      {
        name: 'delivery_radius',
        type: 'text',
        label: 'delivery-radius',
        width: 'half'
      },

      {
        name: 'delivery_fee',
        type: 'text',
        label: 'delivery_fee',
        width: 'half'
      },

      {
        name: 'google-maps',
        type: 'maps',
        label: 'maps',
        width: 'full'
      }
    ]
  },

  {
    panel_name: 'Currency*',
    allyProp: 3,
    label: 'restaurant-currency',
    field: [
      {
        name: 'currency_id',
        type: 'select',
        label: 'currency',
        width: 'full',
        required: true,
      }
    ]
  },

  {
    panel_name: 'Tax Rate',
    allyProp: 4,
    label: 'restaurant-tax',
    field: [
      {
        name: 'decimal_places',
        type: 'text',
        label: 'decimals',
        width: 'third'
      },

      {
        name: 'tax_rate',
        type: 'text',
        label: 'tax_rate',
        width: 'third'
      },

      {
        name: 'tax_percent',
        type: 'text',
        label: 'tax_percent',
        width: 'third'
      },

      {
        name: 'tax_label',
        type: 'text',
        label: 'tax_label',
        width: 'third'
      }
    ]
  },

  {
    panel_name: 'Timing',
    allyProp: 5,
    label: 'restaurant-timing',
    field: [
      {
        name: 'wait_time',
        type: 'text',
        label: 'wait-time',
        width: 'half'
      },

      {
        name: 'auto_close_time',
        type: 'text',
        label: 'auto-close-time',
        width: 'half'
      }
    ]
  },

  {
    panel_name: 'OTP',
    allyProp: 6,
    label: 'restaurant-otp',
    field: [
      {
        name: 'otp_refresh_time',
        type: 'text',
        label: 'otp-refresh-time',
        width: 'full'
      },

      {
        name: 'use_otp',
        type: 'switch',
        label: 'use_otp',
      },
    ]
  },

  {
    panel_name: 'Payment Gateway',
    allyProp: 7,
    label: 'stripe',
    field: [
      {
        name: 'public_key',
        type: 'text',
        label: 'stripe-key',
        width: 'half'
      },

      {
        name: 'secret_key',
        type: 'text',
        label: 'stripe-secret',
        width: 'half'
      },

      {
        name: 'endpoint_secret',
        type: 'text',
        label: 'endpoint-secret',
        width: 'full'
      },

      {
        name: 'mode',
        type: 'select',
        label: 'stripe-mode',
        width: 'half'
      },

      {
        name: 'status',
        type: 'select',
        label: 'stripe-status',
        width: 'half'
      }
    ]
  },

  {
    panel_name: 'Settings',
    allyProp: 8,
    label: 'restaurant-main-settings',
    field: [
      {
        name: 'dine_in',
        type: 'switch',
        label: 'dine-in',
        width: 'full'
      },

      {
        name: 'delivery',
        type: 'switch',
        label: 'delivery',
        width: 'full'
      },

      {
        name: 'takeaway',
        type: 'switch',
        label: 'takeaway',
        width: 'full'
      },

      {
        name: 'is_locally_treat',
        type: 'switch',
        label: 'is_locally_treat',
        width: 'full'
      },

      {
        name: 'visible',
        type: 'switch',
        label: 'visible'
      },

      {
        name: 'combine_items',
        type: 'switch',
        label: 'combine_items'
      },

      {
        name: 'amount_threshold',
        type: 'text',
        label: 'amount_threshold',
        width: 'full',
      },

      {
        name: 'min_delivery_amount',
        type: 'text',
        label: 'min_delivery_amount',
        width: 'full',
      }
    ]
  }
]

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AnyReactComponent = () => (
    <LocationOnIcon size={30} style={{ color: "red" }} />
);

const TabPanel = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ width: index !== 1 ? "100%" : "fit-content" }}
    >
      {value === index && (
          <Box sx={{ padding: '0 2rem 2rem 2rem' }}>
            {children}
          </Box>
      )}
    </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const LocationForm = (props) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const history = useHistory();
  const [role, setRole] = useState(null);
  const [deletePhoto, setDeletePhoto] = useState(false);
  const [locId, setLocId] = useState(null);

  const location = useSelector(state => state.locations.location);
  const currencies = useSelector(state => state.configurations.currencies);
  const categories = useSelector(state => state.configurations.categories);
  const countries = useSelector(state => state.currency.allcountries);
  const paymentMethods = useSelector(state => state.payment.payment_Methods);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const autoCompleteLoading = open && options.length === 0;

  const [image, setImage] = useState({
    image: location.data.img,
    imageData: null,
    delete: false,
    imageFromBackend: true,
  });

  const [value, setValue] = useState({
    tabVal: 0,
    placeHolders: {
      hotel_id: (location && location.data && location.data.hotel_id) ? location.data.hotel_id : '',
      name: (location && location.data && location.data.name) ? location.data.name : '',
      formattedAddress: (location && location.data && location.data.formattedAddress) ? location.data.formattedAddress : '',
      city: (location && location.data && location.data.city) ? location.data.city : '',
      country: (location && location.data && location.data.country) ? location.data.country : '',
      state: (location && location.data && location.data.state) ? location.data.state : '',
      location_telephone: (location && location.data && location.data.location_telephone) ? location.data.location_telephone : '',
      whatsapp_number: (location && location.data && location.data.whatsapp_number) ? location.data.whatsapp_number : '',
      location_email: (location && location.data && location.data.location_email) ? location.data.location_email : '',
      currency_id: (location && location.data && location.data.currency_id) ? location.data.currency_id : '',
      postal_code: (location && location.data && location.data.postal_code) ? location.data.postal_code : '',
      img: (location && location.data && location.data.img) ? location.data.img : '',
      category_name: (location && location.data && location.data.category_name) ? location.data.category_name : '',
      latitude: (location && location.data && location.data.latitude) ? location.data.latitude : '',
      longitude: (location && location.data && location.data.longitude) ? location.data.longitude : '',
      delivery_radius: (location && location.data && location.data.delivery_radius) ? location.data.delivery_radius : '',
      delivery_fee: (location && location.data && location.data.delivery_fee) ? location.data.delivery_fee : 0,
      decimal_places: (location && location.data && location.data.decimal_places) ? location.data.decimal_places : '',
      description: (location && location.data && location.data.description) ? location.data.description : '',
      isEligibleForHotel: (location && location.data && location.data.isEligibleForHotel) ? location.data.isEligibleForHotel : false,
      tax_label: (location && location.data && location.data.tax_label) ? location.data.tax_label : '',
      tax_percent: (location && location.data && location.data.tax_percent) ? location.data.tax_percent : '',
      tax_rate: (location && location.data && location.data.tax_rate) ? location.data.tax_rate : '',
      wait_time: (location && location.data && location.data.wait_time) ? location.data.wait_time : '',
      auto_close_time: (location && location.data && location.data.auto_close_time) ? location.data.auto_close_time : '',
      otp_refresh_time: (location && location.data && location.data.otp_refresh_time) ? location.data.otp_refresh_time : '',
      timezone: (location && location.data && location.data.timezone) ? location.data.timezone : '',
      use_otp: (location && location.data && location.data.use_otp) ? location.data.use_otp : false,
      dine_in: (location && location.data && location.data.dine_in) ? location.data.dine_in : false,
      takeaway: (location && location.data && location.data.takeaway) ? location.data.takeaway : false,
      delivery: (location && location.data && location.data.delivery) ? location.data.delivery : false,
      min_delivery_amount: (location && location.data && location.data.min_delivery_amount) ? location.data.min_delivery_amount : '',
      amount_threshold: (location && location.data && location.data.amount_threshold) ? location.data.amount_threshold : '',
      is_locally_treat: (location && location.data && location.data.is_locally_treat) ? location.data.is_locally_treat : false,
      public_key: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].public_key : "",
      secret_key: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].secret_key : "",
      endpoint_secret: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].endpoint_secret : "",
      mode: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].mode : "",
      status: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].status : "",
      visible: (location && location.data && location.data.visible) ? location.data.visible : "",
      reservation: (location && location.data && location.data.reservation) ? location.data.reservation : false,
      combine_items: (location && location.data && location.data.combine_items) ? location.data.combine_items : false,
    },
    locationData: {
      hotel_id: (location && location.data && location.data.hotel_id) ? location.data.hotel_id : '',
      name: (location && location.data && location.data.name) ? location.data.name : '',
      formattedAddress: (location && location.data && location.data.formattedAddress) ? location.data.formattedAddress : '',
      city: (location && location.data && location.data.city) ? location.data.city : '',
      country: (location && location.data && location.data.country) ? location.data.country : '',
      state: (location && location.data && location.data.state) ? location.data.state : '',
      location_telephone: (location && location.data && location.data.location_telephone) ? location.data.location_telephone : '',
      whatsapp_number: (location && location.data && location.data.whatsapp_number) ? location.data.whatsapp_number : '',
      location_email: (location && location.data && location.data.location_email) ? location.data.location_email : '',
      currency_id: (location && location.data && location.data.currency_id) ? location.data.currency_id : '',
      postal_code: (location && location.data && location.data.postal_code) ? location.data.postal_code : '',
      img: (location && location.data && location.data.img) ? location.data.img : '',
      category_name: (location && location.data && location.data.category_name) ? location.data.category_name : '',
      latitude: (location && location.data && location.data.latitude) ? location.data.latitude : '',
      longitude: (location && location.data && location.data.longitude) ? location.data.longitude : '',
      delivery_radius: (location && location.data && location.data.delivery_radius) ? location.data.delivery_radius : '',
      delivery_fee: (location && location.data && location.data.delivery_fee) ? location.data.delivery_fee : 0,
      decimal_places: (location && location.data && location.data.decimal_places) ? location.data.decimal_places : '',
      description: (location && location.data && location.data.description) ? location.data.description : '',
      isEligibleForHotel: (location && location.data && location.data.isEligibleForHotel) ? location.data.isEligibleForHotel : false,
      tax_label: (location && location.data && location.data.tax_label) ? location.data.tax_label : '',
      tax_percent: (location && location.data && location.data.tax_percent) ? location.data.tax_percent : '',
      tax_rate: (location && location.data && location.data.tax_rate) ? location.data.tax_rate : '',
      wait_time: (location && location.data && location.data.wait_time) ? location.data.wait_time : '',
      auto_close_time: (location && location.data && location.data.auto_close_time) ? location.data.auto_close_time : '',
      otp_refresh_time: (location && location.data && location.data.otp_refresh_time) ? location.data.otp_refresh_time : '',
      timezone: (location && location.data && location.data.timezone) ? location.data.timezone : '',
      use_otp: (location && location.data && location.data.use_otp) ? location.data.use_otp : false,
      dine_in: (location && location.data && location.data.dine_in) ? location.data.dine_in : false,
      takeaway: (location && location.data && location.data.takeaway) ? location.data.takeaway : false,
      delivery: (location && location.data && location.data.delivery) ? location.data.delivery : false,
      min_delivery_amount: (location && location.data && location.data.min_delivery_amount) ? location.data.min_delivery_amount : '',
      amount_threshold: (location && location.data && location.data.amount_threshold) ? location.data.amount_threshold : '',
      is_locally_treat: (location && location.data && location.data.is_locally_treat) ? location.data.is_locally_treat : false,
      public_key: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].public_key : "",
      secret_key: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].secret_key : "",
      endpoint_secret: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].endpoint_secret : "",
      mode: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].mode : "",
      status: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].status : "",
      visible: (location && location.data && location.data.visible) ? location.data.visible : "",
      reservation: (location && location.data && location.data.reservation) ? location.data.reservation : false,
      combine_items: (location && location.data && location.data.combine_items) ? location.data.combine_items : false,
    }
  });

  const [center, setCenter] = useState({
    lat: (location && location.data && location.data.latitude) ? location.data.latitude * 1 : 23,
    lng: (location && location.data && location.data.longitude) ? location.data.longitude * 1 : 23,
  });
  const [zoom, setZoom] = useState(0);
  const [marker, setMarker] = useState({
    lat: (location && location.data && location.data.latitude) ? location.data.latitude * 1 : 23,
    lng: (location && location.data && location.data.longitude) ? location.data.longitude * 1 : 23,
  });
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    confirm: false
  })

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

  const currentValues = useSelector(state => state.form.locationForm ? state.form.locationForm.values : {});
  const loading = useSelector(state => state.configurations.loading || state.locations.loading || state.currency.loading);

  useEffect(() => {
    const init = async () => {
      const savedLanguage = localStorage.getItem("lang");
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
      }
      const userRole = LoginService.getRole();
      setRole(userRole.role);

      const parts = window.location.pathname.split("/");
      setLocId(parts[2]);

      await dispatch(getCurrencies());
      await dispatch(getCategories());
      await dispatch(getAllCountries());
      await dispatch(getAllPaymentMethods(location.data.loc_id))
    };
    init();
  }, [dispatch]);

  useEffect(() => {
    let active = true;

    if (!autoCompleteLoading) {
      return undefined;
    }


    (async () => {
      const locations = await LocationService.getLocations("", true)

      if (active) {
        setOptions(locations);
      }
    })();

    return () => {
      active = false;
    };
  }, [autoCompleteLoading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    setFieldsData();
  }, [location, paymentMethods.loading]);

  const setFieldsData = () => {
    if (location && location.data) {
      setValue({
        tabVal: 0,
        placeHolders: {
          name: (location && location.data && location.data.name) ? location.data.name : '',
          formattedAddress: (location && location.data && location.data.formattedAddress) ? location.data.formattedAddress : '',
          city: (location && location.data && location.data.city) ? location.data.city : '',
          country: (location && location.data && location.data.country) ? location.data.country : '',
          state: (location && location.data && location.data.state) ? location.data.state : '',
          location_telephone: (location && location.data && location.data.location_telephone) ? location.data.location_telephone : '',
          whatsapp_number: (location && location.data && location.data.whatsapp_number) ? location.data.whatsapp_number : '',
          location_email: (location && location.data && location.data.location_email) ? location.data.location_email : '',
          currency_id: (location && location.data && location.data.currency_id) ? location.data.currency_id : '',
          postal_code: (location && location.data && location.data.postal_code) ? location.data.postal_code : '',
          img: (location && location.data && location.data.img) ? location.data.img : '',
          category_name: (location && location.data && location.data.category_name) ? location.data.category_name : '',
          latitude: (location && location.data && location.data.latitude) ? location.data.latitude : '',
          longitude: (location && location.data && location.data.longitude) ? location.data.longitude : '',
          delivery_radius: (location && location.data && location.data.delivery_radius) ? location.data.delivery_radius : '',
          delivery_fee: (location && location.data && location.data.delivery_fee) ? location.data.delivery_fee : 0,
          decimal_places: (location && location.data && location.data.decimal_places) ? location.data.decimal_places : '',
          description: (location && location.data && location.data.description) ? location.data.description : '',
          isEligibleForHotel: (location && location.data && location.data.isEligibleForHotel) ? location.data.isEligibleForHotel : false,
          tax_label: (location && location.data && location.data.tax_label) ? location.data.tax_label : '',
          tax_percent: (location && location.data && location.data.tax_percent) ? location.data.tax_percent : '',
          tax_rate: (location && location.data && location.data.tax_rate) ? location.data.tax_rate : '',
          wait_time: (location && location.data && location.data.wait_time) ? location.data.wait_time : '',
          auto_close_time: (location && location.data && location.data.auto_close_time) ? location.data.auto_close_time : '',
          otp_refresh_time: (location && location.data && location.data.otp_refresh_time) ? location.data.otp_refresh_time : '',
          timezone: (location && location.data && location.data.timezone) ? location.data.timezone : '',
          use_otp: (location && location.data && location.data.use_otp) ? location.data.use_otp : false,
          dine_in: (location && location.data && location.data.dine_in) ? location.data.dine_in : false,
          takeaway: (location && location.data && location.data.takeaway) ? location.data.takeaway : false,
          delivery: (location && location.data && location.data.delivery) ? location.data.delivery : false,
          min_delivery_amount: (location && location.data && location.data.min_delivery_amount) ? location.data.min_delivery_amount : '',
          amount_threshold: (location && location.data && location.data.amount_threshold) ? location.data.amount_threshold : '',
          is_locally_treat: (location && location.data && location.data.is_locally_treat) ? location.data.is_locally_treat : false,
          public_key: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].public_key : "",
          secret_key: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].secret_key : "",
          endpoint_secret: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].endpoint_secret : "",
          mode: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].mode : "",
          status: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].status : "",
          visible: (location && location.data && location.data.visible) ? location.data.visible : "",
          reservation: (location && location.data && location.data.reservation) ? location.data.reservation : false,
          combine_items: (location && location.data && location.data.combine_items) ? location.data.combine_items : false,
        },
        locationData: {
          name: (location && location.data && location.data.name) ? location.data.name : '',
          formattedAddress: (location && location.data && location.data.formattedAddress) ? location.data.formattedAddress : '',
          city: (location && location.data && location.data.city) ? location.data.city : '',
          country: (location && location.data && location.data.country) ? location.data.country : '',
          state: (location && location.data && location.data.state) ? location.data.state : '',
          location_telephone: (location && location.data && location.data.location_telephone) ? location.data.location_telephone : '',
          whatsapp_number: (location && location.data && location.data.whatsapp_number) ? location.data.whatsapp_number : '',
          location_email: (location && location.data && location.data.location_email) ? location.data.location_email : '',
          currency_id: (location && location.data && location.data.currency_id) ? location.data.currency_id : '',
          postal_code: (location && location.data && location.data.postal_code) ? location.data.postal_code : '',
          img: (location && location.data && location.data.img) ? location.data.img : '',
          category_name: (location && location.data && location.data.category_name) ? location.data.category_name : '',
          latitude: (location && location.data && location.data.latitude) ? location.data.latitude : '',
          longitude: (location && location.data && location.data.longitude) ? location.data.longitude : '',
          delivery_radius: (location && location.data && location.data.delivery_radius) ? location.data.delivery_radius : '',
          delivery_fee: (location && location.data && location.data.delivery_fee) ? location.data.delivery_fee : 0,
          decimal_places: (location && location.data && location.data.decimal_places) ? location.data.decimal_places : '',
          description: (location && location.data && location.data.description) ? location.data.description : '',
          isEligibleForHotel: (location && location.data && location.data.isEligibleForHotel) ? location.data.isEligibleForHotel : false,
          tax_label: (location && location.data && location.data.tax_label) ? location.data.tax_label : '',
          tax_percent: (location && location.data && location.data.tax_percent) ? location.data.tax_percent : '',
          tax_rate: (location && location.data && location.data.tax_rate) ? location.data.tax_rate : '',
          wait_time: (location && location.data && location.data.wait_time) ? location.data.wait_time : '',
          auto_close_time: (location && location.data && location.data.auto_close_time) ? location.data.auto_close_time : '',
          otp_refresh_time: (location && location.data && location.data.otp_refresh_time) ? location.data.otp_refresh_time : '',
          timezone: (location && location.data && location.data.timezone) ? location.data.timezone : '',
          use_otp: (location && location.data && location.data.use_otp) ? location.data.use_otp : false,
          dine_in: (location && location.data && location.data.dine_in) ? location.data.dine_in : false,
          takeaway: (location && location.data && location.data.takeaway) ? location.data.takeaway : false,
          delivery: (location && location.data && location.data.delivery) ? location.data.delivery : false,
          min_delivery_amount: (location && location.data && location.data.min_delivery_amount) ? location.data.min_delivery_amount : '',
          amount_threshold: (location && location.data && location.data.amount_threshold) ? location.data.amount_threshold : '',
          is_locally_treat: (location && location.data && location.data.is_locally_treat) ? location.data.is_locally_treat : false,
          public_key: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].public_key : "",
          secret_key: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].secret_key : "",
          endpoint_secret: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].endpoint_secret : "",
          mode: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].mode : "",
          status: (!paymentMethods.loading && paymentMethods && paymentMethods.data && paymentMethods.data[0]) ? paymentMethods.data[0].status : "",
          visible: (location && location.data && location.data.visible) ? location.data.visible : "",
          reservation: (location && location.data && location.data.reservation) ? location.data.reservation : false,
          combine_items: (location && location.data && location.data.combine_items) ? location.data.combine_items : false
        }
      })

      setImage({
        image: (location && location.data && location.data.img) ? location.data.img : '',
        imageData: null,
        delete: false,
        imageFromBackend: true
      })
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        setImage({image: ev.target.result, imageData: file, delete: false, imageFromBackend: false});
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleDelete = async () => {
    try {
      await LocationService.deleteLocation(location.data.loc_id);
      toast.success("Location deleted successfully");
      history.push("/locations");
    } catch (error) {
      toast.error("Failed to delete location");
    }
  };

  const saveLocation = async (values) => {
    console.log(values);
    try {
      let asyncCall = createLocation;

      const formData = new FormData();
      values.hotel_id = values.isEligibleForHotel ? values.hotel_id : 0;
      if (image.imageData) {
        if (!image.delete) {
          formData.append("file", image.imageData);
        }
      }

      if (deletePhoto) {
        values.img = "";
      }
      values.deletePhoto = image.delete;

      if (values.auto_close_time === "") values.auto_close_time = "0";
      if (values.wait_time === "") values.wait_time = 0;
      if (values.amount_threshold === "") values.amount_threshold = 0;
      if (values.visible === "") values.visible = false;

      if (values.loc_id) {
        values.options = values.options ? serialize(values.options) : values.options;
        asyncCall = updateLocation;
      } else {
        const outputCircle = [
          { center: { lat: +values.latitude, lng: +values.longitude } },
          { radius: 1000 * 11 },
        ];
        values.options = CONSTANTS.DUMMY_VALUES;
      }
      formData.append("locationItem", JSON.stringify(values));
      formData.loc_id = values.loc_id;
      await dispatch(asyncCall(formData))
      //toast.success("Location data saved successfully");
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      console.log(err)
    }
  };

  const savePaymentData = async (values) => {
    let data = {
      mode: values.mode,
      status: values.status,
      secret_key: values.secret_key,
      public_key: values.public_key,
      endpoint_secret: values.endpoint_secret
    };
    let response = null;
    if (!paymentMethods.data) {
      data = {...data, type: "stripe"}
      response = await addNewLocationPaymentData(location.data.loc_id, data);
    } else {
      response = await updateLocationPaymentData(location.data.loc_id, data, paymentMethods.data[0].payment_method_id);
    }
    if (response) {
      toast.success("Payment data saved successfully");
      if (props.onSuccess) props.onSuccess();
    }
  };

  const deleteStripe = async () => {
    try {
      const response = await deleteLocationPaymentData(location.data.loc_id, paymentMethods.data[0].payment_method_id);
      console.log(response)
      if (response) {

        toast.success("Stripe data deleted successfully");
        await dispatch(await getAllPaymentMethods(location.data.loc_id))
      } else {
        toast.error("Failed to delete stripe data");
      }
      //if (this.props.onSuccess) this.props.onSuccess();
    } catch (error) {
      toast.error("Failed to delete stripe data");
    }
  }

  const loadParentOptions = async (term) => {
    if (term.length < 3) return
    try {
      let locations = await LocationService.getLocations(term, true);
      console.log("Data", locations)
      //callback(ViewHelper.transformedLocations(locations));
    } catch (e) {
      console.log(e)
      alert(t("could-not-load-locations"));
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue({...value, tabVal: newValue});
  };

  const handleMapChange = (e) => {
    setMarker({ lat: e.center.lat(), lng: e.center.lng() });
    setCenter({ lat: e.center.lat(), lng: e.center.lng() });
  };

  const handleValueChange = (e, field, index) => {
    setValue({...value, locationData: {...value.locationData, [field.name]: e.target.value}, placeHolders: {...value.placeHolders}});
  }

  const handleCheckChange = (e, field) => {
    setValue({...value, locationData: {...value.locationData, [field.name]: e.target.checked}, placeHolders: {...value.placeHolders}});
  }

  const handleSwitchChange = (e, checked, field) => {
    console.log("Event", e)
    console.log("Checked", checked);
    setValue({...value, locationData: {...value.locationData, [field.name]: checked}, placeHolders: {...value.placeHolders}})
  }

  const handleAutoCompleteChange = async (e, field) => {
    setValue({...value, locationData: {...value.locationData, [field.name]: e.name}, placeHolders: {...value.placeHolders}})
  }

  const handleAsyncAutoCompleteChange = async (e, newValue) => {
    setValue({...value, locationData: {...value.locationData, "hotel_id": newValue.loc_id}, placeHolders: {...value.placeHolders}})
  }

  const handleLocationSave = async () => {
    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat},${center.lng}&key=${GOOGLE_MAPS_API_KEY}`);
      const userLocation = {
        locationName: response.data.results[0].formatted_address,
        address: response.data.results[0].formatted_address,
        lat: response.data.results[0].geometry.location.lat,
        long: response.data.results[0].geometry.location.lng,
        country: '',
        state: '',
        city: '',
        streetName: '',
        streetNumber: '',
        postal_code: '',
        place_id: response.data.results[0].place_id
      }

      response.data.results[0].address_components.map((item) => {
        if (item.types.includes('country')) {
          userLocation.country = item.long_name
        }
        if (item.types.includes('administrative_area_level_1')) {
          userLocation.state = item.long_name
        }
        if (item.types.includes('locality')) {
          userLocation.city = item.long_name
        }
        if (item.types.includes('route')) {
          userLocation.streetName = item.long_name
        }
        if (item.types.includes('street_number')) {
          userLocation.streetNumber = item.long_name
        }
        if (item.types.includes('postal_code')) {
          userLocation.postal_code = item.long_name
        }
      })

      console.log(userLocation)
      setValue({...value, locationData: {...value.locationData, latitude: userLocation.lat, longitude: userLocation.long, formattedAddress: userLocation.address}, placeHolders: {...value.placeHolders}});
    } catch (err) {
      console.log(err)
    }
  }

  const handleDataSubmit = async () => {
    if (value.locationData.currency_id === null || value.locationData.currency_id === '' || value.locationData.currency_id === undefined) {
      toast.error("Please select currency");
      return
    }

    if (value.locationData.name === null || value.locationData.name === '' || value.locationData.name === undefined) {
      toast.error("Please, Enter a Location Name");
      return
    }

    if (value.locationData.location_email === null || value.locationData.location_email === '' || value.locationData.location_email === undefined) {
      toast.error("Please, Enter a Location Email");
      return
    }

    if (value.tabVal !== 7) {
      let values = {
        loc_id: location.data.loc_id,
        name: value.locationData.name,
        formattedAddress: value.locationData.formattedAddress,
        city: value.locationData.city,
        country: value.locationData.country,
        state: value.locationData.state,
        location_telephone: value.locationData.location_telephone,
        whatsapp_number: value.locationData.whatsapp_number,
        location_email: value.locationData.location_email,
        currency_id: value.locationData.currency_id,
        postal_code: value.locationData.postal_code,
        img: value.locationData.img,
        category_name: value.locationData.category_name,
        latitude: value.locationData.latitude,
        longitude: value.locationData.longitude,
        delivery_radius: value.locationData.delivery_radius,
        delivery_fee: value.locationData.delivery_fee,
        decimal_places: value.locationData.decimal_places,
        description: value.locationData.description,
        isEligibleForHotel: value.locationData.isEligibleForHotel,
        tax_label: value.locationData.tax_label,
        tax_percent: value.locationData.tax_percent,
        tax_rate: value.locationData.tax_rate,
        wait_time: value.locationData.wait_time,
        auto_close_time: value.locationData.auto_close_time,
        otp_refresh_time: value.locationData.otp_refresh_time,
        timezone: value.locationData.timezone,
        use_otp: value.locationData.use_otp,
        dine_in: value.locationData.dine_in,
        takeaway: value.locationData.takeaway,
        delivery: value.locationData.delivery,
        is_locally_treat: value.locationData.is_locally_treat,
        min_delivery_amount: value.locationData.min_delivery_amount,
        amount_threshold: value.locationData.amount_threshold,
        hotel_id: value.locationData.hotel_id,
        visible: value.locationData.visible,
        reservation: value.locationData.reservation,
        combine_items: value.locationData.combine_items
      }
      await saveLocation(values)
    } else {
      let values = {
        public_key: value.locationData.public_key,
        secret_key: value.locationData.secret_key,
        endpoint_secret: value.locationData.endpoint_secret,
        mode: value.locationData.mode,
        status: value.locationData.status
      }

      await savePaymentData(values)
    }
  }

  const handleDeleteImage = () => {
    setImage({imageFromBackend: false, delete: true, image: null, imageData: null})
  }

  return (loading || countries.loading || categories.loading || currencies.loading || paymentMethods.loading) ? (
      <SpinnerComponent />
  ) : (
      <>
        <Box sx={{
          marginTop: '1rem',
          bgcolor: "background.paper",
          height: "auto",
          flexGrow: 1,
          display: {xs: 'block', md: 'flex'}
        }}>
          <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value.tabVal}
              onChange={handleTabChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                minWidth: 200,
              }}
              TabIndicatorProps={{ style: { backgroundColor: "green" } }}
          >
            {fields.map((label, index) => (
                (
                    <Tab
                        {...a11yProps(label.allyProp)}
                        key={index}
                        label={label.panel_name}
                        sx={{
                          bgcolor: value.tabVal === index ? "#28c76f" : "transparent",
                          color: value.tabVal === index ? "white" : "inherit",
                          border: `1px solid #28c76f`,
                          marginY: '0.25rem',
                          borderRadius: '5px',
                          "&.Mui-selected": {
                            color: "white",
                          },
                        }}
                    />
                )
            ))}
          </Tabs>

          {fields.map((panel, index)=> (
              <TabPanel value={value.tabVal} index={index} key={index}>
                <Card elevation={4} sx={{padding: '2rem'}}>
                  <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography sx={{ fontSize: "1.05rem", fontWeight: '800' }}>{t(panel.label)}</Typography>
                    {index === 7 && <IconButton onClick={()=> deleteStripe()}>
                      <DeleteForever color='error' sx={{height: '25px', width: '25px'}}/>
                    </IconButton>}
                  </Stack>
                  <CardContent sx={{padding: '0 0 0 0', display: 'flex', columnGap: '1rem', '&.MuiCardContent-root': {paddingBottom: '0px'}}}>
                    <Stack rowGap='1rem' sx={{width: '100%'}}>
                      <Stack direction='row' sx={{columnGap: '0.5rem'}}>
                        { index !== 1 ? (
                            <Grid container spacing={index === 8 ? 0 : 3} sx={{marginTop: '1rem', width: (index === 8 ? '50%' : '100%')}}>
                              {panel.field.map((field, fieldIndex) => (
                                  <Grid item key={field.name} xs={12} sm={12} md={field.width === 'full' ? 12 : field.width === 'third' ? 4 : 6}>
                                    {((index === 8 && (fieldIndex !== 0 && fieldIndex !== 1 && fieldIndex !== 2 && fieldIndex !== 3 && fieldIndex !== 4 && fieldIndex !== 5) || (index === 6 && (fieldIndex !== 1))) || (index !== 8 && index !== 6)) && <Typography variant='body2' sx={{ fontSize: '0.9rem', marginTop: (index === 8 ? '1rem' : '0')}}>{t(field.label)}{(index === 3 || (index === 0 && (fieldIndex === 0 || fieldIndex === 1))) && '*'}</Typography>}
                                    {field.type === 'text' ?
                                        <OutlinedInput
                                            onChange={(e)=> handleValueChange(e, field, fieldIndex)}
                                            size="small"
                                            fullWidth
                                            placeholder={`${value?.locationData[field.name]}`}
                                        >

                                        </OutlinedInput> : field.type === 'autocomplete' ?
                                            <Autocomplete
                                                value={{name: value.locationData[field.name]}}
                                                size={'small'}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} value={value.locationData[field.name]} />}
                                                options={timezoneData}
                                                getOptionLabel={(option) => option.name || ""}
                                                onChange={(e, value)=> handleAutoCompleteChange(value, field)}/>
                                            : field.type === 'maps' ?
                                                <Box sx={{height: '50vh', position: 'relative'}}>
                                                  <GoogleMapReact
                                                      apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                                                      defaultCenter={center}
                                                      defaultZoom={zoom}
                                                      onDrag={(e)=> handleMapChange(e)}
                                                  >
                                                    {marker && (
                                                        <AnyReactComponent lat={center.lat} lng={center.lng}/>
                                                    )}
                                                  </GoogleMapReact>
                                                  <Button sx={{ textTransform: 'capitalize', fontSize: '0.9rem', position: 'absolute', bottom: '0.5rem', left: '0.5rem', backgroundColor: '#28c76f', 'hover:': { backgroundColor: '#28c76f' } }} variant='contained' onClick={handleLocationSave}>Set Pin</Button>
                                                </Box>
                                                : field.type === 'textarea' ?
                                                    <OutlinedInput
                                                        multiline
                                                        minRows={4}
                                                        fullWidth
                                                        onChange={(e)=> handleValueChange(e, field, fieldIndex)}
                                                        placeholder={`${value.locationData[field.name]}`} />

                                                    : field.type === 'select' ?
                                                        <Select size='small' fullWidth variant={'outlined'}
                                                                onChange={(e) => handleValueChange(e, field, fieldIndex)}
                                                                value={
                                                                    ((index === 0 && fieldIndex === 7) && value.locationData.country) ||
                                                                    ((index === 0 && fieldIndex === 8) && value.locationData.category_name) ||
                                                                    ((index === 3) && value.locationData.currency_id) ||
                                                                    ((index === 7 && fieldIndex === 3) && value.locationData.mode || "") ||
                                                                    ((index === 7 && fieldIndex === 4) && value.locationData.status || "")
                                                                }
                                                         >
                                                          {(index === 0 && fieldIndex === 7) && (
                                                              (countries && countries.data && countries.data.length > 0) &&
                                                              countries.data.map((country, countryIndex) => (
                                                                  <MenuItem key={countryIndex} value={country.country_name}>{country.country_name}</MenuItem>
                                                              ))
                                                          )}
                                                          {(index === 0 && fieldIndex === 8) && (
                                                              (categories && categories.data && categories.data.length > 0) &&
                                                              categories.data.map((category, categoryIndex) => (
                                                                  <MenuItem key={categoryIndex} value={category}>{category}</MenuItem>
                                                              ))
                                                          )})
                                                          {(index === 3 && (
                                                              (currencies && currencies.data && currencies.data.length > 0) &&
                                                              currencies.data.map((currency, currencyIndex)=> (
                                                                  <MenuItem key={currencyIndex} value={currency.currency_id}>{currency.currency_code} - {currency.currency_name}</MenuItem>
                                                              ))
                                                          ))}
                                                          {(index === 7 && fieldIndex === 3) && (
                                                              ['live', 'sandbox'].map((mode, modeIndex) => (
                                                                  <MenuItem key={modeIndex} value={mode} sx={{textTransform: 'capitalize'}}>{mode}</MenuItem>
                                                              ))
                                                          )}
                                                          {(index === 7 && fieldIndex === 4) && (
                                                              ['active', 'inactive'].map((status, statusIndex) => (
                                                                  <MenuItem key={statusIndex} value={status} sx={{textTransform: 'capitalize'}}>{status}</MenuItem>
                                                              ))
                                                          )}
                                                        </Select> : ((field.type === 'switch') && (((index === 8 && (fieldIndex === 0 || fieldIndex === 1 || fieldIndex === 2 || fieldIndex === 4)) && role === 'Administrator')) || (index === 8 && (fieldIndex === 3 || fieldIndex === 4)) || (index === 6)) ?
                                                            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'start', columnGap: '0.25rem'}}>
                                                              <Switch color='success' size='small' checked={value.locationData[field.name]} onChange={(e, checked)=> handleSwitchChange(e, checked, field)}/>
                                                              <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>{t(field.label)}</Typography>
                                                            </Stack>
                                                            : field.type === 'checkbox' ?
                                                                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'start', columnGap: '0.5rem'}}>
                                                                  <Typography variant='body2' sx={{fontSize: '0.9rem'}}>{ t(field.label) }</Typography>
                                                                  <Checkbox onClick={(e)=> handleCheckChange(e, field)} checked={value.locationData[field.name]} />
                                                                </Stack>
                                                                : <></>
                                    }
                                  </Grid>
                              ))}
                            </Grid>) : (
                            location?.data?.loc_id && (
                                <Stack sx={{overflow: 'hidden', position: 'relative', alignItems: 'center', justifyContent: 'center'}}>
                                  <Typography variant='h6' sx={{fontSize: '0.9rem', alignSelf: 'start'}}>Image</Typography>
                                  {!image.image ?
                                      <Button
                                          component="label"
                                          sx={{fontSize: '1rem', textTransform: 'capitalize', borderColor: green[400], '&:hover': {borderColor: green[800]}, color: green[400]}}
                                          role={undefined}
                                          variant="outlined"
                                          onChange={(e)=> handleImageUpload(e)}
                                          startIcon={<CloudUpload sx={{color: green[400]}} />}
                                      >
                                        Upload Image
                                        <VisuallyHiddenInput type="file" />
                                      </Button> :
                                      <>
                                        <Box component='img' src={(!image.imageFromBackend ? image.image : `${CONSTANTS.RESTAURANT_URL}${image.image}`)} alt={'image'} sx={{height: '150px', width: '150px'}} />
                                        <Button
                                            component="label"
                                            sx={{backgroundColor: green[500], '&:hover': {backgroundColor: green[800]}, fontSize: '0.8rem', textTransform: 'capitalize', position: 'absolute', bottom: '5px', zIndex: 10, padding: '0.25rem 0.5rem', borderRadius: '15px'}}
                                            role={undefined}
                                            variant="contained"
                                            onChange={(e)=> handleImageUpload(e)}
                                            startIcon={<CloudUpload />}>
                                          Change Image
                                          <VisuallyHiddenInput type="file" />
                                        </Button>
                                        <IconButton size={'small'} onClick={()=> handleDeleteImage()} sx={{position: 'absolute', top: '0', right: '0', backgroundColor: 'rgba(0,0,0,0.25)'}}>
                                          <DeleteForever color='error' />
                                        </IconButton>
                                      </>
                                  }
                                </Stack>
                            ))}
                        {
                            index === 8 && (
                                <Grid container spacing={index === 8 ? 0 : 3} sx={{marginTop: '1rem', width: (index === 8 ? '50%' : '100%'), height: 'fit-content'}}>
                                  <Grid item xs={12} md={12} sx={{height: 'fit-content'}}>
                                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'start', columnGap: '0.5rem'}}>
                                      <Switch color='success' size='small' checked={value.locationData.isEligibleForHotel} onChange={(e, checked) => handleSwitchChange(e, checked, {name: 'isEligibleForHotel'})}/>
                                      <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>{t('is-eligible')}</Typography>
                                    </Stack>
                                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'start', columnGap: '0.5rem'}}>
                                      <Switch color='success' size='small' checked={value.locationData.reservation} onChange={(e, checked) => handleSwitchChange(e, checked, {name: 'reservation'})}/>
                                      <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>{t('is-reservable')}</Typography>
                                    </Stack>
                                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'start', columnGap: '0.5rem'}}>
                                      <Switch color='success' size='small' checked={value.locationData.combine_items} onChange={(e, checked) => handleSwitchChange(e, checked, {name: 'combine_items'})}/>
                                      <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>{t('combine_items')}</Typography>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} md={12} sx={{height: 'fit-content', marginTop: '1rem'}}>
                                    <Collapse in={value.locationData.isEligibleForHotel && role === 'Administrator'} unmountOnExit >
                                      <Typography variant='body2' sx={{ fontSize: '0.9rem' }}>{t('hotel_select')}</Typography>
                                      <Autocomplete
                                          defaultValue={{loc_id: (location?.data?.Hotel?.loc_id) ? (location.data.Hotel.loc_id) : '', name: (location?.data?.Hotel?.name) ? (location.data.Hotel.name) : ''}}
                                          fullWidth
                                          size={'small'}
                                          open={open}
                                          onOpen={() => {
                                            setOpen(true);
                                          }}
                                          onClose={() => {
                                            setOpen(false);
                                          }}
                                          isOptionEqualToValue={(option, value) => option.name === value.name}
                                          getOptionLabel={(option) => `${option.loc_id} - ${option.name}`}
                                          options={options}
                                          loading={autoCompleteLoading}
                                          onChange={(event, value) => handleAsyncAutoCompleteChange(event, value)}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                          {autoCompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                          {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                  }}
                                              />
                                          )}
                                      />
                                    </Collapse>
                                  </Grid>
                                </Grid>
                            )
                        }
                      </Stack>
                      <Stack direction={'row'} sx={{alignItems: 'center', justifyContent: 'start', columnGap: '0.5rem'}}>
                        <Button sx={{color: 'white', width: 'fit-content', bgcolor: "#28c76f", '&:hover': {bgcolor: '#28c76f'}}} onClick={()=> handleDataSubmit()}>Save</Button>
                        {role === 'Administrator' && <Button sx={{marginLeft: '1.5rem', color: 'white', width: 'fit-content', bgcolor: red[400], '&:hover': {bgcolor: red[400]}}} onClick={()=> setConfirmationDialog({ open: true, confirm: false })}>Delete</Button>}
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </TabPanel>
          ))}
        </Box>

        <Modal open={confirmationDialog.open} onClose={()=> setConfirmationDialog({...confirmationDialog, open: false})} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Stack sx={{padding: '1rem', rowGap: '0.5rem', borderRadius: '5px', backgroundColor: 'white', width: '300px', maxWidth: '300px'}}>
            <Typography variant='h6' sx={{fontWeight: '600', fontSize: '0.9rem'}}>
              Confirm Deletion
            </Typography>
            <Typography variant='body2' sx={{fontWeight: '400', fontSize: '0.8'}}>
              You are about to delete the hotel/restaurant. Please, confirm that you really want to delete?
            </Typography>
            <Stack direction='row' columnGap={'0.5rem'}>
              <Button onClick={()=> {setConfirmationDialog({ open: false, confirm: true }); handleDelete()}} sx={{color: 'white',textTransform: 'capitalize', fontSize: '0.8rem', backgroundColor: red[400], '&:hover': {backgroundColor: red[400]}}}>
                Delete
              </Button>
              <Button onClick={()=> setConfirmationDialog({ open: false, confirm: false })} sx={{color: 'white',textTransform: 'capitalize', fontSize: '0.8rem', backgroundColor: '#28c76f', '&:hover': {backgroundColor: '#28c76f'}}}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Modal>
      </>
  );
};

export default reduxForm({
  form: "locationForm",
  enableReinitialize: true,
})(LocationForm);
