import React, { useEffect, useState } from "react";
import ImageHelper from "../../../helpers/ImageHelper";
import { Button, Modal, ModalBody } from "reactstrap";
import { X } from "react-feather";

const PdfViewModal = (props) => {
  useEffect(() => {});
  const [pdf, setPdf] = useState();
  let { isOpen, toggleModal } = props;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <ModalBody
          style={{ width: "700px", height: "0px", position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              left: "470px",
              // top: "0",
              // right: "0",
              // marginRight: "5px",
              // marginTop: "5px",
            }}
          >
            <Button.Ripple
              style={{ width: 20, height: 20 }}
              onClick={() => {
                setPdf(null);
                props.toggleModal(null);
              }}
              className="m-0 p-0"
              color="danger"
            >
              <X size={14} />
            </Button.Ripple>
          </div>
        </ModalBody>

        <div className="d-none d-sm-flex flex-space-between full-width">
          <div
            style={{
              margin: "auto",
              display: "flex",
              minHeight: "700px",
              minWidth: "700px",
            }}
          >
            {/* <iframe
              src={`https://me-qr.com/assets/js/pdfjs/web/viewer.html?file=https://phpstack-920157-3520511.cloudwaysapps.com/restaurant_data/${props.selectedPdf}#toolbar=0&navpanes=0&scrollbar=0`}
              frameBorder="0"
              height="inherit"
              width="100%"
            ></iframe> */}
            <iframe
              src={`https://me-qr.com/assets/js/pdfjs/web/viewer.html?file=https://server.servall.be/restaurant_data/${props.selectedPdf}#toolbar=0&navpanes=0&scrollbar=0`}
              frameBorder="0"
              width="69%"
              height="auto"
              title="pdf"
              style={{ margin: "10px" }}
            ></iframe>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default PdfViewModal;
