import CONSTANTS from "../../../constants";

export function allRooms(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.ROOMS.GET_ALL) {
    return { data: action.rooms, loading: action.loading };
  }
  return state;
}

export function roomsByLocation(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.ROOMS.GET_ROOM_BY_LOC) {
    console.log("redux room value", action);
    return { data: action.rooms, loading: action.loading };
  }
  return state;
}

export function roomById(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.ROOMS.GET_ROOM_BY_ID) {
    return { data: action.room, loading: action.loading };
  }
  return state;
}

export function allSessionrooms(state = { rooms: [], loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.ROOMS.ALL_SESSIONS_ROOMS)
    return { rooms: action.rooms, loading: action.loading };
  return state;
}

export function deleteRoom(
  state = { allRooms: { data: [] }, roomById: { data: null, loading: true } },
  action
) {
  switch (action.type) {
    case CONSTANTS.ACTIONS.ROOMS.DELETE_ROOM:
      // Remove the deleted room from the list of all rooms
      const updatedRooms = state.allRooms.data.filter(
        (room) => room.room_id !== action.roomId
      );

      return {
        ...state,
        allRooms: {
          ...state.allRooms,
          data: updatedRooms,
        },
      };

    default:
      return state;
  }
}
