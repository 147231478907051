import LoginService from "./LoginService";
import CONSTANTS from "../constants";
import axios from "axios";

export const getAllReservations = async () => {
    let {token} = LoginService.getToken();
    try {
        const response = await axios.get(
            `${CONSTANTS.API_URL}${CONSTANTS.URLS.RESERVATIONS.GET_ALL_RESERVATIONS}`,
            {headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token }}
        )

        if (!response || !response.data) {
            console.log(response)
            return [];
        } else {
            return response.data
        }
    } catch (err) {
        console.log(err)
        return [];
    }
}

export const addNewReservation = async (loc_id, data) => {
    try {
        const response = await axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RESERVATIONS.ADD_NEW_RESERVATION(loc_id)}`, {...data}, {
            headers: {
                ...CONSTANTS.REQUEST_HEADERS
            }
        })
        if (!response || !response.data) {
            console.log("Reservation Error", response)
            return ({error: true, message: 'Cannot perform Reservation'})
        } else {
            return ({error: false, data: response.data})
        }
    } catch (err) {
        console.log(err)
        return ({error: true, message: 'Cannot perform Reservation'})
    }
}

export const updateReservation = (loc_id, reservation_id, data) => {
    let {token} = LoginService.getToken();
    try {
        const response = axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RESERVATIONS.UPDATE_RESERVATION(loc_id, reservation_id)}`, {data}, {
            headers: {
                ...CONSTANTS.REQUEST_HEADERS,
                "X-ACCESS-TOKEN": token
            }
        })
        if (!response || !response.data) {
            console.log("Reservation Error", response)
            return ({error: true, message: 'Cannot perform Reservation Update'})
        } else {
            return ({error: false, data: response.data})
        }
    } catch (err) {
        console.log(err)
        return ({error: true, message: 'Cannot perform Reservation Update'})
    }
}

export const deleteReservation = async (reservation_id) => {
    let {token} = LoginService.getToken();
    try {
        const response = await axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RESERVATIONS.DELETE_RESERVATION(reservation_id)}`, null,
            {
                headers: {
                    ...CONSTANTS.REQUEST_HEADERS,
                    "X-ACCESS-TOKEN": token
                }
            })

        if (!response || !response.data) {
            console.log("Reservation Error", response)
            return ({error: true, message: 'Cannot perform Reservation Delete'})
        } else {
            return ({error: false, data: response.data})
        }
    } catch (err) {
        console.log(err)
        return ({error: true, message: 'Cannot perform Reservation Delete'})
    }
}

export const updateReservationStatus = async (status, reservation_id) => {
    let {token} = LoginService.getToken();
    console.log(token)
    try {
        const response = await axios.post(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RESERVATIONS.UPDATE_RESERVATION_STATUS(status, reservation_id)}`, {},
            {
                headers: {
                    ...CONSTANTS.REQUEST_HEADERS,
                    "X-ACCESS-TOKEN": token
                }
            })
        if (!response || !response.data) {
            console.log("Reservation Error", response)
            return ({error: true, message: 'Cannot perform Reservation Update'})
        } else {
            return ({error: false, data: response.data})
        }
    } catch (err) {
        console.log(err)
        return ({error: true, message: 'Cannot perform Reservation Update'})
    }
}