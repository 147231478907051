import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import { getAllStaff, changeStaffStatus } from "../../redux/actions/staff";

import "../../assets/scss/pages/page-pricing.scss";
import ListStaff from "./ListStaff";
import { Button } from "reactstrap";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
// import { withTranslation } from "react-i18next";
// import i18n from "i18next";
// import Swal from "sweetalert2";

class Staff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: "",
      selectableFields: [],
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: "app-users",
        header: "app-users",
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: "app-users",
        header: "app-users",
      },
    };
  }

  async componentDidMount() {
    // const savedLanguage = localStorage.getItem("lang");
    // if (savedLanguage) {
    //   i18n.changeLanguage(savedLanguage);
    // }

    await this.props.getAllStaff();
  }

  handleChange = (state) => {
    let staff = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      staff.push(state.selectedRows[i].staff_id);
    }
    this.setState({ staff: staff }, () => {
      // Log selected staff details to the console
      console.log("Selected Staff Details:", state.selectedRows);
    });
    this.setState({ selectableFields: state.selectedRows });
  };

  // async updateAccountStatus() {
  //   if (this.state.staff.length > 0) {
  //     this.showConfirmationDialog(
  //       async () => {
  //         await this.props.changeStaffStatus(this.state.staff);
  //       },
  //       null,
  //       this.props.t("are-you-sure-you-want-to-delete-this-account"),
  //       this.props.t("accounts")
  //     );
  //   } else {
  //     this.showErrorDialog(
  //       null,
  //       this.props.t("please-select-atleast-one-account-for-change-status"),
  //       this.props.t("warning")
  //     );
  //   }
  // }
  async updateAccountStatus() {
    if (this.state.staff.length > 0) {
      let shouldUpdateStatus = true;

      for (const staff of this.state.selectableFields) {
        const staffGroupName =
          staff.StaffLocations[0].StaffGroup.staff_group_name;
        const userRole = localStorage.getItem("tableordercart_admin_role");

        console.log("Staff Group Name:", staffGroupName);
        console.log("User Role:", userRole);

        if (
          staffGroupName === "Restaurant Owner" &&
          userRole === "Restaurant Owner"
        ) {
          shouldUpdateStatus = false;
          this.showErrorDialog(
            null,
            "You cannot delete a restaurant owner",
            "warning"
          );
          break;
        }
        if (
          staffGroupName === "Administrator" &&
          userRole === "Administrator"
        ) {
          shouldUpdateStatus = false;
          this.showErrorDialog(
            null,
            "You cannot delete an Administrator",
            "warning"
          );
          break;
        }
      }

      if (shouldUpdateStatus) {
        await this.showConfirmationDialog(
          async () => {
            await this.props.changeStaffStatus(this.state.staff);
          },
          null,
          "are-you-sure-you-want-to-delete-this-account",
          "accounts"
        );
      }
    } else {
      this.showErrorDialog(
        null,
        "please-select-atleast-one-account-for-change-status",
        "warning"
      );
    }
  }

  // async updateAccountStatus() {
  //   if (this.state.staff.length > 0) {
  //     let shouldLogout = false;

  //     // Assuming this.state.selectableFields is an array
  //     for (const staff of this.state.selectableFields) {
  //       const staffGroupName = staff.StaffLocations[0].StaffGroup.staff_group_name;
  //       const getRole = localStorage.getItem("tableordercart_admin_role");

  //       console.log("Staff Group Name:", staffGroupName);
  //       console.log("GETROLE", getRole);

  //       if (staffGroupName === "Restaurant Owner" && getRole === "Restaurant Owner") {
  //         shouldLogout = true; // Set to true to trigger logout redirection
  //         break; // Exit the loop since we already found a match
  //       }
  //     }

  //     // Call the changeStaffStatus function
  //     this.showConfirmationDialog(
  //       async () => {
  //         await this.props.changeStaffStatus(this.state.staff);

  //         if (shouldLogout) {
  //           setTimeout(() => {
  //             this.props.history.push("/logout");
  //           }, 1500);
  //         }
  //       },
  //       null,
  //       this.props.t("are-you-sure-you-want-to-delete-this-account"),
  //       this.props.t("accounts")
  //     );
  //   } else {
  //     this.showErrorDialog(
  //       null,
  //       this.props.t("please-select-atleast-one-account-for-change-status"),
  //       this.props.t("warning")
  //     );
  //   }
  // }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }

  editStaff(id) {
    this.props.history.push("/staff/edit/" + id);
  }
  assignRole(id) {
    this.props.history.push("/staff/assign/" + id);
  }
  render() {
    // const { t } = this.props;
    const {
      props: { staff, loading },
    } = this;

    //     console.log("STAFFFFFFFFFFF", this.state.selectableFields);

    // // Assuming this.state.selectableFields is an array
    // this.state.selectableFields.forEach((staff) => {
    //   const staffGroupName = staff.StaffLocations[0].StaffGroup.staff_group_name;
    //   console.log("Staff Group Name:", staffGroupName);
    // });
    // const getRole = localStorage.getItem("tableordercart_admin_role");
    // console.log("GETROLE", getRole);

    const {
      state: { confirmationDialog, errorDialog },
    } = this;
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div id="pricing-table">
          <Button
            className="mb-1"
            color="primary"
            size="md"
            onClick={() => this.props.history.push("/staff/new")}
          >
            <span className="ml-1 mr-1 d-md-inline">New</span>
          </Button>
          <Button
            className="ml-1 mb-1"
            color="danger"
            size="md"
            onClick={() => this.updateAccountStatus()}
          >
            <span className="ml-1 mr-1 d-md-inline"> Delete </span>
          </Button>
          {!loading && staff ? (
            <ListStaff
              staff={staff}
              handleChange={this.handleChange.bind(this)}
              editStaff={this.editStaff.bind(this)}
              assignRole={this.assignRole.bind(this)}
            />
          ) : null}
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle="cancel"
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle="ok"
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    staff: {
      allstaff: { data: staff, loading: loading },
    },
  } = state;

  return { staff, loading: loading };
}

export default connect(mapStateToProps, { getAllStaff, changeStaffStatus })(
  Staff
);
