import CONSTANTS from "../../../../constants";

export function customerData(state = null, action) {
    if (action.type === CONSTANTS.ACTIONS.POS.SAVE_CUSTOMER_DATA) {
        return { ...action.data };
    }

    return state
}

export function customerType(state = "walk-in", action) {
    if (action.type === CONSTANTS.ACTIONS.POS.CUSTOMER_TYPE) {
        return action.data
    }

    return state
}

export function clearCustomerData(state = false, action) {
    if (action.type === CONSTANTS.ACTIONS.POS.CLEAR_SELECTED_USER) {
        return action.data
    }

    return state
}