import CONSTANTS from '../../../constants';
import PackageProductCategoryService from '../../../services/PackageProductCategoryService';

export function getAllPackageProductCategory() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.STAFF.ALL_PACKAGE_PRODUCT_CATEGORY,
        packageProductCategoryList: [],
        loading: true,
      });
      let packageProductCategories =
        await PackageProductCategoryService.getPackageProductCategory();
      dispatch({
        type: CONSTANTS.ACTIONS.STAFF.ALL_PACKAGE_PRODUCT_CATEGORY,
        packageProductCategoryList: packageProductCategories,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.STAFF.ALL_PACKAGE_PRODUCT_CATEGORY,
        packageProductCategoryList: [],
        loading: false,
      });
    }
  };
}
