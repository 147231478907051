import React, {useState} from "react";
import {Box, Button, Grid, OutlinedInput, Stack, Typography} from "@mui/material";
import CloudUpload from "@mui/icons-material/CloudUpload";
import {styled} from "@mui/material/styles";
import {addNewPremiumService, updatePremiumServices} from "../../services/PremiumServices";
import toast from "react-hot-toast";
import {useLocation} from "react-router-dom";

const fields = [
    {
        label: 'Title',
        name: 'title',
        db_label: 'service_title',
        type: 'text',
        placeholder: 'Please, Add a Title',
        multiline: false,
    },

    {
        label: 'Link',
        name: 'link',
        db_label: 'service_link',
        type: 'text',
        placeholder: 'Please, Add a Link',
        multiline: false,
    },

    {
        label: 'Description',
        name: 'description',
        db_label: 'service_desc',
        type: 'text',
        placeholder: 'Please, Add a Description',
        multiline: true,
    }
]

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AddNewPremiumService = () => {
    const location = useLocation();
    const data = location.state?.location

    const [serviceData, setServiceData] = useState({
        service_title: '',
        service_desc: '',
        service_link: '',
        image: null,
        imageData: null
    });

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (ev) => {
                setServiceData({...serviceData, image: ev.target.result, imageData: file});
            };
            fileReader.readAsDataURL(file);
        }
    };


    const handleValueChange = (e, field, index) => {
        setServiceData({...serviceData, [field.db_label]: e.target.value})
    }

    const handleSaveService = async () => {
        let formData = new FormData();
        if (serviceData.imageData) {
            formData.append("file", serviceData.imageData);
        }

        formData.append("service_title", serviceData.service_title);
        formData.append("service_desc", serviceData.service_desc);
        formData.append("service_link", serviceData.service_link);

        const response = await addNewPremiumService(data.loc_id, formData);
        if (response.error) {
            toast.error(response.message);
        } else {
            toast.success('Premium Service Added Successfully');
            setServiceData({
                service_title: '',
                service_desc: '',
                service_link: '',
                image: null,
                imageData: null
            })
        }
    }

    return(
        <>
            <Typography variant='h5' sx={{fontWeight: '600', marginBottom: '1rem'}}>New Premium Service</Typography>
            <Stack sx={{alignItems: 'start', justifyContent: 'start', rowGap: '1rem'}}>
                <Grid container spacing={2}>
                    {fields.map((field, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <Typography variant='h6' sx={{fontSize: '0.9rem'}}>{field.label}</Typography>
                            <OutlinedInput
                                value={serviceData[field.db_label]}
                                onChange={(e)=> handleValueChange(e, field, index)}
                                size="small"
                                fullWidth
                                placeholder={field.placeholder}
                                multiline={field.multiline}
                                rows={4}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Stack sx={{overflow: 'hidden', position: 'relative', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='h6' sx={{fontSize: '0.9rem', alignSelf: 'start'}}>Image</Typography>
                    {!serviceData.image ? <Button
                            component="label"
                            sx={{fontSize: '1rem', textTransform: 'capitalize'}}
                            role={undefined}
                            variant="outlined"
                            onChange={(e)=> handleImageUpload(e)}
                            startIcon={<CloudUpload />}
                        >
                            Upload Image
                            <VisuallyHiddenInput type="file" />
                        </Button> :
                        <>
                            <Box component='img' src={serviceData.image} alt={'image'} sx={{height: '150px', width: '150px'}} />
                            <Button
                                component="label"
                                sx={{fontSize: '0.8rem', textTransform: 'capitalize', position: 'absolute', bottom: '5px', zIndex: 10, padding: '0.25rem 0.5rem', borderRadius: '15px'}}
                                role={undefined}
                                variant="contained"
                                onChange={(e)=> handleImageUpload(e)}
                                startIcon={<CloudUpload />}>
                                Change Image
                                <VisuallyHiddenInput type="file" />
                            </Button>
                        </>
                    }
                </Stack>
                <Button sx={{ textTransform: 'capitalize', fontSize: '0.9rem', backgroundColor: '#28c76f', '&:hover': { backgroundColor: '#28c76f' } }} variant='contained' onClick={handleSaveService}>Add Service</Button>
            </Stack>
        </>
    )
}

export default AddNewPremiumService;