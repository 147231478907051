import CONSTANTS from "../../../constants";
import NotificationService from "../../../services/NotificationService";

export function getAllNavBarNotifications(loc_id, page) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.NOTIFICATION.GET_ALL,
        notifications: [],
        loading: true,
      });
      let notifications = await NotificationService.getAllNotificationForWeb(
        loc_id,
        page
      );
      dispatch({
        type: CONSTANTS.ACTIONS.NOTIFICATION.GET_ALL,
        notifications,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.NOTIFICATION.GET_ALL,
        notifications: [],
        loading: false,
      });
    }
  };
}

export function updateNavBarNotificationStatus(loc_id, notification_id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.NOTIFICATION.GET_ALL,
        notifications: [],
        loading: true,
      });
      let notifications =
        await NotificationService.updateNavBarNotificationStatus(
          loc_id,
          notification_id
        );
      let notification = await NotificationService.getAllNotificationForWeb(
        loc_id
      );
      dispatch({
        type: CONSTANTS.ACTIONS.NOTIFICATION.GET_ALL,
        notifications,
        notification,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.NOTIFICATION.GET_ALL,
        notifications: [],
        loading: false,
      });
    }
  };
}
