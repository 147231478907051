import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import LocationService from "../../services/LocationService";
import { Button } from "reactstrap";
import toast from "react-hot-toast";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";

import EventsService from "../../services/EventsService";

export default function UpcomingEvents() {
  const [title, setTitle] = useState("");
  const [model, setModel] = useState([]);
  const [selectLocation, setSelectLocation] = useState(false);
  const [editorLoading, setEditorLoading] = useState(true);

  const handleEditorChange = (content, editor) => {
    console.log("Content was updated:", content);
    setModel(content);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loc = LocationService.getLocation();
        console.log("LOCATION DATA", loc);
        setSelectLocation(loc);
      } catch (e) {
        console.log(e);
      } finally {
        setEditorLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, []);

  const handleSubmit = async () => {
    try {
      if (!title || !model) {
        toast.error("Please fill in both the title and description fields.");
        return;
      }

      await EventsService.createUpcomingEvent(
        title,
        model,
        selectLocation.loc_id
      );

      toast.success("Event saved successfully");
      setTitle("");
      setModel("");
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Failed to save event data");
    }
  };

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <label
          htmlFor="eventTitle"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          Event Title:
        </label>
        <input
          id="eventTitle"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter event title"
          style={{ width: "100%", padding: "10px", fontSize: "18px" }}
        />
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label
          htmlFor="eventDescription"
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          Event Description:
        </label>
        {editorLoading ? (
          <SpinnerComponent />
        ) : (
          <Editor
            id="eventDescription"
            apiKey="1ausnzpznit2slgbdbs476puc2wt1bllt99rw6d6zvjflaqh"
            init={{
              plugins:
                " autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough forecolor removeformat backcolor | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
              menubar: false,
            }}
            onInit={() => setEditorLoading(false)}
            onEditorChange={handleEditorChange}
          />
        )}
      </div>

      <Button
        className="bg-primary"
        onClick={handleSubmit}
        style={{
          float: "right",
          borderRadius: "5px",
          padding: "10px 20px",
          color: "#fff",
          border: "none",
          cursor: "pointer",
        }}
      >
        Submit
      </Button>
    </>
  );
}
