import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Printer } from "react-feather";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import OrderService from "../../services/OrderService";
import ViewHelper from "../../helpers/ViewHelper";
import TimeHelper from "../../helpers/TimeHelper";
import CONSTANTS from "../../constants";
import "../../assets/css/orders.scss";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class PrintOrderSession extends Component {
  state = {
    loading: true,
    orderSessionDetail: null,
    orderMenuTax: null,
  };
  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    let location_id = this.props.location_id;
    let sessionId = this.props.sessionId;
    let sessionDetail = await OrderService.getSessionsDetail(
      location_id,
      sessionId
    );
    this.setState({
      orderSessionDetail: sessionDetail.sessionInfo,
      loading: false,
      orderMenuTax: sessionDetail.order_menu_tax,
    });
    console.log(sessionDetail);
  }

  renderOrderDetailViewTop(session) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        id="order-heading"
      >
        <div className="text-uppercase">
          <p>{this.props.t("session-detail")}</p>
        </div>
        <p>
          {TimeHelper.toFormattedDate(
            session?.session_date,
            "ddd,Do MMM, YYYY"
          )}
        </p>
        <div className="pt-1">
          {this.props.t("start-time-1")}: {session?.start_time}{" "}
          {this.props.t("end-time-1")}: {session?.end_time}
        </div>
        <div className="pt-1">
          <p>
            {this.props.t("session-0")}
            {session?.session_id}{" "}
          </p>
        </div>
      </div>
    );
  }
  calculateTotalPriceOptions = (menuPrice, taxPercent) => {
    console.log("tax percent", taxPercent);
    console.log("menuPrice", menuPrice);
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice = itemPrice + (itemPrice * tax) / 100;
    // const totalPrice = itemPrice + tax;
    console.log("TOTAL_PRICE", totalPrice);
    return totalPrice;
  };

  renderMenuDetails(order, orderMenuTax) {
    console.log("order", order);
    // const orderTotals = order.OrderTotals;
    // let startIndex = 0;
    // let subTotal = orderTotals[startIndex++].value;
    let discounts = 0;
    // if (orderTotals.length > 3) {
    //   discounts = orderTotals[startIndex++].value;
    //   subTotal += parseInt(discounts);
    // }

    // const tax = orderTotals[startIndex++].value;
    // const totals = orderTotals[startIndex].value;
    let subTotal = 0;
    let tax = 0;
    let totals = 0;
    return (
      <Card
        style={{ margin: "0px !important", padding: "0px !important" }}
        className="w-100"
      >
        <CardHeader className="mx-auto font-weight-bold">
          {this.props.t("order-items")}{" "}
        </CardHeader>
        <CardBody>
          {order?.Orders.length > 0
            ? order.Orders.map((singleorder) => {
                const orderTotals = singleorder.OrderTotals;
                orderTotals.map((order_totals) => {
                  if (order_totals?.code === "cart_total") {
                    subTotal += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "discount") {
                    subTotal += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "tax") {
                    tax += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "order_total") {
                    totals += parseFloat(order_totals?.value);
                  }
                });
                // if (orderTotals.length > 0) {
                //   let startIndex = 0;
                //   subTotal += orderTotals[startIndex++].value;
                //   if (orderTotals.length > 3) {
                //     discounts = orderTotals[startIndex++].value;
                //     subTotal += parseInt(discounts);
                //   }
                //   tax += orderTotals[startIndex++].value;
                //   totals += orderTotals[startIndex].value;
                //   console.log("TOTALSSSSS", totals);
                // }
                return singleorder.OrderMenus.map((menu) => {
                  return [
                    <div
                      className="order-item mb-1 d-flex justify-content-between"
                      key={menu.order_menu_id}
                    >
                      <div className="f2 name pr-1">
                        <b>
                          {menu.quantity}x {menu.name}
                        </b>
                        {this.renderOptions(singleorder, menu.order_menu_id)}
                      </div>
                      <div className="f2 price">
                        <strong>
                          {ViewHelper.toCurrency(
                            (parseInt(menu.quantity) *
                              menu?.price *
                              menu?.menu_tax_percentage) /
                              100 +
                              menu?.price * menu.quantity,
                            order?.Location?.Currency?.currency_code,
                            order?.Location?.decimal_places
                          )}
                          {/* {ViewHelper.toCurrency(
                          parseInt(menu.quantity) * menu.price,
                          order.Location.Currency.currency_code,
                          order.Location.decimal_places
                        )} */}
                        </strong>
                      </div>
                    </div>,
                    <div
                      className="order-item mb-3 d-flex justify-content-between"
                      key="comment"
                    >
                      {menu?.comment && (
                        <div className="f3 name pr-1">
                          {this.props.t("special-instructions")}:{" "}
                          <span className="font-italic">{menu?.comment}</span>
                        </div>
                      )}
                    </div>,
                  ];
                });
              })
            : null}
          <div className="order-item border-top pt-2 d-flex justify-content-between">
            <div className="f2 name pr-1">{this.props.t("sub-total")}</div>
            <div className="f2 price">
              {ViewHelper.toCurrency(
                subTotal,
                order?.Location?.Currency?.currency_code,
                order?.Location?.decimal_places
              )}
            </div>
          </div>

          {/* <div className="order-item d-flex justify-content-between">
                <div className="f2 name pr-1">Discounts</div>
                <div className="f2 price">
                  {ViewHelper.toCurrency(
                    -discounts,
                    order.Location.Currency.currency_code,
                    order.Location.decimal_places
                  )}
                </div>
              </div> */}
          {orderMenuTax.length > 0
            ? orderMenuTax.map((menuTax, index) => {
                return (
                  <div
                    key={index}
                    className="order-item d-flex justify-content-between"
                  >
                    <div className="f2 name pr-1">
                      {order?.Location?.tax_label}{" "}
                      {menuTax?.menu_tax_percentage}%
                    </div>
                    <div className="f2 price">
                      {ViewHelper.toCurrency(
                        menuTax?.total_menu_tax,
                        order?.Location?.Currency?.currency_code,
                        order?.Location?.decimal_places
                      )}
                    </div>
                  </div>
                );
              })
            : null}

          <div className="order-item d-flex justify-content-between border-bottom pb-2">
            <div className="f2 name pr-1">
              {order?.Location?.tax_label} {this.props.t("total-0")}
            </div>
            <div className="f2 price">
              {ViewHelper.toCurrency(
                tax,
                order?.Location?.Currency?.currency_code,
                order?.Location?.decimal_places
              )}
            </div>
          </div>

          <div className="order-item d-flex justify-content-between pt-1">
            <div className="f2 name pr-1">
              <strong>{this.props.t("total-0")}</strong>
            </div>
            <div className="f2 price">
              <strong>
                {ViewHelper.toCurrency(
                  totals,
                  order?.Location?.Currency?.currency_code,
                  order?.Location?.decimal_places
                )}
              </strong>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderOptions(order, orderMenuId) {
    let options = order.OrderOptions.filter(
      (option) => option.order_menu_id === orderMenuId
    );
    return options.map((option) => {
      return (
        <div key={option?.order_option_id} className="text-small pl-1">
          {option?.display_type ===
          CONSTANTS.VARIABLES.MENUS.OPTIONS.DISPLAY_TYPES.SELECT
            ? `${option?.quantity}x `
            : ""}
          {option.order_option_name}{" "}
          {ViewHelper.toCurrency(
            // option.order_option_price,
            this.calculateTotalPriceOptions(
              option.order_option_price,
              option.order_item_tax_percentage,
              // option.order_item_tax,
              option.quantity
            ),
            this.state.orderSessionDetail?.Location?.Currency.currency_code,
            this.state.orderSessionDetail?.Location?.decimal_places
          )}
        </div>
      );
    });
  }

  renderOrderDetails(order) {
    return (
      <Card
        style={{ margin: "0px !important", padding: "0px !important" }}
        className="w-100"
      >
        <CardHeader>{this.props.t("customer-and-order-detail")}</CardHeader>
        <CardBody>
          <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
              <strong>{this.props.t("table-name")}</strong>
            </div>
            {/* <div>{ViewHelper.getTableName(order?.table)}</div> */}
            <div>{order?.table_name}</div>
          </div>
          <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
              <strong>{this.props.t("session-status")}</strong>
            </div>
            <div>{order?.session_status?.toUpperCase()}</div>
          </div>
          <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
              <strong>{this.props.t("location-name")}</strong>
            </div>
            <div>{order?.Location?.name}</div>
          </div>
        </CardBody>
      </Card>
    );
  }

  render() {
    const { t } = this.props;
    const {
      state: { orderSessionDetail, loading, orderMenuTax },
    } = this;

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="print_body" ref={this.props.componentRef}>
          {/* <BreadCrumbs
              breadCrumbTitle="Session Order Detail"
              breadCrumbParent="Session"
              breadCrumbActive="Session detail"
            /> */}
          {this.renderOrderDetailViewTop(orderSessionDetail)}
          <Row>
            <Col md="12">
              {this.renderMenuDetails(orderSessionDetail, orderMenuTax)}
            </Col>
            <Col md="12">{this.renderOrderDetails(orderSessionDetail)}</Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withTranslation("global")(PrintOrderSession);
