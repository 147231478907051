import {itemModal} from "../../../../assets/posStyles";
import {Box, Button, Grid, IconButton, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {grey} from "@mui/material/colors";
import React from "react";
import {useSelector} from "react-redux";

const mainStackStyle = {
    height: 'fit-content',
    width: {xs: '95vw', md: '50vw', lg: '35vw'},
    minWidth: {xs: '95vw', sm: '500px', md: '550px'},
    maxWidth: {xs: '95vw', sm: '500px', md: '550px'},
    backgroundColor: 'white',
    borderRadius: '5px'
}

const modalItems = [
    "Total Items", "Subtotal", "Total", "Discount", "Tax", "Charge", "Tips"
]

const OrderTotalModal = ({openModal, setOpenModal, order, values}) => {
    const locationData = useSelector(state => state.pos.locationData);
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    console.log(order);

    return(
        <Modal open={openModal} onClose={()=> handleCloseModal()} sx={itemModal.modalStyle}>
            <Stack sx={mainStackStyle}>
                <Stack direction='row' sx={{padding: '0.5rem 1rem', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>Cart Information</Typography>
                    <IconButton size='small' onClick={()=> handleCloseModal()}>
                        <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}}/>
                    </IconButton>
                </Stack>
                <Box sx={{height: '1px', width: '100%', backgroundColor: grey[200]}} />
                <Stack sx={{rowGap: '0.5rem', padding: '1rem', overflowY: 'auto', maxHeight: '80vh', height: 'fit-content'}}>
                    <Grid container spacing={1}>
                        {
                            modalItems.map((item, index) => (
                                <Grid item xs={12} md={6} lg={4} key={index} flexDirection='row' display='flex' alignItems='center' columnGap='1rem'>
                                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '400'}}>{item}:</Typography>
                                    <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>
                                        {locationData.Currency.currency_symbol}
                                        {index === 0 ? order.OrderMenus.length : index === 1 ? (values.subTotal).toFixed(2) :
                                            index === 2 ? (values.total).toFixed(2) : index === 3 ? "0.00" :
                                                index === 4 ? (values.tax).toFixed(2) : index === 5 ? "0.00" : index === 6 ? "0.00" : "0.00"
                                    }
                                    </Typography>
                                </Grid>
                            ))}
                    </Grid>
                </Stack>
                <Box sx={{height: '1px', width: '100%', backgroundColor: grey[200]}} />
                <Stack direction='row' sx={{padding: '0.5rem 1rem', alignItems: 'center', columnGap: '1rem'}}>
                    <Button variant='contained' onClick={()=> handleCloseModal()} sx={{backgroundColor: 'black', '&:hover': {backgroundColor: grey[700]}, color: 'white', textTransform: 'capitalize'}}>Cancel</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default OrderTotalModal;