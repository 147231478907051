import React from 'react';

export default ({input, label, type, meta: { touched, error, warning }}) => {
  return (
    <div className='form-group'>
      <label htmlFor={input.name}>{label}</label>
      <input className={`form-control ${input.className ? input.className : ''}`} id={input.name} {...input} placeholder={label} type={type} />
      { touched && ((error && <small className='form-text text-danger'>{label} is {error}</small>) ||
        (warning && <small className='form-text text-muted'>{warning}</small>))}
    </div>
  );
}
