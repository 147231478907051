// import React from "react";
// import DataTable from "react-data-table-component";
// import { Check, Edit2 } from "react-feather";
// import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
// import ImageHelper from "../../helpers/ImageHelper";
// import { useEffect } from "react";
// import { useTranslation } from "react-i18next";

// const ListAllLocation = (props) => {
//   const [t, i18n] = useTranslation("global");
//   useEffect(() => {
//     const lang2 = localStorage.getItem("lang");
//     i18n.changeLanguage(lang2);
//   }, []);

//   const editLocation = (id) => {
//     props.editLocation(id);
//   };

//   const renderLocations = (locations) => {
//     console.log("locations", locations);
//     if (!locations || locations.length === 0)
//       return <h1>{t('no-app-locations-found')}</h1>;

//     return (
//       <>
//         <Col sm="12">
//           <Row>
//             {locations.map((location) => {
//               const image = ImageHelper.getImageURL(location.img);
//               return (
//                 <Col lg="3" md="6" sm="12" key={location.loc_id}>
//                   <Card>
//                     <CardHeader className="mx-auto flex-column">
//                       <h4>{location.name}</h4>
//                       <p>{location.category_name}</p>
//                     </CardHeader>
//                     <CardBody className="text-center pt-0">
//                       <div className="avatar mr-1 avatar-xl">
//                         <img src={image} alt="avatarImg" />
//                       </div>
//                       <div className="d-flex justify-content-center">
//                         <Button.Ripple
//                           className="btn-block gradient-light-primary mt-2"
//                           onClick={() => editLocation(location.loc_id)}
//                         >
//                           {t('edit')} </Button.Ripple>
//                       </div>
//                     </CardBody>
//                   </Card>
//                 </Col>
//               );
//             })}
//           </Row>
//         </Col>
//       </>
//     );
//   };
//   return renderLocations(props.locations);
// };

// export default ListAllLocation;

// import React, { useEffect } from "react";
// import DataTable from "react-data-table-component";
// import { Button } from "reactstrap";
// import { useTranslation } from "react-i18next";
// import ImageHelper from "../../helpers/ImageHelper";

// const ListAllLocation = (props) => {
//   console.log("LIST ALL LOCATIONS", props);
//   const [t, i18n] = useTranslation("global");

//   useEffect(() => {
//     const lang2 = localStorage.getItem("lang");
//     i18n.changeLanguage(lang2);
//   }, []);

//   const editLocation = (id) => {
//     props.editLocation(id);
//   };
//   const getBannerCount = (location) => {
//     return location.Banners ? location.Banners.length : 0;
//   };

//   const columns = [
//     {
//       // name: t("Restaurant Name"),

//       name:
//         props.locations.locations[0]?.is_hotel === 1
//           ? t("Hotel Name")
//           : t("Restaurant Name"),
//       selector: "name",
//       sortable: true,
//       style: { width: "150px" }, // Adjust the width as needed
//     },
//     {
//       name: t("Category"),
//       selector: "category_name",
//       sortable: true,
//       style: { width: "150px" }, // Adjust the width as needed
//     },
//     {
//       name: t("Currency"),
//       selector: "Currency?.currency_name",
//       format: (row) => row.Currency?.currency_name || t("N/A"),
//       sortable: true,
//     },

//     {
//       name: t("City"),
//       selector: "city",
//       sortable: true,
//     },

//     {
//       name: t("Country"),
//       selector: "country",
//       sortable: true,
//     },

//     {
//       name: t("Image"),
//       cell: (row) => (
//         <img
//           src={ImageHelper.getImageURL(row.img)}
//           alt="avatarImg"
//           style={{ width: "50px", height: "50px", objectFit: "contain" }}
//         />
//       ),
//     },
//     {
//       name: t("Banners"),
//       selector: (row) => getBannerCount(row),
//       sortable: true,
//     },
//     {
//       name: t("Action"),
//       cell: (row) => (
//         <Button.Ripple
//           className="btn-block gradient-light-primary"
//           onClick={() => editLocation(row.loc_id)}
//         >
//           {t("Edit")}
//         </Button.Ripple>
//       ),
//     },
//   ];

//   const noDataComponent = <h1>{t("no-app-locations-found")}</h1>;

//   return (
//     <DataTable
//       title=""
//       columns={columns}
//       data={props?.locations?.locations}
//       pagination
//       noDataComponent={noDataComponent}
//     />
//   );
// };

// export default ListAllLocation;
// import React, { useEffect } from "react";
// import DataTable from "react-data-table-component";
// import { Button } from "reactstrap";
// import { useTranslation } from "react-i18next";
// import ImageHelper from "../../helpers/ImageHelper";

// const ListAllLocation = (props) => {
//   console.log("LIST ALL LOCATIONS", props);
//   const [t, i18n] = useTranslation("global");

//   useEffect(() => {
//     const lang2 = localStorage.getItem("lang");
//     i18n.changeLanguage(lang2);
//   }, []);

//   const editLocation = (id) => {
//     props.editLocation(id);
//   };

//   const editAttachedRestaurant = (id) => {
//     // Function to edit attached restaurant, if needed
//     console.log("Edit Attached Restaurant:", id);
//   };

//   const getBannerCount = (location) => {
//     return location.Banners ? location.Banners.length : 0;
//   };

//   const columns = [
//     {
//       // name: t("Restaurant Name"),

//       name:
//         props.locations.locations[0]?.is_hotel === 1
//           ? t("Hotel Name")
//           : t("Restaurant Name"),
//       selector: "name",
//       sortable: true,
//       style: { width: "150px" }, // Adjust the width as needed
//     },
//     {
//       name: t("Category"),
//       selector: "category_name",
//       sortable: true,
//       style: { width: "150px" }, // Adjust the width as needed
//     },
//     {
//       name: t("Currency"),
//       selector: "Currency?.currency_name",
//       format: (row) => row.Currency?.currency_name || t("N/A"),
//       sortable: true,
//     },

//     {
//       name: t("City"),
//       selector: "city",
//       sortable: true,
//     },

//     {
//       name: t("Country"),
//       selector: "country",
//       sortable: true,
//     },

//     {
//       name: t("Image"),
//       cell: (row) => (
//         <img
//           src={ImageHelper.getImageURL(row.img)}
//           alt="avatarImg"
//           style={{ width: "50px", height: "50px", objectFit: "contain" }}
//         />
//       ),
//     },
//     {
//       name: t("Banners"),
//       selector: (row) => getBannerCount(row),
//       sortable: true,
//     },
//     {
//       name: t("Action"),
//       cell: (row) => (
//         <Button.Ripple
//           className="btn-block gradient-light-primary"
//           onClick={() => editLocation(row.loc_id)}
//         >
//           {t("Edit")}
//         </Button.Ripple>
//       ),
//     },
//   ];

//   const attachedRestaurantColumns = [
//     {
//       name: t("Restaurant Name"),
//       selector: "name",
//       sortable: true,
//     },
//     {
//       name: t("Currency"),
//       selector: "Currency?.currency_name",
//       format: (row) => row.Currency?.currency_name || t("N/A"),
//       sortable: true,
//     },
//     {
//       name: t("Currency Code"),
//       selector: "Currency?.currency_code",
//       format: (row) => row.Currency?.currency_code || t("N/A"),
//       sortable: true,
//     },
//     {
//       name: t("Category"),
//       selector: "category_name",
//       sortable: true,
//     },
//     {
//       name: t("City"),
//       selector: "city",
//       sortable: true,
//     },
//     {
//       name: t("Phone"),
//       selector: "location_telephone",
//       sortable: true,
//     },

//     {
//       name: t("Email"),
//       selector: "location_email",
//       sortable: true,
//     },
//     {
//       name: t("Country"),
//       selector: "country",
//       sortable: true,
//     },
//   ];

//   const noDataComponent = <h1>{t("no-app-locations-found")}</h1>;
//   const noAttachedRestaurantsComponent = (
//     <h1>{t("no-attached-restaurants-found")}</h1>
//   );

//   return (
//     <>
//       <DataTable
//         title=""
//         columns={columns}
//         data={props?.locations?.locations}
//         pagination
//         noDataComponent={noDataComponent}
//       />
//       {props.locations.locations[0]?.is_hotel === 1 && (
//         <DataTable
//           title={t("Attached Restaurants")}
//           columns={attachedRestaurantColumns}
//           data={props?.locations?.attachedRestaurants}
//           noDataComponent={noAttachedRestaurantsComponent}
//         />
//       )}
//     </>
//   );
// };

// export default ListAllLocation;
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import ImageHelper from "../../helpers/ImageHelper";

const ListAllLocation = (props) => {
  console.log("LIST ALL LOCATIONS", props);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const editLocation = (id) => {
    props.editLocation(id);
  };

  const editAttachedRestaurant = (id) => {
    // Function to edit attached restaurant, if needed
    console.log("Edit Attached Restaurant:", id);
  };

  const getBannerCount = (location) => {
    return location.Banners ? location.Banners.length : 0;
  };

  const getHotelNameById = (hotelId) => {
    const hotel = props?.locations?.locations.find(
        (location) => location.loc_id === hotelId
    );
    return hotel ? hotel.name : t("N/A");
  };
  const columns = [
    {
      name:
          props.locations.locations[0]?.is_hotel === 1
              ? t("Hotel Name")
              : t("Restaurant Name"),
      selector: (row) => (row.name),
      sortable: true,
      style: { width: "150px" }, // Adjust the width as needed
    },
    {
      name: t("Category"),
      selector: (row) => (row.category_name),
      sortable: true,
      style: { width: "150px" }, // Adjust the width as needed
    },
    {
      name: t("Currency"),
      selector: (row) => (row.Currency?.currency_name),
      format: (row) => row.Currency?.currency_name || t("N/A"),
      sortable: true,
    },
    {
      name: t("City"),
      selector: (row) => (row.city),
      sortable: true,
    },
    {
      name: t("Country"),
      selector: (row) => (row.country),
      sortable: true,
    },
    {
      name: t("Image"),
      cell: (row) => (
          <img
              src={ImageHelper.getImageURL(row.img)}
              alt="avatarImg"
              style={{ width: "50px", height: "50px", objectFit: "contain" }}
          />
      ),
    },
    {
      name: t("Banners"),
      selector: (row) => getBannerCount(row),
      sortable: true,
    },
    {
      name: t("Action"),
      cell: (row) => (
          <Button.Ripple
              className="btn-block gradient-light-primary"
              onClick={() => editLocation(row.loc_id)}
          >
            {t("Edit")}
          </Button.Ripple>
      ),
    },
  ];

  const attachedRestaurantColumns = [
    {
      name: t("Restaurant Name"),
      selector: (row) => (row.name),
      sortable: true,
    },
    {
      name: t("Attached Hotel"),
      selector: (row) => (row.hotel_id),
      format: (row) => getHotelNameById(row.hotel_id),
      sortable: true,
    },
    {
      name: t("Currency"),
      selector: (row) => (row.Currency?.currency_name),
      format: (row) => row.Currency?.currency_name || t("N/A"),
      sortable: true,
    },
    {
      name: t("Currency Code"),
      selector: (row) => (row.Currency?.currency_code),
      format: (row) => row.Currency?.currency_code || t("N/A"),
      sortable: true,
    },
    {
      name: t("Category"),
      selector: (row) => (row.category_name),
      sortable: true,
    },
    {
      name: t("City"),
      selector: (row) => (row.city),
      sortable: true,
    },
    {
      name: t("Phone"),
      selector: (row) => (row.location_telephone),
      sortable: true,
    },
    {
      name: t("Email"),
      selector: (row) => (row.location_email),
      sortable: true,
    },
    {
      name: t("Country"),
      selector: (row) => (row.country),
      sortable: true,
    },
  ];

  const noDataComponent = <h1>{t("no-app-locations-found")}</h1>;
  const noAttachedRestaurantsComponent = (
      <h1>{t("no-attached-restaurants-found")}</h1>
  );

  return (
      <>
        <DataTable
            title=""
            columns={columns}
            data={props?.locations.locations}
            pagination
            noDataComponent={noDataComponent}
        />
        {props.locations.locations[0]?.is_hotel === 1 && (
            <DataTable
                title={t("Attached Restaurants")}
                columns={attachedRestaurantColumns}
                data={props?.locations?.attachedRestaurants}
                noDataComponent={noAttachedRestaurantsComponent}
            />
        )}
      </>
  );
};

export default ListAllLocation;