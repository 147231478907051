import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  Col,
  Row,
  Media,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Radio from "../../@vuexy/radio/RadioVuexy";
import TimeHelper from "../../../helpers/TimeHelper";
import CONSTANTS from "../../../constants";
import { ClipLoader } from "react-spinners";
import { X, Image } from "react-feather";
import ImageHelper from "../../../helpers/ImageHelper";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const CategoryModal = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const saveAction = () => {
    if (isOpen) {
      submitCategory();
    }
  };

  const keydownHandler = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) saveAction();
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
  });

  const dummyCategory = {
    name: "",
    status: 1,
    available_time: 0,
    description: "",
    parent_id: 0,
    image: "",
    local_from_time: TimeHelper.toFormattedTime(
      Date.now(),
      CONSTANTS.TIMES.FORMAT.DB
    ),
    local_to_time: TimeHelper.toFormattedTime(
      Date.now(),
      CONSTANTS.TIMES.FORMAT.DB
    ),
    img: "",
  };

  let { isOpen, toggleModal, deleteCategory, saveCategory, deleting, isHotel } = props;
  console.log("IsHotel", isHotel)

  let cat = props.category
    ? props.category
    : JSON.parse(JSON.stringify(dummyCategory));
  console.log("CATEGORY", cat)
  cat.img = ImageHelper.getImageURL(cat.image, "?func=crop&w=120&h=80");
  const [category, setCategory] = useState(cat);
  const [saving, setSaving] = useState(false);
  const [nameError, setError] = useState(false);

  let inputRef = React.createRef();
  let fileRef = React.createRef();

  const updateCategory = (key, val) => {
    const cat = JSON.parse(JSON.stringify(category));
    if (key === "image" && val === null) {
      cat.deletePhoto = true;
      cat.img = null;
    }
    cat[key] = val;
    setCategory(cat);
  };

  const handleChange = (e) => {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "webp", "svg"];
      const fileExtension = e.target.files[0].name.split(".").at(-1);
      if (!allowedFileTypes.includes(fileExtension)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Upload image does not support. Image type must be ${allowedFileTypes.join(
            ", "
          )}`,
        });
        return false;
      }

      var size = parseFloat(e.target.files[0].size / (1024 * 1024 * 5)).toFixed(
        2
      );
      if (size > 5) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t('please-select-image-size-less-than-5-mb'),
        });
        return false;
      }

      let reader = new FileReader();
      reader.onload = (ev) => {
        const itemProp = JSON.parse(JSON.stringify(category));
        itemProp.image = ev.target.result;
        itemProp.img = ev.target.result;
        itemProp.deletePhoto = false;
        setCategory(itemProp);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    let cat = props.category
      ? props.category
      : JSON.parse(JSON.stringify(dummyCategory));
    isOpen = props.isOpen;
    setSaving(false);
    deleting = props.deleting;
    setCategory(cat);
  }, [props]);

  const submitCategory = () => {
    if (saving || deleting) return;
    if (category.name === "") {
      setError(true);
      return;
    }
    setSaving(true);
    saveCategory(category);
  };

  const delCategory = () => {
    if (saving || deleting) return;
    deleteCategory(category);
  };
  const handleStatusChange = (value) => {
    updateCategory("status", value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        onOpened={() => {
          if (props.category) return;
          setTimeout(() => inputRef.current.focus(), 1);
        }}
        className="modal-dialog-centered d-sm-margin-0 p-0"
        backdrop="static"
      >
        <div className="modal-header display-flex full-width flex-space-between">
          <h5 className="modal-title">
            {props?.category
              ? `${props.isHotel ? "Edit" : t("edit")} ${props.isHotel ? "Service" : t("category")}`
              : `${props.isHotel ? "New" : t("new")} ${props.isHotel ? "Service" : t("category")}`}
          </h5>

          <X size={20} onClick={toggleModal} />
        </div>
        <ModalBody>
          <FormGroup>
            <Label for="email">{t('category-name-0')}: </Label>
            <Input
              innerRef={inputRef}
              type="text"
              id="name"
              value={category.name}
              placeholder={t('enter-category-name')}
              invalid={nameError}
              onChange={(e) => updateCategory("name", e.target.value)}
            />
          </FormGroup>
          <FormGroup className="row">
            <Col md={6}>
              <Label for="photoDiv">Image:</Label>
              <div
                tabIndex="0"
                id="photoDiv"
                className="display-flex flex-column border border-2 border-light cursor-pointer"
                onKeyUp={(e) =>
                  e.keyCode === 13 && !e.ctrlKey
                    ? fileRef.current.click()
                    : null
                }
                onClick={() => fileRef.current.click()}
                style={{ position: "relative", height: 120 }}
              >
                <div style={{ textAlign: "-webkit-center" }}>
                  {category.img ? (
                    <div>
                      <div
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                        }}
                      >
                        <Button.Ripple
                          onClick={() => {
                            updateCategory("image", null);
                            fileRef.current.value = null;
                          }}
                          style={{ width: 20, height: 20 }}
                          className="m-0 p-0"
                          color="danger"
                        >
                          <X size={14} />
                        </Button.Ripple>
                      </div>
                      <div
                        style={{ position: "absolute", bottom: 3 }}
                        className="full-width"
                      >
                        <Button.Ripple
                          className="p-25"
                          color="primary"
                          onClick={() => fileRef.current.click()}
                        >
                          <span className="font-small-1">{t('change-photo')}</span>
                        </Button.Ripple>
                      </div>
                      <Media
                        object
                        src={category.img}
                        alt="image"
                        style={{ maxWidth: 148, maxHeight: 118 }}
                        className="align-self-center"
                      />
                    </div>
                  ) : (
                    <div
                      className="align-self-center"
                      style={{ padding: 9, height: 50, width: 50 }}
                    >
                      <Image size={32} className="align-self-center" />
                    </div>
                  )}
                </div>
                {category.img ? null : (
                  <React.Fragment>
                    <span className="font-small-2 primary align-self-center">
                      {t('click-to-upload-a-files')} </span>
                    <span className="font-small-2 color- align-self-center align-text-center">
                      JPG,PNG,JPEG,WEBP,SVG <br /> {t('up-to-5mb')}
                    </span>
                  </React.Fragment>
                )}
              </div>
              <input
                type="file"
                id="categoryFileInput"
                name="fileInput"
                onChange={handleChange}
                ref={fileRef}
                style={{ display: "none" }}
                accept="image/*"
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Col md={12}>
              <Label for="status">{t('status-0')}: </Label>
              <div
                id="status"
                onChange={(e) =>
                  updateCategory("status", parseInt(e.target.value))
                }
              >
                <div className="d-inline-block mr-1">
                  <Radio
                    label={t('enabled')}
                    checked={category.status === 1}
                    name="status"
                    value={1}
                    onChange={() => handleStatusChange(1)}
                  />
                </div>
                <div className="d-inline-block mr-1">
                  <Radio
                    label={t('disabled')}
                    name="status"
                    checked={category.status === 0}
                    value={0}
                    onChange={() => handleStatusChange(0)}
                  />
                </div>
              </div>
            </Col>
          </FormGroup>
          {/* <FormGroup>
            <Label for="time">Available Time:</Label>
            <div id='time' onChange={(e) => updateCategory('available_time', e.target.value) }>
              <div className="d-inline-block mr-1">
                <Radio label="Anytime" checked={category.available_time == 0} name="time" value={0} />
              </div>
              <div className="d-inline-block mr-1">
                <Radio label="Specific Times" checked={category.available_time == 1} name="time"  value={1} />
              </div>
            </div>
          </FormGroup> */}
          {/* { category.available_time != 0 &&
          <FormGroup>
            <Row>
              <Col md={6}>
                <Label for='times'>Available from</Label>
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    defaultDate: category.local_from_time
                  }}
                  onChange={date => updateCategory('local_from_time', TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB))}
                />
              </Col>
              <Col md={6}>
                <Label for='times'>Available till</Label>
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    defaultDate: category.local_to_time
                  }}
                  onChange={ date => updateCategory('local_to_time', TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB))}
                />
              </Col>
            </Row>
          </FormGroup> } */}
        </ModalBody>
        <ModalFooter>
          <div className="d-none d-sm-flex flex-space-between full-width">
            <div>
              {props.category && (
                <Button
                  color="darken-1 border-danger"
                  disabled={saving || deleting}
                  onClick={delCategory}
                >
                  {deleting ? (
                    <ClipLoader size={15} />
                  ) : (
                    <span className="danger"> {t('delete')} </span>
                  )}
                </Button>
              )}{" "}
            </div>
            <div>
              <Button
                color="dark"
                disabled={saving || deleting}
                onClick={() => {
                  if (saving || deleting) return;
                  toggleModal();
                }}
              >
                {t('cancel')} </Button>{" "}
              <Button
                color="primary"
                disabled={saving || deleting}
                onClick={submitCategory}
              >
                {saving ? <ClipLoader size={15} /> : t('save')}
              </Button>{" "}
            </div>
          </div>
          <div className="d-sm-none col-12">
            <Button
              className="col-12"
              color="primary"
              disabled={saving || deleting}
              onClick={submitCategory}
            >
              {saving ? <ClipLoader size={15} /> : t('save')}
            </Button>{" "}
            <Button
              className="col-12 my-50"
              color="dark"
              disabled={saving || deleting}
              onClick={() => {
                if (saving || deleting) return;
                toggleModal();
              }}
            >
              {t('cancel')} </Button>{" "}
            {props.category && (
              <Button
                className="col-12"
                color="darken-1"
                disabled={saving || deleting}
                onClick={delCategory}
              >
                {deleting ? (
                  <ClipLoader size={15} />
                ) : (
                  <span className="danger"> {t('delete')} </span>
                )}
              </Button>
            )}
          </div>
          <p className="d-none d-sm-inline col-12 align-text-right font-small-1 pr-0">
            {t('hit-ctrl-enter-to-save')} </p>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default CategoryModal;
