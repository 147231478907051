import React, { Component } from "react";

import TimeHelper from "../../helpers/TimeHelper";
import DataTableComponent from "../shared/DataTableComponent";
import CONSTANTS from "../../constants";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class TopSessionTimeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        startDate: TimeHelper.getFirstDayOf2018(),
        endDate: TimeHelper.getCurrentDate(),
        orderBy: "total_sales",
        order: "desc",
      },
      columns: [
        {
          name: this.props.t("table-name-0"),
          // selector: "table_name",
          selector: (row) => row.table_name,
          sortable: false,
        },
        {
          name: this.props.t("session-start"),
          // selector: 'start_time',
          selector: (row) => row.start_time,
          sortable: false,
          format: (val) => {
            return TimeHelper.toFormattedDateTime(
              val.session_date + " " + val.start_time
            );
          },
        },
        {
          name: this.props.t("session-end"),
          // selector: 'end_time',
          selector: (row) => row.end_time,
          sortable: false,
          format: (val) => {
            return TimeHelper.toFormattedDateTime(
              val.session_date + " " + val.end_time
            );
          },
        },
        {
          name: this.props.t("time-spend"),
          // selector: 'session_avg_time',
          selector: (row) => row.session_avg_time,
          sortable: false,
        },
      ],
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }

  render() {
    const { t } = this.props;
    const {
      props: { data, showDateFilter, pagination, showPaginationBottom },
    } = this;

    const {
      state: { columns, filters },
    } = this;

    return (
      <div className="location">
        <DataTableComponent
          //   dateFilterUpdated={this.filterUpdated.bind(this)}
          showDateFilter={showDateFilter}
          pagination={pagination}
          showPaginationBottom={showPaginationBottom}
          title={t("session-time")}
          data={data}
          columns={columns}
          startDate={filters.startDate}
          endDate={filters.endDate}
          exportBtn={true}
        />
      </div>
    );
  }
}

export default withTranslation("global")(TopSessionTimeList);
