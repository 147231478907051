import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import CONSTANTS from "../../../../../constants";

const gridItemStyle = {
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    boxShadow: '0 0 2px rgba(0,0,0,0.5)',
    transition: '0.5s !important',
    textAlign: 'center',
    overflow: 'hidden',
    height: '100%',
    cursor: 'pointer'
}

const GridItem = ({menuItem, currencySymbol, setSelectedItem, setOpenModal, placeHolderImage}) => {

    const handleImageError = (e) => {
        e.target.src = placeHolderImage;
    }

    return (
        <Stack sx={gridItemStyle} onClick={()=> {
            setSelectedItem(menuItem);
            setOpenModal(true)
        }}>
            <Box sx={{height: '80px', width: 'auto'}}
                 component='img' src={`${CONSTANTS.RESTAURANT_URL}${menuItem?.thumb_path}`}
                 title={menuItem.menu_name}
                 alt={menuItem.menu_name}
                 onError={(e) => handleImageError(e)}
            />
            <Stack sx={{alignItems: 'center', justifyContent: 'center', padding: '0.5rem', flexGrow: 1}}>
                <Typography variant='h6' sx={{fontSize: '0.9rem'}}>{menuItem.menu_name}</Typography>
                <Typography variant='body2'>{currencySymbol} {menuItem.menu_price}</Typography>
            </Stack>
        </Stack>
    )
}

export default GridItem;