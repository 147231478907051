import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Mail, Lock, Key } from "react-feather";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import LoginService from "../../services/LoginService";
import CONSTANTS from "../../constants";

import loginImg2 from "../../assets/img/logo/waiterAsAServiceLogo.png";
import "../../assets/scss/pages/authentication.scss";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      otp: null,
      password: null,
      confirm_password: null,
      showDialog: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.from)
      localStorage.setItem("returnURL", this.props.location.state.from);
    if (this.props.location.state && this.props.location.state.data)
      localStorage.setItem(
        "returnData",
        JSON.stringify(this.props.location.state.data)
      );
    if (LoginService.getToken()) this.redirectToReturnURL();
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  resetPasswordRequest() {
    if (this.state.email === "" || this.state.email === null) {
      Swal.fire({
        icon: "error",
        title: "Email Required",
        text: "Please enter valid email address",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (this.state.otp === "" || this.state.otp === null) {
      Swal.fire({
        icon: "error",
        title: "OTP Required",
        text: "Please enter valid otp",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (this.state.password === "" || this.state.password === null) {
      Swal.fire({
        icon: "error",
        title: "New Password Required",
        text: "Please enter new password",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (
      this.state.confirm_password === "" ||
      this.state.confirm_password === null
    ) {
      Swal.fire({
        icon: "error",
        title: "Confirm Password Required",
        text: "Please enter confirm password",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (this.state.confirm_password !== this.state.password) {
      Swal.fire({
        icon: "error",
        title: "Password Mismatch",
        text: "Your password not matched",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    this.setState({ loading: true }, async () => {
      try {
        let tokenData = await LoginService.resetPasswordEmail({
          email: this.state.email,
          password: this.state.password,
          otp: this.state.otp,
        });
        if (tokenData) {
          Swal.fire({
            icon: "success",
            title: "Reset Password Success",
            text: "Your password has been successfully reset.",
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            window.location.href = "/login";
          });
        }
      } catch (e) {
        this.setState({ loading: false, showDialog: true });
      }
    });
  }

  returnURL() {
    let url = localStorage.getItem("returnURL") || `${CONSTANTS.BASE_URL}`;
    localStorage.removeItem("returnURL");
    return url;
  }

  returnData() {
    let data = localStorage.getItem("returnData") || null;
    localStorage.removeItem("returnData");
    if (data) return JSON.parse(data);
  }

  redirectToReturnURL() {
    this.props.history.push(this.returnURL(), this.returnData());
  }

  async onChangeTerms() {
    await this.setState({ isTermsCheck: !this.state.isTermsCheck });
  }

  render() {
    return (
      <Row className="m-0 justify-content-center">
        <Col
          lg="6"
          className="d-lg-block  text-center align-self-center px-1 py-0"
        ></Col>
        <Col lg="3" md="9" className="p-0">
          {/* px-1 */}
          <Card className="rounded-4 mb-0  login-tabs-container ">
            <CardHeader className="pb-1">
              <CardTitle>
                <div className="tocImage">
                  <img
                    width={"90%"}
                    height={"auto"}
                    src={loginImg2}
                    alt="loginImg"
                  />
                </div>
              </CardTitle>
            </CardHeader>
            <p className="px-2 auth-title text-center">Reset Password</p>

            <Card className="rounded-0 mb-0 ">
              <CardBody>
                <p className="text-center font-weight-bold">
                  Please reset your password by filling the form
                </p>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <FormGroup className="form-label-group position-relative has-icon-left mt-2">
                    <Input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange.bind(this)}
                    />
                    <div className="form-control-position">
                      <Mail size={15} />
                    </div>
                    <Label>Email</Label>
                  </FormGroup>
                  <FormGroup className="form-label-group position-relative has-icon-left mt-2">
                    <Input
                      type="text"
                      placeholder="Six digit OTP"
                      name="otp"
                      value={this.state.otp}
                      onChange={this.handleInputChange.bind(this)}
                    />
                    <div className="form-control-position">
                      <Key size={15} />
                    </div>
                    <Label>OTP</Label>
                  </FormGroup>
                  <FormGroup className="form-label-group position-relative has-icon-left mt-2">
                    <Input
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleInputChange.bind(this)}
                    />
                    <div className="form-control-position">
                      <Lock size={15} />
                    </div>
                    <Label>Password</Label>
                  </FormGroup>
                  <FormGroup className="form-label-group position-relative has-icon-left mt-2">
                    <Input
                      type="password"
                      placeholder="Password"
                      name="confirm_password"
                      value={this.state.confirm_pasword}
                      onChange={this.handleInputChange.bind(this)}
                    />
                    <div className="form-control-position">
                      <Lock size={15} />
                    </div>
                    <Label>Confirm Password</Label>
                  </FormGroup>
                  <FormGroup className="d-flex justify-content-between align-items-center">
                    <a href="/login">Back to login</a>
                  </FormGroup>
                  <FormGroup className="text-center">
                    <Button.Ripple
                      color="primary"
                      type="submit"
                      className="btn-block"
                      onClick={() => this.resetPasswordRequest()}
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? (
                        <span>Loading...</span>
                      ) : (
                        <span>Send email</span>
                      )}
                    </Button.Ripple>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Card>
        </Col>
        {this.state.showDialog && ( // <-- added parentheses to wrap the SweetAlert component
          <SweetAlert
            danger
            title="Oops!"
            showConfirm={false}
            timeout={2500}
            onConfirm={() => this.setState({ showDialog: false })}
          >
            Please enter correct Email
          </SweetAlert>
        )}
      </Row>
    );
  }

  renderLoader() {
    return (
      <div className="text-sm spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
}

export default ResetPassword;
