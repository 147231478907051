import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import {
  Printer as PrinterIcon,
  Mail,
  Trash,
  Trash2,
  ArrowLeft,
  Cpu,
} from "react-feather";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import OrderService from "../../services/OrderService";
import ViewHelper from "../../helpers/ViewHelper";
import TimeHelper from "../../helpers/TimeHelper";
import CONSTANTS from "../../constants";
import "../../assets/css/orders.scss";
import ReactToPrint from "react-to-print";

import { updateNavBarNotificationStatus } from "../../redux/actions/notifications";
import ResponseList from "../survey_response/responseList";
import { X } from "react-feather";
import toast, { Toaster } from "react-hot-toast";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import LocationService from "../../services/LocationService";
import { getLocation } from "../../redux/actions/locations";
import ConfirmationModal from "../shared/forms/ConfirmationModal";

class DirectOrdersDetail extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
  }

  state = {
    loading: true,
    location: null,
    orderSessionDetail: null,
    sessionId: null,
    orderMenuTax: null,
    showPrint: false,
    showEmailModal: false,
    saving: false,
    customerEmail: null,
  };
  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    console.log(location, ":::::::");
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchSesionsOrder = true) {
    this.setState({ loading: initialize, refreshing: true });
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
    }

    let sessionId = this.props.match.params.id;
    console.log("loc_id", loc_id);
    console.log("sessionId", sessionId);
    let sessionDetail = await OrderService.getSingleDirectOrder(sessionId);

    console.log("SESSIONDETAI", sessionDetail);

    this.setState({
      location: JSON.parse(JSON.stringify(this.props?.location)),
      orderSessionDetail: sessionDetail.details,
      sessionId: sessionDetail?.details.order_id,
      loading: false,
      orderMenuTax: sessionDetail?.order_menu_tax,
    });
  }

  renderOrderDetailViewTop(directOrder) {
    console.log("directOrder-----------", directOrder);

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center mt-2"
            id="order-heading"
        >
          {directOrder.map(
              (order, index) => (
                  console.log("directOrder", order),
                      (
                          <>
                            <div key={index} className="text-uppercase">
                              <p>Order Detail</p>
                            </div>
                            <p className="h4">
                              {TimeHelper.toFormattedDate(
                                  order?.order_date,
                                  "ddd, Do MMM, YYYY"
                              )}
                            </p>

                            <p className="pt-1">
                              {this.props.t("start-time-1")}: {order?.order_time}{" "}
                            </p>

                            <p>
                              OrderId # {""}
                              {order?.order_id}{" "}
                            </p>
                            <p>
                              Order Type {""}
                              {order?.order_type}{" "}
                            </p>
                            <p>
                              Order Status {""}
                              {order?.order_status}{" "}
                            </p>
                          </>
                      )
              )
          )}
          {/* <div className="text-uppercase">
          <p>Order Detail</p>
        </div>
        <p className="h4">
          {TimeHelper.toFormattedDate(session?.order_date, "ddd,Do MMM, YYYY")}
        </p>

        <p className="pt-1">
          {this.props.t("start-time-1")}: {session?.order_time}{" "}
        </p>

        <p>
          OrderId # {""}
          {session?.order_id}{" "}
        </p> */}
        </div>
    );
  }
  calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
    console.log("tax percent", taxPercent);
    console.log("menuPrice", menuPrice);
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice = (itemPrice + (itemPrice * tax) / 100) * itemCount;

    return totalPrice;
  };
  calculateTotalPriceOptions = (menuPrice, taxPercent) => {
    console.log("tax percent", taxPercent);
    console.log("menuPrice", menuPrice);
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice = itemPrice + (itemPrice * tax) / 100;
    // const totalPrice = itemPrice + tax;
    console.log("TOTAL_PRICE", totalPrice);
    return totalPrice;
  };

  renderMenuDetails(order, orderMenuTax) {
    console.log("ORDERSSSSSS", order);
    let discounts = 0;
    let subTotal = 0;
    let tax = 0;
    let totals = 0;
    let delivery_fee = 0;
    const { isDeleteConfirmationModalOpen, orderIdToDelete } = this.state;
    return (
        <div>
          <ConfirmationModal
              header="Delete Order Item"
              title="Are you sure you want to delete this Order Item?"
              isOpen={isDeleteConfirmationModalOpen}
              accept={() => this.handle(orderIdToDelete)}
              acceptTitle="Yes"
              reject={() =>
                  this.setState({
                    isDeleteConfirmationModalOpen: false,
                    orderIdToDelete: null,
                  })
              }
              rejectTitle="No"
          />
          <Card
              className="col-md-8 col-sm-12 shadow-sm print_order_items"
              style={{ margin: "0px auto" }}
          >
            <CardHeader>{this.props.t("order-items")}</CardHeader>
            <CardBody>
              {order?.map((singleorder) => {
                const orderTotals = singleorder?.OrderTotals;

                orderTotals.map((order_totals) => {
                  if (order_totals?.code === "cart_total") {
                    subTotal += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "discount") {
                    discounts += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "tax") {
                    tax += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "order_total") {
                    totals += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "delivery_fee") {
                    if (order_totals?.value <= 0 ) {
                      delivery_fee = 0
                    } else {
                      delivery_fee += parseFloat(order_totals?.value);
                    }
                  }
                });

                return singleorder?.OrderMenus?.map((menu) => {
                  console.log("MENU DATA", menu);
                  return [
                    <div
                        className="order-item mb-1 d-flex justify-content-between"
                        key={menu?.order_menu_id}
                    >
                      <div className="f2 name pr-1">
                        <b>
                          {menu?.quantity}x {menu?.name} ={" "}
                          {/* {ViewHelper.toCurrency(
                          parseInt(menu.quantity) * menu.price,
                          order?.Location?.Currency?.currency_code,
                          order?.Location?.decimal_places
                        )} */}
                          {ViewHelper.toCurrency(
                              (parseInt(menu.quantity) *
                                  menu?.price *
                                  menu?.menu_tax_percentage) /
                              100 +
                              menu?.price * menu.quantity,
                              order?.Location?.Currency?.currency_code,
                              order?.Location?.decimal_places
                          )}
                        </b>
                        {this.renderOptions(singleorder, menu?.order_menu_id)}
                      </div>
                      {/* <div className="f2 price">
                      <strong>
                        {ViewHelper.toCurrency(

                          parseInt(menu.quantity) * menu.price,
                          order?.Location?.Currency?.currency_code,
                          order?.Location?.decimal_places
                        )}
                      </strong>
                      <Trash2 style={{ cursor: "pointer" }} className='text-danger' onClick={(e) => this.setState({ isDeleteConfirmationModalOpen: true, orderIdToDelete: menu?.order_id })} />

                    </div> */}
                    </div>,
                    <div
                        className="order-item mb-3 d-flex justify-content-between"
                        key="comment"
                    >
                      {menu.comment && (
                          <div className="f3 name pr-1">
                            {this.props?.t("special-instructions")}:{" "}
                            <span className="font-italic text-muted">
                          {menu.comment}
                        </span>
                          </div>
                      )}
                    </div>,
                  ];
                });
              })}
              <div className="order-item border-top pt-2 d-flex justify-content-between">
                <div className="f2 name pr-1">Total Excl VAT</div>
                <div className="f2 price">
                  {ViewHelper.toCurrency(
                      subTotal,
                      order?.Location?.Currency?.currency_code,
                      order?.Location?.decimal_places
                  )}
                </div>
              </div>

              <div className="order-item pt-2 d-flex justify-content-between">
                <div className="f2 name pr-1">Discount</div>
                <div className="f2 price">
                  {ViewHelper.toCurrency(
                      discounts,
                      order?.Location?.Currency?.currency_code,
                      order?.Location?.decimal_places
                  )}
                </div>
              </div>
              {/* <div className="order-item d-flex justify-content-between">
            <div className="f2 name pr-1">Discounts</div>
            <div className="f2 price">
              {ViewHelper.toCurrency(
                -discounts,
                order.Location.Currency.currency_code,
                order.Location.decimal_places
              )}
            </div>
          </div> */}
              {orderMenuTax?.length > 0
                  ? orderMenuTax.map((menuTax, index) => {
                    return (
                        <div
                            key={index}
                            className="order-item d-flex justify-content-between"
                        >
                          <div className="f2 name pr-1">
                            {order?.Location?.tax_label}{" "}
                            {menuTax?.menu_tax_percentage}%
                          </div>
                          <div className="f2 price">
                            {ViewHelper.toCurrency(
                                menuTax?.total_menu_tax,
                                // tax,
                                order?.Location?.Currency?.currency_code,
                                order?.Location?.decimal_places
                            )}
                          </div>
                        </div>
                    );
                  })
                  : null}
              <div className="order-item pt-2 d-flex justify-content-between">
                {delivery_fee > 0 && <div className="f2 name pr-1">Delivery Charges</div>}
                <div className="f2 price">
                  {delivery_fee === 0 ? '' :
                      (ViewHelper.toCurrency(
                          delivery_fee,
                          order?.Location?.Currency?.currency_code,
                          order?.Location?.decimal_places
                      ))
                  }
                </div>
              </div>
              {/* <div className="order-item d-flex justify-content-between border-bottom pb-2 mt-2">
            <div className="f2 name pr-1">
              {this.props.t('total-0')} {order?.Location?.tax_label}
            </div>
            <div className="f2 price">
              {ViewHelper.toCurrency(
                tax,
                order?.Location?.Currency?.currency_code,
                order?.Location?.decimal_places
              )}
            </div>
          </div> */}
              <div className="order-item d-flex justify-content-between pt-1">
                <div className="f2 name pr-1">
                  <strong>{this.props.t("total-0")}</strong>
                </div>
                <div className="f2 price">
                  <strong>
                    {ViewHelper.toCurrency(
                        totals,
                        order?.Location?.Currency?.currency_code,
                        order?.Location?.decimal_places
                    )}
                  </strong>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
    );
  }

  renderOptions(order, orderMenuId) {
    console.log("----ORDER-----", order);
    let options = order.OrderOptions.filter(
        (option) => option.order_menu_id === orderMenuId
    );
    return options.map((option) => {
      console.log("OPTIONSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS", option);
      return (
          <div
              key={option.order_option_id}
              className="text-muted text-small pl-1"
          >
            {/* {option.display_type ===
          CONSTANTS.VARIABLES.MENUS.OPTIONS.DISPLAY_TYPES.SELECT
            ? `${option.quantity}x `
            : ''} */}
            {option.quantity}x{option.order_option_name} = {""}
            {option.order_option_price === 0 ? (
                <span>FREE</span>
            ) : (
                <span>
              {ViewHelper.toOptonsCurrency(
                  this.calculateTotalPriceOptions(
                      option.order_option_price,
                      option.order_item_tax_percentage,
                      // option.order_item_tax,
                      option.quantity
                  ),
                  // option.order_option_price + option.order_item_tax,
                  this.state.orderSessionDetail.Location?.Currency?.currency_code,
                  this.state.orderSessionDetail.Location?.decimal_places
              )}
            </span>
            )}
          </div>
      );
    });
  }

  renderOrderDetails(order) {
    return (
        <Card
            className="col-md-8 col-sm-12 shadow-sm"
            style={{ margin: "0px auto" }}
        >
          <CardHeader>{this.props.t("customer-and-order-detail")}</CardHeader>
          <CardBody>
            <div className="mb-2 order-item mb-1 d-flex justify-content-between">
              <div>
                <strong>Order Status</strong>
              </div>
              <div>{order?.order_status?.toUpperCase()}</div>
            </div>
            <div className="mb-2 order-item mb-1 d-flex justify-content-between">
              <div>
                <strong>{this.props.t("location-name")}</strong>
              </div>
              <div>{order?.Location?.name}</div>
            </div>
          </CardBody>
        </Card>
    );
  }

  closeEmailSendModal() {
    this.setState({
      saving: false,
      showEmailModal: false,
      customerEmail: null,
    });
  }

  async sendOrderReceipt() {
    try {
      this.setState({ saving: true });
      let loc_id = this.state.orderSessionDetail.Location.loc_id;
      let session_id = this.state.orderSessionDetail.session_id;

      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(this.state.customerEmail)) {
        toast.error(this.props.t("invalid-email-address"));
        this.setState({ saving: false });
        return;
      }

      let sendEmail = await OrderService.sendReceiptToCustomer(
          loc_id,
          session_id,
          this.state.customerEmail
      );
      toast.success(this.props.t("receipt-send-successfully"));
      this.closeEmailSendModal();
    } catch (e) {
      this.closeEmailSendModal();
      toast.error(e.toString());
      return;
    }
  }

  handleSendToCarsine = async () => {
    try {
      const session_id = this.state.sessionId;
      const response = await LocationService.getSessionCarsine(session_id);
      if (response.error) {
        toast.error(response.error);
      } else {
        toast.success("Order Sent to Caresine");
      }
    } catch (error) {
      toast.error("Error fetching session carsine: " + error.message);
    }
  };

  // calculateTotalPrice = (menuPrice, taxPercent) => {
  //   const itemPrice = menuPrice || 0;
  //   const tax = taxPercent || 0;
  //   return itemPrice + (itemPrice * tax) / 100;
  // };
  render() {
    const { t } = this.props;
    const {
      state: { orderSessionDetail, loading, orderMenuTax },
    } = this;
    console.log("ORDERSESSIONDETAIL", orderSessionDetail);

    if (loading) return <SpinnerComponent />;
    return (
        <>
          <Toaster position="top-right" />
          {/* <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return (
              <Button className="btn-icon" color="primary">
                <PrinterIcon size={15} /> {t("print-session-data")}
              </Button>
            );
          }}
          content={() => this.componentRef.current}
          pageStyle={
            "@page { size: 80mm 297mm; } @media print { body { -webkit-print-color-adjust: white; padding: 40px !important; width: 8cm; height: 100%; margin: 0; } }"
          }
        />
        <Button
          className="btn-icon ml-1"
          color="secondary"
          onClick={() => this.setState({ showEmailModal: true })}
        >
          <Mail size={15} /> {t("send-receipt-in-mail")}
        </Button>
        <Button
          className="btn-icon ml-1"
          color="primary"
          onClick={() => this.handleSendToCarsine()}
          // disabled={orderSessionDetail.session_status !== "completed"}
        >
          <Cpu size={15} /> Send to Caresine
        </Button> */}

          <div className="">
            {this.renderOrderDetailViewTop(orderSessionDetail)}
            <Row>
              <Col md="12" sm="12">
                {this.renderMenuDetails(orderSessionDetail, orderMenuTax)}
              </Col>
              <Col md="12" sm="12">
                {this.renderOrderDetails(orderSessionDetail)}
              </Col>
            </Row>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <ResponseList
                  surveyResponse={orderSessionDetail?.LocationSurveyResponses}
                  hideSessionbtn={true}
              />
            </div>
            <React.Fragment>
              <Modal
                  isOpen={this.state.showEmailModal}
                  className="modal-dialog-centered d-sm-margin-0 p-0"
                  backdrop="static"
              >
                <div className="modal-header display-flex full-width flex-space-between">
                  <h5 className="modal-title">
                    {t("share-receipt-with-customer")}
                  </h5>
                  <X
                      className="cursor-pointer"
                      size={20}
                      onClick={() => this.closeEmailSendModal()}
                  />
                </div>

                <ModalBody>
                  <FormGroup className="row">
                    <Col md={12}>
                      <Label for="question">{t("customer-email")}: </Label>
                      <Input
                          type="email"
                          id="email"
                          className="mt-2"
                          placeholder={t("survey-question")}
                          onChange={(e) =>
                              this.setState({ customerEmail: e.target.value })
                          }
                          autoComplete="off"
                          autoFocus={true}
                      />
                    </Col>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <div className="d-none d-sm-flex flex-space-between full-width">
                    <div>
                      {/* <Button
                      color="dark white"
                      onClick={() => this.closeEmailSendModal()}
                    >
                      {t('cancel')}{' '}
                    </Button>{' '} */}
                      <Button
                          color="primary"
                          onClick={() => this.sendOrderReceipt()}
                          disabled={this.state.saving}
                      >
                        {this.state.saving ? t("sending") : t("send-email-0")}
                      </Button>{" "}
                    </div>
                  </div>
                </ModalFooter>
              </Modal>
            </React.Fragment>
          </div>
        </>
    );
  }
}

function mapStateToProps(state) {
  const { sessionslist } = state.orders;
  const { location } = state.locations;
  return {
    sessionslist: sessionslist?.data?.data ? sessionslist?.data?.data : [],
    totalCount: sessionslist?.data?.count ? sessionslist?.data?.count : 0,
    loading: sessionslist.loading || location.loading,
    location: location.data,
  };
}
export default withTranslation("global")(
    connect(mapStateToProps, {
      getLocation,
      updateNavBarNotificationStatus,
    })(DirectOrdersDetail)
);
