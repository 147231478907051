import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Eye, Trash2, CheckCircle, Circle } from "react-feather";
import Swal from "sweetalert2";
import LocationService from "../../services/LocationService";
import LoginService from "../../services/LoginService";

import EventsService from "../../services/EventsService";
import toast from "react-hot-toast";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";

const Payment = () => {
  const [events, setEvents] = useState([]);
  const [selectLocation, setSelectLocation] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLocationAndData();
  }, []);

  const fetchLocationAndData = async () => {
    try {
      const loc = await LocationService.getLocation();
      setSelectLocation(loc.loc_id);
      fetchData(loc.loc_id);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async (locationId) => {
    try {
      const response = await EventsService.getAllUpcomingEvents(locationId);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      setEvents(responseData);
    } catch (error) {
      console.log("ERROR: ", error);
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false); // Set loading to false when data fetching is completed
    }
  };

  const handleView = (description) => {
    setModalContent(description);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (confirmed.isConfirmed) {
        await EventsService.deleteUpcomingEventById(id);
        toast.success("Your event has been deleted.");

        fetchLocationAndData();
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      Swal.fire("Error!", "Failed to delete event.", "error");
    }
  };

  const handleChangeStatus = async (id, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await EventsService.changeUpcomingEventStatus(id, newStatus);

      toast.success("Your event status has been Updated.");

      fetchLocationAndData();
    } catch (error) {
      console.error("Error updating event status:", error);
      Swal.fire("Error!", "Failed to update event status.", "error");
    }
  };

  return (
    <div>
      {loading ? (
        <SpinnerComponent />
      ) : events.length === 0 ? (
        <h1 className="mx-auto text-center">No events found.</h1>
      ) : (
        <Table responsive>
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <th style={tableHeaderStyle}>ID</th>
              <th style={tableHeaderStyle}>Title</th>
              <th style={tableHeaderStyle}>Description</th>
              <th style={tableHeaderStyle}>Status</th>
              <th style={tableHeaderStyle}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td style={tableCellStyle}>{event.id}</td>
                <td style={tableCellStyle}>{event.event_title}</td>
                <td style={tableCellStyle}>
                  <Button
                    color="primary"
                    onClick={() => handleView(event.event_desc)}
                    style={buttonStyle}
                  >
                    <Eye size={20} />
                  </Button>
                </td>
                <td style={tableCellStyle}>
                  <Button
                    color={event.event_status ? "success" : "danger"}
                    onClick={() =>
                      handleChangeStatus(event.id, event.event_status)
                    }
                    style={buttonStyle}
                  >
                    {event.event_status ? (
                      <CheckCircle size={20} />
                    ) : (
                      <Circle size={20} />
                    )}
                  </Button>
                </td>
                <td style={tableCellStyle}>
                  <Button
                    color="danger"
                    onClick={() => handleDelete(event.id)}
                    style={buttonStyle}
                  >
                    <Trash2 size={20} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        size="lg"
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Event Description
        </ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalOpen(!modalOpen)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Payment;

const tableHeaderStyle = {
  textAlign: "left",
  border: "1px solid #ddd",
  padding: "8px",
};

const tableCellStyle = {
  border: "1px solid #ddd",
  padding: "8px",
};
const buttonStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
