import CONSTANTS from "../../../constants";
import {
    addNewPremiumService, deletePremiumService,
    getPremiumServicesByLocation,
    updatePremiumServices
} from "../../../services/PremiumServices";

export const getPremiumServicesAction = async (loc_id) => {
    return async dispatch => {
        try {
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.GET_PREMIUM_SERVICES, services: null, loading: true });
            let services = await getPremiumServicesByLocation(loc_id);
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.GET_PREMIUM_SERVICES, services, loading: false });
        } catch (err) {
            console.log(err)
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.GET_PREMIUM_SERVICES, services: null, loading: false });
        }
    }
}

export const updatePremiumServiceAction = async () => {
    return async dispatch => {
        try {
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.UPDATE_PREMIUM_SERVICES, services: null, loading: true });
            let services = await updatePremiumServices();
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.UPDATE_PREMIUM_SERVICES, services, loading: false });
        } catch (err) {
            console.log(err)
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.UPDATE_PREMIUM_SERVICES, services: null, loading: false });
        }
    }
}

export const addNewPremiumServiceAction = async (data, loc_id) => {
    return async dispatch => {
        try {
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.ADD_PREMIUM_SERVICES, services: {}, loading: false });
            let services = await addNewPremiumService(data, loc_id);
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.ADD_PREMIUM_SERVICES, services, loading: false });
        } catch (err) {
            console.log(err)
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.ADD_PREMIUM_SERVICES, services: null, loading: false });
        }
    }
}

export const deletePremiumServiceAction = async (data) => {
    return async dispatch => {
        try {
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.DELETE_PREMIUM_SERVICES, services: null, loading: true });
            let services = await deletePremiumService(data);
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.DELETE_PREMIUM_SERVICES, services, loading: false });
        } catch (err) {
            console.log(err)
            dispatch({ type: CONSTANTS.ACTIONS.PREMIUM_SERVICES.DELETE_PREMIUM_SERVICES, services: null, loading: false });
        }
    }
}