import CONSTANTS from '../constants';
import LoginService from './LoginService';

export const createOrder = async ({
  package_id,
  package_title,
  price,
  currency,
  discount,
  payment_method,
  products,
  license_key,
}) => {
  let data = {
    package_id,
    package_title,
    price,
    currency,
    discount,
    payment_method,
    products,
  };

  try {
    let { token } = LoginService.getToken();
    // const response = await fetch(`${ApiRoute.createOrder(license_key)}`, {
    let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.PACKAGES.CREATE_ORDER(
        license_key
      )}`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        // headers: { ...CONSTANTS.REQUEST_HEADERS },
        headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
      }
    );

    console.log('Befote RESPONSE', response);
    if (!response.ok) {
      const errorData = await response.json();
      console.log('Error Response Data', errorData);
      console.log('RESPONSE', response);
      throw new Error(errorData.message);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log('ERROR', error);
    throw new Error(`Could not create Order: ${error.message}`);
  }
};
