import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from "moment";
import Pagination from "../shared/Pagination";
import { useTranslation } from "react-i18next";
import { getAllPurchaseTtransactions } from "../../services/PurchaseHistoryService";
import { Card, CardBody, Table } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ListPurchaseHistory = (props) => {
  console.log(props, "props");
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);
  const [datatransaction, setDataTransaction] = useState();
  console.log(datatransaction, "datatransaction");
  useEffect(() => {
    const getAllTransactions = async () => {
      try {
        const all = await getAllPurchaseTtransactions();
        setDataTransaction(all);
      } catch (error) {
        console.error(error);
      }
    };
    getAllTransactions();
  }, []);

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      format: (val) => {
        return (
          <a
            href="javascript:void(0)"
            onClick={() => props.getTransactionDetail(val)}
          >
            {val.id}
          </a>
        );
      },
    },
    {
      name: t("location"),
      // selector: 'tag_name',
      selector: (row) => row.Location.name,
      format: (val) => {
        return val?.Location?.name;
      },
    },
    {
      name: t("package"),
      selector: (row) => row.package_title,
      format: (val) => {
        return val.package_title;
      },
    },
    {
      name: t("items"),
      selector: (row) => row,
      format: (val) => {
        return val.ItemsInTransactions.length;
      },
    },
    {
      name: t("price-0"),
      selector: (row) => row.price,
      format: (val) => {
        return val.price + " " + val.currency;
      },
    },
    {
      name: t("subscription"),
      selector: (row) => row.subscription,
      format: (val) => {
        if (val.subscription == 1 && val.subscription_status == "active") {
          return t("recurring");
        }
        if (
          val.subscription == 1 &&
          val.subscription_status == "payment_failed"
        ) {
          return t("recurring-canceled");
        }
        return t("one-time-0");
      },
    },
    {
      name: t("purchase-at"),
      selector: (row) => row.createdAt,
      format: (val) => {
        return moment(val.createdAt).format("DD/MM/YYYY");
      },
    },
  ];

  const history = useHistory();

  const showTransactionDetail = async (item) => {
    console.log("transaction", item);
    history.push("/purchase-history-list", {
      data: { item, allItems: datatransaction },
    });
  };

  const renderDishTags = (data) => {
    const tableData = {
      columns: columns,
      data: data,
      print: false,
      export: false,
      filter: false,
    };
    return (
      <>
        <DataTableExtensions {...tableData}>
          <DataTable
            noHeader
            // pagination
            // rowsPerPageOptions={[]}
            columns={columns}
            data={data}
            // onChangePage={(page) => props.getMoreData(page)}
            // paginationComponentOptions={{ noRowsPerPage: 1 }}
            // paginationPerPage={props.limit}
            // paginationServer
            // paginationTotalRows={props.count}
          />
        </DataTableExtensions>
        <Pagination
          onPageChange={(page) => {
            console.log("page: ", page);
            props.getMoreData(page.selected);
          }}
          page={props.currentPage}
          count={props.count}
        />
        <Table>
          <thead>
            <tr>
              <th>Location Name</th>
              <th>Tables</th>
              <th>ServTable User</th>
              <th>ServWait User</th>
              <th>PrintServ User</th>
            </tr>
          </thead>
          <tbody>
            {datatransaction?.allPackagesInfo?.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    style={{ color: "green", cursor: "pointer" }}
                    onClick={() => showTransactionDetail(item)}
                  >
                    {item?.location_name}
                  </td>
                  <td>
                    {item?.quantities?.Tables &&
                      datatransaction?.usedTables
                        ?.filter(
                          (usedItem) => usedItem?.loc_id === item?.location_id
                        )
                        .map((filteredItem, filteredIndex) => (
                          <React.Fragment key={filteredIndex}>
                            <td>
                              {" "}
                              {item?.quantities?.Tables}/
                              {filteredItem?.used_tables}
                            </td>
                          </React.Fragment>
                        ))}
                  </td>

                  {datatransaction?.countsByLocationAndGroup
                    ?.filter((used) => used.location_id === item.location_id)
                    .map((filteredItem, filteredIndex) => (
                      <React.Fragment key={filteredIndex}>
                        <td>
                          {item.quantities["ServTable User"]
                            ? `${item.quantities["ServTable User"]}/${
                                filteredItem?.counts["Eater App Manager"]
                                  ? filteredItem?.counts["Eater App Manager"]
                                  : "0"
                              }`
                            : "0"}
                        </td>
                      </React.Fragment>
                    ))}

                  {datatransaction?.countsByLocationAndGroup
                    ?.filter((used) => used.location_id === item.location_id)
                    .map((filteredItem, filteredIndex) => (
                      <React.Fragment key={filteredIndex}>
                        <td>
                          {item.quantities["ServWait User"]
                            ? `${item.quantities["ServWait User"]}/${
                                filteredItem?.counts["Waiter App Manager"]
                                  ? filteredItem?.counts["Waiter App Manager"]
                                  : "0"
                              }`
                            : "0"}
                        </td>
                      </React.Fragment>
                    ))}

                  {datatransaction?.countsByLocationAndGroup
                    ?.filter((used) => used.location_id === item.location_id)
                    .map((filteredItem, filteredIndex) => (
                      <React.Fragment key={filteredIndex}>
                        <td>
                          {item.quantities["PrintServ User"]
                            ? `${item.quantities["PrintServ User"]}/${
                                (filteredItem?.counts["Kitchen Manager"] || 0) +
                                (filteredItem?.counts["Bar Manager"] || 0) +
                                (filteredItem?.counts["Dessert Manager"] || 0) +
                                (filteredItem?.counts["Alternative_1"] || 0) +
                                (filteredItem?.counts["Alternative_2"] || 0)
                              }`
                            : "0"}
                        </td>
                      </React.Fragment>
                    ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </>
    );
  };
  return renderDishTags(props.data);
};

export default ListPurchaseHistory;
