import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import { getAllModifiers } from "../../redux/actions/menus";
import "../../assets/scss/pages/page-pricing.scss";
import ListModifiers from "./ListModifiers";
import { Button } from "reactstrap";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import LocationService from "../../services/LocationService";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class Modifiers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: "",
      location: null,
      selectLocation: false,
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t('app-users'),
        header: this.props.t('app-users'),
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t('app-users'),
        header: this.props.t('app-users'),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }
  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);
    await this.props.getAllModifiers(loc.loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  handleChange = (state) => {
    let reviews = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      reviews.push(state.selectedRows[i].review_id);
    }
    this.setState({ reviews: reviews });
  };

  async updateCurrencyStatus() {
    if (this.state.currencies.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.props.changeCurrencyStatus(this.state.currencies);
        },
        null,
        this.props.t('are-you-sure-you-want-to-status-of-this-currencies'),
        this.props.t('currencies')
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t('please-select-atleast-one-currency-for-change-status'),
        this.props.t('warning')
      );
    }
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }
  editCurrency(id) {
    // this.props.history.replace('/currencies/edit/' + id);
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  render() {
    const { t } = this.props;

    const {
      props: { modifiers },
    } = this;
    const {
      state: {
        confirmationDialog,
        errorDialog,
        selectLocation,
        location,
        loading,
      },
    } = this;

    const locationName = location ? location.name : "";

    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="display-block mb-1">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.changeLocation.bind(this)}
          />
        </div>
        <div id="pricing-table">
          {!loading && modifiers ? (
            <ListModifiers
              modifiers={modifiers}
              handleChange={this.handleChange.bind(this)}
              editCurrency={this.editCurrency.bind(this)}
            />
          ) : null}
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t('cancel')}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={t('ok')}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    menu: {
      allModifiers: { data: modifiers, loading: loading },
    },
  } = state;

  return { modifiers, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getAllModifiers })(Modifiers)
);
