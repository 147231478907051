import CONSTANTS from "../constants";
import LoginService from "./LoginService";

import { customFetch as fetch } from "../helpers/FetchHelper";

export const getAllPurchaseForAdmin = async (page = 1) => {
  const { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.PURCHASE_HISTORY.default}${page}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Purchase History: ${response}`);
};


export const getAllPurchaseTtransactions = async () => {
  const { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ALL_TRANSACTIONS.default}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Purchase History: ${response}`);
};