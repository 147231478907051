import React from "react";
import * as Icon from "react-feather";

const ownerNavigationConfig = [
  {
    id: "dashboard",
    title: "dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
    starred: false,
  },
  // {
  //   id: "menu-manager",
  //   title: "Menu",
  //   type: "item",
  //   icon: <Icon.ShoppingCart size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/locations/menu",
  //   starred: true,
  // },
  // {
  //   id: 'package',
  //   title: 'Packages',
  //   type: 'item',
  //   icon: <Icon.Package size={20} />,
  //   permissions: ['admin', 'editor'],
  //   navLink: '/packages/all',
  //   starred: false,
  // },
  {
    id: "sessions",
    title: "sessions",
    type: "item",
    icon: <Icon.Key size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/sessions",
    starred: false,
  },
  {
    id: "restaurants",
    title: "restaurants",
    type: "item",
    icon: <Icon.ShoppingBag size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/locations/all",
  },
  {
    id: "packages",
    title: "packages",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/packages/all",
  },
  {
    id: 'paymentmethod',
    title: 'Payment Method',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: ['admin'],
    navLink: '/payment/table',
  },
  {
    id: "purchase-history",
    title: "purchase History",
    type: "item",
    icon: <Icon.Slack size={20} />,
    permissions: ["admin"],
    navLink: "/purchase-history",
  },

  {
    id: 'Accounts',
    title: 'accounts',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ["admin"],
    navLink: "/staff",
  },
  {
    id: "stats",
    title: "overall Stats",
    type: "item",
    icon: <Icon.BarChart2 size={16} />,
    navLink: "/location/stats",
    starred: false,
  },
  {
    id: "pdf",
    title: "menu PDF",
    type: "item",
    icon: <Icon.Upload size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/location/upload/pdf",
    starred: false,
  },
  {
    id: "analytics",
    title: "reporting",
    type: "collapse",
    icon: <Icon.FileText size={16} />,
    children: [
      {
        id: "overall-stats",
        title: "Sales Report ",
        type: "item",
        icon: <Icon.Activity size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/overall_stats",
      },
      {
        id: "item-stats",
        title: "item Stats",
        type: "item",
        icon: <Icon.List size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/item_stats",
      },
    ],
    starred: false,
  },
  {
    id: 'support',
    title: 'support',
    type: 'collapse',
    icon: <Icon.User size={25} />,
    children: [
      {
        id: 'faq',
        title: 'faq',
        type: 'item',
        icon: <Icon.Bookmark size={25} />,
        permissions: ['admin', 'editor'],
        navLink: '/faq',
        starred: false,
      },
      {
        id: 'wiki',
        title: 'wiki',
        type: 'item',
        icon: <Icon.Globe size={25} />,
        permissions: ['admin'],
        navLink: '/wiki',
        starred: false,
      },
      {
        id: 'training',
        title: 'training',
        type: 'item',
        icon: <Icon.HelpCircle size={25} />,
        permissions: ['admin'],
        navLink: '/training',
        starred: false,
      },
      {
        id: 'contact',
        title: 'contact US',
        type: 'item',
        icon: <Icon.Phone size={25} />,
        permissions: ['admin'],
        navLink: '/contactus',
        starred: false,
      },
      {
        id: 'emergency',
        title: 'emergency',
        type: 'item',
        icon: <Icon.AlertOctagon size={25} />,
        permissions: ['admin'],
        navLink: '/emergency',
        starred: false,
      },
    ],
    starred: false,
  },


  {
    id: "logout",
    title: "logout",
    type: "item",
    icon: <Icon.Power size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/logout",
    starred: false,
  },
];
export default ownerNavigationConfig;
