import React from "react";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import {
  Label,
  Row,
  Col,
  Card,
  Input,
  CardBody,
  CardHeader,
  Button,
  ButtonGroup,
} from "reactstrap";
import LocationService from "../../services/LocationService";
import CONSTANTS from "../../constants";
import { connect } from "react-redux";
import { getAllLocations } from "../../redux/actions/locations";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import MenuService from "../../services/MenuService";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import Toggle from "react-toggle";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import Menu from "../menus/Menu";
import Swal from "sweetalert2";

import { withTranslation } from "react-i18next";
import i18n from "i18next";
import toast from "react-hot-toast";

class AllergicItemsForm extends React.Component {
  state = {
    activeStep: 0,
    isOpenSelect: false,
    location: null,
    selectLocation: false,
    editMode: false,
    dishtag: {
      item_id: "",
      item_name: "",
      loc_id: "",
      description: "",
    },
    location: {
      loc_id: 0,
      name: this.props.t("all-venues"),
      Currency: {
        currency_code: "PKR",
      },
      decimal_places: 2,
    },
    loading: false,
    saving: false,
    errorSaving: false,
    errorWallet: false,
    isOpen: false,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: this.props.t("wallet"),
      header: this.props.t("wallet"),
    },
  };

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
      if (window.location.pathname.indexOf("edit") !== -1) {
        this.setState({
          dishtag: this.props?.history?.location?.state?.item,
          editMode: true,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  onBlur() {
    this.filterUpdated();
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  toggleItemsModal() {
    this.setState({ isMenuItemModalOpen: !this.state.isMenuItemModalOpen });
  }

  async updateValue(value, type) {
    if (!isNaN(value) && value) value = parseInt(value);
    let currency = JSON.parse(JSON.stringify(this.state.currency));
    let obj = currency;
    let parts = type.split(".");
    const n = parts.length - 1;
    for (let i = 0; i < n; i++) obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ currency });
  }

  async setOpen(open) {
    await this.setState({ isOpen: open });
  }

  async setOpenSelect() {
    await this.setState({ isOpenSelect: !this.state.isOpenSelect });
  }

  async addUpdateDishTag() {
    const {
      state: { dishtag, location, editMode },
    } = this;
    var item = {};
    var item_name = editMode
      ? document.getElementById(`item_name_${dishtag.item_id}`).value
      : document.getElementById(`item_name`).value;
    if (item_name === "" || item_name === null) {
      return;
    }
    var description = editMode
      ? document.getElementById(`description_${dishtag.item_id}`).value
      : document.getElementById(`description`).value;
    if (dishtag.item_id === "") {
      item = {
        description,
        item_name,
        loc_id: location.loc_id,
      };
      Swal.fire({
        icon: "success",

        title: this.props.t("successfully-added"),
        showConfirmButton: false,
        timer: 1500,
      });
      await MenuService.saveAllergicItem(location.loc_id, item);
      this.props.history.replace("/allergic_items");
    } else {
      item = {
        description,
        item_name,
        item_id: dishtag.item_id,
        loc_id: location.loc_id,
      };
      await MenuService.updateAllergicItem(
        location.loc_id,
        dishtag.item_id,
        item
      );
      this.props.history.replace("/allergic_items");
    }
  }

  handleStepChange(activeStep) {
    this.setState({ activeStep });
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  onConditionChange = (selectedOption) => {
    this.updateValue(selectedOption.value, "plan_time");
  };

  async deleteDishTag() {
    const {
      state: { dishtag, location },
    } = this;
    try {
      await MenuService.deleteAllergicItem(location.loc_id, dishtag.item_id);
      this.props.history.replace("/allergic_items");
    } catch {
      toast.error(" This Item is assigned to a Menu");
    }
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  renderTables(item, locations) {
    const { t } = this.props;
    return (
      <>
        <Row className="p-1 align-items-center">
          <Col md={this.state.editMode ? 5 : 6}>
            <AvGroup>
              <label htmlFor={`item_name_${item.item_id}`}>
                {t("item-name")}
              </label>
              <AvInput
                data-id={item.item_name}
                name={`item_name`}
                // placeholder={'Name'}
                type="text"
                required
                id={
                  this.state.editMode
                    ? `item_name_${item.item_id}`
                    : "item_name"
                }
                value={item.item_name}
              />
              <AvFeedback>{t("please-enter-name")}</AvFeedback>
            </AvGroup>
          </Col>
          <Col md={this.state.editMode ? 5 : 6}>
            <AvGroup>
              <label htmlFor={`description_${item.item_id}`}>
                {t("item-description")}{" "}
              </label>
              <Input
                type="textarea"
                rows="2"
                data-id={item.description}
                defaultValue={item.description}
                name={`description`}
                id={
                  this.state.editMode
                    ? `description_${item.item_id}`
                    : "description"
                }
              />
              <AvFeedback>Description.</AvFeedback>
            </AvGroup>
          </Col>
          {this.state.editMode && (
            <Col md={2} sm={2}>
              <AvGroup>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => this.deleteDishTag()}
                >
                  {t("delete")}{" "}
                </button>
              </AvGroup>
            </Col>
          )}
        </Row>
      </>
    );
  }

  render() {
    const { t } = this.props;

    const {
      props: { locations },
    } = this;

    const {
      state: {
        errorSaving,
        dishtag,
        editMode,
        confirmationDialog,
        selectLocation,
        location,
        loading,
      },
    } = this;

    const locationName = location ? location.name : "";

    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <div className="location">
        <div className="display-block mb-1">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.changeLocation.bind(this)}
          />
        </div>
        <Card className="full-width">
          <AvForm>
            {/* <>
              <Row className="p-1">
                <Col md={6}>
                  <Label>Name</Label>
                </Col>
                <Col md={6}>
                  <Label>Description</Label>
                </Col>
              </Row>
            </> */}

            {this.renderTables(dishtag, locations)}
            <Row className="p-1 ml-1">
              <div className="actions ">
                {!editMode && (
                  <button
                    className="btn btn-primary"
                    onClick={() => this.addUpdateDishTag()}
                  >
                    {t("save-item")}
                  </button>
                )}
                &nbsp;
                {editMode && (
                  <button
                    className="btn btn-primary"
                    onClick={() => this.addUpdateDishTag()}
                  >
                    {t("update-item")}
                  </button>
                )}
              </div>
            </Row>
          </AvForm>
        </Card>

        <ConfirmationModal
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          accept={confirmationDialog.acceptCallback.bind(this)}
          reject={confirmationDialog.rejectCallback.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locations, loading: loading },
    },
  } = state;

  return { locations, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getAllLocations })(AllergicItemsForm)
);
