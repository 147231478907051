import React, { Component } from 'react';
import ViewHelper from '../../helpers/ViewHelper';
import CONSTANTS from '../../constants';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import Chart from 'react-apexcharts';
import { ChevronDown } from 'react-feather';

import 'flatpickr/dist/themes/light.css';
import '../../assets/scss/plugins/forms/flatpickr/flatpickr.scss';
import '../../assets/css/overallStatsGraphCard.scss';
import TimeHelper from '../../helpers/TimeHelper';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class OverallStatsGraphCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      computing: true,
      showFilterPopup: false,
      isOpen: false,
      filterTitle: this.props.t('last-30-days'),
      currencyCode: 'PKR',
      graphData: {
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            type: 'bar',
            stacked: true,
          },
          stroke: {
            width: [2, 0, 0],
            curve: 'smooth',
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          fill: {
            opacity: [0.85, 0.85, 1],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: 'vertical',
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          labels: [],
          markers: {
            size: 0,
          },
          xaxis: {
            type: 'date',
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: (y, props) => {
                if (typeof y !== 'undefined') {
                  let value = y.toFixed(0);
                  if (props.seriesIndex === 0)
                    value = ViewHelper.toCurrency(
                      value,
                      this.state.currencyCode,
                      this.props.location.decimal_places
                    );
                  return value;
                }
                return y;
              },
            },
          },
        },
        series: [],
      },
    };
  }

  async toggleFilterPopup() {
    this.setState({ showFilterPopup: !this.state.showFilterPopup });
  }

  componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setGraphData();
  }

  setGraphData() {
    const graphData = JSON.parse(JSON.stringify(this.state.graphData));
    const {
      props: {
        stats: { xAxisLabel, completedOrders, cancelledOrders, totalSales },
        location,
      },
    } = this;

    let currencyCode = 'PKR';
    if (location.Currency) currencyCode = location.Currency.currency_code;

    let strokeWidth = 5;
    if (xAxisLabel.length > 50) strokeWidth = 1;
    else if (xAxisLabel.length > 40) strokeWidth = 2;
    else if (xAxisLabel.length > 30) strokeWidth = 2.75;
    else if (xAxisLabel.length > 20) strokeWidth = 3.5;

    graphData.options.labels = xAxisLabel;
    graphData.options.stroke.width = [strokeWidth, 0, 0];
    graphData.series = [
      {
        name: CONSTANTS.VARIABLES.ANALYTICS.OVERALL_STATS_CHART.TOTAL_SALES,
        type: 'line',
        data: totalSales,
      },
      {
        name: CONSTANTS.VARIABLES.ANALYTICS.OVERALL_STATS_CHART
          .COMPLETED_ORDERS,
        type: 'column',
        data: completedOrders,
      },
      {
        name: CONSTANTS.VARIABLES.ANALYTICS.OVERALL_STATS_CHART
          .CANCELLED_ORDERS,
        type: 'column',
        data: cancelledOrders,
      },
    ];
    graphData.options.yaxis = [
      {
        seriesName:
          CONSTANTS.VARIABLES.ANALYTICS.OVERALL_STATS_CHART.TOTAL_SALES,
        title: {
          text: `Sales in ${currencyCode}`,
        },
        labels: {
          formatter: (value) => {
            return ViewHelper.toCurrency(
              value,
              currencyCode,
              location.decimal_places
            );
          },
        },
      },
      {
        opposite: true,
        title: {
          text: this.props.t('order'),
        },
        seriesName:
          CONSTANTS.VARIABLES.ANALYTICS.OVERALL_STATS_CHART.COMPLETED_ORDERS,
      },
      {
        show: false,
        seriesName:
          CONSTANTS.VARIABLES.ANALYTICS.OVERALL_STATS_CHART.COMPLETED_ORDERS,
      },
    ];

    this.setState({ graphData: graphData, currencyCode: currencyCode });
  }

  applyFilters() {
    this.toggleFilterPopup();
    this.props.applyFilters();
  }

  toggleDateDropDown() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  renderDateCustomFilter() {
    const allTimeValue = TimeHelper.getFirstDayOf2018();
    const last7Days = TimeHelper.getDaysBeforeToday(7);
    const last30Days = TimeHelper.getDaysBeforeToday(30);
    const lastYear = TimeHelper.getDaysBeforeToday(365);
    const thisMonth = TimeHelper.getFirstDayOf('month');
    const thisYear = TimeHelper.getFirstDayOf('year');
    const {
      state: { isOpen },
    } = this;

    const { t } = this.props;

    return (
      <>
        <div
          tag="small"
          className="text-bold-500 cursor-pointer col-12 time-picker-custom"
          onClick={this.toggleDateDropDown.bind(this)}
        >
          {this.props.filters.filterTitle}{' '}
          <ChevronDown color="black" size={14} />
        </div>
        {isOpen && (
          <div className="col-8 no-padding date-filter-container">
            <Button.Ripple
              outline
              onClick={() => {
                this.props.onChange([last7Days], 'date');
                this.props.onChange('Last 7 days', 'filterTitle');
                this.toggleDateDropDown();
              }}
              className="square full-width"
            >
              {t('last-7-days')}{' '}
            </Button.Ripple>
            <Button.Ripple
              outline
              onClick={() => {
                this.props.onChange([last30Days], 'date');
                this.props.onChange('Last 30 days', 'filterTitle');
                this.toggleDateDropDown();
              }}
              className="square full-width"
            >
              {t('last-30-days')}{' '}
            </Button.Ripple>
            <Button.Ripple
              outline
              onClick={() => {
                this.props.onChange([lastYear], 'date');
                this.props.onChange('Last 365 days', 'filterTitle');
                this.toggleDateDropDown();
              }}
              className="square full-width"
            >
              {t('last-365-days')}{' '}
            </Button.Ripple>
            <Button.Ripple
              outline
              onClick={() => {
                this.props.onChange([thisMonth], 'date');
                this.props.onChange('Month to Date', 'filterTitle');
                this.toggleDateDropDown();
              }}
              className="square full-width"
            >
              {t('month-to-date')}{' '}
            </Button.Ripple>
            <Button.Ripple
              outline
              onClick={() => {
                this.props.onChange([thisYear], 'date');
                this.props.onChange('Year to Date', 'filterTitle');
                this.toggleDateDropDown();
              }}
              className="square full-width"
            >
              {t('year-to-date')}{' '}
            </Button.Ripple>
            <Button.Ripple
              outline
              onClick={() => {
                this.props.onChange([allTimeValue], 'date');
                this.props.onChange('All Time', 'filterTitle');
                this.toggleDateDropDown();
              }}
              className="square full-width"
            >
              {t('all-time')}{' '}
            </Button.Ripple>
            <Button.Ripple
              outline
              className="no-padding square full-width center"
            >
              <Flatpickr
                id="date-interval"
                className="form-control align-text-center"
                options={{
                  mode: 'range',
                  defaultDate: [
                    this.props.filters.startDate,
                    this.props.filters.endDate,
                  ],
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
                onChange={(d) => {
                  if (d.length <= 1) return;
                  this.props.onChange('Custom Date', 'filterTitle');
                  this.props.onChange(d, 'date');
                  this.toggleDateDropDown();
                }}
              />
            </Button.Ripple>
          </div>
        )}
      </>
    );
  }

  render() {
    const { t } = this.props;
    const {
      state: { graphData },
    } = this;

    return (
      <Card>
        <CardHeader>
          <CardTitle>Sales Report</CardTitle>
          <div className="filter-section">
            <span
              tag="small"
              className="text-bold-500 cursor-pointer"
              onClick={this.toggleFilterPopup.bind(this)}
            >
              {t('change-filters')} <ChevronDown size={10} />
            </span>
            {this.state.showFilterPopup ? (
              <div
                className="position-absolute col-md-6"
                style={{ right: 0, zIndex: 1000 }}
              >
                <Card style={{ border: '2px solid #ffffff22' }}>
                  <CardBody>
                    <Form className="px-2 py-2">
                      <FormGroup>
                        <Label for="date-interval">{t('time-span')}</Label>
                        {this.renderDateCustomFilter()}
                      </FormGroup>
                      <FormGroup>
                        <Label for="date-interval">{t('group-by')}</Label>
                        <CustomInput
                          type="select"
                          name="select"
                          id="groupBy"
                          value={this.props.filters.groupBy}
                          onChange={(e) =>
                            this.props.onChange(e.target.value, 'groupBy')
                          }
                        >
                          <option value="date" selected>
                            {t('daily')}{' '}
                          </option>
                          <option value="month">{t('monthly')}</option>
                          <option value="year">{t('yearly')}</option>
                        </CustomInput>
                      </FormGroup>
                      <FormGroup>
                        <Col md={{ size: 8, offset: 4 }}>
                          <Button
                            color="primary"
                            className="mr-1 mb-1"
                            onClick={this.applyFilters.bind(this)}
                          >
                            {t('apply')}{' '}
                          </Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            ) : null}
          </div>
        </CardHeader>
        <CardBody className="no-padding">
          <Chart
            options={graphData.options}
            series={graphData.series}
            type="line"
            height={350}
          />
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation('global')(OverallStatsGraphCard);
