import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import LocationService from "../../services/LocationService";
import { getAllSurveyResponse } from "../../redux/actions/surveyresponse";
import ResponseList from "./responseList";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import CONSTANTS from "../../constants";
import Pagination from "../shared/Pagination";

class SurveyResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      selectLocation: false,
      loading: false,
      page: 0,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation) {
      loc = await LocationService.getLocationFromServer(loc_id);
    }
    await this.props.getAllSurveyResponse(loc.loc_id, 0);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  goToOrderSession(sessionid) {
    this.props.history.push(`${CONSTANTS.BASE_URL}sessions/${sessionid}`);
  }

  onPageChange(page) {
    this.setState({ loading: true });
    this.setState({ page: page.selected }, async () => {
        await this.props.getAllSurveyResponse(
          this.state.location.loc_id,
          page.selected
        );
    });
  }

  render() {
    const {
      props: { surveyResponse , loading },
    } = this;
    const {
      state: { selectLocation, location },
    } = this;
    const locationName = location ? location.name : "";
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="location">
          <div className="display-flex flex-row justify-content-between mb-1">
            <div className="d-flex">
              <LocationSelectHeader
                text={locationName}
                onIconClick={this.changeLocation.bind(this)}
              />
            </div>
          </div>
        </div>
        <div id="pricing-table">
          {!loading &&
          surveyResponse !== null &&
          surveyResponse?.report.length != 0 ? (
            <>
              <ResponseList
                surveyResponse={surveyResponse?.report}
                goToOrderSession={this.goToOrderSession.bind(this)}
              />
              <Pagination
                onPageChange={this.onPageChange.bind(this)}
                page={this.state.page}
                count={surveyResponse.total}
              />
            </>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    surveyresponse: {
      allsurveyresponse: { data: surveyResponse, loading },
    },
  } = state;
  return {
    surveyResponse: surveyResponse,
    loading: loading,
  };
};

export default connect(mapStateToProps, {
  getAllSurveyResponse,
})(SurveyResponse);
