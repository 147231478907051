import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import CONSTANTS from "../../constants";
import Swal from "sweetalert2";

const AddIcon = () => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);



  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [selectImages, setSelectedImages] = useState(null);
  const [deleteImagePath, setDeleteImagePath] = useState(null);
  const [loadingIcons, setLoadingIcons] = useState(true);
  const [loadingSaveIcon, setLoadingSaveIcon] = useState(false);

  // const [icons, setIcons] = useState([]);
  // useEffect(() => {
  //   fetch(`${CONSTANTS.API_URL}/admin/addIcons/get`)
  //     .then((response) => response.json())
  //     .then((data) => setIcons(data.message))
  //     .catch((err) => console.log(err));
  // }, []);
  const [icons, setIcons] = useState([]);
  useEffect(() => {
    if (loadingIcons) {
      fetch(`${CONSTANTS.API_URL}/admin/addIcons/get`)
        .then((response) => response.json())
        .then((data) => setIcons(data.message))
        .catch((err) => console.log(err))
        .finally(() => setLoadingIcons(false));
    }
  }, [loadingIcons]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(e.target.files[0]);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  
  const SaveIcon = async () => {
    if (loadingSaveIcon) {
      return;
    }

    setLoadingSaveIcon(true);
    const fileInput = document.querySelector("#iconFileInput");
    const formData = new FormData();
  
    if (fileInput.files.length > 0) {
      formData.append("fileName", fileInput.files[0]);
    } else {
      Swal.fire({
        icon: "info",
        text: "Please Upload Icon Image",
      });
      return; 
    }
  
    try {
      const response = await fetch(`${CONSTANTS.API_URL}/admin/addIcons/save`, {
        method: "POST",
        body: formData,
        headers: {},
      });
  
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: t('icon-added'),
          text: t('icon-added-successfully'),
          showConfirmButton: false,
          allowOutsideClick: false, 
        });
  
        setFile(null);
        setTimeout(() => {
          window.location.reload(true);
        }, 2200);
      } else {
        throw new Error(t('could-not-update-icons'));
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoadingSaveIcon(false);
    }
  };
  

  const SelectIcon = (icon) => {
    if (selectImages == icon) {
      setSelectedImages(null);
      setSelectedImages(icon.id);
    } else {
      setSelectedImages(icon);
    }

    console.log(icon);
    console.log(deleteImagePath);
  };

  const DeleteIcon = () => {
    
    if (deleteImagePath !== null) {
      let response = fetch(
        `${CONSTANTS.API_URL}/admin/addIcons/delete/${deleteImagePath}`,
        {
          method: "DELETE",
          headers: { ...CONSTANTS.REQUEST_HEADERS },
        }
      );
      if (response) {
        Swal.fire({
          icon: "success",
          title: t('icon-deleted'),
          text: t('icon-deleted-successfully'),
          showConfirmButton: false,
          allowOutsideClick: false, 
        });

        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
        return response;
      }
      throw new Error(t('could-not-delete-the-icon'));
    } else {
      Swal.fire({
        icon: "info",
        text: t('you-must-select-an-icon-for-deletion'),
      });
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <Card>
          <div style={{ display: "block", margin: "0px auto" }}>
            <h1
              style={{ textAlign: "center", color: "#6f7275", margin: "20px" }}
            >
              {t('add-icons')}{' '}
            </h1>

            <div
              style={{
                marginBottom: "20px",
                marginLeft: "12%",
              }}
            >
              {selectedImage ? (
                <div>
                  <img
                    src={selectedImage}
                    alt={t('selected')}
                    style={{
                      width: "200px",
                      height: "200px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      border: selectImages == icons.id ? "2px solid black" : "none",
                    }}
                  />
                </div>
              ) : (
                <div>
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/000/500/167/original/add-icon-design-vector.jpg"
                    alt={t('selected')}
                    style={{ maxWidth: "200px", justifyContent: "center" }}
                    id="iconImage"
                  />
                </div>
              )}

              <input
                id="iconFileInput"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />
            </div>

            <div
              style={{
                paddingBottom: "40px",
                marginLeft: "30%",
              }}
            >
              <button className="btn btn-primary"
                onClick={() => SaveIcon()}
                disabled={!file}
              >
                {t('save')}{' '}
              </button>
            </div>
          </div>
        </Card>
      </div>

      <div className="col-md-6">
        <Card>
          <h1 style={{ textAlign: "center", color: "#6f7275", margin: "20px" }}>
            {t('available-icons')}{' '}
          </h1>
          <div style={{ margin: "20px auto", display: "block" }}>
            {icons.length > 0 ? (
              <div>
                {icons.map((icon) => (
                  <img
                    key={icon.id}
                    style={{
                      width: "100px",
                      height: "100px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      border:
                        selectImages == icon.id
                          ? "2px solid black"
                          : "none",
                    }}
                    src={`${CONSTANTS.API_URL}${icon.fileName}`}
                    alt={t('icon')}
                    className={selectImages == icon ? "selected" : ""}
                    onClick={() => {
                      SelectIcon(icon);
                      setDeleteImagePath(icon.id);
                    }}
                  />
                ))}
              </div>
            ) : null}
          </div>
          <div
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              margin: "0px auto",
              display: "block",
            }}
          >
            <button className="btn btn-danger" onClick={() => DeleteIcon()}>
              {t('delete')}{' '}
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddIcon;
