import CONSTANTS from "../../../constants";
import {getLocationPaymentData} from "../../../services/PaymentMethodService";

export function getAllPaymentMethods (loc_id) {
    return async dispatch => {
        try {
            dispatch({ type: CONSTANTS.ACTIONS.PAYMENT_METHODS.GET, payment_methods: [], loading: true });
            let payment_methods = await getLocationPaymentData(loc_id);
            dispatch({ type: CONSTANTS.ACTIONS.PAYMENT_METHODS.GET, payment_methods, loading: false });
        }
        catch (error) {
            console.log(error);
            dispatch({ type: CONSTANTS.ACTIONS.PAYMENT_METHODS.GET, payment_methods: [], loading: false });
        }
    }
}