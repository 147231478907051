import React from "react";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  ButtonGroup,
} from "reactstrap";
import LocationService from "../../services/LocationService";
import CONSTANTS from "../../constants";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import CurrencyService from "../../services/CurrencyService";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import { getAllCountries, createCurrency } from "../../redux/actions/currency";
import Toggle from "react-toggle";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class CurrencyForm extends React.Component {
  state = {
    activeStep: 0,
    isOpenSelect: false,
    isEditMode: false,
    currency: {
      currency_id: "",
      currency_name: "",
      country_id: "",
      currency_code: 0,
      currency_symbol: "",
      symbol_position: "",
      currency_rate: "",
      thousand_sign: "",
      decimal_sign: "",
      decimal_position: "",
      currency_status: 0,
    },
    location: {
      loc_id: 0,
      name: this.props.t('all-venues'),
      Currency: {
        currency_code: "PKR",
      },
      decimal_places: 2,
    },
    loading: false,
    saving: false,
    errorSaving: false,
    errorWallet: false,
    users: [],
    isOpen: false,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: this.props.t('wallet'),
      header: this.props.t('wallet'),
    },
  };

  async getCurrencyFromId(id) {
    let currency = await CurrencyService.getCurrency(id);
    return currency;
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    let currency = this.state.currency;
    await this.getCountries();
    if (window.location.pathname.indexOf("edit") !== -1) {
      const parts = window.location.pathname.split("/");
      currency = await this.getCurrencyFromId(parts[parts.length - 1]);
      currency.currency_id = parts[parts.length - 1];
      await this.setState({ currency });
      await this.setState({ editMode: true });
    }
    this.setState({ loading: false });
  }

  async getCountries() {
    return this.props.getAllCountries();
  }

  async getStats(loc_id) {
    await this.props.getCustomersStats(loc_id, this.state.filters);
  }

  onBlur() {
    this.filterUpdated();
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  toggleItemsModal() {
    this.setState({ isMenuItemModalOpen: !this.state.isMenuItemModalOpen });
  }

  async updateValue(value, type) {
    if (!isNaN(value) && value) value = parseInt(value);
    let currency = JSON.parse(JSON.stringify(this.state.currency));
    let obj = currency;
    let parts = type.split(".");
    const n = parts.length - 1;
    for (let i = 0; i < n; i++) obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ currency });
  }

  async setOpen(open) {
    await this.setState({ isOpen: open });
  }

  async setOpenSelect() {
    await this.setState({ isOpenSelect: !this.state.isOpenSelect });
  }

  async saveCurrency() {
    const {
      state: { currency },
    } = this;
    await this.props.createCurrency(currency);
    await this.props.history.replace("/currencies");
  }

  handleStepChange(activeStep) {
    this.setState({ activeStep });
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  onConditionChange = (selectedOption) => {
    this.updateValue(selectedOption.value, "plan_time");
  };

  render() {
    const { t } = this.props;

    const {
      props: { loading, countries },
    } = this;

    const {
      state: { currency, errorSaving, confirmationDialog, editMode },
    } = this;
    const {
      currency_name,
      country_id,
      currency_code,
      currency_symbol,
      symbol_position,
      currency_rate,
      thousand_sign,
      decimal_sign,
      decimal_position,
      currency_status,
    } = currency;
    // let currencyOptions = currencies.data ? ViewHelper.toOptions(currencies.data, { idColumn: 'currency_id', valueColumns: ['currency_code', 'currency_name'], joiner: ' - ' }, { allowEmpty: true }) : '';
    if (loading) return <SpinnerComponent />;
    return (
      <div className="location">
        {countries && (
          <Card>
            <CardHeader className="flex-column align-items-start">
              {errorSaving ? (
                <span className="danger">{t('unable-to-save-currency')}</span>
              ) : null}
            </CardHeader>
            <CardBody>
              <AvForm onValidSubmit={() => this.saveCurrency()}>
                <Row>
                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label>{t('title')}</Label>
                      <AvInput
                        name="currency_name"
                        type="text"
                        value={currency_name}
                        required
                        onChange={(e) =>
                          this.updateValue(e.target.value, "currency_name")
                        }
                      />
                      <AvFeedback>{t('please-enter-currency-title')}</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t('country')} </Label>
                      <AvField
                        type="select"
                        name="country_id"
                        value={country_id}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "country_id")
                        }
                      >
                        {countries &&
                          countries.map((row) => {
                            return (
                              <option value={row.country_id}>
                                {row.country_name}
                              </option>
                            );
                          })}
                      </AvField>
                      <AvFeedback>
                        {t('please-select-currency-symbol-position')} </AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t('code')} </Label>
                      <AvInput
                        name="currency_code"
                        required
                        type="text"
                        value={currency_code}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "currency_code")
                        }
                      />
                      <AvFeedback>{t('please-enter-currency-code')}</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t('symbol')} </Label>
                      <AvInput
                        name="currency_symbol"
                        required
                        type="text"
                        value={currency_symbol}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "currency_symbol")
                        }
                      />
                      <AvFeedback>{t('please-enter-currency-symbol')}</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t('symbol-position')} </Label>
                      <AvField
                        type="select"
                        name="symbol_position"
                        value={symbol_position}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "symbol_position")
                        }
                      >
                        <option value="0">{t('left')}</option>
                        <option value="1">{t('right')}</option>
                      </AvField>
                      <AvFeedback>
                        {t('please-select-currency-symbol-position')} </AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t('rate')} </Label>
                      <AvInput
                        name="currency_rate"
                        required
                        type="number"
                        min="1"
                        value={currency_rate}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "currency_rate")
                        }
                      />
                      <AvFeedback>{t('please-enter-your-currency-rate')}</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t('thousand-sign')} </Label>
                      <AvInput
                        name="thousand_sign"
                        required
                        type="text"
                        value={thousand_sign}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "thousand_sign")
                        }
                      />
                      <AvFeedback>{t('please-enter-your-thousand-sign')}</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t('decimal-sign')} </Label>
                      <AvInput
                        name="decimal_sign"
                        required
                        type="text"
                        value={decimal_sign}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "decimal_sign")
                        }
                      />
                      <AvFeedback>{t('please-enter-your-decimal-sign')}</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t('decimal-place')} </Label>
                      <AvInput
                        name="decimal_position"
                        required
                        type="text"
                        value={decimal_position}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "decimal_position")
                        }
                      />
                      <AvFeedback>{t('please-enter-your-decimal-place')}</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <Row className="mx-50 my-1">
                      <h5>{t('status')}</h5>
                      <Toggle
                        name={"currency_status"}
                        defaultChecked={currency_status}
                        checked={currency_status}
                        className="switch-danger ml-2 align-self-baseline"
                        onChange={this.updateValue.bind(
                          this,
                          currency_status === 0 ? 1 : 0,
                          "currency_status"
                        )}
                      />
                    </Row>
                  </Col>
                </Row>
                <Button color="primary" size="md">
                  <span className="ml-1 mr-1 d-md-inline ">{t('submit')}</span>
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        )}
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={"OK"}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    currency: {
      allcountries: { data: countries, loading: loading },
    },
  } = state;

  return { countries, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getAllCountries, createCurrency })(CurrencyForm)
);
