import React, { Component } from "react";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { connect } from "react-redux";

import { getLocation } from "../../redux/actions/locations";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import TimeHelper from "../../helpers/TimeHelper";

import CONSTANTS from "../../constants";
import LocationService from "../../services/LocationService";
import AnalyticsService from "../../services/AnalyticsService";
import ItemsGraphView from "./ItemsGraphView";
import CoRelationItemsList from "./coRelationItemsList";
import BestSoldItemsList from "./bestSoldItemsList";
import TopSessionTimeList from "./topSessionTimeList";
import TableAverageSpend from "./tableAverageSpend";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class LocationStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: {
        loc_id: 0,
        name: this.props.t("all-venues"),
        Currency: {
          currency_code: "PKR",
        },
        decimal_places: 2,
      },
      loading: true,
      filters: {
        startDate: TimeHelper.getFirstDayOf2018(),
        endDate: TimeHelper.getCurrentDate(),
        orderBy: "total_sales",
        order: "desc",
      },
      conrelation_item_graph: null,
      conrelation_item_list: null,
      bestSoldItemGraphView: null,
      bestSoldItemDataForList: null,
      topSessionAvgTime: null,
      topSessionTimeList: null,
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id) {
    this.setState({ loading: true });
    let location;
    if (loc_id !== 0) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = {
        loc_id: 0,
        name: this.props.t("all-venues"),
        Currency: {
          currency_code: "PKR",
        },
        decimal_places: 2,
      };
    }
    let analytic = await AnalyticsService.getLocationStats(
      loc_id,
      this.state.filters
    );
    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(location)),
      conrelation_item_graph: analytic.conrelation_item_graph,
      conrelation_item_list: analytic.corelation_table_items,
      bestSoldItemGraphView: analytic.bestSoldItemGraphView,
      bestSoldItemDataForList: analytic.bestSoldItemDataForList,
      topSessionAvgTime: analytic.getTopSessionGraph,
      topSessionTimeList: analytic.getTopSessionsList,
    });
  }

  onBlur() {
    if (this.state.location)
      this.setState({ selectLocation: !this.state.selectLocation });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  filterUpdated(date) {
    const {
      state: { filters, location },
    } = this;
    filters.startDate = TimeHelper.toFormattedDate(
      date[0],
      CONSTANTS.DATES.FORMAT.DB_DATE
    );
    if (date.length > 1)
      filters.endDate = TimeHelper.toFormattedDate(
        date[1],
        CONSTANTS.DATES.FORMAT.DB_DATE
      );
    else filters.endDate = TimeHelper.getCurrentDate();

    this.setState({ filters: filters }, () => {
      this.getStats(location.loc_id);
    });
  }

  render() {
    const { t } = this.props;

    const {
      state: {
        selectLocation,
        loading,
        columns,
        filters,
        location: stateLocation,
        conrelation_item_graph,
        conrelation_item_list,
        bestSoldItemDataForList,
        bestSoldItemGraphView,
        topSessionAvgTime,
        topSessionTimeList,
      },
    } = this;

    if (loading) return <SpinnerComponent />;

    const locationName = stateLocation ? stateLocation.name : "";

    if (selectLocation)
      return (
        <SelectLocation
          showAll={true}
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <div className="location">
        <div className="mb-50">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.toggleLocation.bind(this)}
          />
        </div>

        {!loading && conrelation_item_graph ? (
          <>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <ItemsGraphView
                  graph={conrelation_item_graph}
                  title={t("frequently-sold-co-items-stats")}
                  type={"bar"}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <CoRelationItemsList
                  data={conrelation_item_list}
                  showDateFilter={false}
                  exportBtn={false}
                  pagination={false}
                  showPaginationBottom={false}
                />
              </div>
            </div>
          </>
        ) : null}
        {!loading && bestSoldItemDataForList ? (
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <ItemsGraphView
                graph={bestSoldItemGraphView}
                title={t("best-sold-items-stats")}
                type={"donut"}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <BestSoldItemsList
                data={bestSoldItemDataForList}
                showDateFilter={false}
                exportBtn={false}
                pagination={false}
                showPaginationBottom={false}
              />
            </div>
          </div>
        ) : null}

        {!loading && topSessionTimeList ? (
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <ItemsGraphView
                graph={topSessionAvgTime}
                title={t("top-time-based-sessions")}
                type={"bar"}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <TopSessionTimeList
                data={topSessionTimeList}
                showDateFilter={false}
                exportBtn={false}
                pagination={false}
                showPaginationBottom={false}
              />
            </div>
          </div>
        ) : null}
        {!loading && (
          <div className="row">
            <div className="col-md-12">
              <TableAverageSpend location={this.state.location} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    analytics: {
      itemsStats: { data: statsData, loading: statsLoading },
    },
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
  } = state;

  return {
    stats: statsData,
    location: locationData,
    loading: statsLoading || locationLoading,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getLocation })(LocationStats)
);
