import {Menu, MenuItem, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import RegisterDetailsModal from "./navbar/registerDetailsModal";

const SmallScreensMainMenu = ({open, anchorEl, setAnchorEl, menuList}) => {
    const history = useHistory();
    const [fullScreen, setFullScreen] = useState(false);
    const [openModal, setOpenModal] = useState({
        openRegisterDetailModal: false
    })

    useEffect(() => {
        if (document.fullscreenElement === null) {
            setFullScreen(false);
        } else {
            setFullScreen(true);
        }
    }, []);

    const handleMenuButtonClick = async (index) => {
        if (index === 10) {
            history.push("/");
        } else if (index === 9) {
            setOpenModal({...openModal, openRegisterDetailModal: true})
        } else if (index === 13) {
            await handleScreenChange();
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleScreenChange = async () => {
        try {
            if (document.fullscreenElement === null) {
                await document.documentElement.requestFullscreen();
                setFullScreen(true);
            } else {
                await document.exitFullscreen();
                setFullScreen(false);
            }
        } catch (err) {
            console.error("Error attempting to toggle fullscreen:", err);
        }
    }

    return(
        <>
            <Menu
                id="items-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu',
                }}
            >
                {menuList.map((menuItem, index)=> (
                    <MenuItem key={index} sx={{columnGap: '1rem'}} onClick={()=> handleMenuButtonClick(index)}>
                        {index !== 13 ? menuItem.icon : (fullScreen ? menuItem.offIcon : menuItem.onIcon)}
                        <Typography variant='h6' sx={{fontSize: '1rem'}}>{index !== 13 ? menuItem.text : (fullScreen ? menuItem.offText : menuItem.onText)}</Typography>
                    </MenuItem>
                ))}
            </Menu>

            {openModal.openRegisterDetailModal && <RegisterDetailsModal openModal={openModal} setOpenModal={setOpenModal}/>}
        </>
    )
}

export default SmallScreensMainMenu;