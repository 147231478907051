import CONSTANTS from '../../../constants';
import ProductPricingService from '../../../services/ProductPricingService';

export function getAllProductPricing() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.STAFF.All_PRODUCT_PRICING,
        productPrcingList: [],
        loading: true,
      });
      let productsPricing =
        await ProductPricingService.getAllProductPricingList();
      dispatch({
        type: CONSTANTS.ACTIONS.STAFF.All_PRODUCT_PRICING,
        productPrcingList: productsPricing,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.STAFF.All_PRODUCT_PRICING,
        productPrcingList: [],
        loading: false,
      });
    }
  };
}
