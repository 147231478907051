import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import { getAllSessionTables } from "../../redux/actions/tables";
import "../../assets/scss/pages/page-pricing.scss";
import ListSessionTablesStatus from "./ListSessionTablesStatus";
import { Button } from "reactstrap";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import LocationService from "../../services/LocationService";
import TableService from "../../services/TableService";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import OrderService from "../../services/OrderService";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import CONSTANTS from "../../constants";
import socketService from "../../helpers/socket";

class SessionTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: "",
      location: null,
      image: null,
      selectLocation: false,
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
      loading: true,
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }
  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation) await this.props.getAllSessionTables(loc_id);
    loc = await LocationService.getLocationFromServer(loc_id);
    console.log(loc);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
      currency: loc.Currency.currency_symbol,
    };
    console.log("LOCATION", location);
    this.setState({ location });
  }

  onChangeToggle = async (event, table, type) => {
    let tableData = [];
    tableData.push(table);
    await TableService.changeTableStatusById(
      this.state.location.loc_id,
      tableData
    );
  };

  handleChange = (state) => {
    let tables = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      tables.push(state.selectedRows[i].table_id);
    }
    this.setState({ tables: tables });
  };

  async updateTableStatus() {
    if (this.state.tables.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.setState({ loading: true });
          await this.props.changeTableStatus(
            this.state.location.loc_id,
            this.state.tables
          );
          await this.setState({ loading: false });
        },
        null,
        this.props.t("are-you-sure-you-want-to-status-of-this-tables"),
        this.props.t("tables")
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t("please-select-atleast-one-table-for-change-status"),
        this.props.t("warning")
      );
    }
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  closeLocationSession = (session) => {
    const location = this.state.location;
    this.showConfirmationDialog(
      async () => {
        this.setState({ loading: true });
        await OrderService.closeLocationSession(session);
        socketService.emit("update_order", {
          location_id: location.loc_id
        })
        this.props.history.push(`${CONSTANTS.BASE_URL}sessions/${session}`);
      },
      null,
      this.props.t("are-you-sure-you-want-to-close-this-session"),
      this.props.t("close-session")
    );
  };

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }
  editTable(id) {
    this.props.history.replace("/tables/edit/" + id);
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    await this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    await this.setState({ selectLocation: false, loading: false });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  openImage(index) {
    this.setState({ image: index });
  }

  closeImage() {
    this.setState({ image: null });
  }

  render() {
    const { t } = this.props;
    const {
      props: { tables },
    } = this;
    const {
      state: {
        confirmationDialog,
        errorDialog,
        selectLocation,
        location,
        loading,
        image,
      },
    } = this;

    const locationName = location ? location.name : "";

    // TODO: Implement proper error handling
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <>
        {image && (
          <Lightbox
            showImgCount={false}
            isOpen={image !== null}
            onCloseRequest={this.closeImage.bind(this)}
            mainSrc={image}
          />
        )}
        <div className="display-block mb-1">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.changeLocation.bind(this)}
          />
        </div>
        <div id="pricing-table">
          <div className="ecommerce-application">
            {!loading && tables ? (
              <ListSessionTablesStatus
                tables={tables}
                location={location}
                closeLocationSession={(session) =>
                  this.closeLocationSession(session)
                }
              />
            ) : null}
          </div>
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t("cancel")}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={t("ok")}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    tables: {
      allSessiontables: { data: tables, loading: loading },
    },
  } = state;

  return { tables, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getAllSessionTables })(SessionTables)
);
