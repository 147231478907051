// import React, { useState, useEffect } from "react";
// import {
//   Card,
//   Button as ReactstrapButton,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
// } from "reactstrap";
// import {
//   Grid,
//   Button,
//   TextField,
//   Stack,
//   MenuItem,
//   Switch,
//   FormControlLabel,
//   Typography,
//   CardContent,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import { useHistory } from "react-router-dom";
// import TimeSlotService from "../../services/TimeSlotsService";
// import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
// import { Alert, Snackbar } from "@mui/material";

// const StyledCard = styled(Card)(({ theme }) => ({
//   height: "100%",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-between",
//   boxShadow: "0 3px 5px 2px rgba(60, 179, 113, 0.3)",
//   borderRadius: 12,
//   padding: theme.spacing(2),
//   transition: "transform 0.3s ease-in-out",
//   "&:hover": {
//     transform: "scale(1.02)",
//     cursor: "pointer",
//     boxShadow:
//       "0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -2px rgba(0,0,0,0.05)",
//   },
// }));

// const LocationTimeSlots = () => {
//   const [timeSlots, setTimeSlots] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [openModal, setOpenModal] = useState(false);
//   const [newDay, setNewDay] = useState("");
//   const [openTime, setOpenTime] = useState("");
//   const [closeTime, setCloseTime] = useState("");
//   const [interval, setInterval] = useState("");
//   const [status, setStatus] = useState(true);
//   const [toastOpen, setToastOpen] = useState(false);
//   const [toastMessage, setToastMessage] = useState("");
//   const [toastSeverity, setToastSeverity] = useState("success");

//   useEffect(() => {
//     const fetchTimeSlots = async () => {
//       try {
//         const data = await TimeSlotService.getAllTimeSlots();
//         setTimeSlots(data.data);
//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching time slots:", error);
//         setIsLoading(false);
//       }
//     };

//     fetchTimeSlots();
//   }, []);

//   const history = useHistory();

//   const handleUpdateTimeSlot = (data) => {
//     const selectedDayData = timeSlots.find(
//       (slot) => slot.location_time_id === data
//     );
//     console.log("SELECTED DAY DATA", selectedDayData);
//   };

//   const handleShowTimeSlots = (dayName) => {
//     const selectedTimeSlots = timeSlots.find(
//       (slot) => slot.day_name === dayName
//     );
//     history.push({
//       pathname: "/selected-time-slots",
//       state: { selectedTimeSlots: selectedTimeSlots.location_slots, dayName },
//     });
//   };

//   const showToast = (message, severity) => {
//     setToastMessage(message);
//     setToastSeverity(severity);
//     setToastOpen(true);
//   };

//   const handleCreateNewDay = async () => {
//     const newDayObj = {
//       day_name: newDay,
//       start_time: openTime,
//       end_time: closeTime,
//       slot_interval: interval,
//       status: status,
//     };

//     try {
//       const response = await TimeSlotService.createTimeSlots(newDayObj);
//       const jsonResponse = await response.json();
//       if (response.ok) {
//         showToast(jsonResponse.message, "success");
//         const updatedTimeSlots = await TimeSlotService.getAllTimeSlots();
//         setTimeSlots(updatedTimeSlots.data);
//         setOpenModal(false);
//       } else {
//         showToast(jsonResponse.message, "error");
//       }
//     } catch (error) {
//       console.error("Error creating new day:", error);
//     }
//   };

//   const handleModalOpen = () => {
//     setOpenModal(true);
//   };

//   const handleModalClose = () => {
//     setOpenModal(false);
//   };

//   const handleEnableDisable = async (timeId, isChecked) => {
//     try {
//       const response = await TimeSlotService.updateDayStatus(timeId, isChecked);
//       const jsonResponse = await response.json();
//       if (response.ok) {
//         showToast("Day Status Updated Successfully", "success");
//         const updatedTimeSlots = await TimeSlotService.getAllTimeSlots();
//         setTimeSlots(updatedTimeSlots.data);
//       } else {
//         showToast(jsonResponse.message, "error");
//       }
//     } catch (error) {
//       console.error("Error updating status:", error);
//     }
//   };

//   if (isLoading) {
//     return <SpinnerComponent />;
//   }

//   return (
//     <Grid container spacing={3} justifyContent="center" alignItems="center">
//       <Grid item xs={12} textAlign="center">
//         <Button variant="contained" color="success" onClick={handleModalOpen}>
//           Create New Day
//         </Button>
//       </Grid>
//       <Grid container spacing={3}>
//         {timeSlots?.map((timeSlot) => (
//           <Grid item xs={12} sm={6} md={4} key={timeSlot.location_time_id}>
//             <StyledCard>
//               <CardContent
//                 style={{ display: "flex", justifyContent: "space-between" }}
//               >
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   <Typography variant="h6" component="div" gutterBottom>
//                     {timeSlot.day_name}
//                   </Typography>
//                 </div>
//                 <div>
//                   <FormControlLabel
//                     control={
//                       <Switch
//                         checked={timeSlot.status}
//                         onChange={(e) =>
//                           handleEnableDisable(
//                             timeSlot.location_time_id,
//                             e.target.checked
//                           )
//                         }
//                         color="success"
//                       />
//                     }
//                   />
//                 </div>
//               </CardContent>

//               <Button
//                 color="success"
//                 onClick={() => handleShowTimeSlots(timeSlot.day_name)}
//                 variant="contained"
//               >
//                 Show Time Slots
//               </Button>
//               <Button
//                 color="success"
//                 onClick={() => handleUpdateTimeSlot(timeSlot.location_time_id)}
//                 variant="contained"
//               >
//                 Update Time Slot
//               </Button>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>
//       <Modal isOpen={openModal} toggle={handleModalClose}>
//         <ModalHeader toggle={handleModalClose}>Create New Day</ModalHeader>
//         <ModalBody>
//           <TextField
//             select
//             label="Select Day"
//             fullWidth
//             value={newDay}
//             onChange={(e) => setNewDay(e.target.value)}
//             sx={{ mb: 2 }}
//           >
//             {[
//               "Monday",
//               "Tuesday",
//               "Wednesday",
//               "Thursday",
//               "Friday",
//               "Saturday",
//               "Sunday",
//             ].map((day) => (
//               <MenuItem key={day} value={day.toLowerCase()}>
//                 {day}
//               </MenuItem>
//             ))}
//           </TextField>
//           <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
//             <TextField
//               label="Opening Time"
//               type="time"
//               value={openTime}
//               onChange={(e) => setOpenTime(e.target.value)}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               inputProps={{
//                 step: 300, // 5 min
//                 style: { textAlign: "center" },
//               }}
//               sx={{ width: "50%" }}
//             />
//             <TextField
//               label="Closing Time"
//               type="time"
//               value={closeTime}
//               onChange={(e) => setCloseTime(e.target.value)}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               inputProps={{
//                 step: 300, // 5 min
//                 style: { textAlign: "center" },
//               }}
//               sx={{ width: "50%" }}
//             />
//           </Stack>
//           <TextField
//             label="Interval (minutes)"
//             type="number"
//             value={interval}
//             onChange={(e) => setInterval(parseInt(e.target.value))}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <FormControlLabel
//             control={
//               <Switch
//                 checked={status}
//                 onChange={(e) => setStatus(e.target.checked)}
//                 color="primary"
//               />
//             }
//             label="Status"
//           />
//         </ModalBody>
//         <ModalFooter>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleCreateNewDay}
//             sx={{ mr: 2 }}
//           >
//             Create Day
//           </Button>
//           <ReactstrapButton color="secondary" onClick={handleModalClose}>
//             Cancel
//           </ReactstrapButton>
//         </ModalFooter>
//       </Modal>
//       <Snackbar
//         open={toastOpen}
//         autoHideDuration={6000}
//         onClose={() => setToastOpen(false)}
//         anchorOrigin={{ vertical: "top", horizontal: "right" }}
//       >
//         <Alert
//           onClose={() => setToastOpen(false)}
//           severity={toastSeverity}
//           sx={{ width: "100%" }}
//         >
//           {toastMessage}
//         </Alert>
//       </Snackbar>
//     </Grid>
//   );
// };

// export default LocationTimeSlots;

import React, { useState, useEffect } from "react";
import {
  Card,
  Button as ReactstrapButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Grid,
  Button,
  TextField,
  Stack,
  MenuItem,
  Switch,
  FormControlLabel,
  Typography,
  CardContent,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import TimeSlotService from "../../services/TimeSlotsService";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { Alert, Snackbar } from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow: "0 3px 5px 2px rgba(60, 179, 113, 0.3)",
  borderRadius: 12,
  padding: theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
    cursor: "pointer",
    boxShadow:
      "0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -2px rgba(0,0,0,0.05)",
  },
}));

const LocationTimeSlots = () => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [newDay, setNewDay] = useState("");
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [interval, setInterval] = useState("");
  const [status, setStatus] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");
  const [selectedDayData, setSelectedDayData] = useState(null); // new state for selected day data

  useEffect(() => {
    const fetchTimeSlots = async () => {
      try {
        const data = await TimeSlotService.getAllTimeSlots();
        setTimeSlots(data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching time slots:", error);
        setIsLoading(false);
      }
    };

    fetchTimeSlots();
  }, []);

  const history = useHistory();

  const handleUpdateTimeSlot = (locationTimeId) => {
    const selectedDay = timeSlots.find(
      (slot) => slot.location_time_id === locationTimeId
    );
    if (selectedDay) {
      setSelectedDayData(selectedDay);
      setNewDay(selectedDay.day_name);
      setOpenTime(selectedDay.start_time);
      setCloseTime(selectedDay.end_time);
      setInterval(selectedDay.slot_interval);
      setStatus(selectedDay.status);
      setOpenModal(true);
    }
  };

  const handleShowTimeSlots = (dayName) => {
    const selectedTimeSlots = timeSlots.find(
      (slot) => slot.day_name === dayName
    );
    history.push({
      pathname: "/selected-time-slots",
      state: { selectedTimeSlots: selectedTimeSlots.location_slots, dayName },
    });
  };

  const showToast = (message, severity) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  const handleCreateNewDay = async () => {
    const dayObj = {
      day_name: newDay,
      start_time: openTime,
      end_time: closeTime,
      slot_interval: interval,
      status: status,
    };

    try {
      let response, jsonResponse;

      if (selectedDayData) {
        // Update existing day
        const updatedDayObj = {
          location_time_id: selectedDayData.location_time_id,
          ...dayObj,
        };
        response = await TimeSlotService.updateTimeSlots(updatedDayObj);
      } else {
        // Create new day
        response = await TimeSlotService.createTimeSlots(dayObj);
      }

      jsonResponse = await response.json();

      if (response.ok) {
        showToast(jsonResponse.message, "success");
        const updatedTimeSlots = await TimeSlotService.getAllTimeSlots();
        setTimeSlots(updatedTimeSlots.data);
        setOpenModal(false);
        setSelectedDayData("");
        setNewDay("");
        setOpenTime("");
        setCloseTime("");
        setInterval();
      } else {
        showToast(jsonResponse.message, "error");
      }
    } catch (error) {
      console.error("Error handling day:", error);
    }
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedDayData(null); // Reset selected day data when modal is closed
    setSelectedDayData("");
    setNewDay("");
    setOpenTime("");
    setCloseTime("");
    setInterval();
  };

  const handleEnableDisable = async (timeId, isChecked) => {
    try {
      const response = await TimeSlotService.updateDayStatus(timeId, isChecked);
      const jsonResponse = await response.json();
      if (response.ok) {
        showToast("Day Status Updated Successfully", "success");
        const updatedTimeSlots = await TimeSlotService.getAllTimeSlots();
        setTimeSlots(updatedTimeSlots.data);
      } else {
        showToast(jsonResponse.message, "error");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  if (isLoading) {
    return <SpinnerComponent />;
  }

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} textAlign="center">
        <Button variant="contained" color="success" onClick={handleModalOpen}>
          Create New Day
        </Button>
      </Grid>
      <Grid container spacing={3} mt={2}>
        {timeSlots?.map(
          (timeSlot) => (
            console.log("TIMESLOT", timeSlot),
            (
              <Grid item xs={12} sm={6} md={4} key={timeSlot.location_time_id}>
                <StyledCard>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" component="div" gutterBottom>
                      {timeSlot.day_name}
                    </Typography>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={timeSlot.status}
                          onChange={(e) =>
                            handleEnableDisable(
                              timeSlot.location_time_id,
                              e.target.checked
                            )
                          }
                          color="success"
                        />
                      }
                    />
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <TextField
                      label="Opening Time"
                      type="time"
                      value={timeSlot.start_time}
                      disabled={true}
                      sx={{ width: "40%" }}
                    />
                    <TextField
                      label="Opening Time"
                      type="time"
                      value={timeSlot.end_time}
                      disabled={true}
                      sx={{ width: "40%" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <Button
                      color="success"
                      onClick={() =>
                        handleUpdateTimeSlot(timeSlot.location_time_id)
                      }
                      variant="contained"
                    >
                      Update Time Slot
                    </Button>
                    <Button
                      color="success"
                      onClick={() => handleShowTimeSlots(timeSlot.day_name)}
                      variant="outlined"
                    >
                      Show Time Slots
                    </Button>
                  </Box>
                </StyledCard>
              </Grid>
            )
          )
        )}
      </Grid>
      <Modal isOpen={openModal} toggle={handleModalClose}>
        <ModalHeader toggle={handleModalClose}>
          {selectedDayData ? "Update Day" : "Create New Day"}
        </ModalHeader>
        <ModalBody>
          <TextField
            select
            label="Select Day"
            fullWidth
            value={newDay}
            onChange={(e) => setNewDay(e.target.value)}
            sx={{ mb: 2 }}
            disabled={!!selectedDayData}
          >
            {[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((day) => (
              <MenuItem key={day} value={day.toLowerCase()}>
                {day}
              </MenuItem>
            ))}
          </TextField>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <TextField
              label="Opening Time"
              type="time"
              value={openTime}
              onChange={(e) => setOpenTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300,
                style: { textAlign: "center" },
              }}
              sx={{ width: "50%" }}
            />
            <TextField
              label="Closing Time"
              type="time"
              value={closeTime}
              onChange={(e) => setCloseTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300,
                style: { textAlign: "center" },
              }}
              sx={{ width: "50%" }}
            />
          </Stack>
          <TextField
            label="Interval (minutes)"
            type="number"
            value={interval}
            onChange={(e) => setInterval(parseInt(e.target.value))}
            fullWidth
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                color="success"
              />
            }
            label="Status"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="contained"
            color="success"
            onClick={handleCreateNewDay}
            sx={{ mr: 2 }}
          >
            {selectedDayData ? "Update Day" : "Create Day"}
          </Button>
          <ReactstrapButton color="secondary" onClick={handleModalClose}>
            Cancel
          </ReactstrapButton>
        </ModalFooter>
      </Modal>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setToastOpen(false)}
          severity={toastSeverity}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default LocationTimeSlots;
