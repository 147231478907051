import React, { Component } from 'react';
import { connect } from 'react-redux';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getLocation } from '../../../redux/actions/locations';
import Lens from './lens.png';
import SpinnerComponent from '../../@vuexy/spinner/Fallback-spinner';
import SelectLocation from '../../shared/SelectLocation';
import LocationSelectHeader from '../../shared/LocationSelectHeader';

import LocationService from '../../../services/LocationService';
import { getMenuGroupedByCategory } from '../../../redux/actions/categories';
import BrandingService from '../../../services/BrandingService';
import ViewHelper from '../../../helpers/ViewHelper';
import ParamHelper from '../../../helpers/ParamHelper';
import toast, { Toaster } from 'react-hot-toast';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import CONSTANTS from '../../../constants';

let container = {
  width: '20%',
  textAlign: 'center',
  padding: '20px',
  cursor: 'pointer',
};

let imageContainer = {
  height: 250,
  width: '100%',
  borderRadius: 12,
  overflow: 'hidden',
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,
};
let imageCSS = { width: '100%', height: 'auto' };
let titleCSS = {
  fontSize: 16,
  color: 'black',
};

class CategoriesMobilePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
      branding: null,
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchMenu = true) {
    this.setState({ loading: initialize, refreshing: true });
    let locationUpdated = false;
    let branding = null;
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      branding = await BrandingService.getBranding(loc_id);
      await this.props.getLocation(loc_id);
      locationUpdated = true;
    }
    if (fetchMenu) {
      await this.getMenu(loc_id);
    }

    const customisedMenu = [];
    const {
      props: { menu },
    } = this;

    for (let i = 0; i < menu.length; i++) {
      customisedMenu.push({
        id: menu[i].category_id,
        title: menu[i].name,
        ...menu[i],
      });
    }

    this.setState({
      loading: false,
      menu: customisedMenu,
      location: JSON.parse(JSON.stringify(this.props.location)),
      refreshing: false,
      isOutlet: this.props.location.is_outlet,
      branding: branding,
    });
  }

  async getMenu(loc_id) {
    return this.props.getMenuGroupedByCategory(loc_id);
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.menu) !== JSON.stringify(this.props.menu) &&
      this.state.location &&
      !this.state.refreshing
    ) {
      this.refreshData(this.state.location.loc_id, false, false);
    }
  }

  render() {
    const { t } = this.props;
    // const t = () => {};

    const {
      props: { location },
    } = this;
    const {
      state: { selectLocation, loading, menu, branding },
    } = this;
    if (loading) return <SpinnerComponent />;
    console.log('branding color', branding, loading);
    const locationName = location ? location.name : '';
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      // <div className="location">
      //   <div className="display-flex flex-row justify-content-between mb-1">
      //     <div className="d-flex">
      //       <LocationSelectHeader
      //         text={locationName}
      //         onIconClick={this.toggleLocation.bind(this)}
      //       />
      //     </div>
      //   </div>
      //   <div className="display-flex flex-row justify-content-between mb-1">
      //     <div className="container">
      //       <div className="row">
      //         <div
      //           style={{
      //             background: 'black',
      //             borderRadius: '30px',
      //             padding: '3.5rem',
      //             display: 'flex',
      //             margin: 'auto',
      //           }}
      //           className="col-10"

      //           // className="col-12"
      //         >
      //           <button
      //             style={{
      //               background: 'black',

      //               borderRadius: '50%',
      //               width: '43px',
      //               height: '40px',
      //               display: 'flex',
      //               justifyContent: 'center',
      //               alignItems: 'center',
      //               position: 'absolute',
      //               zIndex: 1,
      //               top: '5px',
      //               right: '32rem',
      //             }}
      //           >
      //             <img
      //               src={Lens}
      //               alt="Lens Icon"
      //               style={{ width: '100%', height: '100%' }}
      //             />
      //           </button>
      //           <Card
      //             className="p-1 col-12"
      //             style={{
      //               backgroundColor: '#F2F2F2',

      //               overflow: 'auto',
      //             }}
      //           >
      //             <CardHeader
      //               className="mx-auto flex-column p-0 p-2 w-100"
      //               style={{ backgroundColor: `${branding?.h2_color}` }}
      //             >
      //               <h4
      //                 className="mb-0"
      //                 style={{ color: `${branding?.h2_text_color}` }}
      //               >
      //                 {t('categories')}{' '}
      //               </h4>
      //             </CardHeader>
      //             <CardBody className="p-0">
      //               {this.props?.location?.Banners?.length > 0 ? (
      //                 <div className="col-12">
      //                   <div className="row">
      //                     <div
      //                       className="col-6 p-0 mt-1"
      //                       style={{ maxHeight: '300px' }}
      //                     >
      //                       <img
      //                         src={`${CONSTANTS.API_URL}/restaurant_data/${this.props.location?.Banners[0].image}`}
      //                         alt={t('banner')}
      //                         width={'100%'}
      //                         height={'100%'}
      //                       />
      //                     </div>
      //                     {this.props.location.Banners.length > 1 ? (
      //                       <div
      //                         className="col-6"
      //                         style={{ maxHeight: '300px' }}
      //                       >
      //                         <div
      //                           className="row"
      //                           style={{ height: 'calc(100% / 2)' }}
      //                         >
      //                           <img
      //                             src={`${CONSTANTS.API_URL}/restaurant_data/${this.props.location?.Banners[1].image}`}
      //                             alt={t('banner')}
      //                             width={'100%'}
      //                             style={{ height: '100%' }}
      //                           />
      //                         </div>
      //                         {this.props.location.Banners.length > 2 ? (
      //                           <div
      //                             className="row"
      //                             style={{ height: 'calc(100% / 2)' }}
      //                           >
      //                             <img
      //                               src={`${CONSTANTS.API_URL}/restaurant_data/${this.props.location?.Banners[2].image}`}
      //                               alt={t('banner')}
      //                               width={'100%'}
      //                               style={{ height: '100%' }}
      //                             />
      //                           </div>
      //                         ) : null}
      //                       </div>
      //                     ) : null}
      //                   </div>
      //                 </div>
      //               ) : null}
      //             </CardBody>
      //             <CardBody>
      //               <div className="row">
      //                 <div className="col-12 text-center p-3">
      //                   <h3 className="text-uppercase m-0">
      //                     {t('select-a-category')}{' '}
      //                   </h3>
      //                 </div>
      //               </div>
      //               <div className="row">
      //                 <div className="col-12">
      //                   <div className="row">
      //                     {menu.slice(0, 10).map((item) => {
      //                       return (
      //                         <div
      //                           style={container}
      //                           onClick={() =>
      //                             this.props.history.push(
      //                               '/menu/menu-mobile-preview',
      //                               {
      //                                 category_data: item,
      //                               }
      //                             )
      //                           }
      //                         >
      //                           <div style={imageContainer} className="d-flex">
      //                             <img
      //                               src={`${CONSTANTS.API_URL}/restaurant_data/${item?.image}`}
      //                               alt={item.name}
      //                               style={imageCSS}
      //                               onError={({ currentTarget }) => {
      //                                 currentTarget.onerror = null; // prevents looping
      //                                 currentTarget.src = `${CONSTANTS.API_URL}/restaurant_data/${branding?.logo}`;
      //                               }}
      //                             />
      //                           </div>
      //                           <div style={titleCSS}>{item.name}</div>
      //                         </div>
      //                       );
      //                     })}
      //                   </div>
      //                 </div>
      //               </div>
      //             </CardBody>
      //           </Card>
      //           <button
      //             style={{
      //               background: 'transparent',
      //               border: '2px solid white',
      //               borderRadius: '50%',
      //               width: '43px',
      //               height: '40px',
      //               display: 'flex',
      //               justifyContent: 'center',
      //               alignItems: 'center',
      //               position: 'absolute',
      //               zIndex: 1,
      //               bottom: '20px',
      //               left: '32rem',
      //               color: 'white',
      //             }}
      //           >
      //             <FontAwesomeIcon icon={faCircleNotch} />
      //           </button>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div className="location">
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
          </div>
        </div>
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="container">
            <div className="row">
              <div
                style={{
                  background: 'black',
                  borderRadius: '30px',
                  padding: '3.5rem',
                  display: 'flex',
                  margin: 'auto',
                }}
                className="col-lg-9 col-12"
              >
                <button
                  style={{
                    background: 'black',
                    borderRadius: '50%',
                    width: '43px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: 1,
                    top: '5px',
                    right: '46%',
                  }}
                >
                  <img
                    src={Lens}
                    alt="Lens Icon"
                    style={{ width: '100%', height: '100%' }}
                  />
                </button>
                <Card
                  className="p-1 col-12"
                  style={{
                    backgroundColor: '#F2F2F2',
                    overflow: 'auto',
                  }}
                >
                  <CardHeader
                    className="mx-auto flex-column p-0 p-2 w-100"
                    style={{ backgroundColor: `${branding?.basecolor}` }}
                  >
                    <h4
                      className="mb-0"
                      style={{ color: `${branding?.h2_text_color}` }}
                    >
                      {t('categories')}{' '}
                    </h4>
                  </CardHeader>
                  <CardBody className="p-0">
                    {this.props?.location?.Banners?.length > 0 ? (
                      <div className="col-12">
                        <div className="row">
                          {this.props.location.Banners.map((banner, index) => (
                            <div
                              className="col-6 p-0 mt-1"
                              style={{ maxHeight: '300px' }}
                              key={index}
                            >
                              <img
                                src={`${CONSTANTS.API_URL}/restaurant_data/${banner.image}`}
                                alt={t('banner')}
                                width={'100%'}
                                height={'100%'}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </CardBody>
                  <CardBody>
                    <div className="row">
                      <div className="col-12 text-center p-3">
                        <h3 className="text-uppercase m-0">
                          {t('select-a-category')}{' '}
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      {menu.slice(0, 10).map((item, index) => (
                        <div
                          className="col-lg-2 col-12"
                          style={container}
                          onClick={() =>
                            this.props.history.push(
                              '/menu/menu-mobile-preview',
                              {
                                category_data: item,
                              }
                            )
                          }
                          key={index}
                        >
                          <div style={imageContainer} className="d-flex">
                            <img
                              src={`${CONSTANTS.API_URL}/restaurant_data/${item?.image}`}
                              alt={item.name}
                              style={imageCSS}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = `${CONSTANTS.API_URL}/restaurant_data/${branding?.logo}`;
                              }}
                            />
                          </div>
                          <div style={titleCSS}>{item.name}</div>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
                <button
                  style={{
                    background: 'transparent',
                    border: '2px solid white',
                    borderRadius: '50%',
                    width: '43px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: 1,
                    bottom: '20px',
                    left: '48%',
                    color: 'white',
                  }}
                >
                  <FontAwesomeIcon icon={faCircleNotch} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    categories: {
      categoryMenu: { data: menuData, loading: menuLoading },
    },
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
    menu: {
      dummyItem: { data: dummyItem, loading: dummyItemLoading },
    },
  } = state;
  return {
    dummyItem,
    menu: menuData,
    location: locationData,
    loading: menuLoading || locationLoading || dummyItemLoading,
  };
}

export default withTranslation('global')(
  connect(mapStateToProps, {
    getLocation,
    getMenuGroupedByCategory,
  })(CategoriesMobilePreview)
);
