import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvField,
} from "availity-reactstrap-validation";
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { getAllPackageProductCategory } from "../../redux/actions/packageproductcategory";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import PackageProductCategoryService from "../../services/PackageProductCategoryService";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import CONSTANTS from "../../constants";

import { useTranslation } from "react-i18next";

const EditSessionForm = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  console.log("PROPS", props);
  const history = useHistory();

  useEffect(() => {
    const data = async () => {
      await props.getAllPackageProductCategory();
    };
    data();
  }, []);
  const { data: packageproductcategory, loading } = useSelector(
    (store) => store.packageproductcategory.allProductPackageCategoryItems
  );
  console.log("packageproductcategory", packageproductcategory);

  const { packageId } = useParams();
  console.log("PACKAGEID", packageId);

  const [title, setTitle] = useState(props.title || "");
  const [price, setPrice] = useState(props.price || "");
  const [categoryTag, setCategoryTag] = useState(props.categoryTag || "");
  const [selectedPackageProductId, setSelectedPackageProductId] =
    useState(packageId);
  const [packageProductName, setPackageProductName] = useState(
    props.packageProductName || ""
  );
  console.log("PackageProductname", packageProductName);

  const [pricePerType, setPricePerType] = useState(props.pricePerType || "");
  const [description, setDescription] = useState(props.description || "");
  const [multipleQuantity, setMultipleQuantity] = useState(
    props.multipleQuantity || ""
  );
  const [physicalSupport, setPhysicalSupport] = useState(
    props.physicalSupport || ""
  );
  if (loading) return <SpinnerComponent />;
  const { package_categories, package_products, priceType } =
    packageproductcategory;

  const packageCategoriesOptions = package_categories.map((category) => ({
    value: category.tag,
    label: category.name,
  }));

  console.log(packageCategoriesOptions, "packageCategoriesOptions");
  const filteredPackageProducts = package_products.filter(
    (product) => product.category_tag === categoryTag
  );

  const filteredPkgProducts = package_products.filter(
    (product) => product.name === packageProductName
  );
  console.log("FILTERED", filteredPkgProducts);

  const packageProductsOptions = filteredPackageProducts.map((product) => ({
    value: product.id,
    label: product.name,
  }));
  console.log("FILTERED", packageProductsOptions);
  const pkgOptions = filteredPkgProducts.map((product) => ({
    value: product.id,
    label: product.name,
  }));

  const defaultCategoryOption = packageCategoriesOptions.find(
    (option) => option.label === categoryTag
  );

  const priceTypeOptions = Object.keys(priceType).map((key) => ({
    value: key,
    label: priceType[key],
  }));
  const defaultOption = priceTypeOptions.find(
    (option) => option.label === priceType[pricePerType]
  );

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = {
        title,
        price,
        categoryTag,
        description,
        multipleQuantity,
        physicalSupport,
        pricePerType,
        selectedPackageProductId,
      };
      // if (!isValidProductOption(categoryTag, selectedPackageProductId)) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error!",
      //     text: "Please select a valid product option for the chosen category.",
      //   });
      //   return; // Stop further execution
      // }
      const response =
        await PackageProductCategoryService.updatePackageProductCategory(
          formData,
          packageId
        );
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: t("package-product-update-successfully"),
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          history.push(`${CONSTANTS.BASE_URL}packages`);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: t(
            "an-error-occurred-while-creating-the-package-product-category"
          ),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: `An error occurred: ${error.message}`,
      });
    }
    // console.log('PacakgeId:', packageId);
    // console.log('Package Product id:', selectedPackageProductId);
    // console.log('Title:', title);
    // console.log('Description:', description);
    // console.log('Price:', price);
    // console.log('Price Per Type:', pricePerType);
    // console.log('Multiple Quantity:', multipleQuantity);
    // console.log('Physical Support:', physicalSupport);
  };

  // const isValidProductOption = (selectedCategory, selectedProductId) => {
  //   // Assuming packageProductsOptions is the array containing valid product options
  //   const validProductOptions = packageProductsOptions.map(
  //     (option) => option.value
  //   );

  //   console.log(validProductOptions, "product");
  //   return validProductOptions.includes(selectedProductId);
  // };

  return (
    <div>
      <div className="location">
        <Card>
          <CardHeader className="flex-column align-items-start"></CardHeader>
          <CardBody>
            <AvForm>
              <Row>
                <Col md="6" sm="6">
                  <AvGroup>
                    <Label> {t("title")} </Label>
                    <AvInput
                      type="text"
                      name="title"
                      value={title}
                      onChange={(e) => {
                        console.log("Title onChange:", e.target.value);
                        setTitle(e.target.value);
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col md="6" sm="6">
                  <Label> {t("categories")} </Label>
                  <Select
                    name="pricePerType"
                    options={packageCategoriesOptions}
                    defaultValue={defaultCategoryOption}
                    className="React"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      console.log(
                        "pricePerType onChange:",
                        selectedOption.value
                      );
                      setCategoryTag(selectedOption.value);
                    }}
                  />
                </Col>

                <Col md="6" sm="6">
                  <Label> {t("package-product-options")} </Label>
                  <Select
                    name="pricePerType"
                    options={packageProductsOptions}
                    // defaultValue={packageId}
                    defaultValue={pkgOptions.find(
                      (productOption) =>
                        productOption.label === packageProductName
                    )}
                    className="React"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      console.log("Package onChange:", selectedOption.value);
                      setSelectedPackageProductId(selectedOption.value);
                    }}
                  />
                </Col>

                <Col md="6" sm="6">
                  <AvGroup>
                    <Label> {t("price-0")} </Label>
                    <AvInput
                      type="text"
                      name="price"
                      value={price}
                      onChange={(e) => {
                        console.log("Price onChange:", e.target.value);
                        setPrice(e.target.value);
                      }}
                    />
                  </AvGroup>
                </Col>
                {/* <Col md="6" sm="6">
                  <Label> Price Type </Label>
                  <Select
                    name="pricePerType"
                    options={priceTypeOptions}
                    defaultValue={defaultOption}
                    className="React"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      console.log(
                        'pricePerType onChange:',
                        selectedOption.value
                      );
                      setPricePerType(selectedOption.value);
                    }}
                  />
                </Col> */}
                <Col md="12" sm="12">
                  <AvGroup>
                    <Label> {t("description")} </Label>
                    <AvInput
                      type="text"
                      name="description"
                      value={description}
                      onChange={(e) => {
                        console.log("Description onChange:", e.target.value);
                        setDescription(e.target.value);
                      }}
                    />
                  </AvGroup>
                </Col>

                <Col md="6" sm="6">
                  <AvGroup>
                    <div className="d-flex align-items-center">
                      <Label className="mr-2">{t("multiple-quantity")}</Label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="multipleQuantityCheckbox"
                          name="multipleQuantity"
                          checked={multipleQuantity === 1}
                          onChange={(e) =>
                            setMultipleQuantity(e.target.checked ? 1 : 0)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="multipleQuantityCheckbox"
                        ></label>
                      </div>
                    </div>
                  </AvGroup>
                </Col>
                {/* <Col md="6" sm="6">
                  <AvGroup>
                    <div className="d-flex align-items-center">
                      <Label className="mr-2">Physical Item</Label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="physicalItemCheckbox"
                          name="physicalItem"
                          checked={physicalSupport === 1}
                          onChange={(e) =>
                            setPhysicalSupport(e.target.checked ? 1 : 0)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="physicalItemCheckbox"
                        ></label>
                      </div>
                    </div>
                  </AvGroup>
                </Col> */}
              </Row>

              <Button color="primary" size="md" onClick={handleFormSubmit}>
                <span className="ml-1 mr-1 d-md-inline ">{t("update")}</span>
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const {
    packageproductcategory: {
      allProductPackageCategoryItems: {
        data: packageproductcategory,
        loading: loading,
      },
    },
  } = state;

  return { packageproductcategory, loading: loading };
}

export default connect(mapStateToProps, {
  getAllPackageProductCategory,
})(EditSessionForm);
