import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ListRoles = (props) => {

  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const editStaff = (id) => {
    props.editStaff(id);
  };

  const columns = [
    {
      name: t('staff-id'),
      selector: "User.staff_id",
      format: (val) => {
        return val.User ? val.User.staff_id : "";
      },
    },
    {
      name: t('name'),
      selector: "User.first_name",
      format: (val) => {
        return val.User ? val.User.first_name + " " + val.User.last_name : "";
      },
    },
    {
      name: t('email'),
      selector: "User.email",
      sortable: true,
      minWidth: "300px",
      format: (val) => {
        return val.User
          ? val.User.email != ""
            ? val.User.email
            : val.User.facebook_email
          : "";
      },
    },
    {
      name: t('location-name'),
      selector: "Location.name",
      format: (val) => {
        return val.Location ? val.Location.name : "";
      },
    },
    {
      name: t('location-address'),
      selector: "Location.formattedAddress",
      minWidth: "400px",
      wrap: true,
      format: (val) => {
        return val.Location ? val.Location.formattedAddress : "";
      },
    },
    {
      name: t('role-level'),
      selector: "StaffGroup",
      sortable: true,
      format: (val) => {
        return val.StaffGroup && val.StaffGroup.staff_group_name ? (
          <>{val.StaffGroup.staff_group_name}</>
        ) : (
          t('no-role')
        );
      },
    },
    {
      name: t('date-added'),
      selector: "date_added",
      sortable: true,
      format: (val) => {
        return val.date_added ? val.date_added : t('not-available-0');
      },
    },
  ];

  const renderStaff = (staff, handleChange) => {
    if (!staff || staff.length === 0)
      return <h1>{t('no-app-location-roles-found')}</h1>;

    const tableData = {
      columns: columns,
      data: staff,
      print: false,
      export: false,
    };

    return (
      <DataTableExtensions {...tableData}>
        <DataTable
          noHeader
          pagination
          paginationRowsPerPageOptions={[25, 50, 100]}
          paginationPerPage={50}
          selectableRows
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: "primary",
            icon: <Check className="vx-icon" size={12} />,
            label: "",
            size: "sm",
          }}
          onSelectedRowsChange={handleChange}
        />
      </DataTableExtensions>
    );
  };
  return renderStaff(props.staff, props.handleChange);
};

export default ListRoles;
