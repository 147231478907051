import LoginService from "./LoginService";
import axios from "axios";
import CONSTANTS from "../constants";
import {customFetch as fetch} from "../helpers/FetchHelper";


export const getLocationPaymentData = async (loc_id) => {
    try {
        let {token} = LoginService.getToken();
        let response = await axios.get(
            `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.GET_LOCATION_PAYMENT_METHODS(loc_id)}`,
            {
                headers: {...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token},
            }
        );

        if (response.data)
            return response.data
    } catch (err) {
        console.log(err);
        return null
    }
}

export const updateLocationPaymentData = async (loc_id, data, method_id) => {
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.UPDATE_LOCATION_PAYMENT_METHODS(loc_id, method_id)}`,
        {
            body: JSON.stringify(data),
            method: "POST",
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );

    response = await response.json();
    return response
}

export const deleteLocationPaymentData = async (loc_id, method_id) => {
    let { token } = LoginService.getToken();
    let response = await fetch(
        `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DELETE_LOCATION_PAYMENT_METHOD(loc_id, method_id)}`,
        {
            method: "POST",
            headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
        }
    );

    response = await response.json();
    return response
}

export const addNewLocationPaymentData = async (loc_id, data) => {
    try {
        let {token} = LoginService.getToken();
        let response = await axios.post(
            `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.ADD_NEW_PAYMENT_METHOD(loc_id)}`,
            {...data},
            {
                headers: {...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token},
            }
        );

        console.log(response.data)
        return response
    } catch (err) {
        console.log(err, "Here1");
        return null
    }
}