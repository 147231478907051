import React from "react";
import { connect } from "react-redux";
import { Card, CardHeader, CardTitle, CardBody, Button } from "reactstrap";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { Upload, X, Info } from "react-feather";
import { getLocation } from "../../redux/actions/locations";
import LocationService from "../../services/LocationService";
import PdfModal from "../shared/forms/pdfModal";
import ImageHelper from "../../helpers/ImageHelper";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import toast, { Toaster } from "react-hot-toast";
import { uploadPdf } from "../../redux/actions/menus";
import { getAllQrs } from "../../redux/actions/menus";
import { deletePdfQr } from "../../redux/actions/menus";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import PdfViewModal from "../shared/forms/pfdViewModal";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class Pdf extends React.Component {
  state = {
    QRlist: [],
    loading: true,

    isPdfModalOpen: false,
    isPdfViewModalOpen: false,
    selectedPdf: null,
    location: null,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    },
    selectLocation: false,
  };
  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false, location: location });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true) {
    this.setState({ loading: initialize });
    try {
      if (!this.state.location || loc_id !== this.state.location.loc_id) {
        await this.props.getLocation(loc_id);
      }
      let QRs = await this.props.getAllQrs(loc_id);
      this.setState({
        QRlist: QRs,
        location: JSON.parse(JSON.stringify(this.props.location)),
      });
    } catch (error) {
      toast.error("Failed to fetch");
      console.error(error);
    }
  }

  async togglePdfModal(pdf = null) {
    let {
      state: { isPdfModalOpen },
    } = this;
    const selectedPdf = pdf;
    isPdfModalOpen = !isPdfModalOpen;
    return this.setState({ selectedPdf, isPdfModalOpen });
  }

  async togglePdfViewModal(pdf = null) {
    console.log("pdf:", pdf);
    let {
      state: { isPdfViewModalOpen },
    } = this;
    const selectedPdf = pdf;
    isPdfViewModalOpen = !isPdfViewModalOpen;
    this.setState({ selectedPdf, isPdfViewModalOpen });
  }

  async deleteQR(qr) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        this.setState({ confirmationDialog: defaultState, loading: true });
        try {
          let QRlist = await this.props.deletePdfQr(
            this.state.location.loc_id,
            qr.id
          );
          toast.success("QR is deleted successfully");
          setTimeout(() => {
            this.refreshData(this.state.location.loc_id, true);
            window.location.reload();
          }, 0);
          this.setState({ QRlist: QRlist, loading: false });
        } catch (error) {
          toast.error("Failed to delete the QR");
          console.error(error);
        }

        // window.location.reload();
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState, loading: false });
      },
    };
    this.setState({ confirmationDialog });
  }

  async saveUploadPdf() {
    const fileInput = document.querySelector("#uploadPdfFileInput");
    const formData = new FormData();
    if (!fileInput.files[0]) {
      toast.error("Please select a PDF file to upload.");

      return;
    }

    formData.append("file", fileInput.files[0]);

    try {
      const fileExtension = fileInput.files[0].name.split(".").pop();

      if (fileExtension === "pdf") {
        await this.props.uploadPdf(this.state.location.loc_id, formData);
        toast.success("PDF file uploaded successfully");
        await this.togglePdfModal();
        setTimeout(() => {
          this.refreshData(this.state.location.loc_id, true);
          window.location.reload();
        }, 0);
      } else {
        toast.error("Please select a PDF file to upload.");
      }
    } catch (error) {
      toast.error("Failed to upload PDF file");
      console.error(error);
    }
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  render() {
    const { t } = this.props;

    if (this.state.loading) return <SpinnerComponent />;

    const {
      state: { selectLocation },
    } = this;
    const {
      props: { location },
    } = this;

    const locationName = location ? location.name : "";
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <>
        <div className="location">
          <div className="display-flex flex-row justify-content-between mb-1">
            <div className="d-flex">
              <LocationSelectHeader
                text={locationName}
                onIconClick={this.toggleLocation.bind(this)}
              />
              <div className="d-flex flex-wrap align-items-center">
                <Button
                  outline
                  className="font-weight-bold custom-padding primary-2-border mb-1 ml-1 d-flex align-items-center"
                  color="primary"
                  size="md"
                  onClick={() => this.togglePdfModal()}
                >
                  <Upload className="mr-2" size={20} />
                  <span className="mr-2">{t("upload-pdf")}</span>
                </Button>
              </div>
            </div>
          </div>
          {this.state.QRlist.length > 0 ? (
            <div className="row">
              <div className="col-12">
                <Card>
                  <CardHeader>
                    <CardTitle>
                      {locationName}
                      {t("s-pdfqrs")}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      {this.state.QRlist.map((QR, index) => {
                        let file = ImageHelper.getImageURL(QR.PDF);
                        let type = "pdf";
                        return (
                          <div
                            key={index}
                            className="col-md-4 col-sm-6 col-lg-2 mb-5"
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: -10,
                                right: 0,
                              }}
                            >
                              <Button.Ripple
                                style={{ width: 20, height: 20 }}
                                onClick={() => this.deleteQR(QR)}
                                className="m-0 p-0"
                                color="danger"
                              >
                                <X size={14} />
                              </Button.Ripple>
                            </div>
                            <img
                              src={ImageHelper.getImageURL(QR.QR)}
                              alt={t("swiper-1")}
                              className="img-fluid"
                              style={{
                                minHeight: "170px",
                                maxHeight: "200px",
                                margin: "auto",
                                display: "flex",
                              }}
                            />
                            <div style={{ textAlign: "center" }}>
                              <Button
                                color="primary"
                                style={{
                                  maxWidth: "156px",
                                  minWidth: "156px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  position: "relative",
                                }}
                                onClick={async () =>
                                  this.togglePdfViewModal(QR.PDF)
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "absolute",
                                    top: 9,
                                    left: 10,
                                  }}
                                >
                                  <Info
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                    size={20}
                                  />
                                </div>
                                <div
                                  className="ml-1"
                                  style={{
                                    maxWidth: "calc(100% - 10px)",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {QR.name}
                                </div>
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <Card>
                  <CardHeader>
                    <CardTitle>
                      {locationName}
                      {t("s-pdfqrs")}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="col-12">{t("no-pdf-added")}</div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
          <PdfModal
            isOpen={this.state.isPdfModalOpen}
            toggleModal={() => this.togglePdfModal()}
            saveUploadPdf={() => this.saveUploadPdf()}
          />

          <PdfViewModal
            isOpen={this.state.isPdfViewModalOpen}
            selectedPdf={this.state.selectedPdf}
            toggleModal={() => this.togglePdfViewModal()}
          />
          <ConfirmationModal
            isOpen={this.state.confirmationDialog.show}
            accept={this.state.confirmationDialog.acceptCallback.bind(this)}
            reject={this.state.confirmationDialog.rejectCallback.bind(this)}
          />
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locations, loading: locationLoading },
    },
  } = state;
  return {
    location: locations,
    loading: locationLoading,
  };
}
export default withTranslation("global")(
  connect(mapStateToProps, {
    uploadPdf,
    getAllQrs,
    deletePdfQr,
    getLocation,
  })(Pdf)
);
