import React, { Component } from "react";
import CONSTANTS from "../../../constants";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class SessionSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchForm: {
        status: null,
        start_date: null,
        end_date: null, // Add end_date here
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }

  updateSearchItem = (index, value) => {
    let searchFormItem = this.state.searchForm;
    searchFormItem[index] = value;
    this.setState({ searchForm: searchFormItem });
  };

  // submitSearchForm = async (resetform = false) => {
  //   let applySearch = Object.values(this.state.searchForm).every((value) => {
  //     // 👇️ check for multiple conditions
  //     if (value === null || value === undefined || value === '') {
  //       return true;
  //     }
  //     return false;
  //   });
  //   if (!applySearch || resetform) {
  //     await this.props.searchSessionData(this.state.searchForm, resetform);
  //   }
  // };
  submitSearchForm = async (resetform = false) => {
    const { start_date, status } = this.state.searchForm;
    const isDateCleared = !start_date;
    const searchForm = {
      status: isDateCleared ? status : this.state.searchForm.status,
      start_date: isDateCleared ? null : start_date,
    };

    await this.props.searchSessionData(searchForm, resetform);
  };

  // resetForm = () => {
  //   let searchFormItem = this.state.searchForm;
  //   searchFormItem['status'] = null;
  //   searchFormItem['start_date'] = null;
  //   console.log('Before state update:', this.state.searchForm);
  //   this.setState({ searchForm: searchFormItem }, () => {
  //     console.log('After state update:', this.state.searchForm);
  //   });
  //   this.submitSearchForm(true);
  // };
  resetForm = () => {
    let searchFormItem = {
      status: null,
      start_date: null,
    };

    this.setState({ searchForm: searchFormItem }, () => {
      console.log("After state update:", this.state.searchForm);
      // After resetting, submit the search form
      this.submitSearchForm(true);
    });
  };

  render() {
    const { t } = this.props;
    return (
      <form>
        <div className="form-row align-items-center">
          <div className="col-lg-3">
            <div class="form-group">
              <label for="start_date">{t("start-date")}</label>
              <input
                type="date"
                class="form-control"
                id="start_date"
                aria-describedby="menuName"
                // defaultValue={this.state.searchForm['start_date']}
                value={this.state.searchForm["start_date"] || ""}
                onChange={(e) =>
                  this.updateSearchItem("start_date", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div class="form-group">
              <label for="status">{t("status")}</label>
              <select
                class="form-control"
                id="status"
                value={this.state.searchForm["status"] || ""}
                onChange={(e) =>
                  this.updateSearchItem("status", e.target.value)
                }
              >
                <option value={""}>{t("select-status")}</option>
                <option value={"completed"}>{t("completed")}</option>
                <option value={"started"}>{t("started")}</option>
              </select>
            </div>
          </div>
          <div className="col-auto">
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => this.submitSearchForm()}
            >
              {t("submit")}{" "}
            </button>
          </div>
          <div className="col-auto">
            <button
              type="button"
              class="btn btn-danger"
              onClick={() => this.resetForm()}
            >
              {t("reset")}{" "}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation("global")(SessionSearchForm);
