import toast from "react-hot-toast";
import CONSTANTS from "../../../constants";
import RoomService from "../../../services/RoomService";

export function getAllRooms() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.GET_ALL,
        rooms: [],
        loading: true,
      });
      let rooms = await RoomService.getAllRooms();
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.GET_ALL,
        rooms: rooms,
        loading: false,
      });
    } catch (error) {
      console.log("Dispatch Error Getting Rooms.");
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.GET_ALL,
        rooms: [],
        loading: false,
      });
    }
  };
}
export function getRoomsByLocationId(locationId) {
  console.log(locationId, "locationId");
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.GET_ROOM_BY_LOC,
        rooms: [],
        loading: true,
      });
      let rooms = await RoomService.getRoomsByLocationId(locationId);

      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.GET_ROOM_BY_LOC,
        rooms: rooms,
        loading: false,
      });
      console.log("try action");
    } catch (error) {
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.GET_ROOM_BY_LOC,
        rooms: [],
        loading: false,
      });
    }
  };
}

export function getRoomByRoomId(roomId) {
  console.log("RoomID", roomId);
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: CONSTANTS.ACTIONS.ROOMS.GET_ROOM_BY_ID,
      //   room: null,
      //   loading: true,
      // });

      let room = await RoomService.getRoomById(roomId);
      console.log("Room Details", room);

      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.GET_ROOM_BY_ID,
        room: room,
        loading: false,
      });
      return room;
    } catch (error) {
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.GET_ROOM_BY_ID,
        room: null,
        loading: false,
      });
    }
  };
}

export async function saveUpdateRoom(item) {
  return async (dispatch) => {
    try {
      const rooms = await RoomService.saveRoom(item);
      console.log("rooms", rooms);

      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.CREATE_ROOM,
        rooms: rooms,
        loading: false,
      });
    } catch (error) {
      console.log("Dispatch Error Saving Room.");
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.CREATE_ROOM,
        rooms: [],
        loading: false,
      });
    }
  };
}

export function deleteRoom(loc_id, room_id) {
  return async (dispatch) => {
    try {
      const result = await RoomService.deleteRoom(room_id);
      console.log("Room Deletion Result", result);

      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.DELETE_ROOM,
        message: result,
      });
      toast.success("Room deleted successfully");
      let rooms = await RoomService.getRoomsByLocationId(loc_id);
      console.log(rooms, "rooms");

      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.GET_ROOM_BY_LOC,
        rooms: rooms,
        loading: false,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Room is Busy and not available for deletion");

      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.DELETE_ROOM,
        rooms: [],
        loading: false,
      });
    }
  };
}

export function getAllSessionRooms(locationId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.ALL_SESSIONS_ROOMS,
        rooms: [],
        loading: true,
      });
      let rooms = await RoomService.getAllSessionRooms(locationId);
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.ALL_SESSIONS_ROOMS,
        rooms,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.ALL_SESSIONS_ROOMS,
        rooms: [],
        loading: false,
      });
    }
  };
}

export function updateRoom(room_id, item) {
  console.log(room_id, item, "updateRoom");
  return async (dispatch) => {
    try {
      const result = await RoomService.updateRoom(room_id, item);
      console.log(result, "Room Update Result ");
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.UPDATE_ROOM,
        message: result,
      });
    } catch (error) {
      console.log(error, "Room Update error");
      dispatch({
        type: CONSTANTS.ACTIONS.ROOMS.UPDATE_ROOM,
        rooms: [],
        loading: false,
      });
    }
  };
}
