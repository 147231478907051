import CONSTANTS from '../../../constants';
import StaffService from '../../../services/StaffService';

export function getAllStaff() {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: true });
      let staff = await StaffService.getAllStaff();
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: false });
    }
  };
}

export function getStaff(staffId) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff: [], loading: true });
      let staff = await StaffService.getStaff(staffId);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff: [], loading: false });
    }
  };
}

export function getAllStaffRoles() {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: true });
      let staff = await StaffService.getAllStaffRoles();
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: false });
    }
  };
}

export function getAllOwnerStaff() {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: true });
      let staff = await StaffService.getAllOwnerStaff();
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: false });
    }
  };
}


export function getRoles() {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_ROLES, roles: [], loading: true });
      let roles = await StaffService.getRoles();
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_ROLES, roles, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_ROLES, roles: [], loading: false });
    }
  };
}

export function createStaff(data) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff: [], loading: true });
      let staff = await StaffService.createStaff(data);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff: [], loading: false });
    }
  };
}


export function assign(data) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff: [], loading: true });
      let staff = await StaffService.assignRole(data);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff: [], loading: false });
    }
  };
}

export function deleteAssignRole(data) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff: [], loading: true });
      let staff = await StaffService.delAssignRole(data);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.DEFAULT, staff: [], loading: false });
    }
  };
}

export function deleteAssignRoleWithAll(data) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: true });
      let staff = await StaffService.delAssignRole(data);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: false });
    }
  };
}

export function changeStaffStatus(data) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: true });
      let staff = await StaffService.changeStaffStatus(data);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: false });
    }
  };
}

export function getStaffByEmail(data) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: true });
      let staff = await StaffService.getStaffByEmail(data);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.STAFF.ALL_STAFF, staff: [], loading: false });
    }
  };
}