import React, { Component } from "react";
import { AvGroup, AvForm } from "availity-reactstrap-validation";
import { Label, Col } from "reactstrap";
import AsyncSelect from "react-select/async";

import LocationService from "../../services/LocationService";
import ViewHelper from "../../helpers/ViewHelper";
import CONSTANTS from "../../constants";
import LoginService from "../../services/LoginService";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class FilterPurchaseHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchForm: {
        selectedLocation: "",
        payment_type: null,
        package_title: "",
        role: "",
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
    let role = LoginService.getRole().role; // Accessing the 'role' property
    this.setState({ role });
    console.log("ROLE", role);
  }

  updateSearchItem = (index, value) => {};

  submitSearchForm = async (resetform = false) => {
    let applySearch = Object.values(this.state.searchForm).every((value) => {
      // 👇️ check for multiple conditions
      if (value === null || value === undefined || value === "") {
        return true;
      }
      return false;
    });

    if (!applySearch || resetform) {
      let searchItem = new URLSearchParams(this.state.searchForm).toString();
      await this.props.getMoreDataBySearch(searchItem);
    }
    if (this.state.searchForm.selectedLocation === "" && !resetform) {
      // window.location.reload();
      let searchItem = new URLSearchParams(this.state.searchForm).toString();
      await this.props.getMoreDataBySearch(searchItem);
    }
  };

  resetForm = () => {};

  async loadLocations(term, callback) {
    if (term.length < 3) return callback([]);
    try {
      const locations = await LocationService.getLocations(term);
      callback(ViewHelper.transformedLocations(locations));
    } catch (e) {
      console.log(e);
      alert(this.props.t("could-not-load-locations"));
    }
  }

  async onChangeLocation(select) {
    let searchFormItem = this.state.searchForm;
    searchFormItem["selectedLocation"] = select?.loc_id ? select.loc_id : "";
    this.setState({ searchForm: searchFormItem });
  }

  changePaymentType(value) {
    let searchFormItem = this.state.searchForm;
    console.log(searchFormItem, "change");
    searchFormItem["payment_type"] = value;
    this.setState({ searchForm: searchFormItem });
  }

  onTitleChange(value) {
    console.log("Value: ", value);
    let searchFormItem = this.state.searchForm;
    searchFormItem["package_title"] = value;
    this.setState({ searchForm: searchFormItem });
  }
  // async onChangeLocation(select) {
  //   let searchFormItem = this.state.searchForm;
  //   searchFormItem["selectedLocation"] = select?.loc_id ? select.loc_id : "";
  //   this.setState({ searchForm: searchFormItem });

  //   // Reload the page if the input is cleared
  //   if (!select) {
  //     console.log("Loading");
  //     // window.location.reload();
  //   }
  // }
  render() {
    const { t } = this.props;
    const { role } = this.state;

    return (
      <form>
        <div className="form-row align-items-center">
          {/* Location Field */}
          {role !== "Restaurant Manager" && (
            <div className="col">
              <AvForm>
                <AvGroup>
                  <Label for="start_date">{t("location")}</Label>
                  <Col md="12" sm="12" className="pl-0">
                    <AsyncSelect
                      placeholder={t("please-search-location")}
                      autoFocus
                      isClearable
                      cacheOptions
                      onChange={this.onChangeLocation.bind(this)}
                      loadOptions={this.loadLocations.bind(this)}
                      noOptionsMessage={() => t("no-location-present")}
                      className="React"
                      classNamePrefix="select"
                      styles={{
                        control: (provided) => {
                          return { ...provided, borderRadius: "0.3rem" };
                        },
                        dropdownIndicator: (provided) => {
                          return { ...provided, display: "none" };
                        },
                        indicatorSeparator: (provided) => {
                          return {
                            ...provided,
                            display: "none",
                            color: "#fff",
                          };
                        },
                        input: (provided) => {
                          return { ...provided, color: "#fff" };
                        },
                      }}
                    />
                  </Col>
                </AvGroup>
              </AvForm>
            </div>
          )}

          {/* Package Title Field */}
          <div className="col">
            <div className="form-group">
              <label htmlFor="menu_name">{t("package-title")}</label>
              <input
                type="text"
                className="form-control"
                id="menu_name"
                aria-describedby="menuName"
                placeholder="e.g Package One"
                onChange={(e) => this.onTitleChange(e.target.value)}
              />
            </div>
          </div>

          {/* Payment Type Field */}
          <div className="col">
            <div class="form-group">
              <label for="status">{t("Payment Type")}</label>
              <select
                class="form-control"
                id="status"
                onChange={(e) => this.changePaymentType(e.target.value)}
              >
                <option value={""}>{t("select-payment-type")}</option>
                <option value={"subscripiton"}>{t("recurring")}</option>
                <option value={"onetime"}>{t("one-time-0")}</option>
              </select>
            </div>
          </div>

          {/* Submit Button */}
          <div className="col-auto">
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => this.submitSearchForm()}
            >
              {t("submit")}{" "}
            </button>
          </div>

          {/* Reset Button */}
          <div className="col-auto">
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => window.location.reload()}
            >
              {t("reset")}{" "}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation("global")(FilterPurchaseHistory);
