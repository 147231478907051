import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getAllReviews,
  deleteReviewById,
  approveReviewStatusById,
  rejectReviewStatusById,
};

async function getAllReviews(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.HOTELREVIEWS.DEFAULT(
      locationId
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch REVIEWS");
}

async function approveReviewStatusById(reviewId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.HOTELREVIEWS.APPROVEREVIEW(
      reviewId
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update REVIEWS");
}
async function rejectReviewStatusById(reviewId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.HOTELREVIEWS.REJECTREVIEW(
      reviewId
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update REVIEWS");
}
async function deleteReviewById(reviewId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.HOTELREVIEWS.DELETEREVIEW(
      reviewId
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not Delete REVIEWS");
}
