import React, {useState} from 'react';
import {Button, Stack} from "@mui/material";
import {green, orange, purple, red} from "@mui/material/colors";
import ProductsDrawer from "../mainScreen/productsDrawer";
import LeftSectionDrawer from "../mainScreen/leftSectionDrawer";
import {
    ConfirmationNumber, DevicesFold,
    Drafts, Fullscreen, FullscreenExit,
    GridView,
    History,
    Language,
    Logout, MonitorOutlined, Notifications, PointOfSale,
    Print,
    ShoppingBagOutlined
} from "@mui/icons-material";
import SmallScreensMainMenu from "../../components/mainComponents/smallScreensMainMenu";

const menuList = [
    {
        icon: <Logout sx={{color: '#186218'}}/>,
        text: "Logout"
    },
    {
        icon: <Language sx={{color: '#186218'}}/>,
        text: "Language"
    },
    {
        icon: <Drafts sx={{color: '#186218'}}/>,
        text: "Open Draft Sales"
    },
    {
        icon: <Print sx={{color: '#186218'}}/>,
        text: "Print Last Invoice"
    },
    {
        icon: <History sx={{color: '#186218'}}/>,
        text: "Recent Sales Invoice"
    },
    {
        icon: <ShoppingBagOutlined sx={{color: '#186218'}}/>,
        text: "Future Sales"
    },
    {
        icon: <GridView sx={{color: '#186218'}}/>,
        text: "Self/Online Orders"
    },
    {
        icon: <ConfirmationNumber sx={{color: '#186218'}}/>,
        text: "Reservations List"
    },
    {
        icon: <Notifications sx={{color: '#186218'}}/>,
        text: "Kitchen Notifications"
    },
    {
        icon: <PointOfSale sx={{color: '#186218'}}/>,
        text: "Register"
    },
    {
        icon: <DevicesFold sx={{color: '#186218'}}/>,
        text: "Dashboard"
    },
    {
        icon: <MonitorOutlined sx={{color: '#186218'}}/>,
        text: "Customer Display"
    },
    {
        icon: <MonitorOutlined sx={{color: '#186218'}}/>,
        text: "Order Status Screen"
    },
    {
        onIcon: <Fullscreen sx={{color: '#186218'}}/>,
        offIcon: <FullscreenExit sx={{color: '#186218'}}/>,
        onText: "Full Screen",
        offText: "Exit Full Screen"
    }
]

const buttonStyles = {
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.35rem 0.5rem',
    textAlign: 'center',
    lineHeight: '1.5rem',
    flexGrow: 1,
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0,0,0,0.25)',
}

const buttons = [
    {
        label: 'Ongoing Orders',
        backgroundColor: red[400],
        hoverBackgroundColor: red[600]
    },

    {
        label: 'Cart',
        backgroundColor: orange[400],
        hoverBackgroundColor: orange[600]
    },

    {
        label: 'Products',
        backgroundColor: purple[400],
        hoverBackgroundColor: purple[600]
    },

    {
        label: 'Others',
        backgroundColor: green[400],
        hoverBackgroundColor: green[600]
    }
]

const SmallScreenNavbar = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openSideDrawer, setOpenSideDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = (event, index) => {
        if (index === 2) {
            setOpenDrawer(true)
        } else if (index === 0) {
            setOpenSideDrawer(true)
        } else if (index === 3) {
            setAnchorEl(event.currentTarget);
        }
    }

    return(
        <>
            <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, columnGap: '0.5rem'}}>
                {
                    buttons.map((item, index) => (
                        <Button onClick={(event)=> handleButtonClick(event, index)} variant='contained' key={index} sx={{...buttonStyles, backgroundColor: item.backgroundColor, '&:hover': {backgroundColor: item.hoverBackgroundColor}}}>
                            {item.label}
                        </Button>
                    ))
                }
            </Stack>
            <SmallScreensMainMenu open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuList={menuList} />
            <ProductsDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>
            <LeftSectionDrawer open={openSideDrawer} setOpenDrawer={setOpenSideDrawer} />
        </>
    )
}

export default SmallScreenNavbar;