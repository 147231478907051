import React, { useEffect, useState } from "react";
import { Button, Row, Col, FormGroup, Input, Label } from "reactstrap";

import Select from "react-select";
import { Plus, Trash } from "react-feather";
import { ClipLoader } from "react-spinners";
import ImageHelper from "../../../helpers/ImageHelper";
import LoginService from "../../../services/LoginService";
import "../../../assets/scss/plugins/forms/switch/react-toggle.scss";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { useItemProgressListener, useItemFinishListener } from "@rpldy/uploady";
import { asUploadButton } from "@rpldy/upload-button";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

const GlobalModifierForm = (props) => {
  const [t, i18n] = useTranslation("global");

  const getMenuItem = (option_id) => {
    let item = {
      value: "",
      price: "",
      priority: 1,
      status: 1,
      base_price: 0,
    };
    if (option_id) item.option_id = option_id;

    return item;
  };

  let {
    item: propItem,
    updateModifier,
    showConfirmationDialog,
    delItemFromList,
    toggleCollapse,
  } = props;

  const typeOptions = [
    {
      value: "radio",
      label: t("single"),
    },
    {
      value: "select",
      label: t("selector"),
    },
    {
      value: "checkbox",
      label: t("checkbox"),
    },
  ];

  const options = {
    radio: t("single"),
    select: t("selector"),
    checkbox: t("checkbox"),
  };

  propItem.type = {
    value: propItem.display_type,
    label: options[propItem.display_type],
  };

  if (!propItem.OptionValues) propItem.OptionValues = [];

  const [item, setItem] = useState(propItem);
  const [saving, setSaving] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [optionNameError, setOptionNameError] = useState(false);
  const [optionAddToggle, setToggleAdd] = useState(false);
  const [selected, setSelected] = useState();
  const [isUploaded, setIsUploaded] = useState(true);
  const [isDownloaded, setIsDownloaded] = useState();

  let fileRef = [];

  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);

    let { item: propItem } = props;
    propItem.type = {
      value: propItem.display_type,
      label: options[propItem.display_type],
    };
    // setSelected(propItem.options_layout);
    setSelected(1);
    setItem(propItem);
  }, [props]);

  const updateItem = (option, val) => {
    console.log("Updating item:", option, val);
    let itemCopy = JSON.parse(JSON.stringify(item));
    let optionParts = option.split(".");
    let obj = itemCopy;
    for (let i = 0; i < optionParts.length - 1; i++) {
      if (isNaN(optionParts[i])) obj = obj[optionParts[i]];
      else obj = obj[parseInt(optionParts[i])];
    }
    const opt = optionParts[optionParts.length - 1];
    if (opt === "price" && val !== "" && isNaN(val)) return;
    obj[opt] = val;
    setItem(itemCopy);
    updateOptionError();
  };

  const updateOptionError = () => {
    let options = item.OptionValues;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value == "") return setOptionNameError(true);
      if (options[i].price == "" || parseInt(options[i].price) < 0)
        return setPriceError(true);
    }
  };

  const deleteItem = (index) => {
    if (item.OptionValues[index].option_value_id) {
      // If option_value_id exists, prompt for confirmation
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this option value. Do you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // User confirmed, proceed with deletion
          let itemCopy = { ...item };
          itemCopy.OptionValues[index].isDelete = true;
          setItem(itemCopy);

          // Show a confirmation message
          Swal.fire(
            "Deleted!",
            "The option value has been deleted.",
            "success"
          );
        }
      });
    } else {
      // If option_value_id doesn't exist, prompt for confirmation
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to remove this option value. Do you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // User confirmed, proceed with removal
          let itemCopy = { ...item };
          itemCopy.OptionValues.splice(index, 1);
          setItem(itemCopy);

          // Show a confirmation message
          Swal.fire(
            "Removed!",
            "The option value has been removed.",
            "success"
          );
        }
      });
    }

    // showConfirmationDialog(
    //   () => {
    //     console.log("deleting");
    //     if (item.OptionValues[index].option_value_id) {
    //       let itemCopy = item.OptionValues[index];
    //       itemCopy.isDelete = true;
    //       item.OptionValues[index] = itemCopy;
    //       setItem(item);
    //     } else {
    //       let itemCopy = item;
    //       itemCopy.OptionValues.splice(index, 1);
    //       setItem(itemCopy);
    //     }
    //   },
    //   () => {
    //     console.log(`Don't delete`);
    //   },
    //   t("are-you-sure-you-want-to-remove-this-option")
    // );
  };

  const statusItem = (index) => {
    let itemCopy = item.OptionValues[index];
    if (itemCopy.status === 1) {
      itemCopy.status = 0;
    } else {
      itemCopy.status = 1;
    }
    item.OptionValues[index] = itemCopy;
    setItem(item);
  };

  const saveItem = async () => {
    if (!isUploaded) return setIsUploaded(false);

    if (item.option_name === "") return setNameError(true);

    let options = item.OptionValues;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === "") {
        // Show error for empty option value
        toast.error("please provide a value.");
        return;
      }
    }
    const formData = new FormData();
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === "") return setOptionNameError(true);
      if (options[i].price === "") options[i].price = null;
      if (parseInt(options[i].price) < 0) return setPriceError(true);

      if (item.options_layout === 0) {
        let option_id = "";
        if (item.option_id != undefined) {
          option_id = item.option_id;
        } else {
          option_id = "data";
        }
      }
      if (options[i].img == null) {
        options[i].img = options[i].dummyUrl;
      }
    }
    item.OptionValues = options;
    if (item.description == null || item.description == "") {
      item.description = item.option_name;
    }
    formData.append("item", JSON.stringify(item));
    //setSaving(true);
    await updateModifier(formData, item);
    //setSaving(false);
    toggleCollapse();
  };

  const toggleOptionAdd = () => {
    console.log(optionAddToggle);
    setToggleAdd(!optionAddToggle);
  };

  const addNewOption = () => {
    const newItem = getMenuItem(item.option_id);
    item.OptionValues.push(newItem);
    setItem(item);
    toggleOptionAdd();
  };

  const updateItemImage = (key, val, index) => {
    const cat = JSON.parse(JSON.stringify(item));
    cat.OptionValues[index].img = val;
    cat.OptionValues[index].image = val;
    if (key === "img" && val === null) {
      cat.OptionValues[index].deletePhoto = true;
      cat.OptionValues[index].progress = 0;
    }
    setItem(cat);
  };

  const updateOptionLayout = async (key, val) => {
    if (val == 1) {
      await setSelected(1);
    } else {
      await setSelected(0);
    }
    const cat = JSON.parse(JSON.stringify(item));
    cat[key] = val;
    setItem(cat);
  };

  const DivUploadButton = asUploadButton((props) => {
    return (
      <div style={{ position: "absolute", bottom: 3 }} className="full-width">
        <Button.Ripple {...props} className="p-25" color="primary">
          <span className="font-small-1">{t("change-photo")}</span>
        </Button.Ripple>
      </div>
    );
  });
  const UploadButton = asUploadButton((props) => {
    return (
      <div
        {...props}
        className="font-small-2 color- align-self-center align-text-center"
      >
        <span className="font-small-2 primary align-self-center">
          {t("click-to-upload-a-file")}{" "}
        </span>
        <span>
          {t("png-jpg-img")} <br /> {t("up-to-10mb")}
        </span>
      </div>
    );
  });

  const LogProgress = (props) => {
    useItemProgressListener((res) => {
      const itemProp = JSON.parse(JSON.stringify(item));
      let itemCopy = itemProp.OptionValues[props.index];
      itemCopy.progress = res.completed;
      itemProp.OptionValues[props.index] = itemCopy;
      setItem(itemProp);
      setIsUploaded(false);
    });
    useItemFinishListener((res) => {
      const itemProp = JSON.parse(JSON.stringify(item));
      let itemCopy = itemProp.OptionValues[props.index];
      itemCopy.img = res.uploadResponse.data;
      itemCopy.image = res.uploadResponse.data;
      itemCopy.deletePhoto = false;
      itemCopy.progress = 0;
      itemProp.OptionValues[props.index] = itemCopy;
      setItem(itemProp);
      setIsUploaded(true);
    });

    return null;
  };

  const renderOptionValues = (optionValues, option_layout) => {
    return optionValues.map((option, index) => {
      fileRef[index] = React.createRef();
      let image = ImageHelper.getImageURL(option.img, "?func=crop&w=120&h=80");
      let { token } = LoginService.getToken();
      if (option.isDelete) return "";
      return option_layout === 1 ? (
        <React.Fragment key={index}>
          <Col xs={2} className="mb-25">
            <Toggle
              name={"active"}
              data-id={0}
              // defaultChecked={option.status}
              defaultChecked={Boolean(option.status)}
              className="switch-danger"
              onChange={(e) => {
                e.stopPropagation();
                statusItem(index);
              }}
            />
          </Col>
          <Col xs={6} className="mb-25">
            <Input
              type="text"
              id="optionName"
              value={option.value}
              placeholder={t("option")}
              className={`${
                optionNameError && option.value === ""
                  ? "input-border-danger"
                  : ""
              }`}
              onChange={(e) =>
                updateItem(`OptionValues.${index}.value`, e.target.value)
              }
            />
          </Col>
          <Col xs={2} className="mb-25">
            <Input
              key={index}
              type="text"
              required
              id="optionPrice"
              // value={option.price}
              value={option.price ?? ""}
              placeholder={t("price-0")}
              onChange={(e) =>
                updateItem(`OptionValues.${index}.price`, e.target.value)
              }
              className={
                priceError && parseInt(option.price) < 0
                  ? "input-border-danger"
                  : ""
              }
            />
          </Col>
          <Col xs={2} className="mb-25 display-flex align-items-center">
            <Button
              className="btn-icon overflow-visible p-0 m-0"
              color="flat-danger"
              tabIndex="-1"
              onClick={(e) => {
                e.currentTarget.blur();
                deleteItem(index);
              }}
            >
              <Trash className="align-baseline" size={16} />
            </Button>
          </Col>
        </React.Fragment>
      ) : (
        <React.Fragment key={option.option_value_id}>
          {/* <Col xs={4} className='mb-25'>
              <FormGroup>
                <Label for="photoDiv">Image:</Label>
                <Uploady
                  destination={{ url: `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU_OPTIONS.FILE(item.loc_id)}`, headers: { 'Accept': CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token } }}
                  method='POST'
                  fileInputId={index}
                >
                  <div tabIndex="0" id="photoDiv" className='display-flex flex-column border border-2 border-light cursor-pointer' style={{ position: 'relative', height: 100, width: 130 }}>
                    <div style={{ textAlign: '-webkit-center' }}>
                      {image ?
                        <div>
                          <div style={{ position: 'absolute', top: -10, right: -10 }}>
                            <Button.Ripple onClick={() => {
                              updateItemImage('img', null, index);
                            }} style={{ width: 20, height: 20 }} className="m-0 p-0" color="danger">
                              <X size={14} />
                            </Button.Ripple>
                          </div>
                          <DivUploadButton />
                          <Media
                            object
                            src={image}
                            alt="image"
                            style={{ maxWidth: 128, maxHeight: 118, width: 98, height: 98 }}
                            className='align-self-center'
                          />
                        </div> :
                        <div className='align-self-center' style={{ padding: 9, height: 50, width: 50, }}>
                          <Image size={32} className='align-self-center' />
                        </div>
                      }
                    </div>
                    {image ? null :
                      <UploadButton />
                    }
                  </div>
                  {(option.progress < 100) ? <Progress value={option.progress} /> : ''}


                  <LogProgress index={index} />
                </Uploady>
              </FormGroup>
            </Col> */}
          <Col sm={12} md={12} lg={12} className="mb-25">
            <Row>
              <Col xs={6} className="mb-25">
                <FormGroup>
                  <Label>Option:</Label>
                  <Input
                    type="text"
                    id="optionName"
                    value={option.value}
                    placeholder={t("option")}
                    className={`${
                      optionNameError && option.value == ""
                        ? "input-border-danger"
                        : ""
                    }`}
                    onChange={(e) =>
                      updateItem(`OptionValues.${index}.value`, e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={2} className="mb-25">
                <FormGroup>
                  <Label>{t("price")}: </Label>
                  <Input
                    type="text"
                    required
                    id="optionPrice"
                    value={option.price}
                    placeholder={t("price-0")}
                    onChange={(e) =>
                      updateItem(`OptionValues.${index}.price`, e.target.value)
                    }
                    className={
                      priceError && parseInt(option.price) < 0
                        ? "input-border-danger"
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col
                xs={2}
                className="mb-25 d-flex align-items-center justify-content-end"
              >
                <Row>
                  <Toggle
                    name={"active"}
                    data-id={0}
                    defaultChecked={option.status}
                    className="switch-danger"
                    onChange={(e) => {
                      e.stopPropagation();
                      statusItem(index);
                    }}
                  />
                  {/* <p style={{ marginBottom: '0px', marginTop: '2px', marginLeft: '5px' }}>{option.status ? 'Enabled' : 'Disabled'}</p> */}
                </Row>
              </Col>
              <Col
                xs={2}
                className="mb-25 d-flex align-items-center justify-content-around"
              >
                <div className="d-inline">
                  <Button
                    className="btn-icon overflow-visible p-0 m-0"
                    color="flat-danger"
                    tabIndex="-1"
                    onClick={(e) => {
                      e.currentTarget.blur();
                      deleteItem(index);
                    }}
                  >
                    <Trash className="align-baseline" size={22} />
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </React.Fragment>
      );
    });
  };

  return (
    <div>
      <FormGroup className="row">
        <Col sm={8}>
          <Label for="email">Name:</Label>
          <Input
            type="text"
            id="name"
            value={item.option_name}
            invalid={nameError}
            placeholder={t("enter-name")}
            onChange={(e) => updateItem("option_name", e.target.value)}
            className=""
          />
        </Col>
        <Col sm={4}>
          <Label for="category">Type:</Label>
          <Select
            className="React"
            classNamePrefix="select"
            defaultValue={item.type}
            name="color"
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "var(--primary)" : "#fff",
              }),
            }}
            options={typeOptions}
            onChange={(e) => updateItem("display_type", e.value)}
          />
        </Col>
      </FormGroup>
      {/* {item.display_type === "radio" ? (
        <FormGroup>
          <Row>
            <Col xs={12} className="display-flex align-items-center">
              <Label>Menu price dependable on this option?</Label>
              <Checkbox
                tabindex="1"
                color="primary"
                icon={<Check className="vx-icon" size={16} />}
                defaultChecked={parseInt(item.base_price) === 1}
                className="ml-75 p-0"
                onChange={(e) => updateItem(`base_price`, e.target.value)}
                value={item.base_price}
              />
            </Col>
          </Row>
        </FormGroup>
      ) : null} */}
      <FormGroup className="row">
        {item.options_layout == 1 && (
          <>
            <Col xs={2}>
              <Label>{t("status")}</Label>
            </Col>
            <Col xs={6}>
              <Label>{t("options")}</Label>
            </Col>
            <Col xs={2}>
              <Label>{t("price-0")}</Label>
            </Col>
            <Col xs={2}>
              <Label>{t("actions")}</Label>
            </Col>
          </>
        )}
        {renderOptionValues(item.OptionValues, item.options_layout)}
      </FormGroup>
      <div className="row mb-1">
        <Col xs={12}>
          <Button
            outline
            className="font-weight-bold custom-padding primary-2-border"
            color={"primary"}
            size="md"
            onClick={addNewOption}
          >
            <Plus className="mx-50" size={14} />{" "}
            <span className="ml-50 mr-50 primary">{t("add-more-option")}</span>
          </Button>
        </Col>
      </div>
      {/* <div className='row mb-1'>
        <Col xs={12}>
          <FormGroup>
            <Label for="time">Options Layout:</Label>
            <div id='time' onChange={(e) => updateOptionLayout('options_layout', parseInt(e.target.value))}>
              <div className="d-inline-block mr-1">
                <Radio label="Standard" checked={selected === 1} name="options_layout" value={1} />
              </div>
              <div className="d-inline-block mr-1">
                <Radio label="With Photos" checked={selected === 0} name="options_layout" value={0} />
              </div>
            </div>
          </FormGroup>
        </Col>
      </div> */}
      <div>
        {!isUploaded && (
          <p className="danger">{t("please-wait-images-are-uploading")}</p>
        )}
        <Button
          color="dark"
          disabled={saving}
          onClick={() => {
            if (saving) return;
            if (propItem.option_id) setItem(propItem);
            else delItemFromList(propItem);
            toggleCollapse();
          }}
        >
          {t("cancel")}{" "}
        </Button>{" "}
        <Button color="primary" disabled={saving} onClick={saveItem}>
          {saving ? <ClipLoader size={15} /> : t("save")}
        </Button>{" "}
      </div>
    </div>
  );
};

export default GlobalModifierForm;
