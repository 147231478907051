import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  Col,
  Media,
} from "reactstrap";
import Radio from "../../@vuexy/radio/RadioVuexy";
import TimeHelper from "../../../helpers/TimeHelper";
import CONSTANTS from "../../../constants";
import { ClipLoader } from "react-spinners";
import { X, Image } from "react-feather";
import ImageHelper from "../../../helpers/ImageHelper";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

let color = {
  float: "left",
  cursor: "pointer",
  width: "48px",
  height: "48px",
};

const FloorModal = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const saveAction = () => {
    if (isOpen) {
      submitFloor();
    }
  };

  const keydownHandler = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) saveAction();
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
  });

  const dummyFloor = {
    id: null,
    floor_name: "",
    status: 1,
    floor_color: "#82e9ab",
    loc_id: props.loc_id,
  };

  let { isOpen, toggleModal, deleteFloor, saveFloor, deleting } = props;

  let fl = props.floor ? props.floor : JSON.parse(JSON.stringify(dummyFloor));

  const [floor, setFloor] = useState(fl);
  const [saving, setSaving] = useState(false);
  const [nameError, setError] = useState(false);

  let inputRef = React.createRef();

  const updateFloor = (key, val) => {
    const fl = JSON.parse(JSON.stringify(floor));
    fl[key] = val;
    setFloor(fl);
  };

  useEffect(() => {
    let fl = props.floor ? props.floor : JSON.parse(JSON.stringify(dummyFloor));
    isOpen = props.isOpen;
    setSaving(false);
    deleting = props.deleting;
    setFloor(fl);
  }, [props]);

  const submitFloor = () => {
    if (saving || deleting) return;
    if (floor.floor_name === "") {
      setError(true);
      return;
    }
    setSaving(true);
    saveFloor(floor);
  };

  const delFloor = () => {
    if (saving || deleting) return;
    deleteFloor(floor);
  };
  const handleStatusChange = (value) => {
    updateFloor("status", value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        onOpened={() => {
          if (props.floor) return;
          setTimeout(() => inputRef.current.focus(), 1);
        }}
        className="modal-dialog-centered d-sm-margin-0 p-0"
        backdrop="static"
      >
        <div className="modal-header display-flex full-width flex-space-between">
          <h5 className="modal-title">
            {props.floor
              ? `Edit ${props.floor.floor_name}`
              : t("add-new-floor")}
          </h5>
          <X size={20} onClick={toggleModal} />
        </div>
        <ModalBody>
          <FormGroup>
            <Label for="floor_name">{t("floor-name")} : </Label>
            <Input
              innerRef={inputRef}
              type="text"
              id="name"
              value={floor.floor_name}
              placeholder={t("floor-name")}
              invalid={nameError}
              onChange={(e) => updateFloor("floor_name", e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="floor_color" className="mb-1">
              {t("floor-color")}:{" "}
            </Label>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <span
                  style={{
                    ...color,
                    backgroundColor: "rgb(130, 233, 171)",
                    border: `${
                      floor.floor_color === "#82e9ab"
                        ? "1px solid #000"
                        : "#fff"
                    }`,
                  }}
                  role="img"
                  aria-label="Green"
                  title="Green"
                  onClick={() => updateFloor("floor_color", "#82e9ab")}
                  key={"82e9ab"}
                ></span>
                <span
                  className="color"
                  style={{
                    backgroundColor: "rgb(136, 137, 242)",
                    border: `${
                      floor.floor_color === "#8889f2"
                        ? "1px solid #000"
                        : "#fff"
                    }`,
                  }}
                  role="img"
                  aria-label="Blue"
                  title="Blue"
                  onClick={() => updateFloor("floor_color", "#8889f2")}
                  key={"8889f2"}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: "rgb(244, 149, 149)",
                    border: `${
                      floor.floor_color === "#f49595"
                        ? "1px solid #000"
                        : "#fff"
                    }`,
                  }}
                  role="img"
                  aria-label="Red"
                  title="Red"
                  onClick={() => updateFloor("floor_color", "#f49595")}
                  key={"f49595"}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: "rgb(255, 214, 136)",
                    border: `${
                      floor.floor_color === "#ffd688"
                        ? "1px solid #000"
                        : "#fff"
                    }`,
                  }}
                  role="img"
                  aria-label="Orange"
                  title="Orange"
                  onClick={() => updateFloor("floor_color", "#ffd688")}
                  key={"ffd688"}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: "rgb(254, 255, 154)",
                    border: `${
                      floor.floor_color === "#feff9a"
                        ? "1px solid #000"
                        : "#fff"
                    }`,
                  }}
                  role="img"
                  aria-label="Yellow"
                  title="Yellow"
                  onClick={() => updateFloor("floor_color", "#feff9a")}
                  key={"feff9a"}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: "rgb(209, 171, 210)",
                    border: `${
                      floor.floor_color === "#d1abd2"
                        ? "1px solid #000"
                        : "#fff"
                    }`,
                  }}
                  role="img"
                  aria-label="Purple"
                  title="Purple"
                  onClick={() => updateFloor("floor_color", "#d1abd2")}
                  key={"d1abd2"}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: "rgb(75, 75, 75)",
                    border: `${
                      floor.floor_color === "1px solid #4b4b4b"
                        ? "1px solid #000"
                        : "#fff"
                    }`,
                  }}
                  role="img"
                  aria-label="Grey"
                  title="Grey"
                  onClick={() => updateFloor("floor_color", "#4b4b4b")}
                  key={"4b4b4b"}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: "rgb(210, 210, 210)",
                    border: `${
                      floor.floor_color === "#d2d2d2"
                        ? "1px solid #000"
                        : "#fff"
                    }`,
                  }}
                  role="img"
                  aria-label="Light grey"
                  title="Light grey"
                  onClick={() => updateFloor("floor_color", "#d2d2d2")}
                  key={"d2d2d2"}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: "rgb(127, 221, 236)",
                    border: `${
                      floor.floor_color === "#7fddec"
                        ? "1px solid #000"
                        : "#fff"
                    }`,
                  }}
                  role="img"
                  aria-label="Turquoise"
                  title="Turquoise"
                  onClick={() => updateFloor("floor_color", "#7fddec")}
                  key={"7fddec"}
                ></span>
              </div>
            </div>
          </FormGroup>
          <FormGroup className="row">
            <Col md={12}>
              <Label for="status">{t("status-0")}: </Label>
              <div
                id="status"
                onChange={(e) =>
                  updateFloor("status", parseInt(e.target.value))
                }
              >
                <div className="d-inline-block mr-1">
                  <Radio
                    label={t("enabled")}
                    checked={floor.status === 1}
                    name="status"
                    value={1}
                    onChange={() => handleStatusChange(1)}
                  />
                </div>
                <div className="d-inline-block mr-1">
                  <Radio
                    label={t("disabled")}
                    name="status"
                    checked={floor.status === 0}
                    value={0}
                    onChange={() => handleStatusChange(0)}
                  />
                </div>
              </div>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div className="d-none d-sm-flex flex-space-between full-width">
            <div>
              {props.floor && (
                <Button
                  color="darken-1 border-danger"
                  disabled={saving || deleting}
                  onClick={delFloor}
                >
                  {deleting ? (
                    <ClipLoader size={15} />
                  ) : (
                    <span className="danger"> {t("delete")} </span>
                  )}
                </Button>
              )}{" "}
            </div>
            <div>
              <Button
                color="dark"
                disabled={saving || deleting}
                onClick={() => {
                  if (saving || deleting) return;
                  toggleModal();
                }}
              >
                {t("cancel")}{" "}
              </Button>{" "}
              <Button
                color="primary"
                disabled={saving || deleting}
                onClick={submitFloor}
              >
                {saving ? <ClipLoader size={15} /> : t("save")}
              </Button>{" "}
            </div>
          </div>
          <div className="d-sm-none col-12">
            <Button
              className="col-12"
              color="primary"
              disabled={saving || deleting}
              onClick={submitFloor}
            >
              {saving ? <ClipLoader size={15} /> : t("save")}
            </Button>{" "}
            <Button
              className="col-12 my-50"
              color="dark"
              disabled={saving || deleting}
              onClick={() => {
                if (saving || deleting) return;
                toggleModal();
              }}
            >
              {t("cancel")}{" "}
            </Button>{" "}
            {props.floor && (
              <Button
                className="col-12"
                color="darken-1"
                disabled={saving || deleting}
                onClick={delFloor}
              >
                {deleting ? (
                  <ClipLoader size={15} />
                ) : (
                  <span className="danger"> {t("delete")} </span>
                )}
              </Button>
            )}
          </div>
          <p className="d-none d-sm-inline col-12 align-text-right font-small-1 pr-0">
            {t("hit-ctrl-enter-to-save")}{" "}
          </p>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default FloorModal;
