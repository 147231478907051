import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getAllProductPricing } from '../../redux/actions/productpricing';
import '../../assets/scss/pages/page-pricing.scss';
import { Button, Table } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import { Edit2 } from 'react-feather'; // Import the Edit2 icon

import { useTranslation } from "react-i18next";

const PackageSession = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  useEffect(() => {
    const data = async () => {
      await props.getAllProductPricing();
    };
    data();
  }, []);

  const { data: productpricing, loading } = useSelector(
    (store) => store.productpricing.allProductPricingItems
  );

  const handleEdit = (
    packageId,
    title,
    price,
    pricePerType,
    description,
    multipleQuantity,
    physicalSupport,
    categoryTag,
    packageProductName
  ) => {
    const editData = {
      packageId,
      title,
      price,
      pricePerType,
      description,
      multipleQuantity,
      physicalSupport,
      categoryTag,
      packageProductName,
    };

    props.history.push({
      pathname: `/packages/edit/${packageId}`,
      state: editData,
    });
  };

  if (loading) return <SpinnerComponent />;
  return (
    <>
      {/* <div id="pricing-table">
        <Button
          // className="mb-1"
          className="mb-1 col-sm-12 col-md-6 col-lg-2 btn btn-primary float-right"
          color="primary"
          size="md"
          onClick={() => props.history.push('/packages/new')}
        >
          <span className="ml-1 mr-1 d-md-inline">New</span>
        </Button>

        <Table responsive>
          <thead>
            <tr>
              <th>Edit</th>
              <th>Name</th>

              <th>Tag</th>
              <th>Title</th>
              <th>Price</th>
              <th>Price Type</th>
              <th>Description</th>
              <th>Multiple Quantity</th>
              <th>Physical Support</th>
            </tr>
            {productpricing.productPricing.map((item) => (
              <tr>
                <td>
                  <Button
                    color="primary"
                    size="sm"
                    // onClick={() => handleEdit(item.package_products_id)}
                    onClick={() =>
                      handleEdit(
                        item.id,
                        item.title,
                        item.price,
                        item.price_per_type,
                        item.description,
                        item.multiple_quantity,
                        item.physical_support,
                        item.PackageProduct.PackageCategory.name,
                        item.PackageProduct.name
                      )
                    }
                  >
                    <Edit2 size={16} />
                  </Button>
                </td>
                <td>{item.PackageProduct.name}</td>

                <td>{item.PackageProduct.PackageCategory.name}</td>
                <td>{item.title}</td>
                <td>{item.price}</td>
                <td>{item.price_per_type}</td>
                <td>{item.description}</td>
                <td>{item.multiple_quantity}</td>
                <td>{item.physical_support}</td>
              </tr>
            ))}
          </thead>
          <tbody></tbody>
        </Table>
      </div> */}
      <div className="pricing-table-container">
        <Button
          className="mb-1 col-sm-12 col-md-6 col-lg-3 btn btn-primary float-right"
          color="primary"
          size="md"
          onClick={() => props.history.push('/packages/new')}
        >
          <Edit2 size={16} />
          <span className="ml-1 d-md-inline">{t('add-new')} </span>
        </Button>

        <Table responsive>
          <thead>
            <tr>
              <th>{t('edit')}</th>
              <th>{t('name')}</th>
              <th>{t('tag')}</th>
              <th>{t('title')}</th>
              <th>{t('price-0')}</th>
              <th>{t('type')}</th>
              <th>{t('description')}</th>
              {/* <th>Quantity</th> */}
              {/* <th>Physical Support</th> */}
            </tr>
          </thead>
          <tbody>
            {productpricing?.productPricing?.map((item) => (
              <tr key={item.id}>
                <td>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() =>
                      handleEdit(
                        item.id,
                        item.title,
                        item.price,
                        item.price_per_type,
                        item.description,
                        item.multiple_quantity,
                        item.physical_support,
                        item.PackageProduct.PackageCategory.name,
                        item.PackageProduct.name
                      )
                    }
                  >
                    <Edit2 size={16} />
                  </Button>
                </td>
                <td>{item?.PackageProduct?.name}</td>
                <td>{item?.PackageProduct?.PackageCategory.name}</td>
                <td>{item?.title}</td>
                <td>{item?.price}</td>
                {/* <td>{item?.price_per_type}</td> */}
                <td>{item?.PackageProduct?.tag}</td>
                <td>{item?.description}</td>
                {/* <td>{item?.multiple_quantity}</td> */}
                {/* <td>{item?.physical_support}</td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const {
    productpricing: {
      allProductPricingItems: { data: productpricing, loading: loading },
    },
  } = state;

  return { productpricing, loading: loading };
}

export default connect(mapStateToProps, {
  getAllProductPricing,
})(PackageSession);

// import React, { useEffect } from 'react';
// import DataTable from 'react-data-table-component';
// import { Button } from 'reactstrap';
// import { Edit2 } from 'react-feather';
// import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
// import { useSelector, useDispatch } from 'react-redux';
// import { getAllProductPricing } from '../../redux/actions/productpricing';
// import '../../assets/scss/pages/page-pricing.scss';
// import { Search } from 'react-feather'; // Import the Search icon

// const PackageSession = (props) => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllProductPricing());
//   }, [dispatch]);

//   const { data: productpricing, loading } = useSelector(
//     (store) => store.productpricing.allProductPricingItems
//   );

//   const handleEdit = (
//     packageId,
//     title,
//     price,
//     pricePerType,
//     description,
//     multipleQuantity,
//     physicalSupport,
//     categoryTag,
//     packageProductName
//   ) => {
//     const editData = {
//       packageId,
//       title,
//       price,
//       pricePerType,
//       description,
//       multipleQuantity,
//       physicalSupport,
//       categoryTag,
//       packageProductName,
//     };

//     props.history.push({
//       pathname: `/packages/edit/${packageId}`,
//       state: editData,
//     });
//   };

//   if (loading) return <SpinnerComponent />;

//   const columns = [
//     {
//       name: 'Edit',
//       cell: (row) => (
//         <Button
//           backgroundColor="primary"
//           size="sm"
//           onClick={() =>
//             handleEdit(
//               row.id,
//               row.title,
//               row.price,
//               row.price_per_type,
//               row.description,
//               row.multiple_quantity,
//               row.physical_support,
//               row.PackageProduct.PackageCategory.name,
//               row.PackageProduct.name
//             )
//           }
//         >
//           <Edit2 color="var(--primary)" className="ml-0 p-0" size={16} />
//         </Button>
//       ),
//       allowOverflow: true,
//       button: true,
//     },
//     {
//       name: 'Name',
//       selector: 'PackageProduct.name',
//       sortable: true,
//     },
//     {
//       name: 'Tag',
//       selector: 'PackageProduct.PackageCategory.name',
//       sortable: true,
//     },
//     {
//       name: 'Title',
//       selector: 'title',
//       sortable: true,
//     },
//     {
//       name: 'Price',
//       selector: 'price',
//       sortable: true,
//     },
//     {
//       name: 'Price_Per_Type',
//       selector: 'price_per_type',
//       sortable: true,
//     },
//     {
//       name: 'Description',
//       selector: 'description',
//       sortable: true,
//     },
//     {
//       name: 'Multiple Quantity',
//       selector: 'multiple_quantity',
//       sortable: true,
//     },
//     {
//       name: 'Physical Support',
//       selector: 'physical_support',
//       sortable: true,
//     },
//   ];

//   return (
//     <DataTable
//       noHeader
//       columns={columns}
//       data={productpricing.productPricing}
//       pagination
//       highlightOnHover
//       paginationPerPage={10}
//       paginationRowsPerPageOptions={[10, 25, 50]}
//       paginationComponentOptions={{
//         rowsPerPageText: 'Rows per page:',
//         rangeSeparatorText: 'of',
//       }}
//       subHeader
//       subHeaderComponent={
//         <div className="mb-2">
//           <Search size={20} />
//         </div>
//       }
//     />
//   );
// };

// export default PackageSession;

// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { getAllProductPricing } from '../../redux/actions/productpricing';
// import '../../assets/scss/pages/page-pricing.scss';
// import { Button, Table } from 'reactstrap';
// import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';

// class PackageSession extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   async componentDidMount() {
//     await this.props.getAllProductPricing();
//   }

//   render() {
//     const {
//       props: { productpricing, loading },
//     } = this;
//     console.log('PRODUCTPRICENG', productpricing);
//     if (loading) return <SpinnerComponent />;
//     return (
//       <>
//         <div id="pricing-table">
//           <Button
//             className="mb-1"
//             color="primary"
//             size="md"
//             onClick={() => this.props.history.push('/packages/new')}
//           >
//             <span className="ml-1 mr-1 d-md-inline">New</span>
//           </Button>

//           <Table>
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>Title</th>
//                 <th>Price</th>
//                 <th>Price_Per_Type</th>
//                 <th>Description</th>
//                 <th>Multiple Quantity</th>
//                 <th>Physical Support</th>
//               </tr>
//               {productpricing.productPricing.map((item) => (
//                 <tr>
//                   <td>{item.package_products_id}</td>
//                   <td>{item.title}</td>
//                   <td>{item.price}</td>
//                   <td>{item.price_per_type}</td>
//                   <td>{item.description}</td>
//                   <td>{item.multiple_quantity}</td>
//                   <td>{item.physical_support}</td>
//                 </tr>
//               ))}
//             </thead>
//             <tbody></tbody>
//           </Table>
//         </div>
//       </>
//     );
//   }
// }

// function mapStateToProps(state) {
//   const {
//     productpricing: {
//       allProductPricingItems: { data: productpricing, loading: loading },
//     },
//   } = state;

//   return { productpricing, loading: loading };
// }

// export default connect(mapStateToProps, {
//   getAllProductPricing,
// })(PackageSession);
