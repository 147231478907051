import React from 'react';
import { Button } from 'reactstrap';
import { Edit2 } from 'react-feather';
import LocationService from '../../services/LocationService';
import '../../assets/css/locationSelectHeader.scss';

const LocationSelectHeaderPackage = (props) => {
  const count = LocationService.getTotalLocationsCount();
  const handleClick = () => {
    if (count > 1 && props.onIconClick) props.onIconClick();
  };
  return (
    <div>
      <Button
        outline
        className="font-weight-bold success-2-border custom-padding"
        color="success"
        size="md"
        onClick={handleClick}
      >
        {props.text}
      </Button>
    </div>
  );
};

export default LocationSelectHeaderPackage;
