import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Col,
  Media,
} from 'reactstrap';
import { X, Image } from 'react-feather';
import Swal from 'sweetalert2';

import { useTranslation } from 'react-i18next';

const PdfModal = (props) => {
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    const lang2 = localStorage.getItem('lang');
    i18n.changeLanguage(lang2);
  }, []);

  const [pdf, setPdf] = useState();

  let { isOpen, toggleModal } = props;

  let fileRef = React.createRef();

  const handleChange = (e) => {
    if (e.target.files.length > 0) {
      const allowedFileTypes = ['pdf'];
      const fileExtension = e.target.files[0].name.split('.').at(-1);
      if (!allowedFileTypes.includes(fileExtension)) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Upload pdf does not support. Image type must be ${allowedFileTypes.join(
            ', '
          )}`,
        });
        return false;
      }

      var size = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2);
      console.log('FILE SIZE', size);
      if (size > 10) {
        alert(t('please-select-the-pdf-size-less-than-10-mb'));
        return false;
      }
      let reader = new FileReader();
      reader.onload = (ev) => {
        const itemProp = {};
        itemProp.img = ev.target.result;
        setPdf(itemProp);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        className="modal-dialog-centered d-sm-margin-0 p-0"
        backdrop="static"
      >
        <div className="modal-header display-flex full-width flex-space-between">
          <h5 className="modal-title">{t('upload-pdf')}</h5>
          <X size={20} onClick={toggleModal} />
        </div>
        <ModalBody>
          <FormGroup className="row">
            <Col md={12}>
              <Label for="photoDiv">pdf:</Label>
              <div
                tabIndex="0"
                id="photoDiv"
                className="display-flex flex-column border border-2 border-light cursor-pointer"
                onKeyUp={(e) =>
                  e.keyCode === 13 && !e.ctrlKey
                    ? fileRef.current.click()
                    : null
                }
                onClick={() => fileRef.current.click()}
                style={{ position: 'relative', height: 120 }}
              >
                <div style={{ textAlign: '-webkit-center' }}>
                  {pdf?.img ? (
                    <div>
                      <div
                        style={{
                          position: 'absolute',
                          top: -10,
                          right: -10,
                        }}
                      >
                        <Button.Ripple
                          style={{ width: 20, height: 20 }}
                          onClick={() => {
                            fileRef.current.value = null;
                            setPdf(null);
                          }}
                          className="m-0 p-0"
                          color="danger"
                        >
                          <X size={14} />
                        </Button.Ripple>
                      </div>
                      <div
                        style={{ position: 'absolute', bottom: 3 }}
                        className="full-width"
                      >
                        <Button.Ripple
                          className="p-25"
                          color="primary"
                          onClick={() => fileRef.current.click()}
                        >
                          <span className="font-small-1">
                            {t('change-pdf')}
                          </span>
                        </Button.Ripple>
                      </div>
                      <Media
                        object
                        src={pdf.img}
                        alt="pdf"
                        style={{ maxWidth: '100%', maxHeight: 118 }}
                        className="align-self-center"
                      />
                    </div>
                  ) : (
                    <div
                      className="align-self-center"
                      style={{ padding: 9, height: 50, width: 50 }}
                    >
                      <Image size={32} className="align-self-center" />
                    </div>
                  )}
                </div>
                {pdf?.img ? null : (
                  <React.Fragment>
                    <span className="font-small-2 primary align-self-center">
                      {t('click-to-upload-a-file')}{' '}
                    </span>
                    <span className="font-small-2 color- align-self-center align-text-center">
                      PDF <br /> {t('up-to-10mb')}
                    </span>
                  </React.Fragment>
                )}
              </div>
              <input
                type="file"
                id="uploadPdfFileInput"
                name="fileInput"
                onChange={handleChange}
                ref={fileRef}
                style={{ display: 'none' }}
                accept="pdf/*"
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div className="d-none d-sm-flex flex-space-between full-width">
            <div>
              <Button
                color="dark"
                onClick={() => {
                  setPdf(null);
                  props.toggleModal(null);
                }}
              >
                {t('cancel')}{' '}
              </Button>{' '}
              <Button
                color="primary"
                onClick={() => {
                  setPdf(null);
                  props.saveUploadPdf();
                }}
              >
                {t('save')}{' '}
              </Button>{' '}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default PdfModal;
