import CONSTANTS from '../constants';
import LoginService from './LoginService';

import { customFetch as fetch } from '../helpers/FetchHelper';

export const getAllPackages = async () => {
  const { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.PACKAGES.ALL_PACKAGES}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Packages: ${response}`);
};

export const getPackageByLocationId = async (locationId) => {
  const { token } = LoginService.getToken();
  try {
    const response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.PACKAGES.GET_PACKAGE_BY_LOCATION(
        locationId
      )}`,

      {
        method: 'GET',
        headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error(`Could not load Packages: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error(`Could not load Packages: ${error.message}`);
  }
};
