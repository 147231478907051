// import React from "react";
// import {
//   Card,
//   CardBody,
//   CardText,
//   CardTitle,
//   Col,
//   Row,
//   Table,
// } from "reactstrap";

// function Detailspurchasehistory(props) {
//   console.log(props.location.state.data, "=================");
//   const list = props.location.state.data.item;
//   const allItems = props.location.state.data.allItems;
//   return (
//     <div>
//       <Card>
//         <CardBody className="">
//           <>
//             <CardTitle className="">Details</CardTitle>
//             <Row>
//               <Col className="mt-3" md={6}>
//                 <CardText>Location Id</CardText>
//                 <CardText>Location Name</CardText>
//               </Col>
//               <Col className="mt-3" md={6}>
//                 <CardText>{list?.location_id}</CardText>
//                 <CardText>{list.location_name}</CardText>
//               </Col>
//             </Row>
//             <hr />
//             <Row>
//               <Col md={6}>
//                 <CardText>Alternative-1</CardText>
//                 <CardText>Alternative-2 </CardText>
//                 <CardText>Bar Manager</CardText>
//                 <CardText>Dessert Manager</CardText>
//                 <CardText>Eater App Manager </CardText>
//                 <CardText>Kitchen Manager </CardText>
//                 <CardText>Waiter App Manager</CardText>
//               </Col>

//               <Col md={6}>
//                 {allItems?.countsByLocationAndGroup?.map((item, index) => (
//                   <React.Fragment key={index}>
//                     <CardText className="">
//                       {" "}
//                       {item.counts.Alternative_1
//                         ? item.counts.Alternative_1
//                         : 0}
//                     </CardText>
//                     <CardText>
//                       {" "}
//                       {item.counts.Alternative_2
//                         ? item.counts.Alternative_2
//                         : 0}
//                     </CardText>
//                     <CardText>
//                       {" "}
//                       {item.counts["Bar Manager"]
//                         ? item.counts["Bar Manager"]
//                         : 0}
//                     </CardText>
//                     <CardText>
//                       {item.counts["Dessert Manager"]
//                         ? item.counts["Dessert Manager"]
//                         : 0}
//                     </CardText>
//                     <CardText>
//                       {" "}
//                       {item.counts["Eater App Manager"]
//                         ? item.counts["Eater App Manager"]
//                         : 0}
//                     </CardText>
//                     <CardText>
//                       {" "}
//                       {item.counts["Kitchen Manager"]
//                         ? item.counts["Kitchen Manager"]
//                         : 0}
//                     </CardText>
//                     <CardText>
//                       {" "}
//                       {item.counts["Waiter App Manager"]
//                         ? item.counts["Waiter App Manager"]
//                         : 0}
//                     </CardText>
//                   </React.Fragment>
//                 ))}
//               </Col>
//             </Row>
//           </>
//         </CardBody>
//       </Card>
//     </div>
//   );
// }

// export default Detailspurchasehistory;

import React from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";

function Detailspurchasehistory(props) {
  const list = props.location.state.data.item;
  const locationId = list?.location_id;
  const matchingItem =
    props.location.state.data.allItems.countsByLocationAndGroup?.filter(
      (item) => item.location_id === locationId
    );

  return (
    <div>
      <Card>
        <CardBody className="">
          <>
            <CardTitle className="">Details</CardTitle>
            <Row>
              <Col className="mt-3" md={6}>
                <CardText>Location Id</CardText>
                <CardText>Location Name</CardText>
              </Col>
              <Col className="mt-3" md={6}>
                <CardText>{list?.location_id}</CardText>
                <CardText>{list.location_name}</CardText>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <CardText>Alternative-1</CardText>
                <CardText>Alternative-2 </CardText>
                <CardText>Bar Manager</CardText>
                <CardText>Dessert Manager</CardText>
                <CardText>Eater App Manager </CardText>
                <CardText>Kitchen Manager </CardText>
                <CardText>Waiter App Manager</CardText>
              </Col>

              <Col md={6}>
                {matchingItem?.map((item, index) => (
                  <React.Fragment key={index}>
                    <CardText className="">
                      {" "}
                      {item.counts.Alternative_1
                        ? item.counts.Alternative_1
                        : 0}
                    </CardText>{" "}
                    <CardText>
                      {" "}
                      {item.counts.Alternative_2
                        ? item.counts.Alternative_2
                        : 0}
                    </CardText>
                    <CardText>
                      {" "}
                      {item.counts["Bar Manager"]
                        ? item.counts["Bar Manager"]
                        : 0}
                    </CardText>
                    <CardText>
                      {item.counts["Dessert Manager"]
                        ? item.counts["Dessert Manager"]
                        : 0}
                    </CardText>
                    <CardText>
                      {" "}
                      {item.counts["Eater App Manager"]
                        ? item.counts["Eater App Manager"]
                        : 0}
                    </CardText>
                    <CardText>
                      {" "}
                      {item.counts["Kitchen Manager"]
                        ? item.counts["Kitchen Manager"]
                        : 0}
                    </CardText>
                    <CardText>
                      {" "}
                      {item.counts["Waiter App Manager"]
                        ? item.counts["Waiter App Manager"]
                        : 0}
                    </CardText>
                  </React.Fragment>
                ))}
              </Col>
            </Row>
          </>
        </CardBody>
      </Card>
    </div>
  );
}

export default Detailspurchasehistory;
