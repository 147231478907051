import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const ConfirmationModal = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  let {
    accept,
    reject,
    isOpen,
    title = t('are-you-sure-you-want-to-delete-this-item'),
    header = t('confirm-delete-action'),
    rejectTitle = t('no'),
  } = props;

  useEffect(() => {
    accept = props.accept;
    reject = props.reject;
    isOpen = props.isOpen;
    title = props.title
      ? props.title
      : t('are-you-sure-you-want-to-delete-this-item');
  }, [props]);

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={reject} className="modal-dialog-centered">
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>
          <span className="font-medium-1">{title}</span>
        </ModalBody>

        <ModalFooter>
          {reject && (
            <Button color="dark" onClick={reject}>
              {rejectTitle}
            </Button>
          )}{" "}
          {accept && (
            <Button color="danger" onClick={accept}>
              {t('yes')} </Button>
          )}{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default ConfirmationModal;
