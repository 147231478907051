import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Eater = () => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  return (
    <div>
      <h1 className="text-center">{t("coming-soon")}</h1>
    </div>
  );
};

export default Eater;
