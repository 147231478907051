// // import React from "react";
// // import { connect } from "react-redux";
// // import {
// //   Button,
// //   Card,
// //   CardBody,
// //   Col,
// //   Input,
// //   Row,
// //   CardHeader,
// //   CardTitle,
// //   CardImg,
// // } from "reactstrap";
// // import { Send, X } from "react-feather";
// // import LocationSelectHeader from "../shared/LocationSelectHeader";
// // import LocationService from "../../services/LocationService";
// // import { getLocation } from "../../redux/actions/locations";
// // import SelectLocation from "../shared/SelectLocation";
// // import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
// // import Swal from "sweetalert2";
// // import toast, { Toaster } from "react-hot-toast";
// // import { withTranslation } from "react-i18next";
// // import i18n from "i18next";
// // import TermsImage from "../../assets/img/tabscreen.jpg";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { auth } from "firebase";

// // class TermsConditions extends React.Component {
// //   constructor(props) {
// //     super(props);
// //     this.state = {
// //       selectLocation: false,
// //       location: null,
// //       loading: true,
// //     };
// //   }
// //   async componentDidMount() {
// //     const savedLanguage = localStorage.getItem("lang");
// //     if (savedLanguage) {
// //       i18n.changeLanguage(savedLanguage);
// //     }

// //     const location = LocationService.getLocation();
// //     if (location) {
// //       await this.refreshData(location.loc_id);
// //       this.setState({ loading: false });
// //     } else {
// //       this.setState({ loading: false, selectLocation: true });
// //     }
// //   }

// //   async refreshData(loc_id, initialize = true, fetchMenu = true) {
// //     if (!this.state.location || loc_id !== this.state.location.loc_id) {
// //       await this.props.getLocation(loc_id);
// //     }

// //     this.setState({
// //       loading: false,
// //       location: JSON.parse(JSON.stringify(this.props.location)),
// //     });
// //   }

// //   toggleLocation() {
// //     this.setState({ selectLocation: !this.state.selectLocation });
// //   }

// //   updateTermAndConditions = (location) => {
// //     if (
// //       document.getElementById("exampleText").value !== "" ||
// //       document.getElementById("exampleText").value !== null
// //     ) {
// //       let data = {
// //         terms_and_conditions: document.getElementById("exampleText").value,
// //       };

// //       toast.success(this.props.t("terms-and-conditions-saved-successfully"));
// //       LocationService.updateTermAndConditions(data, location.loc_id);
// //     }
// //   };

// //   render() {
// //     const { t } = this.props;
// //     const {
// //       props: { location },
// //     } = this;
// //     const {
// //       state: { selectLocation, loading },
// //     } = this;
// //     const locationName = location ? location.name : "";
// //     if (loading) return <SpinnerComponent />;
// //     if (selectLocation)
// //       return (
// //         <SelectLocation
// //           onBack={this.toggleLocation.bind(this)}
// //           onSelect={this.onSelect.bind(this)}
// //         />
// //       );
// //     console.log("location:", location);
// //     return (
// //       <div className="location">
// //         <Toaster position="top-right" />
// //         <div className="display-flex flex-row justify-content-between mb-1">
// //           <div className="d-flex">
// //             <LocationSelectHeader
// //               text={locationName}
// //               onIconClick={this.toggleLocation.bind(this)}
// //             />
// //           </div>
// //         </div>
// //         <Row className="m-0 justify-content-center">
// //           <Col md="12" className="d-flex justify-content-center">
// //             <Card className="col-12">
// //               <CardHeader>
// //                 <CardTitle>{t("terms-and-conditions")}</CardTitle>
// //               </CardHeader>
// //               <CardBody>
// //                 <Row>
// //                   <Col md="8">
// //                     <Input
// //                       type="textarea"
// //                       name="text"
// //                       id="exampleText"
// //                       rows="15"
// //                       placeholder={t("textarea")}
// //                       defaultValue={location?.terms_and_conditions}
// //                     />
// //                     <Button
// //                       className="btn-icon mt-5"
// //                       color="primary"
// //                       onClick={() => this.updateTermAndConditions(location)}
// //                     >
// //                       <Send size={15} /> {t("update-terms-and-conditions")}
// //                     </Button>
// //                   </Col>

// //                   <Col md="4">
// //                     <div className="row mb-4">
// //                       <Col md={"12"}>
// //                         <div
// //                           style={{
// //                             background: "black",
// //                             borderRadius: "30px",
// //                             padding: "25px",
// //                             // width: '29rem',
// //                             display: "flex",
// //                             margin: "auto",
// //                           }}
// //                           className="col-12"
// //                         >
// //                           <Card
// //                             className="p-1"
// //                             style={{
// //                               backgroundColor: "#F2F2F2",
// //                               width: "100%",

// //                               overflow: "auto",
// //                             }}
// //                           >
// //                             <div
// //                               className=" p-1 w-100"
// //                               style={{
// //                                 // backgroundColor: `${this.state.branding?.basecolor}`,
// //                                 display: "flex",
// //                                 justifyContent: "space-between",
// //                               }}
// //                             >
// //                               <div
// //                                 style={{
// //                                   display: "flex",
// //                                   alignItems: "center",
// //                                   background: "green",
// //                                   color: "white",
// //                                   borderRadius: "5px",
// //                                   padding: "5px",
// //                                   width: "100%",
// //                                   textAlign: "center",
// //                                 }}
// //                               >
// //                                 <span
// //                                   style={{
// //                                     margin: "auto",
// //                                     padding: "5px",
// //                                     textAlign: "center",
// //                                   }}
// //                                 >
// //                                   Welcome Text
// //                                 </span>
// //                               </div>
// //                             </div>

// //                             <Input
// //                               type="textarea"
// //                               name="text"
// //                               id="exampleText"
// //                               rows="15"
// //                               placeholder={t("textarea")}
// //                               defaultValue={location?.terms_and_conditions}
// //                             />

// //                             <div
// //                               style={{
// //                                 display: "flex",
// //                                 alignItems: "center",
// //                                 background: "green",
// //                                 color: "white",
// //                                 borderRadius: "5px",
// //                                 padding: "5px",
// //                                 width: "100%",
// //                                 textAlign: "center",
// //                                 marginTop: "20px",
// //                               }}
// //                             >
// //                               <span
// //                                 style={{
// //                                   margin: "auto",
// //                                   padding: "5px",
// //                                   textAlign: "center",
// //                                 }}
// //                               >
// //                                 Next
// //                               </span>
// //                             </div>
// //                           </Card>
// //                         </div>
// //                       </Col>
// //                     </div>
// //                   </Col>
// //                 </Row>
// //               </CardBody>
// //             </Card>
// //           </Col>
// //         </Row>
// //       </div>
// //     );
// //   }
// // }

// // function mapStateToProps(state) {
// //   const {
// //     locations: {
// //       location: { data: locationData, loading: locationLoading },
// //     },
// //   } = state;
// //   return {
// //     location: locationData,
// //     loading: locationLoading,
// //   };
// // }

// // export default withTranslation("global")(
// //   connect(mapStateToProps, {
// //     getLocation,
// //   })(TermsConditions)
// // );

import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Send } from "react-feather";
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import LocationService from "../../services/LocationService";
import SelectLocation from "../shared/SelectLocation";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { getLocation } from "../../redux/actions/locations";
import TermsImage from "../../assets/img/tabscreen.jpg";
import { auth } from "firebase";

class TermsConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
      editorContent: "",
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchMenu = true) {
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
    }

    const { location } = this.props;
    const defaultWelcomeMessage = "";
    const combinedTermsAndConditions = `${defaultWelcomeMessage}\n\n${location.terms_and_conditions}`;

    this.setState({
      loading: false,
      location: {
        ...location,
        terms_and_conditions: combinedTermsAndConditions,
      },
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  handleEditorChange = (content) => {
    this.setState({ editorContent: content });
  };

  updateTermAndConditions = (location) => {
    let data = {
      terms_and_conditions: this.state.editorContent,
    };

    toast.success(this.props.t("welcome-message-saved-successfully"));
    LocationService.updateTermAndConditions(data, location.loc_id);
  };

  render() {
    const { t } = this.props;
    const { location, selectLocation, loading, editorContent } = this.state;
    const locationName = location ? location.name : "";

    if (loading) return <SpinnerComponent />;
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <div className="location">
        <Toaster position="top-right" />
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
          </div>
        </div>
        <Row className="m-0 justify-content-center">
          <Col md="12" className="d-flex justify-content-center">
            <Card className="col-12">
              <CardHeader>
                <CardTitle>{t("welcome-message")}</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="8">
                    <Editor
                      apiKey="1ausnzpznit2slgbdbs476puc2wt1bllt99rw6d6zvjflaqh"
                      initialValue={location.terms_and_conditions}
                      init={{
                        plugins:
                          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                        toolbar:
                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                      }}
                      onEditorChange={this.handleEditorChange}
                    />
                    <Button
                      className="btn-icon mt-5"
                      color="primary"
                      onClick={() => this.updateTermAndConditions(location)}
                    >
                      <Send size={15} /> {t("update-welcome-message")}
                    </Button>
                  </Col>

                  <Col md="4">
                    <div className="row mb-4">
                      <Col md={"12"}>
                        <Card>
                          <CardHeader>
                            <CardTitle>Preview</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <div className="row mb-4">
                              <div
                                style={{
                                  background: "black",
                                  borderRadius: "30px",
                                  padding: "25px",
                                  display: "flex",
                                  margin: "auto",
                                }}
                                className="col-12"
                              >
                                <Card
                                  className="p-1"
                                  style={{
                                    backgroundColor: "#F2F2F2",
                                    width: "100%",
                                    overflow: "auto",
                                  }}
                                >
                                  <div
                                    className=" p-1 w-100"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        background: "green",
                                        color: "white",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          margin: "auto",
                                          padding: "5px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Welcome Text
                                      </span>
                                    </div>
                                  </div>

                                  <CardBody
                                    dangerouslySetInnerHTML={{
                                      __html: editorContent,
                                    }}
                                    style={{
                                      height: "250px",
                                      overflowY: "auto",
                                    }}
                                  />

                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      background: "green",
                                      color: "white",
                                      borderRadius: "20px",
                                      padding: "5px",
                                      width: "100%",
                                      textAlign: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        margin: "auto",
                                        padding: "5px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Accept
                                    </span>
                                  </div>
                                </Card>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
  } = state;
  return {
    location: locationData,
    loading: locationLoading,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getLocation })(TermsConditions)
);
