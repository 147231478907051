import React, { Component } from "react";
import { connect } from "react-redux";

import { getLocation } from "../../redux/actions/locations";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import TimeHelper from "../../helpers/TimeHelper";

import DataTableComponent from "../shared/DataTableComponent";
import CONSTANTS from "../../constants";
import ViewHelper from "../../helpers/ViewHelper";
import LocationService from "../../services/LocationService";
import AnalyticsService from "../../services/AnalyticsService";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class TableAverageSpend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filters: {
        startDate: TimeHelper.getFirstDayOf2018(),
        endDate: TimeHelper.getCurrentDate(),
        orderBy: "total_sales",
        order: "desc",
      },
      columns: [
        {
          name: this.props.t('table-name'),
          // selector: "table_name",
          selector: (row) => row.table_name,
          sortable: false,
        },
        {
          name: this.props.t('overall-spends'),
          // selector: 'total_sale',
          selector: (row) => row.total_sale,
          sortable: false,
          format: (val) => {
            return `${ViewHelper.toCurrency(val.total_sale)}`;
          },
        },
        {
          name: this.props.t('average-spend'),
          // selector: 'average_spends',
          selector: (row) => row.average_spends,
          sortable: false,
          format: (val) => {
            let average_spends = val.total_sale / val.total_sessions;
            return `${ViewHelper.toCurrency(average_spends)}`;
          },
        },
        {
          name: this.props.t('total-sessions'),
          // selector: 'total_sessions',
          selector: (row) => row.total_sessions,
          sortable: true,
        },
      ],
      tableSpendingList: null,
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    await this.refreshData();
  }

  async refreshData() {
    let tableSpendingList = await this.getTableStats(
      this.props.location.loc_id
    );
    this.setState({
      loading: false,
      tableSpendingList: tableSpendingList,
    });
  }

  async getTableStats(loc_id, page = 0) {
    let tableSpendingList = await AnalyticsService.getTableStatsList(
      loc_id,
      page,
      this.state.filters
    );
    return tableSpendingList.tablesSpendList;
  }

  async filterUpdated(date) {
    const {
      state: { filters },
    } = this;
    const {
      props: { location },
    } = this;
    filters.startDate = TimeHelper.toFormattedDate(
      date[0],
      CONSTANTS.DATES.FORMAT.DB_DATE
    );
    if (date.length > 1)
      filters.endDate = TimeHelper.toFormattedDate(
        date[1],
        CONSTANTS.DATES.FORMAT.DB_DATE
      );
    else filters.endDate = TimeHelper.getCurrentDate();

    this.setState({
      filters: filters,
      tableSpendingList: await this.getTableStats(location.loc_id),
    });
  }

  render() {
    const { t } = this.props;
    const {
      state: { loading, columns, filters, tableSpendingList },
    } = this;

    if (loading) return <SpinnerComponent />;

    return (
      <div className="location">
        {!loading && tableSpendingList ? (
          <DataTableComponent
            dateFilterUpdated={this.filterUpdated.bind(this)}
            showDateFilter={true}
            exportBtn={true}
            title={t('tables-spending')}
            data={tableSpendingList}
            columns={columns}
            startDate={filters.startDate}
            endDate={filters.endDate}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
  } = state;

  return {
    location: locationData,
    loading: locationLoading,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getLocation })(TableAverageSpend)
);
