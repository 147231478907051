import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from '../shared/ErrorHandler';
import {
  getAllergicItems,
  deleteMultipleAllergyItem,
} from '../../redux/actions/menus';
import '../../assets/scss/pages/page-pricing.scss';
import ListAllergicItems from './ListAllergicItems';
import { Button } from 'reactstrap';
import ConfirmationModal from '../shared/forms/ConfirmationModal';
import MenuService from '../../services/MenuService';
import LocationService from '../../services/LocationService';
import SelectLocation from '../shared/SelectLocation';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import { Plus } from 'react-feather';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class AllergicItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: '',
      location: null,
      item: null,
      items: [],
      selectLocation: false,
      dishtag: {
        item_id: '',
        item_name: '',
        loc_id: '',
        description: '',
      },
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t('app-users'),
        header: this.props.t('app-users'),
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t('app-users'),
        header: this.props.t('app-users'),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }
  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);
    await this.props.getAllergicItems(loc.loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  handleChange = (state) => {
    let reviews = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      reviews.push(state.selectedRows[i].review_id);
    }
    this.setState({ reviews: reviews });
  };

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: '',
      header: '',
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: '',
      header: '',
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }
  editDishTag(item) {
    // this.props.history.replace("/allergic_items/edit/" + item.item_id);
    this.props.history.push({
      pathname: '/allergic_items/edit/' + item.item_id,
      state: { item: item },
    });
  }
  handleChange = (state) => {
    let items = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      items.push(state.selectedRows[i].item_id);
    }
    this.setState({ items: items });
  };

  async deleteMultipleAllergyItems() {
    console.log('FUNCTION CALLED');
    console.log('this.state.items: ', this.state.items);
    if (this.state.items.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.setState({ loading: true });
          try {
            await this.props.deleteMultipleAllergyItem(
              this.state.location.loc_id,
              this.state.items
            );
            console.log('PROPS ', this.props);
            await this.refreshData(this.state.location.loc_id, false);
            this.setState({ items: [] }); // Clear selected items
          } catch (e) {
            this.showErrorDialog(null, e.toString(), this.props.t('warning'));
          }
          await this.setState({ loading: false });
        },
        null,
        this.props.t('are-you-sure-you-want-to-delete-these-allergy-items'),
        this.props.t('allergy-items')
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t('please-select-at-least-one-allergy-item-to-delete'),
        this.props.t('warning')
      );
    }
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  render() {
    const { t } = this.props;

    const {
      props: { allergicitems },
    } = this;
    const {
      state: {
        confirmationDialog,
        errorDialog,
        selectLocation,
        location,
        loading,
      },
    } = this;

    const locationName = location ? location.name : '';
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="display-block mb-1">
          <div className="flex-column">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.changeLocation.bind(this)}
            />
            <Button
              outline
              className="font-weight-bold custom-padding primary-2-border mb-1  mt-2"
              color={'primary'}
              size="md"
              onClick={() => this.props.history.replace('/allergic_items/add')}
            >
              <Plus className="mx-2" size={20} />
              <span className="ml-1 mr-2 mr-md-5">
                {t('add-new-allergic-item')}
              </span>
            </Button>

            <Button
              outline
              className="font-weight-bold custom-padding primary-2-border mb-1 ml-1 btn btn-danger btn-md mt-2"
              size="lg"
              onClick={() => this.deleteMultipleAllergyItems()}
            >
              <span
                style={{ color: 'white', fontSize: '14px' }}
                className="ml-1 mr-1  "
              >
                {t('delete')}{' '}
              </span>
            </Button>
          </div>
        </div>
        <div id="pricing-table">
          {!loading && allergicitems ? (
            <ListAllergicItems
              allergicitems={allergicitems}
              handleChange={this.handleChange.bind(this)}
              editDishTag={this.editDishTag.bind(this)}
            />
          ) : null}
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t('cancel')}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={'OK'}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    menu: {
      allAllergicItems: { data: allergicitems, loading: loading },
    },
  } = state;

  return { allergicitems, loading: loading };
}

export default withTranslation('global')(
  connect(mapStateToProps, {
    getAllergicItems,
    deleteMultipleAllergyItem,
  })(AllergicItems)
);
