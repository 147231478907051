import React, {useEffect, useState} from 'react';
import {grey} from "@mui/material/colors";
import {
    Table,
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {Add, Close, Remove} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import numeral from "numeral";
import {
    addToCart,
    handleCheckboxValueSelection, handleDecrement, handleIncrement, handleRadioValueSelection,
    handleSelectValueSelection, removeCartItem,
    setItemsToModifyHelper
} from "../../../../../../helpers/posHelpers";
import {clearCart} from "../../../../../../redux/actions/cart";
import {setRemoveItems} from "../../../../../../redux/actions/pos/order";

const tableHeaders = [
    "Item", "Price", "Qty", "Discount", "Total", "Actions"
]

const ModifyCartComponent = () => {
    const cart = useSelector((state) => state.cart.cartMenu);
    const selectedOrder = useSelector(state => state.pos.selectedOrder);
    const menuData = useSelector(state => state.menu.menuData.data.menu_data);
    const locationData = useSelector(state => state.pos.locationData);
    const [filteredOrderMenu, setFilteredOrderMenu] = useState([]);
    const [removeItems, setRItems] = useState([]);

    const [specialInstruction, setSpecialInstruction] = useState("");

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const calculateTotal = (item, setSelectedRadioCartItem, selectedSelectCartItem, selectedCheckboxCartItem, quantity) => {
        let baseprice = 0;
        let subtotal = 0;
        let totalamount = 0;
        let totaltax = 0;
        let menutax = 0;

        if (setSelectedRadioCartItem.length > 0) {
            setSelectedRadioCartItem.map((item) => {
                item.Option_Values.map((option) => {
                    subtotal += parseFloat(option.price) * (option.optionCount);
                    totalamount += parseFloat(option.price) * (option.optionCount);
                    if (option.order_item_tax > 0) {
                        totaltax += parseFloat(option.order_item_tax) * (option.optionCount);
                        totalamount += parseFloat(option.order_item_tax) * (option.optionCount);
                    }
                });
            });
        }

        if (selectedCheckboxCartItem.length > 0) {
            selectedCheckboxCartItem.map((item) => {
                item.Option_Values.map((option) => {
                    subtotal += parseFloat(option.price) * (option.optionCount);
                    totalamount += parseFloat(option.price) * (option.optionCount);
                    if (option.order_item_tax > 0) {
                        totaltax += parseFloat(option.order_item_tax) * (option.optionCount);
                        totalamount += parseFloat(option.order_item_tax) * (option.optionCount);
                    }
                });
            });
        }

        if (selectedSelectCartItem.length > 0) {
            selectedSelectCartItem.map((item) => {
                item.Option_Values.map((option) => {
                    subtotal += parseFloat(option.price) * (option.optionCount);
                    totalamount += parseFloat(option.price) * (option.optionCount);
                    if (option.order_item_tax > 0) {
                        totaltax += parseFloat(option.order_item_tax) * (option.optionCount);
                        totalamount += parseFloat(option.order_item_tax) * (option.optionCount);
                    }
                });
            });
        }

        if (item && item.menu_id) {
            if (baseprice === 0) {
                subtotal += parseFloat(item.menu_price) * quantity;
                totalamount += parseFloat(item.menu_price) * quantity;
                menutax = ((item.menu_price / 100) * item.menu_tax) * quantity;
                menutax = parseFloat(menutax).toFixed(2);
                totalamount += parseFloat(menutax);
            }
            totaltax += parseFloat(menutax);
        }

        return {subtotal, totalamount, totaltax, menutax}
    }

    const setItemsForTable = async () => {
        let update = 0;
        for (let i = 0; i < filteredOrderMenu.length; i++) {
            let selectedValues = [], selectValueCounts = [], selectedSelectCartItem = [], selectedCheckboxes = [], selectedCheckboxCartItem = [], setSelectedRadioValues = [], setSelectedRadioCartItem = [];
            if (filteredOrderMenu[i].MenuOptions.length > 0) {
                for (let j = 0; j < filteredOrderMenu[i].MenuOptions.length; j++) {
                    if (filteredOrderMenu[i].MenuOptions[j].MenuOptionValues.length > 0) {
                        for (let k = 0; k < filteredOrderMenu[i].MenuOptions[j].MenuOptionValues.length; k++) {
                            if (filteredOrderMenu[i].MenuOptions[j].Option.display_type === "select") {
                                for (let l = 1; l <= filteredOrderMenu[i].MenuOptions[j].MenuOptionValues[k].order_quantity; l++) {
                                    const {
                                        updatedValues,
                                        updatedCounts,
                                        updatedCartItems
                                    } = handleSelectValueSelection(filteredOrderMenu[i].MenuOptions[j].MenuOptionValues[k].option_value_id, filteredOrderMenu[i].MenuOptions[j], "increment", selectedValues, selectValueCounts, selectedSelectCartItem, filteredOrderMenu[i].mainQty);
                                    selectedValues = updatedValues;
                                    selectValueCounts = updatedCounts;
                                    selectedSelectCartItem = updatedCartItems;
                                }
                            } else if (filteredOrderMenu[i].MenuOptions[j].Option.display_type === "checkbox") {
                                const {arg1, arg2} = handleCheckboxValueSelection(filteredOrderMenu[i].MenuOptions[j].MenuOptionValues[k].option_value_id, selectedCheckboxes, filteredOrderMenu[i].MenuOptions[j], selectedCheckboxCartItem, filteredOrderMenu[i].mainQty)
                                selectedCheckboxes = arg1;
                                selectedCheckboxCartItem = arg2;
                            } else if (filteredOrderMenu[i].MenuOptions[j].Option.display_type === "radio") {
                                const {updatedSelectedRadioValues, updatedSelectedRadioCartItem} = handleRadioValueSelection(j, filteredOrderMenu[i].MenuOptions[j].MenuOptionValues[k].option_value_id, filteredOrderMenu[i].MenuOptions[j], setSelectedRadioCartItem, setSelectedRadioValues, filteredOrderMenu[i].mainQty)
                                setSelectedRadioValues = updatedSelectedRadioValues;
                                setSelectedRadioCartItem = updatedSelectedRadioCartItem;
                            }
                        }
                    }
                }
            }
            const {subtotal, totalamount, totaltax, menutax} = calculateTotal(filteredOrderMenu[i], setSelectedRadioCartItem, selectedSelectCartItem, selectedCheckboxCartItem, filteredOrderMenu[i].mainQty);
            addToCart(subtotal, totaltax, totalamount, dispatch, setSelectedRadioCartItem, selectedCheckboxCartItem, selectedSelectCartItem, filteredOrderMenu[i], cart.cart, filteredOrderMenu[i].mainQty, specialInstruction, update);
            update += 1;
        }
        setLoading(false);
    }

    useEffect(() => {
        if (filteredOrderMenu.length > 0) {
            setItemsForTable()
        }
    }, [filteredOrderMenu]);

    const setItemsToModify = () => {
        setItemsToModifyHelper(selectedOrder, menuData, setFilteredOrderMenu, setLoading); //moved to helper file because of large size
    }

    useEffect(() => {
        (async () => {dispatch(clearCart())})();
        setItemsToModify()
    }, []);

    useEffect(() => {
        dispatch(setRemoveItems(removeItems))
    }, [removeItems]);

    const formatPrice = (price) => {
        if (price >= 1000) {
            const formattedValue = numeral(price).format("0.0a");
            return formattedValue;
        } else {
            return numeral(price).format("0.00");
        }
    };

    const calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
        const itemPrice = menuPrice || 0;
        const tax = taxPercent || 0;

        const totalPrice =
            tax > 0
                ? (itemPrice + (itemPrice * tax) / 100) * itemCount
                : itemPrice * itemCount;

        return formatPrice(totalPrice);
    };

    const calculateVariantPrice = (optionPrice, taxPercent, itemCount) => {
        const itemPrice = optionPrice || 0;
        const tax = taxPercent || 0;
        const variantPrice =
            tax > 0
                ? itemPrice * itemCount * (tax / 100) + itemPrice * itemCount
                : itemPrice * itemCount;

        return variantPrice;
    };

    return(
        <Stack sx={{border: `1px solid ${grey[400]}`, borderRadius: '0.25rem', overflowX: 'hidden', flexGrow: 1, overflowY: 'auto', height: 'calc(100vh - 300px)'}}>
            {loading ?
                <Stack alignItems={'center'} justifyContent={'center'} height={'100%'}>
                    <CircularProgress color='success' />
                </Stack>
                :
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                tableHeaders.map((header, index) => (
                                    <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '600'}} key={index}>{header}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cart.cart.map((item, itemIndex) => (
                            <TableRow key={itemIndex}>
                                <TableCell align={'left'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>
                                    {item.menu_name}
                                    <Stack sx={{rowGap: '0.125rem'}}>
                                        {
                                            item.MenuOptions.length > 0 && (item.MenuOptions.map((menu_option, index) => (
                                                <React.Fragment key={menu_option.option_id}>
                                                    {index !== 0 && <Divider />}
                                                    <Typography variant="body2" color="textSecondary" sx={{fontSize: '0.75rem', fontWeight: 'bold'}}>
                                                        {menu_option.option_name}
                                                    </Typography>
                                                    {menu_option.Option_Values.map((menu_option_value) => (
                                                        <Typography key={menu_option_value.option_id} variant="body2" color="textSecondary" sx={{fontSize: '0.7rem', textTransform: 'capitalize'}}>
                                                            {`${menu_option_value.optionCount}x ${menu_option_value.value} = ${locationData.Currency.currency_symbol}${menu_option_value.price === 0
                                                                ? "Free" :
                                                                calculateVariantPrice(
                                                                    menu_option_value.price,
                                                                    menu_option_value.order_item_tax_percentage,
                                                                    menu_option_value.optionCount
                                                                ).toFixed(2)
                                                            }`}
                                                        </Typography>
                                                    ))}
                                                </React.Fragment>
                                            )))
                                        }
                                    </Stack>
                                </TableCell>
                                <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>{locationData.Currency.currency_symbol}{calculateTotalPrice(item.menu_price, item.menu_tax_percentage, 1)}</TableCell>
                                <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>
                                    <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.25rem', justifyContent: 'center'}}>
                                        <IconButton size='small' onClick={()=> handleIncrement(itemIndex, dispatch, cart, filteredOrderMenu, setRItems, removeItems)}>
                                            <Add sx={{height: '1.25rem', width: '1.25rem'}}/>
                                        </IconButton>
                                        {item.itemCount}
                                        <IconButton size='small' onClick={()=> handleDecrement(itemIndex, dispatch, cart, filteredOrderMenu, setRItems, removeItems)}>
                                            <Remove sx={{height: '1.25rem', width: '1.25rem'}}/>
                                        </IconButton>
                                    </Stack>
                                </TableCell>
                                <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>
                                    <TextField
                                        disabled
                                        inputProps={{
                                            style: {
                                                padding: '0.5rem 0.65rem'
                                            }
                                        }} variant='outlined' size='small' placeholder={'Amt'} sx={{width: '50px'}}/>
                                </TableCell>
                                <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>{locationData.Currency.currency_symbol}{calculateTotalPrice(item.menu_price, item.menu_tax_percentage, item.itemCount)}</TableCell>
                                <TableCell align={'center'} sx={{padding: '0.5rem !important', fontWeight: '400', textTransform: 'capitalize'}}>
                                    <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.25rem', justifyContent: 'center'}}>
                                        {/*<Tooltip title={`Edit ${item.menu_name}`}>*/}
                                        {/*    <IconButton size='small'>*/}
                                        {/*        <Edit sx={{height: '1.25rem', width: '1.25rem'}}/>*/}
                                        {/*    </IconButton>*/}
                                        {/*</Tooltip>*/}
                                        <Tooltip title={`Remove ${item.menu_name}`}>
                                            <IconButton size='small' onClick={()=> removeCartItem(itemIndex, dispatch, cart, setRItems)}>
                                                <Close sx={{height: '1.25rem', width: '1.25rem'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </Stack>
    )
}

export default ModifyCartComponent;