import React from 'react';

export default ({input, label, type, meta: { touched, error, warning }}) => {
  return (
    <div className='form-check'>
      <input {...input} className='form-check-input' type='checkbox' checked={input.value} />
      <label className='form-check-label'>{label}</label>
    </div>
  );
}
