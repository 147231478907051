import React from 'react';
import {Box, Divider, Stack, Typography} from "@mui/material";
import servallLogo from "../../../../../assets/img/Servall1.png";
import numeral from "numeral";
import {grey} from "@mui/material/colors";

const PrintableComponent = React.forwardRef((props, ref) => {
        const formatPrice = (price) => {
            if (price >= 1000) {
                const formattedValue = numeral(price).format("0.0a");
                return formattedValue;
            } else {
                return numeral(price).format("0.00");
            }
        };

        const calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
            const itemPrice = menuPrice || 0;
            const tax = taxPercent || 0;

            const totalPrice =
                tax > 0
                    ? (itemPrice + (itemPrice * tax) / 100) * itemCount
                    : itemPrice * itemCount;

            return formatPrice(totalPrice);
        };

        const calculateVariantPrice = (optionPrice, taxPercent, itemCount) => {
            const itemPrice = optionPrice || 0;
            const tax = taxPercent || 0;
            const variantPrice =
                tax > 0
                    ? itemPrice * itemCount * (tax / 100) + itemPrice * itemCount
                    : itemPrice * itemCount;

            return variantPrice;
        };

        return (
            <Box ref={ref} sx={{minHeight: '100%', width: '100%', backgroundColor: 'white', padding: '1rem'}}>
                <Stack sx={{width: '500px', marginX: 'auto', alignItems: 'center', color: 'black'}}>
                    <Box component='img' src={servallLogo} alt='Servall' sx={{width: '125px', height: '65px', borderRadius: '0.25rem', marginBottom: '1rem'}}/>
                    <Typography variant='h5' sx={{fontSize: '1.75rem', fontWeight: '800'}}>{props.locationData.name}</Typography>
                    <Typography variant='body2' sx={{fontSize: '1rem'}}>Address: {props.locationData.formattedAddress}</Typography>
                    <Typography variant='body2' sx={{fontSize: '1rem'}}>Phone: {props.locationData.location_telephone}</Typography>
                    <Typography variant='body2' sx={{fontSize: '1rem'}}>Bill No: {props.orderData.order_id}</Typography>
                    <Typography variant='h5' sx={{fontSize: '1.75rem', fontWeight: '800', textTransform: 'capitalize'}}>Order Type: {props.orderData.order_type}</Typography>
                </Stack>
                <Stack sx={{width: '500px', marginX: 'auto', color: 'black', marginY: '1.5rem'}}>
                    <Typography variant='body2' sx={{fontSize: '1rem'}}>Date: {props.orderData.order_date} {props.orderData.order_time}</Typography>
                    <Typography variant='body2' sx={{fontSize: '1rem'}}>Sales Associate: Manager</Typography>
                    <Typography variant='body2' sx={{fontSize: '1rem'}}>Customer: </Typography>
                </Stack>
                <Stack sx={{width: '500px', marginX: 'auto', color: 'black'}}>
                    {props.orderData.OrderMenus.map((mainMenu, mainIndex) => (
                        <>
                            <Divider />
                            <Stack direction='row' sx={{paddingX: '0.5rem', width: '100%', paddingY: '0.5rem', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600'}}># {mainIndex + 1}: {mainMenu.name} &#160;&#160;&#160;{mainMenu.quantity} X {props.locationData.Currency.currency_symbol}{calculateTotalPrice(mainMenu.price, mainMenu.menu_tax_percentage, 1)}</Typography>
                                <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600'}}>{props.locationData.Currency.currency_symbol}{calculateTotalPrice(mainMenu.price, mainMenu.menu_tax_percentage, mainMenu.quantity)}</Typography>
                            </Stack>
                            {
                                mainMenu.OrderOptions.map((orderOption, optionIndex) => (
                                    <Stack key={orderOption.order_option_id} direction='row' sx={{paddingX: '0.75rem', width: '100%', paddingY: '0.25rem', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Typography variant='body2' sx={{fontSize: '0.9rem'}}># {optionIndex + 1}: {orderOption.order_option_id} &#160;&#160;&#160;{orderOption.quantity} X {props.locationData.Currency.currency_symbol}{calculateVariantPrice(orderOption.order_option_price, orderOption.order_item_tax_percentage, 1)}</Typography>
                                        <Typography variant='body2' sx={{fontSize: '0.9rem'}}>{props.locationData.Currency.currency_symbol}{calculateTotalPrice(orderOption.order_option_price, orderOption.order_item_tax_percentage, orderOption.quantity)}</Typography>
                                    </Stack>
                                ))
                            }

                        </>
                    ))}
                    <Divider />
                    <Stack direction='row' sx={{paddingX: '0.5rem', width: '100%', paddingY: '0.5rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>Total Item(s): {props.orderData.OrderMenus.length}</Typography>
                    </Stack>
                    <Divider />
                    <Stack direction='row' sx={{paddingX: '0.5rem', width: '100%', paddingY: '0.5rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>Sub Total</Typography>
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>{props.locationData.Currency.currency_symbol}{props.orderData.OrderTotals.find(total => total.code === 'cart_total').value}</Typography>
                    </Stack>
                    <Divider />
                    <Stack direction='row' sx={{paddingX: '0.5rem', width: '100%', paddingY: '0.5rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>Total Tax</Typography>
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>{props.locationData.Currency.currency_symbol}{props.orderData.OrderTotals.find(total => total.code === 'tax').value}</Typography>
                    </Stack>
                    <Divider />
                    <Stack direction='row' sx={{paddingX: '0.5rem', width: '100%', paddingY: '0.5rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>Grand Total</Typography>
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>{props.locationData.Currency.currency_symbol}{props.orderData.OrderTotals.find(total => total.code === 'order_total').value}</Typography>
                    </Stack>
                    <Divider />
                    <Stack direction='row' sx={{paddingX: '0.5rem', backgroundColor: grey[200], width: '100%', paddingY: '0.5rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>Total Payable</Typography>
                        <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>{props.locationData.Currency.currency_symbol}{props.orderData.OrderTotals.find(total => total.code === 'order_total').value}</Typography>
                    </Stack>
                    <Divider />
                    {props.paymentData &&
                        <Stack direction='row' sx={{paddingX: '0.5rem', backgroundColor: 'white', width: '100%', paddingY: '0.5rem', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>Payment Methods</Typography>
                        </Stack>
                    }
                    {
                        props.paymentData && props.paymentData.map((payment, index) => (
                            <React.Fragment key={index}>
                                <Divider />
                                <Stack direction='row' sx={{backgroundColor: grey[200], paddingX: '0.5rem', width: '100%', paddingY: '0.5rem', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>{payment.type}</Typography>
                                    <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '800'}}>{props.locationData.Currency.currency_symbol}{payment.amount}</Typography>
                                </Stack>
                            </React.Fragment>
                        ))
                    }
                    <Typography variant='body2' sx={{marginX: 'auto', fontSize: '1rem', marginY: '1rem'}}>Thank you for visiting us!</Typography>
                </Stack>
            </Box>
        );
    }
);

export default PrintableComponent;