import React, { Component } from "react";
import { connect } from "react-redux";

import { getLocation } from "../../redux/actions/locations";
import { getOverallStats } from "../../redux/actions/analytics";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import TimeHelper from "../../helpers/TimeHelper";

import OverallStatsGraphCard from "./OverallStatsGraphCard";
import CONSTANTS from "../../constants";
import LocationService from "../../services/LocationService";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class OverallStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: {
        loc_id: 0,
        name: this.props.t('all-venues'),
      },
      loading: false,
      filters: {
        startDate: TimeHelper.getThirtyDaysBeforeToday(),
        endDate: TimeHelper.getCurrentDate(),
        groupBy: "date",
        order: "desc",
        filterTitle: this.props.t('last-30-days'),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation(
    
    );
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id) {
    this.setState({ loading: true });
    let location;
    if (loc_id !== 0) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = {
        loc_id: 0,
        name: this.props.t('all-venues'),
      };
    }
    await this.props.getOverallStats(loc_id, this.state.filters);
    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(location)),
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  onBlur() {
    if (this.state.location)
      this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  onFilterChange(value, filter) {
    console.log(`${value}, ${filter}`);
    const {
      state: { filters },
    } = this;

    if (filter === "date") {
      filters.startDate = TimeHelper.toFormattedDate(
        value[0],
        CONSTANTS.DATES.FORMAT.DB_DATE
      );
      if (value.length > 1)
        filters.endDate = TimeHelper.toFormattedDate(
          value[1],
          CONSTANTS.DATES.FORMAT.DB_DATE
        );
      else filters.endDate = TimeHelper.getCurrentDate();
    } else {
      filters[filter] = value;
    }
    this.setState({ filters: filters });
  }

  async applyFilters() {
    this.setState({ loading: true });
    await this.props.getOverallStats(
      this.state.location.loc_id,
      this.state.filters
    );
    this.setState({ loading: false });
  }

  render() {
    const { t } = this.props;

    const {
      props: { stats },
    } = this;

    const {
      state: { selectLocation, loading, location: stateLocation, filters },
    } = this;

    const locationName = stateLocation ? stateLocation.name : "";

    if (loading) return <SpinnerComponent />;

    if (selectLocation)
      return (
        <SelectLocation
          showAll={true}
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <div className="location">
        <div className="mb-50">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.toggleLocation.bind(this)}
          />
        </div>
        {stats && !loading ? (
          <OverallStatsGraphCard
            onChange={this.onFilterChange.bind(this)}
            applyFilters={this.applyFilters.bind(this)}
            filters={filters}
            location={stateLocation}
            stats={stats}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    analytics: {
      overallStats: { data: statsData, loading: statsLoading },
    },
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
  } = state;

  return {
    stats: statsData,
    location: locationData,
    loading: statsLoading || locationLoading,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getLocation, getOverallStats })(OverallStats)
);
