import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from '../shared/ErrorHandler';
import {
  getAllTables,
  changeTableStatus,
  deleteTable,
  reGenerateQr,
  unLinkedTabFromTableId,
} from '../../redux/actions/tables';
import '../../assets/scss/pages/page-pricing.scss';
import ListTables from './ListTables';
import { Button } from 'reactstrap';
import ConfirmationModal from '../shared/forms/ConfirmationModal';
import LocationService from '../../services/LocationService';
import TableService from '../../services/TableService';
import SelectLocation from '../shared/SelectLocation';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class Tables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: '',
      location: null,
      image: null,
      selectLocation: false,
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t('app-users'),
        header: this.props.t('app-users'),
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t('app-users'),
        header: this.props.t('app-users'),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);
    await this.props.getAllTables(loc.loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  onChangeToggle = async (event, table, type) => {
    let tableData = [];
    tableData.push(table);
    await TableService.changeTableStatusById(
      this.state.location.loc_id,
      tableData
    );
  };

  unLinkedTabFromTable = async (event, table, type) => {
    let tableData = [];
    tableData.push(table);
    await this.props.unLinkedTabFromTableId(
      this.state.location.loc_id,
      tableData,
      this.props.tables
    );
    // let updatedTable = await TableService.unLinkedTabFromTableById(this.state.location.loc_id, tableData);
    // // const updateTableIndex = this.state.tables.findIndex(object => {
    // //   return object.table_id === table.table_id;
    // // });

    // // console.log("index: " , this.props.tables)
    // this.setState({ tables: [] });
  };

  handleChange = (state) => {
    let tables = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      tables.push(state.selectedRows[i].table_id);
    }
    this.setState({ tables: tables });
  };

  async updateTableStatus() {
    if (this.state.tables.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.setState({ loading: true });
          await this.props.changeTableStatus(
            this.state.location.loc_id,
            this.state.tables
          );
          await this.setState({ loading: false });
        },
        null,
        this.props.t(
          'are-you-sure-you-want-to-change-the-status-of-this-tables'
        ),
        this.props.t('tables')
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t('please-select-atleast-one-table-for-change-status'),
        this.props.t('warning')
      );
    }
  }
  async deleteTables() {
    try {
      if (this.state.tables.length > 0) {
        this.showConfirmationDialog(
          async () => {
            await this.setState({ loading: true });
            try {
              let result = await this.props.deleteTable(
                this.state.location.loc_id,
                this.state.tables
              );
            } catch (e) {
              this.showErrorDialog(null, e.toString(), this.props.t('warning'));
            }
            await this.setState({ loading: false });
          },
          null,
          this.props.t('are-you-sure-you-want-to-delete-this-table'),
          this.props.t('table')
        );
      } else {
        this.showErrorDialog(
          null,
          this.props.t('please-select-atleast-one-table-to-delete'),
          this.props.t('warning')
        );
      }
    } catch (e) {
      alert(e);
    }
  }

  async generateQr() {
    if (this.state.tables.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.setState({ loading: true });
          await this.props.reGenerateQr(
            this.state.location.loc_id,
            this.state.tables
          );
          await this.setState({ loading: false });
        },
        null,
        this.props.t('are-you-sure-you-want-to-regenerate-qr-of-this-tables'),
        this.props.t('tables')
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t('please-select-atleast-one-table-for-change-qr'),
        this.props.t('warning')
      );
    }
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: '',
      header: '',
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: '',
      header: '',
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }

  editTable(id) {
    this.props.history.replace('/tables/edit/' + id);
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    await this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    await this.setState({ selectLocation: false, loading: false });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  openImage(index) {
    this.setState({ image: index });
  }

  closeImage() {
    this.setState({ image: null });
  }

  render() {
    const { t } = this.props;
    const {
      props: { tables },
    } = this;
    const {
      state: {
        confirmationDialog,
        errorDialog,
        selectLocation,
        location,
        loading,
        image,
      },
    } = this;

    const locationName = location ? location.name : '';

    // TODO: Implement proper error handling
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <>
        {image && (
          <Lightbox
            showImgCount={false}
            isOpen={image !== null}
            onCloseRequest={this.closeImage.bind(this)}
            mainSrc={image}
          />
        )}
        <div className="display-block mb-1">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.changeLocation.bind(this)}
          />
        </div>
        <div id="pricing-table">
          {/* <Button
            className="mb-1 mr-1"
            color="primary"
            size="md"
            onClick={() => this.props.history.push("/tables/new")}
          >
            <span className="ml-1 mr-1 d-md-inline ">{t('new')}</span>
          </Button> */}
          {/* <Button
            className="mb-1 mr-1"
            color="danger"
            size="md"
            onClick={() => this.updateTableStatus()}
          >
            <span className="ml-1 mr-1 d-md-inline ">{t('enable-disable')}</span>
          </Button> */}
          {/* <Button className='mb-1 mr-1' color='primary' size='md' onClick={() => this.generateQr()}>
            <span className='ml-1 mr-1 d-md-inline '>ReGenerate QR</span>
          </Button> */}
          {/* <Button
            className="mb-1 mr-1"
            color="danger"
            size="md"
            onClick={() => this.deleteTables()}
          >
            <span className="ml-1 mr-1 d-md-inline ">{t('delete')}</span>
          </Button> */}

          {!loading && tables ? (
            <ListTables
              tables={tables}
              openImage={this.openImage.bind(this)}
              handleChange={this.handleChange.bind(this)}
              onChangeToggle={this.onChangeToggle.bind(this)}
              editTable={this.editTable.bind(this)}
              unLinkedTabFromTable={this.unLinkedTabFromTable.bind(this)}
              showConfirmationDialog={this.showConfirmationDialog.bind(this)}
            />
          ) : null}
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t('cancel')}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={'OK'}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    tables: {
      alltables: { data: tables, loading: loading },
    },
  } = state;

  return { tables, loading: loading };
}

export default withTranslation('global')(
  connect(mapStateToProps, {
    getAllTables,
    changeTableStatus,
    deleteTable,
    reGenerateQr,
    unLinkedTabFromTableId,
  })(Tables)
);
