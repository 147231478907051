import React, { Component } from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../helpers/TimeHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Badge } from "reactstrap";
import CONSTANTS from "../../constants";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class ListAllNotification extends Component {
  componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }

  columns = [
    {
      name: this.props.t("message"),
      selector: (row) => row.message,
      format: (val) => {
        return val.message ? val.message : this.props.t("no-message-available");
      },
    },
    {
      name: this.props.t("session"),
      selector: (row) => row.session_id,
      width: "15%",
      format: (val) => {
        let url = `${CONSTANTS.BASE_URL}sessions/${val.session_id}`;
        if (val.status === "unseen") {
          url += `?n=${val.not_id}`;
        }
        return val.session_id ? <a href={url}>{val.session_id}</a> : "0";
      },
    },
    {
      name: this.props.t("status"),
      selector: (row) => row.status,
      width: "7%",
      format: (val) => {
        return val.status && val.status === "seen" ? (
          <Badge pill color="success">
            {val.status}
          </Badge>
        ) : (
          <Badge pill color="danger">
            {val.status}
          </Badge>
        );
      },
    },
    {
      name: this.props.t("table"),
      selector: (row) => row.table_name,
      width: "20%",
      format: (val) => {
        return val.table_name
          ? val.table_name
          : this.props.t("not-available-0");
      },
    },
    {
      name: this.props.t("time"),
      selector: (row) => row.not_timer,
      width: "15%",
      format: (val) => {
        return val.not_timer
          ? TimeHelper.toFormattedDateTimeDB(val.not_timer)
          : this.props.t("not-available-0");
      },
    },
  ];

  renderNotificationList = (tables) => {
    if (!tables || tables.length === 0)
      return <h1>{this.props.t("no-app-notification-found")}</h1>;

    return (
      <>
        <DataTable
          noHeader
          paginationRowsPerPageOptions={[25, 50, 100]}
          paginationPerPage={50}
          selectableRowsComponent={Checkbox}
          columns={this.columns}
          data={tables.notificationList}
        />
      </>
    );
  };

  render() {
    const { t } = this.props;
    return this.renderNotificationList(this.props.notificationData);
  }
}

export default withTranslation("global")(ListAllNotification);
