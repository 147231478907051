import React from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../../helpers/TimeHelper";
import ViewHelper from "../../../helpers/ViewHelper";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";
import DataTableExtensions from 'react-data-table-component-extensions';
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ListAppUsers = (props) => {

  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const columns = [
    {
      name: 'ID',
      selector: 'user_id',
      sortable: true,
    },
    {
      name: t('first-name'),
      selector: 'first_name',
      sortable: true,
      format: (val) => {
        return val.first_name ? val.first_name : t('no-name');
      }
    },
    {
      name: t('last-name'),
      selector: 'last_name',
      sortable: true,
      format: (val) => {
        return val.last_name ? val.last_name : t('no-name');
      }
    }, {
      name: t('nickname'),
      selector: 'first_name',
      sortable: true,
      minWidth: '200px',
      format: (val) => {
        return val.first_name && val.last_name ? val.first_name + '' + val.last_name : t('no-nick-name');
      }
    }, {
      name: 'Email(Facebook)',
      selector: 'facebook_email',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return val.facebook_email ? val.facebook_email : t('not-available-0');
      }
    }
    , {
      name: 'Email(Self)',
      selector: 'email',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return val.email ? val.email : t('not-available-0');
      }
    }, {
      name: t('phone-number'),
      selector: 'phone_number',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.phone_number ? val.phone_number : t('not-available-0');
      }
    }, {
      name: 'DOB',
      selector: 'date_of_birth',
      sortable: true,
      format: (val) => {
        return val.date_of_birth ? val.date_of_birth : t('not-available-0');
      }
    }, {
      name: t('city'),
      selector: 'city',
      sortable: true,
      format: (val) => {
        return val.city ? val.city : t('not-available-0');
      }
    }, {
      name: t('country'),
      selector: 'country',
      sortable: true,
      format: (val) => {
        return val.country ? val.country : t('not-available-0');
      }
    }, {
      name: 'Device/OS',
      selector: 'last_used_device',
      minWidth: '300px',
      format: (val) => {
        return val.last_used_device ? val.last_used_device : t('not-available-0');
      }
    }, {
      name: t('reg-date'),
      selector: 'registration_date',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return val.registration_date ? TimeHelper.toFormattedDateTime(val.registration_date) : t('not-available-0');
      }
    }, {
      name: t('last-app-use-date'),
      selector: 'last_app_use_date',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return val.last_app_use_date ? TimeHelper.toFormattedDateTime(val.last_app_use_date) : t('not-available-0');
      }
    }, {
      name: t('banned'),
      selector: 'is_banned',
    }, {
      name: t('latitue'),
      selector: 'latitude',
      format: (val) => {
        return val.latitude ? val.latitude : t('not-available-0');
      }
    }, {
      name: t('longitude'),
      selector: 'longitude',
      format: (val) => {
        return val.longitude ? val.longitude : t('not-available-0');
      }
    }, {
      name: t('gender'),
      selector: 'gender',
      format: (val) => {
        return val.gender ? val.gender : t('not-available-0');
      }
    }, {
      name: t('score'),
      selector: 'score',
      format: (val) => {
        return val.score ? val.score : t('not-available-0');
      }
    }
  ];

  const renderAppUsers = (users, handleChange) => {
    if (!users || users.length === 0)
      return <h1>{t('no-app-user-found')}</h1>;
    const tableData = {
      columns: columns,
      data: users,
      print: false,
      export: false,
    };
    return <DataTableExtensions
      {...tableData}
    >
     <DataTable
        noHeader
        pagination
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={50}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={{
          color: "primary",
          icon: <Check className="vx-icon" size={12} />,
          label: "",
          size: "sm"
        }}
        onSelectedRowsChange={handleChange}
        columns={columns}
        data={users}
      /></DataTableExtensions>;
  }
      return renderAppUsers(props.users,props.handleChange);

}

      export default ListAppUsers;
