import React from "react";
import { Card, CardHeader, CardTitle, Table, Progress } from "reactstrap";
import { withTranslation } from "react-i18next";

class LastestSessionListAdminDashboard extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Card>
        <CardHeader>
          <CardTitle>{t("latest-sessions")}</CardTitle>
        </CardHeader>
        <Table
          responsive
          className="dashboard-table table-hover-animation mb-0 mt-1"
        >
          <thead>
            <tr>
              <th>{t("location")}</th>
              <th>{t("table")}</th>
              <th>{t("session-date")}</th>
              <th>{t("start-end-time")}</th>
              <th className="text-center">{t("total-items-0")}</th>
              <th className="text-center">{t("amount")}</th>
              <th className="text-center">{t("status")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.length > 0 ? (
              this.props.data.map((item) => {
                return (
                  <tr>
                    <td>{item.location_name}</td>
                    {/* <td>{item.table_name}</td> */}
                    <td>{item.table_name > 0 ? item.table_name : "N/A"}</td>
                    <td>{item.session_date}</td>
                    <td>
                      {item.start_time}
                      {item.end_time !== null
                        ? " / " + item.end_time
                        : t("in-progress")}
                    </td>
                    {/* <td className="text-center">{item.total_items}</td> */}
                    <td className="text-center">
                      {" "}
                      {item.total_items > 0 ? item.total_items : "N/A"}
                    </td>
                    <td className="text-center">
                      {item.order_total > 0
                        ? `${item.currency_symbol} ${item.order_total}`
                        : "N/A"}
                    </td>
                    <td className="text-center">
                      {item.session_status.toUpperCase()}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td span="6">{t("no-session-found")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    );
  }
}

export default withTranslation("global")(LastestSessionListAdminDashboard);

// export default LastestSessionListAdminDashboard;
