import CONSTANTS from "../../../constants";
import CategoryService from "../../../services/CategoryService";
import toast from "react-hot-toast";

export function getMenuGroupedByCategory(
  loc_id,
  searchItemData = null,
  minimalData = 0,
  initialize = false
) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({
          type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU,
          data: null,
          loading: true,
        });
      let menu = await CategoryService.getMenusGroupedByCategory(
        loc_id,
        searchItemData,
        minimalData
      );
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU,
        data: menu,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU,
        data: null,
        loading: false,
      });
    }
  };
}

export function saveCategory(loc_id, category, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({
          type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY,
          data: null,
          loading: true,
        });
      let cat = await CategoryService.saveCategory(loc_id, category);
      toast.success("Category Item Added Successfully");
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY,
        data: cat,
        loading: false,
      });
    } catch (error) {
      toast.error("Category Item already exists");
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY,
        data: null,
        loading: false,
      });
    }
  };
}

export function delCategory(loc_id, categoryId, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({
          type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY,
          data: null,
          loading: true,
        });
      let cat = await CategoryService.delCategory(loc_id, categoryId);
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY,
        data: cat,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY,
        data: null,
        loading: false,
      });
    }
  };
}

export function updateCategoryPriority(loc_id, categories, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({
          type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU_UPDATE,
          data: null,
          loading: true,
        });
      let menu = await CategoryService.updateCategoryPriority(
        loc_id,
        categories
      );
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU_UPDATE,
        data: menu,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU_UPDATE,
        data: null,
        loading: false,
      });
    }
  };
}
