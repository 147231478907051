import React from 'react';

export default ({input, label, type, meta: { touched, error, warning }, options}) => {
  return (
    <div className='form-group'>
      <label htmlFor={input.name}>{label}</label>
      <select {...input} className='form-control' id={input.name} >
        { options.map((option) => <option key={option.id} value={option.id}>{option.value}</option>) }
      </select>
      { touched && ((error && <small className='form-text text-danger'>{label} is {error}</small>) ||
        (warning && <small className='form-text text-muted'>{warning}</small>))}
    </div>
  );
}
