import { combineReducers } from 'redux';
import {clearCustomerData, customerData, customerType} from "./customer/POSCustomerReducer";
import {locationData, userLocationData} from "./location/POSLocationReducer";
import {isModifyOrder, orderType, posOrdersHistory, removeItemsList, selectedOrder} from "./order/POSOrderReducer";
import {allTables, selectedTable} from "./tables/POSTableReducer";
import {registerDetails} from "./register/POSRegisterReducer";
import {selectedCategory} from "./category/POSCategoryReducer";

const pos = combineReducers({
    customerData,
    customerType,
    locationData,
    orderType,
    userLocationData,
    allTables,
    selectedTable,
    posOrdersHistory,
    registerDetails,
    selectedCategory,
    selectedOrder,
    isModifyOrder,
    removeItemsList,
    clearCustomerData
})

export default pos;