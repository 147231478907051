import React from 'react';
import { Row, Col } from 'reactstrap';
import '../../assets/scss/pages/dashboard-analytics.scss';
import '../../assets/scss/plugins/charts/apex-charts.scss';
import StatisticsCard from '../../components/@vuexy/statisticsCard/StatisticsCard';
import RecentLocationForAdmin from './RecentLocationForAdmin';
import { withTranslation } from 'react-i18next';

import {
  ShoppingBag,
  Check,
  DollarSign,
  Globe,
  List,
  Key,
  Users,
  Bookmark,
} from 'react-feather';
import TopLocationsBasedOnSessions from './TopLocationsBasedOnSessions';
import TopCityForLocation from './TopCityForLocation';
import TopStatesForLocation from './TopStatesForLocation';
import TopCategoriesForLocation from './TopCategoriesForLocation';
import LastestSessionListAdminDashboard from './LastestSessionListAdminDashboard';
class AdministratorDashboard extends React.Component {
  state = {};

  getChartOptions = (color) => {
    let ordersReceived = {
      chart: {
        id: 'revenue',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      grid: {
        show: false,
      },
      colors: [color],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2.5,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.7,
          opacityTo: 0.5,
          stops: [0, 80, 100],
        },
      },

      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        x: { show: false },
      },
    };
    return ordersReceived;
  };

  render() {
    const { t } = this.props;
    console.log(this.props);
    // const langChoosen = localStorage.getItem("lang");
    // this.props.i18n.changeLanguage(langChoosen);

    const {
      props: { data, location },
    } = this;
    if (data) {
      return (
        <React.Fragment>
          <Row>
            <Col>
              <h4>{t('restaurants-stats')}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="success"
                icon={<ShoppingBag className="success" size={22} />}
                stat={data.overAllHotels}
                statTitle={'Total Hotels'}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="primary"
                icon={<ShoppingBag className="primary" size={22} />}
                stat={data.overAllLocations}
                statTitle={t('total-restaurants')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="success"
                icon={<ShoppingBag className="success" size={22} />}
                stat={data.localTreatedLocations}
                statTitle={t('local-restaurants')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="warning"
                icon={<ShoppingBag className="warning" size={22} />}
                stat={data.locationCountInLast7Days}
                statTitle={t('restaurants-in-7-days')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="danger"
                icon={<ShoppingBag className="danger" size={22} />}
                stat={data.locationCountInLast30Days}
                statTitle={t('restaurants-in-30-days')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="info"
                icon={<ShoppingBag className="info" size={22} />}
                stat={data.localLocationCountInLast7Days}
                statTitle={t('local-restaurants-in-7-days')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="dark"
                icon={<ShoppingBag className="dark" size={22} />}
                stat={data.localLocationCountInLast30Days}
                statTitle={t('local-restaurants-in-30-days')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>{t('overall-stats')}</h4>
            </Col>
          </Row>
          <Row>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="primary"
                icon={<Check className="primary" size={22} />}
                stat={data.total_categories}
                statTitle={t('total-categories')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="success"
                icon={<DollarSign className="success" size={22} />}
                stat={data.total_currencies}
                statTitle={t('total-currencies')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="info"
                icon={<Globe className="info" size={22} />}
                stat={data.total_countries}
                statTitle={t('total-countries')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="warning"
                icon={<List className="warning" size={22} />}
                stat={data.total_items}
                statTitle={t('total-items')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="dark"
                icon={<Key className="dark" size={22} />}
                stat={data.total_orders}
                statTitle={t('total-orders')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="danger"
                icon={<Users className="danger" size={22} />}
                stat={data.total_staff}
                statTitle={t('total-staff')}
              />
            </Col>
            <Col lg="3" sm="6">
              <StatisticsCard
                hideChart
                iconRight
                iconBg="success"
                icon={<Bookmark className="success" size={22} />}
                stat={data.total_tables}
                statTitle={t('total-tables')}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <TopLocationsBasedOnSessions
                topLocations={data.mostSessionInLocation}
                dataText={t('locations-based-on-sessions')}
              />
            </Col>
            <Col md="6" sm="12">
              <TopCityForLocation
                topLocations={data.mostLocationsInCity}
                dataText={t('based-on-locations')}
              />
            </Col>
            <Col md="6" sm="12">
              <TopStatesForLocation
                topLocations={data.mostLocationsInStates}
                dataText={t('based-on-registered-locations')}
              />
            </Col>
            <Col md="6" sm="12">
              <TopCategoriesForLocation
                topLocations={data.mostLocationsInCategory}
                dataText={t('found-in-locations')}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <RecentLocationForAdmin data={data.recentLocationRegistered} />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <LastestSessionListAdminDashboard data={data.recentSessions} />
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return <h1>{t('there-is-no-location-in-the-database')}</h1>;
    }
  }
}

export default AdministratorDashboard;
