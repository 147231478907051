
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SelectHotel from '../shared/SelectHotel';

import CONSTANTS from '../../constants';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import LocationService from '../../services/LocationService';

class Rooms extends Component {
  // componentDidMount() {
  //   const savedLanguage = localStorage.getItem('lang');
  //   if (savedLanguage) {
  //     i18n.changeLanguage(savedLanguage);
  //   }
  // }
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      selectLocation: false,
      loading: false,


    };
  }
  async componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      console.log(location, "llllllllllllllllll")
      if (location) {
        // await this.refreshData(location.loc_id);
        this.props.history.push(`${CONSTANTS.BASE_URL}room/${location.loc_id}`);
        this.setState({ loading: false, location: location.loc_id });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }

  }

  onSelect(location) {
    this.props.history.push(`${CONSTANTS.BASE_URL}room/${location.loc_id}`);
  }

  render() {
    const { location, selectLocation } = this.state
    console.log(location, selectLocation, 'location,selectLocation')
    const { t } = this.props;

    return (
      <>
        <div className="locations">
          <SelectHotel hideBack={true} onSelect={this.onSelect.bind(this)} />
          <div className="add-location-button p-3">
            {/* TODO: Add Validation so only admins can see that */}
            {/* <Link
              className="col-sm-12 col-md-6 col-lg-3 btn btn-primary float-right"
              to={`${CONSTANTS.BASE_URL}hotels/new`}
            >
              Add New Room
            </Link> */}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('global')(Rooms);
