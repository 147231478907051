import React from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../../helpers/TimeHelper";
import ViewHelper from "../../../helpers/ViewHelper";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const RolesList = (props) => {

  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const editStaff = (id) => {
    props.editStaff(id);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => (row.staff_id),
      width: "20%",
    },
    {
      name: t("staff-id"),
      selector: (row) => (row.User.staff_id),
      format: (val) => {
        return val ? val.staff_id : null;
      },
      width: "20%",
    },
    //    {
    //   name: t('name'),
    //   selector: "User.first_name",
    //   width: "12%",
    //   format: (val) => {
    //     console.log("VAL", val);
    //     return props.staffOnly && props.staffOnly.staff_name
    //       ? props.staffOnly.staff_name
    //       : val?.User?.first_name && val?.User?.last_name
    //       ? val?.User?.first_name + ' ' + val?.User?.last_name
    //       : t('not-available');
    //   }
    // },
    // {
    //   name: t('name'),
    //   selector: "User.first_name",
    //   width: "12%",
    //   format: (val) => {
    //     console.log("VAL",val);
    //     return val?.User?.first_name && val?.User?.last_name ? val?.User?.first_name + '' + val?.User?.last_name : t('N/A');
    //   }
    //   // format: (val) => {
    //   //   return val.User
    //   //     ? val.User.first_name + " " + val.User.last_name
    //   //     : t('not-available');
    //   // },
    // },
    // {
    //   name: t('email'),
    //   selector: "User.email",
    //   width: "23%",
    //   format: (val) => {
    //     return val.User ? val.User.email : t('not-available');
    //   },
    // },
    {
      name: t("location-name"),
      selector: (row) => (row.location.name),
      width: "20%",
      format: (val) => {
        return val.Location ? val.Location.name : t("not-available");
      },
    },
    {
      name: t("role-level"),
      selector: (row) => (row.StaffGroup),
      width: "18%",
      format: (val) => {
        return val.StaffGroup && val.StaffGroup.staff_group_name ? (
          <>
            <div className="row">
              <div className="col-lg-12">
                <a
                  className="btn btn-primary btn-sm"
                  style={{ "margin-top": "5px" }}
                >
                  {val.StaffGroup.staff_group_name}
                </a>
              </div>
            </div>
          </>
        ) : (
          t("no-role")
        );
      },
    },
    {
      name: t("date-added"),
      selector: (row) => (row.data_added),
      format: (val) => {
        return val.date_added ? val.date_added : t("not-available-0");
      },
    },
  ];

  const renderStaff = (staff, handleChange, staffOnly) => {
    if (!staff || staff.length === 0)
      return <h1>{t("no-app-location-roles-found")}</h1>;

    return (
      <DataTable
        noHeader
        pagination
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={50}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={{
          color: "primary",
          icon: <Check className="vx-icon" size={12} />,
          label: "",
          size: "sm",
        }}
        onSelectedRowsChange={handleChange}
        columns={columns}
        data={staff}
      />
    );
  };
  return renderStaff(props.staff, props.handleChange, props.staffOnly);
};

export default RolesList;
