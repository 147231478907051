import React, { useEffect, useState } from "react";
import { AvForm, AvInput, AvGroup } from "availity-reactstrap-validation";
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { getAllPricing } from "../../redux/actions/pricing";
import { getAllProductPricing } from "../../redux/actions/productpricing";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import PricingService from "../../services/PricingService";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import CONSTANTS from "../../constants";
import { CardTitle, Collapse } from "reactstrap";
import { ChevronDown, ChevronUp } from "react-feather";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";

import "../../assets/css/collapsibeCardComponent.scss";

import "../../assets/css/collapsibeCardComponent.scss";

import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const PricingSessionForm = (props) => {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [description, setDescription] = useState("");
  const [paymentMode, setPaymentMode] = useState("");

  const [formData, setFormData] = useState([]);
  console.log("FORMDATA", formData);

  const [selectedItems, setSelectedItems] = useState([]);
  const [benefits, setBenefits] = useState([{ title: "" }]);
  const [minQuantity, setMinQuantity] = useState({});
  const [minQuantityError, setMinQuantityError] = useState("");
  const [maxQuantityError, setMaxQuantityError] = useState("");
  const [maxQuantity, setMaxQuantity] = useState({});
  const [packagePrices, setPackagePrices] = useState({});
  const [defaultPackagePrice, setDefaultPackagePrice] = useState("");
  // Validation error states
  const [titleError, setTitleError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [packageError, setPackageError] = useState("");
  const [paymentModeError, setPaymentModeError] = useState("");

  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  console.log(isOpen, "isopen");
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      await props.getAllProductPricing();
    };
    fetchData();
  }, []);

  const toggleCheckbox = (pkgId, isChecked) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      const productIndex = newData.findIndex((item) => item.pkgId === pkgId);

      if (productIndex !== -1) {
        newData[productIndex].required = isChecked ? 1 : 0;
      } else {
        newData.push({ pkgId, required: isChecked ? 1 : 0 });
      }
      setMinQuantity({
        ...minQuantity,
        [pkgId]: isChecked ? "1" : "0",
      });
      return newData;
    });
  };
  const toggleCollapse = (pkgId, product = null) => {
    console.log("PRODUCT", product);
    setIsOpen((prevState) => ({
      ...prevState,
      [pkgId]: !prevState[pkgId],
    }));
    if (product != null) {
      let parray = selectedItems;
      let removeitem = false;
      if (parray.length > 0) {
        parray.map((item, index) => {
          if (item.package_products_pricing_id == pkgId) {
            parray.splice(index, 1);
            removeitem = true;
          }
        });
      }
      // Capture min and max quantity for this package
      setMinQuantity({
        ...minQuantity,
        [pkgId]: product.min_capacity || "",
      });
      setMaxQuantity({
        ...maxQuantity,
        [pkgId]: product.max_capacity || "",
      });
      setDefaultPackagePrice(product.price);
      if (!removeitem) {
        parray.push({
          package_products_pricing_id: product.id,
          product_title: product.title,
          // price: product.price,
          physical_available: product.physical_support,
          multiple_quantity: product.multiple_quantity,
          required: 0,
        });
      }

      setSelectedItems(parray);
    }
  };

  useEffect(() => {
    const data = async () => {
      await props.getAllPricing();
    };
    data();
  }, []);
  const { data: pricing, loading: loadingPricing } = useSelector(
    (store) => store.pricing.allPricingItems
  );
  console.log("Pricing Data:", pricing);

  const { data: productpricing, loading: loadingProductPricing } = useSelector(
    (store) => store.productpricing.allProductPricingItems
  );
  console.log("Product Pricing Data:", productpricing);

  if (loadingPricing || loadingProductPricing) return <SpinnerComponent />;

  const addBenefitField = () => {
    setBenefits([...benefits, { title: "" }]);
  };

  const removeBenefitField = (index) => {
    const updatedBenefits = [...benefits];
    updatedBenefits.splice(index, 1);
    setBenefits(updatedBenefits);
  };

  const handleSubmit = async () => {
    let isValid = true;
    if (!title) {
      setTitleError(t("title-is-required"));
      isValid = false;
    }
    if (!price) {
      setPriceError(t("price-is-required"));
      isValid = false;
    }
    if (!selectedItems || selectedItems.length === 0) {
      setPackageError(t("product-is-required"));
      isValid = false;
    } else {
      setPackageError("");
    }

    if (!description) {
      setDescriptionError(t("description-is-required"));
      isValid = false;
    }
    if (!paymentMode) {
      setPaymentModeError(t("payment-mode-is-required"));
      isValid = false;
    } else {
      setPaymentModeError("");
    }
    if (!isValid) {
      return;
    }
    try {
      let formattedBenefits = benefits.filter(
        (benefit) => benefit.title.trim() !== ""
      );
      if (formattedBenefits.length === 0) {
        formattedBenefits = []; // Ensure an empty array is sent
      } else {
        formattedBenefits = formattedBenefits.map((benefit) => ({
          title: benefit.title,
        }));
      }
      const products = selectedItems.map((item) => {
        const required =
          formData.find(
            (dataItem) => dataItem.pkgId === item.package_products_pricing_id
          )?.required || 0;
        const minQuantityValue = parseInt(
          minQuantity[item.package_products_pricing_id] || "1",
          10
        );
        const maxQuantityValue = parseInt(
          maxQuantity[item.package_products_pricing_id] || "1",
          10
        );

        // Validate min_quantity and max_quantity
        if (minQuantityValue > maxQuantityValue) {
          setMinQuantityError(
            t("min-quantity-should-be-less-than-or-equal-to-max-quantity")
          );
          toast.error(
            "min-quantity-should-be-less-than-or-equal-to-max-quantity"
          );
          isValid = false;
        } else {
          setMinQuantityError(""); // Reset error if valid
        }

        return {
          ...item,
          required,
          min_quantity: minQuantityValue,
          max_quantity: maxQuantityValue,
          payment_mode: paymentMode[item.package_products_pricing_id] || "",
          price: parseInt(
            packagePrices[item.package_products_pricing_id] ||
              defaultPackagePrice
          ),
        };
      });

      if (!isValid) {
        return; // Exit function if validation fails
      }
      const requestData = {
        title: title,
        description: description,
        estimate_price: price,
        total_products: selectedItems.length,
        number_of_benefits: formattedBenefits.length,

        benefits: formattedBenefits,

        products: selectedItems.map((item) => ({
          ...item,
          required:
            formData.find(
              (dataItem) => dataItem.pkgId === item.package_products_pricing_id
            )?.required || 0,
          min_quantity: parseInt(
            minQuantity[item.package_products_pricing_id] || "1",
            10
          ),
          max_quantity: parseInt(
            maxQuantity[item.package_products_pricing_id] || "1",
            10
          ),
          payment_mode: paymentMode[item.package_products_pricing_id] || "",
          price: parseInt(
            packagePrices[item.package_products_pricing_id] ||
              defaultPackagePrice
          ),
        })),
      };

      console.log("REQUEST DATA", requestData);

      const response = await PricingService.createPricingPackage(requestData);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: t("package-product-created-successfully"),
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          history.push(`${CONSTANTS.BASE_URL}pricing`);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: t(
            "an-error-occurred-while-creating-the-package-product-category"
          ),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: `An error occurred: ${error.message}`,
      });
    }
  };

  return (
    <div>
      <div className="location">
        <Card>
          <CardBody>
            <h1>{t("info")}</h1>
            <AvForm>
              <Row>
                <Col md="6" sm="6">
                  <AvGroup>
                    <Label> {t("title")} </Label>
                    <AvInput
                      type="text"
                      name="title"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setTitleError("");
                      }}
                    />
                    {titleError && (
                      <div className="text-danger">{titleError}</div>
                    )}
                  </AvGroup>
                </Col>

                <Col md="6" sm="6">
                  <AvGroup>
                    <Label> {t("estimated-price")} </Label>
                    <AvInput
                      type="number"
                      name="price"
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                        setPriceError("");
                      }}
                    />
                    {priceError && (
                      <div className="text-danger">{priceError}</div>
                    )}
                  </AvGroup>
                </Col>

                <Col md="12" sm="12">
                  <AvGroup>
                    <Label> {t("description")} </Label>
                    <AvInput
                      type="text"
                      name="description"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        setDescriptionError("");
                      }}
                    />
                    {descriptionError && (
                      <div className="text-danger">{descriptionError}</div>
                    )}
                  </AvGroup>
                </Col>
              </Row>

              <h1>{t("products")}</h1>
              {packageError ? (
                <div className="text-danger">{packageError}</div>
              ) : null}

              <>
                {productpricing.productPricing.map((pkg) => (
                  <>
                    <div className="vx-collapse collapse-bordered mb-2">
                      <Card key={{ pkg }} className="border-radius-0-5">
                        <CardHeader
                          onClick={() => toggleCollapse(pkg)}
                          className="border-radius-0-5 bg-white border-bottom border-primary"
                        >
                          <CardTitle className="lead collapse-title collapsed">
                            {pkg.title}
                            <span
                              style={{
                                display: "inline-block",
                                marginLeft: "10px",
                                padding: "5px 10px",
                                borderRadius: "15px",
                                backgroundColor: "green",
                                color: "white",
                                fontSize: "12px",
                              }}
                            >
                              {pkg?.PackageProduct?.tag}
                            </span>
                          </CardTitle>

                          {/* Display the tag value */}
                          <div className="justify-content-end display-flex">
                            <Checkbox
                              color="primary"
                              icon={<span className="vx-icon"></span>}
                              checked={isOpen[pkg.id]}
                              onChange={() => {
                                toggleCollapse(pkg.id, pkg);
                              }}
                            />

                            <span
                              className="text-bold-500 cursor-pointer"
                              onClick={() => toggleCollapse(pkg.id, pkg)}
                            >
                              {isOpen[pkg.id] ? (
                                <ChevronUp size={25} />
                              ) : (
                                <ChevronDown size={25} />
                              )}
                            </span>
                          </div>
                        </CardHeader>
                        <Collapse isOpen={isOpen[pkg.id]}>
                          <CardBody className=" p-3">
                            <div key={pkg.id}>
                              {/* <div className="form-group">
                                <input
                                  type="checkbox"
                                  checked={pkg.physical_support}
                                  disabled
                                />
                                <span className="ml-2">Physical Available</span>
                              </div> */}
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  checked={pkg.multiple_quantity}
                                  disabled
                                />
                                <span className="ml-2">
                                  {t("multiple-quantity")}
                                </span>
                              </div>
                              {/* {pkg.multiple_quantity === 1 ? (
                                <>
                                  <div className="form-group">
                                    <label>{t("min-quantity")}</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={minQuantity[pkg.id] || ""}
                                      // onChange={(e) => {
                                      //   setMinQuantity({
                                      //     ...minQuantity,
                                      //     [pkg.id]: e.target.value,
                                      //   });
                                      // }}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        setMinQuantity({
                                          ...minQuantity,
                                          [pkg.id]:
                                            inputValue == "0" &&
                                            formData.find(
                                              (item) => item.pkgId === pkg.id
                                            )?.required == 1
                                              ? "1"
                                              : inputValue,
                                        });
                                      }}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label>{t("max-quantity")}</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={maxQuantity[pkg.id] || ""}
                                      onChange={(e) => {
                                        setMaxQuantity({
                                          ...maxQuantity,
                                          [pkg.id]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null} */}

                              {pkg.multiple_quantity === 1 ? (
                                <>
                                  <div className="form-group">
                                    <label>{t("max-quantity")}</label>
                                    <input
                                      type="number"
                                      className={`form-control ${
                                        maxQuantityError[pkg.id]
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      value={maxQuantity[pkg.id] || ""}
                                      onChange={(e) => {
                                        setMaxQuantity({
                                          ...maxQuantity,
                                          [pkg.id]: e.target.value,
                                        });

                                        // Validate min and max quantities
                                        if (
                                          parseInt(
                                            minQuantity[pkg.id] || "0",
                                            10
                                          ) > parseInt(e.target.value, 10)
                                        ) {
                                          setMaxQuantityError({
                                            ...maxQuantityError,
                                            [pkg.id]: t(
                                              "max-quantity-should-be-greater-than-or-equal-to-min-quantity"
                                            ),
                                          });
                                        } else {
                                          setMaxQuantityError({
                                            ...maxQuantityError,
                                            [pkg.id]: "",
                                          });
                                        }
                                      }}
                                    />
                                    {maxQuantityError[pkg.id] && (
                                      <div className="invalid-feedback">
                                        {maxQuantityError[pkg.id]}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group">
                                    <label>{t("min-quantity")}</label>
                                    <input
                                      type="number"
                                      className={`form-control ${
                                        minQuantityError[pkg.id]
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      value={minQuantity[pkg.id] || ""}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        setMinQuantity({
                                          ...minQuantity,
                                          [pkg.id]:
                                            inputValue === "0" &&
                                            formData.find(
                                              (item) => item.pkgId === pkg.id
                                            )?.required === 1
                                              ? "1"
                                              : inputValue,
                                        });

                                        // Validate min and max quantities
                                        if (
                                          parseInt(inputValue, 10) >
                                          parseInt(
                                            maxQuantity[pkg.id] || "0",
                                            10
                                          )
                                        ) {
                                          setMinQuantityError({
                                            ...minQuantityError,
                                            [pkg.id]: t(
                                              "min-quantity-should-be-less-than-or-equal-to-max-quantity"
                                            ),
                                          });
                                        } else {
                                          setMinQuantityError({
                                            ...minQuantityError,
                                            [pkg.id]: "",
                                          });
                                        }
                                      }}
                                    />
                                    {minQuantityError[pkg.id] && (
                                      <div className="invalid-feedback">
                                        {minQuantityError[pkg.id]}
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : null}

                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    toggleCheckbox(pkg.id, e.target.checked)
                                  }
                                  checked={
                                    formData.find(
                                      (item) => item.pkgId === pkg.id
                                    )?.required === 1
                                  }
                                />
                                <span className="ml-2">{t("required")}</span>
                              </div>
                              <div className="form-group">
                                <label>{t("price-0")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  // value={packagePrices[pkg.id] || ''}
                                  defaultValue={pkg.price}
                                  onChange={(e) => {
                                    const updatedPackagePrices = {
                                      ...packagePrices,
                                    };
                                    updatedPackagePrices[pkg.id] =
                                      e.target.value;
                                    setPackagePrices(updatedPackagePrices);
                                  }}
                                />
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={pkg.price}
                                  disabled
                                /> */}
                              </div>
                              <div className="form-group">
                                <Label>{t("payment-mode")}</Label>
                                <select
                                  className="form-control"
                                  name={`paymentMode-${pkg.id}`}
                                  value={paymentMode[pkg.id] || ""}
                                  onChange={(e) => {
                                    setPaymentMode({
                                      ...paymentMode,
                                      [pkg.id]: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">
                                    {t("select-payment-mode")}
                                  </option>
                                  <option value="license">
                                    {t("license")}
                                  </option>
                                  <option value="onetime">
                                    {t("one-time-0")}
                                  </option>
                                </select>

                                {paymentModeError && (
                                  <div className="text-danger">
                                    {paymentModeError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </CardBody>
                        </Collapse>
                      </Card>
                    </div>
                  </>
                ))}
              </>

              <Row>
                <Col md="12" sm="12">
                  <h1 className="mt-3">{t("benefits")}</h1>
                  {benefits.map((benefit, index) => (
                    <AvGroup>
                      <Label>
                        {t("benefit")} {index + 1}
                      </Label>
                      <div className="d-flex">
                        <AvInput
                          name="benefits"
                          type="text"
                          value={benefit.title}
                          onChange={(e) => {
                            const updatedBenefits = [...benefits];
                            updatedBenefits[index].title = e.target.value;
                            setBenefits(updatedBenefits);
                          }}
                        />
                        {index !== 0 && (
                          <Button
                            color="danger"
                            className="ml-2"
                            onClick={() => removeBenefitField(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    </AvGroup>
                  ))}
                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      className="ml-2 mb-4"
                      onClick={addBenefitField}
                    >
                      +
                    </Button>
                  </div>
                </Col>
              </Row>

              <div className="d-flex justify-content-end">
                <Button color="primary" size="md" onClick={handleSubmit}>
                  <span className="ml-1 mr-1 d-md-inline">{t("submit")}</span>
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const {
    pricing: {
      allPricingItems: { data: pricing, loading: loadingPricing },
    },
    productpricing: {
      allProductPricingItems: {
        data: productpricing,
        loading: loadingProductPricing,
      },
    },
  } = state;

  return {
    pricing,
    loadingPricing,
    productpricing,
    loadingProductPricing,
  };
}

export default connect(mapStateToProps, {
  getAllPricing,
  getAllProductPricing,
})(PricingSessionForm);
