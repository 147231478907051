import React from "react";
import { useTranslation } from "react-i18next";
import dummyFoodImage from "../../assets/img/dummy-food.png";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Box,
  Grid,
  FormControl,
  Checkbox,
  Input,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
  Snackbar,
  Card,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import { useSelector, useDispatch } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  getCartTotal,
  addItemsToCart,
  clearCart,
} from "../../redux/actions/cart";

import AddIcon from "@mui/icons-material/Add";
import CONSTANTS from "../../constants";
// import mixpanel from "mixpanel-browser";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  // return <Fade ref={ref} {...props} />;
  return <Slide direction="down" ref={ref} {...props} />;
});

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: "white",
  backgroundColor: "green",
  "&:hover": {
    backgroundColor: "green",
    color: "white",
    boxShadow: `0 0 8px ${"green"}`,
  },
}));
const Modal = ({ open, onClose, itemName, restaurantData }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cartMenu.cart);
  const [quantity, setQuantity] = useState(1);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedRadioValues, setSelectedRadioValues] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectValueCounts, setSelectValueCounts] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [specialInstruction, setSpecialInstruction] = useState("");
  const [selectedRadioCartItem, setSelectedRadioCartItem] = useState([]);
  const [selectedCheckboxCartItem, setSelectedCheckboxCartItem] = useState([]);
  const [selectedSelectCartItem, setSelectedSelectCartItem] = useState([]);
  const [cartItemsDataList, setCartItemsDataList] = useState(cart);
  const [requiredFieldError, setRequiredFieldError] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMsg, setOpenSnackBarMsg] = useState("");

  const handleRadioChange = (index, value) => {

    setSelectedRadioValues((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[index] === value) {
        updatedValues[index] = "";
      } else {
        updatedValues[index] = value;
      }

      return updatedValues;
    });
  };

  const resetValues = () => {
    setQuantity(1);
    setSelectedValues([]);
    setSelectedRadioValues([]);
    setSelectedCheckboxes([]);
    setSelectValueCounts([]);
    setShowMore();
    setTotalAmount(0);
    setTotalTax(0);
    setSubTotal(0);
    setSelectedRadioCartItem([]);
    setSelectedCheckboxCartItem([]);

    setSelectedSelectCartItem([]);
    setRequiredFieldError("");
    setOpenSnackBar(false);
    setOpenSnackBarMsg("");
    onClose();
  };
  const handleClose = () => {
    resetValues();
    onClose();
  };

  useEffect(() => {
    setCartItemsDataList(cart);
  }, [open]);

  useEffect(() => {
    let baseprice = 0;
    let subtotal = 0;
    let totalamount = 0;
    let totaltax = 0;
    let menutax = 0;
    if (selectedRadioCartItem.length > 0) {
      selectedRadioCartItem.map((item) => {
        item.Option_Values.map((option) => {
          if (item.base_price == 1) {
            baseprice = 1;
            subtotal +=
              parseFloat(option.price) * option.optionCount * quantity;
            totalamount +=
              parseFloat(option.price) * option.optionCount * quantity;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
              totalamount +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
            }
          } else {
            subtotal += parseFloat(option.price) * option.optionCount;
            totalamount += parseFloat(option.price) * option.optionCount;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) * option.optionCount;
              totalamount +=
                parseFloat(option.order_item_tax) * option.optionCount;
            }
          }
        });
      });
    }

    if (selectedCheckboxCartItem.length > 0) {
      selectedCheckboxCartItem.map((item) => {
        item.Option_Values.map((option) => {
          if (item.base_price == 1) {
            baseprice = 1;
            subtotal +=
              parseFloat(option.price) * option.optionCount * quantity;
            totalamount +=
              parseFloat(option.price) * option.optionCount * quantity;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
              totalamount +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
            }
          } else {
            subtotal += parseFloat(option.price) * option.optionCount;
            totalamount += parseFloat(option.price) * option.optionCount;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) * option.optionCount;
              totalamount +=
                parseFloat(option.order_item_tax) * option.optionCount;
            }
          }
        });
      });
    }

    if (selectedSelectCartItem.length > 0) {
      selectedSelectCartItem.map((item) => {
        item.Option_Values.map((option) => {
          if (item.base_price == 1) {
            baseprice = 1;
            subtotal +=
              parseFloat(option.price) * option.optionCount * quantity;
            totalamount +=
              parseFloat(option.price) * option.optionCount * quantity;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
              totalamount +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
            }
          } else {
            subtotal += parseFloat(option.price) * option.optionCount;
            totalamount += parseFloat(option.price) * option.optionCount;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) * option.optionCount;
              totalamount +=
                parseFloat(option.order_item_tax) * option.optionCount;
            }
          }
        });
      });
    }
    if (baseprice == 0) {
      subtotal += parseFloat(itemName.menu_price) * quantity;
      totalamount += parseFloat(itemName.menu_price) * quantity;
      menutax = (itemName.menu_price / 100) * itemName.menu_tax;
      menutax = parseFloat(menutax).toFixed(2);
      totalamount += parseFloat(menutax);
    }
    totaltax += parseFloat(menutax);
    setTotalAmount(totalamount.toFixed(2));
    setSubTotal(subtotal.toFixed(2));
    setTotalTax(totaltax.toFixed(2));
  }, [
    itemName,
    totalAmount,
    subTotal,
    totalTax,
    selectedSelectCartItem,
    cart,
    quantity,
    selectedCheckboxCartItem,
    selectedRadioCartItem,
  ]);

  const handleSelectValueSelection = (valueId, menuOption, action) => {
    const updatedValues = [...selectedValues];
    const updatedCounts = { ...selectValueCounts };
    // console.log(updatedCounts, updatedValues, "selected");

    if (updatedValues.includes(valueId)) {
      if (action === "increment") {
        updatedCounts[valueId] = (updatedCounts[valueId] || 0) + 1;
      } else if (action === "decrement") {
        updatedCounts[valueId] = Math.max((updatedCounts[valueId] || 0) - 1, 0);
      }
    } else {
      updatedValues.push(valueId);
      if (action === "increment") {
        updatedCounts[valueId] = menuOption.min;
      } else if (action === "decrement") {
        console.error("Attempted to decrement a value that doesn't exist.");
      }
    }

    if (menuOption.max != 0 && updatedCounts[valueId] > menuOption.max) {
      setOpenSnackBar(true);
      setOpenSnackBarMsg("You Have Selected Maximum Option Quantity");
      return;
    }

    let radioTypeItm = {
      option_id: menuOption.option_id,
      display_type: "select",
      base_price: menuOption.base_price,
      option_name: menuOption.Option.option_name,
      Option_Values: [],
    };

    if (updatedValues.length > 0) {
      updatedValues.map((item) => {
        menuOption.MenuOptionValues.map((option) => {
          if (option.option_value_id == item) {
            let price = option.new_price > 0 ? option.new_price : 0;
            let itemtax =
              menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
            if (updatedCounts[item] > 0) {
              radioTypeItm.Option_Values.push({
                optionCount: updatedCounts[item],
                value: option.OptionValue.value,
                option_value_id: option.option_value_id,
                price: price,
                order_item_tax: itemtax.toFixed(2),
                order_item_tax_percentage:
                  menuOption.item_tax > 0 ? menuOption.item_tax : 0,
                menu_option_type: menuOption.option_menu_type,
              });
            }
          }
        });
      });
    }

    let arraySelectedSelectCartItem = [...selectedSelectCartItem];
    let match = false;
    arraySelectedSelectCartItem.map((item, index) => {
      if (item.option_id == radioTypeItm.option_id) {
        match = true;
        arraySelectedSelectCartItem[index] = radioTypeItm;
      }
    });
    if (!match) {
      arraySelectedSelectCartItem.push(radioTypeItm);
    }

    let selectedItemsArray = [];
    arraySelectedSelectCartItem.map((item) => {
      if (item.Option_Values.length > 0) {
        selectedItemsArray.push(item);
      }
    });

    setSelectedSelectCartItem(selectedItemsArray);
    setSelectedValues(updatedValues);
    setSelectValueCounts(updatedCounts);
  };

  const handleRadioValueSelection = (index, valueId, menuOption) => {
    let selectedOption = null;
    menuOption.MenuOptionValues.map((option) => {
      if (option.option_value_id == valueId) {
        selectedOption = option;
      }
    });
    let price = selectedOption.new_price > 0 ? selectedOption.new_price : 0;
    let itemtax =
      menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
    let radioTypeItm = {
      option_id: selectedOption.option_id,
      display_type: "radio",
      base_price: menuOption.base_price,
      option_name: menuOption.Option.option_name,
      Option_Values: [
        {
          optionCount: 1,
          value: selectedOption.OptionValue.value,
          option_value_id: selectedOption.option_value_id,
          price: price,
          order_item_tax: itemtax.toFixed(2),
          order_item_tax_percentage:
            menuOption.item_tax > 0 ? menuOption.item_tax : 0,
          menu_option_type: menuOption.option_menu_type,
        },
      ],
    };
    let arraySelectedRadioCartItem = [...selectedRadioCartItem];
    let match = false;
    if (selectedRadioValues.length > 0) {
      arraySelectedRadioCartItem.map((item, index) => {
        if (item.option_id == radioTypeItm.option_id) {
          match = true;
          arraySelectedRadioCartItem[index] = radioTypeItm;
        }
      });
      if (!match) {
        arraySelectedRadioCartItem.push(radioTypeItm);
      }
    } else {
      arraySelectedRadioCartItem.push(radioTypeItm);
    }
    let arrayRadioItems = [...selectedRadioValues];
    arrayRadioItems[index] = valueId;
    setSelectedRadioValues(arrayRadioItems);
    setSelectedRadioCartItem(arraySelectedRadioCartItem);
  };

  const handleCheckboxValueSelection = (valueId, menuOption) => {
    let preSelectedCheckboxes = [];
    if (selectedCheckboxes.includes(valueId)) {
      preSelectedCheckboxes = selectedCheckboxes.filter((id) => id !== valueId);
    } else {
      preSelectedCheckboxes = [...selectedCheckboxes, valueId];
    }

    let radioTypeItm = {
      option_id: menuOption.option_id,
      display_type: "checbox",
      base_price: menuOption.base_price,
      option_name: menuOption.Option.option_name,
      Option_Values: [],
    };
    if (preSelectedCheckboxes.length > 0) {
      preSelectedCheckboxes.map((item) => {
        menuOption.MenuOptionValues.map((option) => {
          if (option.option_value_id == item) {
            let price = option.new_price > 0 ? option.new_price : 0;
            let itemtax =
              menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
            radioTypeItm.Option_Values.push({
              optionCount: 1,
              value: option.OptionValue.value,
              option_value_id: option.option_value_id,
              price: price,
              order_item_tax: itemtax.toFixed(2),
              order_item_tax_percentage:
                menuOption.item_tax > 0 ? menuOption.item_tax : 0,
              menu_option_type: menuOption.option_menu_type,
            });
          }
        });
      });
    }

    let arraySelectedChecboxCartItem = [...selectedCheckboxCartItem];
    let match = false;
    let maxSelectedValuesReached = false;
    arraySelectedChecboxCartItem.map((item, index) => {
      if (item.option_id == radioTypeItm.option_id) {
        match = true;
        arraySelectedChecboxCartItem[index] = radioTypeItm;
        if (
          menuOption.max != 0 &&
          arraySelectedChecboxCartItem[index].Option_Values.length >
            menuOption.max
        ) {
          maxSelectedValuesReached = true;
        }
      }
    });
    if (!match) {
      arraySelectedChecboxCartItem.push(radioTypeItm);
    }

    let selectedItemsArray = [];
    arraySelectedChecboxCartItem.map((item) => {
      if (item.Option_Values.length > 0) {
        selectedItemsArray.push(item);
      }
    });

    if (maxSelectedValuesReached) {
      setOpenSnackBar(true);
      setOpenSnackBarMsg(t("you-have-selected-maximum-options"));
      return;
    }

    setSelectedCheckboxes((prevCheckboxes) => {
      if (prevCheckboxes.includes(valueId)) {
        return prevCheckboxes.filter((id) => id !== valueId);
      } else {
        return [...prevCheckboxes, valueId];
      }
    });
    setSelectedCheckboxCartItem(selectedItemsArray);
  };

  const addToCart = () => {
    // console.log("add to cart");

    let selectedValuesForCart = [];
    selectedRadioCartItem.map((item) => {
      selectedValuesForCart.push(item.option_id);
    });

    selectedCheckboxCartItem.map((item) => {
      selectedValuesForCart.push(item.option_id);
    });

    selectedSelectCartItem.map((item) => {
      selectedValuesForCart.push(item.option_id);
    });

    for (let i = 0; i < itemName.MenuOptions.length; i++) {
      let requiredFound = false;
      if (selectedValuesForCart.length == 0) {
        if (itemName.MenuOptions[i].required != 1) {
          requiredFound = true;
        }
      } else {
        for (let j = 0; j < selectedValuesForCart.length; j++) {
          if (
            itemName.MenuOptions[i].required == 1 &&
            itemName.MenuOptions[i].option_id == selectedValuesForCart[j]
          ) {
            requiredFound = true;
          } else if (itemName.MenuOptions[i].required != 1) {
            requiredFound = true;
          }
        }
      }

      if (!requiredFound) {
        setOpenSnackBar(true);
        setOpenSnackBarMsg("Please Select All Required Options");
        return;
      }
    }

    for (let i = 0; i < itemName.MenuOptions.length; i++) {
      let minFound = false;
      console.log(itemName.MenuOptions[i], "itemName.MenuOptions");
      let errorMessage = [];

      if (itemName.MenuOptions[i].Option.display_type === "select") {
        if (selectedSelectCartItem.length === 0) {
          if (itemName.MenuOptions[i].min !== 1) {
            minFound = false;
          }
        } else {
          for (let j = 0; j < selectedSelectCartItem.length; j++) {
            const selectedItem = selectedSelectCartItem[j];
            const optionValues = selectedItem.Option_Values?.map(
              (option) => option.value
            );

            console.log(selectedItem, "selected");
            const hasOptionCount = selectedItem.Option_Values?.map(
              (option) => option.optionCount
            );
            const minOptionCount = Math.min(...hasOptionCount);
            if (
              minOptionCount < itemName.MenuOptions[i].min &&
              itemName.MenuOptions[i].option_id === selectedItem.option_id
            ) {
              minFound = true;

              errorMessage.push({
                value: optionValues,
              });
            } else {
              minFound = false;
            }
          }
        }
        errorMessage.forEach((item) => {
          item.value.forEach((value) => {
            console.log(value);
          });
        });
        if (minFound) {
          const errorMessageString = errorMessage
            .map((item) => item.value.join(", "))
            .join("; ");

          setOpenSnackBar(true);
          setOpenSnackBarMsg(
            `Minimum-Quantity-of these Varients- ${errorMessageString} must be ${itemName.MenuOptions[i].min}`
          );
          return;
        }
      }
    }

    let cartItems = JSON.parse(JSON.stringify(cartItemsDataList));
    let menuItemsOptions = [];
    if (selectedRadioCartItem.length > 0) {
      menuItemsOptions = [...menuItemsOptions, ...selectedRadioCartItem];
    }

    if (selectedCheckboxCartItem.length > 0) {
      menuItemsOptions = [...menuItemsOptions, ...selectedCheckboxCartItem];
    }

    if (selectedSelectCartItem.length > 0) {
      menuItemsOptions = [...menuItemsOptions, ...selectedSelectCartItem];
    }

    let menuTax = (itemName.menu_price / 100) * itemName.menu_tax * quantity;
    if (cartItems.length === 0) {
      cartItems.push({
        menu_id: itemName.menu_id,
        menu_name: itemName.menu_name,
        MenuOptions: menuItemsOptions,
        menu_type: itemName.menu_type,
        itemCount: quantity,
        menu_price: itemName.menu_price,
        comment: specialInstruction,
        order_menu_tax: parseFloat(menuTax.toFixed(2)),
        menu_tax_percentage: itemName.menu_tax,
        menu_photo: itemName.menu_photo,
      });

      dispatch(addItemsToCart(cartItems));
    } else {
      updateCartItemList(cartItems, menuItemsOptions, menuTax);
    }
    const payload = {
      subTotal: subTotal,
      totalTax: totalTax,
      totalAmount: totalAmount,
    };

    console.log("PAYLOAD", payload);

    dispatch(getCartTotal(payload));
    resetValues();
  };

  const updateCartItemList = async (cartItems, menuItemsOptions, menuTax) => {
    let found = false;
    let itemQuantity = 0;

    let newMenuItemOptionsArray = JSON.parse(JSON.stringify(menuItemsOptions));

    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].menu_id == itemName.menu_id) {
        if (cartItems[i].comment == specialInstruction) {
          found = true;
        } else {
          found = false;
          break;
        }

        if (
          newMenuItemOptionsArray.length === cartItems[i].MenuOptions.length
        ) {
          found = true;
          for (let j = 0; j < cartItems[i].MenuOptions.length; j++) {
            for (let k = 0; k < newMenuItemOptionsArray.length; k++) {
              if (
                cartItems[i].MenuOptions[j].option_id ==
                newMenuItemOptionsArray[k].option_id
              ) {
                found = true;
                if (
                  cartItems[i].MenuOptions[j].Option_Values.length ===
                  newMenuItemOptionsArray[k].Option_Values.length
                ) {
                  found = true;
                  if (cartItems[i].MenuOptions[j].Option_Values.length > 0) {
                    for (
                      let l = 0;
                      l < cartItems[i].MenuOptions[j].Option_Values.length;
                      l++
                    ) {
                      for (
                        let m = 0;
                        m < newMenuItemOptionsArray[k].Option_Values.length;
                        m++
                      ) {
                        if (
                          cartItems[i].MenuOptions[j].Option_Values[l]
                            .option_value_id ==
                          newMenuItemOptionsArray[k].Option_Values[m]
                            .option_value_id
                        ) {
                          found = true;
                          newMenuItemOptionsArray[k].Option_Values[
                            m
                          ].optionCount += parseInt(
                            cartItems[i].MenuOptions[j].Option_Values[l]
                              .optionCount
                          );
                          newMenuItemOptionsArray[k].Option_Values[
                            m
                          ].order_item_tax =
                            parseFloat(
                              newMenuItemOptionsArray[k].Option_Values[m]
                                .order_item_tax
                            ) +
                            parseFloat(
                              cartItems[i].MenuOptions[j].Option_Values[l]
                                .order_item_tax
                            ) *
                              parseInt(
                                cartItems[i].MenuOptions[j].Option_Values[l]
                                  .optionCount
                              );
                        } else {
                          found = false;
                        }
                      }
                      if (found) {
                        break;
                      }
                    }
                  }
                } else {
                  found = false;
                }
              } else {
                found = false;
              }

              if (found) {
                break;
              }
            }
            if (!found) {
              break;
            }
          }

          if (found) {
            itemQuantity =
              parseInt(quantity) + parseInt(cartItems[i].itemCount);
            cartItems[i].MenuOptions = newMenuItemOptionsArray;
            cartItems[i].itemCount = itemQuantity;
            cartItems[i].order_menu_tax =
              parseFloat(cartItems[i].order_menu_tax) * itemQuantity;
            break;
          }
        } else {
          found = false;
        }
      }
    }

    if (!found) {
      cartItems.push({
        menu_id: itemName.menu_id,
        menu_name: itemName.menu_name,
        MenuOptions: menuItemsOptions,
        menu_type: itemName.menu_type,
        itemCount: quantity,
        menu_price: itemName.menu_price,
        comment: specialInstruction,
        order_menu_tax: menuTax.toFixed(2) * quantity,
        menu_tax_percentage: itemName.menu_tax,
        menu_photo: itemName.menu_photo,
      });
    }
    setCartItemsDataList(cartItems);
    dispatch(addItemsToCart(cartItems));
  };

  const isFullScreen = useMediaQuery("(max-width:600px)");
  const [t, i18n] = useTranslation("global");

  const calculateTotalPrice = (menuPrice, taxPercent) => {
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice =
      tax > 0 ? itemPrice + (itemPrice * tax) / 100 : itemPrice;

    return totalPrice;
  };

  return (
    <>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullScreen={isFullScreen}
          maxWidth="xs"
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
            },
          }}
        >
          <DialogContent className="custom-scrollbar">
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div
                  style={{
                    width: "100%", // Make sure the container takes full width
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={
                      itemName.menu_photo
                        ? CONSTANTS.RESTAURANT_URL + itemName.menu_photo
                        : dummyFoodImage
                    }
                    alt={itemName.menu_name}
                    style={{
                      height: "auto",
                      width: "auto",
                      maxWidth: "150px", // Set a fixed max width for the image
                      display: "block",
                      margin: "auto",
                      borderRadius: "20px",
                    }}
                    onError={(e) => {
                      e.target.src = null;
                      e.target.style.width = "auto";
                    }}
                  />
                </div>
              </Grid>

              <DialogTitle
                display="flex"
                justifyContent="space-between"
                flexDirection={"column"}
              >
                {" "}
                <Typography variant="h6" fontWeight="bold">
                  {itemName.menu_name}
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  {restaurantData?.Currency?.currency_symbol}
                  {calculateTotalPrice(
                    itemName.menu_price,
                    itemName.menu_tax
                  ).toFixed(2)}
                </Typography>
                <Box>
                  <CloseButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      zIndex: 1,
                      top: 8,
                    }}
                  >
                    <CloseIcon />
                  </CloseButton>
                </Box>
              </DialogTitle>
              <Divider />

              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1" textAlign="left">
                    {itemName.menu_description}
                  </Typography>

                  {itemName.MenuOptions.map((menuOption, index) => (
                    <div key={index}>
                      {menuOption.MenuOptionValues.some(
                        (optionValue) => optionValue?.OptionValue?.status === 1
                      ) && (
                        <Box>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              background: "#e9dfd0",
                              borderRadius: 4,
                              padding: "10px",
                            }}
                          >
                            <Typography variant="body1" fontWeight="bold">
                              {menuOption.Option.option_name}
                            </Typography>
                            {menuOption.required ? (
                              <Typography
                                variant="body1"
                                style={{ marginLeft: "auto" }}
                              >
                                <small>required</small>
                              </Typography>
                            ) : null}
                          </div>
                          {menuOption.Option.display_type === "select" ||
                          (menuOption.Option.display_type === "checkbox" &&
                            (menuOption.min !== 0 || menuOption.max !== 0)) ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {menuOption.Option.display_type === "select" ? (
                                <small>
                                  {t("you-need-to-select-maximum")}{" "}
                                  {menuOption.max}{" "}
                                  {t("quantity-from-each-option")}{" "}
                                </small>
                              ) : (
                                <small>
                                  {t("you-need-to-select-maximum-0")}{" "}
                                  {menuOption.max} {t("options")}
                                </small>
                              )}
                            </div>
                          ) : null}
                        </Box>
                      )}

                      <Box>
                        {menuOption.Option.display_type === "select" && (
                          <FormControl variant="outlined" fullWidth>
                            {menuOption.MenuOptionValues.map(
                              (menuOptionValue, idx) => (
                                <>
                                  <>
                                    {menuOptionValue?.OptionValue?.status ==
                                      1 && (
                                      <Card
                                        style={{
                                          padding: "10px",
                                          margin: "10px",
                                        }}
                                      >
                                        <div
                                          key={idx}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AddIcon
                                              onClick={() =>
                                                handleSelectValueSelection(
                                                  menuOptionValue.option_value_id,
                                                  menuOption,
                                                  "increment"
                                                )
                                              }
                                            />
                                            <Box sx={{ ml: "10px" }}>
                                              <Typography
                                                sx={{
                                                  fontWeight: "bold",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                {
                                                  menuOptionValue.OptionValue
                                                    .value
                                                }
                                              </Typography>

                                              <Typography
                                                variant="body1"
                                                style={{
                                                  marginLeft: "0px",
                                                  textAlign: "left",
                                                }}
                                              >
                                                <small>
                                                  {menuOptionValue.new_price > 0
                                                    ? restaurantData?.Currency
                                                        ?.currency_symbol +
                                                      calculateTotalPrice(
                                                        menuOptionValue.new_price,
                                                        menuOption.item_tax
                                                      ).toFixed(2)
                                                    : t("free-0")}
                                                </small>
                                              </Typography>
                                            </Box>
                                          </div>

                                          {selectValueCounts[
                                            menuOptionValue.option_value_id
                                          ] >= menuOption.min ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <Button
                                                variant="outlined text-light"
                                                onClick={() =>
                                                  handleSelectValueSelection(
                                                    menuOptionValue.option_value_id,
                                                    menuOption,
                                                    "decrement"
                                                  )
                                                }
                                                style={{
                                                  fontWeight:
                                                    selectedValues.includes(
                                                      menuOptionValue.option_value_id
                                                    )
                                                      ? "bold"
                                                      : "normal",
                                                  flexShrink: 0,
                                                  minWidth: "2rem",
                                                  backgroundColor: "green",
                                                  height: "1.5rem",
                                                }}
                                              >
                                                -
                                              </Button>
                                              <Typography
                                                variant="body1"
                                                style={{
                                                  margin: "0 8px",
                                                }}
                                              >
                                                {selectValueCounts[
                                                  menuOptionValue
                                                    .option_value_id
                                                ] || menuOption.min}
                                              </Typography>
                                              <Button
                                                variant="outlined text-light"
                                                onClick={() =>
                                                  handleSelectValueSelection(
                                                    menuOptionValue.option_value_id,
                                                    menuOption,
                                                    "increment"
                                                  )
                                                }
                                                style={{
                                                  fontWeight:
                                                    selectedValues.includes(
                                                      menuOptionValue.option_value_id
                                                    )
                                                      ? "bold"
                                                      : "normal",
                                                  flexShrink: 0,
                                                  minWidth: "2rem",
                                                  height: "1.5rem",
                                                  backgroundColor: "green",
                                                }}
                                              >
                                                +
                                              </Button>
                                            </div>
                                          ) : null}
                                        </div>
                                      </Card>
                                    )}
                                  </>
                                  {/* )} */}
                                </>
                              )
                            )}
                          </FormControl>
                        )}
                        {menuOption.Option.display_type === "radio" &&
                          menuOption.MenuOptionValues.some(
                            (optionValue) =>
                              optionValue?.OptionValue?.status === 1
                          ) && (
                            <>
                              {menuOption.MenuOptionValues.map(
                                (menuOptionValue, idx) =>
                                  menuOptionValue?.OptionValue?.status ===
                                    1 && (
                                    <Card
                                      key={idx}
                                      style={{
                                        padding: "10px",
                                        margin: "10px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControl
                                        component="fieldset"
                                        className="w-100"
                                      >
                                        <RadioGroup
                                          aria-label={
                                            menuOption.Option.option_name
                                          }
                                          name={menuOption.Option.option_name}
                                          value={
                                            selectedRadioValues[index] || ""
                                          }
                                          onChange={(e) =>
                                            handleRadioValueSelection(
                                              index,
                                              e.target.value,
                                              menuOption
                                            )
                                          }
                                          className="w-100"
                                        >
                                          <FormControlLabel
                                            value={
                                              menuOptionValue.option_value_id
                                            }
                                            control={
                                              <Radio
                                                checked={
                                                  selectedRadioValues[index] ==
                                                  menuOptionValue.option_value_id
                                                }
                                                onChange={() =>
                                                  handleRadioChange(
                                                    index,
                                                    menuOptionValue.option_value_id
                                                  )
                                                }
                                              />
                                            }
                                            label={
                                              <div>
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {
                                                    menuOptionValue.OptionValue
                                                      .value
                                                  }
                                                </span>
                                              </div>
                                            }
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                      <Typography
                                        variant="body1"
                                        style={{ marginLeft: "30px" }}
                                      >
                                        <small>
                                          {menuOptionValue.new_price > 0
                                            ? restaurantData?.Currency
                                                ?.currency_symbol +
                                              calculateTotalPrice(
                                                menuOptionValue.new_price,
                                                menuOption.item_tax
                                              ).toFixed(2)
                                            : t("free-1")}
                                        </small>
                                      </Typography>
                                    </Card>
                                  )
                              )}
                            </>
                          )}
                        {menuOption.Option.display_type === "checkbox" &&
                          menuOption.MenuOptionValues.some(
                            (optionValue) =>
                              optionValue?.OptionValue?.status === 1
                          ) && (
                            <>
                              {menuOption?.MenuOptionValues.map(
                                (menuOptionValue, idx) =>
                                  menuOptionValue?.OptionValue?.status ===
                                    1 && (
                                    <Card
                                      key={idx}
                                      style={{
                                        padding: "10px",
                                        margin: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          alignItems: "center",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={selectedCheckboxes.includes(
                                                menuOptionValue.option_value_id
                                              )}
                                              onChange={() =>
                                                handleCheckboxValueSelection(
                                                  menuOptionValue.option_value_id,
                                                  menuOption
                                                )
                                              }
                                            />
                                          }
                                          label={
                                            <div>
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {
                                                  menuOptionValue.OptionValue
                                                    .value
                                                }
                                              </span>
                                            </div>
                                          }
                                        />
                                        <Typography
                                          variant="body1"
                                          style={{ marginLeft: "30px" }}
                                        >
                                          <small>
                                            {menuOptionValue.new_price > 0
                                              ? restaurantData?.Currency
                                                  ?.currency_symbol +
                                                calculateTotalPrice(
                                                  menuOptionValue.new_price,
                                                  menuOption.item_tax
                                                ).toFixed(2)
                                              : t("free-2")}
                                          </small>
                                        </Typography>
                                      </div>
                                    </Card>
                                  )
                              )}
                            </>
                          )}
                      </Box>
                    </div>
                  ))}

                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div></div>
                    {requiredFieldError !== "" && requiredFieldError}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Button
                          onClick={addToCart}
                          style={{
                            fontWeight: "bold",
                            borderRadius: "20px",
                            backgroundColor: "green",
                            fontSize: "15px",
                            color: "#FFFFFF",
                            padding: "10px",
                          }}
                        >
                          {t("add-to-cart")}{" "}
                        </Button>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        message={openSnackBarMsg}
        onClose={() => {
          setOpenSnackBar(false);
          setOpenSnackBarMsg("");
        }}
      />
    </>
  );
};

export default Modal;
