import React, { useState } from "react";
import { useEffect } from "react";
import { Mail, PhoneCall } from "react-feather";
import { useTranslation } from "react-i18next";
import { Button, Card, CardImg, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import contact from '../../assets/img/contact-us-09.png'

const Contact = () => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);



  return (
    <div>
      <Card style={{
        width: '100%',
        padding: "20px"
      }}>
        <h1 className="">{t('Contact Us')}</h1>
        <Row>
          <Col md='6'>
            <CardImg src={contact} />
          </Col>
          <Col md='6'>
            <h3 className="mt-3">Have Any Query, No Hesitation Connect Any Time.</h3>
            <Card style={{ borderRadius: "10px", border: "3px solid green" }} className="p-2 w-75 mt-2">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Mail color="green" />
                <strong className="ml-1" style={{ fontSize: "15px" }}>Connect With Email:info@servall.be </strong>
              </div>
            </Card>

            <Card style={{ borderRadius: "10px", border: "3px solid green" }} className="p-2 w-75 mt-2">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <PhoneCall color="green" />
                <strong className="ml-1" style={{ fontSize: "15px" }}>Connect With Call:+32 483 47 67 55. </strong>
              </div>
            </Card>
          </Col>
        </Row>

      </Card>
    </div>
  );
};

export default Contact;
