import React, { useState, useEffect } from "react";
import LocationService from "../../services/LocationService";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { getPackageByLocationId } from "../../services/PackageService";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  CardDeck,
  Col,
  Badge,
} from "reactstrap";

import { useTranslation } from "react-i18next";

const ShowPackages = () => {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [locationData, setLocationData] = useState({});
  const [purchaseId, setPurchaseId] = useState(null);

  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get("transaction");
    if (term) {
      setPurchaseId(term);
    }
    const fetchData = async () => {
      try {
        const locationDataResponse = await LocationService.getLocation();
        console.log(
          "LOCATION Data to show Specific Location Packages",
          locationDataResponse
        );
        setLocationData(locationDataResponse);

        // Fetch package data once location data is available
        if (locationDataResponse.loc_id) {
          const packageData = await getPackageByLocationId(
            locationDataResponse.loc_id
          );
          console.log("To show Specific Location Packages", packageData);
          setPackages(packageData);
        }
      } catch (error) {
        await Swal.fire({
          icon: "error",
          title: t('error'),
          text: t('error-fetching-data'),
          showConfirmButton: true,
        });
      } finally {
        setIsLoading(false); // Set loading to false after successful fetch or in case of an error
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <>
          <h3 className="text-center">{t('packages-of')} {locationData.name}</h3>
          {packages?.transactions?.length === 0 ? (
            <CardHeader
              style={{
                backgroundColor: "green", // Change to your desired background color
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center", // Center align the text
                padding: "20px",
                marginTop: "50px",
              }}
            >
              <h4
                style={{
                  color: "white",
                  margin: "0",
                  fontWeight: "bold",
                }}
              >
                {t('no-packages-purchased')} </h4>
            </CardHeader>
          ) : (
            <CardDeck>
              {packages?.transactions?.map((packageItem) => (
                <>
                  <Col key={packageItem.key} xs={12} sm={12} md={4} className="pb-2">
                    <Card>
                      <CardHeader
                        style={{
                          backgroundColor: "green", // Change to your desired background color
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center", // Center align the text
                          padding: "20px",
                        }}
                      >
                        <h4
                          style={{
                            color: "white",
                            margin: "0",
                            fontWeight: "bold",
                          }}
                        >
                          {packageItem.package_title}
                        </h4>
                        {purchaseId != null && purchaseId == packageItem.id && (
                          <Badge className="badge-warning" pill>
                            {t('new')} </Badge>
                        )}
                      </CardHeader>

                      <CardBody>
                        <CardText>
                          <strong>{t("Price")}: </strong> {packageItem.price}{" "}
                          {packageItem.currency}
                          <br />
                          <strong>Status:</strong>{" "}
                          {packageItem.subscription_status}
                        </CardText>
                        <h5>{t('items-in-package')}: </h5>
                        <ul>
                          {packageItem.ItemsInTransactions.map((item) => (
                            <li key={item.id}>
                              {item.title}: {item.quantity} x {item.price}{" "}
                            </li>
                          ))}
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              ))}
            </CardDeck>
          )}
        </>
      )}
    </div>
  );
};

export default ShowPackages;
