import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import HotelForm from './HotelForm';
import { initializeNewLocation } from '../../redux/actions/locations';
import CONSTANTS from '../../constants';

class newHotel extends Component {
  componentDidMount() {
    this.props.initializeNewLocation();
  }

  redirectToLocation() {
    if (this.props.location && this.props.location.loc_id)
      this.props.history.push(
        `${CONSTANTS.BASE_URL}hotels/${this.props.location.loc_id}`
      );
  }

  render() {
    const { location, loading } = this.props;
    return (
      <>
        {/* <Breadcrumbs
          breadCrumbTitle="Restaurants"
          breadCrumbParent="Restaurants"
          breadCrumbActive="Add New Restaurant"
        /> */}
        {location ? (
          <div className="hotel">
            <Card>
              <CardBody>
                <HotelForm onSuccess={this.redirectToLocation.bind(this)} />
              </CardBody>
            </Card>
          </div>
        ) : loading ? (
          <SpinnerComponent />
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { location } = state.locations;
  return { location: location.data, loading: location.loading };
}

export default connect(mapStateToProps, { initializeNewLocation })(newHotel);
