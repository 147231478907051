import React, {useEffect, useState} from "react"
import {Button, Grid, MenuItem, OutlinedInput, Select, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {addNewReservation} from "../../services/ReservationServices";
import toast from "react-hot-toast";
import TimeSlotsService from "../../services/TimeSlotsService";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const fields = [
    {
        label: 'Name',
        name: 'name',
        db_label: 'name',
        type: 'text',
        placeholder: 'Please, Enter your name',
        required: true,
        multiline: false,
        fullWidth: false,
    },

    {
        label: 'Email',
        name: 'email',
        type: 'text',
        db_label: 'email',
        placeholder: 'Please, Enter your email',
        required: true,
        multiline: false,
        fullWidth: false,
    },

    {
        label: 'Phone',
        name: 'number',
        type: 'text',
        db_label: 'number',
        placeholder: 'Please, Enter your phone number',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Number of Guests',
        name: 'user_count',
        type: 'text',
        db_label: 'user_count',
        placeholder: 'Please, Enter number of guests',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Date',
        name: 'date',
        type: 'date',
        db_label: 'date',
        placeholder: 'Select, a Date',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Time',
        name: 'time',
        type: 'select',
        db_label: 'location_slot_id',
        placeholder: 'Select, a Time',
        required: true,
        multiline: false,
        fullWidth: false
    },

    {
        label: 'Message',
        name: 'description',
        type: 'text',
        db_label: 'description',
        placeholder: 'Please, Enter your message',
        required: false,
        multiline: true,
        fullWidth: true
    }
]

const NewReservation = () => {
    const location = useLocation();
    const data = location.state?.location
    const [t, i18n] = useTranslation('global');
    const [reservationData, setReservationData] = useState({
        name: '',
        email: '',
        number: '',
        user_count: '',
        date: '',
        location_slot_id: '',
        description: '',
    });
    const [originalTimeSlots, setOriginalTimeSlots] = useState([]);
    const [selectedDateSlots, setSelectedDateSlots] = useState([]);

    const fetchSlotsData = async () => {
        const timeSlots = await TimeSlotsService.getAllTimeSlots();

        const today = moment().startOf('day');
        const datesArray = [...Array(7).keys()].map(day => {
            const currentDay = moment(today).add(day, 'days'); // Clone today and add days
            const date = currentDay.format('ddd DD MMM');
            const dayName = currentDay.format('dddd');
            const dbDate = currentDay.format('YYYY-MM-DD');
            return { date, dayName, dbDate };
        });

        let originalSlots = [];
        for (let i = 0; i < datesArray.length; i++) {
            for (let j = 0; j < timeSlots.data.length; j++) {
                if ((timeSlots.data[j].day_name === datesArray[i].dayName.toLowerCase())) {
                    const slotDate = moment(datesArray[i].dbDate)
                    const checkDate = slotDate.isSame(moment(), 'day')
                    if (checkDate) {
                        let filteredSlots = [];
                        for (let k = 0; k < timeSlots.data[j].location_slots; k++) {
                            if (moment().isBefore(moment(`${datesArray[i].dbDate} ${timeSlots.data[j].location_slots[k].end_time}`))) {
                                filteredSlots.push(timeSlots.data[j].location_slots[k]);
                            }
                        }
                        originalSlots.push({
                            ...datesArray[i], ...timeSlots.data[j],
                            location_slots: filteredSlots,
                            original_location_slots: timeSlots.data[j].location_slots
                        })
                    } else {
                        originalSlots.push({...datesArray[i], ...timeSlots.data[j]})
                    }
                }
            }
        }

        setOriginalTimeSlots(originalSlots);
    }

    useEffect(() => {
        fetchSlotsData();
    }, []);

    const handleValueChange = (e, field, index) => {
        setReservationData({...reservationData, [field.name]: e.target.value})
    }

    const handleSaveReservation = async () => {
        if (!reservationData.date || reservationData.date === '') {
            toast.error('Please select a date');
            return;
        }
        if (!reservationData.location_slot_id || reservationData.location_slot_id === '') {
            toast.error('Please select a time');
            return;
        }
        if (!reservationData.user_count || reservationData.user_count === '') {
            toast.error('Please enter number of guests');
            return;
        }
        if (!reservationData.name || reservationData.name === '') {
            toast.error('Please enter your name');
            return;
        }
        if (!reservationData.email || reservationData.email === '') {
            toast.error('Please enter your email');
            return;
        }
        if (!reservationData.number || reservationData.number === '') {
            toast.error('Please enter your phone number');
            return;
        }

        const response = await addNewReservation(data.loc_id, reservationData)
        if (response.error) {
            toast.error(response.message);
        } else {
            toast.success('Reservation Added Successfully');
            setReservationData({
                name: '',
                email: '',
                number: '',
                user_count: '',
                date: '',
                description: '',
                location_slot_id: ''
            })
        }
    }

    const handleDateChange = (d) => {
        const date = moment(d[0]).format("YYYY-MM-DD");

        (moment(date).isSame(moment(), 'day')) ?
            (
                originalTimeSlots.map((slot, index)=> (
                    moment(date).isSame(moment(`${slot.dbDate}`), 'day') && (
                        (slot.location_slots.length <= 0) ?
                            setSelectedDateSlots([]) :
                            setSelectedDateSlots(slot.location_slots)
                    ))))
            :
            (originalTimeSlots.map((slot, index)=> (
                ((moment(date).format("dddd")).toLowerCase() === (slot.dayName).toLowerCase()) && (
                    ((moment().format("dddd")) === (slot.dayName)) ?
                        setSelectedDateSlots(slot.original_location_slots) :
                        setSelectedDateSlots(slot.location_slots)
                ))))

        setReservationData({...reservationData, date: date, location_slot_id: ''});
    }

    const handleTimeChange = (e, field) => {
        setReservationData({...reservationData, [field.db_label]: e.target.value})
    }

    return(
        <>
            <Typography variant='h5' sx={{fontWeight: '600', marginBottom: '1rem'}}>New Reservation</Typography>
            <Stack sx={{alignItems: 'start', justifyContent: 'start', rowGap: '1rem'}}>
                <Grid container spacing={2}>
                    {fields.map((field, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <Typography variant='h6' sx={{fontSize: '0.9rem'}}>{field.label}</Typography>
                            {field.type === 'text' ? <OutlinedInput
                                value={reservationData[field.db_label]}
                                onChange={(e)=> handleValueChange(e, field, index)}
                                size="small"
                                fullWidth
                                placeholder={field.placeholder}
                                multiline={field.multiline}
                                rows={4}
                            /> : field.type === 'date' ?
                                <Flatpickr
                                    className="form-control align-text-center bg-white"
                                    options={{
                                        mode: 'single',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                    onChange={(d) => handleDateChange(d)}
                                />
                                :
                                <Select
                                    size={'small'}
                                    variant="outlined"
                                    fullWidth
                                    value={reservationData[field.db_label]}
                                    onChange={(e)=> handleTimeChange(e, field, index)}
                                >
                                    {selectedDateSlots.length > 0 ?
                                        selectedDateSlots.map((slot, index)=> (
                                            <MenuItem value={slot.location_slot_id} key={index}>{moment("1/1/1/" + slot.start_time).format("hh:mm A")} - {moment("1/1/1/" + slot.end_time).format("hh:mm A")}</MenuItem>
                                        )) :
                                        <MenuItem value={''}>No slots available</MenuItem>
                                    }
                                </Select>
                            }
                        </Grid>
                    ))}
                </Grid>
                <Button sx={{ textTransform: 'capitalize', fontSize: '0.9rem', backgroundColor: '#28c76f', '&:hover': { backgroundColor: '#28c76f' } }} variant='contained' onClick={handleSaveReservation}>Add Reservation</Button>
            </Stack>
        </>
    )
}

export default NewReservation