import CONSTANTS from "../../../constants";
import FloorService from "../../../services/FloorService";

export function getAllFloors(locationId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.FLOORS.ALL_FLOORS,
        floors: [],
        loading: true,
      });
      let floors = await FloorService.getAllFloors(locationId);
      dispatch({
        type: CONSTANTS.ACTIONS.FLOORS.ALL_FLOORS,
        floors: floors,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.FLOORS.ALL_FLOORS,
        floors: [],
        loading: false,
      });
    }
  };
}

export function saveFloor(locationId, floor) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.FLOORS.ALL_FLOORS,
        floors: [],
        loading: true,
      });
      let floors = await FloorService.saveFloor(locationId, floor);
      dispatch({
        type: CONSTANTS.ACTIONS.FLOORS.ALL_FLOORS,
        floors: floors,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: CONSTANTS.ACTIONS.FLOORS.ALL_FLOORS,
        floors: [],
        loading: false,
      });
    }
  };
}

export function delFloor(locationId, floor_id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.FLOORS.ALL_FLOORS,
        floors: [],
        loading: true,
      });
      let floors = await FloorService.deleteFloor(locationId, floor_id);
      dispatch({
        type: CONSTANTS.ACTIONS.FLOORS.ALL_FLOORS,
        floors: floors,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: CONSTANTS.ACTIONS.FLOORS.ALL_FLOORS,
        floors: [],
        loading: false,
      });
    }
  };
}
