import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import {
  getAllLocations,
  getAllLocationsByStaffId,
} from "../../redux/actions/locations";
import "../../assets/scss/pages/page-pricing.scss";
import { isArray } from "lodash";
import { Row, Col } from "reactstrap";
import ListAllLocation from "./ListAllLocation";

class AllLocations extends Component {
  async componentDidMount() {
    if (this.props?.match?.params?.staffid) {
      var userid = this.props?.match?.params?.staffid;
      await this.props.getAllLocationsByStaffId(userid);
    } else {
      try {
        await this.props.getAllLocations();
      } catch (err) {
        console.log(err);
      }
    }
  }

  // editLocation(id) {

  //   this.props.history.replace("/locations/" + id);
  // }
  editLocation = (id) => {
    const isHotel = this.props.locations.locations[0]?.is_hotel === 1;
    if (isHotel) {
      this.props.history.replace("/hotels/" + id);
    } else {
      this.props.history.replace("/locations/" + id);
    }
  };

  render() {
    const {
      props: { locations, loading },
    } = this;
    console.log("PROPS LOCATION TO BE SEND", locations);
    console.log("LOCATIONS", locations);

    // console.log("Is Hotel:", locations.is_hotel);
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <Col sm="12" className="mb-3">
          {/* <Row>
            <Col sm="12">
              <strong>{t("assigned-restaurant")}</strong>
            </Col>
          </Row> */}

          {/* {locations.locations[0]?.is_hotel === 1 && loading ? (
            <div>
              <strong>{t("assigned-hotel")}</strong>
            </div>
          ) : (
            <Col sm="12">
              <strong>{t("assigned-restaurant")}</strong>
            </Col>
          )} */}
        </Col>
        <div id="pricing-table">
          {!loading && isArray(locations.locations) && locations.locations ? (
            <ListAllLocation
              locations={locations}
              editLocation={this.editLocation.bind(this)}
            />
          ) : null}
        </div>
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  console.log("STATES", state);
  const {
    locations: {
      location: { data: locations, loading: loading },
    },
  } = state;

  return { locations, loading: loading };
}

export default (
  connect(mapStateToProps, {
    getAllLocations,
    getAllLocationsByStaffId,
  })(AllLocations)
);
