import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getOverallStats,
  getItemStats,
  getDashboardStats,
  getLocationStats,
  getTableStatsList,
};

async function getOverallStats(loc_id, filters) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ANALYTICS.DEFAULT(
      loc_id,
      "overall"
    )}`,
    {
      method: "POST",
      body: JSON.stringify(filters),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;

  throw new Error("Could not fetch stats");
}

async function getItemStats(loc_id, filters) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ANALYTICS.DEFAULT(loc_id, "items")}`,
    {
      method: "POST",
      body: JSON.stringify(filters),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;

  throw new Error("Could not fetch stats");
}

async function getLocationStats(loc_id, filters) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ANALYTICS.OVERALL_CO_ITEMS_REPORTS(
      loc_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(filters),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  console.log("Response: ", response);
  if (response) return response;

  throw new Error("Could not fetch stats");
}

async function getDashboardStats(loc_id = null, role) {
  let { token } = LoginService.getToken();
  let path = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ANALYTICS.MANAGER_DASHBOARD(
    loc_id
  )}`;
  if (role.role === "Administrator") {
    path = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ANALYTICS.ADMIN_DASHBOARD}`;
  }
  let response = await fetch(path, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();
  if (response) return response;

  throw new Error("Could not fetch stats");
}

async function getTableStatsList(loc_id, page, filters) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ANALYTICS.TABLE_SPENDING_STATS_LIST(
      loc_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(filters),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch stats");
}
