import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
} from "reactstrap";
import { createOrder } from "../../services/BuyingService";
import LocationSelectHeaderPackage from "../shared/LocationSelectHeaderPackage";

import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import "./checkout.css";
const CheckoutPage = () => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const history = useHistory();
  const [apiData, setApiData] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  const { checkoutData } = location.state || {};
  console.log("CHECKOUTDATA", checkoutData);
  const { locationData } = location.state || {};
  console.log("LOCATIONDATA", locationData);

  useEffect(() => {
    setApiData(checkoutData);
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(delay);
  }, []);

  const handleAPICall = async () => {
    setLoading(true);

    try {
      const response = await createOrder(apiData);
      console.log(" API Response:", response);

      // await Swal.fire({
      //   icon: 'success',
      //   title: 'Success',
      //   text: response.message,
      //   timer: 2500,
      //   showConfirmButton: false,
      // });
      window.location.href = response.checkoutUrl;
      // setApiData('');

      // setTimeout(() => {
      //   history.push('/');
      // }, 1500);
    } catch (error) {
      // Check if the error contains a message from the response
      let errorMessage = "";
      if (error && error.message) {
        errorMessage = error.message;
      }

      await Swal.fire({
        icon: "error",
        title: t("error"),
        text: errorMessage,
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <LocationSelectHeaderPackage
          text={locationData ? locationData.name : t("no-location-found")}
        />

        <Row>
          <Col style={{ margin: "auto" }} xs={12} md={7}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  {t("order-summary")}
                </p>
              </CardHeader>
              <CardBody>
                <div>
                  {checkoutData ? (
                    checkoutData.length === 0 ? (
                      <p>{t("no-checkout")}</p>
                    ) : (
                      <>
                        {checkoutData.products.map((item) => (
                          <div
                            key={item.package_products_pricing_id}
                            style={{ marginTop: "20px" }}
                          >
                            <h2
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center",
                                color: "green",
                              }}
                            >
                              {item.title}
                            </h2>

                            <table>
                              <tr>
                                <td>{t("price")}: </td>
                                <td>€ {item.price}</td>
                              </tr>
                              <tr>
                                <td>{t("physical-support")}: </td>
                                <td>
                                  {item.physical_support ? t("yes") : t("no")}
                                </td>
                              </tr>
                              <tr>
                                <td>{t("price-per-type")}: </td>
                                <td>{item.price_per_type}</td>
                              </tr>
                              <tr>
                                <td>{t("category-type")}: </td>
                                <td>{item.category_type}</td>
                              </tr>
                              <tr>
                                <td>{t("quantity")}: </td>
                                <td>{item.quantity}</td>
                              </tr>
                              <tr>
                                <td>{t("total")}: </td>
                                <td>€ {item.quantity * item.price}</td>
                              </tr>
                            </table>
                          </div>
                        ))}
                        <div
                          style={{
                            marginTop: "16px",
                            paddingTop: "16px",
                            borderTop: "1px solid #888",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div style={{ flex: 1, textAlign: "right" }}>
                              <h5
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "8px",
                                }}
                              >
                                {t("sub-total")}:{" "}
                              </h5>
                            </div>
                            <div>
                              <h5 style={{ fontWeight: "bold" }}>
                                €{checkoutData.price}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <Form
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            color="primary"
                            size="md"
                            onClick={handleAPICall}
                            disabled={loading}
                          >
                            {loading ? t("placing-order") : t("place-order")}
                          </Button>
                        </Form>
                      </>
                    )
                  ) : (
                    <p>{t("no-items-for-checkout")}</p>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CheckoutPage;
