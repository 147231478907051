import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getAllStaff,
  getRoles,
  createStaff,
  getStaff,
  changeStaffStatus,
  assignRole,
  delAssignRole,
  getAllStaffRoles,
  getAllOwnerStaff,
  getStaffByEmail,
};

async function getAllStaff() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.DEFAULT}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch staff');
}

async function getRoles() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.ROLES}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch roles');
}

async function getStaff(staffId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.GET_STAFF(staffId)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch staff');
}

async function getAllStaffRoles() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.GET_ALL_STAFF}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch staff');
}

async function getAllOwnerStaff() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.CUSTOMERLIST}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch staff');
}

async function createStaff(staff) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.DEFAULT}`,
    {
      method: 'POST',
      body: JSON.stringify(staff),
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch staff');
}

async function assignRole(staff) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.ASSIGN}`,
    {
      method: 'POST',
      body: JSON.stringify(staff),
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch assign staff');
}

async function delAssignRole(staff) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.DEL}`,
    {
      method: 'POST',
      body: JSON.stringify(staff),
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch assign staff');
}

async function changeStaffStatus(staff) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.STATUSES}`,
    {
      method: 'POST',
      body: JSON.stringify(staff),
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not update staff');
}

async function getStaffByEmail(term) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.SEARCH}?name=${term}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch roles');
}
