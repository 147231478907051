import CONSTANTS from '../../../constants';
import SurveyQuestions from '../../../services/SurveyQuestions';

export function getAllSurveyQuestions(loc_id, page) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.SURVEYQUESTIONS.GET_ALL,
        questions: [],
        loading: true,
      });
      let questions = await SurveyQuestions.getAllSurveyQuestions(loc_id, page);
      dispatch({
        type: CONSTANTS.ACTIONS.SURVEYQUESTIONS.GET_ALL,
        questions: questions,
        loading: false,
      });
    } catch (error) {
      console.log('Dispatch Error Survey Question.');
      dispatch({
        type: CONSTANTS.ACTIONS.SURVEYQUESTIONS.GET_ALL,
        questions: [],
        loading: false,
      });
    }
  };
}

export function changeQuestionStatus(item, status) {
  return async (dispatch) => {
    try {
      let questions = await SurveyQuestions.changeQuestionStatus(item, status);
      dispatch({
        type: CONSTANTS.ACTIONS.SURVEYQUESTIONS.GET_ALL,
        questions: questions,
        loading: false,
      });
    } catch (error) {
      console.log('Dispatch Error Update Survey Question Status.');
      dispatch({
        type: CONSTANTS.ACTIONS.SURVEYQUESTIONS.GET_ALL,
        questions: [],
        loading: false,
      });
    }
  };
}

export function saveUpdateQuestion(item, location_id) {
  return async (dispatch) => {
    try {
      let questions = [];
      if (item.question_id) {
        questions = await SurveyQuestions.updateQuestion(item, location_id);
      } else {
        questions = await SurveyQuestions.saveQuestion(item, location_id);
      }
      dispatch({
        type: CONSTANTS.ACTIONS.SURVEYQUESTIONS.GET_ALL,
        questions: questions,
        loading: false,
      });
    } catch (error) {
      console.log('Dispatch Error Update Survey Question Status.');
      dispatch({
        type: CONSTANTS.ACTIONS.SURVEYQUESTIONS.GET_ALL,
        questions: [],
        loading: false,
      });
    }
  };
}

export function deleteQuestions(loc_id, question_id) {
  return async (dispatch) => {
    try {
      const result = await SurveyQuestions.deleteQuestion(loc_id, question_id);
      console.log('Question Result', result);

      //   dispatch({
      //     type: CONSTANTS.ACTIONS.SURVEYQUESTIONS.GET_ALL,
      //     message: result,
      //   });
    } catch (error) {
      console.log('Error', error);
      console.log('Dispatch Error Delete Survey Question.');

      dispatch({
        type: CONSTANTS.ACTIONS.SURVEYQUESTIONS.GET_ALL,
        questions: [],
        loading: false,
      });
    }
  };
}
