import React from 'react';
import { Card, CardHeader, CardTitle, Table, Progress } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class RecentLocationForAdmin extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <Card>
        <CardHeader>
          <CardTitle>{t('latest-restaurants')}</CardTitle>
        </CardHeader>
        <Table
          responsive
          className="dashboard-table table-hover-animation mb-0 mt-1"
        >
          <thead>
            <tr>
              <th>{t('location')}</th>
              <th>{t('category')}</th>
              <th>{t('country')}</th>
              <th>{t('tax-label')}</th>
              <th>{t('status')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.length > 0 ? (
              this.props.data.map((item) => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.category_name}</td>
                    <td>{item.country}</td>
                    <td>{item.tax_label}</td>
                    <td>
                      {item.is_locally_treat
                        ? t('local-management')
                        : t('cloud-management')}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td span="6">{t('no-location-found')}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    );
  }
}

export default withTranslation('global')(RecentLocationForAdmin);

// export default RecentLocationForAdmin;
