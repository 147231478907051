// import React from 'react';
// import { useState } from 'react';
// import {
//   Container,
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   Select,
//   TabContent,
//   TabPane,
//   Nav,
//   NavItem,
//   Button,
//   Card,
//   NavLink,
// } from 'reactstrap';
// import CONSTANTS from '../../../constants';

// const MenuItemPage = (props) => {
//   console.log('MENU ITEM PROPS', props.history.location.state);
//   const [status, setStatus] = useState(false); // Status checkbox state
//   const [chefRecommended, setChefRecommended] = useState(false); // Chef Recommended checkbox state

//   const {
//     item,
//     nutriScoreOptions,
//     location,
//     allDishTags,
//     allAllergicItems,
//     categories,
//     randomcustomisedTags,
//   } = props.history.location.state;
//   const menuType = [
//     {
//       value: 'kitchen',
//       label: CONSTANTS.VARIABLES.MENUS.TYPE.KITCHEN,
//     },
//     {
//       value: 'bar',
//       label: CONSTANTS.VARIABLES.MENUS.TYPE.BAR,
//     },
//     {
//       value: 'dessert',
//       label: CONSTANTS.VARIABLES.MENUS.TYPE.DESSERT,
//     },
//   ];
//   const handleStatusChange = () => {
//     setStatus(!status); // Toggle the status checkbox
//   };

//   const handleChefRecommendedChange = () => {
//     setChefRecommended(!chefRecommended); // Toggle the Chef Recommended checkbox
//   };

//   return (
//     <Container>
//       {/* <Card className="p-2"> */}
//       <Row>
//         <Col md="6" className="mx-auto">
//           <h1>{location.name}</h1>
//           <Form>
//             <Row>
//               <Col md="6">
//                 <FormGroup>
//                   <Label for="item">Item Name:</Label>
//                   <Input type="text" name="item" id="item">
//                     {item ? item.name : 'N/A'}
//                   </Input>
//                 </FormGroup>
//               </Col>

//               <Col md="6">
//                 <FormGroup>
//                   <Label for="customItems">Category:</Label>
//                   <Input type="select" name="customItems" id="customItems">
//                     {categories.map((category) => (
//                       <option key={category.id} value={category.id}>
//                         {category.title}
//                       </option>
//                     ))}
//                   </Input>
//                 </FormGroup>
//               </Col>
//               <Col md="12">
//                 <FormGroup>
//                   <Label for="menuType">Menu Type:</Label>
//                   <Input type="select" name="menuType" id="menuType">
//                     <option value="">Select a Menu Type</option>
//                     {menuType.map((type) => (
//                       <option key={type.value} value={type.value}>
//                         {type.label}
//                       </option>
//                     ))}
//                   </Input>
//                 </FormGroup>
//               </Col>
//             </Row>

//             <Row>
//               <Col md="6">
//                 <FormGroup>
//                   <Label for="nutriScore">Nutri Score:</Label>
//                   <Input type="select" name="nutriScore" id="nutriScore">
//                     {nutriScoreOptions.map((option) => (
//                       <option key={option.value} value={option.value}>
//                         {option.label}
//                       </option>
//                     ))}
//                   </Input>
//                 </FormGroup>
//               </Col>
//               <Col md={6}>
//                 <FormGroup>
//                   <Label for="photoDiv">{'menu-main-image'}: </Label>
//                   <div
//                     tabIndex="0"
//                     id="photoDiv"
//                     className="display-flex flex-column border border-2 border-light cursor-pointer"
//                     // onKeyUp={(e) =>
//                     //   e.keyCode === 13 && !e.ctrlKey
//                     //     ? fileRef.current.click()
//                     //     : null
//                     // }
//                     // onClick={() => fileRef.current.click()}
//                     style={{ position: 'relative', height: 120 }}
//                   >
//                     <div style={{ textAlign: '-webkit-center' }}>
//                       <div>
//                         <div
//                           style={{
//                             position: 'absolute',
//                             top: -10,
//                             right: -10,
//                           }}
//                         >
//                           <Button
//                             // onClick={() => {
//                             //   updateItem('img', null);
//                             //   fileRef.current.value = null;
//                             // }}
//                             style={{ width: 20, height: 20 }}
//                             className="m-0 p-0"
//                             color="danger"
//                           >
//                             {/* <X size={14} /> */}
//                           </Button>
//                         </div>
//                         <div
//                           style={{ position: 'absolute', bottom: 3 }}
//                           className="full-width"
//                         >
//                           <Button
//                             className="p-25"
//                             color="primary"
//                             // onClick={() => fileRef.current.click()}
//                           >
//                             <span className="font-small-1">
//                               {'change-photo'}
//                             </span>
//                           </Button>
//                         </div>
//                         {/* <Media
//                           object
//                           src={item.img}
//                           alt="image"
//                           style={{ maxWidth: 220, maxHeight: 120 }}
//                           className="align-self-center"
//                         /> */}
//                       </div>

//                       <div
//                         className="align-self-center"
//                         style={{ padding: 9, height: 50, width: 50 }}
//                       >
//                         {/* <Image size={32} className="align-self-center" /> */}
//                       </div>
//                     </div>
//                     {/* {item.img ? null : ( */}
//                     <React.Fragment>
//                       <span className="font-small-2 primary align-self-center">
//                         {'click-to-upload-a-file'}{' '}
//                       </span>
//                       <span className="font-small-2 color- align-self-center align-text-center">
//                         JPG,PNG,JPEG,WEBP,SVG <br /> {'up-to-5mb'}
//                       </span>
//                     </React.Fragment>
//                     {/* )} */}
//                   </div>
//                   <input
//                     type="file"
//                     id="fileInput"
//                     name="fileInput"
//                     // onChange={handleChange}
//                     // ref={fileRef}
//                     style={{ display: 'none' }}
//                     accept="image/*"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="6">
//                 <FormGroup>
//                   <Label for="item">Price:</Label>
//                   <Input type="text" name="item" id="item"></Input>
//                 </FormGroup>
//               </Col>

//               <Col md="6">
//                 <FormGroup>
//                   <Label for="dishTags">Dish Tags:</Label>
//                   <Input type="select" name="dishTags" id="dishTags">
//                     {allDishTags.map((tag) => (
//                       <option key={tag.id} value={tag.id}>
//                         {tag.title}
//                       </option>
//                     ))}
//                   </Input>
//                 </FormGroup>
//               </Col>
//             </Row>

//             <Row>
//               <Col md="6">
//                 {' '}
//                 <FormGroup>
//                   <Label for="allergicItems">Allergic Items:</Label>
//                   <Input type="select" name="allergicItems" id="allergicItems">
//                     {allAllergicItems.map((item) => (
//                       <option key={item.id} value={item.id}>
//                         {item.title}
//                       </option>
//                     ))}
//                   </Input>
//                 </FormGroup>
//               </Col>
//               <Col md="6">
//                 <FormGroup>
//                   <Label for="email">{'item-description-0'}: </Label>
//                   <Input
//                     type="textarea"
//                     id="name"
//                     // value={item.menu_description}
//                     placeholder={'enter-item-description'}
//                   />
//                 </FormGroup>
//               </Col>

//               <Col md="6">
//                 <Label for="menu_tax">Tax({location?.tax_label}):</Label>
//                 <Input
//                   type="text"
//                   id="menu_tax"
//                   // value={item.menu_tax}

//                   placeholder={'enter-tax-value'}
//                 />
//               </Col>

//               <Col md="6">
//                 {' '}
//                 <FormGroup>
//                   <Label for="customTags">Tag:</Label>
//                   <Input type="select" name="customTags" id="customTags">
//                     {randomcustomisedTags.map((item2) => (
//                       <option key={item2.tag_id} value={item2.tag_id}>
//                         {item2.tag_name}
//                       </option>
//                     ))}
//                   </Input>
//                 </FormGroup>
//               </Col>
//             </Row>
//           </Form>
//         </Col>

//         <Row>
//           <Col md="6">
//             <FormGroup check>
//               <Label check>
//                 <Input
//                   type="checkbox"
//                   onChange={handleStatusChange}
//                   checked={status}
//                 />
//                 Status (Enabled/Disabled)
//               </Label>
//             </FormGroup>
//           </Col>

//           {/* Chef Recommended checkbox */}
//           <Col md="6">
//             <FormGroup check>
//               <Label check>
//                 <Input
//                   type="checkbox"
//                   onChange={handleChefRecommendedChange}
//                   checked={chefRecommended}
//                 />
//                 Chef Recommended
//               </Label>
//             </FormGroup>
//           </Col>
//         </Row>
//       </Row>
//       {/* </Card> */}
//     </Container>
//   );
// };

// export default MenuItemPage;

import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  Col,
  Media,
} from "reactstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Radio from "../../@vuexy/radio/RadioVuexy";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";

import { ClipLoader } from "react-spinners";
import Select from "react-select";
import { Image, Plus, Trash, X } from "react-feather";
import ImageHelper from "../../../helpers/ImageHelper";
import * as classnames from "classnames";
import MenuModifiersList from "../MenuModifiersList";
import ConfirmationModal from "./ConfirmationModal";
import GlobalModifiersFormModal from "./GlobalModifiersFormModal";
import MenuService from "../../../services/MenuService";
import CONSTANTS from "../../../constants";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const MenuItemPage = (props) => {
  console.log(" PROPS", props.history);
  console.log("MENU ITEM PROPS", props.history.location.state);

  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const dummyItem = {
    menu_name: "",
    menu_description: "",
    menu_status: 1,
    menu_price: "",
    menu_photo: "",
    menu_category_id: null,
    stock_qty: 0,
    minimum_qty: 0,
    subtract_stock: 0,
    mealtime_id: 0,
    pre_rating: 4.0,
    review_photo: "",
    ratable: 1,
    MenuOptions: [],
    MenuItemRecommendations: [],
    menu_tax: 0,
    highlighted: 0,
    MenuIcons: [],
  };

  const menuType = [
    {
      value: "kitchen",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.KITCHEN,
    },
    {
      value: "bar",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.BAR,
    },
    {
      value: "dessert",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.DESSERT,
    },
    {
      value: "alternative_1",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.ALTERNATIVE_1,
    },
    {
      value: "alternative_2",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.ALTERNATIVE_2,
    },
  ];

  let {
    isOpen,
    toggleModal,
    selectedCategory,
    categories,
    deleteMenu,

    deleting,
    location,
    allDishTags,
    selectedDishTags,
    allAllergicItems,
    selectedAllergicItems,
    itemsArrayForRecom,
    nutriScoreOptions,
  } = props.history.location.state;

  let itemProp = props.history.location.state.item
    ? props.history.location.state.item
    : JSON.parse(JSON.stringify(dummyItem));

  var preSelectedMenuIcon = null;
  if (itemProp?.MenuIcons?.length > 0) {
    preSelectedMenuIcon = itemProp.MenuIcons[0].iconPath;
  }

  let recommendedItemOne = { value: "", label: t("select-item") };
  let recommendedItemTwo = { value: "", label: t("select-item") };
  if (
    itemProp?.MenuItemRecommendations &&
    itemProp?.MenuItemRecommendations.length > 0
  ) {
    if (itemProp?.MenuItemRecommendations[0]?.Menu) {
      recommendedItemOne.value =
        itemProp?.MenuItemRecommendations[0].recommendation_item_id;
      recommendedItemOne.label =
        itemProp?.MenuItemRecommendations[0].Menu.menu_name;
    }

    if (itemProp?.MenuItemRecommendations[1]?.Menu) {
      recommendedItemTwo.value =
        itemProp?.MenuItemRecommendations[1].recommendation_item_id;
      recommendedItemTwo.label =
        itemProp?.MenuItemRecommendations[1].Menu.menu_name;
    }
  }

  let selectedMenuType = {};
  if (props.history.location.state.item?.menu_type) {
    selectedMenuType = menuType.map((type) => {
      return type.value === props.history.location.state.item?.menu_type
        ? type
        : null;
    });
  }
  let selectedNutriOption = [];
  for (let a = 0; a < nutriScoreOptions?.length; a++) {
    if (nutriScoreOptions[a]?.value == itemProp?.nutri_score) {
      selectedNutriOption.push(nutriScoreOptions[a]);
    }
  }

  const [item, setItem] = useState(itemProp);
  const [selectedImages, setSelectedImages] = useState(preSelectedMenuIcon);
  const [icons, setIcons] = useState([]);

  const [saving, setSaving] = useState(false);
  const [nameError, setError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [tab, setTab] = useState(1);
  const [prevOpenState, setPrevOpenState] = useState(isOpen);
  const [mediaUpload, setMediaUpload] = useState(null);
  const [preUploadedMedia, setPreUploadedMedia] = useState([]);
  const [currentUploadedMedia, setCurrentUploadedMedia] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState({
    show: false,
    acceptCallback: () => {},
    rejectCallback: () => {},
    title: "",
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--primary)"
        : state.isFocused
        ? "#7367f099"
        : state.data.backgroundColor,
    }),
  };

  const fetchIcon = () => {
    fetch(`${CONSTANTS.API_URL}/display/displayIcons`)
      .then((response) => response.json())
      .then((data) => setIcons(data.message))
      .catch((err) => console.log(err));
  };

  // console.log(icons);

  const onValidateDropDownError = (value, name) => {
    setDropDownError((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };

  const [errorDropDownError, setDropDownError] = useState({
    category: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
    dishtag: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
    menutype: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
    allergyitems: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
  });

  const [globalModifiersDialog, setGlobalModifiersDialog] = useState(false);

  const toggleGlobalModifierModal = (changeTab = false) => {
    setGlobalModifiersDialog(!globalModifiersDialog);
    if (changeTab) toggle(2);
  };

  const getSelectedModifiers = () => {
    return item.MenuOptions.map((mo) => mo.option_id);
  };

  const showConfirmationDialog = (accept, reject, title) => {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        setConfirmationDialog(defaultState);
      },
      rejectCallback: () => {
        setConfirmationDialog(defaultState);
        if (reject) reject();
      },
      title: title,
    };
    setConfirmationDialog(confirmationDialog);
  };

  let inputRef = React.createRef();
  let fileRef = React.createRef();
  let mediaFileRef = React.createRef();

  const toggle = (sTab) => {
    if (tab !== sTab) {
      setTab(sTab);
    }

    if (sTab === 3) {
      let menuMediaArray = [];
      if (item.MenuMedia?.length > 0) {
        item.MenuMedia.map((media, i) => {
          menuMediaArray.push({
            original:
              CONSTANTS.API_URL + "/restaurant_data/" + media?.menu_photo,
            id: media?.id,
            bulletOnClick: (media) => {
              setCurrentUploadedMedia(media?.item.id);
            },
          });
        });
        setCurrentUploadedMedia(menuMediaArray[0].id);
      }
      setPreUploadedMedia(menuMediaArray);
    } else {
      setPreUploadedMedia([]);
    }
  };

  const updateItem = (key, val) => {
    if (key === "highlighted") {
      val = val == 0 ? 1 : 0;
    }
    const cat = JSON.parse(JSON.stringify(item));
    cat[key] = val;
    if (key === "img" && val === null) cat.deletePhoto = true;
    setItem(cat);
    if (key === "menu_category") onValidateDropDownError("", "category");
  };

  const updateDishtags = (val) => {
    const cat = JSON.parse(JSON.stringify(item));
    cat["menu_tags"] = val;
    setItem(cat);
    onValidateDropDownError("", "dishtag");
  };

  const updateAllergicItem = (val) => {
    const cat = JSON.parse(JSON.stringify(item));

    cat["menu_allergy_items"] = val;
    setItem(cat);
    onValidateDropDownError("", "allergyitems");
  };

  const updateMenuType = (val) => {
    const cat = JSON.parse(JSON.stringify(item));
    cat["menu_type"] = val.value;
    setItem(cat);
    onValidateDropDownError("", "menutype");
  };

  const handleChange = (e) => {
    if (e.target.files.length > 0) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "webp", "svg"];
      const fileExtension = e.target.files[0].name.split(".").at(-1);
      if (!allowedFileTypes.includes(fileExtension)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Upload image does not support. Image type must be ${allowedFileTypes.join(
            ", "
          )}`,
        });
        return false;
      }

      var size = parseFloat(e.target.files[0].size / (1024 * 1024 * 5)).toFixed(
        2
      );
      if (size > 5) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("please-select-a-image-size-less-than-5-mb"),
        });
        // alert('Please select image size less than 5 MB');
        return false;
      }

      let reader = new FileReader();
      reader.onload = (ev) => {
        const itemProp = JSON.parse(JSON.stringify(item));
        itemProp.img = ev.target.result;
        itemProp.deletePhoto = false;
        setItem(itemProp);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const uploadMediaChange = (e) => {
    if (e.target.files.length > 0) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "webp", "svg"];
      const fileExtension = e.target.files[0].name
        .split(".")
        .pop()
        .toLowerCase();
      if (!allowedFileTypes.includes(fileExtension)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Upload image does not support. Image type must be ${allowedFileTypes.join(
            ", "
          )}`,
        });
        return false;
      }

      var size = parseFloat(e.target.files[0].size / (1024 * 1024 * 5)).toFixed(
        2
      );
      if (size > 5) {
        alert(t("please-select-an-image-size-less-than-5-mb"));
        return false;
      }

      let reader = new FileReader();
      reader.onload = (ev) => {
        let img = ev.target.result;
        setMediaUpload(img);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const bulletOnClick = (menuItem, itemIndex, currentIndex) => {
    console.log(menuItem, itemIndex, currentIndex);
  };

  const delMenu = () => {
    if (saving || deleting) return;
    deleteMenu(item);
  };

  const updateItemRecommendation = (value, index) => {
    let oldMenu = item;
    if (
      itemProp?.MenuItemRecommendations &&
      itemProp?.MenuItemRecommendations.length > 0
    ) {
      if (oldMenu?.MenuItemRecommendations[index]) {
        oldMenu.MenuItemRecommendations[index].recommendation_item_id =
          value.value;
      } else {
        oldMenu.MenuItemRecommendations[index] = {
          loc_id: oldMenu.loc_id,
          main_menu_id: oldMenu.menu_id,
          recommendation_item_id: value.value,
        };
      }
    } else {
      oldMenu.MenuItemRecommendations.push({
        loc_id: oldMenu.loc_id,
        main_menu_id: oldMenu.menu_id,
        recommendation_item_id: value.value,
      });
    }

    if (value.value === "") {
      oldMenu.MenuItemRecommendations.splice(index, 1);
    }

    setItem(oldMenu);
  };

  const updateIcon = (icon, index) => {
    let oldMenu = item;
    console.log("icon");
    console.log(`"${icon}"`);
    console.log(preSelectedMenuIcon);

    if (preSelectedMenuIcon === icon || preSelectedMenuIcon == `"${icon}"`) {
      console.log("helooo");
      setSelectedImages(null);
      preSelectedMenuIcon = null;
      oldMenu.MenuIcons = [];
      console.log(preSelectedMenuIcon);
      setSelectedImages(preSelectedMenuIcon);

      console.log(icon);

      setItem(oldMenu);
    } else if (selectedImages == icon || preSelectedMenuIcon == icon) {
      console.log("1");
      setSelectedImages(null);
      oldMenu.MenuIcons = [];
      setSelectedImages(icon);
      oldMenu.MenuIcons.push({
        iconPath: icon,
      });
      // console.log("update: ", oldMenu.MenuIcons);
      setItem(oldMenu);
    } else {
      // If the icon is not selected, select it
      console.log("3");
      setSelectedImages(icon);
      oldMenu.MenuIcons.pop({
        iconPath: icon,
      });
      oldMenu.MenuIcons.push({
        iconPath: icon,
      });
      setItem(oldMenu);
    }
  };

  useEffect(() => {
    let itemProp = props.history.location.state.item
      ? props.history.location.state.item
      : JSON.parse(JSON.stringify(dummyItem));
    itemProp.menu_category = selectedCategory;
    itemProp.img = ImageHelper.getImageURL(
      itemProp.menu_photo,
      "?func=crop&w=120&h=80"
    );

    itemProp.menu_tags = selectedDishTags;
    itemProp.menu_allergy_items = selectedAllergicItems;
    isOpen = props.isOpen;
    setSaving(false);
    fetchIcon();
    deleting = props.deleting;
    setError(false);
    if (prevOpenState !== isOpen) {
      setPrevOpenState(isOpen);
      setTab(1);
    }
    setItem(itemProp);
    setPriceError(false);
  }, [props]);

  const submitItem = () => {
    if (saving || deleting) return;
    if (item.menu_name === "") {
      setError(true);
      return;
    }

    if (
      item.menu_price === "" ||
      item.menu_price == 0 ||
      isNaN(item.menu_price)
    ) {
      setPriceError(true);
      return;
    }

    setSaving(true);
    props.history.location.saveItem(item);
    // After saving, navigate back to /locations/menu
    props.history.push("/locations/menu");
  };

  const fetchGlobalModifiersForItem = async () => {
    const modifiers = await MenuService?.getModifiers(
      item.loc_id,
      item.menu_id
    );
    const it = JSON.parse(JSON.stringify(item));
    it.MenuOptions = modifiers;
    setItem(it);
  };

  const categoryOptions = categories?.map((cat) => ({
    value: cat.id,
    label: cat.name,
  }));

  const allDishTagOptions = allDishTags?.map((tag) => ({
    value: tag.id,
    label: tag.title,
  }));

  const allAllergicItemOptions = allAllergicItems?.map((item) => ({
    value: item.id,
    label: item.title,
    description: item.description,
  }));

  const renderCustomControls = () => {
    return (
      <Trash
        onClick={() =>
          showConfirmationDialog(
            () => {
              props.deleteMenuMedia(currentUploadedMedia);
            },
            null,
            "Are you sure you want to remove this menu media?"
          )
        }
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          zIndex: 100,
          cursor: "pointer",
        }}
        color="white"
        size={30}
      />
    );
  };
  const handleStatusChange = (value) => {
    updateItem("status", value);
  };

  return (
    <React.Fragment>
      {console.log("selectedImages", selectedImages)}
      {/* <Modal
        isOpen={isOpen}
        toggle={() => toggleModal()}
        onOpened={() => {
          if (props.item) return;
          setTimeout(() => inputRef.current.focus(), 1);
        }}
        className="modal-dialog-centered d-sm-margin-0 p-0"
        backdrop="static"
      > */}
      {/* <div className="menu-item-page">
        <h5 className="modal-title">
          {props.item?.menu_name ? props.item.menu_name : t('new-item')}
        </h5>
        <X size={20} onClick={toggleModal} />
      </div> */}

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: tab === 1,
            })}
            onClick={() => {
              toggle(1);
            }}
          >
            {t("details")}{" "}
          </NavLink>
        </NavItem>
        {props.item?.menu_name && (
          <>
            <NavItem>
              <NavLink
                className={classnames({
                  active: tab === 2,
                })}
                onClick={() => {
                  toggle(2);
                }}
              >
                {t("variants")}{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: tab === 3,
                })}
                onClick={() => {
                  toggle(3);
                }}
              >
                {t("images")}{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: tab === 4,
                })}
                onClick={() => {
                  toggle(4);
                }}
              >
                {t("recommendation-items")}{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: tab === 5,
                })}
                onClick={() => {
                  toggle(5);
                }}
              >
                {t("icons")}
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>
      <TabContent activeTab={tab}>
        <TabPane tabId={1}>
          <FormGroup className="row">
            <Col md={12}>
              <Label for="name">{t("item-name-0")}: </Label>
              <Input
                innerRef={inputRef}
                type="text"
                id="name"
                // value={item.menu_name}
                value={item.menu_name || ""}
                placeholder={t("enter-item-name")}
                invalid={nameError}
                onChange={(e) => updateItem("menu_name", e.target.value)}
              />
            </Col>
            <Col md={12}>
              <Label
                for="category"
                style={{
                  color:
                    errorDropDownError.category.errorMsg !== "" ? "red" : null,
                }}
              >
                {errorDropDownError.category.errorMsg !== ""
                  ? t("category-0")
                  : t("category")}
              </Label>
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "var(--primary)"
                      : state.isFocused
                      ? "#7367f099"
                      : "#fff",
                  }),
                }}
                className="React no-pointer-events"
                classNamePrefix="select"
                defaultValue={item.menu_category}
                name="color"
                options={categoryOptions}
                onChange={(e) => updateItem("menu_category", e)}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Col md={12}>
              <Label
                for="category"
                style={{
                  color:
                    errorDropDownError.menutype.errorMsg !== "" ? "red" : null,
                }}
              >
                {errorDropDownError.menutype.errorMsg !== ""
                  ? t("menu-type-2")
                  : t("menu-type")}
              </Label>
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "var(--primary)"
                      : state.isFocused
                      ? "#7367f099"
                      : "#fff",
                  }),
                }}
                className="React no-pointer-events"
                classNamePrefix="select"
                defaultValue={selectedMenuType}
                name="menu_type"
                options={[{ value: "", label: "Select..." }, ...menuType]} // Add the None option at the beginning
                onChange={(e) => updateMenuType(e)}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Col md={12}>
              <Label for="nutriScore">{t("nutri-score")}: </Label>
              <Select
                styles={customStyles}
                defaultValue={selectedNutriOption}
                // onChange={handleNutriScoreChange}
                className="React no-pointer-events"
                classNamePrefix="select"
                onChange={(e) => updateItem("nutri_score", e.value)}
                options={nutriScoreOptions}
              />
              {selectedNutriOption && selectedNutriOption.length > 0 && (
                <div
                  style={{
                    marginTop: "10px",
                    backgroundColor: selectedNutriOption[0].backgroundColor,
                    padding: "5px",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  {t("nutri-score")}: {selectedNutriOption[0].label}
                </div>
              )}
            </Col>
          </FormGroup>

          <FormGroup className="row">
            <Col md={12}>
              <Label
                for="category"
                style={{
                  color:
                    errorDropDownError.dishtag.errorMsg !== "" ? "red" : null,
                }}
              >
                {errorDropDownError.dishtag.errorMsg !== ""
                  ? t("dish-tags-1")
                  : t("dish-tags-0")}
              </Label>
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "var(--primary)"
                      : state.isFocused
                      ? "#7367f099"
                      : "#fff",
                  }),
                }}
                className="React no-pointer-events"
                classNamePrefix="select"
                defaultValue={selectedDishTags}
                name="color"
                options={allDishTagOptions}
                onChange={(e) => updateDishtags(e)}
                isMulti
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Col md={12}>
              <Label
                for="category"
                style={{
                  color:
                    errorDropDownError.allergyitems.errorMsg !== ""
                      ? "red"
                      : null,
                }}
              >
                {errorDropDownError.allergyitems.errorMsg !== ""
                  ? t("allergic-items-0")
                  : t("allergic-items")}
              </Label>
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "var(--primary)"
                      : state.isFocused
                      ? "#7367f099"
                      : "#fff",
                  }),
                }}
                className="React no-pointer-events"
                classNamePrefix="select"
                defaultValue={selectedAllergicItems}
                name="color"
                options={allAllergicItemOptions}
                onChange={(e) => updateAllergicItem(e)}
                isMulti
              />
              <div>
                {item?.menu_allergy_items?.map((selectedItem, index) => (
                  <div
                    key={index}
                    style={{
                      border: "1px solid gray",
                      margin: "10px",
                      padding: "10px",
                      borderRadius: "5px",
                      background: selectedItem?.isSelected ? "gray" : "none",
                    }}
                  >
                    <h6
                      style={{
                        color: "primary",
                        textDecoration: "underline",
                      }}
                    >
                      {selectedItem?.label}
                    </h6>
                    <p style={{ fontStyle: "italic", fontSize: "14px" }}>
                      {selectedItem?.description}
                    </p>
                  </div>
                ))}
              </div>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Col md={6}>
              <Label for="photoDiv">{t("menu-main-image")}: </Label>
              <div
                tabIndex="0"
                id="photoDiv"
                className="display-flex flex-column border border-2 border-light cursor-pointer"
                onKeyUp={(e) =>
                  e.keyCode === 13 && !e.ctrlKey
                    ? fileRef.current.click()
                    : null
                }
                onClick={() => fileRef.current.click()}
                style={{ position: "relative", height: 120 }}
              >
                <div style={{ textAlign: "-webkit-center" }}>
                  {item.img ? (
                    <div>
                      <div
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                        }}
                      >
                        <Button.Ripple
                          onClick={() => {
                            updateItem("img", null);
                            fileRef.current.value = null;
                          }}
                          style={{ width: 20, height: 20 }}
                          className="m-0 p-0"
                          color="danger"
                        >
                          <X size={14} />
                        </Button.Ripple>
                      </div>
                      <div
                        style={{ position: "absolute", bottom: 3 }}
                        className="full-width"
                      >
                        <Button.Ripple
                          className="p-25"
                          color="primary"
                          onClick={() => fileRef.current.click()}
                        >
                          <span className="font-small-1">
                            {t("change-photo")}
                          </span>
                        </Button.Ripple>
                      </div>
                      <Media
                        object
                        src={item.img}
                        alt="image"
                        style={{ maxWidth: 220, maxHeight: 120 }}
                        className="align-self-center"
                      />
                    </div>
                  ) : (
                    <div
                      className="align-self-center"
                      style={{ padding: 9, height: 50, width: 50 }}
                    >
                      <Image size={32} className="align-self-center" />
                    </div>
                  )}
                </div>
                {item.img ? null : (
                  <React.Fragment>
                    <span className="font-small-2 primary align-self-center">
                      {t("click-to-upload-a-file")}{" "}
                    </span>
                    <span className="font-small-2 color- align-self-center align-text-center">
                      JPG,PNG,JPEG,WEBP,SVG <br /> {t("up-to-5mb")}
                    </span>
                  </React.Fragment>
                )}
              </div>
              <input
                type="file"
                id="fileInput"
                name="fileInput"
                onChange={handleChange}
                ref={fileRef}
                style={{ display: "none" }}
                accept="image/*"
              />
            </Col>
            <Col md={6}>
              <Label for="price">{t("price")}: </Label>
              <Input
                type="text"
                id="price"
                invalid={priceError}
                // value={item.menu_price}
                value={item.menu_price || ""}
                placeholder={t("enter-price")}
                onChange={(e) => updateItem("menu_price", e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="email">{t("item-description-0")}: </Label>
            <Input
              type="textarea"
              id="name"
              value={item.menu_description}
              placeholder={t("enter-item-description")}
              onChange={(e) => updateItem("menu_description", e.target.value)}
            />
          </FormGroup>
          <FormGroup className="row">
            <Col md={12}>
              <Label for="menu_tax">Tax({location?.tax_label}):</Label>
              <Input
                type="text"
                id="menu_tax"
                // value={item.menu_tax}
                value={item.menu_tax || ""}
                placeholder={t("enter-tax-value")}
                onChange={(e) => updateItem("menu_tax", e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="status">{t("status-0")}: </Label>
            <div
              id="status"
              onChange={(e) =>
                updateItem("menu_status", parseInt(e.target.value))
              }
            >
              <div className="d-inline-block mr-1">
                <Radio
                  label={t("enabled")}
                  checked={item.menu_status === 1}
                  name="status"
                  value={1}
                  onChange={() => handleStatusChange(1)}
                />
              </div>
              <div className="d-inline-block mr-1">
                <Radio
                  label={t("disabled")}
                  name="status"
                  checked={item.menu_status === 0}
                  value={0}
                  onChange={() => handleStatusChange(0)}
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="highlighted">
              {t("chefs-recommended-the-menu-item")}:{" "}
            </Label>
            <div id="highlighted">
              <div className="d-inline-block mr-1">
                <Checkbox
                  label={t("chefs-recommended")}
                  color="primary"
                  defaultChecked={item.highlighted == 1}
                  value={
                    item.highlighted == 0 || item.highlighted === undefined
                      ? 0
                      : 1
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    updateItem("highlighted", e.target.value);
                  }}
                />
              </div>
            </div>
          </FormGroup>
        </TabPane>
        <TabPane tabId={2}>
          <MenuModifiersList
            showConfirmationDialog={showConfirmationDialog}
            modifiers={item.MenuOptions}
            updateModifiers={updateItem.bind(this, "MenuOptions")}
            location={location}
          />
          <Button
            outline
            className="font-weight-bold custom-padding primary-2-border"
            color={"primary"}
            size="md"
            onClick={toggleGlobalModifierModal.bind(this)}
          >
            <Plus className="mx-50" size={14} />{" "}
            <span className="ml-50 mr-50 primary">{t("add-variants")}</span>
          </Button>
        </TabPane>
        <TabPane tabId={3}>
          <>
            <FormGroup className="row">
              <Col md={12}>
                <Label for="photoDiv">{t("menu-image")}: </Label>
                <div
                  tabIndex="0"
                  id="photoDiv"
                  className="display-flex flex-column border border-2 border-light cursor-pointer"
                  onKeyUp={(e) =>
                    e.keyCode === 13 && !e.ctrlKey
                      ? mediaFileRef.current.click()
                      : null
                  }
                  onClick={() => mediaFileRef.current.click()}
                  style={{ position: "relative", height: 120 }}
                >
                  <div style={{ textAlign: "-webkit-center" }}>
                    {mediaUpload ? (
                      <div>
                        <div
                          style={{ position: "absolute", bottom: 3 }}
                          className="full-width"
                        >
                          <Button.Ripple
                            className="p-25"
                            color="primary"
                            onClick={() => mediaFileRef.current.click()}
                          >
                            <span className="font-small-1">
                              {t("change-photo")}{" "}
                            </span>
                          </Button.Ripple>
                        </div>
                        <Media
                          object
                          src={mediaUpload}
                          alt="image"
                          style={{ maxWidth: 148, maxHeight: 118 }}
                          className="align-self-center"
                        />
                      </div>
                    ) : (
                      <div
                        className="align-self-center"
                        style={{ padding: 9, height: 50, width: 50 }}
                      >
                        <Image size={32} className="align-self-center" />
                      </div>
                    )}
                  </div>
                  {mediaUpload ? null : (
                    <React.Fragment>
                      <span className="font-small-2 primary align-self-center">
                        {t("click-to-upload-a-file")}{" "}
                      </span>
                      <span className="font-small-2 color- align-self-center align-text-center">
                        JPG,PNG,JPEG,WEBP,SVG <br /> {t("up-to-5mb")}
                      </span>
                    </React.Fragment>
                  )}
                </div>
                <input
                  type="file"
                  id="uploadMediaFileInput"
                  name="uploadMediaFileInput"
                  onChange={uploadMediaChange}
                  ref={mediaFileRef}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </Col>
            </FormGroup>
            {preUploadedMedia.length > 0 && (
              <div className="row p-1">
                <ImageGallery
                  items={preUploadedMedia}
                  lazyLoad={true}
                  showThumbnails={false}
                  showNav={false}
                  showPlayButton={false}
                  showBullets
                  renderCustomControls={() => renderCustomControls()}
                  showFullscreenButton={false}
                />
              </div>
            )}
          </>
        </TabPane>
        <TabPane tabId={4}>
          <FormGroup className="row">
            <Col md={12}>
              <Label
                for="category"
                style={{
                  color:
                    errorDropDownError.menutype.errorMsg !== "" ? "red" : null,
                }}
              >
                {t("item-1")}:{" "}
              </Label>
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "var(--primary)"
                      : state.isFocused
                      ? "#7367f099"
                      : "#fff",
                  }),
                }}
                className="React no-pointer-events"
                classNamePrefix="select"
                defaultValue={recommendedItemOne}
                name="recommended_item_one"
                // Add the None option at the beginning
                onChange={(e) => updateItemRecommendation(e, 0)}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Col md={12}>
              <Label
                for="category"
                style={{
                  color:
                    errorDropDownError.menutype.errorMsg !== "" ? "red" : null,
                }}
              >
                {t("item-2")}:{" "}
              </Label>
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "var(--primary)"
                      : state.isFocused
                      ? "#7367f099"
                      : "#fff",
                  }),
                }}
                className="React no-pointer-events"
                classNamePrefix="select"
                defaultValue={recommendedItemTwo}
                name="recommended_item_two"
                onChange={(e) => updateItemRecommendation(e, 1)}
              />
            </Col>
          </FormGroup>
        </TabPane>
        <TabPane tabId={5}>
          <>
            {icons?.length > 0 ? (
              <div>
                {icons.map((icon) => {
                  return (
                    <>
                      <img
                        style={{
                          width: "70px",
                          height: "70px",
                          marginRight: "10px",
                          marginLeft: "10px",

                          border:
                            preSelectedMenuIcon == `"${icon}"` ||
                            selectedImages == icon
                              ? "2px solid black"
                              : selectedImages === null ||
                                preSelectedMenuIcon === null ||
                                preSelectedMenuIcon === "null"
                              ? "none"
                              : "none",
                        }}
                        src={`${CONSTANTS.API_URL}/${icon}`}
                        alt={t("icon")}
                        className={
                          preSelectedMenuIcon == `"${icon}"` ||
                          selectedImages == icon
                            ? "selected"
                            : ""
                        }
                        onClick={() => {
                          updateIcon(icon);
                        }}
                      />
                    </>
                  );
                })}
              </div>
            ) : null}
          </>
        </TabPane>
      </TabContent>

      <div className="d-none d-sm-flex flex-space-between full-width">
        <div>
          {props.history.location.state.item &&
            !props.history.location.state.item.is_dummy_item &&
            tab !== 3 && (
              <Button
                color="bg-danger-3"
                disabled={saving || deleting}
                onClick={delMenu}
                className="border-danger"
              >
                {deleting ? (
                  <ClipLoader size={15} />
                ) : (
                  <span className="danger"> {t("delete")} </span>
                )}
              </Button>
            )}{" "}
        </div>
        <div>
          {tab != 3 ? (
            <Button
              color="primary"
              disabled={saving || deleting}
              onClick={submitItem}
            >
              {saving ? <ClipLoader size={15} /> : t("save")}
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={mediaUpload === null}
              onClick={() => props.saveUploadMedia(item)}
            >
              {saving ? <ClipLoader size={15} /> : t("upload")}
            </Button>
          )}{" "}
        </div>
      </div>
      <div className="d-sm-none col-12">
        {tab !== 3 && (
          <Button
            className="col-12"
            color="primary"
            disabled={saving || deleting}
            onClick={submitItem}
          >
            {saving ? <ClipLoader size={15} /> : t("save")}
          </Button>
        )}{" "}
        {props.history.location.state.item && tab !== 3 && (
          <Button
            className="col-12"
            color="darken-1"
            disabled={saving || deleting}
            onClick={delMenu}
          >
            {deleting ? (
              <ClipLoader size={15} />
            ) : (
              <span className="danger"> {t("delete")} </span>
            )}
          </Button>
        )}
      </div>
      {/* <p className="d-none d-sm-inline col-12 align-text-right font-small-1 pr-0">
            Hit Ctrl + Enter to Save
          </p> */}

      {/* </Modal> */}
      <GlobalModifiersFormModal
        fetchGlobalModifiersForItem={fetchGlobalModifiersForItem.bind(this)}
        isOpen={globalModifiersDialog}
        selectedModifiers={getSelectedModifiers()}
        toggleModal={toggleGlobalModifierModal.bind(this)}
        showConfirmationDialog={showConfirmationDialog.bind(this)}
        menu_id={item.menu_id}
        title={item.menu_name}
        loc_id={location?.loc_id}
        isDummyItem={item.is_dummy_item}
      />
      <ConfirmationModal
        title={confirmationDialog.title}
        isOpen={confirmationDialog.show}
        accept={confirmationDialog.acceptCallback.bind(this)}
        reject={confirmationDialog.rejectCallback.bind(this)}
      />
    </React.Fragment>
  );
};
export default MenuItemPage;
