import React from "react";
import { Button } from "reactstrap";
import { Database } from "react-feather";
import LocationService from "../../services/LocationService";
import "../../assets/css/locationSelectHeader.scss";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const TakeDatabaseBackup = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  return (
    <div>
      <Button
        outline
        className="font-weight-bold success-2-border custom-padding"
        color="success"
        size="md"
        onClick={() => props.onIconClick()}
      >
        <Database size={15} />
        {t('backup')}
      </Button>
    </div>
  );
};

export default TakeDatabaseBackup;
