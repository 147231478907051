import React, { Component } from "react";

import TimeHelper from "../../helpers/TimeHelper";
import DataTableComponent from "../shared/DataTableComponent";
import CONSTANTS from "../../constants";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class BestSoldItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        startDate: TimeHelper.getFirstDayOf2018(),
        endDate: TimeHelper.getCurrentDate(),
        orderBy: "total_sales",
        order: "desc",
      },
      columns: [
        {
          name: this.props.t("item-name"),
          // selector: "menu_name",
          selector: (row) => row.menu_name,
          sortable: false,
        },
        {
          name: this.props.t("units-sold"),
          // selector: 'total_sales',
          selector: (row) => row.total_sales,
          sortable: true,
        },
      ],
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }

  render() {
    const { t } = this.props;
    const {
      props: { data, showDateFilter, pagination, showPaginationBottom },
    } = this;

    const {
      state: { columns, filters },
    } = this;

    return (
      <div className="location">
        <DataTableComponent
          //   dateFilterUpdated={this.filterUpdated.bind(this)}
          showDateFilter={showDateFilter}
          pagination={pagination}
          showPaginationBottom={showPaginationBottom}
          title={t("best-sold-items")}
          data={data}
          columns={columns}
          startDate={filters.startDate}
          endDate={filters.endDate}
          exportBtn={true}
        />
      </div>
    );
  }
}

export default withTranslation("global")(BestSoldItemsList);
