import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import { Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from 'sweetalert2';
import LoginService from '../../services/LoginService';
import CONSTANTS from '../../constants';

import loginImg2 from '../../assets/img/logo/waiterAsAServiceLogo.png';
import logo from '../../assets/img/Servall.png';

import '../../assets/scss/pages/authentication.scss';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: '',
      showDialog: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.from)
      localStorage.setItem('returnURL', this.props.location.state.from);
    if (this.props.location.state && this.props.location.state.data)
      localStorage.setItem(
        'returnData',
        JSON.stringify(this.props.location.state.data)
      );
    if (LoginService.getToken()) this.redirectToReturnURL();
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  forgetPasswordRequest() {
    if (this.state.email !== '' && this.state.email !== null) {
      this.setState({ loading: true }, async () => {
        try {
          let tokenData = await LoginService.sendForgetPasswordEmail(
            this.state.email
          );
          Swal.fire({
            icon: 'success',
            title: 'OTP Send Successfully',
            text: 'Mail has been send on your given email address. Kindly follow up the instructions',
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            window.location.href = '/reset_password';
          });
        } catch (e) {
          console.log('Hello', e.toString());
          this.setState({ loading: false, showDialog: true });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Email Required',
        text: 'Please enter valid email address',
        showConfirmButton: false,
        timer: 1000,
      });
    }
  }

  returnURL() {
    let url = localStorage.getItem('returnURL') || `${CONSTANTS.BASE_URL}`;
    localStorage.removeItem('returnURL');
    return url;
  }

  returnData() {
    let data = localStorage.getItem('returnData') || null;
    localStorage.removeItem('returnData');
    if (data) return JSON.parse(data);
  }

  redirectToReturnURL() {
    this.props.history.push(this.returnURL(), this.returnData());
  }

  async onChangeTerms() {
    await this.setState({ isTermsCheck: !this.state.isTermsCheck });
  }

  render() {
    return (
      <Row className="m-0 justify-content-center">
        <Col
          lg="6"
          className="d-lg-block  text-center align-self-center px-1 py-0"
        ></Col>
        <Col lg="3" md="9" className="p-0">
          {/* px-1 */}
          <Card className="rounded-4 mb-0  login-tabs-container ">
            <CardHeader className="pb-1">
              <CardTitle>
                <div className="tocImage">
                  <img
                    width={'70%'}
                    height={'auto'}
                    className="mx-auto d-flex"
                    // src={loginImg2}
                    src={logo}
                    alt="loginImg"
                  />
                </div>
              </CardTitle>
            </CardHeader>
            <p className="px-2 auth-title text-center">
              Forget Password Request
            </p>

            <Card className="rounded-0 mb-0 ">
              <CardBody>
                <p className="text-center font-weight-bold">
                  Kindly enter valid email address for updating the password
                </p>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <FormGroup className="form-label-group position-relative has-icon-left mt-2">
                    <Input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange.bind(this)}
                    />
                    <div className="form-control-position">
                      <Mail size={15} />
                    </div>
                    <Label>Email</Label>
                  </FormGroup>
                  <FormGroup className="d-flex justify-content-between align-items-center">
                    <Link to="/login">Back to login</Link>
                  </FormGroup>
                  <FormGroup className="text-center">
                    <Button.Ripple
                      color="primary"
                      type="submit"
                      className="btn-block"
                      onClick={() => this.forgetPasswordRequest()}
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? (
                        <span>Loading...</span>
                      ) : (
                        <span>Send email</span>
                      )}
                    </Button.Ripple>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Card>
        </Col>
        {this.state.showDialog && (
          <SweetAlert
            danger
            title="Oops!"
            showConfirm={false}
            timeout={2500}
            onConfirm={() => this.setState({ showDialog: false })}
          >
            Please enter correct Email
          </SweetAlert>
        )}
      </Row>
    );
  }

  renderLoader() {
    return (
      <div className="text-sm spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
}

export default ForgetPassword;
