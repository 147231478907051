import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import TimeHelper from '../../helpers/TimeHelper';
import ViewHelper from '../../helpers/ViewHelper';
import Checkbox from '../@vuexy/checkbox/CheckboxesVuexy';
import { Check, Edit2 } from 'react-feather';
import { Button } from 'reactstrap';
import ImageHelper from '../../helpers/ImageHelper';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ListDishTags = (props) => {
  const [t, i18n] = useTranslation('global');
  useEffect(() => {
    const lang2 = localStorage.getItem('lang');
    i18n.changeLanguage(lang2);
  }, []);

  const editDishTag = (id) => {
    props.editDishTag(id);
  };

  const columns = [
    {
      name: 'ID',
      // selector: 'tag_id',
      selector: (row) => row.tag_id,
      sortable: true,
    },
    {
      name: t('edit'),
      // selector: 'tag_id',
      selector: (row) => row.tag_id,
      format: (val) => {
        return (
          <Button
            outline
            className="btn-icon bg-white"
            color="primary"
            onClick={() => editDishTag(val.tag_id)}
          >
            <Edit2 color="var(--primary)" className="ml-0 p-0" size={10} />
          </Button>
        );
      },
    },
    {
      name: t('tag-name'),
      // selector: 'tag_name',
      selector: (row) => row.tag_name,
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.tag_name ? val.tag_name : t('not-available');
      },
    },
    {
      name: t('description'),
      // selector: 'tag_description',
      selector: (row) => row.tag_description,
      minWidth: '300px',
      format: (val) => {
        return val.tag_description ? val.tag_description : t('not-available');
      },
    },
  ];

  const renderDishTags = (dishtags, handleChange) => {
    if (!dishtags || dishtags.length === 0)
      return <h1>{t('no-app-dishtags-found')}</h1>;
    const tableData = {
      columns: columns,
      data: dishtags,
      print: false,
      export: false,
    };
    return (
      <DataTableExtensions {...tableData}>
        <DataTable
          noHeader
          pagination
          paginationRowsPerPageOptions={[25, 50, 100]}
          paginationPerPage={50}
          selectableRows
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: 'primary',
            icon: <Check className="vx-icon" size={12} />,
            label: '',
            size: 'sm',
          }}
          onSelectedRowsChange={handleChange}
          columns={columns}
          data={dishtags}
        />
      </DataTableExtensions>
    );
  };
  return renderDishTags(props.dishtags, props.handleChange);
};

export default ListDishTags;

// import React, { useState } from 'react';
// import { Edit2 } from 'react-feather';
// import { Button } from 'reactstrap';
// import { Table } from 'reactstrap';
// import Checkbox from '../@vuexy/checkbox/CheckboxesVuexy';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// const ListDishTags = (props) => {
//   const [selectedTagId, setSelectedTagId] = useState([]);
//   const editDishTag = (id) => {
//     props.editDishTag(id);
//     console.log(props);
//   };
//   const selectDishTag = (index) => {
//     let copyArr = [...props.dishtags];
//     if (copyArr[index]) {
//       copyArr[index].selected = !copyArr[index].selected;
//       console.log('copyarr', copyArr);
//     }
//     selectAllDishTags(copyArr);
//   };
//   const selectAllDishTags = () => {
//     props.dishtags.map((id) => selectedTagId.push(id));

//     console.log(props);
//   };

//   const renderDishTags = (dishtags, handleChange) => {
//     if (!dishtags || dishtags.length === 0)
//       return <h1>No App dishtags found.</h1>;
//     return (
//       <>
//         <DragDropContext onDragEnd={props.onDragEnd}>
//           <Droppable droppableId="droppable">
//             {(provided, snapshot) => (
//               <div ref={provided.innerRef}>
//                 <Table>
//                   <thead style={{ textAlign: 'center' }}>
//                     <tr>
//                       <th>
//                         <div
//                           className="col-md-12"
//                           style={{
//                             display: 'flex',
//                           }}
//                         >
//                           <Checkbox
//                             color="var(--primary)"
//                             className="ml-0 p-0"
//                             size={8}
//                             onClick={() => selectAllDishTags()}
//                           />
//                         </div>
//                       </th>
//                       <th>ID</th>
//                       <th>Edit</th>
//                       <th>Tag Name</th>
//                       <th>Description</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {dishtags.map((item, index) => (
//                       <Draggable
//                         key={`${item.tag_id}`}
//                         draggableId={`${item.tag_id}`}
//                         index={index}
//                       >
//                         {(provided, snapshot) => (
//                           <tr
//                             ref={provided.innerRef}
//                             {...provided.draggableProps}
//                             {...provided.dragHandleProps}
//                             className="drag-wrapper"
//                           >
//                             <td>
//                               <div
//                                 className="col-md-12"
//                                 style={{
//                                   display: 'flex',
//                                 }}
//                               >
//                                 <Checkbox
//                                   color="var(--primary)"
//                                   className="ml-0 p-0"
//                                   size={8}
//                                   onClick={() => selectDishTag(item.tag_id)}
//                                 />
//                               </div>
//                             </td>
//                             <td>
//                               <div
//                                 style={{
//                                   display: 'flex',
//                                   justifyContent: 'center',
//                                 }}
//                               >
//                                 {item.tag_id}
//                               </div>
//                             </td>
//                             <td>
//                               <div
//                                 className="col-md-12"
//                                 style={{
//                                   display: 'flex',
//                                   justifyContent: 'center',
//                                 }}
//                               >
//                                 <Button
//                                   outline
//                                   className="btn-icon"
//                                   color="primary"
//                                   onClick={() => editDishTag(item.tag_id)}
//                                 >
//                                   <Edit2
//                                     color="var(--primary)"
//                                     className="ml-0 p-0"
//                                     size={8}
//                                   />
//                                 </Button>
//                               </div>
//                             </td>
//                             <td>
//                               <div
//                                 style={{
//                                   display: 'flex',
//                                   justifyContent: 'center',
//                                 }}
//                               >
//                                 {item.tag_name}
//                               </div>
//                             </td>
//                             <td>
//                               <div
//                                 style={{
//                                   display: 'flex',
//                                   justifyContent: 'center',
//                                 }}
//                               >
//                                 {item.tag_description}
//                               </div>
//                             </td>
//                           </tr>
//                         )}
//                       </Draggable>
//                     ))}
//                   </tbody>
//                 </Table>
//               </div>
//             )}
//           </Droppable>
//         </DragDropContext>
//       </>
//     );
//   };
//   return renderDishTags(props.dishtags, props.handleChange);
// };

// export default ListDishTags;
