import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getAllTimeSlots,
  createTimeSlots,
  updateTimeSlots,
  updateSlotStatus,
  updateDayStatus,
  getEachDaySlot,
};

async function getAllTimeSlots() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TIMESLOTS.DEFAULT}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch tables");
}

async function createTimeSlots(newDayObj) {
  const { token } = LoginService.getToken();
  const response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TIMESLOTS.CREATE}`,
    {
      method: "POST",
      headers: {
        ...CONSTANTS.REQUEST_HEADERS,
        "X-ACCESS-TOKEN": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newDayObj),
    }
  );

  // return await response.json();
  if (response) return response;
  throw new Error(`Could not Send cAll waiter : ${response}`);
}
async function updateTimeSlots(newDayObj) {
  try {
    const { token } = LoginService.getToken();
    let response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TIMESLOTS.UPDATE_DAY}`,
      {
        method: "POST",
        headers: {
          ...CONSTANTS.REQUEST_HEADERS,
          "X-ACCESS-TOKEN": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newDayObj),
      }
    );
    if (response) return response;
    throw new Error(`Could not Send cAll waiter : ${response}`);
  } catch (error) {
    console.error("Error creating time slots:", error);
    throw error;
  }
}

async function updateSlotStatus(slot_id, status) {
  console.log("SLOTID-------- ", slot_id);
  console.log("STATUS--------------", status);

  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TIMESLOTS.UPDATE_SLOT(
      slot_id,
      status
    )}`,
    {
      method: "POST",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch tables");
}
async function updateDayStatus(time_id, status) {
  console.log("SLOTtime_idID ", time_id);
  console.log("STATUS", status);

  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TIMESLOTS.CHANGE_DAY_STATUS(
      time_id,
      status
    )}`,
    {
      method: "POST",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  if (response) return response;
  throw new Error(`Could not Send cAll waiter : ${response}`);
}

async function getEachDaySlot(day) {
  console.log("day ", day);

  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TIMESLOTS.GET_EACH_DAY_TIMESLOT(
      day
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch tables");
}

async function getAllSessionTables(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.SESSION_TABLE_STATUSES(
      locationId
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch tables");
}

async function getTable(locationId, tableId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.GET_TABLE(
      locationId,
      tableId
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch table");
}

async function changeTableStatus(locationId, tables) {
  let { token } = LoginService.getToken();
  tables = { table_ids: tables };
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.STATUSES(locationId)}`,
    {
      method: "POST",
      body: JSON.stringify(tables),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update tables");
}
async function deleteTable(locationId, tables, floor_id) {
  let { token } = LoginService.getToken();
  tables = { table_ids: tables };
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.DELETE_TABLES(
      locationId,
      floor_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(tables),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (!response?.error) return response;
  throw response.message;
}
async function changeTableStatusById(locationId, table) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.STATUSESID(
      locationId,
      table
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update table");
}
async function unLinkedTabFromTableById(locationId, table) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.UNLINKTAB(
      locationId,
      table
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update table");
}
async function generateQr(locationId, tables) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.QR(locationId)}`,
    {
      method: "POST",
      body: JSON.stringify(tables),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update tables");
}

async function getTabsLinkedTables(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.GET_TAB_LINKED_TABLES(
      loc_id
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update table");
}

async function sendNotificationToSelectedTabs(locationId, data) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${
      CONSTANTS.API_URL
    }${CONSTANTS.URLS.ADMIN.TABLES.SEND_NOTIFICATION_TO_SELECTED_TABS(
      locationId
    )}`,
    {
      method: "POST",
      body: data,
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );

  if (response.ok) {
    response = await response.json();
    return response;
  }
  throw new Error("Could not send notification to tables");
}
