import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Alert,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";

import { getAllPackages } from "../../services/PackageService";
import Swal from "sweetalert2";
import LocationService from "../../services/LocationService";
import LocationSelectHeaderPackage from "../shared/LocationSelectHeaderPackage";

import { useTranslation } from "react-i18next";
import { CheckSquare } from "react-feather";
import toast from "react-hot-toast";

const PERKS_TO_SHOW = 3; // Number of perks to show initially

const planDetails = [
  {
    plan: "Eater Package One",
    features: [
      { name: "E-Mail Support", included: false },
      { name: "Table Features", included: false },
      { name: "Help Center Access", included: true },
      { name: "Tablets Access", included: false },
    ],
  },
  {
    plan: "Eater Package Two",
    features: [
      { name: " E-Mail Support", included: true },
      { name: "Table Features", included: true },
      { name: "Help Center Access", included: false },
      { name: "Tablets Access", included: true },
    ],
  },
];

export default function AllPackages() {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const history = useHistory();

  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [finalData, setFinalData] = useState({});
  const [perksToShow, setPerksToShow] = useState(PERKS_TO_SHOW);
  const [showMorePerks, setShowMorePerks] = useState({}); // Track expanded perks for each package

  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const packagesData = await getAllPackages();
        console.log("PACKAGES DATA", packagesData.data);
        setPackages(packagesData.data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);
  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(delay);
  }, []);

  const handleBuyNowClick = (pkg) => {
    setSelectedPackage(pkg);
    setIsModalOpen(true);
  };
  const handleViewMorePerks = (pkg) => {
    setShowMorePerks((prevShowMorePerks) => ({
      ...prevShowMorePerks,
      [pkg.id]: !prevShowMorePerks[pkg.id],
    }));
  };
  const handleProductSelection = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      const updatedProducts = { ...prevSelectedProducts };

      if (
        !updatedProducts[product.id] ||
        !updatedProducts[product.id].selected
      ) {
        // If the product is not selected or user selects it, update its selection status
        updatedProducts[product.id] = {
          ...product,
          selected: true,
          quantity: 1, // Set the default quantity to 1
        };
      } else {
        // If the product is selected and user unselects it, remove it from selected products
        delete updatedProducts[product.id];
      }

      console.log("Selected Products:", updatedProducts);
      console.log("Selected Package:", selectedPackage);

      return updatedProducts;
    });
  };

  const handleCheckout = async () => {
    if (!locationData) {
      await Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please select a location",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    // Check if no product is selected
    const selectedProductsCount = Object.values(selectedProducts).filter(
      (product) => product.selected
    ).length;

    // Filter the required products that are not selected
    const requiredProductsNotSelected = Object.values(
      selectedPackage.ProductsInPackages
    ).filter(
      (product) =>
        product.required === 1 && !selectedProducts[product.id]?.selected
    );

    if (selectedProductsCount === 0) {
      await Swal.fire({
        icon: "error",
        title: "Oops",
        text: t("please-add-at-least-1-product"),
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    } else if (requiredProductsNotSelected.length > 0) {
      await Swal.fire({
        icon: "error",
        title: "Oops",
        text: t("required-product-not-selected"),
        timer: 2000,
        showConfirmButton: false,
      });

      return;
    }

    // let totalPrice = selectedPackage.estimate_price;
    let totalPrice = 0;

    Object.values(selectedProducts).forEach((product) => {
      if (product.selected) {
        // Add the product price * quantity to the total price
        totalPrice += product.price * (product.quantity || 1);
      }
    });

    const checkoutData = {
      package_id: selectedPackage.id,
      package_title: selectedPackage.title,
      price: totalPrice,
      currency: "USD",
      discount: 0,
      payment_method: "stripe",
      products: Object.values(selectedProducts).map((product) => ({
        // category_type: 'app',
        category_type:
          product.PackageProductsPricing.PackageProduct.category_tag,
        product_tag: product.PackageProductsPricing.PackageProduct.name,
        package_products_pricing_id: product.id,
        title: product.product_title,
        price: product.price,
        // price_per_type: 'License',
        price_per_type: product.PackageProductsPricing.price_per_type,
        physical_support: 0,
        quantity: product.quantity || 1,
        payment_mode: product.payment_mode,
      })),
      license_key: locationData.license_key,
    };

    console.log("Checkout Data:", checkoutData);

    setFinalData(checkoutData);

    // Instead of using history.push, you can pass the data as state
    history.push("/packages/checkout", { checkoutData, locationData });
  };

  const incrementQuantity = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      const updatedProducts = { ...prevSelectedProducts };
      const selectedProduct = updatedProducts[product.id];
      console.log(updatedProducts, "updatedProducts");
      console.log(selectedProduct, "selectedProduct");

      if (selectedProduct) {
        const updatedQuantity = (selectedProduct.quantity || 0) + 1;
        const maxQuantity = product.max_quantity; // Get the max_quantity from the product

        if (maxQuantity !== undefined && updatedQuantity > maxQuantity) {
          // If max_quantity is defined and the updated quantity exceeds it, show an error
          toast.error("Quantity exceeds the maximum limit.");
          return updatedProducts;
        }

        selectedProduct.quantity = updatedQuantity;
      } else {
        // If the product is not yet selected, set its quantity to 1
        updatedProducts[product.id] = {
          ...product,
          selected: true,
          quantity: 1,
        };
      }

      console.log("Selected Products:", updatedProducts);
      return updatedProducts;
    });
  };

  const decrementQuantity = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      const updatedProducts = { ...prevSelectedProducts };
      const selectedProduct = updatedProducts[product.id];

      if (
        selectedProduct &&
        selectedProduct.quantity > (selectedProduct.min_quantity || 1)
      ) {
        // If the product is selected and quantity is greater than 1, decrement the quantity
        selectedProduct.quantity -= 1;
      } else {
        // If the product is not selected or quantity is 1, remove it from selected products
        delete updatedProducts[product.id];
      }

      console.log("Selected Products:", updatedProducts);
      return updatedProducts;
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProducts({}); // Reset selectedProducts to an empty object
  };

  // Add this function to calculate the total amount //
  const calculateTotalAmount = () => {
    // let total = selectedPackage ? selectedPackage.estimate_price : 0;
    let total = 0;

    Object.values(selectedProducts).forEach((product) => {
      if (product.selected) {
        total += product.price * (product.quantity || 1);
      }
    });

    return total.toFixed(2);
  };
  const fetchLocationData = async () => {
    try {
      const locationData = await LocationService.getLocation();
      console.log("LOCATION", locationData);
      setLocationData(locationData);
    } catch (error) {
      await Swal.fire({
        icon: "error",
        title: t("error"),
        text: t("error-fetching-location-data"),

        showConfirmButton: true,
      });
    }
  };
  useEffect(() => {
    fetchLocationData();
  }, []);

  return (
    <>
      {isLoading ? ( // Check if isLoading is true
        <SpinnerComponent /> // Show the spinner while loading
      ) : (
        <Container>
          <LocationSelectHeaderPackage
            text={locationData ? locationData.name : t("no-location-found")}
          />

          <h1 className="text-center font-weight-bold mb-4 mt-3">
            {t("packages-0")}
          </h1>
          <Row>
            {packages?.map((pkg) => (
              <Col key={pkg.title} xs={12} sm={pkg.title ? 12 : 6} md={4}>
                <Card style={{ borderBottom: "4px solid green" }}>
                  <CardHeader
                    style={{
                      backgroundColor: "green", // Change to your desired background color
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center", // Center align the text
                      padding: "20px",
                    }}
                  >
                    <h4
                      style={{
                        color: "white",
                        margin: "0",
                        fontWeight: "bold",
                      }}
                    >
                      {pkg.title}
                    </h4>
                  </CardHeader>

                  <CardBody>
                    <div
                      className="text-center"
                      style={{ marginBottom: "10px" }}
                    >
                      <h1 style={{ fontWeight: "bold", marginBottom: "0" }}>
                        € {pkg.estimate_price}{" "}
                      </h1>
                      <span style={{ fontSize: "15px", marginTop: "0" }}>
                        (estimated)
                      </span>
                    </div>
                    <div>
                      <Button
                        style={{
                          fontWeight: "bold",
                          display: "block",
                          margin: "auto",
                          borderRadius: "40px",
                        }}
                        color="primary"
                        onClick={() => handleBuyNowClick(pkg)}
                      >
                        {t("buy-now")}{" "}
                      </Button>
                    </div>

                    <h5
                      style={{ fontWeight: "bold", marginTop: "20px" }}
                      align="center"
                    >
                      {t("included-in-this-package")}{" "}
                    </h5>
                    <hr />

                    {/* <ul style={{ display: 'block', marginRight: 'auto' }}>
                      {pkg.PerksInPackages.map((line) => (
                        <p key={line.id}>
                          <CheckSquare style={{ color: 'green' }} />{' '}
                          {line.title}
                        </p>
                      ))}
                    </ul> */}
                    <ul style={{ display: "block", marginRight: "auto" }}>
                      {pkg.PerksInPackages.slice(
                        0,
                        showMorePerks[pkg.id]
                          ? pkg.PerksInPackages.length
                          : PERKS_TO_SHOW
                      ).map((line) => (
                        <p key={line.id}>
                          <CheckSquare style={{ color: "green" }} />{" "}
                          {line.title}
                        </p>
                      ))}
                    </ul>
                    {pkg.PerksInPackages.length > PERKS_TO_SHOW && (
                      <Button
                        onClick={() => handleViewMorePerks(pkg)}
                        style={{
                          fontWeight: "bold",
                          display: "block",
                          margin: "auto",
                          borderRadius: "40px",
                        }}
                        color="primary"
                      >
                        {showMorePerks[pkg.id]
                          ? t("View Less")
                          : t("View More")}
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      )}
      {/* Plan Details Table */}
      {/* <Container>
        <h1 className="text-center">Explore Our Plan Details</h1>
        <Table>
          <thead>
            <tr>
              <th>Features</th>
              {planDetails.map((plan) => (
                <th key={plan.plan} align="center">
                  {plan.plan}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {planDetails[0].features.map((feature, index) => (
              <tr key={feature.name}>
                <td>{feature.name}</td>
                {planDetails.map((plan) => (
                  <td key={plan.plan} align="center">
                    <h1>{plan.features[index].included ? '✅' : '❌'}</h1>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container> */}
      <Modal isOpen={isModalOpen} toggle={handleCloseModal} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{selectedPackage?.title}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleCloseModal}
              style={{
                borderRadius: "50%",
                backgroundColor: "green",
                color: "white",
                border: "none",
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <span aria-hidden>&times;</span>
            </button>
          </div>
          {selectedPackage && (
            <div className="modal-body">
              <p>{selectedPackage.description}</p>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {selectedPackage.ProductsInPackages.map((product) => (
                  <li
                    key={product.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      fontSize: "15px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={!!selectedProducts[product.id]?.selected}
                      onChange={() => handleProductSelection(product)}
                    />
                    <span style={{ flex: "1" }}>
                      {" "}
                      {product.product_title}
                      {product.required === 1 && (
                        <span style={{ color: "red" }}> *</span>
                      )}
                    </span>
                    <span
                      style={{ marginRight: "10px" }}
                    >{`€${product.price}`}</span>
                    {selectedProducts[product.id]?.selected &&
                      product.multiple_quantity === 1 && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button
                            onClick={() => decrementQuantity(product)}
                            style={{
                              borderRadius: "50%",
                              backgroundColor: "green",
                              color: "white",
                              border: "none",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            -
                          </button>
                          <span style={{ margin: "0 5px" }}>
                            {selectedProducts[product.id]?.quantity || 1}
                          </span>
                          <button
                            onClick={() => incrementQuantity(product)}
                            style={{
                              borderRadius: "50%",
                              backgroundColor: "green",
                              color: "white",
                              border: "none",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            +
                          </button>
                        </div>
                      )}
                  </li>
                ))}
              </ul>
              <h6
                style={{
                  fontWeight: "bold",
                  textAlign: "right",
                  marginTop: "20px",
                }}
              >
                {t("total")}€{calculateTotalAmount()}
              </h6>
            </div>
          )}

          <div className="modal-footer">
            <Button color="primary" onClick={handleCheckout}>
              {t("checkout")}{" "}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
