import React, { Component } from "react";
import { connect } from "react-redux";

import { getLocation } from "../../redux/actions/locations";
import { getAll } from "../../redux/actions/options";

import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";

import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";

import LocationService from "../../services/LocationService";
import DnDListView from "../shared/DnDListView";
import {
  getMenuGroupedByCategory,
  saveCategory,
  updateCategoryPriority,
  delCategory,
} from "../../redux/actions/categories";
import CollapsibleCardComponent from "../shared/CollapsibleCardComponent";
import { ChevronDown, ChevronUp, Plus, FileText } from "react-feather";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import ViewHelper from "../../helpers/ViewHelper";
import {
  saveItem,
  updateMenuPriority,
  delItem,
  getDummyItem,
  getAllDishTags,
  getAllergicItems,
  uploadMenuMedia,
  deleteMenuMedia,
} from "../../redux/actions/menus";
import ScrollToTop from "../shared/ScrollToTop";
import CategoryModal from "../shared/forms/CategoryModal";
import MenuItemModal from "../shared/forms/MenuItemModal";
import MenuItemPage from "../shared/forms/MenuItemPage";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import MenuSearchForm from "../shared/searchForm/MenuSearchForm";
import ParamHelper from "../../helpers/ParamHelper";
import toast, { Toaster } from "react-hot-toast";
import { ExportToExcel } from "../../helpers/ExportToExcel";

import { withTranslation } from "react-i18next";
import i18n from "i18next";
import SelectHotel from "../shared/SelectHotel";
import ServiceSearchForm from "../shared/searchForm/ServiceSearchForm";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: false,
      selectedNutriOption: null,
      menu: [],
      newMenu: [],
      collapseItems: [],
      isCategoryModalOpen: false,
      selectedCategory: null,
      isMenuItemModalOpen: false,
      selectedItem: null,
      menuCategory: null,
      
      deletingCat: false,
      deletingItem: false,
      refreshing: false,
      isOutlet: false,
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
      },
      allDishTags: [],
      allAllergicItems: [],
      selectedAllergicItems: [],
      selectedDishTags: [],

      itemsArrayForRecom: [],
      nutriScoreOptions: [
        { value: "A", label: "A", backgroundColor: "green" },
        { value: "B", label: "B", backgroundColor: "lime" },
        { value: "C", label: "C", backgroundColor: "yellow" },
        { value: "D", label: "D", backgroundColor: "orange" },
        { value: "E", label: "E", backgroundColor: "red" },
      ],
      search: false,
    };
    this.endRef = React.createRef();
  }
  endRef = null;

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchMenu = true) {
    this.setState({ loading: initialize, refreshing: true });
    let locationUpdated = false;
    let collapseItems = this.state.collapseItems.slice();
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      this.props.getAll(loc_id);
      await this.props.getLocation(loc_id);
      locationUpdated = true;
      collapseItems = [];
    }
    if (fetchMenu) {
      await this.props.getDummyItem(loc_id);
      await this.props.getAllDishTags(loc_id);
      await this.props.getAllergicItems(loc_id);
      await this.getMenu(loc_id);
    }

    const customisedMenu = [];
    const customisedTags = [];
    const customisedAllergicItems = [];
    const {
      props: { menu, dishTags, allAllergicItems },
    } = this;


    for (let i = 0; i < menu?.length; i++) {
      for (let j = 0; j < menu[i]?.Menus?.length; j++) {
        let numberOfOption = 0, flag = true;
        for (let k = 0; k < menu[i]?.Menus[j]?.MenuOptions?.length; k++) {
          if (menu[i] && menu[i].Menus[j] && menu[i].Menus[j].MenuOptions[k] && !menu[i].Menus[j].MenuOptions[k]?.option_menu_type && (menu[i].Menus[j].MenuOptions[k]?.option_menu_type !== "kitchen" && menu[i].Menus[j].MenuOptions[k]?.option_menu_type !== "bar" && menu[i].Menus[j].MenuOptions[k]?.option_menu_type !== "dessert")) {
            numberOfOption++;
            flag = false;
          }
        }
        menu[i].Menus[j].numberOfOptions = numberOfOption;
        menu[i].Menus[j].flag = flag;
      }
      customisedMenu.push({
        id: menu[i]?.category_id,
        title: menu[i]?.name,
        ...menu[i],
      });
      if (locationUpdated) {
        collapseItems.push(menu[i].category_id);
      }
    }

    for (let i = 0; i < dishTags?.length; i++) {
      customisedTags.push({
        id: dishTags[i].tag_id,
        title: dishTags[i].tag_name,
        ...dishTags[i],
      });
    }

    for (let i = 0; i < allAllergicItems?.length; i++) {
      customisedAllergicItems.push({
        id: allAllergicItems[i].item_id,
        title: allAllergicItems[i].item_name,
        ...allAllergicItems[i],
      });
    }

    // selectedDishTags

    let selectedItem = this.state.selectedItem;
    if (selectedItem && selectedItem?.menu_id) {
      const cat = customisedMenu.filter(
        (c) => c.id === this.state.menuCategory.value
      )[0];
      const it = cat.Menus.filter(
        (mo) => mo.menu_id === selectedItem.menu_id
      )[0];
      selectedItem = it;
    }

    this.setState({
      deletingCat: false,
      deletingItem: false,
      loading: false,
      menu: customisedMenu,
      collapseItems,
      allDishTags: customisedTags,
      location: JSON.parse(JSON.stringify(this.props.location)),
      selectedItem,
      refreshing: false,
      isOutlet: this.props.location.is_outlet,
      allAllergicItems: customisedAllergicItems,
    });
    if (!this.state.search) {
      this.setState({ newMenu: customisedMenu });
    }
  }

  async getMenu(loc_id) {
    return this.props.getMenuGroupedByCategory(loc_id);
  }

  onBlur() {
    if (this.state.location)
      this.setState({ selectLocation: !this.state.selectLocation });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  toggleCollapse = (collapseID) => {
    let index = this.state.collapseItems.indexOf(collapseID);
    if (index >= 0) {
      let items = this.state.collapseItems;
      items.splice(index, 1);
      this.setState({ collapseItems: items });
    } else {
      let items = this.state.collapseItems;
      items.push(collapseID);
      this.setState({ collapseItems: items });
    }
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = ViewHelper.reorder(
      this.state.menu,
      result.source.index,
      result.destination.index
    );
    this.setState({
      menu: items,
    });
    const itemsToSave = ParamHelper.getCategoriesForBulkUpdate(
      items,
      this.state.location.loc_id
    );
    this.props.updateCategoryPriority(this.props.location.loc_id, itemsToSave);
  };

  async toggleCategoryModal(category = null) {
    let {
      state: { isCategoryModalOpen },
    } = this;
    const selectedCategory = category;
    isCategoryModalOpen = !isCategoryModalOpen;
    return this.setState({ selectedCategory, isCategoryModalOpen });
  }

  async saveCategory(category) {
    if (category.name === "") return;

    let isNew = false;

    if (!category.priority) {
      category.priority = this.state.menu.length + 1;
      isNew = true;
    }
    if (category.Menus) delete category.Menus;

    const fileInput = document.querySelector("#categoryFileInput");
    const formData = new FormData();
    if (fileInput.files.length > 0) {
      formData.append("file", fileInput.files[0]);
      category.image = "";
      category.img = "";
    }
    formData.append("categoryItem", JSON.stringify(category));
    await this.props.saveCategory(this.state.location.loc_id, formData);
    await this.toggleCategoryModal();
    await this.refreshData(this.state.location.loc_id, true);

    if (isNew) {
      let {
        state: { collapseItems, menu },
      } = this;
      collapseItems.push(menu[menu.length - 1].category_id);
      await this.setState({ collapseItems });
      setTimeout(
        () => this.endRef.current.scrollIntoView({ behavior: "smooth" }),
        200
      );
    }
  }

  async toggleMenuModal(category = null, item = null) {
    let {
      state: { isMenuItemModalOpen },
    } = this;
    isMenuItemModalOpen = !isMenuItemModalOpen;
    if (!isMenuItemModalOpen) {
      return this.setState({
        selectedItem: null,
        isMenuItemModalOpen,
        menuCategory: null,
      });
    }
    const selectedItem = item
      ? item
      : { ...this.props.dummyItem, MenuOptions: [] };

    const menuCategory = { value: category?.id, label: category?.name };
    const customisedSelectedTags = [];
    const customisedSelectedItems = [];
    const customisedSelectedNutri = null;
    for (let b = 0; b < item?.MenuTags.length; b++) {
      customisedSelectedTags.push({
        value: item?.MenuTags[b].tag_id,
        label: item?.MenuTags[b].Dish_Tag?.tag_name,
      });
    }

    for (let b = 0; b < item?.MenuAllergyItems.length; b++) {
      customisedSelectedItems.push({
        value: item?.MenuAllergyItems[b].item_id,
        label: item?.MenuAllergyItems[b].Allergy_Item.item_name,
      });
    }

    let itemsArrayForRecomOptions = [];
    if (item != null)
      this.state.menu.map((menuItem) => {
        if (menuItem?.Menus) {
          menuItem.Menus.map((singleMenuItem) => {
            if (singleMenuItem.menu_id !== item.menu_id) {
              itemsArrayForRecomOptions.push({
                label: singleMenuItem.menu_name,
                value: singleMenuItem.menu_id,
              });
            }
          });
        }
      });
    return this.setState({
      selectedItem,
      isMenuItemModalOpen,
      menuCategory,
      selectedDishTags: customisedSelectedTags,
      selectedAllergicItems: customisedSelectedItems,
      itemsArrayForRecom: itemsArrayForRecomOptions,
    });
  }

  async saveItem(item) {
    if (item.menu_name === "" || item.menu_price == 0) return;
    item.menu_category_id = item.menu_category.value;
    delete item.img;
    if (!item.menu_priority) {
      let menus = this.state.menu.filter((m) => m.id === item.menu_category_id);
      menus = menus.length > 0 ? menus[0].Menus : null;
      const size = menus ? menus.length : 0;
      item.menu_priority = size + 1;
    }
    const fileInput = document.querySelector("#fileInput");
    const formData = new FormData();
    formData.append("file", fileInput.files[0]);
    formData.append("menuItem", JSON.stringify(item));
    await this.props.saveItem(this.state.location.loc_id, formData);
    await this.toggleMenuModal();
    await this.refreshData(this.state.location.loc_id, false);
  }

  async saveUploadMedia(item) {
    const fileInput = document.querySelector("#uploadMediaFileInput");
    const formData = new FormData();
    formData.append("file", fileInput.files[0]);

    try {
      await this.props.uploadMenuMedia(
        this.state.location.loc_id,
        item.menu_id,
        formData
      );
      toast.success(this.props.t("menu-image-upload-successful"));
      await this.toggleMenuModal();
      setTimeout(() => {
        this.refreshData(this.state.location.loc_id, true);
      }, 1500);
    } catch (error) {
      toast.error(this.props.t("failed-to-upload-menu-image"));
      console.error(error);
    }
  }

  async deleteMenuMedia(menu_media_id) {
    await this.props.deleteMenuMedia(this.state.location.loc_id, menu_media_id);
    await this.toggleMenuModal();
    await this.refreshData(this.state.location.loc_id, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.menu) !== JSON.stringify(this.props.menu) &&
      this.state.location &&
      !this.state.refreshing
    ) {
      this.refreshData(this.state.location.loc_id, false, false);
    }
  }

  async deleteCategory(category) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        this.setState({ confirmationDialog: defaultState, deletingCat: true });
        await this.props.delCategory(
          this.state.location.loc_id,
          category.category_id
        );
        // await this.toggleCategoryModal();
        await this.refreshData(this.state.location.loc_id, false);
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState, deletingCat: false });
      },
    };
    this.setState({ confirmationDialog });
  }

  async deleteMenu(menu, showtoggle = true) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        this.setState({ confirmationDialog: defaultState, deletingItem: true });
        await this.props.delItem(this.state.location.loc_id, menu.menu_id);
        this.setState({ isMenuItemModalOpen: false });
        // await this.toggleMenuModal();
        await this.refreshData(this.state.location.loc_id, false);
      },
      rejectCallback: () => {
        this.setState({
          confirmationDialog: defaultState,
          deletingItem: false,
        });
      },
    };

    this.setState({ confirmationDialog });
  }

  async toggleItemStatus(item) {
    const menuItem = {
      menu_id: parseInt(item.id),
      menu_status: (item.status + 1) % 3,
    };
    const formData = new FormData();
    formData.append("menuItem", JSON.stringify(menuItem));
    this.props.saveItem(this.state.location.loc_id, formData);
    let menu = JSON.parse(JSON.stringify(this.state.menu));
    let category = menu.filter(
      (c) => c.category_id === item.menu_category_id
    )[0];
    let m = category.Menus.filter((i) => i.menu_id === menuItem.menu_id)[0];
    m.menu_status = menuItem.menu_status;
    this.setState({ menu });
  }

  async toggleCatStatus(item) {
    const catItem = {
      category_id: item.id,
      status: (parseInt(item.status) + 1) % 2,
    };
    this.props.saveCategory(this.state.location.loc_id, catItem);
    let menu = JSON.parse(JSON.stringify(this.state.menu));
    let category = menu.filter((c) => c.category_id === item.id)[0];
    category.status = catItem.status;
    this.setState({ menu });
  }

  renderCategoryCards() {
    const {
      props: { loading },
    } = this;

    if (loading) {
      return <SpinnerComponent />;
    }
    return (
      <ListGroup id="list-group-dnd">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                {this.state.menu.map((item, index) => (
                  <Draggable
                    key={`${item.id}`}
                    draggableId={`${item.id}`}
                    index={index}
                    isDragDisabled={this.state.isOutlet}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="drag-wrapper"
                      >
                        <ListGroupItem className="no-padding no-border">
                          <CollapsibleCardComponent
                            deleteCategory={this.deleteCategory.bind(this)}
                            isOutlet={this.state.isOutlet}
                            toggleStatus={this.toggleCatStatus.bind(this)}
                            toggleMenuModal={this.toggleMenuModal.bind(
                              this,
                              item
                            )}
                            toggleCategoryModal={this.toggleCategoryModal.bind(
                              this
                            )}
                            dragHandleProps={provided.dragHandleProps}
                            item={item}
                            toggleCollapse={this.toggleCollapse.bind(this)}
                            isOpen={
                              this.state.collapseItems.indexOf(item.id) >= 0
                            }
                            loading={this.state.loading}
                          >
                            <DnDListView
                              isOutlet={this.state.isOutlet}
                              toggleMenuModal={this.toggleMenuModal.bind(
                                this,
                                item
                              )}
                              updateMenuPriority={this.props.updateMenuPriority}
                              menu={item.Menus}
                              location={this.props.location}
                              toggleStatus={this.toggleItemStatus.bind(this)}
                              deleteMenu={this.deleteMenu.bind(this)}
                              deleting={this.state.deletingItem}
                            />
                          </CollapsibleCardComponent>
                        </ListGroupItem>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ListGroup>
    );
  }

  closeAll() {
    this.setState({ collapseItems: [] });
  }

  openAll() {
    let collapseItems = this.state.menu.map((item) => item.id);
    this.setState({ collapseItems });
  }

  showConfirmationDialog(accept, reject) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        reject();
        this.setState({ confirmationDialog: defaultState });
      },
    };
    this.setState({ confirmationDialog });
  }

  async searchMenuData(searchItem, resetform) {
    if (resetform) {
      this.setState({ loading: true });
      this.setState({ search: false });
    } else {
      this.setState({ search: true });
    }
    searchItem = new URLSearchParams(searchItem).toString();

    await this.props.getMenuGroupedByCategory(
      this.props.location.loc_id,
      searchItem
    );

    this.setState({ loading: false, search: true });
  }

  async handleNutriScoreChange(selectedOption) {
    this.setState({ selectedNutriOption: selectedOption.value });
  }

  async exportMenu() {
    let exportMenuData = [];
    let exportMenuHeader = [
      this.props.t("category-id"),
      this.props.t("category-name"),
      this.props.t("category-image"),
      this.props.t("item-id"),
      this.props.t("item-name"),
      this.props.t("item-photo"),
      this.props.t("item-price"),
      this.props.t("item-tags"),
      this.props.t("item-type"),
      this.props.t("item-highlighted"),
      this.props.t("variants"),
    ];
    if (this.state.menu.length > 0) {
      this.state.menu.map((menu) => {
        if (menu.Menus.length > 0) {
          menu.Menus.map((item) => {
            let menu_Tags = "";
            let op = "";
            if (item.MenuTags.length > 0) {
              item.MenuTags.map((tags) => {
                menu_Tags += op;
                menu_Tags += tags.Dish_Tag.tag_name;
                op = ",";
              });
            }
            exportMenuData.push({
              category_id: menu.category_id,
              category_name: menu.title,
              category_image: menu.image,
              menu_id: item.menu_id,
              menu_name: item.menu_name,
              menu_photo: item.menu_photo,
              menu_price: item.menu_price,
              menu_tags: menu_Tags,
              menu_type: item.menu_type,
              highlighted: item.highlighted
                ? this.props.t("highlighted")
                : this.props.t("not-highlighted"),
              variants: item.MenuOptions.length,
            });
          });
        }
      });
      ExportToExcel({
        data: exportMenuData,
        header: exportMenuHeader,
        fileName: "Session-History",
      });
    }
  }

  render() {
    const { t } = this.props;
    // const t = () => {};

    const {
      props: { location, dishTags },
    } = this;
    const {
      state: { selectLocation, loading, menu },
    } = this;
    // if (loading) return <SpinnerComponent />;

    const locationName = location ? location.name : "";
    // if (selectLocation)
    //   return (
    //     <SelectLocation
    //       onBack={this.toggleLocation.bind(this)}
    //       onSelect={this.onSelect.bind(this)}

    //     />
    //   );
    const { pathname } = this.props.history.location;

    if (pathname === "/locations/menu" && selectLocation) {
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );
    } else if (pathname === "/locations/services" && selectLocation) {
      return (
        // Render the component for /locations/services
        <SelectHotel
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );
    }

    return (
      <div className="location">
        <Toaster position="top-right" />
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex align-items-center">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
            {!this.state.isOutlet && (
              <div className="d-flex flex-nowrap overflow-auto">
                <Button
                  outline
                  className="font-weight-bold custom-padding primary-2-border  ml-1"
                  color={"primary"}
                  size="md"
                  onClick={() => this.toggleCategoryModal()}
                >
                  <Plus size={20} />{" "}
                  <span style={{ whiteSpace: "nowrap" }}>
                    {location?.is_hotel === 1
                      ? "Add Category Service"
                      : t("add-category")}
                  </span>
                </Button>
                <Button
                  outline
                  className="font-weight-bold custom-padding primary-2-border  ml-1"
                  color={"primary"}
                  size="md"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleMenuModal();
                  }}
                >
                  <Plus size={20} />{" "}
                  <span style={{ whiteSpace: "nowrap" }}>
                    {location?.is_hotel === 1
                      ? "Add Item Service"
                      : t("add-item")}
                  </span>
                </Button>
                {location?.is_hotel === 1
                      ? null
                      : <Button
                      outline
                      className="font-weight-bold custom-padding primary-2-border ml-1"
                      color={"primary"}
                      size="md"
                      onClick={(e) => {
                        this.props.history.replace("/menu/mobile-preview");
                      }}
                    >
                      <span>{t("view-mobile-menu")}</span>
                      
                    </Button>}
                
                
                {/* <Button
                  outline
                  color={'primary'}
                  className="font-weight-bold custom-padding primary-2-border ml-1"
                  size="md"
                  onClick={() => {
                    const dataToSend = {
                      categories: this.state.menu,
                      location: location,
                      nutriScoreOptions: this.state.nutriScoreOptions,
                      allDishTags: this.state.allDishTags,
                      allAllergicItems: this.state.allAllergicItems,
                      randomcustomisedTags: dishTags,
                    };

                    this.props.history.push({
                      pathname: '/menu/creation',
                      state: dataToSend,
                      saveItem: this.saveItem.bind(this), // Include saveItem here
                    });
                  }}
                >
                  <span>Menu Creation Page</span>
                </Button> */}
              </div>
            )}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ChevronUp
            size={30}
            color="white"
            className="cursor-pointer bg-dark mr-1"
            onClick={this.closeAll.bind(this)}
          />
          <ChevronDown
            size={30}
            color="white"
            className="cursor-pointer bg-dark"
            onClick={this.openAll.bind(this)}
          />
          <FileText
            size={30}
            color="black"
            className="cursor-pointer ml-1"
            onClick={() => this.exportMenu()}
          />
        </div>
        {this.props.location?.is_hotel ? (
  <ServiceSearchForm
    categories={this.state.newMenu}
    searchMenuData={(searchItem, resetform) =>
      this.searchMenuData(searchItem, resetform)
    }
    search={this.state.search}
  />
) : (
  <MenuSearchForm
    categories={this.state.newMenu}
    searchMenuData={(searchItem, resetform) =>
      this.searchMenuData(searchItem, resetform)
    }
    search={this.state.search}
  />
)}

      
      
        {this.renderCategoryCards()}
        <CategoryModal
          deleting={this.state.deletingCat}
          deleteCategory={this.deleteCategory.bind(this)}
          saveCategory={this.saveCategory.bind(this)}
          isOpen={this.state.isCategoryModalOpen}
          category={this.state.selectedCategory}
          isHotel={location?.is_hotel}
          toggleModal={this.toggleCategoryModal.bind(this)}
        />
        <MenuItemModal
          showConfirmationDialog={this.showConfirmationDialog.bind(this)}
          deleting={this.state.deletingItem}
          deleteMenu={this.deleteMenu.bind(this)}
          selectedCategory={this.state.menuCategory}
          categories={this.state.menu}
          saveItem={this.saveItem.bind(this)}
          location={location}
          isOpen={this.state.isMenuItemModalOpen}
          item={this.state.selectedItem}
          nutriScoreOptions={this.state.nutriScoreOptions}
          handleNutriScoreChange={this.handleNutriScoreChange.bind(this)}
          selectedNutriOption={this.state.selectedNutriOption}
          isHotel={location?.is_hotel}
          toggleModal={this.toggleMenuModal.bind(this)}
          allDishTags={this.state.allDishTags}
          allAllergicItems={this.state.allAllergicItems}
          selectedAllergicItems={this.state.selectedAllergicItems}
          selectedDishTags={this.state.selectedDishTags}
          randomcustomisedTags={dishTags}
          saveUploadMedia={this.saveUploadMedia.bind(this)}
          deleteMenuMedia={this.deleteMenuMedia.bind(this)}
          itemsArrayForRecom={this.state.itemsArrayForRecom}
        />

        <ConfirmationModal
          isOpen={this.state.confirmationDialog.show}
          accept={this.state.confirmationDialog.acceptCallback.bind(this)}
          reject={this.state.confirmationDialog.rejectCallback.bind(this)}
        />
        <ScrollToTop />
        <div ref={this.endRef} id="emptyEndDiv"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    categories: {
      categoryMenu: { data: menuData, loading: menuLoading },
    },
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
    menu: {
      dummyItem: { data: dummyItem, loading: dummyItemLoading },
      allDishTags: { data: allDishTags, loading: dishTagsLoading },
      allAllergicItems: { data: allAllergicItems, loading: allergicItem },
    },
  } = state;
  return {
    dummyItem,
    menu: menuData,
    location: locationData,
    loading:
      menuLoading ||
      locationLoading ||
      dummyItemLoading ||
      dishTagsLoading ||
      allergicItem,
    dishTags: allDishTags,
    allAllergicItems: allAllergicItems,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getLocation,
    getMenuGroupedByCategory,
    updateCategoryPriority,
    updateMenuPriority,
    saveCategory,
    saveItem,
    delCategory,
    delItem,
    getAll,
    getDummyItem,
    getAllDishTags,
    getAllergicItems,
    uploadMenuMedia,
    deleteMenuMedia,
  })(Menu)
);
