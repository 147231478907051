import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import { getAllOwnerStaff, changeStaffStatus } from "../../redux/actions/staff";
import "../../assets/scss/pages/page-pricing.scss";
import ListStaff from "./ListStaff";
import { Button } from "reactstrap";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class CustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: "",
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    await this.props.getAllOwnerStaff();
  }

  handleChange = (state) => {
    let staff = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      staff.push(state.selectedRows[i].staff_id);
    }
    this.setState({ staff: staff });
  };

  async updateAccountStatus() {
    if (this.state.staff.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.props.changeStaffStatus(this.state.staff);
        },
        null,
        this.props.t("are-you-sure-you-want-to-delete-this-account"),
        this.props.t("accounts")
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t("please-select-atleast-one-account-for-change-status"),
        this.props.t("warning")
      );
    }
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }

  editStaff(id) {
    this.props.history.push("/staff/edit/" + id);
  }
  assignRole(id) {
    this.props.history.push("/staff/assign/" + id);
  }
  render() {
    const { t } = this.props;
    const {
      props: { staff, loading },
    } = this;
    const {
      state: { confirmationDialog, errorDialog },
    } = this;
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div id="pricing-table">
          {!loading && staff ? (
            <ListStaff
              staff={staff}
              handleChange={this.handleChange.bind(this)}
              editStaff={this.editStaff.bind(this)}
              assignRole={this.assignRole.bind(this)}
              viewDetailbtn={true}
            />
          ) : null}
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t("cancel")}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={t("ok")}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    staff: {
      allstaff: { data: staff, loading: loading },
    },
  } = state;

  return { staff, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getAllOwnerStaff,
    changeStaffStatus,
  })(CustomerList)
);
