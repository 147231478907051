import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  Button,
  Form,
  Label,
  Media,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { FormGroup } from "react-bootstrap";
import momentTimezone from "moment-timezone";
import TimezonePicker from "react-bootstrap-timezone-picker";
import { serialize } from "php-serialize";

import toast, { Toaster } from "react-hot-toast";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import Text from "../shared/fields/Text";
import PrependText from "../shared/fields/PrependText";
import Hidden from "../shared/fields/Hidden";
import Select from "../shared/fields/Select";
import SearchSelect from "../shared/fields/SearchSelect";
import Checkbox from "../shared/fields/Checkbox";
import TextArea from "../shared/fields/TextArea";
import {
  getCurrencies,
  getCategories,
} from "../../redux/actions/configurations";
import { updateLocation, createLocation } from "../../redux/actions/locations";
import LocationService from "../../services/LocationService";
import ViewHelper from "../../helpers/ViewHelper";
import ValidationHelper from "../../helpers/ValidationHelper";
import CONSTANTS from "../../constants";
import { getAllCountries } from "../../redux/actions/currency";
import Toggle from "react-toggle";

import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { Image, X } from "react-feather";
import ImageHelper from "../../helpers/ImageHelper";

import { withTranslation } from "react-i18next";
import i18n from "i18next";
import Autocomplete from "react-google-autocomplete";

class HotelForm extends Component {
  fileRef = React.createRef();
  state = {
    timezoneLabel: null,
    timezone: "Asia/Shanghai +08:00",
    img: null,
    deletePhoto: false,
  };

  handlePhotoChange(e) {
    if (e.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (ev) => {
        this.setState({ img: ev.target.result, deletePhoto: false });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.props.getCurrencies();
    this.props.getCategories();
    this.getCountries();
  }

  async getCountries() {
    return this.props.getAllCountries();
  }

  async saveLocation(values) {
    console.log("VALUES", values);
    let asyncCall = this.props.createLocation;
    values.timezone = this.state.timezone;
    const formData = new FormData();

    // values.currency_id = 0;
    values.wait_time = 0;
    values.category_name = "hotel";
    values.is_hotel = 1;
    const fileInput = document.querySelector("#fileInput");
    formData.append("file", fileInput.files[0]);
    values.deletePhoto = this.state.deletePhoto;
    if (values.loc_id) {
      values.options = values.options
        ? serialize(values.options)
        : values.options;
      asyncCall = this.props.updateLocation;
    } else {
      const outputCircle = [];
      outputCircle.push({
        center: { lat: +values.latitude, lng: +values.longitude },
      });
      outputCircle.push({ radius: 1000 * 11 });
      let tempValues = CONSTANTS.DUMMY_VALUES;
      values.options = tempValues;
    }
    formData.append("locationItem", JSON.stringify(values));
    formData.loc_id = values.loc_id;
    await asyncCall(formData);
    toast.success("Hotel data saved successfully");
    if (this.props.onSuccess) this.props.onSuccess();
  }

  async loadParentOptions(term, callback) {
    if (term.length < 3) return callback([]);
    try {
      let locations = await LocationService.getLocations(term);
      locations = locations.filter((location) => location.is_parent);
      callback(ViewHelper.transformedLocations(locations));
    } catch (e) {
      console.log(e);
      alert(this.props.t("could-not-load-locations"));
    }
  }

  handleChange(e) {
    const diff = momentTimezone.tz(e).format("Z");
    this.setState({ timezoneLabel: e, timezone: `${e} ${diff}` });
  }

  renderToggleSwitch = ({ input, label }) => (
    <FormGroup>
      <Label for={input.name}>{label}</Label>
      <div>
        <Toggle
          checked={input.value}
          type="switch"
          id={input.name}
          {...input}
          className="switch-danger align-self-baseline"
        />
      </div>
    </FormGroup>
  );
  render() {
    const { t } = this.props;
    const {
      location,
      countries,
      currencies,
      categories,
      currentValues,
      loading,
      handleSubmit,
      submitting,
    } = this.props;
    console.log("LOCATION", location);
    if (loading) return <SpinnerComponent />;
    if (currencies && location && categories) {
      let parent = location.Parent;
      let timezoneLabel = this.state.timezoneLabel
        ? this.state.timezoneLabel
        : location.timezone
        ? location.timezone.split(" ")[0]
        : this.state.timezone.split(" ")[0];
      let currencyOptions = ViewHelper.toOptions(
        currencies,
        {
          idColumn: "currency_id",
          valueColumns: ["currency_code", "currency_name"],
          joiner: " - ",
        },
        { allowEmpty: true }
      );
      let countriesOptions = ViewHelper?.toOptions(
        countries,
        { idColumn: "country", valueColumns: ["country_name"] },
        { allowEmpty: true }
      );
      let categoryOptions = ViewHelper.toOptions(
        categories,
        {},
        { allowEmpty: true }
      );
      let parentValue = parent
        ? { loc_id: parent.loc_id, label: `${parent.loc_id} - ${parent.name}` }
        : {};
      const image =
        this.state.img || this.state.deletePhoto
          ? this.state.img
          : ImageHelper.getImageURL(location.img);
      console.log("location", location);
      return (
        <>
          <Form onSubmit={handleSubmit(this.saveLocation.bind(this))}>
            <Card className="border-bottom rounded-0 shadow-none">
              <CardHeader>
                <strong>{"Hotel Settings"}</strong>
              </CardHeader>
              <CardBody>
                <Field name="loc_id" component={Hidden} />
                <Field
                  name="name"
                  component={Text}
                  label={"Hotel Name"}
                  validate={ValidationHelper.required}
                />
                <Field
                  name="location_email"
                  component={Text}
                  label={t("email")}
                  type="email"
                  validate={ValidationHelper.isValidEmail}
                />
                <Row>
                  <Col md="6">
                    <Field
                      name="latitude"
                      component={Text}
                      label={t("latitude")}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="longitude"
                      component={Text}
                      label={t("longitude")}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                </Row>
                {/* <Field
                  name="slug"
                  component={PrependText}
                  label="Slug"
                  validate={ValidationHelper.required}
                  prepend={`${CONSTANTS.WEB_URL}/`}
                /> */}
                <Field
                  name="formattedAddress"
                  component={Text}
                  label={t("address")}
                  validate={ValidationHelper.required}
                />
                <Row>
                  <Col md="4">
                    <Field
                      name="location_telephone"
                      component={Text}
                      label={t("phone-number")}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="whatsapp_number"
                      component={Text}
                      label={t("whatsapp-number")}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="postal_code"
                      component={Text}
                      label={t("postal-code")}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Field
                      name="city"
                      component={Text}
                      label={t("city")}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="state"
                      component={Text}
                      label={t("state")}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="country"
                      component={Select}
                      label={t("country")}
                      validate={ValidationHelper.required}
                      options={countriesOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Field
                      name="timezone"
                      component={() => {
                        return (
                          <FormGroup>
                            <Label>{t("timezone")}</Label>
                            <TimezonePicker
                              absolute={false}
                              defaultValue={timezoneLabel}
                              placeholder={t("select-timezone")}
                              onChange={this.handleChange.bind(this)}
                              className="col-12 p-0"
                            />
                          </FormGroup>
                        );
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="currency_id"
                      component={Select}
                      label={t("currency")}
                      validate={ValidationHelper.required}
                      options={currencyOptions}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="shadow-none">
              <CardHeader>
                <strong>{t("settings")}</strong>
              </CardHeader>
              <CardBody>
                <Row>
                  {/* <Col md="4">
                    <Field
                      name="currency_id"
                      component={Select}
                      label={t('currency')}
                      validate={ValidationHelper.required}
                      options={currencyOptions}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="decimal_places"
                      component={Text}
                      label={t('decimals')}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="tax_percent"
                      component={Text}
                      label={t('tax-rate')}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="tax_label"
                      component={Text}
                      label={t('tax-label')}
                      validate={ValidationHelper.required}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="wait_time"
                      component={Text}
                      label={t('wait-time')}
                      validate={ValidationHelper.required}
                    />
                  </Col> */}
                  <Col md="4">
                    <Field
                      name="auto_close_time"
                      component={Text}
                      label={t("auto-close-time")}
                      validate={ValidationHelper.nonNegativeNumber}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="otp_refresh_time"
                      component={Text}
                      label={t("otp-refresh-time")}
                      validate={ValidationHelper.nonNegativeNumber}
                    />
                  </Col>
                  <Col md="4">
                    <Field
                      name="use_otp"
                      component={this.renderToggleSwitch}
                      label={t("Enable OTP")}
                    />
                  </Col>
                </Row>
                {/* <Field
                  name="pixel_trackers"
                  component={TextArea}
                  label="Pixel Tracking"
                  helpText={
                    "Please paste scripts for all your pixel trackers here. Append in new line to add more."
                  }
                /> */}
                {/* {currentValues && !currentValues.is_parent && (
                  <Field
                    name="parent_id"
                    component={SearchSelect}
                    label="Parent"
                    loadOptions={this.loadParentOptions.bind(this)}
                    defaultValue={parentValue}
                  />
                )} */}
                {/* {currentValues && !currentValues.parent_id && (
                  <Field name="is_parent" component={Checkbox} label="Parent" />
                )} */}
                <Field
                  name="visible"
                  component={Checkbox}
                  label={t("visible")}
                />
                {/* <Field
                  name="location_status"
                  component={Checkbox}
                  label="Is Restaurant"
                /> */}
                {location?.loc_id ? (
                  <FormGroup>
                    <Label for="photoDiv">Logo:</Label>
                    <div
                      tabIndex="0"
                      id="photoDiv"
                      className="display-flex flex-column border border-2 border-light cursor-pointer"
                      onKeyUp={(e) =>
                        e.keyCode === 13 && !e.ctrlKey
                          ? this.fileRef.current.click()
                          : null
                      }
                      onClick={() => this.fileRef.current.click()}
                      style={{ position: "relative", height: 120, width: 150 }}
                    >
                      <div style={{ textAlign: "-webkit-center" }}>
                        {image ? (
                          <div>
                            <div
                              style={{
                                position: "absolute",
                                top: -10,
                                right: -10,
                              }}
                            >
                              <Button.Ripple
                                onClick={() => {
                                  this.setState({
                                    img: null,
                                    deletePhoto: true,
                                  });
                                  this.fileRef.current.value = null;
                                }}
                                style={{ width: 20, height: 20 }}
                                className="m-0 p-0"
                                color="danger"
                              >
                                <X size={14} />
                              </Button.Ripple>
                            </div>
                            <div
                              style={{ position: "absolute", bottom: 3 }}
                              className="full-width"
                            >
                              <Button.Ripple
                                className="p-25"
                                color="primary"
                                onClick={() => this.fileRef.current.click()}
                              >
                                <span className="font-small-1">
                                  {t("change-photo")}{" "}
                                </span>
                              </Button.Ripple>
                            </div>
                            <Media
                              object
                              src={image}
                              alt="image"
                              style={{ maxWidth: 148, maxHeight: 118 }}
                              className="align-self-center"
                            />
                          </div>
                        ) : (
                          <div
                            className="align-self-center"
                            style={{ padding: 9, height: 50, width: 50 }}
                          >
                            <Image size={32} className="align-self-center" />
                          </div>
                        )}
                      </div>
                      {image ? null : (
                        <React.Fragment>
                          <span className="font-small-2 primary align-self-center">
                            {t("click-to-upload-a-file")}{" "}
                          </span>
                          <span className="font-small-2 color- align-self-center align-text-center">
                            {t("png-jpg-img")} <br /> {t("up-to-10mb")}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                    <input
                      type="file"
                      id="fileInput"
                      name="fileInput"
                      onChange={this.handlePhotoChange.bind(this)}
                      ref={this.fileRef}
                      style={{ display: "none" }}
                    />
                  </FormGroup>
                ) : (
                  <input
                    type="file"
                    id="fileInput"
                    name="fileInput"
                    onChange={this.handlePhotoChange.bind(this)}
                    ref={this.fileRef}
                    style={{ display: "none" }}
                  />
                )}
                {/* {location?.loc_id ? (
                  <Row>
                    <Col md="12">
                      <strong>
                        {location.name}{' '}
                        {t('are-locally-treated-unique-identifer-is')}:{' '}
                        {location.unique_identify_key}
                      </strong>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col md="12">
                      <strong>
                        <Field
                          name="is_locally_treat"
                          component={Checkbox}
                          label={t(
                            'please-select-this-option-if-this-hotel-will-be-treated-locally-and-this-option-will-not-be-change-able-later'
                          )}
                        />
                      </strong>
                    </Col>
                  </Row>
                )} */}

                <Row>
                  <Col md="12">
                    <strong>
                      {t("licence-key")}: {location.license_key}
                    </strong>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="actions">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={submitting}
              >
                Save Hotel
              </button>
            </div>
          </Form>
        </>
      );
    } else {
      return "";
    }
    return "";
  }
}

function mapStateToProps(state) {
  let { location } = state.locations;
  let { currencies, categories } = state.configurations;
  let { allcountries } = state?.currency;
  let initialValues = {};
  const parts = window.location.pathname.split("/");
  if (parts[parts.length - 1] !== "new") {
    if (location.data) {
      const {
        loc_id,
        name,
        latitude,
        longitude,
        slug,
        formattedAddress,
        city,
        state,
        country,
        currency_id,
        category_name,
        location_status,
        visible,
        is_parent,
        is_hotel,
        parent_id,
        pixel_trackers,
        location_telephone,
        use_otp,
        whatsapp_number,
        decimal_places,
        tax_percent,
        tax_label,
        postal_code,
        location_email,
        wait_time,
        auto_close_time,
        otp_refresh_time,
      } = location.data;
      initialValues = {
        loc_id,
        name,
        latitude,
        longitude,
        slug,
        formattedAddress,
        city,
        state,
        is_hotel,
        use_otp,
        country,
        currency_id,
        category_name,
        location_status,
        visible,
        is_parent,
        parent_id,
        pixel_trackers,
        location_telephone,
        whatsapp_number,
        decimal_places,
        tax_percent,
        tax_label,
        postal_code,
        location_email,
        wait_time,
        auto_close_time,
        otp_refresh_time,
      };
    }
  }

  let currentValues = {};
  if (state.form && state.form.locationForm)
    currentValues = state.form.locationForm.values;
  return {
    currentValues,
    initialValues,
    location: location?.data,
    countries: allcountries?.data,
    currencies: currencies?.data,
    categories: categories?.data,
    loading: currencies?.loading || location.loading || categories.loading,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getAllCountries,
    getCurrencies,
    getCategories,
    updateLocation,
    createLocation,
  })(reduxForm({ form: "locationForm" })(HotelForm))
);
