import React, { useState, useEffect } from "react";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import { getAllPurchaseForAdmin } from "../../services/PurchaseHistoryService";
import ListPurchaseHistory from "./listpurchasehistory";
import FilterPurchaseHistory from "./filterpurchasehistory";
import Swal from "sweetalert2";
import { CardHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

const ShowPurchaseHistory = (props) => {
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [licenseData, setLicenseData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchForm, setSearchForm] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);

    fetchData();
  }, []);

  const fetchData = async (page = 0, search = null) => {
    try {
      // Fetch package data once location data is available
      let urlquery = `?page=${page}`;
      if (search != null) {
        urlquery = `?page=${page}&${search}`;
      }
      const packageData = await getAllPurchaseForAdmin(urlquery);
      console.log("TABLES LOCATION", packageData.tablesLocations);
      setLicenseData(packageData.tablesLocations);
      setPurchaseHistory(packageData);
      return;
    } catch (error) {
      await Swal.fire({
        icon: "error",
        title: t("error"),
        text: t("error-fetching-data"),
        showConfirmButton: true,
      });
    } finally {
      setIsLoading(false); // Set loading to false after successful fetch or in case of an error
    }
  };

  const fetchMoreTransactions = async (page) => {
    setCurrentPage(page);
    await fetchData(page, searchForm);
  };

  const getMoreDataBySearch = async (search) => {
    setSearchForm(search);
    setCurrentPage(0);
    await fetchData(0, search);
  };

  const showTransactionDetail = async (transaction) => {
    console.log("transaction", transaction);
    props.history.push({
      pathname: "/purchase-history-detail",
      state: { transaction, licenseData }, // your data array of objects
    });
  };

  return (
    <div>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <>
          <h3 className="mb-2">{t("purchase History")}</h3>
          <FilterPurchaseHistory
            getMoreDataBySearch={(search) => getMoreDataBySearch(search)}
          />
          {purchaseHistory?.transactions?.length === 0 ? (
            <CardHeader
              style={{
                backgroundColor: "green", // Change to your desired background color
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center", // Center align the text
                padding: "20px",
                marginTop: "50px",
              }}
            >
              <h4
                style={{
                  color: "white",
                  margin: "0",
                  fontWeight: "bold",
                }}
              >
                {t("no-purchase-history-found")}{" "}
              </h4>
            </CardHeader>
          ) : (
            <>
              <ListPurchaseHistory
                data={purchaseHistory.transactions}
                count={purchaseHistory.count}
                totalPages={purchaseHistory.totalPages}
                getMoreData={(page) => fetchMoreTransactions(page)}
                limit={purchaseHistory.limit}
                currentPage={currentPage}
                getTransactionDetail={(transaction) =>
                  showTransactionDetail(transaction)
                }
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ShowPurchaseHistory;
