import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from '../shared/ErrorHandler';
import {
  getAllDishTags,
  deleteMultipleDishTag,
  bulkUpdateTags,
} from '../../redux/actions/menus';
import '../../assets/scss/pages/page-pricing.scss';
import ListDishTags from './ListDishTags';
import { Button } from 'reactstrap';
import ConfirmationModal from '../shared/forms/ConfirmationModal';
import LocationService from '../../services/LocationService';
import SelectLocation from '../shared/SelectLocation';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import { Plus } from 'react-feather';
import { Card, CardHeader, CardTitle } from 'reactstrap';
import ViewHelper from '../../helpers/ViewHelper';
import ParamHelper from '../../helpers/ParamHelper';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class DishTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: '',
      location: null,
      tags: [],
      selectLocation: false,
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t('app-users'),
        header: this.props.t('app-users'),
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t('app-users'),
        header: this.props.t('app-users'),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }
  handleChange = (state) => {
    let tags = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      tags.push(state.selectedRows[i].tag_id);
    }
    this.setState({ tags: tags });
  };

  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);
    await this.props.getAllDishTags(loc.loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }
  async deleteMultipleDishTags() {
    if (this.state.tags.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.setState({ loading: true });
          try {
            await this.props.deleteMultipleDishTag(
              this.state.location.loc_id,
              this.state.tags
            );
            await this.refreshData(this.state.location.loc_id, false);
            this.setState({ tags: [] }); //CLEAR THE TAGS
          } catch (e) {
            this.showErrorDialog(null, e.toString(), this.props.t('warning'));
          }
          await this.setState({ loading: false });
        },
        null,
        this.props.t('are-you-sure-you-want-to-delete-these-dish-tags'),
        this.props.t('dish-tags')
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t('please-select-atleast-one-dish-tag-to-delete'),
        this.props.t('warning')
      );
    }
  }

  // handleChange = (state) => {
  //   let reviews = [];
  //   for (let i = 0; i < state.selectedRows.length; i++) {
  //     reviews.push(state.selectedRows[i].review_id);
  //   }
  //   this.setState({ reviews: reviews });
  // };

  async updateCurrencyStatus() {
    if (this.state.currencies.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.props.changeCurrencyStatus(this.state.currencies);
        },
        null,
        this.props.t('are-you-sure-you-want-to-status-of-this-currencies'),
        this.props.t('currencies')
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t('please-select-atleast-one-currency-for-change-status'),
        this.props.t('warning')
      );
    }
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: '',
      header: '',
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: '',
      header: '',
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }
  editDishTag(id) {
    this.props.history.replace('/dish_tags/edit/' + id);
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const items = ViewHelper.reorder(
      this.props.dishtags,
      result.source.index,
      result.destination.index
    );
    console.log(items);
    this.props.bulkUpdateTags(this.state.location.loc_id, items);
  }

  render() {
    const { t } = this.props;
    const {
      props: { dishtags },
    } = this;
    const {
      state: {
        confirmationDialog,
        errorDialog,
        selectLocation,
        location,
        loading,
      },
    } = this;

    const locationName = location ? location.name : '';
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="display-block mb-1">
          <div className="flex-column">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.changeLocation.bind(this)}
            />
            <Button
              outline
              className="font-weight-bold custom-padding primary-2-border mb-1 mt-2"
              color={'primary'}
              size="md"
              onClick={() => this.props.history.replace('/dish_tags/add')}
            >
              <Plus className="mx-2" size={20} />{' '}
              <span className="ml-1 mr-5 ">{t('add-new-dish-tag')}</span>
            </Button>
            <Button
              outline
              className="font-weight-bold custom-padding primary-2-border mb-1 ml-1 btn btn-danger btn-md mt-2"
              size="lg"
              onClick={() => this.deleteMultipleDishTags()}
            >
              <span
                style={{ color: 'white', fontSize: '14px' }}
                className="ml-1 mr-1  "
              >
                {t('delete')}{' '}
              </span>
            </Button>
          </div>
        </div>
        <div id="pricing-table">
          <Card>
            {!loading && dishtags ? (
              <ListDishTags
                dishtags={dishtags}
                handleChange={this.handleChange.bind(this)}
                editDishTag={this.editDishTag.bind(this)}
                onDragEnd={this.onDragEnd.bind(this)}
              />
            ) : null}
          </Card>
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t('cancel')}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={t('ok')}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    menu: {
      allDishTags: { data: dishtags, loading: loading },
    },
  } = state;

  return { dishtags, loading: loading };
}

export default withTranslation('global')(
  connect(mapStateToProps, {
    getAllDishTags,
    deleteMultipleDishTag,
    bulkUpdateTags,
  })(DishTags)
);
