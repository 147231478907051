import CONSTANTS from '../../../constants';

export function alltables(state = { data: [], loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.TABLES.ALL_TABLES) return { data: action.tables, loading: action.loading };
  return state;
}

export function allSessiontables(state = { data: [], loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.TABLES.ALL_SESSIONS_TABLES) return { data: action.tables, loading: action.loading };
  return state;
}