import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getAllPricingList,
  createPricingPackage,
  updatePricingPackage,
};

async function getAllPricingList() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.GETPRICINGLIST}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token },
    }
  );
  console.log('rrr', response);
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch  Pricing List');
}
// async function createPricingPackage(formData) {
//   let { token } = LoginService.getToken();

//   const requestBody = {
//     title: formData.title,
//     estimate_price: formData.estimate_price,
//     description: formData.description,
//     benefits: formData.benefits, // Include the benefits array
//     number_of_benefits: formData.benefits.length, // Set the correct number of benefits
//   };

//   let response = await fetch(
//     `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.CREATEPRICINGLIST}`,
//     {
//       method: 'POST',
//       headers: {
//         ...CONSTANTS.REQUEST_HEADERS,
//         'X-ACCESS-TOKEN': token,
//         'Content-Type': 'application/json', // Add the appropriate content type
//       },
//       body: JSON.stringify(requestBody),
//     }
//   );
//   if (response.ok) {
//     const responseData = await response.json();
//     return responseData;
//   } else {
//     const errorResponseData = await response.json();
//     throw new Error(
//       errorResponseData.message || 'Failed to create package product category'
//     );
//   }
// }
async function createPricingPackage(formData) {
  const { title, estimate_price, description, products, benefits } = formData;
  const { token } = LoginService.getToken();

  const requestBody = {
    title: title,
    estimate_price: estimate_price,
    description: description,
    products: products, // Include the products array
    benefits: benefits, // Include the benefits array
    number_of_benefits: benefits.length,
    total_products: products.length, // Set the correct number of products
  };

  try {
    const response = await fetch(
      `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.CREATEPRICINGLIST}`,
      {
        method: 'POST',
        headers: {
          ...CONSTANTS.REQUEST_HEADERS,
          'X-ACCESS-TOKEN': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      }
    );

    if (!response.ok) {
      const errorResponseData = await response.json();
      throw new Error(
        errorResponseData.message || 'Failed to create package product category'
      );
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error(`An error occurred: ${error.message}`);
  }
}

async function updatePricingPackage(formData, packageId) {
  let { token } = LoginService.getToken();

  const requestBody = {
    title: formData.title,
    estimate_price: formData.price,
    description: formData.description,
    number_of_benefits: formData.benefits.length,
    package_products_id: formData.selectedPackageProductId,
    benefits: formData.benefits,
    products: formData.products,
    total_products: formData.products.length,
    // packageId: formData.packageId,
    // required: formData.required,
  };

  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.STAFF.UPDATEPACKAGEPRICING(
      packageId
    )}`,
    {
      method: 'POST',
      headers: {
        ...CONSTANTS.REQUEST_HEADERS,
        'X-ACCESS-TOKEN': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    }
  );

  response = await response.json();
  if (response) return response;
  throw new Error('Could not create package product category');
}
