import LoginService from "./LoginService";
import CONSTANTS from "../constants";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getAllSurveyResponse,
};

async function getAllSurveyResponse(loc_id, page = 0) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.SURVEY.GET_ALL_RESPONSE(
      loc_id,
      page
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not delete tags");
}
