import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getAllTables,
  createTable,
  getTable,
  changeTableStatus,
  deleteTable,
  generateQr,
  changeTableStatusById,
  unLinkedTabFromTableById,
  getTabsLinkedTables,
  sendNotificationToSelectedTabs,
  getAllSessionTables,
  getAllTablesByFloorId,
};

async function getAllTables(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.DEFAULT(locationId)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch tables");
}

async function getAllTablesByFloorId(locationId, floor_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.GET_TABLE_BY_FLOOR(
      locationId,
      floor_id
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch tables");
}

async function getAllSessionTables(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.SESSION_TABLE_STATUSES(
      locationId
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch tables");
}

async function getTable(locationId, tableId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.GET_TABLE(
      locationId,
      tableId
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not fetch table");
}

async function createTable(locationId, tables) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.DEFAULT(locationId)}`,
    {
      method: "POST",
      body: JSON.stringify(tables),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  let returnResponse = await response.json();
  if (response.status !== 200) {
    throw returnResponse.message;
  } else {
    return returnResponse;
  }
}

async function changeTableStatus(locationId, tables) {
  let { token } = LoginService.getToken();
  tables = { table_ids: tables };
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.STATUSES(locationId)}`,
    {
      method: "POST",
      body: JSON.stringify(tables),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update tables");
}
async function deleteTable(locationId, tables, floor_id) {
  let { token } = LoginService.getToken();
  tables = { table_ids: tables };
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.DELETE_TABLES(
      locationId,
      floor_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify(tables),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (!response?.error) return response;
  throw response.message;
}
async function changeTableStatusById(locationId, table) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.STATUSESID(
      locationId,
      table
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update table");
}
async function unLinkedTabFromTableById(locationId, table) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.UNLINKTAB(
      locationId,
      table
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update table");
}
async function generateQr(locationId, tables) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.QR(locationId)}`,
    {
      method: "POST",
      body: JSON.stringify(tables),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update tables");
}

async function getTabsLinkedTables(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ADMIN.TABLES.GET_TAB_LINKED_TABLES(
      loc_id
    )}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error("Could not update table");
}

async function sendNotificationToSelectedTabs(locationId, data) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${
      CONSTANTS.API_URL
    }${CONSTANTS.URLS.ADMIN.TABLES.SEND_NOTIFICATION_TO_SELECTED_TABS(
      locationId
    )}`,
    {
      method: "POST",
      body: data,
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        "X-ACCESS-TOKEN": token,
      },
    }
  );

  if (response.ok) {
    response = await response.json();
    return response;
  }
  throw new Error("Could not send notification to tables");
}
