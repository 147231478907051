import React, { Component } from "react";
import { connect } from "react-redux";

import { getLocation } from "../../redux/actions/locations";
import { getItemsStats } from "../../redux/actions/analytics";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import TimeHelper from "../../helpers/TimeHelper";
import DataTable from "react-data-table-component";

import DataTableComponent from "../shared/DataTableComponent";
import CONSTANTS from "../../constants";
import ViewHelper from "../../helpers/ViewHelper";
import LocationService from "../../services/LocationService";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class ItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: {
        loc_id: 0,
        name: this.props.t("all-venues"),
        Currency: {
          currency_code: "PKR",
        },
        decimal_places: 2,
      },
      loading: false,
      filters: {
        startDate: TimeHelper.getFirstDayOf2018(),
        endDate: TimeHelper.getCurrentDate(),
        orderBy: "total_sales",
        order: "desc",
      },
      columns: [
        //   {
        //   name: "Item ID",
        //   selector: "menu_id",
        //   sortable: false
        // },
        {
          name: this.props.t("name"),
          // selector: 'name',
          selector: (row) => row.name,
          sortable: false,
        },
        {
          name: this.props.t("category"),
          // selector: "category_name",
          selector: (row) => row.category_name,
          sortable: false,
        },
        {
          name: this.props.t("units-sold"),
          // selector: 'total_items_sold',
          selector: (row) => row.total_items_sold,
          sortable: true,
          format: (val) => {
            return `${val.total_items_sold.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`;
          },
        },
        {
          name: this.props.t("total-sales"),
          // selector: 'total_sales',
          selector: (row) => row.total_sales,
          sortable: true,
          format: (val) => {
            return ViewHelper.toCurrency(
              val.total_sales,
              this.state.location?.Currency?.currency_code,
              this.state.location?.decimal_places
            );
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation(
    );
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id) {
    this.setState({ loading: true });
    let location;
    if (loc_id !== 0) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = {
        loc_id: 0,
        name: "All Venues",
        Currency: {
          currency_code: "PKR",
        },
        decimal_places: 2,
      };
    }
    await this.getStats(loc_id);
    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(location)),
    });
  }

  async getStats(loc_id) {
    await this.props.getItemsStats(loc_id, this.state.filters);
  }

  onBlur() {
    if (this.state.location)
      this.setState({ selectLocation: !this.state.selectLocation });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  filterUpdated(date) {
    const {
      state: { filters, location },
    } = this;
    filters.startDate = TimeHelper.toFormattedDate(
      date[0],
      CONSTANTS.DATES.FORMAT.DB_DATE
    );
    if (date.length > 1)
      filters.endDate = TimeHelper.toFormattedDate(
        date[1],
        CONSTANTS.DATES.FORMAT.DB_DATE
      );
    else filters.endDate = TimeHelper.getCurrentDate();

    this.setState({ filters: filters }, () => {
      this.getStats(location.loc_id);
    });
  }

  render() {
    const { t } = this.props;
    const {
      props: { stats },
    } = this;

    const {
      state: {
        selectLocation,
        loading,
        columns,
        filters,
        location: stateLocation,
      },
    } = this;

    console.log("LOCATION",this.state.location)

    if (loading) return <SpinnerComponent />;

    const locationName = stateLocation ? stateLocation.name : "";

    if (selectLocation)
      return (
        <SelectLocation
          showAll={true}
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <div className="location">
        <div className="mb-50">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.toggleLocation.bind(this)}
          />
        </div>
        {!loading && stats ? (
          <DataTableComponent
            dateFilterUpdated={this.filterUpdated.bind(this)}
            showDateFilter={true}
            title={"Top Items"}
            data={stats}
            columns={columns}
            startDate={filters.startDate}
            endDate={filters.endDate}
            pagination
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    analytics: {
      itemsStats: { data: statsData, loading: statsLoading },
    },
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
  } = state;

  return {
    stats: statsData,
    location: locationData,
    loading: statsLoading || locationLoading,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getLocation, getItemsStats })(ItemsList)
);
