import CONSTANTS from "../../../constants";
import TableService from "../../../services/TableService";

export function getAllTables(locationId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: [],
        loading: true,
      });
      let tables = await TableService.getAllTables(locationId);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: [],
        loading: false,
      });
    }
  };
}

export function getTableByFloorId(locationId, floor_id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: [],
        loading: true,
      });
      let tables = await TableService.getAllTablesByFloorId(
        locationId,
        floor_id
      );
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: [],
        loading: false,
      });
    }
  };
}

export function getAllSessionTables(locationId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_SESSIONS_TABLES,
        tables: [],
        loading: true,
      });
      let tables = await TableService.getAllSessionTables(locationId);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_SESSIONS_TABLES,
        tables,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_SESSIONS_TABLES,
        tables: [],
        loading: false,
      });
    }
  };
}

export function getConnectTabsTables(locationId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.CONNECTED_TABLES,
        tables: [],
        loading: true,
      });
      let tables = await TableService.getTabsLinkedTables(locationId);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.CONNECTED_TABLES,
        tables,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.CONNECTED_TABLES,
        tables: [],
        loading: false,
      });
    }
  };
}

export function createTable(locationId, table, oldTablesArray = []) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: oldTablesArray,
        loading: true,
      });
      let tables = await TableService.createTable(locationId, table);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: oldTablesArray,
        loading: false,
      });
      throw error.toString();
    }
  };
}

export function changeTableStatus(locationId, data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: [],
        loading: true,
      });
      let tables = await TableService.changeTableStatus(locationId, data);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: [],
        loading: false,
      });
    }
  };
}
export function deleteTable(locationId, data , floor_id) {
  return async (dispatch) => {
    try {
      // dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
      let tables = await TableService.deleteTable(locationId, data , floor_id);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables,
        loading: false,
      });
    } catch (e) {
      throw e.toString();
    }
  };
}

export function unLinkedTabFromTableId(locationId, data, tables) {
  return async (dispatch) => {
    try {
      let table = await TableService.unLinkedTabFromTableById(locationId, data);
      let tableIndex = tables.findIndex((object) => {
        return object.table_id === table.table_id;
      });
      let updatedTables = [...tables];
      updatedTables[tableIndex] = table;
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: updatedTables,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables,
        loading: false,
      });
    }
  };
}

export function reGenerateQr(locationId, data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: [],
        loading: true,
      });
      let tables = await TableService.generateQr(locationId, data);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES,
        tables: [],
        loading: false,
      });
    }
  };
}
