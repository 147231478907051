import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Mail, Lock, Check, Eye, EyeOff } from "react-feather";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import LoginService from "../../services/LoginService";
import CONSTANTS from "../../constants";
import OtpModal from "./OtpModal";

// import loginImg from '../../assets/img/logo/toc-logo-with-text.png';

import loginImg from "../../assets/img/logo/waaS.jpg";
import loginImg2 from "../../assets/img/logo/waiterAsAServiceLogo.png";
// import loginImg from '../../assets/img/logo/waiterAsAServiceLogo.png';
// import loginImg from '../../assets/img/logo/waas2.jpg';
import "../../assets/scss/pages/authentication.scss";
import logo from "../../assets/img/Servall.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      password: "",
      isTermsCheck: true,
      showDialog: false,
      showPassword: false,
      isOtpModalOpen: false, // Add this state
    };
  }

  openOtpModal = () => {
    this.setState({ isOtpModalOpen: true });
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.from)
      localStorage.setItem("returnURL", this.props.location.state.from);
    if (this.props.location.state && this.props.location.state.data)
      localStorage.setItem(
        "returnData",
        JSON.stringify(this.props.location.state.data)
      );
    if (LoginService.getToken()) this.redirectToReturnURL();
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  login() {
    if (this.state.isTermsCheck) {
      this.setState({ loading: true }, async () => {
        try {
          console.log("running");
          let tokenData = await LoginService.login(
            this.state.email,
            this.state.password
          );
          console.log("tokenData", tokenData);
          if (tokenData.isOwner == 1) {
            Swal.fire({
              icon: "success",
              title: "OTP SENT SUCCESSFULLY ",
              text: `OTP sent to  ${this.state.email}.`,
              showConfirmButton: false,
              timer: 2300,
            }).then(() => {
              this.setState({ loading: false });
              this.openOtpModal();
            });
          } else {
            LoginService.setLoginData(tokenData, tokenData.role);
            // this.redirectToReturnURL();
            // window.location.href = '/';

            Swal.fire({
              icon: "success",
              title: "Logged In",
              text: "You have been successfully Logged In.",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.href = "/";
            });
          }
        } catch (e) {
          console.error("login error", e);
          this.setState({ loading: false, showDialog: true });
          // alert(
          //   "Failed login attempt. Please enter correct username or password."
          // );
        }
      });
    } else {
      alert("Please select Terms & Condition to proceed login.");
    }
  }

  returnURL() {
    let url = localStorage.getItem("returnURL") || `${CONSTANTS.BASE_URL}`;
    localStorage.removeItem("returnURL");
    return url;
  }

  returnData() {
    let data = localStorage.getItem("returnData") || null;
    localStorage.removeItem("returnData");
    if (data) return JSON.parse(data);
  }

  redirectToReturnURL() {
    this.props.history.push(this.returnURL(), this.returnData());
  }

  async onChangeTerms() {
    await this.setState({ isTermsCheck: !this.state.isTermsCheck });
  }

  render() {
    return (
      // <Row className="m-0 justify-content-center">
      //   <Col
      //     sm="8"
      //     xl="7"
      //     lg="10"
      //     md="8"
      //     className="d-flex justify-content-center"
      //   >
      //     <Card className="bg-authentication login-card rounded-0 mb-0 w-100">
      //       <Row className="m-0">
      //         <Col
      //           lg="6"
      //           className="d-lg-block d-none text-center align-self-center px-1 py-0"
      //         >
      //           <div
      //             style={{
      //               display: 'flex',
      //               flexFlow: 'row',
      //               alignItems: 'center',
      //               height: '300px',
      //             }}
      //           >
      //             <img
      //               width={'100%'}
      //               height={'auto'}
      //               src={loginImg}
      //               alt="loginImg"
      //             />
      //           </div>
      //         </Col>
      //         <Col lg="6" md="12" className="p-0">
      //           <Card className="rounded-0 mb-0 px-2 login-tabs-container">
      //             <CardHeader className="pb-1">
      //               <CardTitle>
      //                 <h4 className="mb-0">Login</h4>
      //               </CardTitle>
      //             </CardHeader>
      //             <p className="px-2 auth-title">
      //               Welcome back, please login to your account.
      //             </p>
      //             <Card className="rounded-0 mb-0 px-2">
      //               <CardBody>
      //                 <h4>Waiter As Service Admin Panel</h4>
      //                 <p>Manage all your restaurant orders at one place</p>
      //                 <Form onSubmit={(e) => e.preventDefault()}>
      //                   <FormGroup className="form-label-group position-relative has-icon-left">
      //                     <Input
      //                       type="email"
      //                       placeholder="Email"
      //                       name="email"
      //                       value={this.state.email}
      //                       onChange={this.handleInputChange.bind(this)}
      //                     />
      //                     <div className="form-control-position">
      //                       <Mail size={15} />
      //                     </div>
      //                     <Label>Email</Label>
      //                   </FormGroup>
      //                   <FormGroup className="form-label-group position-relative has-icon-left">
      //                     <Input
      //                       type="password"
      //                       name="password"
      //                       placeholder="Password"
      //                       value={this.state.password}
      //                       onChange={this.handleInputChange.bind(this)}
      //                     />
      //                     <div className="form-control-position">
      //                       <Lock size={15} />
      //                     </div>
      //                     <Label>Password</Label>
      //                   </FormGroup>
      //                   <div className="d-flex justify-content-between">
      //                     <Button.Ripple
      //                       color="primary"
      //                       type="submit"
      //                       onClick={this.login.bind(this)}
      //                     >
      //                       {this.state.loading && this.renderLoader()}
      //                       {!this.state.loading && 'Login'}
      //                     </Button.Ripple>
      //                   </div>
      //                 </Form>
      //               </CardBody>
      //             </Card>
      //           </Card>
      //         </Col>
      //       </Row>
      //     </Card>
      //   </Col>
      // </Row>
      <Row className="m-0 justify-content-center">
        <Col
          lg="6"
          className="d-lg-block  text-center align-self-center px-1 py-0"
        >
          {/* <div className="tocImage">
            <img width={'80%'} height={'auto'} src={loginImg2} alt="loginImg" />
          </div> */}
          {/* <div className="tocImage">
            <img width={'60%'} height={'auto'} src={loginImg} alt="loginImg" />
          </div> */}
        </Col>
        <Col lg="3" md="9" className="p-0">
          {/* px-1 */}
          <Card className="rounded-4 mb-0  login-tabs-container ">
            <CardHeader className="pb-1">
              <CardTitle>
                <h2 className="mb-0 font-weight-bold text-center">
                  Welcome To
                  {/* Welcome To Waiter As Service Admin Panel👋 */}
                </h2>
                <div className="tocImage">
                  <img
                    width={"70%"}
                    height={"auto"}
                    className="mx-auto d-flex"
                    // src={loginImg2}
                    src={logo}
                    alt="loginImg"
                  />
                </div>
              </CardTitle>
            </CardHeader>
            <p className="px-2 auth-title text-center">
              Welcome back, please login to your account.
            </p>

            <Card className="rounded-0 mb-0 ">
              <CardBody>
                <p className="text-center font-weight-bold">
                  Manage all your restaurant orders at one place
                </p>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <FormGroup className="form-label-group position-relative has-icon-left mt-2">
                    <Input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange.bind(this)}
                    />
                    <div className="form-control-position">
                      <Mail size={15} />
                    </div>
                    <Label>Email</Label>
                  </FormGroup>
                  <FormGroup className="form-label-group position-relative has-icon-left">
                    <Input
                      // type="password"
                      type={this.state.showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleInputChange.bind(this)}
                    />
                    <Button
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        padding: "0",
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        outline: "none",
                      }}
                      color="none"
                      onClick={this.togglePasswordVisibility}
                    >
                      {this.state.showPassword ? (
                        <Eye size={16} />
                      ) : (
                        <EyeOff size={16} />
                      )}
                    </Button>

                    <div className="form-control-position">
                      <Lock size={15} />
                    </div>
                    <Label>Password</Label>
                  </FormGroup>
                  {/* <FormGroup className="d-flex justify-content-between align-items-center">
                    <Checkbox
                      color="primary"
                      icon={<Check className="vx-icon" size={16} />}
                      label="I accept the terms & conditions"
                      defaultChecked={this.state.isTermsCheck}
                      onChange={this.onChangeTerms.bind(this)}
                    />
                  </FormGroup> */}
                  <FormGroup className="d-flex justify-content-between align-items-center">
                    <a href="/forget_password">Forget Password</a>
                  </FormGroup>
                  <FormGroup className="text-center">
                    <Button.Ripple
                      color="primary"
                      type="submit"
                      className="btn-block"
                      onClick={() => this.login()}
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? (
                        <span>Loading...</span>
                      ) : (
                        <span>Login</span>
                      )}
                    </Button.Ripple>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Card>
        </Col>
        {this.state.showDialog && ( // <-- added parentheses to wrap the SweetAlert component
          <SweetAlert
            danger
            title="Oops!"
            showConfirm={false}
            timeout={2500}
            onConfirm={() => this.setState({ showDialog: false })}
          >
            Please enter correct Email or Password
          </SweetAlert>
        )}
        {this.state.isOtpModalOpen && (
          <OtpModal
            isOpen={this.state.isOtpModalOpen}
            toggle={() => this.setState({ isOtpModalOpen: false })}
            handleOtpSubmit={this.handleOtpSubmit}
            email={this.state.email}
            password={this.state.password}
          />
        )}
      </Row>
    );
  }

  renderLoader() {
    return (
      <div className="text-sm spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
}

export default Login;
