import CONSTANTS from '../constants';

export default class ImageHelper {
  static getCloudImageURL(originalUrl, queryOptions) {
    // change original url only in case when env set for cloud_image_url
    if (CONSTANTS.CLOUD_IMAGE_URL) {
      if (originalUrl.indexOf(':4000') === -1) {
        originalUrl = originalUrl.replace('https://', '');
        originalUrl = originalUrl.replace('http://', '');
      }
      return `${CONSTANTS.CLOUD_IMAGE_URL}${originalUrl}${queryOptions}`;
    }
    return originalUrl;
  }

  static getImageURL(url, query) {
    url = url
      ? this.getCloudImageURL(
          `${CONSTANTS.API_URL}/restaurant_data/${url}`,
          query
        )
      : null;
    return url;
  }
}
