import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../../shared/ErrorHandler";
import { getAllUsers, updateBanStatus } from "../../../redux/actions/users";
import "../../../assets/scss/pages/page-pricing.scss";
import ListAppUsers from "./ListAppUsers";
import { Button } from "reactstrap";
import ConfirmationModal from "../../shared/forms/ConfirmationModal";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class AppUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: "",
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    await this.props.getAllUsers();
  }

  handleChange = (state) => {
    let users = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      users.push(state.selectedRows[i].user_id);
    }
    this.setState({ users: users });
  };

  async updateBan() {
    if (this.state.users.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.props.updateBanStatus(this.state.users);
        },
        null,
        this.props.t("are-you-sure-you-want-to-ban-unban-this-users"),
        this.props.t("app-users")
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t("please-select-atleast-one-user-for-ban-unban"),
        this.props.t("warning")
      );
    }
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }

  render() {
    const { t } = this.props;
    const {
      props: { users, loading },
    } = this;
    const {
      state: { confirmationDialog, errorDialog },
    } = this;
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div id="pricing-table">
          <Button
            className="mb-1"
            color="primary"
            size="md"
            onClick={() => this.updateBan()}
          >
            <span className="ml-1 mr-1 d-md-inline">Ban/UnBan</span>
          </Button>
          {!loading && users ? (
            <ListAppUsers
              users={users}
              handleChange={this.handleChange.bind(this)}
            />
          ) : null}
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t('cancel')}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={t('ok')}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    users: {
      allusers: { data: users, loading: loading },
    },
  } = state;

  return { users, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, { getAllUsers, updateBanStatus })(AppUsers)
);
