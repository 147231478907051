import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';

const cardStyle = {
  textAlign: 'center',
  border: '1px solid white',
  borderRadius: '5px',
  transition: 'transform 0.2s',
  cursor: 'pointer',
};

const iconStyle = {
  fontSize: '24px',
};

const cardTitleStyle = {
  fontSize: '10px',
  fontWeight: 'bold',
  marginTop: '5px',
  textDecoration: 'none',
};

const customLinkStyle = {
  fontWeight: 'bolder',
  textDecoration: 'none',
};

const OwnerQuickLinks = () => {
  const [t, i18n] = useTranslation('global');
  const cardCount = 6;
  const [isHovered, setIsHovered] = useState(Array(cardCount).fill(false));

  const handleCardHover = (index) => {
    const updatedIsHovered = Array(cardCount).fill(false);
    updatedIsHovered[index] = true;
    setIsHovered(updatedIsHovered);
  };

  const handleCardLeave = () => {
    setIsHovered(Array(cardCount).fill(false));
  };

  useEffect(() => {
    const lang2 = localStorage.getItem('lang');
    i18n.changeLanguage(lang2);
  }, []);

  const cardContent = [
    {
        icon: <Icon.Key size={30} />,
      title: t('Sessions'),
      link: '/sessions',
    },
    {
      icon: <Icon.ShoppingBag size={30} />,
      title: t('Restaurants'),
      link: '/locations/all',
    },
    {
      icon: <Icon.Package size={30} />,
      title: t('Packages'),
      link: '/packages/all',
    },
    {
      icon: <Icon.Slack size={30} />,
      title: t('Purchase History'),
      link: '/purchase-history',
    },
    {
      icon: <Icon.Users size={30} />,
      title: t('Accounts'),
      link: '/staff',
    },

    {
      icon: <Icon.BarChart2 size={30} />,
      title: t('Overall Stats'),
      link: '/location/stats',
    },
  ];

  return (
    <>
      <Row>
        {cardContent.map((card, index) => (
          <Col lg="2" md="4" sm="6" className="col-4" key={index}>
            <Card
              style={{
                ...cardStyle,
                border: isHovered[index]
                  ? '3px solid green'
                  : '1px solid white',
                height: '110px',
              }}
              onMouseEnter={() => handleCardHover(index)}
              onMouseLeave={handleCardLeave}
            >
              <Link to={card.link} style={customLinkStyle}>
                <CardBody>
                  <div style={iconStyle}>{card.icon}</div>
                  <h6 className="card-title" style={cardTitleStyle}>
                    {card.title}
                  </h6>
                </CardBody>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default OwnerQuickLinks;
