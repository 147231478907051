import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  Col,
  Card,
  CardBody,
  Row,
  Media,
} from "reactstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Radio from "../../@vuexy/radio/RadioVuexy";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";

import { ClipLoader } from "react-spinners";
import Select from "react-select";
import { Image, Plus, Trash, X } from "react-feather";
import ImageHelper from "../../../helpers/ImageHelper";
import * as classnames from "classnames";
import MenuModifiersList from "../MenuModifiersList";
import ConfirmationModal from "./ConfirmationModal";
import GlobalModifiersFormModal from "./GlobalModifiersFormModal";
import MenuService from "../../../services/MenuService";
import CONSTANTS from "../../../constants";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const MenuItemModal = (props) => {
  const saveAction = () => {
    if (isOpen) {
      submitItem();
    }
  };

  const keydownHandler = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) saveAction();
  };

  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);

    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
  }, []);

  const dummyItem = {
    menu_name: "",
    menu_description: "",
    menu_status: 1,
    menu_price: "",
    menu_photo: "",
    menu_category_id: null,
    stock_qty: 0,
    minimum_qty: 0,
    subtract_stock: 0,
    mealtime_id: 0,
    pre_rating: 4.0,
    review_photo: "",
    ratable: 1,
    MenuOptions: [],
    MenuItemRecommendations: [],
    menu_tax: 0,
    highlighted: 0,
    MenuIcons: [],
  };

  const menuType = [
    {
      value: "kitchen",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.KITCHEN,
    },
    {
      value: "bar",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.BAR,
    },
    {
      value: "dessert",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.DESSERT,
    },
    {
      value: "alternative_1",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.ALTERNATIVE_1,
    },
    {
      value: "alternative_2",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.ALTERNATIVE_2,
    },
  ];

  let {
    isOpen,
    toggleModal,
    selectedCategory,
    categories,
    deleteMenu,
    saveItem,
    deleting,
    location,
    allDishTags,
    selectedDishTags,
    allAllergicItems,
    selectedAllergicItems,
    itemsArrayForRecom,
    nutriScoreOptions,
    isHotel,
  } = props;

  console.log("IsHotel", isHotel);
  let itemProp = props.item
    ? props.item
    : JSON.parse(JSON.stringify(dummyItem));
  // console.log("PROPs", props.item);
  var preSelectedMenuIcon = null;
  if (itemProp?.MenuIcons?.length > 0) {
    preSelectedMenuIcon = itemProp.MenuIcons[0].iconPath;
  }

  let recommendedItemOne = { value: "", label: t("select-item") };
  let recommendedItemTwo = { value: "", label: t("select-item") };
  if (
    itemProp?.MenuItemRecommendations &&
    itemProp?.MenuItemRecommendations.length > 0
  ) {
    if (itemProp?.MenuItemRecommendations[0]?.Menu) {
      recommendedItemOne.value =
        itemProp?.MenuItemRecommendations[0].recommendation_item_id;
      recommendedItemOne.label =
        itemProp?.MenuItemRecommendations[0].Menu.menu_name;
    }

    if (itemProp?.MenuItemRecommendations[1]?.Menu) {
      recommendedItemTwo.value =
        itemProp?.MenuItemRecommendations[1].recommendation_item_id;
      recommendedItemTwo.label =
        itemProp?.MenuItemRecommendations[1].Menu.menu_name;
    }
  }

  let selectedMenuType = {};
  if (props.item?.menu_type) {
    selectedMenuType = menuType.map((type) => {
      return type.value === props.item?.menu_type ? type : null;
    });
  }
  let selectedNutriOption = [];
  for (let a = 0; a < nutriScoreOptions.length; a++) {
    if (nutriScoreOptions[a].value == itemProp?.nutri_score) {
      selectedNutriOption.push(nutriScoreOptions[a]);
    }
  }

  const [item, setItem] = useState(itemProp);
  const [selectedImages, setSelectedImages] = useState(preSelectedMenuIcon);
  const [icons, setIcons] = useState([]);

  const [saving, setSaving] = useState(false);
  const [nameError, setError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [tab, setTab] = useState(1);
  const [prevOpenState, setPrevOpenState] = useState(isOpen);
  const [mediaUpload, setMediaUpload] = useState(null);
  const [preUploadedMedia, setPreUploadedMedia] = useState([]);
  const [currentUploadedMedia, setCurrentUploadedMedia] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState({
    show: false,
    acceptCallback: () => {},
    rejectCallback: () => {},
    title: "",
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--primary)"
        : state.isFocused
        ? "#7367f099"
        : state.data.backgroundColor,
      color: "black",
      fontWeight: "bold",
    }),
  };

  const fetchIcon = () => {
    fetch(`${CONSTANTS.API_URL}/admin/addIcons/get`)
      .then((response) => response.json())
      .then((data) => {
        setIcons(data.message);
        console.log(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchIcon();
  }, []);
  console.log("ICONS", icons);

  const onValidateDropDownError = (value, name) => {
    setDropDownError((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };

  const [errorDropDownError, setDropDownError] = useState({
    category: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
    dishtag: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
    menutype: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
    allergyitems: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidateDropDownError,
    },
  });

  const [globalModifiersDialog, setGlobalModifiersDialog] = useState(false);

  const toggleGlobalModifierModal = (changeTab = false) => {
    console.log("====================================");
    console.log("ITEM");
    console.log("====================================");
    setGlobalModifiersDialog(!globalModifiersDialog);
    if (changeTab) toggle(2);
  };

  const getSelectedModifiers = () => {
    return item.MenuOptions.map((mo) => mo.option_id);
  };

  const showConfirmationDialog = (accept, reject, title) => {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        setConfirmationDialog(defaultState);
      },
      rejectCallback: () => {
        setConfirmationDialog(defaultState);
        if (reject) reject();
      },
      title: title,
    };
    setConfirmationDialog(confirmationDialog);
  };

  let inputRef = React.createRef();
  let fileRef = React.createRef();
  let mediaFileRef = React.createRef();

  const toggle = (sTab) => {
    if (tab !== sTab) {
      setTab(sTab);
    }

    if (sTab === 3) {
      let menuMediaArray = [];
      if (item.MenuMedia.length > 0) {
        item.MenuMedia.map((media, i) => {
          menuMediaArray.push({
            original:
              CONSTANTS.API_URL + "/restaurant_data/" + media.menu_photo,
            id: media.id,
            bulletOnClick: (media) => {
              setCurrentUploadedMedia(media.item.id);
            },
          });
        });
        setCurrentUploadedMedia(menuMediaArray[0].id);
      }
      setPreUploadedMedia(menuMediaArray);
    } else {
      setPreUploadedMedia([]);
    }
  };

  const updateItem = (key, val) => {
    console.log("KEYVALUES", key, val);
    if (key === "highlighted") {
      val = val == 0 ? 1 : 0;
    }
    if (key === "menu_status") {
      val = val == 1 ? 1 : 0;
    }
    const cat = JSON.parse(JSON.stringify(item));
    cat[key] = val;
    if (key === "img" && val === null) cat.deletePhoto = true;
    setItem(cat);
    if (key === "menu_category") onValidateDropDownError("", "category");
  };

  const updateDishtags = (val) => {
    const cat = JSON.parse(JSON.stringify(item));
    cat["menu_tags"] = val;
    setItem(cat);
    onValidateDropDownError("", "dishtag");
  };

  const updateAllergicItem = (val) => {
    const cat = JSON.parse(JSON.stringify(item));

    cat["menu_allergy_items"] = val;
    setItem(cat);
    onValidateDropDownError("", "allergyitems");
  };

  const updateMenuType = (val) => {
    const cat = JSON.parse(JSON.stringify(item));
    cat["menu_type"] = val.value;
    setItem(cat);
    onValidateDropDownError("", "menutype");
  };

  const handleChange = (e) => {
    if (e.target.files.length > 0) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "webp", "svg"];
      const fileExtension = e.target.files[0].name.split(".").at(-1);
      if (!allowedFileTypes.includes(fileExtension)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Upload image does not support. Image type must be ${allowedFileTypes.join(
            ", "
          )}`,
        });
        return false;
      }

      var size = parseFloat(e.target.files[0].size / (1024 * 1024 * 5)).toFixed(
        2
      );
      if (size > 5) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("please-select-a-image-size-less-than-5-mb"),
        });
        // alert('Please select image size less than 5 MB');
        return false;
      }

      let reader = new FileReader();
      reader.onload = (ev) => {
        const itemProp = JSON.parse(JSON.stringify(item));
        itemProp.img = ev.target.result;
        itemProp.deletePhoto = false;
        setItem(itemProp);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const uploadMediaChange = (e) => {
    if (e.target.files.length > 0) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "webp", "svg"];
      const fileExtension = e.target.files[0].name
        .split(".")
        .pop()
        .toLowerCase();
      if (!allowedFileTypes.includes(fileExtension)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Upload image does not support. Image type must be ${allowedFileTypes.join(
            ", "
          )}`,
        });
        return false;
      }

      var size = parseFloat(e.target.files[0].size / (1024 * 1024 * 5)).toFixed(
        2
      );
      if (size > 5) {
        alert(t("please-select-an-image-size-less-than-5-mb"));
        return false;
      }

      let reader = new FileReader();
      reader.onload = (ev) => {
        let img = ev.target.result;
        setMediaUpload(img);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const bulletOnClick = (menuItem, itemIndex, currentIndex) => {
    console.log(menuItem, itemIndex, currentIndex);
  };

  const delMenu = () => {
    if (saving || deleting) return;
    deleteMenu(item);
  };

  const updateItemRecommendation = (value, index) => {
    console.log(value, index, "updateItemRecommendation");
    let oldMenu = item;
    if (
      itemProp?.MenuItemRecommendations &&
      itemProp?.MenuItemRecommendations.length > 0
    ) {
      if (oldMenu?.MenuItemRecommendations[index]) {
        oldMenu.MenuItemRecommendations[index].recommendation_item_id =
          value.value;
      } else {
        oldMenu.MenuItemRecommendations[index] = {
          loc_id: oldMenu.loc_id,
          main_menu_id: oldMenu.menu_id,
          recommendation_item_id: value.value,
        };
      }
    } else {
      oldMenu.MenuItemRecommendations.push({
        loc_id: oldMenu.loc_id,
        main_menu_id: oldMenu.menu_id,
        recommendation_item_id: value.value,
      });
    }

    if (value.value === "") {
      oldMenu.MenuItemRecommendations.splice(index, 1);
    }

    setItem(oldMenu);
  };

  const updateIcon = (icon, index) => {
    let oldMenu = item;
    // console.log("icon");
    // console.log(`"${icon}"`);
    // console.log(preSelectedMenuIcon);

    if (preSelectedMenuIcon === icon || preSelectedMenuIcon == `"${icon}"`) {
      // console.log("helooo");
      setSelectedImages(null);
      preSelectedMenuIcon = null;
      oldMenu.MenuIcons = [];
      // console.log(preSelectedMenuIcon);
      setSelectedImages(preSelectedMenuIcon);

      // console.log(icon);

      setItem(oldMenu);
    } else if (selectedImages == icon || preSelectedMenuIcon == icon) {
      // console.log("1");
      setSelectedImages(null);
      oldMenu.MenuIcons = [];
      setSelectedImages(icon);
      oldMenu.MenuIcons.push({
        iconPath: icon,
      });
      // console.log("update: ", oldMenu.MenuIcons);
      setItem(oldMenu);
    } else {
      // If the icon is not selected, select it
      // console.log("3");
      setSelectedImages(icon);
      oldMenu.MenuIcons.pop({
        iconPath: icon,
      });
      oldMenu.MenuIcons.push({
        iconPath: icon,
      });
      setItem(oldMenu);
    }
  };

  useEffect(() => {
    let itemProp = props.item
      ? props.item
      : JSON.parse(JSON.stringify(dummyItem));
    itemProp.menu_category = selectedCategory;
    itemProp.img = ImageHelper.getImageURL(
      itemProp.menu_photo,
      "?func=crop&w=120&h=80"
    );

    itemProp.menu_status = itemProp.menu_status ? 1 : 0;
    itemProp.menu_tags = selectedDishTags;
    itemProp.menu_allergy_items = selectedAllergicItems;
    itemProp.menu_status = itemProp.menu_status ? 1 : 0;
    isOpen = props.isOpen;
    setMediaUpload([]);
    setSelectedImages([]);
    setSaving(false);
    // fetchIcon();
    deleting = props.deleting;
    setError(false);
    if (prevOpenState !== isOpen) {
      setPrevOpenState(isOpen);
      setTab(1);
    }
    setItem(itemProp);
    setPriceError(false);
  }, [props]);
  const submitItem = () => {
    console.log("item", item);
    if (saving || deleting) return;
    if (
      // item.menu_name === "" ||
      item.menu_name === null ||
      item.menu_name === undefined
    ) {
      setError(true);
      return;
    }
    if (
      item.menu_category.value === null ||
      item.menu_category.value === undefined
    ) {
      onValidateDropDownError(t("please-select-category"), "category");
      return false;
    }

    // Check if isHotel is true
    if (isHotel) {
      // item.menu_type = "hotel";
      item.MenuOptions = [];
      item.menu_allergy_items = [];
    } else {
      if (
        item.menu_type === null ||
        item.menu_type === "" ||
        item.menu_type === undefined
      ) {
        onValidateDropDownError(t("please-select-menu-type"), "menutype");
        return false;
      }
    }

    if (
      item.menu_price === "" ||
      item.menu_price == 0 ||
      isNaN(item.menu_price)
    ) {
      setPriceError(true);
      return;
    }

    setSaving(true);
    saveItem(item);
  };

  // const submitItem = () => {
  //   console.log('item', item);

  //   if (saving || deleting) return;
  //   if (
  //     // item.menu_name === "" ||
  //     item.menu_name === null ||
  //     item.menu_name === undefined
  //   ) {
  //     setError(true);
  //     return;
  //   }
  //   if (
  //     item.menu_category.value === null ||
  //     item.menu_category.value === undefined
  //   ) {
  //     onValidateDropDownError(t('please-select-category'), 'category');
  //     return false;
  //   }

  //   if (item.menu_tags.length <= 0) {
  //     onValidateDropDownError(t('please-select-menu-tag'), 'dishtag');
  //     return false;
  //   }

  //   if (
  //     item.menu_price === '' ||
  //     item.menu_price == 0 ||
  //     isNaN(item.menu_price)
  //   ) {
  //     setPriceError(true);
  //     return;
  //   }

  //   setSaving(true);
  //   saveItem(item);
  // };

  const fetchGlobalModifiersForItem = async () => {
    const modifiers = await MenuService.getModifiers(item.loc_id, item.menu_id);
    const it = JSON.parse(JSON.stringify(item));
    it.MenuOptions = modifiers;
    setItem(it);
  };

  const categoryOptions = categories.map((cat) => ({
    value: cat.id,
    label: cat.name,
  }));

  const allDishTagOptions = allDishTags.map((tag) => ({
    value: tag.id,
    label: tag.title,
  }));

  const allAllergicItemOptions = allAllergicItems.map((item) => ({
    value: item.id,
    label: item.title,
    description: item.description,
  }));

  const renderCustomControls = () => {
    return (
      <Trash
        onClick={() =>
          showConfirmationDialog(
            () => {
              props.deleteMenuMedia(currentUploadedMedia);
            },
            null,
            "Are you sure you want to remove this menu media?"
          )
        }
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          zIndex: 100,
          cursor: "pointer",
        }}
        color="white"
        size={30}
      />
    );
  };
  const handleStatusChange = (value) => {
    updateItem("status", value);
  };

  const calculateTotalPrice = (menuPrice, taxPercent) => {
    const itemPrice = parseFloat(menuPrice) || 0;
    const tax = parseFloat(taxPercent) || 0;
    return itemPrice + (itemPrice * tax) / 100;
  };
  console.log("MENU DATAAAAAAAA", item);

  return (
    <React.Fragment>
      {isHotel ? (
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal()}
          onOpened={() => {
            if (props.item) return;
            setTimeout(() => inputRef.current.focus(), 1);
          }}
          className="modal-dialog-centered d-sm-margin-0 p-0"
          backdrop="static"
        >
          <div className="modal-header display-flex full-width flex-space-between">
            <h5 className="modal-title">
              {props.item?.menu_name ? props.item.menu_name : t("new-item")}
            </h5>
            <X className="cursor-pointer" size={20} onClick={toggleModal} />
          </div>

          <Card>
            <CardBody>
              <FormGroup>
                <Label for="name">Service Name </Label>
                <Input
                  innerRef={inputRef}
                  type="text"
                  id="name"
                  value={item.menu_name || ""}
                  placeholder={t("enter-item-name")}
                  invalid={nameError}
                  onChange={(e) => updateItem("menu_name", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  for="category"
                  style={{
                    color:
                      errorDropDownError.category.errorMsg !== ""
                        ? "red"
                        : null,
                  }}
                >
                  {errorDropDownError.category.errorMsg !== ""
                    ? t("category-0")
                    : "Service Category"}
                </Label>
                <Select
                  className="React no-pointer-events"
                  classNamePrefix="select"
                  defaultValue={item.menu_category}
                  name="color"
                  options={categoryOptions}
                  onChange={(e) => updateItem("menu_category", e)}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  for="menutype"
                  style={{
                    color:
                      errorDropDownError.menutype.errorMsg !== ""
                        ? "red"
                        : null,
                  }}
                >
                  {errorDropDownError.menutype.errorMsg !== ""
                    ? t("menu-type-2")
                    : t("menu-type")}
                </Label>
                <Select
                  // styles={{
                  //   option: (provided, state) => ({
                  //     ...provided,
                  //     backgroundColor: state.isSelected
                  //       ? "var(--primary)"
                  //       : state.isFocused
                  //       ? "#7367f099"
                  //       : "#fff",
                  //     background: "black",
                  //   }),
                  // }}
                  className="React no-pointer-events"
                  classNamePrefix="select"
                  defaultValue={selectedMenuType}
                  name="menu_type"
                  options={[{ value: "", label: "Select..." }, ...menuType]}
                  onChange={(e) => updateMenuType(e)}
                />
              </FormGroup>
            </CardBody>
            <CardBody>
              <Label for="photoDiv">{t("menu-image")}: </Label>
              <div
                tabIndex="0"
                id="photoDiv"
                className="display-flex flex-column cursor-pointer"
                onKeyUp={(e) =>
                  e.keyCode === 13 && !e.ctrlKey
                    ? fileRef.current.click()
                    : null
                }
                onClick={() => fileRef.current.click()}
              >
                <div style={{ textAlign: "-webkit-center" }}>
                  {item.img ? (
                    <div>
                      <div>
                        <Button.Ripple
                          onClick={() => {
                            updateItem("img", null);
                            fileRef.current.value = null;
                          }}
                          style={{ width: 20, height: 20 }}
                          className="m-0 p-0"
                          color="danger"
                        >
                          <X size={14} />
                        </Button.Ripple>
                      </div>
                      <div className="full-width">
                        <Button.Ripple
                          className="p-50"
                          color="primary"
                          onClick={() => fileRef.current.click()}
                        >
                          <span className="font-small-3">
                            {t("change-photo")}
                          </span>
                        </Button.Ripple>
                      </div>
                      <Media
                        object
                        src={item.img}
                        alt="image"
                        style={{
                          width: "100%",
                          height: "8rem",
                          objectFit: "contain",
                        }}
                        className="align-self-center"
                      />
                    </div>
                  ) : (
                    <div
                      className="align-self-center"
                      style={{ padding: 9, height: 50, width: 50 }}
                    >
                      <Image size={32} className="align-self-center" />
                    </div>
                  )}
                </div>
                {item.img ? null : (
                  <React.Fragment>
                    <span className="font-small-2 primary align-self-center">
                      {t("click-to-upload-a-file")}{" "}
                    </span>
                    <span className="font-small-2 color- align-self-center align-text-center">
                      JPG, PNG, JPEG, WEBP, SVG <br /> {t("up-to-5mb")}
                    </span>
                  </React.Fragment>
                )}
              </div>
              <input
                type="file"
                id="fileInput"
                name="fileInput"
                onChange={handleChange}
                ref={fileRef}
                style={{ display: "none" }}
                accept="image/*"
              />
            </CardBody>
            <CardBody>
              <FormGroup className="row">
                <Col>
                  <Label for="price">{t("price")}: </Label>
                  <Input
                    type="text"
                    id="price"
                    invalid={priceError}
                    // value={item.menu_price}
                    value={item.menu_price || ""}
                    placeholder={t("enter-price")}
                    // onChange={(e) =>
                    //   updateItem('menu_price', e.target.value)
                    // }
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Check if the input is a valid non-negative number
                      if (/^\d*\.?\d+$/.test(inputValue) || inputValue === "") {
                        updateItem("menu_price", inputValue);
                      }
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for="email">{t("item-description-0")}: </Label>
                <Input
                  type="textarea"
                  id="name"
                  value={item.menu_description}
                  placeholder={t("enter-item-description")}
                  onChange={(e) =>
                    updateItem("menu_description", e.target.value)
                  }
                  rows={4}
                  cols={40}
                />
              </FormGroup>
              <FormGroup className="row">
                <Col md={12}>
                  <Label for="menu_tax">Tax({location?.tax_label}):</Label>
                  <Input
                    type="text"
                    id="menu_tax"
                    value={item.menu_tax || ""}
                    placeholder={t("enter-tax-value")}
                    // onChange={(e) =>
                    //   updateItem('menu_tax', e.target.value)
                    // }
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Check if the input is a valid non-negative number
                      if (/^\d*\.?\d+$/.test(inputValue) || inputValue === "") {
                        updateItem("menu_tax", inputValue);
                      }
                    }}
                  />
                </Col>
              </FormGroup>
            </CardBody>
            <CardBody>
              <FormGroup>
                <div
                  id="status"
                  onChange={(e) =>
                    updateItem("menu_status", parseInt(e.target.value))
                  }
                >
                  {console.log("mmmmmmmmmm", item.menu_status)}
                  <div className="d-inline-block mr-1">
                    <Label for="status" className="mb-0">
                      {t("status-0")}:{" "}
                    </Label>
                    <Radio
                      label={t("enabled")}
                      name="status"
                      value={1}
                      checked={item.menu_status === 1}
                      onChange={() => handleStatusChange(1)}
                    />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio
                      label={t("disabled")}
                      name="status"
                      checked={item.menu_status === 0}
                      value={0}
                      onChange={() => handleStatusChange(0)}
                    />
                  </div>
                </div>
              </FormGroup>
            </CardBody>
          </Card>

          <ModalFooter>
            <div className="d-none d-sm-flex flex-space-between full-width">
              <div>
                {props.item && !props.item.is_dummy_item && tab !== 3 && (
                  <Button
                    color="bg-danger-3"
                    disabled={saving || deleting}
                    onClick={delMenu}
                    // className="border-danger"
                  >
                    {/* {deleting ? (
                      <ClipLoader size={15} />
                    ) : (
                      <span className="danger"> {t('delete')} </span>
                    )} */}
                  </Button>
                )}{" "}
              </div>
              <div>
                <Button
                  color="dark white"
                  disabled={saving || deleting}
                  onClick={() => {
                    if (saving || deleting) return;
                    toggleModal();
                  }}
                >
                  {t("cancel")}{" "}
                </Button>{" "}
                {tab != 3 ? (
                  <Button
                    color="primary"
                    disabled={saving || deleting}
                    onClick={submitItem}
                  >
                    {saving ? <ClipLoader size={15} /> : t("save")}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    disabled={mediaUpload === null}
                    onClick={() => props.saveUploadMedia(item)}
                  >
                    {saving ? <ClipLoader size={15} /> : t("upload")}
                  </Button>
                )}{" "}
              </div>
            </div>
            <div className="d-sm-none col-12">
              {tab !== 3 && (
                <Button
                  className="col-12"
                  color="primary"
                  disabled={saving || deleting}
                  onClick={submitItem}
                >
                  {saving ? <ClipLoader size={15} /> : t("save")}
                </Button>
              )}{" "}
              <Button
                className="col-12 my-50"
                color="dark white"
                disabled={saving || deleting}
                onClick={() => {
                  if (saving || deleting) return;
                  toggleModal();
                }}
              >
                {t("cancel")}{" "}
              </Button>{" "}
            </div>
          </ModalFooter>
        </Modal>
      ) : (
        <Modal
          style={{ minWidth: "75%" }}
          isOpen={isOpen}
          toggle={() => toggleModal()}
          onOpened={() => {
            if (props.item) return;
            setTimeout(() => inputRef.current.focus(), 1);
          }}
          className="modal-dialog-centered d-sm-margin-0 p-0"
          backdrop="static"
        >
          <div className="modal-header display-flex full-width flex-space-between">
            <h5 className="modal-title">
              {props.item?.menu_name ? props.item.menu_name : t("new-item")}
            </h5>
            <X className="cursor-pointer" size={20} onClick={toggleModal} />
          </div>
          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: tab === 1,
                  })}
                  onClick={() => {
                    toggle(1);
                  }}
                >
                  {t("details")}{" "}
                </NavLink>
              </NavItem>
              {props.item?.menu_name && (
                <>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: tab === 2,
                      })}
                      onClick={() => {
                        toggle(2);
                      }}
                    >
                      {t("variants")}{" "}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: tab === 3,
                      })}
                      onClick={() => {
                        toggle(3);
                      }}
                    >
                      {t("images")}{" "}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: tab === 4,
                      })}
                      onClick={() => {
                        toggle(4);
                      }}
                    >
                      {t("recommendation-items")}{" "}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: tab === 5,
                      })}
                      onClick={() => {
                        toggle(5);
                      }}
                    >
                      {t("icons")}
                    </NavLink>
                  </NavItem>
                </>
              )}
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={1}>
                <Row className="menuRow">
                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <FormGroup>
                          <Label for="name">{t("item-name-0")}: </Label>
                          <Input
                            innerRef={inputRef}
                            type="text"
                            id="name"
                            value={item.menu_name || ""}
                            placeholder={t("enter-item-name")}
                            invalid={nameError}
                            onChange={(e) =>
                              updateItem("menu_name", e.target.value)
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label
                            for="category"
                            style={{
                              color:
                                errorDropDownError.category.errorMsg !== ""
                                  ? "red"
                                  : null,
                            }}
                          >
                            {errorDropDownError.category.errorMsg !== ""
                              ? t("category-0")
                              : t("category")}
                          </Label>
                          <Select
                            // styles={{
                            //   option: (provided, state) => ({
                            //     ...provided,
                            //     backgroundColor: state.isSelected
                            //       ? 'var(--primary)'
                            //       : state.isFocused
                            //       ? '#7367f099'
                            //       : '#fff',

                            //   }),
                            // }}
                            className="React no-pointer-events"
                            classNamePrefix="select"
                            defaultValue={item.menu_category}
                            name="color"
                            options={categoryOptions}
                            onChange={(e) => updateItem("menu_category", e)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label
                            for="menutype"
                            style={{
                              color:
                                errorDropDownError.menutype.errorMsg !== ""
                                  ? "red"
                                  : null,
                            }}
                          >
                            {errorDropDownError.menutype.errorMsg !== ""
                              ? t("menu-type-2")
                              : t("menu-type")}
                          </Label>
                          <Select
                            // styles={{
                            //   option: (provided, state) => ({
                            //     ...provided,
                            //     backgroundColor: state.isSelected
                            //       ? "var(--primary)"
                            //       : state.isFocused
                            //       ? "#7367f099"
                            //       : "#fff",
                            //     background: "black",
                            //   }),
                            // }}
                            className="React no-pointer-events"
                            classNamePrefix="select"
                            defaultValue={selectedMenuType}
                            name="menu_type"
                            options={[
                              { value: "", label: "Select..." },
                              ...menuType,
                            ]}
                            onChange={(e) => updateMenuType(e)}
                          />
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <Label for="photoDiv">{t("menu-image")}: </Label>
                        <div
                          style={{ height: "15.5rem" }}
                          tabIndex="0"
                          id="photoDiv"
                          className="display-flex flex-column cursor-pointer"
                          onKeyUp={(e) =>
                            e.keyCode === 13 && !e.ctrlKey
                              ? fileRef.current.click()
                              : null
                          }
                          onClick={() => fileRef.current.click()}
                        >
                          <div style={{ textAlign: "-webkit-center" }}>
                            {item.img ? (
                              <div>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: -10,
                                    right: -10,
                                  }}
                                >
                                  <Button.Ripple
                                    onClick={() => {
                                      updateItem("img", null);
                                      fileRef.current.value = null;
                                    }}
                                    style={{ width: 20, height: 20 }}
                                    className="m-0 p-0"
                                    color="danger"
                                  >
                                    <X size={14} />
                                  </Button.Ripple>
                                </div>
                                <div
                                  style={{ position: "absolute", bottom: 3 }}
                                  className="full-width"
                                >
                                  <Button.Ripple
                                    className="p-50"
                                    color="primary"
                                    onClick={() => fileRef.current.click()}
                                  >
                                    <span className="font-small-3">
                                      {t("change-photo")}
                                    </span>
                                  </Button.Ripple>
                                </div>
                                <Media
                                  object
                                  src={item.img}
                                  alt="image"
                                  style={{
                                    width: "100%",
                                    height: "15rem",
                                    objectFit: "cover",
                                  }}
                                  className="align-self-center"
                                />
                              </div>
                            ) : (
                              <div
                                className="align-self-center"
                                style={{ padding: 9, height: 50, width: 50 }}
                              >
                                <Image
                                  size={32}
                                  className="align-self-center"
                                />
                              </div>
                            )}
                          </div>
                          {item.img ? null : (
                            <React.Fragment>
                              <span className="font-small-2 primary align-self-center">
                                {t("click-to-upload-a-file")}{" "}
                              </span>
                              <span className="font-small-2 color- align-self-center align-text-center">
                                JPG, PNG, JPEG, WEBP, SVG <br />{" "}
                                {t("up-to-5mb")}
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                        <input
                          type="file"
                          id="fileInput"
                          name="fileInput"
                          onChange={handleChange}
                          ref={fileRef}
                          style={{ display: "none" }}
                          accept="image/*"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row className="menuRow">
                  <Col md={6}>
                    <Card>
                      <CardBody style={{ height: "25rem" }}>
                        <FormGroup className="row">
                          <Col md={12}>
                            <Label for="nutriScore">{t("nutri-score")}: </Label>
                            <Select
                              styles={customStyles}
                              defaultValue={selectedNutriOption}
                              // onChange={handleNutriScoreChange}
                              className="React no-pointer-events"
                              classNamePrefix="select"
                              onChange={(e) =>
                                updateItem("nutri_score", e.value)
                              }
                              options={nutriScoreOptions}
                            />
                            {selectedNutriOption &&
                              selectedNutriOption.length > 0 && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    backgroundColor:
                                      selectedNutriOption[0].backgroundColor,
                                    padding: "5px",
                                    borderRadius: "5px",
                                    color: "black",
                                    fontWeight: "700",
                                  }}
                                >
                                  {t("nutri-score")}:{" "}
                                  {selectedNutriOption[0].label}
                                </div>
                              )}
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Col md={12}>
                            <Label
                              for="category"
                              style={{
                                color:
                                  errorDropDownError.dishtag.errorMsg !== ""
                                    ? "red"
                                    : null,
                              }}
                            >
                              {errorDropDownError.dishtag.errorMsg !== ""
                                ? t("dish-tags-1")
                                : t("dish-tags-0")}
                            </Label>
                            <Select
                              styles={{
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "var(--primary)"
                                    : state.isFocused
                                    ? "#7367f099"
                                    : "",
                                }),
                              }}
                              className="React no-pointer-events"
                              classNamePrefix="select"
                              defaultValue={selectedDishTags}
                              name="color"
                              options={allDishTagOptions}
                              onChange={(e) => updateDishtags(e)}
                              isMulti
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Col md={12}>
                            <Label
                              for="category"
                              style={{
                                color:
                                  errorDropDownError.allergyitems.errorMsg !==
                                  ""
                                    ? "red"
                                    : null,
                              }}
                            >
                              {errorDropDownError.allergyitems.errorMsg !== ""
                                ? t("allergic-items-0")
                                : t("allergic-items")}
                            </Label>
                            <Select
                              // styles={{
                              //   option: (provided, state) => ({
                              //     ...provided,
                              //     backgroundColor: state.isSelected
                              //       ? "var(--primary)"
                              //       : state.isFocused
                              //       ? "#7367f099"
                              //       : "var(--primary)",
                              //   }),
                              // }}
                              className="React no-pointer-events"
                              classNamePrefix="select"
                              defaultValue={selectedAllergicItems}
                              name="color"
                              options={allAllergicItemOptions}
                              onChange={(e) => updateAllergicItem(e)}
                              isMulti
                            />
                            <div
                              style={{ overflowY: "auto", maxHeight: "200px" }}
                            >
                              {item?.menu_allergy_items?.map(
                                (selectedItem, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      border: "1px solid gray",
                                      margin: "10px",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      background: selectedItem.isSelected
                                        ? "gray"
                                        : "none",
                                    }}
                                  >
                                    <h6
                                      style={{
                                        color: "primary",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {selectedItem.label}
                                    </h6>
                                    <p
                                      style={{
                                        fontStyle: "italic",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {selectedItem.description}
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          </Col>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <FormGroup className="row">
                          <Col>
                            <Label for="price">{t("price")}: </Label>
                            <Input
                              type="text"
                              id="price"
                              invalid={priceError}
                              // value={item.menu_price}
                              value={item.menu_price}
                              placeholder={t("enter-price")}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input is a valid non-negative number
                                if (
                                  /^\d{0,13}(\.\d{0,2})?$/.test(inputValue) ||
                                  inputValue === ""
                                ) {
                                  updateItem("menu_price", inputValue);
                                }
                              }}
                              // onChange={(e) => {
                              //   const inputValue = e.target.value;
                              //   if (
                              //     /^\d{0,13}(\.\d{0,2})?$/.test(inputValue) ||
                              //     inputValue === ""
                              //   ) {
                              //     updateItem("menu_price", inputValue);
                              //   }
                              // }}

                              // onChange={(e) => {
                              //   const inputValue = e.target.value;
                              //   // Check if the input is a valid non-negative number
                              //   if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
                              //     updateItem("menu_price", inputValue);
                              //   }
                              // }}

                              // onChange={(e) => {
                              //   const inputValue = e.target.value;
                              //   // Check if the input is a valid non-negative number
                              //   if (/^\d*\.?\d+$/.test(inputValue) || inputValue === "") {
                              //     updateItem("menu_price", inputValue);
                              //   }
                              // }}

                              // onChange={(e) =>
                              //   updateItem("menu_price", e.target.value)
                              // }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <Label for="email">{t("item-description-0")}: </Label>
                          <Input
                            type="textarea"
                            id="name"
                            value={item.menu_description}
                            placeholder={t("enter-item-description")}
                            onChange={(e) =>
                              updateItem("menu_description", e.target.value)
                            }
                            rows={4}
                            cols={40}
                          />
                        </FormGroup>
                        <FormGroup className="row">
                          <Col md={12}>
                            <Label for="menu_tax">
                              Tax({location?.tax_label}):
                            </Label>
                            <Input
                              type="text"
                              id="menu_tax"
                              value={item.menu_tax || ""}
                              placeholder={t("enter-tax-value")}
                              // onChange={(e) =>
                              //   updateItem("menu_tax", e.target.value)
                              // }
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  /^\d{0,13}(\.\d{0,2})?$/.test(inputValue) ||
                                  inputValue === ""
                                ) {
                                  updateItem("menu_tax", inputValue);
                                }
                              }}

                              // onChange={(e) => {
                              //   const inputValue = e.target.value;
                              //   // Check if the input is a valid non-negative number
                              //   if (/^\d*\.?\d+$/.test(inputValue) || inputValue === "") {
                              //     updateItem("menu_tax", inputValue);
                              //   }
                              // }}
                            />
                          </Col>
                          <Col md={12}>
                            <Label for="vat_included">Price Incl VAT</Label>
                            <Input
                              type="text"
                              id="vat_included"
                              value={calculateTotalPrice(
                                item.menu_price,
                                item.menu_tax
                              ).toFixed(2)}
                              disabled
                              onChange={(e) =>
                                updateItem("menu_tax", e.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="menuRow">
                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <FormGroup>
                          <div
                            id="status"
                            onChange={(e) =>
                              updateItem(
                                "menu_status",
                                parseInt(e.target.value)
                              )
                            }
                          >
                            <div className="d-inline-block mr-1">
                              <Label for="status" className="mb-0">
                                {t("status-0")}:{" "}
                              </Label>
                              <Radio
                                label={t("enabled")}
                                checked={item.menu_status === 1}
                                name="status"
                                value={1}
                                onChange={() => handleStatusChange(1)}
                              />
                            </div>
                            <div className="d-inline-block mr-1">
                              <Radio
                                label={t("disabled")}
                                name="status"
                                checked={item.menu_status === 0}
                                value={0}
                                onChange={() => handleStatusChange(0)}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <FormGroup>
                          <div id="highlighted">
                            <div className="d-inline-block mr-1">
                              <Label for="highlighted" className="mb-0">
                                {t("chefs-recommended-the-menu-item")}:{" "}
                              </Label>
                              <Checkbox
                                label={t("chefs-recommended")}
                                color="primary"
                                defaultChecked={item.highlighted == 1}
                                value={
                                  item.highlighted == 0 ||
                                  item.highlighted === undefined
                                    ? 0
                                    : 1
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateItem("highlighted", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={2}>
                <MenuModifiersList
                  showConfirmationDialog={showConfirmationDialog}
                  modifiers={item.MenuOptions}
                  updateModifiers={updateItem.bind(this, "MenuOptions")}
                  location={location}
                />
                <Button
                  outline
                  className="font-weight-bold custom-padding primary-2-border"
                  color={"primary"}
                  size="md"
                  onClick={toggleGlobalModifierModal.bind(this)}
                >
                  <Plus className="mx-50" size={14} />{" "}
                  <span className="ml-50 mr-50 primary">
                    {t("add-variants")}
                  </span>
                </Button>
              </TabPane>
              <TabPane tabId={3}>
                <>
                  {/* <FormGroup className="row">
                  <Col md={12}>
                    <div
                      tabIndex="0"
                      id="photoDiv"
                      className="display-flex flex-column border border-2 border-light cursor-pointer"
                      onKeyUp={(e) =>
                        e.keyCode === 13 && !e.ctrlKey
                          ? mediaFileRef.current.click()
                          : null
                      }
                      onClick={() => mediaFileRef.current.click()}
                      style={{ position: "relative", height: 120 }}
                    >
                      <div style={{ textAlign: "-webkit-center" }}>
                        {mediaUpload ? (
                          <div>
                            <div
                              style={{ position: "absolute", bottom: 3 }}
                              className="full-width"
                            >
                              <Button.Ripple
                                className="p-50"
                                color="primary"
                                onClick={() => mediaFileRef.current.click()}
                              >
                                <span className="font-small-3">
                                  {t("change-photo")}{" "}
                                </span>
                              </Button.Ripple>
                            </div>
                            <Media
                              object
                              src={mediaUpload}
                              alt="image"
                              style={{ maxWidth: 148, maxHeight: 118 }}
                              className="align-self-center"
                            />
                            
                          </div>
                        ) : (
                          <div
                            className="align-self-center"
                            style={{ padding: 9, height: 50, width: 50 }}
                          >
                            <Image size={32} className="align-self-center" />
                          </div>
                        )}
                      </div>
                      {mediaUpload ? null : (
                        <React.Fragment>
                          <span className="font-small-2 primary align-self-center">
                            {t("click-to-upload-a-file")}{" "}
                          </span>
                          <span className="font-small-2 color- align-self-center align-text-center">
                            JPG,PNG,JPEG,WEBP,SVG <br /> {t("up-to-5mb")}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                    <input
                      type="file"
                      id="uploadMediaFileInput"
                      name="uploadMediaFileInput"
                      onChange={uploadMediaChange}
                      ref={mediaFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                  </Col>
                </FormGroup> */}
                  <FormGroup className="row">
                    <Col md={12}>
                      <div
                        tabIndex="0"
                        id="photoDiv"
                        className="display-flex flex-column border border-2 border-light cursor-pointer"
                        onKeyUp={(e) =>
                          e.keyCode === 13 && !e.ctrlKey
                            ? mediaFileRef.current.click()
                            : null
                        }
                        onClick={() => mediaFileRef.current.click()}
                        style={{ position: "relative", height: 120 }}
                      >
                        <div style={{ textAlign: "-webkit-center" }}>
                          {mediaUpload && mediaUpload.length > 0 ? (
                            <div>
                              <div
                                style={{ position: "absolute", bottom: 3 }}
                                className="full-width"
                              >
                                <Button.Ripple
                                  className="p-50"
                                  color="primary"
                                  onClick={() => mediaFileRef.current.click()}
                                >
                                  <span className="font-small-3">
                                    {t("change-photo")}{" "}
                                  </span>
                                </Button.Ripple>
                              </div>
                              <Media
                                object
                                src={mediaUpload}
                                alt="image"
                                style={{ maxWidth: 148, maxHeight: 118 }}
                                className="align-self-center"
                              />
                            </div>
                          ) : (
                            <div
                              className="align-self-center"
                              style={{ padding: 9, height: 50, width: 50 }}
                            >
                              <Image size={32} className="align-self-center" />
                            </div>
                          )}
                        </div>
                        {mediaUpload && mediaUpload.length > 0 ? null : (
                          <React.Fragment>
                            <span className="font-small-2 primary align-self-center">
                              {t("click-to-upload-a-file")}{" "}
                            </span>
                            <span className="font-small-2 color- align-self-center align-text-center">
                              JPG, PNG, JPEG, WEBP, SVG <br /> {t("up-to-5mb")}
                            </span>
                          </React.Fragment>
                        )}
                      </div>
                      <input
                        type="file"
                        id="uploadMediaFileInput"
                        name="uploadMediaFileInput"
                        onChange={uploadMediaChange}
                        ref={mediaFileRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                    </Col>
                  </FormGroup>

                  {preUploadedMedia.length > 0 && (
                    <div className="row p-1 w-25 mx-auto">
                      <ImageGallery
                        items={preUploadedMedia}
                        lazyLoad={true}
                        showThumbnails={false}
                        showNav={false}
                        showPlayButton={false}
                        showBullets
                        renderCustomControls={() => renderCustomControls()}
                        showFullscreenButton={false}
                      />
                    </div>
                  )}
                </>
              </TabPane>
              <TabPane tabId={4}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label
                        for="category"
                        style={{
                          color:
                            errorDropDownError.menutype.errorMsg !== ""
                              ? "red"
                              : null,
                        }}
                      >
                        {t("item-1")}:{" "}
                      </Label>
                      <Select
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "var(--primary)"
                              : state.isFocused
                              ? "#7367f099"
                              : "",
                          }),
                        }}
                        className="React no-pointer-events"
                        classNamePrefix="select"
                        defaultValue={recommendedItemOne}
                        name="recommended_item_one"
                        options={[
                          { value: "", label: t("select-item") },
                          ...itemsArrayForRecom,
                        ]}
                        onChange={(e) => updateItemRecommendation(e, 0)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label
                        for="category"
                        style={{
                          color:
                            errorDropDownError.menutype.errorMsg !== ""
                              ? "red"
                              : null,
                        }}
                      >
                        {t("item-2")}:{" "}
                      </Label>
                      <Select
                        // styles={{
                        //   option: (provided, state) => ({
                        //     ...provided,
                        //     backgroundColor: state.isSelected
                        //       ? "var(--primary)"
                        //       : state.isFocused
                        //       ? "#7367f099"
                        //       : "#fff",
                        //   }),
                        // }}
                        className="React no-pointer-events"
                        classNamePrefix="select"
                        defaultValue={recommendedItemTwo}
                        name="recommended_item_two"
                        options={[
                          { value: "", label: t("select-item") },
                          ...itemsArrayForRecom,
                        ]} // Add the None option at the beginning
                        onChange={(e) => updateItemRecommendation(e, 1)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={5}>
                <>
                  {icons?.length > 0 ? (
                    <div>
                      {icons.map((icon) => {
                        return (
                          <>
                            <img
                              style={{
                                // width: '70px',
                                // height: '70px',
                                height: "14rem",

                                marginRight: "10px",
                                marginLeft: "10px",

                                border:
                                  preSelectedMenuIcon == `"${icon}"` ||
                                  selectedImages == icon
                                    ? "2px solid black"
                                    : selectedImages === null ||
                                      preSelectedMenuIcon === null ||
                                      preSelectedMenuIcon === "null"
                                    ? "none"
                                    : "none",
                              }}
                              src={`${CONSTANTS.API_URL}/${icon}`}
                              alt={t("icon")}
                              className={
                                preSelectedMenuIcon == `"${icon}"` ||
                                selectedImages == icon
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => {
                                updateIcon(icon);
                              }}
                            />
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                </>
              </TabPane>
            </TabContent>
          </ModalBody>
          <ModalFooter>
            <div className="d-none d-sm-flex flex-space-between full-width">
              <div>
                {props.item && !props.item.is_dummy_item && tab !== 3 && (
                  <Button
                    color="bg-danger-3"
                    disabled={saving || deleting}
                    onClick={delMenu}
                    // className="border-danger"
                  >
                    {/* {deleting ? (
                    <ClipLoader size={15} />
                  ) : (
                    <span className="danger"> {t('delete')} </span>
                  )} */}
                  </Button>
                )}{" "}
              </div>
              <div>
                <Button
                  color="dark white"
                  disabled={saving || deleting}
                  onClick={() => {
                    if (saving || deleting) return;
                    toggleModal();
                  }}
                >
                  {t("cancel")}{" "}
                </Button>{" "}
                {tab != 3 ? (
                  <Button
                    color="primary"
                    disabled={saving || deleting}
                    onClick={submitItem}
                  >
                    {saving ? <ClipLoader size={15} /> : t("save")}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    disabled={mediaUpload === null}
                    onClick={() => props.saveUploadMedia(item)}
                  >
                    {saving ? <ClipLoader size={15} /> : t("upload")}
                  </Button>
                )}{" "}
              </div>
            </div>
            <div className="d-sm-none col-12">
              {tab !== 3 && (
                <Button
                  className="col-12"
                  color="primary"
                  disabled={saving || deleting}
                  onClick={submitItem}
                >
                  {saving ? <ClipLoader size={15} /> : t("save")}
                </Button>
              )}{" "}
              <Button
                className="col-12 my-50"
                color="dark white"
                disabled={saving || deleting}
                onClick={() => {
                  if (saving || deleting) return;
                  toggleModal();
                }}
              >
                {t("cancel")}{" "}
              </Button>{" "}
              {props.item && tab !== 3 && (
                <Button
                  className="col-12"
                  color="darken-1"
                  disabled={saving || deleting}
                  onClick={delMenu}
                >
                  {deleting ? (
                    <ClipLoader size={15} />
                  ) : (
                    <span className="danger"> {t("delete")} </span>
                  )}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      )}

      <GlobalModifiersFormModal
        fetchGlobalModifiersForItem={fetchGlobalModifiersForItem.bind(this)}
        isOpen={globalModifiersDialog}
        selectedModifiers={getSelectedModifiers()}
        toggleModal={toggleGlobalModifierModal.bind(this)}
        showConfirmationDialog={showConfirmationDialog.bind(this)}
        menu_id={item.menu_id}
        title={item.menu_name}
        loc_id={location?.loc_id}
        isDummyItem={item.is_dummy_item}
      />
      <ConfirmationModal
        title={confirmationDialog.title}
        isOpen={confirmationDialog.show}
        accept={confirmationDialog.acceptCallback.bind(this)}
        reject={confirmationDialog.rejectCallback.bind(this)}
      />
    </React.Fragment>
  );
};
export default MenuItemModal;
