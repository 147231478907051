import React from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import Chart from "react-apexcharts";

class ItemsGraphView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      props: {
        graph: { series, options },
        title,
        type,
      },
    } = this;
    return (
      <Card>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardBody>
          <Chart series={series} options={options} height={400} type={type} />
        </CardBody>
      </Card>
    );
  }
}
export default ItemsGraphView;
