import React, { useState } from "react";
import QRCode from "qrcode.react";
import { Modal, Button } from "react-bootstrap";
import CONSTANTS from "../../constants";

const DirectOrderQr = (props) => {
  const { location } = props;
  const slug = location.slug;
  const qrUrl = `${CONSTANTS.WEBSITE_URL}/restaurants/${slug}`;

  console.log("props", props);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="mx-2">
      <div onClick={handleShow} style={{ cursor: "pointer" }}>
        <QRCode value={qrUrl} size={40} />
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <div style={{ background: "white", padding: "16px" }}>
            <QRCode value={qrUrl} size={150} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => window.open(qrUrl, "_blank")}
          >
            Open Link
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DirectOrderQr;
