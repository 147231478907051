
export const timezoneData = [
    {"name": "Africa/Abidjan +00:00"},
    {"name": "Africa/Accra +00:00"},
    {"name": "Africa/Addis_Ababa +03:00"},
    {"name": "Africa/Algiers +01:00"},
    {"name": "Africa/Asmara +03:00"},
    {"name": "Africa/Asmera +03:00"},
    {"name": "Africa/Bamako +00:00"},
    {"name": "Africa/Bangui +01:00"},
    {"name": "Africa/Banjul +00:00"},
    {"name": "Africa/Bissau +00:00"},
    {"name": "Africa/Blantyre +02:00"},
    {"name": "Africa/Brazzaville +01:00"},
    {"name": "Africa/Bujumbura +02:00"},
    {"name": "Africa/Cairo +02:00"},
    {"name": "Africa/Casablanca +01:00"},
    {"name": "Africa/Ceuta +02:00"},
    {"name": "Africa/Conakry +00:00"},
    {"name": "Africa/Dakar +00:00"},
    {"name": "Africa/Dar_es_Salaam +03:00"},
    {"name": "Africa/Djibouti +03:00"},
    {"name": "Africa/Douala +01:00"},
    {"name": "Africa/El_Aaiun +01:00"},
    {"name": "Africa/Freetown +00:00"},
    {"name": "Africa/Gaborone +02:00"},
    {"name": "Africa/Harare +02:00"},
    {"name": "Africa/Johannesburg +02:00"},
    {"name": "Africa/Juba +03:00"},
    {"name": "Africa/Kampala +03:00"},
    {"name": "Africa/Khartoum +02:00"},
    {"name": "Africa/Kigali +02:00"},
    {"name": "Africa/Kinshasa +01:00"},
    {"name": "Africa/Lagos +01:00"},
    {"name": "Africa/Libreville +01:00"},
    {"name": "Africa/Lome +00:00"},
    {"name": "Africa/Luanda +01:00"},
    {"name": "Africa/Lubumbashi +02:00"},
    {"name": "Africa/Lusaka +02:00"},
    {"name": "Africa/Malabo +01:00"},
    {"name": "Africa/Maputo +02:00"},
    {"name": "Africa/Maseru +02:00"},
    {"name": "Africa/Mbabane +02:00"},
    {"name": "Africa/Mogadishu +03:00"},
    {"name": "Africa/Monrovia +00:00"},
    {"name": "Africa/Nairobi +03:00"},
    {"name": "Africa/Ndjamena +01:00"},
    {"name": "Africa/Niamey +01:00"},
    {"name": "Africa/Nouakchott +00:00"},
    {"name": "Africa/Ouagadougou +00:00"},
    {"name": "Africa/Porto-Novo +01:00"},
    {"name": "Africa/Sao_Tome +00:00"},
    {"name": "Africa/Timbuktu +00:00"},
    {"name": "Africa/Tripoli +02:00"},
    {"name": "Africa/Tunis +01:00"},
    {"name": "Africa/Windhoek +02:00"},
    {"name": "America/Adak -10:00"},
    {"name": "America/Anchorage -08:00"},
    {"name": "America/Anguilla -04:00"},
    {"name": "America/Antigua -04:00"},
    {"name": "America/Araguaina -03:00"},
    {"name": "America/Argentina/Buenos_Aires -03:00"},
    {"name": "America/Argentina/Catamarca -03:00"},
    {"name": "America/Argentina/ComodRivadavia -03:00"},
    {"name": "America/Argentina/Cordoba -03:00"},
    {"name": "America/Argentina/Jujuy -03:00"},
    {"name": "America/Argentina/La_Rioja -03:00"},
    {"name": "America/Argentina/Mendoza -03:00"},
    {"name": "America/Argentina/Rio_Gallegos -03:00"},
    {"name": "America/Argentina/Salta -03:00"},
    {"name": "America/Argentina/San_Juan -03:00"},
    {"name": "America/Argentina/San_Luis -03:00"},
    {"name": "America/Argentina/Tucuman -03:00"},
    {"name": "America/Argentina/Ushuaia -03:00"},
    {"name": "America/Aruba -04:00"},
    {"name": "America/Asuncion -04:00"},
    {"name": "America/Atikokan -05:00"},
    {"name": "America/Atka -10:00"},
    {"name": "America/Bahia -03:00"},
    {"name": "America/Bahia_Banderas -05:00"},
    {"name": "America/Barbados -04:00"},
    {"name": "America/Belem -03:00"},
    {"name": "America/Belize -06:00"},
    {"name": "America/Blanc-Sablon -04:00"},
    {"name": "America/Boa_Vista -04:00"},
    {"name": "America/Bogota -05:00"},
    {"name": "America/Boise -06:00"},
    {"name": "America/Buenos_Aires -03:00"},
    {"name": "America/Cambridge_Bay -06:00"},
    {"name": "America/Campo_Grande -04:00"},
    {"name": "America/Cancun -05:00"},
    {"name": "America/Caracas -04:00"},
    {"name": "America/Catamarca -03:00"},
    {"name": "America/Cayenne -03:00"},
    {"name": "America/Cayman -05:00"},
    {"name": "America/Chicago -05:00"},
    {"name": "America/Chihuahua -07:00"},
    {"name": "America/Coral_Harbour -05:00"},
    {"name": "America/Cordoba -03:00"},
    {"name": "America/Costa_Rica -06:00"},
    {"name": "America/Creston -07:00"},
    {"name": "America/Cuiaba -04:00"},
    {"name": "America/Curacao -04:00"},
    {"name": "America/Danmarkshavn +00:00"},
    {"name": "America/Dawson -07:00"},
    {"name": "America/Dawson_Creek -07:00"},
    {"name": "America/Denver -06:00"},
    {"name": "America/Detroit -04:00"},
    {"name": "America/Dominica -04:00"},
    {"name": "America/Edmonton -06:00"},
    {"name": "America/Eirunepe -05:00"},
    {"name": "America/El_Salvador -06:00"},
    {"name": "America/Ensenada -07:00"},
    {"name": "America/Fort_Nelson -07:00"},
    {"name": "America/Fort_Wayne -04:00"},
    {"name": "America/Fortaleza -03:00"},
    {"name": "America/Glace_Bay -03:00"},
    {"name": "America/Godthab -02:00"},
    {"name": "America/Goose_Bay -03:00"},
    {"name": "America/Grand_Turk -04:00"},
    {"name": "America/Grenada -04:00"},
    {"name": "America/Guadeloupe -04:00"},
    {"name": "America/Guatemala -06:00"},
    {"name": "America/Guayaquil -05:00"},
    {"name": "America/Guyana -04:00"},
    {"name": "America/Halifax -03:00"},
    {"name": "America/Havana -04:00"},
    {"name": "America/Hermosillo -07:00"},
    {"name": "America/Indiana/Indianapolis -04:00"},
    {"name": "America/Indiana/Knox -05:00"},
    {"name": "America/Indiana/Marengo -04:00"},
    {"name": "America/Indiana/Petersburg -04:00"},
    {"name": "America/Indiana/Tell_City -05:00"},
    {"name": "America/Indiana/Vevay -04:00"},
    {"name": "America/Indiana/Vincennes -04:00"},
    {"name": "America/Indiana/Winamac -04:00"},
    {"name": "America/Indianapolis -04:00"},
    {"name": "America/Inuvik -06:00"},
    {"name": "America/Iqaluit -04:00"},
    {"name": "America/Jamaica -05:00"},
    {"name": "America/Jujuy -03:00"},
    {"name": "America/Juneau -08:00"},
    {"name": "America/Kentucky/Louisville -04:00"},
    {"name": "America/Kentucky/Monticello -04:00"},
    {"name": "America/Knox_IN -05:00"},
    {"name": "America/Kralendijk -04:00"},
    {"name": "America/La_Paz -04:00"},
    {"name": "America/Lima -05:00"},
    {"name": "America/Los_Angeles -07:00"},
    {"name": "America/Louisville -04:00"},
    {"name": "America/Lower_Princes -04:00"},
    {"name": "America/Maceio -03:00"},
    {"name": "America/Managua -06:00"},
    {"name": "America/Manaus -04:00"},
    {"name": "America/Marigot -04:00"},
    {"name": "America/Martinique -04:00"},
    {"name": "America/Matamoros -05:00"},
    {"name": "America/Mazatlan -06:00"},
    {"name": "America/Mendoza -03:00"},
    {"name": "America/Menominee -05:00"},
    {"name": "America/Merida -05:00"},
    {"name": "America/Metlakatla -08:00"},
    {"name": "America/Mexico_City -05:00"},
    {"name": "America/Miquelon -02:00"},
    {"name": "America/Moncton -03:00"},
    {"name": "America/Monterrey -05:00"},
    {"name": "America/Montevideo -03:00"},
    {"name": "America/Montreal -04:00"},
    {"name": "America/Montserrat -04:00"},
    {"name": "America/Nassau -04:00"},
    {"name": "America/New_York -04:00"},
    {"name": "America/Nipigon -04:00"},
    {"name": "America/Nome -08:00"},
    {"name": "America/Noronha -02:00"},
    {"name": "America/North_Dakota/Beulah -05:00"},
    {"name": "America/North_Dakota/Center -05:00"},
    {"name": "America/North_Dakota/New_Salem -05:00"},
    {"name": "America/Nuuk -02:00"},
    {"name": "America/Ojinaga -06:00"},
    {"name": "America/Panama -05:00"},
    {"name": "America/Pangnirtung -04:00"},
    {"name": "America/Paramaribo -03:00"},
    {"name": "America/Phoenix -07:00"},
    {"name": "America/Port-au-Prince -04:00"},
    {"name": "America/Port_of_Spain -04:00"},
    {"name": "America/Porto_Acre -05:00"},
    {"name": "America/Porto_Velho -04:00"},
    {"name": "America/Puerto_Rico -04:00"},
    {"name": "America/Punta_Arenas -03:00"},
    {"name": "America/Rainy_River -05:00"},
    {"name": "America/Rankin_Inlet -05:00"},
    {"name": "America/Recife -03:00"},
    {"name": "America/Regina -06:00"},
    {"name": "America/Resolute -05:00"},
    {"name": "America/Rio_Branco -05:00"},
    {"name": "America/Rosario -03:00"},
    {"name": "America/Santa_Isabel -07:00"},
    {"name": "America/Santarem -03:00"},
    {"name": "America/Santiago -04:00"},
    {"name": "America/Santo_Domingo -04:00"},
    {"name": "America/Sao_Paulo -03:00"},
    {"name": "America/Scoresbysund -01:00"},
    {"name": "America/Shiprock -06:00"},
    {"name": "America/Sitka -08:00"},
    {"name": "America/St_Barthelemy -04:00"},
    {"name": "America/St_Johns -02:30"},
    {"name": "America/St_Kitts -04:00"},
    {"name": "America/St_Lucia -04:00"},
    {"name": "America/St_Thomas -04:00"},
    {"name": "America/St_Vincent -04:00"},
    {"name": "America/Swift_Current -06:00"},
    {"name": "America/Tegucigalpa -06:00"},
    {"name": "America/Thule -03:00"},
    {"name": "America/Thunder_Bay -04:00"},
    {"name": "America/Tijuana -07:00"},
    {"name": "America/Toronto -04:00"},
    {"name": "America/Tortola -04:00"},
    {"name": "America/Vancouver -07:00"},
    {"name": "America/Virgin -04:00"},
    {"name": "America/Whitehorse -07:00"},
    {"name": "America/Winnipeg -05:00"},
    {"name": "America/Yakutat -08:00"},
    {"name": "America/Yellowknife -06:00"},
    {"name": "Antarctica/Casey +08:00"},
    {"name": "Antarctica/Davis +07:00"},
    {"name": "Antarctica/DumontDUrville +10:00"},
    {"name": "Antarctica/Macquarie +11:00"},
    {"name": "Antarctica/Mawson +05:00"},
    {"name": "Antarctica/McMurdo +12:00"},
    {"name": "Antarctica/Palmer -03:00"},
    {"name": "Antarctica/Rothera -03:00"},
    {"name": "Antarctica/South_Pole +12:00"},
    {"name": "Antarctica/Syowa +03:00"},
    {"name": "Antarctica/Troll +02:00"},
    {"name": "Antarctica/Vostok +06:00"},
    {"name": "Arctic/Longyearbyen +02:00"},
    {"name": "Asia/Aden +03:00"},
    {"name": "Asia/Almaty +06:00"},
    {"name": "Asia/Amman +03:00"},
    {"name": "Asia/Anadyr +12:00"},
    {"name": "Asia/Aqtau +05:00"},
    {"name": "Asia/Aqtobe +05:00"},
    {"name": "Asia/Ashgabat +05:00"},
    {"name": "Asia/Ashkhabad +05:00"},
    {"name": "Asia/Atyrau +05:00"},
    {"name": "Asia/Baghdad +03:00"},
    {"name": "Asia/Bahrain +03:00"},
    {"name": "Asia/Baku +04:00"},
    {"name": "Asia/Bangkok +07:00"},
    {"name": "Asia/Barnaul +07:00"},
    {"name": "Asia/Beirut +03:00"},
    {"name": "Asia/Bishkek +06:00"},
    {"name": "Asia/Brunei +08:00"},
    {"name": "Asia/Calcutta +05:30"},
    {"name": "Asia/Chita +09:00"},
    {"name": "Asia/Choibalsan +08:00"},
    {"name": "Asia/Chongqing +08:00"},
    {"name": "Asia/Chungking +08:00"},
    {"name": "Asia/Colombo +05:30"},
    {"name": "Asia/Dacca +06:00"},
    {"name": "Asia/Damascus +03:00"},
    {"name": "Asia/Dhaka +06:00"},
    {"name": "Asia/Dili +09:00"},
    {"name": "Asia/Dubai +04:00"},
    {"name": "Asia/Dushanbe +05:00"},
    {"name": "Asia/Famagusta +03:00"},
    {"name": "Asia/Gaza +03:00"},
    {"name": "Asia/Harbin +08:00"},
    {"name": "Asia/Hebron +03:00"},
    {"name": "Asia/Ho_Chi_Minh +07:00"},
    {"name": "Asia/Hong_Kong +08:00"},
    {"name": "Asia/Hovd +07:00"},
    {"name": "Asia/Irkutsk +08:00"},
    {"name": "Asia/Istanbul +03:00"},
    {"name": "Asia/Jakarta +07:00"},
    {"name": "Asia/Jayapura +09:00"},
    {"name": "Asia/Jerusalem +03:00"},
    {"name": "Asia/Kabul +04:30"},
    {"name": "Asia/Kamchatka +12:00"},
    {"name": "Asia/Karachi +05:00"},
    {"name": "Asia/Kashgar +08:00"},
    {"name": "Asia/Kathmandu +05:45"},
    {"name": "Asia/Katmandu +05:45"},
    {"name": "Asia/Khandyga +09:00"},
    {"name": "Asia/Kolkata +05:30"},
    {"name": "Asia/Krasnoyarsk +07:00"},
    {"name": "Asia/Kuala_Lumpur +08:00"},
    {"name": "Asia/Kuching +08:00"},
    {"name": "Asia/Kuwait +03:00"},
    {"name": "Asia/Macao +08:00"},
    {"name": "Asia/Macau +08:00"},
    {"name": "Asia/Magadan +11:00"},
    {"name": "Asia/Makassar +08:00"},
    {"name": "Asia/Manila +08:00"},
    {"name": "Asia/Muscat +04:00"},
    {"name": "Asia/Nicosia +03:00"},
    {"name": "Asia/Novokuznetsk +07:00"},
    {"name": "Asia/Novosibirsk +07:00"},
    {"name": "Asia/Omsk +06:00"},
    {"name": "Asia/Oral +05:00"},
    {"name": "Asia/Phnom_Penh +07:00"},
    {"name": "Asia/Pontianak +07:00"},
    {"name": "Asia/Pyongyang +09:00"},
    {"name": "Asia/Qatar +03:00"},
    {"name": "Asia/Qyzylorda +06:00"},
    {"name": "Asia/Rangoon +06:30"},
    {"name": "Asia/Riyadh +03:00"},
    {"name": "Asia/Saigon +07:00"},
    {"name": "Asia/Sakhalin +11:00"},
    {"name": "Asia/Samarkand +05:00"},
    {"name": "Asia/Seoul +09:00"},
    {"name": "Asia/Shanghai +08:00"},
    {"name": "Asia/Singapore +08:00"},
    {"name": "Asia/Srednekolymsk +11:00"},
    {"name": "Asia/Taipei +08:00"},
    {"name": "Asia/Tashkent +05:00"},
    {"name": "Asia/Tbilisi +04:00"},
    {"name": "Asia/Tehran +04:30"},
    {"name": "Asia/Tel_Aviv +03:00"},
    {"name": "Asia/Thimbu +06:00"},
    {"name": "Asia/Thimphu +06:00"},
    {"name": "Asia/Tokyo +09:00"},
    {"name": "Asia/Tomsk +07:00"},
    {"name": "Asia/Ulaanbaatar +08:00"},
    {"name": "Asia/Ulan_Bator +08:00"},
    {"name": "Asia/Urumqi +08:00"},
    {"name": "Asia/Ust-Nera +10:00"},
    {"name": "Asia/Vientiane +07:00"},
    {"name": "Asia/Vladivostok +10:00"},
    {"name": "Asia/Yakutsk +09:00"},
    {"name": "Asia/Yangon +06:30"},
    {"name": "Asia/Yekaterinburg +05:00"},
    {"name": "Asia/Yerevan +04:00"},
    {"name": "Atlantic/Azores -01:00"},
    {"name": "Atlantic/Bermuda -03:00"},
    {"name": "Atlantic/Canary +01:00"},
    {"name": "Atlantic/Cape_Verde -01:00"},
    {"name": "Atlantic/Faeroe +01:00"},
    {"name": "Atlantic/Faroe +01:00"},
    {"name": "Atlantic/Jan_Mayen +02:00"},
    {"name": "Atlantic/Madeira +01:00"},
    {"name": "Atlantic/Reykjavik +00:00"},
    {"name": "Atlantic/South_Georgia -02:00"},
    {"name": "Atlantic/St_Helena +00:00"},
    {"name": "Atlantic/Stanley -03:00"},
    {"name": "Australia/ACT +10:00"},
    {"name": "Australia/Adelaide +09:30"},
    {"name": "Australia/Brisbane +10:00"},
    {"name": "Australia/Broken_Hill +09:30"},
    {"name": "Australia/Canberra +10:00"},
    {"name": "Australia/Currie +10:00"},
    {"name": "Australia/Darwin +09:30"},
    {"name": "Australia/Eucla +08:45"},
    {"name": "Australia/Hobart +10:00"},
    {"name": "Australia/LHI +10:30"},
    {"name": "Australia/Lindeman +10:00"},
    {"name": "Australia/Lord_Howe +10:30"},
    {"name": "Australia/Melbourne +10:00"},
    {"name": "Australia/NSW +10:00"},
    {"name": "Australia/North +09:30"},
    {"name": "Australia/Perth +08:00"},
    {"name": "Australia/Queensland +10:00"},
    {"name": "Australia/South +09:30"},
    {"name": "Australia/Sydney +10:00"},
    {"name": "Australia/Tasmania +10:00"},
    {"name": "Australia/Victoria +10:00"},
    {"name": "Australia/West +08:00"},
    {"name": "Australia/Yancowinna +09:30"},
    {"name": "Brazil/Acre -05:00"},
    {"name": "Brazil/DeNoronha -02:00"},
    {"name": "Brazil/East -03:00"},
    {"name": "Brazil/West -04:00"},
    {"name": "CET +01:00"},
    {"name": "CST6CDT -06:00"},
    {"name": "Canada/Atlantic -03:00"},
    {"name": "Canada/Central -06:00"},
    {"name": "Canada/Eastern -04:00"},
    {"name": "Canada/Mountain -07:00"},
    {"name": "Canada/Newfoundland -02:30"},
    {"name": "Canada/Pacific -07:00"},
    {"name": "Canada/Saskatchewan -06:00"},
    {"name": "Canada/Yukon -07:00"},
    {"name": "Chile/Continental -03:00"},
    {"name": "Chile/EasterIsland -05:00"},
    {"name": "Cuba -04:00"},
    {"name": "EET +02:00"},
    {"name": "EST -05:00"},
    {"name": "EST5EDT -04:00"},
    {"name": "Egypt +02:00"},
    {"name": "Eire +01:00"},
    {"name": "Etc/GMT +00:00"},
    {"name": "Etc/GMT+0 +00:00"},
    {"name": "Etc/GMT+1 -01:00"},
    {"name": "Etc/GMT+10 -10:00"},
    {"name": "Etc/GMT+11 -11:00"},
    {"name": "Etc/GMT+12 -12:00"},
    {"name": "Etc/GMT+2 -02:00"},
    {"name": "Etc/GMT+3 -03:00"},
    {"name": "Etc/GMT+4 -04:00"},
    {"name": "Etc/GMT+5 -05:00"},
    {"name": "Etc/GMT+6 -06:00"},
    {"name": "Etc/GMT+7 -07:00"},
    {"name": "Etc/GMT+8 -08:00"},
    {"name": "Etc/GMT+9 -09:00"},
    {"name": "Etc/GMT-0 +00:00"},
    {"name": "Etc/GMT-1 +01:00"},
    {"name": "Etc/GMT-10 +10:00"},
    {"name": "Etc/GMT-11 +11:00"},
    {"name": "Etc/GMT-12 +12:00"},
    {"name": "Etc/GMT-13 +13:00"},
    {"name": "Etc/GMT-14 +14:00"},
    {"name": "Etc/GMT-2 +02:00"},
    {"name": "Etc/GMT-3 +03:00"},
    {"name": "Etc/GMT-4 +04:00"},
    {"name": "Etc/GMT-5 +05:00"},
    {"name": "Etc/GMT-6 +06:00"},
    {"name": "Etc/GMT-7 +07:00"},
    {"name": "Etc/GMT-8 +08:00"},
    {"name": "Etc/GMT-9 +09:00"},
    {"name": "Etc/GMT0 +00:00"},
    {"name": "Etc/Greenwich +00:00"},
    {"name": "Etc/UCT +00:00"},
    {"name": "Etc/UTC +00:00"},
    {"name": "Etc/Universal +00:00"},
    {"name": "Etc/Zulu +00:00"},
    {"name": "Europe/Amsterdam +02:00"},
    {"name": "Europe/Andorra +02:00"},
    {"name": "Europe/Astrakhan +04:00"},
    {"name": "Europe/Athens +03:00"},
    {"name": "Europe/Belfast +01:00"},
    {"name": "Europe/Belgrade +02:00"},
    {"name": "Europe/Berlin +02:00"},
    {"name": "Europe/Bratislava +02:00"},
    {"name": "Europe/Brussels +02:00"},
    {"name": "Europe/Bucharest +03:00"},
    {"name": "Europe/Budapest +02:00"},
    {"name": "Europe/Busingen +02:00"},
    {"name": "Europe/Chisinau +03:00"},
    {"name": "Europe/Copenhagen +02:00"},
    {"name": "Europe/Dublin +01:00"},
    {"name": "Europe/Gibraltar +02:00"},
    {"name": "Europe/Guernsey +01:00"},
    {"name": "Europe/Helsinki +03:00"},
    {"name": "Europe/Isle_of_Man +01:00"},
    {"name": "Europe/Istanbul +03:00"},
    {"name": "Europe/Jersey +01:00"},
    {"name": "Europe/Kaliningrad +02:00"},
    {"name": "Europe/Kiev +03:00"},
    {"name": "Europe/Kirov +03:00"},
    {"name": "Europe/Lisbon +01:00"},
    {"name": "Europe/Ljubljana +02:00"},
    {"name": "Europe/London +01:00"},
    {"name": "Europe/Luxembourg +02:00"},
    {"name": "Europe/Madrid +02:00"},
    {"name": "Europe/Malta +02:00"},
    {"name": "Europe/Mariehamn +03:00"},
    {"name": "Europe/Minsk +03:00"},
    {"name": "Europe/Monaco +02:00"},
    {"name": "Europe/Moscow +03:00"},
    {"name": "Europe/Nicosia +03:00"},
    {"name": "Europe/Oslo +02:00"},
    {"name": "Europe/Paris +02:00"},
    {"name": "Europe/Podgorica +02:00"},
    {"name": "Europe/Prague +02:00"},
    {"name": "Europe/Riga +03:00"},
    {"name": "Europe/Rome +02:00"},
    {"name": "Europe/Samara +04:00"},
    {"name": "Europe/San_Marino +02:00"},
    {"name": "Europe/Sarajevo +02:00"},
    {"name": "Europe/Saratov +04:00"},
    {"name": "Europe/Simferopol +03:00"},
    {"name": "Europe/Skopje +02:00"},
    {"name": "Europe/Sofia +03:00"},
    {"name": "Europe/Stockholm +02:00"},
    {"name": "Europe/Tallinn +03:00"},
    {"name": "Europe/Tirane +02:00"},
    {"name": "Europe/Tiraspol +03:00"},
    {"name": "Europe/Ulyanovsk +04:00"},
    {"name": "Europe/Uzhgorod +03:00"},
    {"name": "Europe/Vaduz +02:00"},
    {"name": "Europe/Vatican +02:00"},
    {"name": "Europe/Vienna +02:00"},
    {"name": "Europe/Vilnius +03:00"},
    {"name": "Europe/Volgograd +04:00"},
    {"name": "Europe/Warsaw +02:00"},
    {"name": "Europe/Zagreb +02:00"},
    {"name": "Europe/Zaporozhye +03:00"},
    {"name": "Europe/Zurich +02:00"},
    {"name": "GB +01:00"},
    {"name": "GB-Eire +01:00"},
    {"name": "GMT +00:00"},
    {"name": "GMT+0 +00:00"},
    {"name": "GMT-0 +00:00"},
    {"name": "GMT0 +00:00"},
    {"name": "Greenwich +00:00"},
    {"name": "HST -10:00"},
    {"name": "Hongkong +08:00"},
    {"name": "Iceland +00:00"},
    {"name": "Indian/Antananarivo +03:00"},
    {"name": "Indian/Chagos +06:00"},
    {"name": "Indian/Christmas +07:00"},
    {"name": "Indian/Cocos +06:30"},
    {"name": "Indian/Comoro +03:00"},
    {"name": "Indian/Kerguelen +05:00"},
    {"name": "Indian/Mahe +04:00"},
    {"name": "Indian/Maldives +05:00"},
    {"name": "Indian/Mauritius +04:00"},
    {"name": "Indian/Mayotte +03:00"},
    {"name": "Indian/Reunion +04:00"},
    {"name": "Iran +04:30"},
    {"name": "Israel +03:00"},
    {"name": "Jamaica -05:00"},
    {"name": "Japan +09:00"},
    {"name": "Kwajalein +12:00"},
    {"name": "Libya +02:00"},
    {"name": "MET +01:00"},
    {"name": "MST -07:00"},
    {"name": "MST7MDT -06:00"},
    {"name": "Mexico/BajaNorte -07:00"},
    {"name": "Mexico/BajaSur -06:00"},
    {"name": "Mexico/General -05:00"},
    {"name": "NZ +12:00"},
    {"name": "NZ-CHAT +12:45"},
    {"name": "Navajo -06:00"},
    {"name": "PRC +08:00"},
    {"name": "PST8PDT -07:00"},
    {"name": "Pacific/Apia +13:00"},
    {"name": "Pacific/Auckland +12:00"},
    {"name": "Pacific/Bougainville +11:00"},
    {"name": "Pacific/Chatham +12:45"},
    {"name": "Pacific/Chuuk +10:00"},
    {"name": "Pacific/Easter -05:00"},
    {"name": "Pacific/Efate +11:00"},
    {"name": "Pacific/Enderbury +13:00"},
    {"name": "Pacific/Fakaofo +13:00"},
    {"name": "Pacific/Fiji +12:00"},
    {"name": "Pacific/Funafuti +12:00"},
    {"name": "Pacific/Galapagos -06:00"},
    {"name": "Pacific/Gambier -09:00"},
    {"name": "Pacific/Guadalcanal +11:00"},
    {"name": "Pacific/Guam +10:00"},
    {"name": "Pacific/Honolulu -10:00"},
    {"name": "Pacific/Johnston -10:00"},
    {"name": "Pacific/Kiritimati +14:00"},
    {"name": "Pacific/Kosrae +11:00"},
    {"name": "Pacific/Kwajalein +12:00"},
    {"name": "Pacific/Majuro +12:00"},
    {"name": "Pacific/Marquesas -09:30"},
    {"name": "Pacific/Midway -11:00"},
    {"name": "Pacific/Nauru +12:00"},
    {"name": "Pacific/Niue -11:00"},
    {"name": "Pacific/Norfolk +11:00"},
    {"name": "Pacific/Noumea +11:00"},
    {"name": "Pacific/Pago_Pago -11:00"},
    {"name": "Pacific/Palau +09:00"},
    {"name": "Pacific/Pitcairn -08:00"},
    {"name": "Pacific/Pohnpei +11:00"},
    {"name": "Pacific/Ponape +11:00"},
    {"name": "Pacific/Port_Moresby +10:00"},
    {"name": "Pacific/Rarotonga -10:00"},
    {"name": "Pacific/Saipan +10:00"},
    {"name": "Pacific/Samoa -11:00"},
    {"name": "Pacific/Tahiti -10:00"},
    {"name": "Pacific/Tarawa +12:00"},
    {"name": "Pacific/Tongatapu +13:00"},
    {"name": "Pacific/Truk +10:00"},
    {"name": "Pacific/Wake +12:00"},
    {"name": "Pacific/Wallis +12:00"},
    {"name": "Pacific/Yap +10:00"},
    {"name": "Poland +02:00"},
    {"name": "Portugal +01:00"},
    {"name": "ROC +08:00"},
    {"name": "ROK +09:00"},
    {"name": "Singapore +08:00"},
    {"name": "Turkey +03:00"},
    {"name": "UCT +00:00"},
    {"name": "US/Alaska -08:00"},
    {"name": "US/Aleutian -10:00"},
    {"name": "US/Arizona -07:00"},
    {"name": "US/Central -05:00"},
    {"name": "US/East-Indiana -04:00"},
    {"name": "US/Eastern -04:00"},
    {"name": "US/Hawaii -10:00"},
    {"name": "US/Indiana-Starke -05:00"},
    {"name": "US/Michigan -04:00"},
    {"name": "US/Mountain -06:00"},
    {"name": "US/Pacific -07:00"},
    {"name": "US/Samoa -11:00"},
    {"name": "UTC +00:00"},
    {"name": "Universal +00:00"},
    {"name": "W-SU +03:00"},
    {"name": "WET +00:00"},
    {"name": "Zulu +00:00"}
]
