import React, { useEffect, useState } from "react";
import CollapsibleCardComponent from "./CollapsibleCardComponent";
import ItemModifierForm from "./forms/ItemModifierForm";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ListGroup } from "reactstrap";
import ViewHelper from "../../helpers/ViewHelper";
import CONSTANTS from "../../constants";
import { useTranslation } from "react-i18next";

const MenuModifiersList = (props) => {
  const getItemsForView = (items) => {
    for (let i = 0; i < items.length; i++) {
      items[i].title = items[i].Option.option_name;
      items[i].id = items[i].menu_option_id;
    }

    return items;
  };

  const {
    modifiers,
    updateModifiers,
    showConfirmationDialog,
    toggleGlobalModifierModal,
    isDragDisabled: dragDisableProp,
    location: location,
  } = props;

  console.log("My Modifiers", modifiers);

  const [items, setItems] = useState(getItemsForView(modifiers));
  const [activeId, setActiveId] = useState(0);

  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  useEffect(() => {
    setItems(getItemsForView(modifiers));
  }, [props]);

  const updateItem = (item) => {
    let itemsCopy = items.map((i) => (i.id === item.id ? item : i));
    updateModifiers(itemsCopy);
  };

  const toggleCollapse = (id) => {
    if (id === activeId) setActiveId(0);
    else setActiveId(id);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const itemsCopy = ViewHelper.reorder(
        items,
        result.source.index,
        result.destination.index,
        true
    );
    setItems(itemsCopy);
    updateModifiers(itemsCopy);
  };

  const deleteItem = (item) => {
    showConfirmationDialog(
        () => {
          let itemsCopy = items.filter((i) => i.id !== item.id);
          updateModifiers(itemsCopy);
        },
        null,
        t("are-you-sure-you-want-to-remove-this-modifier-from-this-item")
    );
  };

  const options = {
    radio: t("single"),
    select: t("selector"),
    checkbox: t("checkbox"),
  };
  const menuType = [
    {
      value: "kitchen",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.KITCHEN,
    },
    {
      value: "bar",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.BAR,
    },
    {
      value: "dessert",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.DESSERT,
    },
    {
      value: "alternative_1",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.ALTERNATIVE_1,
    },
    {
      value: "alternative_2",
      label: CONSTANTS.VARIABLES.MENUS.TYPE.ALTERNATIVE_2,
    },
  ];

  console.log("Items", items);

  return (
      <>
        <ListGroup id="list-group-dnd">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                  <div ref={provided.innerRef}>
                    <div className="mb-1">
                      {items &&
                          items.map((item, index) => (
                              <>
                                {item.Option.OptionValues.some((value) => value.status === 1) &&
                                    (item.Option.OptionValues.length === 0 ? null : (
                                        <Draggable
                                            key={index}
                                            draggableId={item.id.toString()}
                                            index={index}
                                            isDragDisabled={false}
                                        >
                                          {(provided) => (
                                              <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  className="drag-wrapper"
                                              >
                                                {console.log(
                                                    item.Option.OptionValues[0].status,
                                                    "draggable"
                                                )}
                                                <>
                                                  <CollapsibleCardComponent
                                                      deleteCardItem={deleteItem.bind(this)}
                                                      badgeTitle={
                                                        options[item.Option.display_type]
                                                      }
                                                      // bodyColor="content-dark-bg-color"
                                                      item={item}
                                                      toggleCollapse={toggleCollapse.bind(
                                                          this
                                                      )}
                                                      isOpen={item.id === activeId}
                                                  >
                                                    <ItemModifierForm
                                                        showConfirmationDialog={
                                                          showConfirmationDialog
                                                        }
                                                        item={item}
                                                        updateModifier={updateItem.bind(this)}
                                                        location_id={location.loc_id}
                                                        menuType={menuType}
                                                    />
                                                  </CollapsibleCardComponent>
                                                </>
                                              </div>
                                          )}
                                        </Draggable>
                                    ))}
                              </>
                          ))}

                      {items && items.length === 0 ? (
                          <span>{t("no-variants-attached-to-this-item")}</span>
                      ) : null}
                    </div>
                  </div>
              )}
            </Droppable>
          </DragDropContext>
        </ListGroup>
      </>
  );
};

export default MenuModifiersList;

// <>
//       <ListGroup id="list-group-dnd">
//         <DragDropContext onDragEnd={onDragEnd}>
//           <Droppable droppableId="droppable">
//             {(provided) => (
//               <div ref={provided.innerRef}>
//                 <div className="mb-1">
//                   {items &&
//                     items.map((item, index) => (
//                       <>
//                         <Draggable
//                           key={index}
//                           draggableId={item.id.toString()}
//                           index={index}
//                           isDragDisabled={false}
//                         >
//                           {(provided) => (
//                             <div
//                               ref={provided.innerRef}
//                               {...provided.draggableProps}
//                               {...provided.dragHandleProps}
//                               className="drag-wrapper"
//                             >
//                               {console.log(
//                                 item.Option.OptionValues[0].status,
//                                 "draggable"
//                               )}
//                               <CollapsibleCardComponent
//                                 deleteCardItem={deleteItem.bind(this)}
//                                 badgeTitle={options[item.Option.display_type]}
//                                 // bodyColor="content-dark-bg-color"
//                                 item={item}
//                                 toggleCollapse={toggleCollapse.bind(this)}
//                                 isOpen={item.id === activeId}
//                               >
//                                 <ItemModifierForm
//                                   showConfirmationDialog={
//                                     showConfirmationDialog
//                                   }
//                                   item={item}
//                                   updateModifier={updateItem.bind(this)}
//                                   location_id={location.loc_id}
//                                   menuType={menuType}
//                                 />
//                               </CollapsibleCardComponent>
//                             </div>
//                           )}
//                         </Draggable>
//                       </>
//                     ))}
//                   {items && items.length === 0 ? (
//                     <span>{t("no-variants-attached-to-this-item")}</span>
//                   ) : null}
//                 </div>
//               </div>
//             )}
//           </Droppable>
//         </DragDropContext>
//       </ListGroup>
//     </>
