import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row, Media } from "reactstrap";
import NumericInput from "react-numeric-input";

import Select from "react-select";
import { Check, Plus, Trash, Image, X } from "react-feather";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";
import { defaultStyle } from "../numberInputStyles/InputStyles";
import Radio from "../../@vuexy/radio/RadioVuexy";
import MathHelper from "../../../helpers/MathHelper";
import ImageHelper from "../../../helpers/ImageHelper";
import Swal from "sweetalert2";
import "../../../assets/scss/plugins/forms/switch/react-toggle.scss";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const ItemModifierForm = (props) => {
  const [t, i18next] = useTranslation("global");

  // const formatOptionForDropdown = (optionValues) => {
  //   return optionValues.map((ov) => ({
  //     value: ov.option_value_id,
  //     label: ov.value,
  //   }));
  // };

  const formatOptionForDropdown = (optionValues, selectedValues) => {
    console.log("OPTIONVALUES", optionValues);
    console.log("selectedValues", selectedValues);

    const availableOptions = optionValues.filter((ov) => !selectedValues.includes(ov.option_value_id) && ov.status !== 0);

    return availableOptions.map((ov) => ({
      value: ov.option_value_id,
      label: ov.value,
    }));
  };

  const getMenuItem = (selected, optionValues) => {
    let selectedObj = optionValues.filter(
      (ov) => ov.option_value_id === selected.value
    )[0];
    return {
      menu_option_id: item.menu_option_id,
      option_value_id: selectedObj.option_value_id,
      option_id: selectedObj.option_id,
      menu_id: item.menu_id,
      new_price: selectedObj.price,
      quantity: 0,
      subtract_stock: 0,
      OptionValue: selectedObj,
      isNew: true,
      menu_option_value_id: MathHelper.getRndInteger(),
      item_tax: 0,
      base_price: 0,
      loc_id: props.location_id,
    };
  };

  let { item: propItem, updateModifier, showConfirmationDialog } = props;

  console.log("ITEMS", props);
  let selectedMenuType = {};
  if (props.item?.option_menu_type) {
    selectedMenuType = props.menuType.map((type) => {
      return type.value === props.item?.option_menu_type ? type : null;
    });
  }
  console.log(selectedMenuType, "selectedMenuType");
  const typeOptions = [
    {
      value: "radio",
      label: t("single"),
    },
    {
      value: "select",
      label: t("selector"),
    },
    {
      value: "checkbox",
      label: t("checkbox"),
    },
  ];

  const options = {
    radio: t("single"),
    select: t("selector"),
    checkbox: t("checkbox"),
  };

  propItem.type = {
    value: propItem.Option.display_type,
    label: options[propItem.Option.display_type],
  };

  const [item, setItem] = useState(propItem);
  const [optionAddToggle, setToggleAdd] = useState(false);
  const [optiohide, setOption] = useState();

  useEffect(() => {
    // const lang2 = localStorage.getItem("lang");
    // i18next.changeLanguage(lang2);

    let { item: propItem } = props;
    propItem.type = {
      value: propItem.Option.display_type,
      label: options[propItem.Option.display_type],
    };
    setItem(props.item);
  }, [props]);

  // const updateItem = (option, val) => {
  //   let itemCopy = item;
  //   let optionParts = option.split(".");
  //   let obj = itemCopy;
  //   for (let i = 0; i < optionParts.length - 1; i++) {
  //     if (isNaN(optionParts[i])) obj = obj[optionParts[i]];
  //     else obj = obj[parseInt(optionParts[i])];
  //   }
  //   if (option === "default_value_id" && val === obj["default_value_id"])
  //     val = 0;

  //   if (option === "base_price" && val == 0) {
  //     val = 1;
  //     obj["required"] = 1;
  //   } else if (option === "base_price" && val == 1) {
  //     val = 0;
  //   }

  //   if ((option === "min" || option === "max") && (val > 100 || val < 0))
  //     return;

  //   const opt = optionParts[optionParts.length - 1];
  //   if (opt === "new_price" && val !== "" && isNaN(val)) return;

  //   if (opt === "new_price" && val === "") val = null;

  //   obj[opt] = val;
  //   if (obj["base_price"] == 1) {
  //     obj["required"] = 1;
  //   }
  //   updateModifier(itemCopy);
  // };
  const updateItem = (option, val) => {
    let itemCopy = { ...item };
    let optionParts = option.split(".");
    let obj = itemCopy;

    for (let i = 0; i < optionParts.length - 1; i++) {
      if (isNaN(optionParts[i])) obj = obj[optionParts[i]];
      else obj = obj[parseInt(optionParts[i])];
    }

    if (option === "default_value_id" && val === obj["default_value_id"])
      val = 0;

    if (option === "base_price" && val == 0) {
      val = 1;
      obj["required"] = 1;
    } else if (option === "base_price" && val == 1) {
      val = 0;
    }

    if ((option === "min" || option === "max") && (val > 100 || val < 0)) {
      // Prevent setting invalid values and show an error
      console.error("Value should be between 0 and 100");
      return;
    }

    const opt = optionParts[optionParts.length - 1];

    if (opt === "new_price" && val !== "" && isNaN(val)) return;

    if (opt === "new_price" && val === "") val = null;

    if (opt === "min" && obj["max"] < val) {
      // If min is greater than max, set max equal to min and display an error
      obj["max"] = val;
      console.log(val, "MAX");
      // alert("Min value cannot be greater than Max value");
    } else if (opt === "max" && val < obj["min"]) {
      // If max is less than min, show an error and prevent further processing
      val = obj["min"];
      console.log(val, "MIN");
      // Swal.fire({
      //   icon: "error",
      //   text: "Max value cannot be less than Min value",
      //   timer: 2000,
      //   showConfirmButton: true,
      // });
      return;
    }

    obj[opt] = val;

    if (obj["base_price"] == 1) {
      obj["required"] = 1;
    }

    updateModifier(itemCopy);
  };

  const deleteItem = (index) => {
    console.log(index, "deleteItem");
    showConfirmationDialog(
      () => {
        console.log("deleting");
        let itemCopy = item;
        itemCopy.MenuOptionValues.splice(index, 1);
        updateModifier(itemCopy);
        console.log(itemCopy, "itemCopy");
        setOption(itemCopy);
      },
      () => {
        console.log("Don't delete");
      },
      t("are-you-sure-you-want-to-remove-this-option")
    );
  };

  const toggleOptionAdd = () => {
    console.log(optionAddToggle);
    setToggleAdd(!optionAddToggle);
  };

  const addSelectedItem = (selected) => {
    if (selected === null) return;
    const newItem = getMenuItem(selected, item.Option.OptionValues);
    item.MenuOptionValues.push(newItem);
    updateModifier(item);
    toggleOptionAdd();
  };

  // const addSelectedItem = (selected) => {
  //   if (selected === null) return;

  //   let itemCopy = { ...item }; // Create a shallow copy of the original item
  //   const newItem = getMenuItem(selected, item.Option.OptionValues);
  //   itemCopy.MenuOptionValues = [...itemCopy.MenuOptionValues, newItem];

  //   // Filter out the selected option from the available options
  //   const updatedOptions = itemCopy.Option.OptionValues.filter(
  //     (option) => option.option_value_id !== selected.value
  //   );

  //   // Update the available options in the itemCopy
  //   itemCopy.Option = {
  //     ...itemCopy.Option,
  //     OptionValues: updatedOptions,
  //   };

  //   updateModifier(itemCopy);
  //   toggleOptionAdd();
  // };

  //   const addSelectedItem = (selected) => {
  //   if (selected === null) return;

  //   let itemCopy = { ...item }; // Create a shallow copy of the original item
  //   const newItem = getMenuItem(selected, item.Option.OptionValues);
  //   itemCopy.MenuOptionValues = [...itemCopy.MenuOptionValues, newItem];

  //   updateModifier(itemCopy);
  //   toggleOptionAdd();
  // };
  const handleStatusChange = (value) => {
    updateItem("required", value);
  };

  // const [required, setRequired] = useState(item.required);
  // const handleStatusChange = (value) => {
  //   console.log("Item Status Changed:", value);
  //   if (required !== value) {
  //     setRequired(value);
  //   }
  //   updateItem("required", value);
  // };

  const renderMenuOptionValues = (optionValues, options_layout, default_id) => {
    console.log("OPTIONSVAL", optionValues);
    console.log("DEFAULT", default_id);
    return optionValues.filter((option) => option?.OptionValue?.status !== 0).map((option, index) => {
        // let image = ImageHelper.getImageURL(
        //   option.OptionValue.img,
        //   "?func=crop&w=120&h=80"
        // );
        if (!option.OptionValue) return null;
        console.log("Here is the Option", option);
        return options_layout == 1 ? (
          <React.Fragment>
            <Col xs={6} className="mb-25">
              <Input type="text" id="optionName" value={option.OptionValue.value} placeholder={t("option")} readOnly/>
            </Col>
            <Col xs={3} className="mb-25">
              <Input
                type="text"
                id="optionPrice"
                value={option.new_price === null ? "" : option.new_price}
                placeholder={t("price")}
                onChange={(e) =>
                  updateItem(
                    `MenuOptionValues.${index}.new_price`,
                    e.target.value
                  )
                }
              />
            </Col>
            <Col xs={3} className="mb-25 display-flex align-items-center">
              <Button
                className="btn-icon overflow-visible p-0 m-0"
                color="flat-danger"
                tabindex="-1"
                onClick={(e) => {
                  e.currentTarget.blur();
                  deleteItem(index);
                }}
              >
                <Trash className="align-baseline" size={16} />
              </Button>
              <Checkbox
                tabindex="1"
                color="primary"
                icon={<Check className="vx-icon" size={16} />}
                defaultChecked={
                  parseInt(option.menu_option_value_id) === default_id
                }
                checked={parseInt(option.menu_option_value_id) === default_id}
                className="ml-75 p-0"
                value={option.menu_option_value_id}
                onChange={(e) => updateItem(`default_value_id`, e.target.value)}
              />
            </Col>
          </React.Fragment>
        ) : (
          <React.Fragment key={option.option_value_id}>
            {/* <Col xs={4} className='mb-25'>
              <FormGroup>
                <Label for="photoDiv">Image:</Label>
                <div tabIndex="0" id="photoDiv" className='display-flex flex-column border border-2 border-light cursor-pointer' style={{ position: 'relative', height: 100, width: 130 }}>
                  <div style={{ textAlign: '-webkit-center' }}>
                    {image ?
                      <div>
                        <Media
                          object
                          src={image}
                          alt="image"
                          style={{ maxWidth: 128, maxHeight: 118, width: 98, height:98 }}
                          className='align-self-center'
                        />
                      </div> :
                      <div className='justify-content-center align-self-center' style={{ padding: 9, height: 50, width: 50, }}>
                        <Image size={32} className='align-self-center' />
                      </div>
                    }
                  </div>
                  {image ? null :
                    <React.Fragment>
                      <span className='font-small-2 primary align-self-center'>No Image Available</span>
                    </React.Fragment>
                  }
                </div>
              </FormGroup>
            </Col> */}
            <Col sm={12} className="mb-25">
              <Row>
                <Col sm={8} md={6} lg={10} className="mb-25">
                  <FormGroup>
                    <Label>Option:</Label>
                    <Input
                      type="text"
                      id="optionName"
                      value={option.OptionValue.value}
                      placeholder={t("option")}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col sm={4} md={6} lg={2} className="mb-25">
                  <FormGroup>
                    <Label>Price:</Label>
                    <Input
                      type="text"
                      id="optionPrice"
                      value={option.new_price === null ? "" : option.new_price}
                      placeholder={t("price")}
                      onChange={(e) =>
                        updateItem(
                          `MenuOptionValues.${index}.new_price`,
                          e.target.value
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-25 display-flex align-items-center">
                  {/* <Label>Default:</Label> */}
                  {/* <Checkbox
                  tabIndex="1"
                  color="primary"
                  icon={<Check className="vx-icon" size={16} />}
                  defaultChecked={
                    parseInt(option.menu_option_value_id) === default_id
                  }
                  checked={parseInt(option.menu_option_value_id) === default_id}
                  className="ml-75 p-0"
                  value={option.menu_option_value_id}
                  onChange={(e) =>
                    updateItem(`default_value_id`, e.target.value)
                  }
                /> */}
                  {/* <Checkbox
                    tabIndex="1"
                    color="primary"
                    icon={<Check className="vx-icon" size={16} />}
                    checked={
                      parseInt(option.menu_option_value_id) === default_id
                    }
                    className="ml-75 p-0"
                    value={option.menu_option_value_id}
                    onChange={(e) =>
                      updateItem(`default_value_id`, e.target.value)
                    }
                  /> */}

                  <Button
                    className="btn-icon overflow-visible p-0 m-0"
                    color="flat-danger"
                    tabIndex="-1"
                    onClick={(e) => {
                      e.currentTarget.blur();
                      deleteItem(index);
                    }}
                  >
                    {/* <Trash size={16} /> */}
                  </Button>
                </Col>
              </Row>
            </Col>
          </React.Fragment>
        );
      });
  };

  const renderMenuExtras = (item) => {
    console.log(item.Option.option_name, "itemssssssssssssss");
    // const hasMenuOptionValues =
    //   item.MenuOptionValues && item.MenuOptionValues.length > 0;

    // if (!hasMenuOptionValues) {
    //   return null; // Don't render anything when MenuOptionValues is empty
    // }
    if (item.type.label === options.radio) {
      return (
        <React.Fragment>
          <Col xs={12} className="my-75 mb-0">
            <div
              id="status"
              onChange={(e) => updateItem("required", parseInt(e.target.value))}
            >
              <div className="d-inline-block mr-1">
                <Radio
                  label={t("mandatory")}
                  checked={item.required === 1}
                  name={`required-${item.menu_option_id}`}
                  value={1}
                  onChange={() => handleStatusChange(1)}
                />
              </div>

              <div className="d-inline-block mr-1">
                <Radio
                  label={t("optional")}
                  name={`required-${item.menu_option_id}`}
                  checked={item.required === 0}
                  value={0}
                  onChange={() => handleStatusChange(0)}
                />
              </div>
            </div>
          </Col>
          <Row>
            <Col xs={12}>
              <Label>{t("menu-type-1")} :</Label>
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "var(--primary)"
                      : state.isFocused
                      ? "#7367f099"
                      : "",
                  }),
                }}
                className="React no-pointer-events"
                classNamePrefix="select"
                defaultValue={selectedMenuType}
                name="menu_type"
                options={props.menuType}
                onChange={(e) => updateItem(`option_menu_type`, e.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="display-flex align-items-center">
              <FormGroup>
                <Label>{t("item-tax")}</Label>
                <Input
                  type="text"
                  id="optionPrice"
                  value={item.item_tax}
                  placeholder={t("item-tax-0")}
                  onChange={(e) => updateItem(`item_tax`, e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12} className="display-flex align-items-center">
              <Label>{t("menu-price-dependable-on-this-option")}</Label>

              <Checkbox
                tabindex="1"
                color="primary"
                icon={<Check className="vx-icon" size={16} />}
                defaultChecked={parseInt(item.base_price) === 1}
                className="ml-75 p-0"
                onChange={(e) => updateItem(`base_price`, e.target.value)}
                value={item.base_price}
              />
            </Col>
          </Row>
             <div>
            Check this box if you want the prices of selected variants to
            replace the original price of the menu item.
            <a
              href="https://docs.servall.be/index.php/docs/docly-documentation/manager/menu/when-to-apply-variant-prices-only-7118/"
              target="_blank"
              rel="noopener noreferrer"
            >
              More information
            </a>
          </div>

          <div>(Required for item size, Small, Medium, Large etc.)</div> */}
        </React.Fragment>
      );
    } else {
      return (
        <>
          <Row>
            {item.type.label !== options.checkbox && (
              <Col xs={6}>
                <FormGroup className="custom-number-input-style">
                  <Label for="min">{t("min-selections-0")} :</Label>
                  <NumericInput
                    id="min"
                    min={1}
                    max={100}
                    value={item.min}
                    mobile
                    style={defaultStyle}
                    // onChange={(e) => {
                    //   if (e > 0) {
                    //     updateItem("min", e);
                    //   }
                    // }}
                    onKeyDown={(e) => {
                      if (e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const newValue = parseInt(e);
                      if (!isNaN(newValue)) {
                        if (newValue === 0) {
                          updateItem("min", 1); // Replace 0 with 1
                        } else if (newValue > 0) {
                          updateItem("min", newValue); // Update for other positive values
                        }
                      }
                    }}

                    // onKeyDown={(e) => {
                    //   if (e.key === "0") {
                    //     console.log(item.min, "sssssssssssssssssssssssssss");
                    //     if (item.min === null || item.min === undefined) {
                    //       console.log(e.target.value, "1234567890");
                    //       e.preventDefault();
                    //       updateItem("min", 1);
                    //     }
                    //   }
                    // }}
                  />
                </FormGroup>
              </Col>
            )}

            <Col xs={6}>
              <FormGroup className="custom-number-input-style">
                <Label for="max">{t("max-selections-0")} :</Label>
                <NumericInput
                  id="max"
                  // min={1}
                  min={item.min}
                  max={100}
                  value={item.max}
                  mobile
                  style={defaultStyle}
                  // onChange={(e) => updateItem("max", e)}
                  // onChange={(e) => {
                  //   const newValue = parseInt(e);
                  //   if (!isNaN(newValue)) {
                  //     if (newValue === 0) {
                  //       updateItem("max", 1); // Replace 0 with 1
                  //     } else if (newValue < item.min) {
                  //       toast.error(
                  //         "Maximum value cannot be less than minimum value."
                  //       );
                  //     } else {
                  //       updateItem("max", newValue);
                  //     }
                  //   }
                  // }}
                  onChange={(e) => {
                    const newValue = parseInt(e);
                    if (!isNaN(newValue)) {
                      if (newValue === 0) {
                        toast.error(
                          "Maximum value cannot be less than minimum value."
                        );
                        updateItem("max", 1);
                      } else if (newValue < item.min) {
                        toast.error(
                          "Maximum value cannot be less than minimum value."
                        );
                      } else {
                        updateItem("max", newValue);
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Label>{t("menu-type-1")}</Label>
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "var(--primary)"
                      : state.isFocused
                      ? "#7367f099"
                      : "",
                  }),
                }}
                className="React no-pointer-events"
                classNamePrefix="select"
                defaultValue={selectedMenuType}
                name="menu_type"
                options={props.menuType}
                onChange={(e) => updateItem(`option_menu_type`, e.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="display-flex align-items-center">
              <FormGroup>
                <Label>{t("item-tax")}</Label>
                <Input
                  type="text"
                  id="optionPrice"
                  value={item.item_tax}
                  placeholder={t("item-tax-0")}
                  onChange={(e) => updateItem(`item_tax`, e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12} className="display-flex align-items-center">
              <Label>{t("menu-price-dependable-on-this-option")}</Label>
              <Checkbox
                tabindex="1"
                color="primary"
                icon={<Check className="vx-icon" size={16} />}
                defaultChecked={parseInt(item.base_price) === 1}
                className="ml-75 p-0"
                onChange={(e) => updateItem(`base_price`, e.target.value)}
                value={item.base_price}
              />
            </Col>
          </Row>
           <div>
            Check this box if you want the prices of selected variants to
            replace the original price of the menu item.
            <a
              href="https://docs.servall.be/index.php/docs/docly-documentation/manager/menu/when-to-apply-variant-prices-only-7118/"
              target="_blank"
              rel="noopener noreferrer"
            >
              More information
            </a>
          </div>

          <div>(Required for item size, Small, Medium, Large etc.)</div> */}
        </>
      );
    }
  };

  return (
    <div>
      <FormGroup className="row">
        {item.options_layout == 1 && (
          <>
            <Col xs={6}>
              <Label>{t("options")}</Label>
            </Col>
            <Col xs={3}>
              <Label>{t("price")}</Label>
            </Col>
            <Col xs={3}>
              <Label>{t("actions")}</Label>
            </Col>
          </>
        )}
        {renderMenuOptionValues(
          item.MenuOptionValues,
          item.Option.options_layout,
          parseInt(item.default_value_id)
        )}
      </FormGroup>
      <div className="row mb-1">
        {optionAddToggle ? (
          <React.Fragment>
            <Col xs={6}>
              <Select
                className="React"
                classNamePrefix="select"
                name="color"
                options={formatOptionForDropdown(
                  item.Option.OptionValues,
                  item.MenuOptionValues.map((ov) => ov.option_value_id)
                )}
                onChange={(e) => addSelectedItem(e)}
                onBlur={toggleOptionAdd}
                autoFocus={true}
                defaultMenuIsOpen
              />

              {/* <Select
                className="React"
                classNamePrefix="select"
                name="color"
                options={formatOptionForDropdown(item.Option.OptionValues)}
                onChange={(e) => addSelectedItem(e)}
                onBlur={toggleOptionAdd}
                autoFocus={true}
                defaultMenuIsOpen
              /> */}
            </Col>
          </React.Fragment>
        ) : (
          <Col xs={12}>
            {/* <Button
              outline
              className="font-weight-bold custom-padding primary"
              color={"primary"}
              size="md"
              onClick={toggleOptionAdd}
            >
              <Plus className="mx-50" size={14} />{" "}
              <span className="ml-50 mr-50 primary">
                {t("add-more-option")}
              </span>
            </Button> */}
          </Col>
        )}
      </div>
      {renderMenuExtras(item)}
    </div>
  );
};

export default ItemModifierForm;
