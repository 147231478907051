import React from "react";
import { connect } from "react-redux";
import Draggable from "react-draggable";
import {
  createTable,
  getTableByFloorId,
  deleteTable,
} from "../../../redux/actions/tables";
import { getLocation } from "../../../redux/actions/locations";
import SelectLocation from "../../shared/SelectLocation";
import LocationSelectHeader from "../../shared/LocationSelectHeader";
import LocationService from "../../../services/LocationService";
import { getAllFloors } from "../../../redux/actions/floors";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import tableFloorStyle from "./dragTables.module.css";
import SpinnerComponent from "../../@vuexy/spinner/Fallback-spinner";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import TableModal from "../../shared/forms/TableModal";
import ConfirmationModal from "../../shared/forms/ConfirmationModal";
import toast, { Toaster } from "react-hot-toast";
import * as classnames from "classnames";

class FloorTablesManagement extends React.Component {
  state = {
    selectLocation: false,
    location: null,
    loading: true,
    tables: [],
    floor: this.props.location.state.floor,
    selectedTable: null,
    isTableModalOpen: false,
    selectedTableForModal: null,
    deletingTable: false,
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    },
  };

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchMenu = true) {
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
      await this.props.getAllFloors(loc_id);
      console.log("Flooes 2: ", this.props.floors);
      if (this.state.floor) {
        await this.props.getTableByFloorId(loc_id, this.state.floor.id);
      }
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.locationData)),
      tables: this.props.tables,
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  onStop = async (event, dragElement, tableIndex) => {
    let tableState = this.state.tables[tableIndex];

    if (
      tableState.position_x != dragElement.x ||
      tableState.position_y != dragElement.y
    ) {
      tableState.position_x = dragElement.x;
      tableState.position_y = dragElement.y;
      this.setState({ table: this.state.tables });
      let tableArray = [];
      tableArray.push(tableState);
      try {
        await this.props.createTable(
          tableState.loc_id,
          tableArray,
          this.state.tables
        );
        this.setState({ tables: this.props.tables });
      } catch (e) {
        toast.error(e.toString());
      }
    }
  };

  onStart = async (e, drag, table) => {
    this.setState({ selectedTable: table });
  };

  async toggleTableModal(table = null) {
    let {
      state: { isTableModalOpen },
    } = this;
    const selectedTableForModal = table;
    isTableModalOpen = !isTableModalOpen;
    return this.setState({
      selectedTableForModal,
      isTableModalOpen,
      deletingTable: false,
    });
  }

  async deleteTable(table) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        this.setState({
          confirmationDialog: defaultState,
          deletingTable: true,
        });
        let deleteTablesId = [];
        deleteTablesId.push(table.table_id);
        try {
          let deleteTableRes = await this.props.deleteTable(
            this.state.location.loc_id,
            deleteTablesId,
            this.state.floor.id
          );
          this.setState({ tables: this.props.tables });
        } catch (e) {
          toast.error(e.toString());
        }

        await this.toggleTableModal();

        window.location.reload();
      },
      rejectCallback: () => {
        this.setState({
          confirmationDialog: defaultState,
          deletingTable: false,
        });
      },
      title: `Are you sure to delete ${table.table_name}`,
      header: this.props.t("delete-table"),
    };
    this.setState({ confirmationDialog });
  }

  // async saveTable(table) {
  //   let newTable = [];
  //   newTable.push(table);
  //   try {
  //     await this.props.createTable(
  //       this.state.location.loc_id,
  //       newTable,
  //       this.state.tables
  //     );
  //     this.setState({ tables: this.props.tables });
  //   } catch (e) {
  //     this.setState({ tables: this.state.tables });
  //     toast.error(e.toString());
  //   }
  //   await this.toggleTableModal();
  // }

  async saveTable(table) {
    if (table.table_id) {
      try {
        await this.props.createTable(
          this.state.location.loc_id,
          [table],
          this.state.tables
        );
        this.setState({ tables: this.props.tables });
      } catch (e) {
        toast.error(e.toString());
      }
    } else {
      const newTable = { ...table };
      const randomX = Math.floor(Math.random() * 500);
      const randomY = Math.floor(Math.random() * 500);

      newTable.position_x = randomX;
      newTable.position_y = randomY;

      const newTables = [...this.state.tables, newTable];

      try {
        await this.props.createTable(
          this.state.location.loc_id,
          [newTable],
          newTables
        );
        this.setState({ tables: this.props.tables });
      } catch (e) {
        this.setState({ tables: this.state.tables });
        toast.error(
          "Table Limit Exceeded. You cannot create more tables at this time."
        );
      }
    }

    this.toggleTableModal();
  }

  async getFloorTables(selectedfloor) {
    this.setState({
      floor: selectedfloor,
      selectedTableForModal: null,
      tables: [],
      selectedTable: null,
    });
    let floors = await this.props.getAllFloors(this.state.location.loc_id);
    this.props.floors.map((floor) => {
      if (floor.id === selectedfloor.id) {
        this.setState({ tables: floor.Tables });
      }
    });
  }

  render() {
    const { t } = this.props;
    let {
      floor,
      loading,
      selectLocation,
      selectedTable,
      confirmationDialog,
      tables,
    } = this.state;
    let { locationData, floors } = this.props;

    if (loading) return <SpinnerComponent />;
    const locationName = locationData ? locationData.name : "";
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <>
        <Toaster position="top-right" />
        <div className="location ecommerce-application">
          <div className="display-flex flex-row justify-content-between mb-1">
            <div className="d-flex">
              <LocationSelectHeader
                text={locationName}
                onIconClick={this.toggleLocation.bind(this)}
              />
              {floor.id ? (
                <div className="ml-1">
                  <LocationSelectHeader text={floor.floor_name} />
                </div>
              ) : null}
            </div>
          </div>

          <Button
            className="mb-1 "
            color="primary"
            size="md"
            onClick={() => this.toggleTableModal()}
          >
            <span className="ml-1 mr-1 d-md-inline ">{t("new-table")}</span>
          </Button>
          {selectedTable != null && (
            <Button
              className="mb-1 mr-1 ml-1"
              color="secondary"
              size="md"
              onClick={() => this.toggleTableModal(selectedTable)}
            >
              <span className="ml-1 mr-1 d-md-inline ">
                {t("edit")} {selectedTable.table_name}
              </span>
            </Button>
          )}
        </div>
        <div className="row">
          <div className={`${tableFloorStyle.floor_selector}`}>
            {floors.map((fl) => {
              return (
                <span
                  class={`${tableFloorStyle.button}${" "}${
                    tableFloorStyle.button
                  }${" "}${floor?.id === fl.id ? tableFloorStyle.active : ""}`}
                  onClick={() => this.getFloorTables(fl, floors)}
                >
                  {fl.floor_name}
                </span>
              );
            })}
          </div>
        </div>
        <div
          className="row"
          style={{
            height: "40rem",
            width: "96rem",
            margin: "auto",
            position: "relative",
            overflow: "auto",
            border: "20px solid black",
            backgroundColor: `${floor.floor_color}`,
          }}
        >
          <div
            className="col-12"
            // style={{
            //   backgroundColor: `${floor.floor_color}`,
            //   position: 'relative',
            // }}
          >
            {tables.map((table, index) => {
              let shape = table.table_shape;
              return (
                <Draggable
                  onStop={(event, dragElement) =>
                    this.onStop(event, dragElement, index)
                  }
                  onStart={(e, drag) => this.onStart(e, drag, table)}
                  bounds="parent"
                  position={{ x: table.position_x, y: table.position_y }}
                >
                  <div
                    className={`${tableFloorStyle.shapeclass}${" "}${
                      tableFloorStyle[shape]
                    }`}
                    onMouseEnter={this.onDropAreaMouseEnter}
                    onMouseLeave={this.onDropAreaMouseLeave}
                    style={{
                      backgroundColor: table.table_color,
                      position: "absolute",
                      transform: `translate(${table.position_x}px, ${table.position_y}px)`,
                      textAlign: "center",
                    }}
                  >
                    <span>{table.table_name}</span>
                    <span className={`${tableFloorStyle.seat_capacity}`}>
                      {table.max_capacity}
                    </span>

                    {table.table_status === false && (
                      <div>
                        <span
                          style={{ fontSize: "20px" }}
                          role="img"
                          aria-label="Not Available"
                          title="Not Available"
                        >
                          🚫
                        </span>
                      </div>
                    )}
                  </div>
                </Draggable>
              );
            })}
          </div>
          <TableModal
            deleting={this.state.deletingTable}
            deleteTable={this.deleteTable.bind(this)}
            saveTable={this.saveTable.bind(this)}
            isOpen={this.state.isTableModalOpen}
            table={this.state.selectedTableForModal}
            loc_id={this.state.location.loc_id}
            toggleModal={this.toggleTableModal.bind(this)}
            floor={this.state.floor}
          />
          <ConfirmationModal
            isOpen={confirmationDialog.show}
            accept={confirmationDialog.acceptCallback.bind(this)}
            reject={confirmationDialog.rejectCallback.bind(this)}
            title={confirmationDialog.title}
            header={confirmationDialog.header}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    floors: {
      allFloors: { data: floorsData, loading: floorLoading },
    },
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
    tables: {
      alltables: { data: tables, loading: loading },
    },
  } = state;
  return {
    floors: floorsData,
    locationData,
    loading: floorLoading || locationLoading || loading,
    tables,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    createTable,
    getLocation,
    getAllFloors,
    getTableByFloorId,
    deleteTable,
  })(FloorTablesManagement)
);

// import React from "react";
// import { connect } from "react-redux";
// import Draggable from "react-draggable";
// import {
//   createTable,
//   getTableByFloorId,
//   deleteTable,
// } from "../../../redux/actions/tables";
// import { getLocation } from "../../../redux/actions/locations";
// import SelectLocation from "../../shared/SelectLocation";
// import LocationSelectHeader from "../../shared/LocationSelectHeader";
// import LocationService from "../../../services/LocationService";
// import { getAllFloors } from "../../../redux/actions/floors";
// import { withTranslation } from "react-i18next";
// import i18n from "i18next";
// import tableFloorStyle from "./dragTables.module.css";
// import SpinnerComponent from "../../@vuexy/spinner/Fallback-spinner";
// import { Button, Nav, NavItem, NavLink } from "reactstrap";
// import TableModal from "../../shared/forms/TableModal";
// import ConfirmationModal from "../../shared/forms/ConfirmationModal";
// import toast, { Toaster } from "react-hot-toast";
// import * as classnames from "classnames";

// class FloorTablesManagement extends React.Component {
//   state = {
//     selectLocation: false,
//     location: null,
//     loading: true,
//     tables: [],
//     scrollX: 0, // Track horizontal scroll position
//     scrollY: 0, // Track vertical scroll position
//     floor: this.props.location.state.floor,
//     selectedTable: null,
//     isTableModalOpen: false,
//     selectedTableForModal: null,
//     deletingTable: false,
//     confirmationDialog: {
//       show: false,
//       acceptCallback: () => {},
//       rejectCallback: () => {},
//       title: "",
//       header: "",
//     },
//   };

//   async componentDidMount() {
//     const savedLanguage = localStorage.getItem("lang");
//     if (savedLanguage) {
//       i18n.changeLanguage(savedLanguage);
//     }

//     const location = LocationService.getLocation();
//     if (location) {
//       await this.refreshData(location.loc_id);
//       this.setState({ loading: false });
//     } else {
//       this.setState({ loading: false, selectLocation: true });
//     }
//   }

//   async refreshData(loc_id, initialize = true, fetchMenu = true) {
//     if (!this.state.location || loc_id !== this.state.location.loc_id) {
//       await this.props.getLocation(loc_id);
//       await this.props.getAllFloors(loc_id);
//       console.log("Flooes 2: ", this.props.floors);
//       if (this.state.floor) {
//         await this.props.getTableByFloorId(loc_id, this.state.floor.id);
//       }
//     }

//     this.setState({
//       loading: false,
//       location: JSON.parse(JSON.stringify(this.props.locationData)),
//       tables: this.props.tables,
//     });
//   }

//   toggleLocation() {
//     this.setState({ selectLocation: !this.state.selectLocation });
//   }

//   async onSelect(location) {
//     this.setState({ loading: true });
//     LocationService.setLocation(location);
//     await this.refreshData(location.loc_id);
//     this.setState({ selectLocation: false, loading: false });
//   }

//   onStop = async (event, dragElement, tableIndex) => {
//     let tableState = this.state.tables[tableIndex];

//     if (
//       tableState.position_x != dragElement.x ||
//       tableState.position_y != dragElement.y
//     ) {
//       tableState.position_x = dragElement.x;
//       tableState.position_y = dragElement.y;
//       this.setState({ table: this.state.tables });
//       let tableArray = [];
//       tableArray.push(tableState);
//       try {
//         await this.props.createTable(
//           tableState.loc_id,
//           tableArray,
//           this.state.tables
//         );
//         this.setState({ tables: this.props.tables });
//       } catch (e) {
//         toast.error(e.toString());
//       }
//     }
//   };

//   onStart = async (e, drag, table) => {
//     this.setState({ selectedTable: table });
//   };

//   async toggleTableModal(table = null) {
//     let {
//       state: { isTableModalOpen },
//     } = this;
//     const selectedTableForModal = table;
//     isTableModalOpen = !isTableModalOpen;
//     return this.setState({
//       selectedTableForModal,
//       isTableModalOpen,
//       deletingTable: false,
//     });
//   }

//   async deleteTable(table) {
//     const defaultState = {
//       show: false,
//       acceptCallback: () => {},
//       rejectCallback: () => {},
//       title: "",
//       header: "",
//     };

//     const confirmationDialog = {
//       show: true,
//       acceptCallback: async () => {
//         this.setState({
//           confirmationDialog: defaultState,
//           deletingTable: true,
//         });
//         let deleteTablesId = [];
//         deleteTablesId.push(table.table_id);
//         try {
//           let deleteTableRes = await this.props.deleteTable(
//             this.state.location.loc_id,
//             deleteTablesId,
//             this.state.floor.id
//           );
//           this.setState({ tables: this.props.tables });
//         } catch (e) {
//           toast.error(e.toString());
//         }

//         await this.toggleTableModal();

//         window.location.reload();
//       },
//       rejectCallback: () => {
//         this.setState({
//           confirmationDialog: defaultState,
//           deletingTable: false,
//         });
//       },
//       title: `Are you sure to delete ${table.table_name}`,
//       header: this.props.t("delete-table"),
//     };
//     this.setState({ confirmationDialog });
//   }

//   // async saveTable(table) {
//   //   let newTable = [];
//   //   newTable.push(table);
//   //   try {
//   //     await this.props.createTable(
//   //       this.state.location.loc_id,
//   //       newTable,
//   //       this.state.tables
//   //     );
//   //     this.setState({ tables: this.props.tables });
//   //   } catch (e) {
//   //     this.setState({ tables: this.state.tables });
//   //     toast.error(e.toString());
//   //   }
//   //   await this.toggleTableModal();
//   // }

//   async saveTable(table) {
//     if (table.table_id) {
//       try {
//         await this.props.createTable(
//           this.state.location.loc_id,
//           [table],
//           this.state.tables
//         );
//         this.setState({ tables: this.props.tables });
//       } catch (e) {
//         toast.error(e.toString());
//       }
//     } else {
//       const newTable = { ...table };
//       const randomX = Math.floor(Math.random() * 500);
//       const randomY = Math.floor(Math.random() * 500);

//       newTable.position_x = randomX;
//       newTable.position_y = randomY;

//       const newTables = [...this.state.tables, newTable];

//       try {
//         await this.props.createTable(
//           this.state.location.loc_id,
//           [newTable],
//           newTables
//         );
//         this.setState({ tables: this.props.tables });
//       } catch (e) {
//         this.setState({ tables: this.state.tables });
//         toast.error(
//           "Table Limit Exceeded. You cannot create more tables at this time."
//         );
//       }
//     }

//     this.toggleTableModal();
//   }

//   async getFloorTables(selectedfloor) {
//     this.setState({
//       floor: selectedfloor,
//       selectedTableForModal: null,
//       tables: [],
//       selectedTable: null,
//     });
//     let floors = await this.props.getAllFloors(this.state.location.loc_id);
//     this.props.floors.map((floor) => {
//       if (floor.id === selectedfloor.id) {
//         this.setState({ tables: floor.Tables });
//       }
//     });
//   }
//   handleScroll = (event) => {
//     // Update the scroll position on scroll event
//     this.setState({
//       scrollX: event.target.scrollLeft,
//       scrollY: event.target.scrollTop,
//     });
//   };

//   render() {
//     const { t } = this.props;
//     let {
//       floor,
//       loading,
//       selectLocation,
//       selectedTable,
//       confirmationDialog,
//       tables,
//       scrollX,
//       scrollY,
//     } = this.state;
//     let { locationData, floors } = this.props;
//     const containerHeight = Math.max(
//       document.documentElement.clientHeight,
//       window.innerHeight || 0
//     );
//     const containerWidth = Math.max(
//       document.documentElement.clientWidth,
//       window.innerWidth || 0
//     );

//     const bottomBoundary = containerHeight - scrollY - 40; // Consider container border

//     if (loading) return <SpinnerComponent />;
//     const locationName = locationData ? locationData.name : "";
//     if (selectLocation)
//       return (
//         <SelectLocation
//           onBack={this.toggleLocation.bind(this)}
//           onSelect={this.onSelect.bind(this)}
//         />
//       );

//     return (
//       <>
//         <Toaster position="top-right" />
//         <div className="location ecommerce-application">
//           <div className="display-flex flex-row justify-content-between mb-1">
//             <div className="d-flex">
//               <LocationSelectHeader
//                 text={locationName}
//                 onIconClick={this.toggleLocation.bind(this)}
//               />
//               {floor.id ? (
//                 <div className="ml-1">
//                   <LocationSelectHeader text={floor.floor_name} />
//                 </div>
//               ) : null}
//             </div>
//           </div>

//           <Button
//             className="mb-1 "
//             color="primary"
//             size="md"
//             onClick={() => this.toggleTableModal()}
//           >
//             <span className="ml-1 mr-1 d-md-inline ">{t("new-table")}</span>
//           </Button>
//           {selectedTable != null && (
//             <Button
//               className="mb-1 mr-1 ml-1"
//               color="secondary"
//               size="md"
//               onClick={() => this.toggleTableModal(selectedTable)}
//             >
//               <span className="ml-1 mr-1 d-md-inline ">
//                 {t("edit")} {selectedTable.table_name}
//               </span>
//             </Button>
//           )}
//         </div>
//         <div className="row">
//           <div className={`${tableFloorStyle.floor_selector}`}>
//             {floors.map((fl) => {
//               return (
//                 <span
//                   class={`${tableFloorStyle.button}${" "}${
//                     tableFloorStyle.button
//                   }${" "}${floor?.id === fl.id ? tableFloorStyle.active : ""}`}
//                   onClick={() => this.getFloorTables(fl, floors)}
//                 >
//                   {fl.floor_name}
//                 </span>
//               );
//             })}
//           </div>
//         </div>
//         <div
//           className="row"
//           style={{
//             height: "40rem",
//             width: "96rem", // Adjusted width to fit all tables
//             margin: "auto",
//             position: "relative",
//             overflowX: "auto", // Enable horizontal scrolling
//             overflowY: "auto", // Enable vertical scrolling
//             border: "20px solid black",
//             backgroundColor: floor.floor_color,
//           }}
//           onScroll={this.handleScroll} // Handle scroll event
//           // style={{
//           //   height: "40rem",
//           //   width: "96rem", // Adjusted width to fit all tables
//           //   margin: "auto",
//           //   position: "relative",
//           //   overflowX: "auto", // Enable horizontal scrolling
//           //   overflowY: "visible", // Enable horizontal scrolling
//           //   border: "20px solid black",
//           //   backgroundColor: `${floor.floor_color}`,
//           //   // height: "40rem",
//           //   // width: "96rem",
//           //   // margin: "auto",
//           //   // position: "relative",
//           //   // overflow: "auto",
//           //   // border: "20px solid black",
//           //   // backgroundColor: `${floor.floor_color}`,
//           // }}
//         >
//           <div
//             className="col-12"
//             style={{ whiteSpace: "nowrap" }}
//             // style={{
//             //   backgroundColor: `${floor.floor_color}`,
//             //   position: 'relative',
//             // }}
//           >
//             {tables.map((table, index) => {
//               let shape = table.table_shape;

//               return (
//                 <Draggable
//                   onStop={(event, dragElement) =>
//                     this.onStop(event, dragElement, index)
//                   }
//                   onStart={(e, drag) => this.onStart(e, drag, table)}
//                   // bounds="parent"
//                   // bounds={{
//                   //   left: 0,
//                   //   top: 0,
//                   //   right: window.innerWidth,
//                   //   bottom: 0,
//                   // }} // Adjusted bounds dynamically
//                   bounds={{
//                     left: -scrollX, // Adjust bounds based on horizontal scroll position
//                     top: -scrollY, // Adjust bounds based on vertical scroll position
//                     right: containerWidth, // Adjust bounds based on horizontal scroll position
//                     bottom: bottomBoundary, // Set the bottom boundary
//                   }}
//                   position={{ x: table.position_x, y: table.position_y }}
//                 >
//                   <div
//                     className={`${tableFloorStyle.shapeclass}${" "}${
//                       tableFloorStyle[shape]
//                     }`}
//                     onMouseEnter={this.onDropAreaMouseEnter}
//                     onMouseLeave={this.onDropAreaMouseLeave}
//                     style={{
//                       backgroundColor: table.table_color,
//                       position: "absolute",
//                       transform: `translate(${table.position_x}px, ${table.position_y}px)`,
//                       textAlign: "center",
//                     }}
//                   >
//                     <span>{table.table_name}</span>
//                     <span className={`${tableFloorStyle.seat_capacity}`}>
//                       {table.max_capacity}
//                     </span>

//                     {table.table_status === false && (
//                       <div>
//                         <span
//                           style={{ fontSize: "20px" }}
//                           role="img"
//                           aria-label="Not Available"
//                           title="Not Available"
//                         >
//                           🚫
//                         </span>
//                       </div>
//                     )}
//                   </div>
//                 </Draggable>
//               );
//             })}
//           </div>
//           <TableModal
//             deleting={this.state.deletingTable}
//             deleteTable={this.deleteTable.bind(this)}
//             saveTable={this.saveTable.bind(this)}
//             isOpen={this.state.isTableModalOpen}
//             table={this.state.selectedTableForModal}
//             loc_id={this.state.location.loc_id}
//             toggleModal={this.toggleTableModal.bind(this)}
//             floor={this.state.floor}
//           />
//           <ConfirmationModal
//             isOpen={confirmationDialog.show}
//             accept={confirmationDialog.acceptCallback.bind(this)}
//             reject={confirmationDialog.rejectCallback.bind(this)}
//             title={confirmationDialog.title}
//             header={confirmationDialog.header}
//           />
//         </div>
//       </>
//     );
//   }
// }

// function mapStateToProps(state) {
//   const {
//     floors: {
//       allFloors: { data: floorsData, loading: floorLoading },
//     },
//     locations: {
//       location: { data: locationData, loading: locationLoading },
//     },
//     tables: {
//       alltables: { data: tables, loading: loading },
//     },
//   } = state;
//   return {
//     floors: floorsData,
//     locationData,
//     loading: floorLoading || locationLoading || loading,
//     tables,
//   };
// }

// export default withTranslation("global")(
//   connect(mapStateToProps, {
//     createTable,
//     getLocation,
//     getAllFloors,
//     getTableByFloorId,
//     deleteTable,
//   })(FloorTablesManagement)
// );
