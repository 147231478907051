import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { Printer as PrinterIcon, Mail } from "react-feather";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import OrderService from "../../services/OrderService";
import ViewHelper from "../../helpers/ViewHelper";
import TimeHelper from "../../helpers/TimeHelper";
import CONSTANTS from "../../constants";
import "../../assets/css/orders.scss";
import ReactToPrint from "react-to-print";
// import PrintOrderSession from './printOrderSession';
import { updateNavBarNotificationStatus } from "../../redux/actions/notifications";
import ResponseList from "../survey_response/responseList";
import { X } from "react-feather";
import toast, { Toaster } from "react-hot-toast";
import { upperCase } from "lodash";
import moment from "moment";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class PurchaseHistoryDetail extends Component {
  state = {
    loading: true,
    transactionDetail: null,
  };
  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const { state } = this.props.location;
    console.log("State: ", state);
    this.setState({ transactionDetail: state, loading: false });
  }

  renderTransactionDetailViewTop(transaction) {
    var nextSubscriptionDate = transaction.subscription_end;
    if (
      transaction.subscription == 1 &&
      transaction.subscription_status === "active"
    ) {
      nextSubscriptionDate = moment(nextSubscriptionDate)
        .add(1, "M")
        .format("DD-MM-YYYY");
    } else if (
      transaction.subscription == 1 &&
      transaction.subscription_status === "payment_failed"
    ) {
      nextSubscriptionDate = moment(nextSubscriptionDate).format("DD-MM-YYYY");
    }
    console.log("nextSubscriptionDate: ", nextSubscriptionDate);
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        id="order-heading"
      >
        <div className="text-uppercase">
          <p>
            {this.props.t("purchase-detail-of")} {transaction.Location.name}
          </p>
        </div>
        <div className="h4">
          {TimeHelper.toFormattedDate(
            transaction.createdAt,
            "ddd,Do MMM, YYYY"
          )}
        </div>
        <h5>
          <b>
            {transaction.subscription == 1
              ? this.props.t("recurring")
              : this.props.t("one-time-0")}{" "}
            {this.props.t("payment")}
          </b>
        </h5>
        {transaction.subscription == 1 && (
          <div>
            {this.props.t('subscription-status')}:{" "}
            <b>{upperCase(transaction.subscription_status)}</b>
            {transaction.subscription_status === "active" ? (
              <>
                <span>{this.props.t('next-invoice-will-charge-at')}: </span>
                <b>{nextSubscriptionDate}</b>
              </>
            ) : (
              <>
                <span>{this.props.t('last-invoice-was-done-at')}: </span>
                <b>{nextSubscriptionDate}</b>
              </>
            )}
          </div>
        )}
      </div>
    );
  }

  renderTransactionDetails(transaction) {
    return (
      <Card
        className="col-md-8 col-sm-12 shadow-sm print_order_items"
        style={{ margin: "0px auto" }}
      >
        <CardHeader>{this.props.t('package-items')}</CardHeader>
        <CardBody>
          {transaction.ItemsInTransactions.map((singleorder) => {
            return [
              <div
                className="order-item mb-1 d-flex justify-content-between"
                key={singleorder.id}
              >
                <div className="f2 name pr-1">
                  <b>
                    {singleorder.quantity}x {singleorder.title}
                  </b>
                  {singleorder.payment_mode === "license" &&
                  singleorder.status === "active" ? (
                    <div>
                      <small>{this.props.t('recurring-active')}</small>
                    </div>
                  ) : singleorder.payment_mode === "license" &&
                    singleorder.status === "inactive" ? (
                    <div>
                      <small>{this.props.t('recurring-in-active')}</small>
                    </div>
                  ) : null}
                </div>
                <div className="f2 price">
                  <strong>
                    {singleorder.price * singleorder.quantity}{" "}
                    {transaction.currency}
                  </strong>
                </div>
              </div>,
            ];
          })}
          <div className="order-item d-flex justify-content-between pt-1">
            <div className="f2 name pr-1">
              <strong>{this.props.t('total-0')}</strong>
            </div>
            <div className="f2 price">
              <strong>
                {transaction.price} {transaction.currency}
              </strong>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderOrderDetails(order) {
    return (
      <Card
        className="col-md-8 col-sm-12 shadow-sm"
        style={{ margin: "0px auto" }}
      >
        <CardHeader>{this.props.t('customer-and-order-detail')}</CardHeader>
        <CardBody>
          <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
              <strong>{this.props.t('table-name')}</strong>
            </div>
            <div>{ViewHelper.getTableName(order.Table)}</div>
          </div>
          <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
              <strong>{this.props.t('session-status')}</strong>
            </div>
            <div>{order.session_status.toUpperCase()}</div>
          </div>
          <div className="mb-2 order-item mb-1 d-flex justify-content-between">
            <div>
              <strong>{this.props.t('location-name')}</strong>
            </div>
            <div>{order.Location.name}</div>
          </div>
        </CardBody>
      </Card>
    );
  }

  closeEmailSendModal() {
    this.setState({
      saving: false,
      showEmailModal: false,
      customerEmail: null,
    });
  }

  async sendOrderReceipt() {
    try {
      this.setState({ saving: true });
      let loc_id = this.state.orderSessionDetail.Location.loc_id;
      let session_id = this.state.orderSessionDetail.session_id;
      if (
        this.state.customerEmail === null ||
        this.state.customerEmail === " "
      ) {
        toast.error(this.props.t('email-is-required'));
      }
      let sendEmail = await OrderService.sendReceiptToCustomer(
        loc_id,
        session_id,
        this.state.customerEmail
      );
      toast.success(this.props.t('receipt-send-successfully'));
      this.closeEmailSendModal();
    } catch (e) {
      this.closeEmailSendModal();
      toast.error(e.toString());
      return;
    }
  }

  render() {
    const { t } = this.props;
    const {
      state: { transactionDetail, loading,licenseData },
    } = this;
    console.log("purchaselicenseDataData",licenseData)
    console.log("transactionDetail",transactionDetail)
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="">
          {this.renderTransactionDetailViewTop(transactionDetail.transaction)}
          <Row>
            <Col md="12" sm="12">
              {this.renderTransactionDetails(transactionDetail.transaction)}
            </Col>
            <Col md="12" sm="12">
              {/* {this.renderOrderDetails(orderSessionDetail)} */}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withTranslation("global")(PurchaseHistoryDetail);
