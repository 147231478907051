import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  faCartPlus,
  faArrowLeft,
  faPlus,
  faMinus,
  faEye,
  faCamera,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Lens from './lens.png';

import './MenuOptions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLocation } from '../../../redux/actions/locations';

import SpinnerComponent from '../../@vuexy/spinner/Fallback-spinner';
import SelectLocation from '../../shared/SelectLocation';
import LocationSelectHeader from '../../shared/LocationSelectHeader';
import tablogo from '../../../assets/img/tab.png';
import LocationService from '../../../services/LocationService';

import BrandingService from '../../../services/BrandingService';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Card, CardHeader, Button, Tooltip } from 'reactstrap';
import CONSTANTS from '../../../constants';

class MenuOptionsPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
      branding: null,
      selectValueCounts: {},
      payToolTipOpen: false,
      callWaiterToolTipOpen: false,
      cartToolTipOpen: false,
      goBackToolTipOpen: false,
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
    console.log('MENU ADD TO CART PROPS: ', this.props);
    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);

      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
    const { category_data } = this.props.location.state;
    console.log('CATEGORY_DATA', category_data);
  }

  async refreshData(loc_id, initialize = true, fetchMenu = true) {
    this.setState({ loading: initialize, refreshing: true });
    let locationUpdated = false;
    let branding = null;
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      branding = await BrandingService.getBranding(loc_id);
      await this.props.getLocation(loc_id);

      locationUpdated = true;
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.location)),
      refreshing: false,
      isOutlet: this.props.location.is_outlet,
      branding: branding,
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.menu) !== JSON.stringify(this.props.menu) &&
      this.state.location &&
      !this.state.refreshing
    ) {
      this.refreshData(this.state.location.loc_id, false, false);
    }
  }

  // Function to handle radio value selection
  handleRadioValueSelection(optionIndex, selectedValue, menuOption) {
    // Implement your logic here to update the selected radio values
  }

  // Function to handle checkbox value selection
  handleCheckboxValueSelection(optionValueId, menuOption) {
    // Implement your logic here to update the selected checkboxes
  }
  handleSelectValueSelection(optionValueId, menuOption) {
    const { selectValueCounts } = this.state;

    // Check if the option is already selected
    const isOptionSelected = selectValueCounts[optionValueId];

    // Create a copy of the selectValueCounts object
    const updatedCounts = { ...selectValueCounts };

    if (isOptionSelected) {
      // If the option is already selected, remove it
      delete updatedCounts[optionValueId];
    } else {
      // If the option is not selected, add it
      updatedCounts[optionValueId] = 1;
    }

    this.setState({ selectValueCounts: updatedCounts });
  }
  togglePayTooltip = () => {
    this.setState({
      payToolTipOpen: !this.state.payToolTipOpen,
    });
  };

  toggleCallWaiterTooltip = () => {
    this.setState({
      callWaiterToolTipOpen: !this.state.callWaiterToolTipOpen,
    });
  };
  toggleCartToolTip = () => {
    this.setState({
      cartToolTipOpen: !this.state.cartToolTipOpen,
    });
  };
  toggleGoBackTooltip = () => {
    this.setState({
      goBackToolTipOpen: !this.state.goBackToolTipOpen,
    });
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const { t } = this.props;
    const {
      props: { dishtags },
    } = this;

    const categoryItem = this.props?.history?.location?.state?.category_data;
    const menuItem = this.props?.history?.location?.state?.menu_data;
    const nutriScore = menuItem.nutri_score || 'A';

    // Define color mappings for each grade
    const colorMappings = {
      A: '#1D8E39',
      B: '#9BC432',
      C: '#FBBA05',
      D: '#E96120',
      E: '#D32024',
    };
    console.log('Menu ITEMS', menuItem);
    console.log('CATEGORY ITEMS', categoryItem);
    const {
      props: { location },
    } = this;

    const {
      state: { selectLocation, loading, branding },
    } = this;

    if (loading) return <SpinnerComponent />;
    const locationName = location ? location.name : '';
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <div className="location">
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
          </div>
        </div>
        <div className="display-flex flex-row justify-content-between mb-1">
          <div
            className="container"
            // style={{
            //   backgroundImage: `url(${tablogo}) `,
            //   backgroundPosition: 'center',

            //   backgroundRepeat: 'no-repeat',
            //   backgroundSize: 'contain',
            //   height: '200vh',
            // }}
          >
            <div>
              <div
                style={{
                  background: 'black',
                  borderRadius: '30px',
                  padding: '3.5rem',
                  display: 'flex',
                  margin: 'auto',
                }}
                className="col-lg-8 col-12"
              >
                <button
                  style={{
                    background: 'black',

                    borderRadius: '50%',
                    width: '43px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: 1,
                    top: '5px',
                    right: '46%',
                  }}
                >
                  <img
                    src={Lens}
                    alt="Lens Icon"
                    style={{ width: '100%', height: '100%' }}
                  />
                </button>

                <Card
                  className="p-1 col-12"
                  style={{
                    backgroundColor: '#F2F2F2',
                    maxHeight: '90vh',
                    overflow: 'auto',
                  }}
                >
                  <CardHeader
                    className="  p-2 w-100"
                    style={{
                      backgroundColor: `${branding?.basecolor}`,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h4
                      className="mb-0"
                      style={{
                        color: `${branding?.h2_text_color}`,
                        textAlign: 'left',
                        fontWeight: 'bold',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <FontAwesomeIcon
                          id="arrowLeftIcon"
                          style={{
                            width: '30px',
                            height: '30px',
                            marginRight: '10px',
                            verticalAlign: 'middle',
                            color: 'white',
                            padding: '5px',
                            cursor: 'pointer',
                          }}
                          icon={faArrowLeft}
                          className="mr-1"
                          onClick={() =>
                            this.props.history.push(
                              '/menu/menu-mobile-preview',
                              {
                                category_data: categoryItem,
                              }
                            )
                          }
                        />
                      </div>
                      <div>{categoryItem?.name}</div>
                      <Tooltip
                        target="arrowLeftIcon"
                        isOpen={this.state.goBackToolTipOpen}
                        toggle={this.toggleGoBackTooltip}
                      >
                        Go back to the mobile preview menu.
                      </Tooltip>
                    </h4>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <button
                          id="myTooltip1"
                          style={{
                            width: '5rem',
                            backgroundColor: 'transparent',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '20px',
                            border: '1px solid white',
                            textAlign: 'center',
                            marginRight: '10px',
                          }}
                        >
                          {t('pay-now')}
                        </button>
                        <Tooltip
                          target="myTooltip1"
                          isOpen={this.state.payToolTipOpen}
                          toggle={this.togglePayTooltip}
                        >
                          This is the "Pay Now" button. It will initiate the
                          payment process.
                        </Tooltip>

                        <button
                          id="myTooltip2"
                          style={{
                            width: '7rem',
                            backgroundColor: 'transparent',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '20px',
                            border: '1px solid white',
                            textAlign: 'center',
                          }}
                        >
                          {t('call-waiter')}
                        </button>
                        <Tooltip
                          target="myTooltip2"
                          isOpen={this.state.callWaiterToolTipOpen}
                          toggle={this.toggleCallWaiterTooltip}
                        >
                          This is the "Call Waiter" button. It will call the
                          waiter for assistance.
                        </Tooltip>
                      </div>

                      <FontAwesomeIcon
                        id="myTooltip3"
                        style={{
                          width: '45px',
                          height: '30px',
                          marginLeft: '10px',
                          background: 'green',
                          color: 'white',
                          borderRadius: '5px',
                          padding: '5px',
                          cursor: 'pointer',
                        }}
                        icon={faCartPlus}
                        className="mr-1"
                      />

                      <Tooltip
                        target="myTooltip3"
                        isOpen={this.state.cartToolTipOpen}
                        toggle={this.toggleCartToolTip}
                      >
                        This is your shopping cart.
                      </Tooltip>
                    </div>
                  </CardHeader>
                  <div>
                    <div>
                      <div style={{ position: 'relative', padding: '1.0rem' }}>
                        <Slider {...settings}>
                          {menuItem.MenuMedia.map((mediaItem, index) => (
                            <div key={index}>
                              <div style={{ position: 'relative' }}>
                                <img
                                  src={`${CONSTANTS.API_URL}/restaurant_data/${mediaItem?.menu_photo}`}
                                  alt={`Media ${index}`}
                                  style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                    margin: 'auto',
                                    borderRadius: '10px',
                                  }}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = `${CONSTANTS.API_URL}/restaurant_data/${branding?.logo}`;
                                  }}
                                />
                                <div
                                  className="nutri-score-overlay"
                                  style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '10px',
                                    backgroundColor: 'white',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    zIndex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: '18px',
                                        marginBottom: '5px',
                                        fontWeight: 'bold',
                                        color: 'darkgray',
                                      }}
                                    >
                                      NUTRI-SCORE
                                    </div>
                                    <div
                                      className="nutri-scores"
                                      style={{
                                        display: 'flex',
                                      }}
                                    >
                                      {['A', 'B', 'C', 'D', 'E'].map(
                                        (grade) => (
                                          <div
                                            key={grade}
                                            className="nutri-score-circle"
                                            style={{
                                              backgroundColor:
                                                colorMappings[grade],
                                              color: 'white',
                                              width:
                                                nutriScore === grade
                                                  ? '37px'
                                                  : '30px',
                                              height: '60px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              margin: '0.5px',
                                              fontSize:
                                                nutriScore === grade
                                                  ? '22px'
                                                  : '18px',
                                              fontWeight: 'bold',

                                              borderRadius:
                                                nutriScore === grade
                                                  ? '45%'
                                                  : '0',
                                              opacity:
                                                nutriScore === grade ? 1 : 0.7,
                                            }}
                                          >
                                            {grade}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-2"
                    style={{ fontWeight: 'bolder', fontSize: '17px' }}
                  >
                    {menuItem?.menu_name}
                  </div>

                  <div
                    className="mt-2"
                    style={{ fontWeight: 'lighter', fontSize: '14px' }}
                  >
                    {menuItem?.menu_description}

                    {menuItem?.MenuAllergyItems.length > 0 ? (
                      <div className="card ">
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{ fontWeight: 'bold' }}
                          >
                            {t('allergens')}{' '}
                          </h5>
                          {menuItem?.MenuAllergyItems.map(
                            (allergyItem, index) => (
                              <div key={index}>
                                <button
                                  className="btn btn mr-2"
                                  style={{
                                    backgroundColor: branding?.basecolor,
                                    color: 'white',
                                  }}
                                >
                                  {allergyItem?.Allergy_Item?.item_name}
                                </button>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ) : null}

                    <div className="card ">
                      {menuItem?.MenuOptions.map((menuOption, index) => (
                        <div key={index}>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',

                                borderRadius: 4,
                                padding: '10px',
                              }}
                            >
                              <div
                                className="card-title "
                                style={{ fontWeight: 'bold' }}
                              >
                                {menuOption?.Option.option_name}
                              </div>
                              {menuOption?.required ? (
                                <div style={{ marginLeft: 'auto' }}>
                                  <small>required</small>
                                </div>
                              ) : null}
                            </div>
                            {(menuOption?.Option.display_type === 'select' ||
                              menuOption?.Option.display_type === 'checkbox') &&
                            (menuOption?.min !== 0 || menuOption?.max !== 0) ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {menuOption?.Option.display_type ===
                                'select' ? (
                                  <small>
                                    {t('you-need-to-select-maximum')}{' '}
                                    {menuOption.max}{' '}
                                    {t('quantity-from-each-option')}{' '}
                                  </small>
                                ) : (
                                  <small>
                                    {t('you-need-to-select-maximum-0')}{' '}
                                    {menuOption.max} {t('options')}
                                  </small>
                                )}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            {menuOption?.Option?.display_type === 'select' && (
                              <div>
                                {menuOption?.MenuOptionValues.map(
                                  (menuOptionValue, idx) => {
                                    // Define a counter to keep track of displayed options
                                    if (idx < 5) {
                                      return (
                                        <div
                                          key={idx}
                                          style={{ margin: '10px' }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    this.state
                                                      .selectValueCounts[
                                                      menuOptionValue
                                                        .option_value_id
                                                    ] > 0
                                                  }
                                                  onChange={() =>
                                                    this.handleSelectValueSelection(
                                                      menuOptionValue?.option_value_id,
                                                      menuOption,
                                                      'increment'
                                                    )
                                                  }
                                                />
                                              </div>

                                              <div
                                                style={{
                                                  fontWeight: 'normal',
                                                  fontSize: '15px',
                                                  marginLeft: '10px',
                                                }}
                                              >
                                                {
                                                  menuOptionValue?.OptionValue
                                                    .value
                                                }
                                              </div>
                                            </div>
                                            <div>
                                              <p>
                                                {menuOptionValue?.new_price > 0
                                                  ? menuOptionValue?.new_price
                                                  : t('free')}
                                              </p>
                                            </div>

                                            {this.state.selectValueCounts[
                                              menuOptionValue?.option_value_id
                                            ] > 0 ? (
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <div
                                                  onClick={() =>
                                                    this.handleSelectValueSelection(
                                                      menuOptionValue?.option_value_id,
                                                      menuOption,
                                                      'decrement'
                                                    )
                                                  }
                                                >
                                                  -
                                                </div>
                                                <div>
                                                  {this.state.selectValueCounts[
                                                    menuOptionValue
                                                      .option_value_id
                                                  ] || 0}
                                                </div>
                                                <div
                                                  onClick={() =>
                                                    this.handleSelectValueSelection(
                                                      menuOptionValue.option_value_id,
                                                      menuOption,
                                                      'increment'
                                                    )
                                                  }
                                                >
                                                  +
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      );
                                    }
                                    return null;
                                  }
                                )}
                              </div>
                            )}

                            {menuOption.Option.display_type === 'radio' && (
                              <div>
                                {menuOption.MenuOptionValues.map(
                                  (menuOptionValue, idx) => (
                                    <div key={idx} style={{ margin: '10px' }}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <div>
                                            <input
                                              type="radio"
                                              name={`option_${index}`}
                                              value={
                                                menuOptionValue.option_value_id
                                              }
                                              onChange={() =>
                                                this.handleRadioValueSelection(
                                                  index,
                                                  menuOptionValue.option_value_id,
                                                  menuOption
                                                )
                                              }
                                            />
                                          </div>

                                          <div
                                            style={{
                                              fontWeight: 'normal',
                                              fontSize: '15px',
                                              marginLeft: '10px',
                                            }}
                                          >
                                            {menuOptionValue.OptionValue.value}
                                          </div>
                                        </div>
                                        <div>
                                          <p>
                                            {menuOptionValue.new_price > 0
                                              ? menuOptionValue.new_price
                                              : t('free')}
                                          </p>
                                        </div>

                                        {this.state.selectValueCounts[
                                          menuOptionValue.option_value_id
                                        ] > 0 ? (
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <div
                                              onClick={() =>
                                                this.handleSelectValueSelection(
                                                  menuOptionValue.option_value_id,
                                                  menuOption,
                                                  'decrement'
                                                )
                                              }
                                            >
                                              -
                                            </div>
                                            <div>
                                              {this.state.selectValueCounts[
                                                menuOptionValue.option_value_id
                                              ] || 0}
                                            </div>
                                            <div
                                              onClick={() =>
                                                this.handleSelectValueSelection(
                                                  menuOptionValue.option_value_id,
                                                  menuOption,
                                                  'increment'
                                                )
                                              }
                                            >
                                              +
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            {menuOption.Option.display_type === 'checkbox' && (
                              <div>
                                {menuOption.MenuOptionValues.map(
                                  (menuOptionValue, idx) => (
                                    <div key={idx} style={{ margin: '10px' }}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <input type="checkbox" />
                                        <span style={{ fontWeight: 'bold' }}>
                                          {menuOptionValue?.OptionValue?.value}
                                        </span>
                                        <div></div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="card ">
                      <div className="card-body">
                        <h5
                          className="card-title"
                          style={{ fontWeight: 'bold' }}
                        >
                          {t('add-special-instructions')}{' '}
                        </h5>
                        <input
                          type="text"
                          style={{
                            border: '1px solid #ccc',
                            borderRadius: '20px',
                            padding: '10px',
                            width: '100%',
                            boxSizing: 'border-box',
                            fontSize: '16px',
                            marginTop: '10px',
                          }}
                          placeholder={t('e-g-add-something-extra')}
                        />
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <h5
                          className="card-title"
                          style={{ fontWeight: 'bold' }}
                        >
                          {t('recommended-item')}{' '}
                        </h5>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '8px',
                          }}
                        >
                          {menuItem.MenuItemRecommendations.map(
                            (recommendedItem, index) => (
                              <div
                                key={index}
                                style={{
                                  position: 'relative',
                                }}
                              >
                                <img
                                  src={`${CONSTANTS.API_URL}/restaurant_data/${recommendedItem?.Menu?.menu_photo}`}
                                  alt={`recommendedItems`}
                                  style={{
                                    maxWidth: '200px',
                                    height: '200px',
                                    borderRadius: '10px',
                                  }}
                                />

                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                  }}
                                ></div>
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',

                                    color: 'white',
                                    padding: '4px',
                                    fontSize: '14px',
                                    fontWeight: 'bolder',
                                  }}
                                >
                                  {recommendedItem?.Menu?.menu_name}
                                </div>

                                <FontAwesomeIcon
                                  icon={faEye}
                                  style={{
                                    position: 'absolute',
                                    bottom: '8px',
                                    left: '6px',
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    padding: '4px',
                                    fontSize: '30px',
                                  }}
                                />

                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: '8px',
                                    right: '6px',
                                    width: '5rem',
                                    backgroundColor: 'white',
                                    color: 'green',
                                    padding: '5px',
                                    borderRadius: '20px',
                                    border: '1px solid black',
                                    textAlign: 'center',
                                  }}
                                >
                                  €{recommendedItem?.Menu?.menu_price}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-body"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div>
                        <button
                          style={{
                            backgroundColor: branding?.basecolor,
                            color: 'white',
                          }}
                          className="btn btn-sm"
                        >
                          -
                        </button>
                        <span className="mx-2">1</span>
                        <button
                          style={{
                            backgroundColor: branding?.basecolor,
                            color: 'white',
                          }}
                          className="btn btn-sm"
                        >
                          +
                        </button>
                      </div>
                      <button
                        style={{
                          backgroundColor: branding?.basecolor,
                          color: 'white',
                          flex: '1',
                        }}
                        className="btn btn btn-lg col-12 ml-2"
                      >
                        {t('add-to-cart')}
                      </button>
                      <div
                        style={{
                          // flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                          fontSize: '18px',
                          marginLeft: '10px',
                        }}
                      >
                        <span
                          style={{
                            color: branding?.h2_text_color,
                          }}
                        >
                          €12.00
                        </span>
                      </div>
                    </div>
                  </div>
                </Card>
                <button
                  style={{
                    background: 'transparent',
                    border: '2px solid white',
                    borderRadius: '50%', // Makes it a circle
                    width: '43px', // Adjust the size as needed
                    height: '40px', // Adjust the size as needed
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: 1,
                    bottom: '20px',
                    left: '46%',
                    color: 'white',
                  }}
                >
                  <FontAwesomeIcon icon={faCircleNotch} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
  } = state;
  return {
    location: locationData,
    loading: locationLoading,
  };
}

export default withTranslation('global')(
  connect(mapStateToProps, {
    getLocation,
  })(MenuOptionsPreview)
);
