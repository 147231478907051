import React from "react";
import { connect } from "react-redux";
import {
  Label,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  FormGroup,
  Input,
  Media,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Select from "react-select";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import { Image, X } from "react-feather";
import { getLocation } from "../../redux/actions/locations";
import LocationService from "../../services/LocationService";
import TableService from "../../services/TableService";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import { getConnectTabsTables } from "../../redux/actions/tables";
import socketService from "../../helpers/socket";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import * as classnames from "classnames";
import MenuService from "../../services/MenuService";
import Swal from "sweetalert2";

class SendPopupsOnTabs extends React.Component {
  fileRef = React.createRef();
  state = {
    errorSaving: false,
    selectedTabs: [],
    allAvailableTabsOptions: [],
    allAvailableMenuOptions: [],
    confirmationPopup: false,
    errorPopup: false,
    loading: true,
    selectLocation: false,
    titleError: false,
    tabsError: false,
    descriptionError: false,
    notificationTitle: "",
    notificationDescription: "",
    img: "",
    tab: 1,
    menu_id: null,
    menuError: false,
  };

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchTables = true) {
    this.setState({ loading: initialize });
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
    }
    if (fetchTables) {
      await this.getTables(loc_id);
    }

    const availableTabs = [];
    const {
      props: { tables },
    } = this;

    for (let i = 0; i < tables.length; i++) {
      availableTabs.push({
        value: tables[i].manager_user_id,
        label: tables[i].table_name,
      });
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.location)),
      allAvailableTabsOptions: availableTabs,
    });
  }

  async getTables(loc_id) {
    return await this.props.getConnectTabsTables(loc_id);
  }

  updateSelectedTabs = (e) => {
    this.setState({ selectedTabs: e });
  };

  updateSelectedMenu = (val) => {
    this.setState({ menu_id: val });
  };

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  async sendPopupNotification() {
    try {
      const fileInput = document.querySelector("#fileInput");
      const formData = new FormData();
      if (this.state.tab === 1) {
        formData.append("file", fileInput.files[0]);
        formData.append("notification_for", "tabs");
      } else {
        formData.append("menu_id", this.state.menu_id);
        formData.append("notification_for", "menu");
      }
      formData.append("selectedtabs", JSON.stringify(this.state.selectedTabs));
      formData.append("title", this.state.notificationTitle);
      formData.append("description", this.state.notificationDescription);

      let popupData = await TableService.sendNotificationToSelectedTabs(
        this.state.location.loc_id,
        formData
      );

      if (popupData) {
        socketService.emit("send_popup_trigger", popupData);
      }
      this.setState({
        confirmationPopup: false,
        notificationTitle: "",
        notificationDescription: "",
        selectedTabs: [],
      });
      window.location.reload();
    } catch (e) {
      this.setState({ errorPopup: true });
      this.setState({
        confirmationPopup: false,
      });
    }
  }

  checkValidData() {
    if (this.state.tab === 1 && this.state.selectedTabs.length === 0) {
      this.setState({ tabsError: true });
      return;
    }

    if (this.state.tab === 2 && this.state.menu_id === null) {
      this.setState({ menuError: true });
      return;
    }

    if (
      document.getElementById("title").value === "" ||
      document.getElementById("title").value === null
    ) {
      this.setState({ titleError: true });
      return;
    }

    if (
      document.getElementById("description").value === "" ||
      document.getElementById("description").value === null
    ) {
      this.setState({ descriptionError: true });
      return;
    }
    this.setState({
      confirmationPopup: true,
      notificationTitle: document.getElementById("title").value,
      notificationDescription: document.getElementById("description").value,
    });
  }

  handlePhotoChange(e) {
    if (e.target.files.length > 0) {
      const acceptedFileTypes = [
        "image/jpg",
        "image/png",
        "image/jpeg",
        "image/webp",
        "image/svg+xml",
      ];
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

      const selectedFile = e.target.files[0];

      // Check if the selected file type is allowed
      if (acceptedFileTypes.includes(selectedFile.type)) {
        // Check if the selected file size is within the limit
        if (selectedFile.size <= maxFileSize) {
          let reader = new FileReader();
          reader.onload = (ev) => {
            this.setState({ img: ev.target.result, deletePhoto: false });
          };
          reader.readAsDataURL(selectedFile);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: this.props.t("file-size-exceeds-the-5mb-limit"),
          });
          return false;
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.props.t(
            "invalid-file-type-please-select-a-valid-image-file-jpg-png-jpeg-webp-or-svg"
          ),
        });
        return false;
      }
    }
  }

  // handlePhotoChange(e) {
  //   if (e.target.files.length > 0) {
  //     let reader = new FileReader();
  //     reader.onload = (ev) => {
  //       this.setState({ img: ev.target.result, deletePhoto: false });
  //     };
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // }

  toggleTab = async (sTab) => {
    if (this.state.tab !== sTab) {
      this.setState({
        tab: sTab,
        titleError: false,
        descriptionError: false,
        tabsError: false,
        menuError: false,
      });
    }

    if (sTab === 2) {
      this.setState({ loading: true });
      let menus = await MenuService.getAllMenuListPopup(
        this.state.location.loc_id
      );
      let menuList = [];
      for (let i = 0; i < menus.length; i++) {
        menuList.push({
          value: menus[i].menu_id,
          label: menus[i].menu_name,
        });
      }
      this.setState({ allAvailableMenuOptions: menuList, loading: false });
    }
  };

  render() {
    const { t } = this.props;
    const {
      state: {
        errorSaving,
        allAvailableTabsOptions,
        confirmationPopup,
        errorPopup,
        loading,
        selectLocation,
        titleError,
        descriptionError,
        img,
        allAvailableMenuOptions,
      },
    } = this;
    const image = img;
    const {
      props: { location },
    } = this;

    if (loading) return <SpinnerComponent />;

    const locationName = location ? location.name : "";
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );
    return (
      <div className="location">
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
          </div>
        </div>
        <Card>
          <CardHeader className="flex-column align-items-start">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.tab === 1,
                  })}
                  onClick={() => {
                    this.toggleTab(1);
                  }}
                >
                  {t("trigger-popups")}{" "}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.tab === 2,
                  })}
                  onClick={() => {
                    this.toggleTab(2);
                  }}
                >
                  {t("trigger-menu-popup")}{" "}
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>

          <CardBody>
            <FormGroup className="row">
              <Col md={12}>
                <Label for="tabs">
                  {this.state.tabsError ? (
                    <p style={{ color: "red" }}>{t("select-tables-tab")}</p>
                  ) : (
                    t("select-tables-tabs")
                  )}
                </Label>
                <Select
                  className={`React no-pointer-events border-red ${
                    this.state.tabsError ? "border-danger" : null
                  }`}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "var(--primary)"
                        : state.isFocused
                        ? ""
                        : "",
                    }),
                  }}
                  classNamePrefix="select"
                  name="tabs"
                  options={allAvailableTabsOptions}
                  onChange={(e) => this.updateSelectedTabs(e)}
                  isMulti
                  isSearchable
                  hideSelectedOptions={false}
                />
              </Col>
            </FormGroup>
            {this.state.tab === 1 ? (
              <>
                <FormGroup className="row">
                  <Col lg={2} md={4} sm={12}>
                    <Label for="photoDiv">Image:</Label>
                    <div
                      tabIndex="0"
                      id="photoDiv"
                      className="display-flex flex-column border border-2 border-light cursor-pointer"
                      style={{ position: "relative", height: 120 }}
                      onKeyUp={(e) =>
                        e.keyCode === 13 && !e.ctrlKey
                          ? this.fileRef.current.click()
                          : null
                      }
                      onClick={() => this.fileRef.current.click()}
                    >
                      <div style={{ textAlign: "-webkit-center" }}>
                        {image ? (
                          <div>
                            <div
                              style={{
                                position: "absolute",
                                top: -10,
                                right: -10,
                              }}
                            >
                              <Button.Ripple
                                onClick={() => {
                                  this.setState({
                                    img: null,
                                    deletePhoto: true,
                                  });
                                  this.fileRef.current.value = null;
                                }}
                                style={{ width: 20, height: 20 }}
                                className="m-0 p-0"
                                color="danger"
                              >
                                <X size={14} />
                              </Button.Ripple>
                            </div>
                            <div
                              style={{ position: "absolute", bottom: 3 }}
                              className="full-width"
                            >
                              <Button.Ripple
                                className="p-25"
                                color="primary"
                                onClick={() => this.fileRef.current.click()}
                              >
                                <span className="font-small-1">
                                  {t("change-photo")}
                                </span>
                              </Button.Ripple>
                            </div>
                            <Media
                              object
                              src={image}
                              alt="image"
                              style={{ maxWidth: 148, maxHeight: 118 }}
                              className="align-self-center"
                            />
                          </div>
                        ) : (
                          <div
                            className="align-self-center"
                            style={{ padding: 9, height: 50, width: 50 }}
                          >
                            <Image size={32} className="align-self-center" />
                          </div>
                        )}
                      </div>
                      {image ? null : (
                        <React.Fragment>
                          <span className="font-small-2 primary align-self-center">
                            {t("click-to-upload-a-file")}{" "}
                          </span>
                          <span className="font-small-2 color- align-self-center align-text-center">
                            {t("png-jpg-img")} <br /> {t("up-to-5mb")}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                    <input
                      type="file"
                      id="fileInput"
                      name="fileInput"
                      style={{ display: "none" }}
                      ref={this.fileRef}
                      onChange={this.handlePhotoChange.bind(this)}
                    />
                  </Col>
                </FormGroup>
              </>
            ) : (
              <FormGroup className="row">
                <Col md={12}>
                  <Label for="menus">{t("select-menu-item")} : </Label>
                  <Select
                    className={`React no-pointer-events border-red ${
                      this.state.menuError ? "border-danger" : null
                    }`}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "var(--primary)"
                          : state.isFocused
                          ? ""
                          : "",
                      }),
                    }}
                    classNamePrefix="select"
                    name="menus"
                    options={allAvailableMenuOptions}
                    onChange={(e) => this.updateSelectedMenu(e.value)}
                  />
                </Col>
              </FormGroup>
            )}
            <FormGroup className="row">
              <Col md={12}>
                <Label for="title">{t("popup-title")}: </Label>
                <Input
                  type="text"
                  id="title"
                  placeholder={t("enter-popup-title-here")}
                  invalid={titleError}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Col md={12}>
                <Label for="description">{t("popup-description")}: </Label>
                <Input
                  type="textarea"
                  id="description"
                  placeholder={t("enter-description")}
                  invalid={descriptionError}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Col md={12}>
                <Button
                  color="primary"
                  disabled={false}
                  onClick={(e) => this.checkValidData()}
                >
                  {t("save")}{" "}
                </Button>{" "}
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
        <ConfirmationModal
          header={t("need-confirmation")}
          title={t(
            "are-you-sure-to-send-popup-notification-to-all-selected-tables"
          )}
          isOpen={confirmationPopup}
          accept={() => this.sendPopupNotification()}
          reject={() => this.setState({ confirmationPopup: false })}
        />
        <ConfirmationModal
          header={"ERROR"}
          title={t("notification-popups-not-be-able-to-send-pls-retry-again")}
          isOpen={errorPopup}
          reject={() => this.setState({ errorPopup: false })}
          rejectTitle={"OK"}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
    tables: {
      connectedTables: { data: tables, loading: tablesLoading },
    },
  } = state;
  return {
    location: locationData,
    loading: locationLoading && tablesLoading,
    tables,
  };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getLocation,
    getConnectTabsTables,
  })(SendPopupsOnTabs)
);
