import React from "react";
import {Grid, IconButton, Modal, OutlinedInput, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const ReservationModal = ({reservation, openReservation, setOpenReservation}) => {
    const [t, i18n] = useTranslation('global');
    console.log(reservation);
    const data = {
        name: reservation?.name ? reservation?.name : '',
        email: reservation?.email ? reservation?.email : '',
        phone: reservation?.number ? reservation?.number : '',
        date: reservation?.date ? reservation?.date : '',
        time: reservation?.time ? reservation?.time : '',
        numberOfGuests: reservation?.user_count ? reservation?.user_count : '',
        description: reservation?.description ? reservation?.description : ''
    }

    return(
        <Modal open={openReservation} onClose={() => setOpenReservation(false)} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Stack sx={{width: {xs: '90vw', sm: '400px', md: '450px'}, padding: '1rem 2rem 2rem 2rem', backgroundColor: 'white', height: 'fit-content', maxHeight: '90vh'}}>
                <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h5' sx={{fontSize: '1.25rem', fontWeight: '600'}}>{t('reservation_details')}</Typography>
                    <IconButton>
                        <CloseIcon onClick={()=> setOpenReservation(false)}/>
                    </IconButton>
                </Stack>
                <Grid container spacing={2}>
                    {Object.entries(data).map(([key, value], index) => (
                        <Grid item xs={12} sm={index !== 6 ? 6 : 12} key={index}>
                            <Typography variant='h6' sx={{fontSize: '0.9rem'}}>{t(key)}</Typography>
                            <OutlinedInput
                                value={value}
                                size="small"
                                fullWidth
                                multiline={index === 6}
                                rows={4}
                                disabled
                            />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </Modal>
    )
}

export default ReservationModal